//@flow
import React from 'react';
import {
	StyleSheet,
	View,
	Image,
	TouchableOpacity,
	Dimensions
} from 'react-native';
import { Paragraph, CardSection, Card, Text } from 'Components/common';
import { useNavigation } from '@react-navigation/native';
import { i18n } from 'Theme';

const window = Dimensions.get('window');

export const RedditThreads = (props) => {
	const { id, post, theme } = props;
	const navigation = useNavigation();

	return (
		<View key={id}>
			<TouchableOpacity
				activeOpacity={0.5}
				onPress={() =>
					navigation.navigate('RecipesRedditSingle', {
						permalink: post.permalink
					})
				}>
				<Card style={{ backgroundColor: props.theme.placeholderGray }}>
					<CardSection>
						<Paragraph style={styles.cardHeading}>{post.title}</Paragraph>
					</CardSection>
					{post.preview && (
						<CardSection style={styles.paddingZero}>
							<Image
								style={styles.imageStyle}
								source={{
									uri: post.preview.images[0].source.url.replace(/&amp;/g, '&')
								}}
							/>
						</CardSection>
					)}

					<CardSection style={{ backgroundColor: props.theme.placeholderGray }}>
						<Text style={styles.white}>{post.domain}</Text>
						<View style={styles.metaContainer}>
							<Text style={styles.white}>
								{post.ups} {i18n.t('likes')}
							</Text>
							<Text style={styles.white}>
								{post.num_comments} {i18n.t('comments')}
							</Text>
						</View>
					</CardSection>
				</Card>
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	cardHeading: {
		paddingLeft: 0,
		fontSize: 14,
		lineHeight: 16,
		color: '#FFF'
	},
	metaContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	imageStyle: {
		width: window.width - 30,
		height: (238 / 375) * window.width,
		alignSelf: 'center'
	},
	paddingZero: {
		padding: 0
	},
	white: {
		color: '#FFF'
	}
});
