//@flow
import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Theme';
import Icon from 'react-native-vector-icons/FontAwesome';
import ActionButton from 'react-native-circular-action-menu';
import styles from '../../Styles/Styles';

export const EditMealActionButton = ({
	toggleMacroModal,
	navigation,
	route,
	theme
}) => {
	const searchRoute = () => {
		navigation.navigate('MealEditingSearch', {
			...route.params,
			editingMeal: true,
			editingIngredient: false
		});
	};
	const barcodeRoute = () => {
		navigation.navigate('MealEditingBarcode', {
			...route.params,
			editingMeal: true,
			editingIngredient: false
		});
	};

	const listRoute = (type) => {
		navigation.navigate('MealEditingTabList', {
			...route.params,
			editingMeal: true,
			editingIngredient: false,
			type: `${type}`
		});
	};

	return (
		<ActionButton
			buttonColor={theme.themeAccent}
			radius={200}
			position="right"
			bgColor="rgba(0,0,0,0.5)"
			btnOutRange={theme.border}
			outRangeScale={0.75}>
			<ActionButton.Item
				buttonColor={theme.themeAccent}
				title={i18n.t('quickmacro')}
				onPress={() => toggleMacroModal(true)}
				size={45}>
				<Icon name="plus" style={styles.actionButtonIcon} />
			</ActionButton.Item>
			<ActionButton.Item
				buttonColor={theme.themeAccent}
				title={i18n.t('search')}
				onPress={searchRoute}
				size={45}>
				<Icon name="search" style={styles.actionButtonIcon} />
			</ActionButton.Item>
			<ActionButton.Item
				buttonColor={theme.themeAccent}
				title={i18n.t('barcode')}
				onPress={barcodeRoute}
				size={45}>
				<Icon name="barcode" style={styles.actionButtonIcon} />
			</ActionButton.Item>
			<ActionButton.Item
				buttonColor={theme.themeAccent}
				title={i18n.t('foods')}
				onPress={() => listRoute('food')}
				size={45}>
				<Icon name="spoon" style={styles.actionButtonIcon} />
			</ActionButton.Item>
			<ActionButton.Item
				buttonColor={theme.themeAccent}
				title={i18n.t('meals')}
				onPress={() => listRoute('meal')}
				size={45}>
				<Icon name="cutlery" style={styles.actionButtonIcon} />
			</ActionButton.Item>
			<ActionButton.Item
				buttonColor={theme.themeAccent}
				title={i18n.t('favorites')}
				onPress={() => listRoute('favorite')}
				size={45}>
				<Icon name="star" style={styles.actionButtonIcon} />
			</ActionButton.Item>
			<ActionButton.Item
				buttonColor={theme.themeAccent}
				title={i18n.t('recent')}
				onPress={() => listRoute('recent')}
				size={45}>
				<Icon name="history" style={styles.actionButtonIcon} />
			</ActionButton.Item>
		</ActionButton>
	);
};

EditMealActionButton.propTypes = {
	listArray: PropTypes.array,
	onSelectionChange: PropTypes.func
};
