//@flow
//Strings used throughout the app translated into English, French, Spanish, Portuguese, German

export const deStrings = {
	appName: 'MyKeto',
	log: 'Log',
	login: 'Einloggen',
	learn: 'Lernen',
	foodlist: 'Food List',
	recipes: 'Rezepte',
	mealplan: 'Essensplan',
	otherdiet: 'Andere Diäten',
	moreapps: 'Mehr Apps',
	macrocalculator: 'Makro-Rechner',
	purchase: 'Kauf',
	tracker: 'Tracker',
	progress: 'Fortschritt',
	manage: 'Verwalten',
	settings: 'Die Einstellungen',
	preferences: 'Einstellungen',
	customgoals: 'Benutzerdefinierte Ziele',
	othertracking: 'Andere Verfolgung',
	social: 'Sozial',
	fitnessTracker: 'Fitness-Tracker',
	feedback: 'Feedback / Support',
	addFood: 'Lebensmittel hinzufügen',
	addIngredient: 'Zutat hinzufügen',
	mealsRecipes: 'Mahlzeiten / Rezepte',
	editLogEntry: 'Protokolleintrag bearbeiten',
	editMeal: 'Mahlzeit bearbeiten',
	editFood: 'Essen bearbeiten',
	deleteFood: 'Lebensmittel löschen',
	deleteMeal: 'Mahlzeit löschen',
	friends: 'Freunde',
	copyMeal: 'Mahlzeit kopieren',
	mealCopied: 'Mahlzeit kopiert',
	copyFood: 'Essen kopieren',
	copyItems: 'Elemente kopieren',
	copyPlus: 'Kopieren +',
	copyAll: 'Kopiere alles',
	copied: 'Kopiert',
	pending: 'Steht aus',
	manageFoods: 'Lebensmittel verwalten',
	manageMeals: 'Mahlzeiten verwalten',
	macros: 'Makros',
	ingredients: 'Zutaten',
	directions: 'Richtungen',
	nutrition: 'Ernährung',
	nutrients: 'Nährstoffe',
	save: 'Sparen',
	cancel: 'Stornieren',
	goback: 'Geh zurück',
	close: 'Schließen',
	delete: 'Löschen',
	saveas: 'Speichern als',
	add: 'Hinzufügen',
	confirm: 'Bestätigen',
	addfavorite: 'Favorit hinzufügen',
	removeFavorite: 'Favorit entfernen',
	remove: 'Entfernen',
	removed: 'Entfernt',
	added: 'Hinzugefügt',
	clear: 'Klar',
	send: 'Senden',
	required: 'Erforderlich',
	macrosAddedToMeal: 'Zum Essen hinzugefügte Makros',
	ingredientUpdated: 'Zutat aktualisiert',
	addtoMeal: 'Zum Essen hinzufügen',
	saveMeal: 'Mahlzeit speichern',
	addedToMeal: 'Zum Essen hinzugefügt',
	mealSaved: 'Mahlzeit gespeichert',
	mealsAdded: 'Mahlzeiten hinzugefügt',
	addTo: 'Hinzufügen',
	addedToLog: 'Zum Protokoll hinzugefügt',
	saveAsFood: 'Als Lebensmittel speichern',
	updateEntry: 'Eintrag aktualisieren',
	updateIngredient: 'Zutat aktualisieren',
	otherTrackingUpdated: 'Sonstiges Tracking aktualisiert',
	nameEmpty: 'Name leer',
	pleaseEnterName: 'Bitte geben Sie einen Namen ein',
	selectDate: 'Datum auswählen',
	foodNotFound: 'Essen nicht gefunden',
	deleted: 'Gelöscht',
	deleteConfirm: 'Sind Sie sicher, dass Sie löschen möchten?',
	addedToFavorites: 'zu Favoriten hinzugefügt',
	favoritesCleared: 'Favoriten gelöscht',
	removedFromFavorites: 'Aus Favoriten entfernt',
	savedToFoods: 'In Lebensmitteln gespeichert',
	updated: 'Aktualisiert',
	multiAdd: 'Multi-Add',
	noThanks: 'Nein Danke',
	giveFeedback: 'Feedback geben',
	rateAppName: 'MyKeto bewerten',
	never: 'Noch nie',
	later: 'Später',
	rate: 'Bewertung',
	rateDesc:
		'Wenn Sie MyKeto gerne verwenden, würde es Ihnen etwas ausmachen, sich eine Sekunde Zeit zu nehmen, um es zu bewerten? Es dauert nicht länger als eine Minute. Vielen Dank für Ihre Unterstützung!',
	rateFeed:
		'Würde es Ihnen etwas ausmachen, uns Feedback zu geben, was Sie in unserer App sehen möchten? Danke!',
	basics: 'Grundlagen',
	benefits: 'Leistungen',
	sideeffects: 'Nebenwirkungen',
	myths: 'Mythen',
	faq: 'FAQ',
	faq1: 'Häufig gestellte Fragen',
	getstarted: 'Loslegen',
	fiber: 'Ballaststoff',
	tips: 'Tipps',
	products: 'Empfohlene Produkte',
	basicsh1: 'Was ist die Keto-Diät?',
	basicsh2: 'Wie funktioniert es?',
	basicsh3: 'Warum Keto?',
	basicsp1:
		'Die ketogene oder Ketodiät ist einer kohlenhydratarmen Diät wie der Atkins sehr ähnlich. Der Hauptunterschied zwischen einem regulären kohlenhydratarmen Diätplan und einem ketogenen Diätplan besteht in der Menge an Kohlenhydraten und Proteinen, die täglich zugelassen wird.  N  n Im Allgemeinen senken kohlenhydratarme Diäten die Kohlenhydrataufnahme auf unter 100 Gramm. Strenge ketogene Diäten sind eine Untergruppe von kohlenhydratarmen Diäten, die normalerweise nur bis zu 50 g Kohlenhydrate pro Tag zulassen.',
	basicsp2:
		'Darüber hinaus ist die ketogene Diät eine fettreiche, proteinreiche und kohlenhydratarme Diät. Sie tauschen die Kohlenhydrate in der Ernährung mit einem höheren Fettanteil und einer moderaten Proteinaufnahme aus.',
	basicsp3:
		'Diese fettreiche Diät versetzt Ihren Körper in einen Zustand namens "Ketose", in dem der Körper Ketone in der Leber produziert, die als Energie verwendet werden.  N  nNormalerweise werden Kohlenhydrate in Glukose zerlegt, die Ihr Körper im Blut trägt, um sie zu geben Ihre Zellen Energie. Im Gegensatz dazu macht Ketose Ketone aus den hohen Mengen an Fett, die verbraucht werden, und das Vorhandensein großer Kohlenhydrate in der Nahrung wird die Ketose tatsächlich stoppen.',
	basicsp4:
		'Im Vergleich zur westlichen Ernährung, die hauptsächlich aus hochraffinierten Kohlenhydraten besteht, kann Keto viele gesundheitliche Vorteile bieten. Die meisten Menschen beginnen eine ketogene Diät, weil sie Gewicht verlieren wollen. Wissenschaftliche Untersuchungen legen jedoch nahe, dass dies noch weiter geht, beispielsweise die Senkung Ihres Risikos für Herzkrankheiten, Diabetes, Krebs, Schlaganfall und mehr.  N  nDie Ketodiät beinhaltet viel mehr als nur das Entfernen von Kohlenhydraten Lebensstil über die allgemeine Gesundheit. Es fördert lange, intensive Energieanfälle, eine Steigerung von gesundem, leckerem Essen und eine insgesamt bessere Sicht auf Ihr Leben. Es ist mit einem Übermaß an Optionen leicht zu warten und oft eine Antwort auf die Verbesserung des Wohlbefindens, die viele Menschen zunächst nur schwer erfassen können.  N  nKeto ist schwierig und wird Ihre Willenskraft auf die Probe stellen, aber es verändert die Art und Weise, wie Sie sehen und darüber nachdenken sich selbst, Essen und Gesundheit im Allgemeinen.',
	basicsbq:
		'Die Nährstoffaufnahme bei einer ketogenen Diät beträgt in der Regel täglich etwa 70-75% der Kalorien aus Fett, 20-25% aus Eiweiß und 5-10% aus Kohlenhydraten.',
	benefitsh0: 'Vorteile',
	benefitsp0:
		'Ketogene Diäten haben eine lange Liste von Vorteilen und können bis zu einer Woche gesehen werden, während sie sich über einen Zeitraum von 3 Wochen allmählich entwickeln.',
	benefitsh1: '1. Gewichtsverlust',
	benefitsp1:
		'Wenn der Körper Fett als Hauptenergiequelle verbrennt, wird er es im Wesentlichen aus Ihren Fettreserven entnehmen, da Sie sich im Fasten befinden. Studien zeigen, dass Menschen mit kohlenhydratarmer Ernährung mehr Gewicht und schneller verlieren als Menschen mit fettarmer Ernährung. Dies gilt auch dann, wenn die fettarmen Diätetiker die Kalorien aktiv einschränken.',
	benefitsbq1:
		'Stark fettleibige Personen mit einer hohen Prävalenz von Diabetes oder dem metabolischen Syndrom verloren während einer sechsmonatigen kohlenhydratarmen Diät mehr Gewicht als bei einer kalorien- und fettarmen Diät, wobei sich die Insulinsensitivität und der Triglyceridspiegel auch danach relativ verbesserten Anpassung an die Menge des verlorenen Gewichts.',
	benefitsbq2:
		'Eine kohlenhydratarme Ernährung scheint eine wirksame Methode zur kurzfristigen Gewichtsabnahme bei übergewichtigen Jugendlichen zu sein und beeinträchtigt das Lipidprofil nicht.',
	benefitsbq3:
		'Eine einjährige multizentrische, kontrollierte Studie mit 63 übergewichtigen Männern und Frauen, die nach dem Zufallsprinzip entweder einer kohlenhydratarmen, proteinreichen, fettreichen Diät oder einer kalorienarmen, kohlenhydratreichen, fettarmen (konventionellen) Diät unterzogen wurden ) Diät. Die kohlenhydratarme Diät führte in den ersten sechs Monaten zu einem größeren Gewichtsverlust (absoluter Unterschied, ungefähr 4 Prozent) als die konventionelle Diät, aber die Unterschiede waren nach einem Jahr nicht signifikant.',
	benefitsbq4:
		'Ein großer Prozentsatz des bei kohlenhydratarmen Diäten verlorenen Fettes stammt in der Regel aus dem schädlichen Fett in der Bauchhöhle, von dem bekannt ist, dass es schwerwiegende Stoffwechselprobleme verursacht.',
	benefitsbq5:
		'Eine kürzlich von der Johns Hopkins University School of Medicine durchgeführte Studie bestätigt, dass eine fettreiche, kohlenhydratarme Ernährung die Gefäßgesundheit nicht beeinträchtigt UND zu einem schnelleren Gewichtsverlust führt. Die Autoren bestätigten, dass die Diätetiker in der Gruppe mit niedrigem Kohlenhydratgehalt über einen kürzeren Zeitraum mehr Gewicht verloren als in der Gruppe mit höherem Kohlenhydratgehalt, und dass die Gruppe mit niedrigem Kohlenhydratgehalt keine schädlichen Veränderungen der Gefäßgesundheit aufwies.',
	benefitsh2: '2. Niedriger Blutdruck',
	benefitsp2:
		'Low Carb Diäten sind sehr effektiv bei der Senkung des Blutdrucks. Sprechen Sie mit Ihrem Arzt, wenn Sie Blutdruckmedikamente einnehmen, bevor Sie beginnen.',
	benefitsbq6:
		'In einer Stichprobe ambulanter medizinischer Patienten führte eine kohlenhydratarme Keto-Diät zu ähnlichen Verbesserungen wie die Orlistat-Arzneimitteltherapie in Kombination mit einer fettarmen Diät in Bezug auf Gewicht, Serumlipid und glykämische Parameter und war wirksamer zur Senkung des Blutdrucks.',
	benefitsh3: '3. Verbessertes Cholesterinprofil',
	benefitsp3:
		'Es wird gezeigt, dass es die Triglycerid- und Cholesterinspiegel verbessert, die am meisten mit dem Aufbau von Arterien verbunden sind.',
	benefitsbq7:
		'Ketogene Diäten erhöhen die Konzentrationen von herzgesundem HDL-Cholesterin mehr als fettarme, kohlenhydratreiche Diäten.',
	benefitsbq8:
		'Die Senkung des Kohlenhydratverbrauchs bei gesunden Personen führt auch zu einem höheren HDL-Cholesterinspiegel.',
	benefitsbq9:
		'Low-Carb, fettreiche Diäten senken die LDL-Partikelkonzentration (LDL-P) und erhöhen die Größe des LDL-Cholesterins.',
	benefitsbq10:
		'Ketogene Diäten verringern die Menge an schädlichem VLDL-Cholesterin im Blut.',
	benefitsh4: '4. Stabilisierte Behandlung von Blutzucker und Diabetes',
	benefitsp4:
		'Der beste Weg, um den Blutzucker- und Insulinspiegel zu senken, besteht darin, den Kohlenhydratverbrauch zu senken. Dies ist auch ein sehr wirksamer Weg, um Typ-II-Diabetes zu behandeln und möglicherweise sogar umzukehren.',
	benefitsbq11:
		'Die LCKD verbesserte die Blutzuckerkontrolle bei Patienten mit Typ-2-Diabetes, so dass Diabetes-Medikamente bei den meisten Teilnehmern abgesetzt oder reduziert wurden. Da die LCKD bei der Senkung des Blutzuckers sehr wirksam sein kann, sollten Patienten mit Diabetesmedikamenten, die diese Diät anwenden, unter strenger ärztlicher Aufsicht stehen oder in der Lage sein, ihre Medikamente anzupassen.',
	benefitsbq12:
		'Hoch gesättigte Fettsäuren mit sehr niedrigem Kohlenhydratgehalt (VLCARB) führen zu einem ähnlichen Fettabbau wie Diäten mit niedrigem Gehalt an gesättigten Fettsäuren, sind jedoch wirksamer bei der Verbesserung der Triacylglycerin-, HDL-C-, Fasten- und postprandialen Glukose- und Insulinkonzentrationen. VLCARB kann bei der kurzfristigen Behandlung von Patienten mit Insulinresistenz und Hypertriacylglycerolämie nützlich sein.',
	benefitsbq13:
		'Ernährungsumstellung führte bei motivierten Freiwilligen mit Typ-2-Diabetes zu Verbesserungen der Blutzuckerkontrolle und der Reduzierung / Elimination von Medikamenten. Die kohlenhydratarme Ernährung führte zu größeren Verbesserungen der Blutzuckerkontrolle und zu einer häufigeren Reduzierung / Eliminierung von Medikamenten als die Diät mit niedrigem Blutzuckerindex. Eine Änderung des Lebensstils durch kohlenhydratarme Interventionen ist wirksam zur Verbesserung und Umkehrung von Typ-2-Diabetes.',
	benefitsh5: '5. Mangel an Hunger',
	benefitsp5:
		'Ketonkörper dämpfen den Appetit, und Fett ist von Natur aus befriedigender und lässt uns länger in einem gesättigten, vollen Zustand zurück.',
	benefitsbq14:
		'Die Symptome von negativen Auswirkungen und Hunger verbesserten sich bei Patienten nach einer Keto-Diät stärker als bei Patienten nach einer fettarmen Diät.',
	benefitsh6: '6. Anti-Krebs',
	benefitsp6:
		'Keto kann bei der Bekämpfung bestimmter Krebsarten und verschiedener Tumoren helfen.',
	benefitsbq15:
		'Obwohl der Mechanismus, durch den ketogene Diäten in Kombination mit Standard-Radiochemotherapien Antikrebseffekte zeigen, nicht vollständig aufgeklärt wurde, haben präklinische Ergebnisse die Sicherheit und potenzielle Wirksamkeit der Verwendung ketogener Diäten in Kombination mit Radiochemotherapie zur Verbesserung der Reaktionen gezeigt in Mauskrebsmodellen. Diese präklinischen Studien haben den Anstoß gegeben, den Einsatz ketogener Diäten auf derzeit laufende klinische Phase-I-Studien auszudehnen.',
	benefitsh7: '7. Bessere Haut',
	benefitsp7:
		'Forscher sagen, dass Lebensmittel, die den Blutzuckerspiegel erhöhen, auch die Hormone erhöhen können. Die Hormone können die Ölproduktion stimulieren, was wiederum Akne auslösen kann. Die Keto-Diät verhindert Blutzuckerspitzen, indem Fett als Hauptenergiequelle verwendet wird.',
	benefitsbq16:
		'In einem im Journal der Akademie für Ernährung und Diätetik veröffentlichten Artikel überprüfen Burris und ihre Kollegen 27 Studien zu Ernährung und Akne. Einige der überzeugenderen Beweise stammen aus einigen kürzlich durchgeführten Studien mit Teenagern und jungen Männern (im Alter von 15 bis 25 Jahren), die Akne hatten. Nachdem die Teilnehmer eine Diät mit niedriger glykämischer Belastung befolgt hatten, dokumentierten die Forscher eine Abnahme der entzündlichen Akne-Läsionen. Die Studien waren klein, aber die Ergebnisse waren signifikant.',
	benefitsh8: '8. Verbesserte Zahngesundheit',
	benefitsp8:
		'Zucker (Kohlenhydrate) ist der Hauptverursacher von Zahnkrankheiten und Karies.',
	benefitsbq17:
		'Speisereste, insbesondere Kohlenhydrate, weisen kariesfördernde Eigenschaften auf, die eine Fermentation und Säureakkumulation in Bakterienplaque ermöglichen. Es verbessert die vier zuvor erwähnten Aktivitäten des Biofilms - Implantation, Kolonisierung, Stoffwechselaktivität und Dicke. Wenn Kohlenhydrate in einer Diät eingeschränkt sind, begrenzt dies einen wesentlichen Faktor für die Entwicklung von Karies.',
	benefitsh9: '9. Stimmungsstabilisierung',
	benefitsp9:
		'Es hat sich gezeigt, dass Ketonkörper bei der Stabilisierung von Neurotransmittern wie Serotonin und Dopamin von Vorteil sind, was zu einer besseren Stimmungskontrolle führt.',
	benefitsbq18:
		'Die ketogene Diät, die ursprünglich in den 1920er Jahren eingeführt wurde, wurde kürzlich als Zusatzbehandlung für refraktäre Epilepsie, insbesondere bei Kindern, wiederbelebt. In dieser schwer zu behandelnden Population zeigt die Diät eine bemerkenswerte Wirksamkeit, wobei zwei Drittel eine signifikante Verringerung der Anfallshäufigkeit zeigen und ein Drittel nahezu anfallsfrei wird. Es gibt mehrere Gründe zu vermuten, dass die ketogene Diät auch als Stimmungsstabilisator bei bipolaren Erkrankungen nützlich sein kann. Dazu gehört die Beobachtung, dass mehrere krampflösende Interventionen das Ergebnis bei Stimmungsstörungen verbessern können. Darüber hinaus werden bei Probanden mit ketogener Ernährung vorteilhafte Veränderungen des Gehirn-Energie-Profils festgestellt. Dies ist wichtig, da der globale zerebrale Hypometabolismus ein Merkmal des Gehirns von depressiven oder manischen Personen ist. Schließlich wird erwartet, dass die extrazellulären Veränderungen, die bei der Ketose auftreten, die intrazellulären Natriumkonzentrationen senken, eine gemeinsame Eigenschaft aller wirksamen Stimmungsstabilisatoren. Versuche der ketogenen Ernährung zur Rückfallprävention bipolarer Stimmungsepisoden sind gerechtfertigt.',
	benefitsh10: '10. Mögliche erhöhte Energie',
	benefitsp10:
		'Es wird gezeigt, dass Fette das effektivste Molekül sind, um als Brennstoff zu verbrennen.',
	benefitsbq19:
		"Während des metabolischen Stresses dienen Ketone als alternative Energiequelle, um den normalen Stoffwechsel der Gehirnzellen aufrechtzuerhalten. Tatsächlich kann BHB (ein Hauptketon) ein noch effizienterer Kraftstoff als Glukose sein und mehr Energie pro verwendeter Sauerstoffeinheit liefern. Eine ketogene Ernährung erhöht auch die Anzahl der Mitochondrien, sogenannte 'Energiefabriken“ in Gehirnzellen. Eine kürzlich durchgeführte Studie fand eine verstärkte Expression von Genen, die für mitochondriale Enzyme und den Energiestoffwechsel kodieren, im Hippocampus, einem Teil des Gehirns, der für Lernen und Gedächtnis wichtig ist. Hippocampuszellen degenerieren häufig bei altersbedingten Gehirnerkrankungen, was zu kognitiven Dysfunktionen und Gedächtnisverlust führt. Mit einer erhöhten Energiereserve können Neuronen möglicherweise Krankheitsstressoren abwehren, die normalerweise die Zelle erschöpfen und abtöten würden.",
	sideeffectsh0: 'Gefahren',
	sideeffectsp0:
		'Vorsichtshalber sollten Sie sich immer an Ihren Hausarzt wenden, bevor Sie eine ketogene Diät beginnen. Sie sollten besonders müde sein, wenn Sie oder jemand in Ihrer Familie bereits an Nieren-, Herz- oder Diabeteserkrankungen leiden.',
	sideeffectsh1: 'Osteoporose und Nierensteine',
	sideeffectsp1:
		'Es wurde auch gezeigt, dass proteinreiche Diäten dazu führen, dass Menschen eine große Menge Kalzium in ihren Urin ausscheiden. Über einen längeren Zeitraum kann dies das Risiko einer Person für Osteoporose und Nierensteine ​​erhöhen. Eine Diät, die das Protein auf Kosten erhöht einer sehr restriktiven Aufnahme von pflanzlichen Kohlenhydraten kann schlecht für die Knochen sein, aber nicht unbedingt eine proteinreiche Aufnahme allein. ',
	sideeffectsh2: 'Nierenversagen',
	sideeffectsp2:
		'Der Konsum von zu viel Protein belastet die Nieren, was eine Person anfällig für Nierenerkrankungen machen kann.',
	sideeffectsh3: 'Schilddrüse, Herz, Blutdruck',
	sideeffectsp3:
		'Es gibt nur begrenzte Forschungsergebnisse, die darauf hindeuten könnten, dass Ketose bei einer kleinen Untergruppe von Menschen einen kurzlebigen Anstieg des Adrenalin- und Cortisolspiegels im Körper verursachen kann. Diese erhöhten Hormonspiegel können wiederum Schilddrüsenprobleme und Herzunregelmäßigkeiten verursachen und erhöhter Blutdruck für einige. ',
	sideeffectsh4: 'Nebenwirkungen',
	sideeffectsp4:
		'Wenn Ihr Körper in einen ketogenen Zustand versetzt wird, wird Ihr Körper auf natürliche Weise die Reste Ihrer Glukose verwenden. Dadurch wird der Körper von jeglicher Glukosespeicherung in den Muskeln erschöpft, was zu Energiemangel und allgemeiner Lethargie führen kann. In den ersten Wochen berichten die Leute: ',
	sideeffectsbq1:
		'"In den ersten Tagen bis zu einigen Wochen der kohlenhydratarmen Anpassung setzt Ihr Körper die Kohlenhydrat-Arbeiterenzyme ab und baut neue Fettarbeiter-Enzyme auf. Sobald die Belegschaft in Ihrem Körper ausgeschaltet ist, beginnen Sie, auf Ihrem neuen niedrigen Niveau richtig zu funktionieren." -Carb, fettreichere Ernährung. Die Kohlenhydrate, die Sie früher zur Energiegewinnung verwendet haben, werden jetzt weitgehend durch Ketone ersetzt ... "- Mike Eades MD',
	sideeffectsh5: 'Keto-Grippe',
	sideeffectsp5: 'Grippeähnliche Symptome',
	sideeffectsh6: 'Kopfschmerzen',
	sideeffectsp6:
		'Ketose kann bei manchen Menschen Kopfschmerzen verursachen und Sie fühlen sich möglicherweise ein wenig benommen.',
	sideeffectsh7: 'Müdigkeit und Schwindel',
	sideeffectsp7:
		'Wenn Sie anfangen, Wasser abzulassen, verlieren Sie Mineralien wie Salz, Kalium und Magnesium. Wenn Sie weniger Mineralien haben, werden Sie sehr, sehr müde, benommen oder schwindelig, bekommen Muskelkrämpfe und Kopfschmerzen. Dies ist eine davon die häufigsten Nebenwirkungen der kohlenhydratarmen Ernährung, und sie können größtenteils vermieden werden, indem sichergestellt wird, dass Sie genügend Ersatzmineralien erhalten. ',
	sideeffectsh8: 'Hypoglykämie-Episoden',
	sideeffectsp8:
		'Der Körper ist es gewohnt, eine bestimmte Menge Insulin freizusetzen, um sich um die Glukose zu kümmern, die aus all den Kohlenhydraten entsteht, die Sie essen. Wenn Sie Ihre Kohlenhydrataufnahme tanken, kann es anfangs gelegentlich zu Anfällen mit niedrigem Blutzucker kommen.',
	sideeffectsh9: 'Geistige Nebelhaftigkeit',
	sideeffectsp9:
		'Es ist nicht ungewöhnlich, dass es zu Beginn an geistiger Klarheit mangelt. In den meisten Fällen hält der Gehirnnebel nur wenige Tage an.',
	sideeffectsh10: 'Häufiges Wasserlassen',
	sideeffectsp10:
		'Nach dem Beginn werden Sie feststellen, dass Sie häufiger auf die Toilette gehen. Der Körper verbrennt die übrig gebliebene Glukose (aus der Speicherform von Glykogen) in Ihrer Leber und Ihren Muskeln. Durch den Abbau von Glykogen wird viel Wasser und Ihre Nieren freigesetzt Wenn Ihr zirkulierender Insulinspiegel sinkt, beginnen die Nieren außerdem, überschüssiges Natrium auszuscheiden, was auch zu häufigerem Wasserlassen führt. ',
	sideeffectsh11: 'Verstopfung',
	sideeffectsp11:
		'Dies ist eine der häufigsten Nebenwirkungen und ist normalerweise eine Folge von Dehydration, Salzverlust und Magnesiummangel. Achten Sie darauf, hydratisiert zu bleiben und den ganzen Tag über viel Wasser zu trinken. Denken Sie auch daran, genügend Ballaststoffe aus Gemüse zu essen, und Sie können sogar täglich einen oder zwei Teelöffel Flohsamenschalenfasern einnehmen.',
	sideeffectsh12: 'Carb Cravings',
	sideeffectsp12:
		'Der Körper passt sich immer noch dem Leben ohne Kohlenhydrate wie Zucker an. Etwa eine Woche lang besteht ein starkes Verlangen nach stärkehaltigen Lebensmitteln wie Brot.',
	sideeffectsh13: 'Durchfall',
	sideeffectsp13:
		'Dies passiert normalerweise Menschen, die sich unklugerweise dazu entschließen, ihre Fettaufnahme durch eine kohlenhydratarme Ernährung zu begrenzen, was dazu führt, dass sie zu viel Protein essen.',
	sideeffectsh14: 'Muskelkrämpfe',
	sideeffectsp14:
		'Mineralverlust, insbesondere Magnesium, trägt am wahrscheinlichsten zu Krämpfen bei.',
	sideeffectsh15: 'Fitness- und Übungsstufen',
	sideeffectsp15:
		'Zu Beginn ist es normal, etwas Kraft und Ausdauer zu verlieren. Machen Sie sich keine Sorgen, sobald sich Ihr Körper an die Ketose gewöhnt hat, können Sie die Fette vollständig als primäre Energiequelle nutzen. Zahlreiche Studien zeigen, dass dies der Fall ist ist kein langfristiger Leistungsabfall für Sportler. ',
	sideeffectsh16: 'Elektrolyte',
	sideeffectsp16:
		'Da Ketose eine harntreibende Wirkung auf den Körper hat, sind die Nebenwirkungen meistens das Ergebnis des Ausspülens von Elektrolyten. Stellen Sie sicher, dass Sie viel Wasser trinken und Ihre Salz- / Natriumaufnahme aufrecht erhalten. Sie sollten mit all Ihren Lebensmitteln salzverrückt werden. Dies hilft bei der Wasserretention und hilft beim Auffüllen von Elektrolyten.',
	sideeffectsh17: 'Fazit',
	sideeffectsp17:
		'Diese ketogenen Nebenwirkungen sind eigentlich nur geringfügige Probleme und treten nur über einen Zeitraum der ersten Wochen auf. Sie werden besser und klingen schließlich ab, und dann werden Sie die vollen Vorteile der Ketodiät erfahren.',
	mythsh0: 'Der dickste Mythos',
	mythsp0:
		'Die Mehrheit der Menschen, die die Ketodiät aufgeben, scheitert, weil sie sich nicht wohl fühlen, wenn sie ihre Fettaufnahme massiv erhöhen, insbesondere gesättigte Fettsäuren.',
	mythsh1: 'High Carbs + High Fat = Bad',
	mythsp1:
		'High Carb Diäten erhöhen den Blutzucker- und Insulinspiegel. Hohe Zucker- und Insulinspiegel korrelieren mit hohen Entzündungen. Die meisten amerikanischen Diäten sind reich an Zucker und reich an gesättigten Fettsäuren. Bei der Untersuchung wurden diese beiden Faktoren miteinander kombiniert und nicht getrennt untersucht.  N  nGesättigtes Fett wurde für die Entzündung verantwortlich gemacht, die Herzkrankheiten verursacht, da es in Kombination mit einer kohlenhydratreichen Diät untersucht wurde. Ketogene Diäten, die reich an gesättigten Fettsäuren und sehr kohlenhydratarm sind, minimieren Entzündungen.',
	mythsbq1:
		'Eine John Hopkins-Studie bestätigte, dass gesättigte Fettsäuren im Zusammenhang mit einer kohlenhydratarmen Ernährung nicht schädlich sind und die Ursache für Herzerkrankungen ein chronisch hoher Kohlenhydratverbrauch ist, nicht eine hohe Aufnahme von gesättigten Fettsäuren und Cholesterin. Mit anderen Worten, Fett macht uns fett, wenn es mit einem hohen Gehalt an Kohlenhydraten kombiniert wird.',
	mythsh2: 'Was ist mit Protein?',
	mythsp2:
		'Für die Ketose ist es wichtig, dass Ihr Proteinspiegel den von Kalorien in Kalorien und nicht von Gewicht nicht überschreitet. Dies liegt daran, dass Protein in übermäßigen Mengen sehr ähnlich wie Kohlenhydrate wirkt. Sie werden schließlich in Glukose zerlegt, die dann Ihren Blutzucker erhöht und einen Insulinanstieg verursacht.',
	mythsh3: 'Ketose ist gefährlich und schädlich',
	mythsp3:
		'Ketose, ein vorteilhafter Stoffwechselzustand, wird oft mit Ketoazidose verwechselt, die etwas völlig anderes und eine schwerwiegende Erkrankung ist. Ketoazidose wird dadurch verursacht, dass der Körper die Produktion von Ketonen nicht richtig reguliert. Sie tritt am häufigsten bei Alkoholikern und Typen auf 1 Diabetiker. ',
	mythsh4: '"Es ist nicht wartbar"',
	mythsp4:
		'Das Festhalten an der Diät kann einfacher sein als Sie denken. Wenn Sie viel auswärts essen, insbesondere Fast Food, besteht die Versuchung der Bequemlichkeit und Sie haben möglicherweise einige Schwierigkeiten. Das Gleiche gilt, wenn Sie viel Stärke und Zucker zu sich nehmen Essen in Ihrer Speisekammer. Wenn Sie sich jedoch von diesen Lebensmitteln fernhalten und sich diesen nicht aussetzen, werden Sie es kaum bemerken. Das Verlangen wird nach den ersten ein oder zwei Wochen nachlassen, wenn nicht früher.',
	mythsh5: 'Sie können nicht alle Ihre Vitamine bekommen',
	mythsp5:
		'Bei einer richtigen ketogenen Ernährung erhält man weitaus mehr Vitamine und Mineralien als bei einer typischen Ernährung. Die Hauptausnahme ist Vitamin C, das nur in mehr als Spurenmengen benötigt wird, wenn Sie eine kohlenhydratreiche Ernährung einhalten. Essen Blattgemüse wird jedoch mehr als genug Vitamin C sein. ',
	mythsh6: 'Keto wird Ihnen Nierenschäden zufügen',
	mythsp6:
		'Menschen mit sehr fortgeschrittener Nierenerkrankung haben Probleme beim Umgang mit großen Mengen an Nahrungsprotein. Es gibt jedoch keine Forschung darüber, dass eine proteinreiche Ernährung Nierenerkrankungen verursacht. Es besteht möglicherweise ein erhöhtes Risiko für Nierensteine ​​aufgrund eines erhöhten Harnsäurespiegels in der Blut, aber das lässt sich leicht vermeiden, indem Sie viel Flüssigkeit trinken und wenn Sie besonders vorsichtig sein möchten, Kaliumcitrat-Präparate einnehmen. ',
	mythsh7: '"Ich will mich nicht verhungern"',
	mythsp7:
		'Fett und Eiweiß werden viel langsamer verdaut als Kohlenhydrate. Sie werden also feststellen, dass Sie längere Zeit ohne Hunger leben.',
	mythsh8: 'Keto gibt dir schlechten Atem',
	mythsp8:
		'Wenn Sie in Ketose sind, ist dies sicherlich in Ihrem Atem erkennbar. Was Sie riechen, sind Ketone, fruchtig riechende Moleküle, die aus Fett umgewandelt werden, um von den Zellen als Brennstoff verwendet zu werden. Wenn Sie in Ketose sind, ist es tatsächlich ziemlich befriedigend zu wissen, dass Sie tatsächlich Kalorien ausatmen! Kauen Sie einfach etwas zuckerfreien Kaugummi, wenn es Sie stört. ',
	getstartedh0: 'Wie man anfängt',
	getstartedp0:
		'Insgesamt hat der Verzehr einer hohen Menge an Fett, mäßigem Eiweiß und einer begrenzten Menge an Kohlenhydraten einen massiven Einfluss auf Ihre Gesundheit - eine Senkung Ihres Cholesterins, Ihres Körpergewichts, Ihres Blutzuckers sowie eine Steigerung Ihres Energie- und Stimmungsniveaus.  n  nWenn Sie in der Denkweise sind, einen realistischeren und gesünderen Ansatz zum Abnehmen von Körperfett zu entwickeln, scheint ein kohlenhydratarmer Lebensstil nicht so schlecht zu sein. Nehmen Sie sich ein paar Wochen Zeit, um den anfänglichen Buckel zu überwinden, und diese lästigen Nebenwirkungen lassen nach.  n  nEin wichtiger Punkt bei einer Ketodiät: Seien Sie darauf vorbereitet, mehr Zeit in der Küche zu verbringen. Ein Keto-Menü beinhaltet das Kochen und Essen von echten Lebensmitteln. Wenn Sie noch nicht kochen können, ist dies eine gute Lernerfahrung für Sie.  n  nSie sollten den Taschenrechnerabschnitt dieser App verwenden, um Ihre unten genannten spezifischen Makros zu berechnen.',
	getstartedh1:
		'1. Füllen Sie Ihre Küche so auf, dass Sie keine Versuchungen haben.',
	getstartedh2:
		'2. Reduzieren Sie den täglichen Kohlenhydratverbrauch auf etwa 20 g, jedoch ist alles unter 50 g akzeptabel.',
	getstartedh3: '3. Erhöhen Sie den Fettanteil in Ihrer Ernährung erheblich.',
	getstartedh4:
		'4. Bringen Sie die Proteinaufnahme auf 0,6 bis 1 g pro Pfund magerer Körpermasse. (1,3 bis 2,2 g pro kg).',
	getstartedh5: '5. Erhöhen Sie den Wasserverbrauch erheblich.',
	getstartedh6:
		'6. Erhöhung der Aufnahme von Elektrolyten wie Salz, Kalium und Magnesium.',
	fiberh0: 'Faser - Holen Sie sich einige!',
	fiberh1: 'Keto-Faserquellen umfassen:',
	fiberh2: 'Leinsamen',
	fiberh3: 'Chia Seeds',
	fiberh4: 'Gemüse, das nah an allen Ballaststoffen ist',
	fiberh5: 'Faserzusätze',
	fiberh6: 'Mehr Ballaststoffe als verwendbare Kohlenhydrate:',
	fiberh7: 'Weizenkleie',
	fiberh8: 'Ungesüßte Kokosnuss und Kokosmehl',
	fiberh9: 'High Fibre Cereals',
	fiberh10: 'Collard Greens',
	fiberh11: 'Avocado, Hass',
	fiberh12: 'Spinat und Mangold',
	fiberh13: 'Brokkoli',
	fiberh14: 'Blumenkohl',
	fiberh15: 'Brombeeren',
	fiberh16: 'Ungefähr so ​​viel verwendbarer Kohlenhydrat wie Faser:',
	fiberh17: 'Spargel',
	fiberh18: 'Sellerie',
	fiberh19: 'Aubergine',
	fiberh20: 'Salat, Romaine',
	fiberh21: 'Pilze',
	fiberh22: 'Radieschen',
	fiberh23: 'Rote Himbeeren',
	fiberh24: 'High Fibre, aber nicht so viel Fiber wie Usable Carb:',
	fiberh25: 'Reiskleie',
	fiberh26: 'Kohl',
	fiberh27: 'Paprika',
	fiberh28: 'Schneeerbsen (essbare Schote)',
	fiberh29: 'Zucchini Squash',
	fiberh30: 'Erdbeeren',
	fiberp0:
		'Faser ist eine Zuckerform namens Cellulose. Wir haben nicht die notwendigen Enzyme, um Cellulose abzubauen, daher aktiviert es die Glykolyse nicht und beeinflusst den Insulinspiegel nicht. Ballaststoffreiche Lebensmittel können sicher mit Keto gegessen werden und sollten den ganzen Tag über konsumiert werden. Es ist wichtig zu beachten, dass Ballaststoffe NICHT für Ihre Kohlenhydrataufnahme zählen.  N  n Wenn Ihre Mahlzeit beispielsweise insgesamt 10 g Kohlenhydrate und 5 g Ballaststoffe enthält, beträgt die tatsächliche Kohlenhydrataufnahme für die Mahlzeit 5 g (10 g bis 5 g). . Obwohl Ballaststoffe ein Kohlenhydrat sind, werden sie nicht als einfaches Kohlenhydrat verdaut und sind daher nicht in Ihrer täglichen Kohlenhydratzahl enthalten.',
	fiberp1:
		'Es ist wichtig zu betonen, dass Ballaststoffe keine Kohlenhydrate NEGIEREN - sie werden einfach nicht gezählt. Das Mischen einer Handvoll Flachsmehl in eine Schüssel Eiscreme funktioniert also nicht!',
	fiberp2:
		'Die meisten Menschen beziehen ihre Ballaststoffe aus Getreide, und die Eliminierung dieser Lebensmittelgruppe für Ihre ketogene Ernährung kann zu einer erheblichen Verringerung Ihrer täglichen Ballaststoffaufnahme führen.',
	fiberp3:
		'Die Ernährungsrichtlinien für Amerikaner von 2010 empfehlen, dass Sie zwischen 25 g und 38 g Ballaststoffe pro Tag erhalten, aber jemand, der eine Ketodiät hat, benötigt möglicherweise mehr.',
	fiberp4:
		'In Leinsamen ist fast kein brauchbares Kohlenhydrat enthalten. Es ist sehr reich an löslichen und unlöslichen Ballaststoffen (etwa ein Drittel der Ballaststoffe ist löslich) und hat noch einen Haufen Nährstoffe. Flachs ist eine der besten Ballaststoffquellen für Ihre Ernährung.',
	fiberp5: 'Haben Sie ein Faser- und Kohlenhydratprofil ähnlich wie Leinsamen.',
	fiberp6: 'Senfgrün, Chicorée, Endivie',
	fiberp7: 'Wie Flohsamenschalenpulver',
	fiberp8:
		'½ Tasse roh, 3 Gramm verwendbarer Kohlenhydrat, 6 Gramm Ballaststoffe',
	fiberp9: '1 Unze, 2 Gramm verwendbarer Kohlenhydrat, 5 Gramm Ballaststoffe',
	fiberp10:
		'Überprüfen Sie die Etiketten sorgfältig, aber einige Getreide mit hohem Fasergehalt enthalten auch wenig oder ziemlich wenig Kohlenhydrate. Beispiele - Alle Kleie mit extra Faser; Fiber One',
	fiberp11:
		'1 Tasse gehackt, gekocht, 4 Gramm verwendbarer Kohlenhydrat, 5 Gramm Ballaststoffe',
	fiberp12:
		'1 mittlere Avocado, 3 Gramm verwendbarer Kohlenhydrat, 12 Gramm Ballaststoffe',
	fiberp13:
		'Gefrorene 1 10 Unzen Packung, 10 g Gesamtkohlenhydrat, 6 g Ballaststoffe. 6 Tassen roher Spinat oder Mangold = ca. 1 Tasse gekocht',
	fiberp14:
		'1/2 Tasse gehackt, gekocht, 1 Gramm verwendbarer Kohlenhydrat, 3 Gramm Ballaststoffe. 1 Tasse gehackt, roh, 4 Gramm verwendbarer Kohlenhydrat, 2 Gramm Ballaststoffe',
	fiberp15:
		'1/2 Tasse Stücke, gekocht, 1 Gramm verwendbarer Kohlenhydrat, 2 Gramm Ballaststoffe. 1 Tasse roh, 2 Gramm verwendbarer Kohlenhydrat, 2,5 Gramm Ballaststoffe',
	fiberp16:
		'1 Tasse, roh, 6 Gramm verwendbarer Kohlenhydrat, 8 Gramm Ballaststoffe',
	fiberp17:
		'1/2 Tasse Stücke, 2 Gramm verwendbare Kohlenhydrate, 2 Gramm Ballaststoffe',
	fiberp18:
		'1 Tasse gehackt, 1,5 Gramm verwendbarer Kohlenhydrat, 1,5 Gramm Ballaststoffe',
	fiberp19:
		'1 Tasse rohe, gewürfelte, 2 Gramm verwendbare Faser, 3 Gramm Faser. 1 Tasse gewürfelt, gekocht, 5 Gramm verwendbarer Kohlenhydrat, 3 Gramm Ballaststoffe',
	fiberp20:
		'1 Tasse zerkleinert, 0,5 Gramm verwendbarer Kohlenhydrat, 1 g Faser',
	fiberp21:
		'1 Tasse, geschnitten, roh, 1 Gramm verwendbarer Kohlenhydrat, 1 Gramm Faser',
	fiberp22:
		'1 Tasse roh, in Scheiben geschnitten, 2 Gramm verwendbarer Kohlenhydrat, 2 Gramm Ballaststoffe',
	fiberp23:
		'1 Tasse, roh, 7 Gramm verwendbarer Kohlenhydrat, 8 Gramm Ballaststoffe',
	fiberp24:
		'1/4 Tasse 8 Gramm verwendbarer Kohlenhydrat, 6 Gramm Ballaststoffe',
	fiberp25:
		'1 Tasse roh, gehackt, 3 Gramm verwendbarer Kohlenhydrat, 2 Gramm Ballaststoffe. 1/2 Tasse gekocht, gehackt, 2 Gramm verwendbarer Kohlenhydrat 1 Gramm Faser',
	fiberp26:
		'1 Tasse gehackt, roh, 4 Gramm verwendbarer Kohlenhydrat, 3 Gramm Ballaststoffe',
	fiberp27:
		'1 Tasse ganz, roh, 3 Gramm verwendbarer Kohlenhydrat, 2 Gramm Ballaststoffe',
	fiberp28:
		'1 Tasse gekocht, in Scheiben geschnitten, 4 Gramm verwendbarer Kohlenhydrat, 3 Gramm Ballaststoffe',
	fiberp29:
		'1/2 Tasse in Scheiben geschnitten, 5 Gramm verwendbarer Kohlenhydrat, 2 Gramm Ballaststoffe',
	tipsh1: 'Machen Sie einen Kohlenhydrat-Sweep.',
	tipsp1:
		'Überprüfen Sie die Küchenschränke und den Kühlschrank und entfernen Sie alle kohlenhydratreichen Lebensmittel. Dies wird Versuchungen später vermeiden.',
	tipsh2:
		'Lassen Sie sich vor Beginn der Diät einer Gesundheitsuntersuchung und einem Bluttest unterziehen.',
	tipsp2:
		'Planen Sie weitere vier bis acht Wochen nach dem Start. Auf diese Weise können Sie die Auswirkungen der Änderung Ihrer Essgewohnheiten auf Cholesterin, Blutdruck und andere Gesundheitsmerkmale verfolgen.',
	tipsh3: 'Kaufen Sie Ketostix.',
	tipsp3:
		'Um zu überprüfen, ob Sie an Ketose leiden oder nicht. Ketostix soll messen, wie viel Ketone sich in Ihrem Urin befinden. In letzter Zeit ist ein Blutketonmessgerät erhältlich, mit dem die Ketose zu Hause verfolgt werden kann. Dies ist eine viel genauere Methode, um Ihre individuellen Ketonwerte zu verfolgen.',
	tipsh4: 'Denken Sie über Ihre Mahlzeiten nach und wie Sie sie planen werden.',
	tipsp4:
		'Dies hilft beim Kauf der richtigen Lebensmittel im Lebensmittelgeschäft und gibt einen Rahmen vor, der eingehalten werden muss, wenn die Essenszeit eintrifft. Wenn Sie wissen, dass Sie heute Abend Lachs und Brokkoli zum Abendessen haben sollen, ist es viel einfacher, die Auswahl zu vermeiden die alten kohlenhydratreichen Lebensmittel, die Sie früher gegessen haben. ',
	tipsh5: 'Trinke genug.',
	tipsp5:
		'Wenn die Kohlenhydrataufnahme gesenkt wird, beginnen die Nieren, das überschüssige Wasser, das der Körper aufgrund einer hohen Kohlenhydrataufnahme gehalten hat, abzulassen. Stellen Sie sicher, dass Sie genug Wasser trinken, um das zu ersetzen, was verloren geht.',
	tipsh6: 'Denken Sie an soziale Situationen, die auftreten werden.',
	tipsp6:
		'Erarbeiten Sie Möglichkeiten, um mit den Zeiten umzugehen, in denen die Versuchung groß ist, den "alten" Weg zu gehen. Sie möchten nicht blind sein, wenn jemand im Büro eine Schachtel mit Ihren Lieblingspralinen hereinbringt und diese direkt unter Ihre Nase legt. Ebenso wird ein Bier mit Ihren Freunden normalerweise zu einem Date mit Kartoffelschalen und Nachos. Denken Sie stattdessen an Salat und Steak.',
	tipsh7: 'Konzentriere dich nicht auf dein Gewicht.',
	tipsp7:
		'Wiegen Sie sich nicht jeden Tag. Ihr Gewicht kann aufgrund von Änderungen der Wasseraufnahme und -aufnahme zwischen 2 und 4 Pfund pro Tag variieren. Sie können den Fettabbau nicht täglich genau verfolgen, und die Schwankungen werden es tun." mach dich verrückt, wenn du dich auf sie konzentrierst. Wiege dich stattdessen einmal pro Woche oder verfolge deine Körpermaße, um deinen Fortschritt zu verfolgen.',
	makes: 'Macht',
	servings: 'Portionen',
	fat: 'Fett',
	carbs: 'Kohlenhydrate',
	totalcarb: 'Total Carbs',
	netcarb: 'Net Carbs',
	protein: 'Protein',
	calories: 'Kalorien',
	zeroResults: '0 Ergebnisse',
	loadMore: 'Mehr laden',
	likes: 'Likes',
	comments: 'Bemerkungen',
	netcarbFinder: 'Net Carb Finder',
	search: 'Suche',
	searchFilter: 'Suchfilter',
	barcode: 'Barcode',
	offline: 'Offline',
	results: 'Ergebnisse',
	barcodeNotRecognized: 'Barcode nicht erkannt',
	barcodeNotRecognizedDetail:
		'Versuchen Sie, die Datenbank zu durchsuchen oder als benutzerdefiniertes Lebensmittel hinzuzufügen.',
	clearAllRecentlyScanned: 'Alle kürzlich gescannten Daten löschen?',
	clearAllConfirm:
		'Dadurch werden Ihre kürzlich gescannten Barcodes auf allen synchronisierten Geräten gelöscht',
	requestCamera: 'Bitte um Kameraerlaubnis',
	noAccessCamera: 'Kein Zugang zur Kamera',
	scanBarcode: 'Barcode scannen',
	itemFound: 'Gegenstand gefunden',
	itemNotFoundFS: 'Gegenstand nicht im fetten Geheimnis gefunden',
	ItemNotFoundFSdesc:
		'Versuchen Sie, die Datenbank zu durchsuchen oder als benutzerdefiniertes Lebensmittel hinzuzufügen.',
	generic: 'Generisch',
	basedDiet: '% basierend auf einer 2000-Kalorien-Diät',
	all: 'Alles',
	alcohol: 'Alkohol',
	beverages: 'Getränke',
	cheeses: 'Käse',
	dairy: 'Molkerei',
	dressings: 'Dressings (Check Labels)',
	fatsoils: 'Fette und Öle',
	fish: 'Fisch',
	flours: 'Mehl oder Mahlzeiten',
	fowl: 'Geflügel',
	fruits: 'Früchte',
	grainsstarch: 'Getreide & Stärke',
	meats: 'Fleisch',
	nutslegumes: 'Nüsse und Hülsenfrüchte',
	seeds: 'Saat',
	shellfish: 'Schaltier',
	sugarssweetener: 'Zucker & Süßstoff',
	vegetables: 'Gemüse',
	calculatorDetails:
		'Wir benötigen einige Details, um Ihren persönlichen Keto-Plan zu erstellen',
	height: 'Höhe',
	weight: 'Gewicht',
	age: 'Alter',
	gender: 'Geschlecht',
	male: 'Männlich',
	female: 'Weiblich',
	exerciseLevel: 'Schwierigkeitsgrad',
	littleToNone: 'Wenig bis gar nichts',
	exerciseOne: '1-3 mal / Woche',
	exerciseTwo: '3-5 mal / Woche',
	exerciseThree: '6-7 mal / Woche',
	exerciseFour: '2 mal / Tag',
	goalWeight: 'Zielgewicht',
	faster: 'Schneller',
	slower: 'Langsamer',
	weightPlan: 'Ihr Gewichtsplan',
	maintain: 'Pflegen',
	easier: 'Einfacher',
	mild: 'Leicht',
	moderate: 'Mäßig',
	difficult: 'Schwer',
	customize: 'Anpassen',
	dailyCalories: 'Tägliche Kalorien',
	week: 'Woche',
	lose: 'Verlieren',
	by: 'durch',
	bodyFat: 'Körperfett',
	bodyFatDetails:
		'Ihr Körperfettanteil wird verwendet, um Ihre ideale Proteinmenge basierend auf Ihrer gesamten Muskelmasse zu berechnen.',
	proteinDetail:
		'Zu viel Protein kann die Ketose brechen. Sie brauchen also gerade genug, um die Muskeln zu erhalten, und ein bisschen mehr, um sie aufzubauen.',
	dailyProtein: 'Tägliches Protein',
	idealRange: 'Ihre ideale Reichweite ist',
	maintainLower: 'pflegen',
	build: 'bauen',
	maintainMuscle: 'Muskel aufrechterhalten',
	buildMuscle: 'Muskeln aufbauen',
	netcarbDescription:
		'Über 50 g Netto-Kohlenhydrate pro Tag zu essen, reicht aus, um die Ketose zu brechen. Die meisten Keto-Diätetiker essen zwischen 20 und 30 g. Je weniger Kohlenhydrate Sie essen, desto geringer ist die Wahrscheinlichkeit, dass Sie die Ketose brechen.',
	language: 'Sprache',
	languageUpdated: 'Sprache aktualisiert',
	english: 'Englisch',
	french: 'Französisch',
	spanish: 'Spanisch',
	portuguese: 'Portugiesisch',
	german: 'Deutsche',
	logout: 'Ausloggen',
	loggedOut: 'Abgemeldet',
	unitsUpdated: 'Einheiten aktualisiert',
	featureRequest: 'Featureanfrage',
	deleteAccount: 'Konto löschen',
	verifyDeleteMessage:
		'Dadurch werden Ihr Konto und alle Daten gelöscht. Sind Sie sicher?',
	accountDeleted: 'Konto gelöscht',
	net: 'Netz',
	total: 'Gesamt',
	preferredCarb: 'Bevorzugte Kohlenhydrate',
	preferredCarbDesc:
		'Die Gesamtkohlenhydrate enthalten Ballaststoffe und Zuckeralkohole, während die Nettokohlenhydrate nur zählbare Kohlenhydrate enthalten, die die Ketose beeinflussen. Die meisten Keto-Diätetiker verfolgen nur die Nettokohlenhydrate.',
	updateMacroGoals: 'Makroziele aktualisieren',
	defaultMacroGoalsUpdated: 'Standard-Makroziele aktualisiert',
	customGoalsApplied: 'Benutzerdefinierte Ziele für Daten',
	tracking: 'Verfolgung',
	recalculateMacroGoals: 'Makroziele neu berechnen',
	manuallyChangeMacroGoals: 'Makroziele manuell ändern',
	changePreferences: 'Einstellungen ändern',
	otherTracking: 'Andere Verfolgung',
	other: 'Andere',
	notes: 'Anmerkungen',
	invalidCode: 'Ungültiger Code',
	purchaseRestored: 'Kauf wiederhergestellt',
	userNotFound: 'Benutzer nicht gefunden',
	error: 'Error',
	errorProducts:
		'Beim Laden der Produkte ist ein Fehler aufgetreten. Stellen Sie sicher, dass Sie eine gültige Internetverbindung haben.',
	trackerEnabledAdsRemoved: 'Tracker aktiviert, Anzeigen entfernt',
	purchaseTracking: 'Kaufverfolgung',
	gettingProductDetails: 'Produktdetails abrufen ...',
	noPurchaseFound: 'Kein Kauf gefunden',
	noPurchaseFoundMore:
		'Auf diesem Konto wurde kein Kauf gefunden. Bitte mailen Sie uns, wenn Sie der Meinung sind, dass dies nicht korrekt ist.',
	account: 'Konto',
	signIn: 'Einloggen',
	logIn: 'Anmeldung',
	signedIn: 'Angemeldet',
	accountCreated: 'Account erstellt',
	signUp: 'Anmelden',
	email: 'Email',
	password: 'Passwort',
	forgotPassword: 'Passwort vergessen?',
	resetEmailSent: 'E-Mail zurückgesendet',
	newUser: 'Neuer Benutzer?',
	emailError: 'Geben Sie eine gültige E-Mail-Adresse ein',
	passwordError: 'Geben Sie ein Passwort ein',
	emailSubscribe: 'Holen Sie sich Updates und Sonderangebote von MyKeto',
	enterYourEmail: 'Geben sie ihre E-Mail Adresse ein',
	medicalDisclaimer: 'Medizinischer Haftungsausschluss',
	disclaim:
		'Medizinischer Haftungsausschluss - MyKeto wird nur zu Bildungs- und Unterhaltungszwecken angeboten und beabsichtigt in keiner Weise, medizinische oder andere Erkrankungen zu diagnostizieren, zu heilen oder zu behandeln. Lassen Sie sich immer von Ihrem Arzt oder einem anderen qualifizierten Gesundheitsdienstleister beraten, bevor Sie Ihre Ernährung oder Ihren Trainingsplan ändern, und stellen Sie Ihrem Arzt alle Fragen, die Sie möglicherweise zu einer Krankheit haben. Obwohl wir unser Bestes tun, um die Richtigkeit der hierin enthaltenen Informationen zu überprüfen, können wir deren Richtigkeit nicht garantieren.',
	skipAccount: 'Konto überspringen',
	registerAccount: 'Account registrieren',
	convertAccount: 'Konto in Login konvertieren',
	createAccountLater:
		'Sie können später ein Konto mit Ihren Daten erstellen, indem Sie zum Menü Einstellungen gehen',
	whyLogin: 'Warum einloggen?',
	usingAccount:
		'Durch die Verwendung eines Kontos werden Ihre Daten auf allen anderen Geräten gesichert und synchronisiert. Die Registrierung dauert nur eine Sekunde, und wir geben keine persönlichen Daten weiter oder speichern sie.',
	totalcarbError: 'Total kann nicht weniger als Fiber sein',
	auto: 'Auto',
	manual: 'Handbuch',
	name: 'Name',
	editingItem: 'Element bearbeiten',
	foods: 'Lebensmittel',
	meals: 'Mahlzeiten',
	meal: 'Mahlzeit',
	favorites: 'Favoriten',
	recent: 'Letzte',
	recentlyused: 'Kürzlich verwendet',
	saveToFoods: 'In Lebensmitteln speichern',
	searched: 'Gesucht',
	myfoods: 'Meine Lebensmittel',
	mymeals: 'Meine Mahlzeiten',
	quickmacro: 'Schnelle Makros',
	create: 'Erstellen',
	created: 'Erstellt',
	createFood: 'Lebensmittel erstellen',
	createMeal: 'Mahlzeit erstellen',
	ingredientNotFound:
		'Zutat nicht gefunden, versuchen Sie es erneut hinzuzufügen',
	totalServings: 'Gesamtportionen',
	totalServingsDesc: 'Bitte geben Sie eine Nummer für die Gesamtportionen ein',
	clearAllFavorites: 'Alle Favoriten löschen?',
	clearAllFavoritesDesc:
		'Dadurch werden alle Ihre Favoriten auf allen synchronisierten Geräten gelöscht',
	clearAllRecentlyUsed: 'Alle kürzlich verwendeten Elemente löschen?',
	clearAllRecentlyUsedConfirm:
		'Dadurch werden Ihre kürzlich protokollierten Elemente auf allen synchronisierten Geräten gelöscht',
	removedFromRecentlyUsed: 'Aus kürzlich verwendetem entfernt',
	itemsAdded: 'Elemente hinzugefügt',
	mealName: 'Mahlzeit Name',
	servingName: 'Serving Name',
	wholeRecipe: 'Ganzes Rezept',
	clickStart:
		'Sie sind fertig, klicken Sie auf die Schaltfläche unten, um loszulegen',
	privacyPolicy: 'Datenschutz-Bestimmungen',
	termsConditions: 'Geschäftsbedingungen',
	policyAgree: 'Wenn Sie unten klicken, stimmen Sie unserer zu',
	unitsOnboard:
		'Wählen Sie Ihre bevorzugten Einheiten für Körpermaße (Gewicht, Brustgröße usw.) und Wasser',
	clockOnboard:
		'Wählen Sie aus, wie Ihre Start- und Endzeitanzeige formatiert werden soll',
	scheduleOnboard:
		'Wählen Sie aus, ob Sie bei jedem Fensterwechsel Benachrichtigungen erhalten möchten und ob Sie vor Ablauf des Zeitraums eine Erinnerung wünschen.',
	manualOnboardSettings:
		'Wählen Sie Ihr schnelles Standardziel (Sie können dies schnell auf der Startseite ändern) und wenn Sie Ihr Ziel in Gesamtstunden auswählen möchten oder wenn Sie ein Datum und eine Uhrzeit für Ihr Ziel auswählen möchten.',
	manualOnboardNotifications:
		'Wählen Sie aus, ob Sie Benachrichtigungen erhalten möchten, z. B. eine bestimmte Erinnerung an das tägliche Fasten zu einer bestimmten Zeit und Erinnerungen, wann das Fasten beendet ist oder bald enden wird.',
	weightLoss: 'Gewichtsverlust',
	diseasePrevention: 'Krankheitsvorbeugung',
	lowerBloodPressure: 'Niedriger Blutdruck',
	improvedCholesterolProfile: 'Verbessertes Cholesterinprofil',
	antiAging: 'Anti-Aging & Langlebigkeit',
	insulinManagement: 'Insulin- und Diabetes-Management',
	antiCancer: 'Anti-Krebs',
	heartHealth: 'Herz Gesundheit',
	improvedSkin: 'Verbesserte Haut',
	dentalHealth: 'Zahngesundheit',
	whyKeto: 'Warum interessieren Sie sich für die ketogene Ernährung?',
	betterTailor:
		'Wir werden MyKeto besser anpassen, um Ihnen bei Ihren Zielen zu helfen',
	goals: 'Tore',
	goalsExplanation:
		'Wir werden diese Informationen verwenden, um Ihnen bei der Vorhersage Ihres Fortschritts zu helfen',
	finished: 'Fertig',
	startTracking: 'Start Tracking',
	weightUnits: 'Gewichts- und Körpermaße',
	units: 'Einheiten',
	metric: 'Metrisch',
	imperial: 'Kaiserliche',
	ftcm: 'ft, lbs',
	bodyMeasurements: 'Messungen',
	bmi: 'BMI',
	leanBodyMass: 'Lean Body Mass',
	waistSize: 'Taillenumfang',
	hipSize: 'Hüftumfang',
	chestSize: 'Oberweite',
	neckSize: 'Nackengröße',
	armSize: 'Armgröße',
	forearmSize: 'Unterarmgröße',
	calfSize: 'Wadengröße',
	thighSize: 'Oberschenkelgröße',
	enterNumber: 'Bitte geben Sie eine Zahl über Null ein.',
	vitals: 'Vitals',
	water: 'Wasser',
	bloodGlucose: 'Blutzucker',
	exercise: 'Übung',
	bloodPressure: 'Blutdruck',
	heartRate: 'Pulsschlag',
	ketones: 'Ketone',
	ketoneLevels: 'Ketonebenen',
	potassium: 'Kalium',
	sodium: 'Natrium',
	magnesium: 'Magnesium',
	systolic: 'Systolisch',
	diastolic: 'Diastolisch',
	breakfast: 'Frühstück',
	lunch: 'Mittagessen',
	dinner: 'Abendessen',
	snacks: 'Snacks',
	quickAdd: 'Schnell hinzufügen',
	quickAddMacros: 'Quick Add Macros',
	quickAddToMeal: 'Quick Add To Meal',
	copyTo: 'Kopieren nach',
	saveAsMeal: 'Als Mahlzeit speichern',
	addEntry: 'Eintrag hinzufügen',
	caloriesFromMacros: 'Kalorien aus Makros',
	eaten: 'Gegessen',
	remaining: 'Verbleibend',
	macroGoalsEaten: 'Makroziele gegessen',
	per: 'Pro',
	syncExercise: 'Synchronisationsübung',
	estimateCalories: 'Kalorien schätzen',
	selectTime: 'Zeit auswählen',
	duration: 'Dauer',
	caloriesBurned: 'Kalorien verbrannt',
	addExerciseCal: 'Add Exercise Cal',
	custom: 'Benutzerdefiniert',
	oneWeek: '1 Woche',
	oneMonth: '1 Monat',
	threeMonths: '3 Monate',
	sixMonths: '6 Monate',
	oneYear: '1 Jahr',
	allTime: 'All Time',
	to: 'Zu',
	addTwoEntries: '2 oder mehr Einträge hinzufügen',
	netChange: 'Net Change',
	noEntriesFound: 'Keine Einträge gefunden',
	addFoodEntries:
		'Fügen Sie Ihrem Protokoll Lebensmitteleinträge hinzu, um Daten anzuzeigen',
	fitbitDesc:
		'Senden Sie Ihre MyKeto-Gewichts-, Wasser- und Makrodaten an Fitbit. Senden Sie Ihre Fitbit-Übungsaktivität an MyKeto',
	fitbitConnected: 'Fitbit Syncing Connected',
	fitbitRemove: 'Möchten Sie den Fitbit-Zugriff von diesem Gerät entfernen?',
	fitbitDisconnected: 'Fitbit nicht verbunden',
	healthkit1:
		'Um Ihre Berechtigungen für Apple Healthkit zu aktualisieren, öffnen Sie die Health Kit-App auf Ihrem Telefon, tippen Sie auf Quellen und wählen Sie MyKeto aus der Liste aus.',
	healthkit2:
		'Senden Sie Ihre MyKeto-Gewichts-, Wasser- und Makrodaten an Apple HealthKit',
	friendRequest: 'Frage von einem Freund',
	tryAddFriend:
		'Versuchen Sie, einen Freund per E-Mail mit der Schaltfläche (+) hinzuzufügen. Sobald Sie akzeptiert wurden, können Sie die gespeicherten Lebensmittel und Mahlzeiten des anderen kopieren.',
	alreadyCopied: 'Bereits kopiert',
	sent: 'Geschickt',
	received: 'Empfangen',
	alreadyInvited: 'Bereits eingeladen',
	alreadyPendingInvite:
		'Dieser Benutzer hat bereits eine ausstehende Einladung',
	alreadyFriended: 'Bereits befreundet',
	alreadyYourFriend: 'Dieser Benutzer ist schon dein Freund',
	pendingInvite: 'Ausstehende Einladung',
	pendingAlready: 'Dieser Benutzer hat Ihnen bereits eine Einladung gesendet',
	friendRequestSent: 'Freundschaftsanfrage gesendet',
	invalidEmail: 'Ungültige E-Mail',
	cantFriendYourself: 'Du kannst dich nicht anfreunden',
	friendRequestCanceled: 'Freundschaftsanfrage abgesagt',
	friendRequestAccepted: 'Freundschaftsanfrage angenommen',
	friendRequestDeclined: 'Freundschaftsanfrage abgelehnt',
	unfriended: 'Unfreundlich',
	allFoodsCopied: 'Alle Lebensmittel kopiert',
	exerciseLabel1: 'Aerobic Dance (Casual)',
	exerciseLabel2: 'Aerobic Dance (Moderate)',
	exerciseLabel3: 'Aerobic Dance (Intense)',
	exerciseLabel4: 'Basketball (Vollplatz)',
	exerciseLabel5: 'Basketball (halber Platz)',
	exerciseLabel6: 'Gymnastik',
	exerciseLabel7: 'Radfahren (9 km / h)',
	exerciseLabel8: 'Radfahren (16 km / h)',
	exerciseLabel9: 'Radfahren (21 km / h)',
	exerciseLabel10: 'Tanzen (lässig)',
	exerciseLabel12: 'Tanzen (mäßig)',
	exerciseLabel13: 'Tanzen (intensiv)',
	exerciseLabel14: 'Feldsport',
	exerciseLabel15: 'Frisbie',
	exerciseLabel16: 'Golf (zu Fuß)',
	exerciseLabel17: 'Golf (mit Wagen)',
	exerciseLabel18: 'Gymnastik',
	exerciseLabel19: 'Hausarbeit',
	exerciseLabel20: 'Springseil',
	exerciseLabel21: 'Kampfkunst',
	exerciseLabel22: 'Racquetball',
	exerciseLabel23: 'Inliner fahren',
	exerciseLabel24: 'Rudergerät (intensiv)',
	exerciseLabel25: 'Rudergerät (mäßig)',
	exerciseLabel26: 'Laufen (6,5 km / h)',
	exerciseLabel27: 'Laufen (8 km / h)',
	exerciseLabel28: 'Laufen (9,7 km / h)',
	exerciseLabel29: 'Laufen (7 Meilen pro Stunde, 11,3 km / h)',
	exerciseLabel30: 'Laufen (13 km / h)',
	exerciseLabel31: 'Laufen (16 km / h)',
	exerciseLabel32: 'Laufen (19,3 km / h)',
	exerciseLabel33: 'Weicher Ball',
	exerciseLabel34: 'Treppenstufe (6", 15,2 cm)',
	exerciseLabel35: 'Treppenstufe (20,3 cm)',
	exerciseLabel36: 'Treppenstufe (25,4 cm)',
	exerciseLabel37: 'Treppenstufe (30,5 cm)',
	exerciseLabel38: 'Schwimmen (lässig)',
	exerciseLabel39: 'Schwimmen (intensiv)',
	exerciseLabel40: 'Tennis',
	exerciseLabel41: 'Volleyball',
	exerciseLabel42: 'Gehen (2 Meilen pro Stunde, 3,2 km / h)',
	exerciseLabel43: 'Gehen (4,8 km / h)',
	exerciseLabel44: 'Gehen (6,5 km / h)',
	exerciseLabel45: 'Krafttraining (intensiv)',
	exerciseLabel46: 'Krafttraining (normal)',
	exerciseLabel47: 'Yardwork'
};
