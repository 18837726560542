//@flow
import * as React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore, {
	reduxFirebaseConfig
} from './app/redux/configureStore';
import { StyleSheet, SafeAreaView } from 'react-native';
import { Loader } from 'Components/common';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { navigationRef } from 'Navigation/NavigationService';
import { Colors, Global } from 'Theme';
import { RootContainer } from './app/RootContainer';
import { MenuProvider } from 'react-native-popup-menu';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from 'firebase/app';
import '@react-native-firebase/auth';
import '@react-native-firebase/database';

const { store, persistor } = configureStore();

/*if (Platform.OS !== 'web') {
	AdMobInterstitial.setAdUnitID(Global.admobInterID); // Test ID, Replace with your-admob-unit-id
}*/

const rrfProps = {
	firebase,
	config: reduxFirebaseConfig,
	dispatch: store.dispatch
	// createFirestoreInstance // <- needed if using firestore
};

export default function App(props) {
	const [isLoadingComplete, setLoadingComplete] = React.useState(false);

	React.useEffect(() => {
		async function loadResourcesAndDataAsync() {
			try {
				SplashScreen.preventAutoHideAsync();
				//Platform.OS !== 'web' && (await setTestDeviceIDAsync('EMULATOR'));
				// Load fonts
				await Font.loadAsync({
					...Ionicons.font,
					Comfortaa: require('./assets/fonts/Comfortaa.ttf'),
					Raleway: require('./assets/fonts/Raleway.ttf')
				});
			} catch (e) {
				// We might want to provide this error information to an error reporting service
				console.warn(e);
			} finally {
				setLoadingComplete(true);
				SplashScreen.hideAsync();
			}
		}

		loadResourcesAndDataAsync();
	}, []);

	if (!isLoadingComplete && !props.skipLoadingScreen) {
		return null;
	} else {
		return (
			<Provider store={store}>
				<PersistGate loading={<Loader full={true} />} persistor={persistor}>
					<ReactReduxFirebaseProvider {...rrfProps}>
						<SafeAreaView style={styles.container}>
							<NavigationContainer ref={navigationRef}>
								<MenuProvider>
									<RootContainer />
								</MenuProvider>
							</NavigationContainer>
						</SafeAreaView>
					</ReactReduxFirebaseProvider>
				</PersistGate>
			</Provider>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.themeAccent
	}
});

//LogBox.ignoreLogs(['Require cycle:']);
