// @flow
import {
	CALCULATE_BMR,
	CALCULATE_BASE_CALORIES,
	CALCULATE_PROTEIN_RANGE,
	CALCULATE_RESULTS,
	ON_CHANGE_DEFICIT,
	UPDATE_CALC_VALUE
} from './types';
import { getProteins } from '../utilities/MacroCalculatorHelper';

export const updateCalcValue = (name: string, value: string | number) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/profilePage/`);
	let updates = {};
	const postData = value;
	updates[`${name}`] = postData;
	return ref.update(updates);
};

export const updateReducerCalcValue = (key: string, value: string | number) => {
	return {
		type: UPDATE_CALC_VALUE,
		key,
		value
	};
};

export const calculateBMR = (
	age: number,
	gender: string,
	weightKg: string,
	heightCm: string
) => {
	let bmr = 0;
	let correctWeight = +weightKg;
	let correctHeight = +heightCm;

	bmr =
		gender === 'male'
			? 10 * parseFloat(correctWeight) +
			  6.25 * parseFloat(correctHeight) +
			  5 -
			  5 * parseFloat(age)
			: (bmr =
					10 * parseFloat(correctWeight) +
					6.25 * parseFloat(correctHeight) -
					161 -
					5 * parseFloat(age));

	return { type: CALCULATE_BMR, BMR: +bmr.toFixed(0) };
};

export const getBaseCalories = (bmr: number, exerciseLevel: number) => {
	const baseCalories = +(+bmr * exerciseLevel).toFixed(0);
	return { type: CALCULATE_BASE_CALORIES, baseCalories };
};

export const calculateResults = (calories: number, ratio: Array<number>) => {
	const fat = (calories * ratio[0]) / 9;
	const protein = (calories * ratio[1]) / 4;
	const carb = (calories * ratio[2]) / 4;
	const macroRatio = calculateMacroRatio(calories, fat, protein, carb);
	return {
		type: CALCULATE_RESULTS,
		calories: calories,
		carb: +carb.toFixed(0),
		fat: fat.toFixed(0),
		protein: protein.toFixed(0),
		macroRatio
	};
};

export const onDeficitChange = (
	deficit: number,
	baseCalories: number,
	ratio: Array<number>
) => (dispatch) => {
	let deficitCalories;
	let finalCalories;
	deficitCalories = baseCalories * (deficit / 100);
	finalCalories = (baseCalories + deficitCalories).toFixed(0);
	if (deficit === 0) {
		finalCalories = baseCalories;
	}

	dispatch(calculateResults(+finalCalories, ratio));
	return dispatch({
		type: ON_CHANGE_DEFICIT,
		deficit: deficit,
		calories: +finalCalories
	});
};

export const calculateProteinOptions = (
	weightKg: string,
	bodyFat: string,
	isMetric: boolean
) => {
	const proteins = getProteins(weightKg, bodyFat, isMetric);
	return { type: CALCULATE_PROTEIN_RANGE, proteins };
};

export const calculateCustomResults = (
	calories: string,
	protein: string,
	carb: string
) => {
	const nonfatCal = (+protein + +carb) * 4;
	const fat = (+calories - +nonfatCal) / 9;
	const ratio = calculateMacroRatio(+calories, +fat, +protein, +carb);
	return {
		type: CALCULATE_RESULTS,
		calories: +calories.toFixed(0),
		carb: +carb.toFixed(0),
		fat: (+fat).toFixed(0),
		protein: (+protein).toFixed(0),
		macroRatio: ratio
	};
};

export const calculateMacroRatio = (calories, fat, protein, carb) => {
	return [
		(+fat * 9) / calories,
		(+protein * 4) / calories,
		(+carb * 4) / calories
	];
};
