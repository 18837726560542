//@flow
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	ScrollView,
	View,
	StyleSheet,
	TextInput,
	TouchableOpacity,
	Platform,
	Image,
	SafeAreaView
} from 'react-native';
import {
	Text,
	Gram,
	Button,
	HeaderTextButton,
	HeaderIconButton,
	WebCompatAlert
} from 'Components/common';
import { i18n, Images } from 'Theme';
import {
	returnCorrectUnitsString,
	moderateScale,
	returnCorrectUnitsValue,
	randomString,
	convertToMetricForFirebase,
	estimateExercise
} from 'Utilities';
import moment from 'moment';
import { KeyboardAccessoryView } from 'react-native-keyboard-accessory';
import KeyboardManager from 'react-native-keyboard-manager';
import DatePicker from 'react-native-datepicker';
import WebDatePicker from 'react-datepicker';
import NavigationBar from 'react-native-navbar';
import { FontAwesome } from '@expo/vector-icons';
import { fitbit_getExercise } from 'Components/tracker/fitnesstrackers/utilities/Fitbit';
import CalorieBurnedEstimator from './CalorieBurnedEstimator';
import { saveOtherTracking } from '../../actions';
import Elevations from 'react-native-elevation';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

type Props = {
	date: string,
	onClose: Function,
	onDelete: Function,
	isModalVisible: boolean,
	name: string,
	unitsArray: Object,
	valueArr: Array<Object>,
	dateMode?: string,
	fromProgressPage?: boolean
};

//"Other Tracking" Edit Modal for miscellaneous data such as Exercise, Water Intake, and Notes
export const OtherTrackingModal = (props: Props) => {
	const dispatch = useDispatch();
	const {
		date,
		valueArr,
		unitsArray,
		name,
		onDelete,
		onClose,
		isModalVisible,
		dateMode = 'time',
		fromProgressPage = false
	} = props;
	const { theme } = useSelector((state) => state.startup);

	const { auth: fitbitAuth } = useSelector(
		(state) => state.fitnessTracker.fitbit
	);

	const [editingEntry, setEditingEntry] = useState({
		time: returnTimeToDate(date).format(),
		value: ''
	});
	const [isEditing, toggleEditing] = useState(false);
	const [editingID, setEditingID] = useState('0');

	const [exerciseCalc, toggleExerciseCalc] = useState(false);
	const [exerciseEntry, setExerciseEntry] = useState({
		weight: '0',
		weightUnit: 'lbs',
		time: '60',
		timeUnit: 'mins',
		calculatedCalorie: 0,
		activityLabel: { value: 2.65, label: i18n.t('ex1') }
	});

	const { units } = useSelector((state) => state.firebase.profile.settings);

	const _textInput = useRef(null);

	useEffect(() => {
		if (Platform.OS === 'ios') KeyboardManager.setEnableAutoToolbar(false);
		return () => {
			if (Platform.OS === 'ios') KeyboardManager.setEnableAutoToolbar(true);
		};
	}, []);

	useEffect(() => {
		setEditingEntry({
			time: returnTimeToDate(date).format(),
			value: '0.0',
			valueTwo: '0.0'
		});
	}, [date]);

	useEffect(() => {
		if (fromProgressPage && valueArr.length > 0) {
			setEditingEntry({
				time: moment(valueArr[0].time).toISOString(),
				value: valueArr[0].formattedValue
			});
			setEditingID(valueArr[0].id);
			toggleEditing(true);
		}
	}, [valueArr, fromProgressPage]);

	//If entry is for Exercise, header right button needs to show the Calculator option icon
	const renderRightHeaderBtn = (name: string) => {
		if (name !== 'Exercise') {
			return <View />;
		} else {
			return (
				<HeaderIconButton
					style={{
						borderRadius: 5
					}}
					name={!exerciseCalc ? 'md-calculator' : 'md-close'}
					color={theme.blue}
					iconStyle={
						!exerciseCalc
							? { fontSize: 28, color: '#FFF' }
							: { fontSize: 28, color: theme.red }
					}
					onPress={() => toggleExerciseCalc(!exerciseCalc)}
				/>
			);
		}
	};

	//On close, reset all editing forms and close modal
	const _onClose = () => {
		toggleEditing(false);
		setEditingEntry({
			time: returnTimeToDate(date).format(),
			value: '0.0'
		});
		setEditingID('0');
		onClose();
	};

	//Save (or update a previous) vital entry
	const onSubmit = (
		name: string,
		unitType: string,
		unitString: string,
		editingEntry: Object,
		alreadySavedID?: string
	) => {
		let formattedObj;
		let formattedValue;

		//If alreadySavedID argument is given, this means the entry is not new and is being edited rather than saved for the first time
		const id =
			typeof alreadySavedID !== 'undefined' ? alreadySavedID : randomString(14);

		if (unitType === 'notes') {
			formattedValue = editingEntry.value;
		} else {
			//Format decimal point for firebase saving
			formattedValue = editingEntry.value.replace(',', '.');

			//Error check to make sure value is a real number
			if (isNaN(formattedValue) || +formattedValue <= 0) {
				return WebCompatAlert(i18n.t('error'), i18n.t('enterNumber'));
			}
		}

		//Convert value to metric or default value units in order to save to firebase
		const formattedMetricValue = convertToMetricForFirebase(
			unitType,
			unitString,
			formattedValue
		);
		//Formatted vital object to save to firebase
		formattedObj = { time: editingEntry.time, value: formattedMetricValue };

		//Add unique ID to formatted vital obj
		const objVal = { ...formattedObj, id };

		//Finally, save vital object to firebase
		dispatch(
			saveOtherTracking(
				name,
				objVal,
				id,
				date !== formattedObj.time ? date : null
			)
		);
		_onClose();
	};

	//If textinput value is 0 on focus, erase to allow easier text entry
	const onFocusEdit = () => {
		if (editingEntry.value === '0.0') {
			return _textInput.current.setNativeProps({ text: '' });
		}
	};

	//When clicking a previous entry, set editing form to previous entries data. IF previous entry is already set (highlighted), then de-select it on another click
	const onEditPreviousEntry = (item: Object, userPrefValue: string) => {
		//Previous entry is already highlighted, deselect it
		if (item.id === editingID) {
			toggleEditing(false);
			setEditingID('0');
			setEditingEntry({
				time: moment().toISOString(),
				value: '0.0'
			});
		} else {
			//Editing is blank, use entry data to load into editing form for editing aready saved entry
			toggleEditing(true);
			setEditingID(item.id);
			setEditingEntry({
				time: moment(item.time).toISOString(),
				value: userPrefValue
			});
		}
	};

	//When changing the exercise preset type (ie Cardio, Tennis, Dancing)
	const onChangeExercise = (name: string, value: string) => {
		const tempObj = { ...exerciseEntry, [`${name}`]: value };
		const calories = estimateExercise(
			tempObj.weight,
			tempObj.weightUnit,
			tempObj.time,
			tempObj.timeUnit,
			tempObj.activityLabel,
			units.exerciseUnits
		).toString();
		setExerciseEntry(tempObj);
		setEditingEntry({ time: editingEntry.time, value: calories });
	};

	//Renders textinput for number inputs
	const renderWaterInput = (unitType: string, unitString: string) => {
		return (
			<View>
				<View style={[styles.numberRow, { borderBottomColor: theme.border }]}>
					<TextInput
						style={[
							styles.numberInput,
							{ color: theme.themeAccent, borderColor: theme.placeholderGray }
						]}
						onChangeText={(value) =>
							setEditingEntry({ time: editingEntry.time, value: value })
						}
						keyboardType={'numeric'}
						value={editingEntry.value}
						underlineColorAndroid="rgba(0,0,0,0)"
						onFocus={() => onFocusEdit()}
						onBlur={() =>
							_textInput.current.setNativeProps({
								text: editingEntry.value
							})
						}
						ref={_textInput}
					/>
					<Gram
						style={{ alignSelf: 'center', color: theme.darkFont }}
						customText={returnCorrectUnitsString(unitType, unitString)}
					/>
				</View>
				<View
					style={[
						styles.waterBtnContainer,
						{ borderBottomColor: theme.border }
					]}>
					<Button
						textStyle={[styles.waterBtn, { color: theme.darkFont }]}
						style={[styles.waterBtnCtn, { backgroundColor: theme.base }]}
						onPress={() =>
							setEditingEntry({
								time: editingEntry.time,
								value: addWater(
									unitString === 'ml' ? '250' : '8',
									editingEntry.value
								)
							})
						}>
						{unitString === 'ml' ? '+250ml' : '+8oz'}
					</Button>
					<Button
						textStyle={[styles.waterBtn, { color: theme.darkFont }]}
						style={[styles.waterBtnCtn, { backgroundColor: theme.base }]}
						onPress={() =>
							setEditingEntry({
								time: editingEntry.time,
								value: addWater(
									unitString === 'ml' ? '500' : '17',
									editingEntry.value
								)
							})
						}>
						{unitString === 'ml' ? '+500ml' : '+17oz'}
					</Button>
					<Button
						textStyle={[styles.waterBtn, { color: theme.darkFont }]}
						style={[styles.waterBtnCtn, { backgroundColor: theme.base }]}
						onPress={() =>
							setEditingEntry({
								time: editingEntry.time,
								value: addWater(
									unitString === 'ml' ? '1000' : '24',
									editingEntry.value
								)
							})
						}>
						{unitString === 'ml' ? '+1000ml' : '+24oz'}
					</Button>
				</View>
			</View>
		);
	};

	//Renders textinput for notes
	const renderNotesInput = () => {
		return (
			<View style={[styles.numberRow, { borderBottomColor: theme.border }]}>
				<TextInput
					style={[
						styles.notesInput,
						{ color: theme.themeAccent, borderColor: theme.placeholderGray }
					]}
					onChangeText={(value) =>
						setEditingEntry({ time: editingEntry.time, value: value })
					}
					value={editingEntry.value}
					keyboardType={'default'}
					placeholder={'...'}
					placeholderTextColor={theme.themeAccent}
					multiline={true}
					underlineColorAndroid="rgba(0,0,0,0)"
					onFocus={() => onFocusEdit()}
					onBlur={() =>
						_textInput.current.setNativeProps({
							text: editingEntry.value
						})
					}
					ref={_textInput}
				/>
			</View>
		);
	};

	//render form if user is editing Exercise
	const renderExercise = (unitType: string, unitString: string) => {
		return (
			<View>
				<View style={[styles.numberRow, { borderBottomColor: theme.border }]}>
					<TextInput
						style={[
							styles.numberInput,
							{ color: theme.themeAccent, borderColor: theme.placeholderGray }
						]}
						onChangeText={(value) =>
							setEditingEntry({
								time: editingEntry.time,
								value: value
							})
						}
						keyboardType={'numeric'}
						value={editingEntry.value}
						underlineColorAndroid="rgba(0,0,0,0)"
						onFocus={() => onFocusEdit()}
						onBlur={() =>
							_textInput.current.setNativeProps({
								text: editingEntry.value
							})
						}
						ref={_textInput}
					/>
					<Gram
						style={{ alignSelf: 'center', color: theme.darkFont }}
						customText={returnCorrectUnitsString(unitType, unitString)}
					/>
				</View>
				{fitbitAuth.isAuth && (
					<TouchableOpacity
						style={[
							styles.fitbitButton,
							{ backgroundColor: theme.backdropGray }
						]}
						onPress={() =>
							dispatch(
								fitbit_getExercise(fitbitAuth.access_token, date, (val) =>
									setEditingEntry({
										time: editingEntry.time,
										value: val
									})
								)
							)
						}>
						<Image style={styles.fitbitImage} source={Images.fitBit} />
						<Text style={styles.fitbitBtnTxt}>{i18n.t('syncExercise')}</Text>
					</TouchableOpacity>
				)}
				{exerciseCalc && (
					<CalorieBurnedEstimator
						onCalculated={(value) =>
							setEditingEntry({
								time: editingEntry.time,
								value: value
							})
						}
						onChange={(name, value) => onChangeExercise(name, value)}
						exerciseEntry={exerciseEntry}
						theme={theme}
					/>
				)}
			</View>
		);
	};

	//Render the time picker at top of editing form, native (android, ios) use a different picker library than web
	const renderTimePicker = () => {
		if (Platform.OS !== 'web') {
			const formattedDate =
				dateMode === 'time' ? 'hh:mm a' : 'MMM-DD-YYYY hh:mm a';
			return (
				<DatePicker
					style={{ width: 200, alignSelf: 'center', marginTop: 10 }}
					mode={dateMode}
					date={moment(editingEntry.time).format(formattedDate)}
					placeholder={i18n.t('edit')}
					confirmBtnText={i18n.t('confirm')}
					cancelBtnText={i18n.t('cancel')}
					iconComponent={
						<FontAwesome name="caret-down" size={20} color={theme.darkFont} />
					}
					format={formattedDate}
					hideText={false}
					is24Hour={false}
					customStyles={{
						dateInput: {
							borderWidth: 0
						},
						dateText: {
							color: theme.themeAccent,
							fontFamily: 'Comfortaa'
						},
						datePicker: {
							backgroundColor: theme.baseBG,
							justifyContent: 'center'
						},
						btnTextConfirm: { fontFamily: 'Comfortaa', color: theme.teal },
						btnTextCancel: { fontFamily: 'Comfortaa', color: '#333' }
					}}
					onDateChange={(data) => {
						const fin =
							dateMode === 'time'
								? moment(date + ' ' + data, 'YYYY-MM-DD hh:mm a')
								: moment(data, 'MMM-DD-YYYY hh:mm a');
						setEditingEntry({
							value: editingEntry.value,
							time: fin.toISOString()
						});
					}}
				/>
			);
		} else {
			return (
				<View
					style={[
						styles.webTimeContainer,
						{ backgroundColor: theme.base, borderBottomColor: theme.border }
					]}>
					<WebDatePicker
						selected={moment(editingEntry.time).toDate()}
						onChange={(dateObj) => {
							setEditingEntry({
								value: editingEntry.value,
								time: dateObj.toISOString()
							});
						}}
						showTimeSelect
						showTimeSelectOnly={dateMode === 'time' ? true : false}
						timeIntervals={1}
						timeCaption="Time"
						dateFormat={dateMode === 'time' ? 'hh:mm a' : 'MMM d, yyyy hh:mm a'}
					/>
				</View>
			);
		}
	};

	//Renders the time picker and the correct textinput based off the vital type
	const renderEditForm = (
		name: string,
		unitType: string,
		unitString: string
	) => {
		return (
			<ScrollView keyboardShouldPersistTaps={'handled'}>
				{renderTimePicker()}
				{unitType === 'notes' && renderNotesInput()}
				{unitType === 'exercise' && renderExercise(unitType, unitString)}
				{unitType === 'water' && renderWaterInput(unitType, unitString)}
				<View>
					{!fromProgressPage &&
						valueArr.map((item) => {
							let userPrefValue = returnCorrectUnitsValue(
								unitType,
								unitString,
								item.value
							);
							userPrefValue =
								unitType !== 'notes' ? userPrefValue.toFixed(2) : userPrefValue;
							return (
								<View
									key={item.id}
									style={[
										styles.itemContainer,
										{
											borderBottomColor: theme.border,
											backgroundColor: theme.popMenuBg
										},
										isEditing && editingID === item.id
											? { backgroundColor: theme.border }
											: {}
									]}>
									<TouchableOpacity
										style={styles.valueCtn}
										onPress={() => onEditPreviousEntry(item, userPrefValue)}>
										<Text
											style={[
												styles.itemTitle,
												{ color: theme.themeAccent }
											]}>{`${moment(item.time).format('hh:mm a')}`}</Text>
										<Text style={[styles.spacer, { color: theme.themeAccent }]}>
											|
										</Text>
										<Text
											style={[styles.valueTitle, { color: theme.themeAccent }]}>
											{userPrefValue}
										</Text>
										<Gram
											style={{ paddingLeft: 5, color: theme.darkFont }}
											customText={returnCorrectUnitsString(
												unitType,
												unitString
											)}
											addPar={false}
										/>
									</TouchableOpacity>
									<TouchableOpacity
										style={[
											styles.quickDelete,
											{
												backgroundColor: theme.grey,
												borderLeftColor: theme.border
											}
										]}
										onPress={() => onDelete(item, name)}>
										<FontAwesome
											name="close"
											size={14}
											color={theme.red}
											style={styles.quickDeleteIcon}
										/>
									</TouchableOpacity>
								</View>
							);
						})}
				</View>
			</ScrollView>
		);
	};

	//Combine Textinput form with the add/update button components. Slightly change submit function and text based on if editing previous entry
	const renderEditingCtn = (name: string) => {
		const unitType = unitsArray[name];
		const unitString =
			typeof units[`${unitType}Units`] !== 'undefined'
				? units[`${unitType}Units`]
				: '';
		const submitFn =
			editingID === '0'
				? () => onSubmit(name, unitType, unitString, editingEntry)
				: () => onSubmit(name, unitType, unitString, editingEntry, editingID);
		let textBtn =
			editingID === '0'
				? `${i18n.t('add')} - ${editingEntry.value}`
				: `${i18n.t('update')} - ${editingEntry.value}`;
		if (unitType === 'notes') {
			textBtn = !isEditing ? `${i18n.t('add')}` : `${i18n.t('update')}`;
		}
		return (
			<View style={{ flex: 1 }}>
				{renderEditForm(name, unitType, unitString)}
				<KeyboardAccessoryView
					alwaysVisible={true}
					avoidKeyboard={true}
					style={{ backgroundColor: theme.teal }}
					androidAdjustResize>
					<Button
						iconName="ios-checkmark-circle"
						color={theme.white}
						iconSize={16}
						style={{
							backgroundColor: theme.teal,
							padding: 0,
							marginHorizontal: 0,
							marginVertical: 5
						}}
						textStyle={styles.btn}
						fullWidth={true}
						isFlat={true}
						onPress={submitFn}>
						{textBtn}
					</Button>
				</KeyboardAccessoryView>
			</View>
		);
	};

	return (
		<Modal.ReactNativeModal
			isVisible={isModalVisible}
			hasBackdrop={false}
			style={{ margin: 0 }}
			useNativeDriver={false}
			hideModalContentWhileAnimating={true}
			animationIn={'slideInUp'}
			animationOut={'slideOutDown'}
			onModalShow={() => _textInput && _textInput.current.focus()}>
			<SafeAreaView
				style={[styles.topCtn, { backgroundColor: theme.navBar }]}
			/>
			<SafeAreaView style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<NavigationBar
					statusBar={
						Platform.OS === 'ios' ? { hidden: false } : { hidden: true }
					}
					title={
						<Text
							style={{
								color: theme.navText,
								fontFamily: 'Comfortaa',
								fontSize: 14
							}}>
							{i18n.t(name.replace(/\s+/g, ''))}
						</Text>
					}
					containerStyle={{
						backgroundColor: theme.navBar,
						paddingHorizontal: 10
					}}
					leftButton={
						<HeaderTextButton
							title={i18n.t('close')}
							onPress={() => _onClose()}
						/>
					}
					rightButton={renderRightHeaderBtn(name)}
				/>
				{renderEditingCtn(name)}
			</SafeAreaView>
		</Modal.ReactNativeModal>
	);
};

const returnTimeToDate = (date) => {
	const currentTime = moment().format('HH:mm a');
	return moment(`${date} ${currentTime}`, 'YYYY-MM-DD HH:mm a');
};

const addWater = (valueToAdd: string, currentValue: string) => {
	let defaultValue = 0;
	if (typeof currentValue === 'undefined') {
		defaultValue = 0;
	} else {
		defaultValue = +currentValue;
	}
	defaultValue += +valueToAdd;
	return defaultValue.toString();
};

const styles = StyleSheet.create({
	numberInput: {
		fontSize: moderateScale(15),
		fontFamily: 'Comfortaa',
		justifyContent: 'flex-end',
		alignItems: 'center',
		textAlign: 'center',
		paddingHorizontal: 5,
		marginRight: 10,
		borderWidth: 1,
		minWidth: 100,
		minHeight: 45
	},
	notesInput: {
		width: '75%',
		fontSize: moderateScale(10),
		fontFamily: 'Comfortaa',
		paddingVertical: 25,
		paddingHorizontal: 10,
		marginRight: 10,
		borderWidth: 1,

		minWidth: 100,
		minHeight: 45
	},
	btnContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	waterBtnContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1
	},
	waterBtnCtn: {
		flex: 1
	},
	waterBtn: {
		fontSize: 10,
		color: '#333'
	},
	clearBorder: {
		borderRightWidth: 0
	},
	btn: {
		fontSize: 12
	},
	numberRow: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingVertical: 20,
		borderBottomWidth: 1
	},
	itemContainer: {
		borderBottomWidth: 1,
		flexDirection: 'row'
	},
	itemTitle: {
		fontSize: moderateScale(10),
		width: 80
	},
	spacer: {
		paddingHorizontal: 10
	},
	valueTitle: {
		width: '80%'
	},

	valueCtn: {
		flex: 4,
		alignItems: 'center',
		flexDirection: 'row',
		paddingVertical: 10,
		paddingLeft: 20,
		paddingRight: 8
	},
	valueText: {
		fontSize: 16,
		textAlign: 'left',
		lineHeight: 20,
		paddingRight: moderateScale(5)
	},
	timeText: {
		fontSize: 13,
		textAlign: 'left',
		lineHeight: 13,
		paddingVertical: 5
	},
	quickDelete: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'stretch',
		flex: 0.75,
		paddingVertical: 15,
		borderLeftWidth: 1
	},
	quickDeleteIcon: {
		alignSelf: 'center'
	},
	webTimeContainer: {
		zIndex: 9999,
		flexDirection: 'row',
		height: 50,
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1
	},
	fitbitButton: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginHorizontal: 15,
		height: 50,
		marginVertical: 10,
		borderRadius: 3,
		...Elevations[1]
	},
	fitbitBtnTxt: {
		fontSize: 10
	},
	fitbitImage: {
		height: 25,
		width: 25,
		borderRadius: 5,
		marginRight: 10
	}
});
