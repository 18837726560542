//@flow
import React, { useState, useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
	setEditIngredientValue,
	editQuickAddedIngredient,
	updateQuickMacroIngredient,
	addMacrosToMeal,
	removeIngredient
} from 'Redux/actions';
import { saveEditSingleMeal } from './actions';
import { editItemPreloadServing } from 'Redux/actions';
import styles from './Styles/Styles';
import { MacroGrid, WebCompatAlert } from 'Components/common';
import {
	EditMealActionButton,
	CustomSingleMealModals,
	HeaderEditing,
	IngredientsEditing
} from './components/mealediting';
import { servingEditingSelector } from './selectors';
import { i18n } from 'Theme';

export const CustomMealTabSingleEdit = ({ route, navigation }) => {
	const dispatch = useDispatch();
	const { item } = route.params;
	const {
		savedmealsv3,
		customfoodsv3,
		settings: { units }
	} = useSelector((state) => state.firebase.profile);
	const { editingIngredientArr, tempIngredientObject } = useSelector(
		(state) => state.singleItemMealEdit
	);
	const servingData = useSelector((state) =>
		servingEditingSelector(state, route)
	);
	const { theme } = useSelector((state) => state.startup);
	const [editIngredientModal, toggleEditIngredientModal] = useState(false);
	const [macroModal, toggleMacroModal] = useState(false);
	const [editingItemName, changeItemName] = useState(item.name);
	const [suggestedServingSize, edintServingSize] = useState(
		item.suggestedServingSize ? item.suggestedServingSize : '1'
	);
	const [customServingName, changeServingName] = useState(
		item.customServingName ? item.customServingName : i18n.t('servings')
	);

	useEffect(() => {
		navigation.setParams({
			onSubmit: () => submitMeal()
		});
	}, [
		editingItemName,
		editingIngredientArr,
		suggestedServingSize,
		customServingName,
		route.params.manage
	]);

	const changeServingSize = (value) => {
		edintServingSize(value.replace(',', '.'));
	};

	const submitMeal = () => {
		if (isNaN(suggestedServingSize) || +suggestedServingSize < 0) {
			return WebCompatAlert(
				i18n.t('totalServings'),
				i18n.t('totalServingsDesc')
			);
		}

		const mC = () => {
			//popToTop makes the LogStack revert back to home log page from its edit meal pages, but since we are in Manage stack we navigate back to Manage.
			navigation.popToTop();
			navigation.navigate('Manage');
		};

		let callback;
		callback = route.params.manage ? () => mC() : () => navigation.goBack();

		return dispatch(
			saveEditSingleMeal(
				editingItemName,
				item,
				editingIngredientArr,
				suggestedServingSize,
				customServingName,
				callback
			)
		);
	};

	const editIngredientNavRouter = (ingredient) => {
		switch (ingredient.type) {
			case 'api':
				navigation.navigate('MealEditingSingleSearch', {
					foodID: ingredient.id,
					ingredientRandom: ingredient.random,
					editingMeal: true,
					editingIngredient: true,
					manage: false,
					itemName: ingredient.name
				});
				break;
			case 'food':
				if (typeof customfoodsv3[ingredient.id] === 'undefined') {
					return dispatch(
						setEditIngredientValue(ingredient, () =>
							toggleEditIngredientModal(true)
						)
					);
				}
				dispatch(editItemPreloadServing('food', { name: ingredient.name }));
				navigation.navigate('MealEditingSingleFood', {
					foodID: ingredient.id,
					ingredientRandom: ingredient.random,
					editingMeal: true,
					editingIngredient: true,
					manage: false,
					itemName: ingredient.name
				});
				break;
			case 'meal':
				if (typeof savedmealsv3[ingredient.id] === 'undefined') {
					return dispatch(
						setEditIngredientValue(ingredient, () =>
							toggleEditIngredientModal(true)
						)
					);
				}
				dispatch(
					editItemPreloadServing('mealClone', {
						name: ingredient.name
					})
				);
				navigation.navigate('MealEditingSingleMeal', {
					foodID: ingredient.id,
					ingredientRandom: ingredient.random,
					editingMeal: true,
					editingIngredient: true,
					manage: false,
					itemName: ingredient.name
				});
				break;
			case 'macro':
				dispatch(
					setEditIngredientValue(ingredient, () =>
						toggleEditIngredientModal(true)
					)
				);
				break;
			default:
				dispatch(
					setEditIngredientValue(ingredient, () =>
						toggleEditIngredientModal(true)
					)
				);
				break;
		}
	};

	return (
		<View style={[styles.container, { backgroundColor: theme.baseBG }]}>
			<ScrollView style={styles.container}>
				<HeaderEditing
					itemName={editingItemName}
					servingSize={suggestedServingSize}
					servingName={customServingName}
					changeItemName={changeItemName}
					changeServingName={changeServingName}
					changeServingSize={changeServingSize}
					theme={theme}
				/>
				<MacroGrid
					{...servingData.servingMacros}
					carbUnits={units.carbUnits}
					theme={theme}
				/>
				<IngredientsEditing
					items={editingIngredientArr}
					editing={true}
					removeIngredient={removeIngredient}
					editServing={editIngredientNavRouter}
					theme={theme}
				/>
			</ScrollView>
			<CustomSingleMealModals
				macroModal={macroModal}
				toggleMacroModal={toggleMacroModal}
				editIngredientModal={editIngredientModal}
				toggleEditIngredientModal={toggleEditIngredientModal}
				updateQuickMacroIngredient={updateQuickMacroIngredient}
				tempIngredientObject={tempIngredientObject}
				addMacrosToMeal={addMacrosToMeal}
			/>
			<EditMealActionButton
				navigation={navigation}
				toggleMacroModal={toggleMacroModal}
				route={route}
				theme={theme}
			/>
		</View>
	);
};
