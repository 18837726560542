//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import {
	VictoryChart,
	VictoryScatter,
	VictoryAxis,
	VictoryTheme,
	VictoryLabel,
	VictoryLegend
} from 'victory-native';
import moment from 'moment';

type Props = {
	width: number,
	data: Object,
	progressFilter: string,
	title: string,
	range: Array<Number>,
	theme: Object,
	xLabel?: string,
	yLabel?: string,
	legendData?: Array
};

export const ProgressScatter = (props: Props) => {
	const {
		width,
		data,
		range,
		xLabel,
		yLabel,
		children,
		legendData = [],
		theme
	} = props;

	let xTickValues = data.map((d) => {
		return d.x;
	});

	return (
		<View style={[styles.chartContainer, { backgroundColor: theme.baseBG }]}>
			<VictoryChart
				theme={themeWithSystemFont}
				padding={{ top: 55, bottom: 65, left: 70, right: 50 }}
				domainPadding={{ x: 1, y: 10 }}
				width={width}
				maxDomain={{ y: range[1] }}
				minDomain={{ y: range[0] }}
				scale={{ x: 'time' }}
				disableContainerEvents={false}
				animate={{
					duration: 250,
					onLoad: { duration: 0 }
				}}>
				<VictoryAxis
					dependentAxis
					axisLabelComponent={<VictoryLabel dy={-140} dx={5} angle={0} />}
					label={yLabel}
					tickFormat={(y) => y.toFixed(1)}
					style={{
						grid: { stroke: () => theme.border },
						tickLabels: {
							fontSize: 12,
							fontFamily: 'Raleway',
							fill: theme.darkFont
						},
						axisLabel: {
							padding: 40,
							fontSize: 14,
							fontFamily: 'Raleway',
							fill: theme.darkFont
						},
						axis: { stroke: theme.placeholderGray }
					}}
				/>
				<VictoryAxis
					fixLabelOverlap={true}
					tickValues={xTickValues}
					tickLabelComponent={<VictoryLabel angle={40} />}
					tickFormat={(x) => moment(x).format('MMM-DD')}
					label={xLabel}
					scale={{ x: 'time' }}
					style={{
						tickLabels: {
							fontSize: 8,
							fill: theme.darkFont,
							fontFamily: 'Raleway'
						},
						axisLabel: {
							padding: 40,
							fontSize: 14,
							fontFamily: 'Raleway',
							fill: theme.darkFont
						},
						axis: { stroke: theme.placeholderGray },
						grid: { stroke: () => theme.baseBG },
					}}
				/>
				<VictoryScatter
					style={{
						data: {
							fill: ({ datum }) =>
								datum.type === 'systolic' ? theme.red : theme.blue
						}
					}}

					size={4}
					data={data}
					symbol={({ datum }) =>
						typeof datum.type === 'undefined'
							? 'circle'
							: datum.type === 'systolic'
							? 'triangleDown'
							: 'triangleUp'
					}
				/>
				{legendData.length > 0 && (
					<VictoryLegend
						x={125}
						y={10}
						orientation="horizontal"
						gutter={20}
						style={{ border: { stroke: 'black' } }}
						data={legendData}
					/>
				)}
				{children}
			</VictoryChart>
		</View>
	);
};

const styles = StyleSheet.create({
	chartContainer: {
		backgroundColor: '#FFF',
		borderTopWidth: 0,
		justifyContent: 'center',
		alignItems: 'center'
	},
	chartErrorContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'stretch',
		backgroundColor: '#FFF',
		marginVertical: 50
	}
});

function replaceRobotoWithSystemFont(obj: any) {
	const keys = Object.keys(obj);
	keys.forEach(function (key) {
		const value = obj[key];
		if (key === 'fontFamily') {
			obj[key] = obj[key].replace("'Roboto',", "'System',");
		}
		if (typeof value === 'object') {
			replaceRobotoWithSystemFont(obj[key]);
		}
	});
	return obj;
}

const themeWithSystemFont = replaceRobotoWithSystemFont({
	...VictoryTheme.material
});
