import {
	REQUEST_FATSECRET_BARCODE,
	RECEIVE_FATSECRET_BARCODE,
	ERROR_FATSECRET_BARCODE,
	PUSH_RECENTLY_SCANNED_BARCODE,
	CLEAR_RECENTLY_SCANNED
} from './types';
import jsSHA from 'jssha';
import * as NavigationService from 'Navigation/NavigationService';
import Toast from 'react-native-root-toast';
import { WebCompatAlert } from 'Components/common';
import { i18n } from 'Theme';
import { Platform } from "react-native";
const REQUEST_URL = 'http://platform.fatsecret.com/rest/server.api';
const REQUEST_METHOD = 'GET';
const OAUTH_SIGNATURE_METHOD = 'HMAC-SHA1';
const OAUTH_VERSION = '1.0';
const FORMAT = 'json';
const OAUTH_CONSUMER_KEY = '0c349309114f4ee784900151e0f4039b';
const SHARED_SECRET = '785e42225de34903943895bee51b4cc5';

export const requestFatSecretScan = () => {
	return {
		type: REQUEST_FATSECRET_BARCODE
	};
};

export const pushRecentlyScanned = (foodData) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	firebase
		.update(`userdata/${uid}/recentbarcodes/${foodData.food_id}`, {
			food_id: foodData.food_id,
			food_name: foodData.food_name,
			food_type: foodData.food_type,
			brand_name: foodData.brand_name
		})
		.then(() => {
			dispatch({
				type: PUSH_RECENTLY_SCANNED_BARCODE
			});
		});
};

export const clearAllRecentBarcodes = () => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;

	firebase
		.update(`userdata/${uid}/`, {
			recentbarcodes: []
		})
		.then(() => {
			dispatch({
				type: CLEAR_RECENTLY_SCANNED
			});
		});
};

export const quickRemoveRecentScanned = (food) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	firebase.remove(`userdata/${uid}/recentbarcodes/${food.food_id}`).then(() => {
		return Toast.show(`${food.food_name} ${i18n.t('removed')}`, {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
};

export const receiveFatSecretScan = (fatSecretDecodedBarcode, nextRoute) => {
	//Since the Barcode scan is used by Adding Foods, Adding Foods - Editing Meal, Managing Foods - Editing Meal, and Net Carb Look-Up, we must have a correct redirect routing definied with correct paramters to ensure the correct buttons appear on the redirected route. First it checks the route that the barcode scan came from, and from that info it sends the user to the correct following Scanned Item Single page
	let redirectRoute;
	let nextScreenParams;

	redirectRoute = nextRoute;
	nextScreenParams = {
		foodID: fatSecretDecodedBarcode.food_id.value,
		recentBarcode: true
	};

	if (fatSecretDecodedBarcode.food_id.value == '0') {
		WebCompatAlert(i18n.t('itemNotFoundFS'), i18n.t('ItemNotFoundFSdesc'));
		return (dispatch) => {
			dispatch({ type: ERROR_FATSECRET_BARCODE });
		};
	} else {
		return (dispatch) => {
			dispatch({
				type: RECEIVE_FATSECRET_BARCODE,
				payload: fatSecretDecodedBarcode.food_id.value
			});
			Toast.show(i18n.t('itemFound'), {
				duration: Toast.durations.SHORT,
				position: Toast.positions.CENTER,
				shadow: true,
				animation: true
			});
			// return NavigationService.navigate("SearchTabSingle", { foodID: fatSecretDecodedBarcode.food_id.value, mealTime: params.mealTime, logDate: params.logDate, tracker: true });
			return NavigationService.navigate(redirectRoute, nextScreenParams);
		};
	}
};

export const fetchFatSecretScan = (
	EAN_13_BARCODE_FORMATTED,
	redirectParams
) => {
	const METHOD = 'food.find_id_for_barcode';
	const OAUTH_TIMESTAMP = new Date().getTime();
	const OAUTH_NONCE = '' + new Date().getTime();
	const BARCODE_ID = EAN_13_BARCODE_FORMATTED;

	// Create a Signature Base String
	const REQUEST_URL_ENCODED = encodeURIComponent(REQUEST_URL);
	let NORMALISED_PARAMETERS = 'barcode=' + BARCODE_ID;
	NORMALISED_PARAMETERS += '&format=' + FORMAT;
	NORMALISED_PARAMETERS += '&method=' + METHOD;
	NORMALISED_PARAMETERS += '&oauth_consumer_key=' + OAUTH_CONSUMER_KEY;
	NORMALISED_PARAMETERS += '&oauth_nonce=' + OAUTH_NONCE;
	NORMALISED_PARAMETERS += '&oauth_signature_method=' + OAUTH_SIGNATURE_METHOD;
	NORMALISED_PARAMETERS += '&oauth_timestamp=' + OAUTH_TIMESTAMP;
	NORMALISED_PARAMETERS += '&oauth_version=' + OAUTH_VERSION;

	const NORMALISED_PARAMETERS_ENCODED = encodeURIComponent(
		NORMALISED_PARAMETERS
	);

	const BASE_STRING =
		REQUEST_METHOD +
		'&' +
		REQUEST_URL_ENCODED +
		'&' +
		NORMALISED_PARAMETERS_ENCODED;

	const OAUTH_SIGNATURE = new jsSHA(BASE_STRING, 'TEXT');
	const OAUTH_SIGNATURE_BASE64 = OAUTH_SIGNATURE.getHMAC(
		encodeURIComponent(SHARED_SECRET) + '&',
		'TEXT',
		'SHA-1',
		'B64'
	);
	const OAUTH_SIGNATURE_BASE64_ENCODED = encodeURIComponent(
		OAUTH_SIGNATURE_BASE64
	);
	const OAUTH_REQUEST_URL =
		REQUEST_URL +
		'?' +
		NORMALISED_PARAMETERS +
		'&oauth_signature=' +
		OAUTH_SIGNATURE_BASE64_ENCODED;
	return (dispatch) => {
		dispatch(requestFatSecretScan());
		if (Platform.OS === 'web') {
			var href = new URL(
				'https://us-central1-medidiet-119a0.cloudfunctions.net/fatsecret/fatsecret'
			);
			href.searchParams.set('url', OAUTH_REQUEST_URL);
			return fetch(`${href.toString()}`, {
				method: 'GET'
			})
				.then((response) => response.json())
				.then((json) => dispatch(receiveFatSecretScan(json)))
				.catch((error) => {
					console.error(error);
				});
		} else {
			return fetch(OAUTH_REQUEST_URL)
				.then((response) => response.json())
				.then((barcodeData) =>
					dispatch(receiveFatSecretScan(barcodeData, redirectParams))
				);
		}
	};
};
