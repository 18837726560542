//@flow
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	ScrollView,
	View,
	StyleSheet,
	TextInput,
	TouchableOpacity,
	Platform,
	SafeAreaView
} from 'react-native';
import {
	Text,
	Gram,
	Button,
	HeaderTextButton,
	WebCompatAlert
} from 'Components/common';
import { i18n } from 'Theme';
import {
	returnCorrectUnitsString,
	moderateScale,
	randomString,
	convertToMetricForFirebase,
	returnCorrectUnitsValue
} from 'Utilities';
import moment from 'moment';
import { KeyboardAccessoryView } from 'react-native-keyboard-accessory';
import KeyboardManager from 'react-native-keyboard-manager';
import DatePicker from 'react-native-datepicker';
import WebDatePicker from 'react-datepicker';
import NavigationBar from 'react-native-navbar';
import { FontAwesome } from '@expo/vector-icons';
import { saveVital } from 'Redux/actions';
import Elevations from 'react-native-elevation';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

type Props = {
	date: string,
	onClose: Function,
	onDelete: Function,
	isModalVisible: boolean,
	name: string,
	unitsArray: Object,
	valueArr: Array<Object>,
	dateMode?: string,
	fromProgressPage?: boolean,
	theme: Object
};

//Modal for adding and editing vital entries on the Vital screens. Contains many different input formats depending on the different vitals
export const VitalsEditModal = (props: Props) => {
	const {
		date,
		valueArr,
		unitsArray,
		name,
		isModalVisible,
		onDelete,
		onClose,
		dateMode = 'time',
		fromProgressPage = false,
		theme
	} = props;
	const dispatch = useDispatch();

	//Editing State for the text-inputs and datetime picker of the current modal entry
	const [editingEntry, setEditingEntry] = useState({
		time: returnTimeToDate(date).format(),
		value: '0.0',
		valueTwo: '0.0'
	});

	//If a user clicks on a previous entry for the day, toggle editing mode of that clicked entry
	const [isEditing, toggleEditing] = useState(false);

	//Set the ID of the current editing entry, this is used to highlight and change text when editing an entry
	const [editingID, setEditingID] = useState('0');
	const { units } = useSelector((state) => state.firebase.profile.settings);

	const _textInput = useRef(null);
	const _textInputTwo = useRef(null);

	useEffect(() => {
		if (Platform.OS === 'ios') KeyboardManager.setEnableAutoToolbar(false);
		return () => {
			if (Platform.OS === 'ios') KeyboardManager.setEnableAutoToolbar(true);
		};
	}, []);

	useEffect(() => {
		setEditingEntry({
			time: returnTimeToDate(date).format(),
			value: '0.0',
			valueTwo: '0.0'
		});
	}, [date]);

	useEffect(() => {
		//If coming from the Progress Vitals Page, need to preload the modal with values on component mount.
		if (fromProgressPage && valueArr.length > 0) {
			setEditingEntry({
				time: moment(valueArr[0].time).toISOString(),
				value: valueArr[0].formattedValue,
				valueTwo: valueArr[0].formattedValueTwo
					? valueArr[0].formattedValueTwo
					: '0.0'
			});
			setEditingID(valueArr[0].id);
			toggleEditing(true);
		}
	}, [valueArr, fromProgressPage]);

	//On close, reset all editing forms and close modal
	const _onClose = () => {
		toggleEditing(false);
		setEditingEntry({
			time: returnTimeToDate(date).format(),
			value: '0.0',
			valueTwo: '0.0'
		});
		setEditingID('0');
		onClose();
	};

	//Save (or update a previous) vital entry
	const onSubmit = (
		name: string,
		unitType: string,
		unitString: string,
		editingEntry: Object,
		alreadySavedID?: string
	) => {
		let formattedObj;
		let formattedValue;
		let formattedValueTwo;

		//If alreadySavedID argument is given, this means the entry is not new and is being edited rather than saved for the first time
		const id =
			typeof alreadySavedID !== 'undefined' ? alreadySavedID : randomString(14);

		//Format decimal point for firebase saving
		formattedValue = editingEntry.value.replace(',', '.');

		//Error check to make sure value is a real number
		if (isNaN(formattedValue) || +formattedValue <= 0) {
			return WebCompatAlert(i18n.t('error'), i18n.t('enterNumber'));
		}

		if (unitType === 'mmHg') {
			//if vital is Blood Pressure, also format and error check second textinput value
			formattedValueTwo = editingEntry['valueTwo'].replace(',', '.');
			if (isNaN(formattedValueTwo) || +formattedValueTwo <= 0) {
				return WebCompatAlert(i18n.t('error'), i18n.t('enterNumber'));
			}
		}

		//Convert value to metric or default value units in order to save to firebase
		const formattedMetricValue = convertToMetricForFirebase(
			unitType,
			unitString,
			formattedValue
		);
		//Formatted vital object to save to firebase
		formattedObj =
			unitType !== 'mmHg'
				? { time: editingEntry.time, value: formattedMetricValue }
				: {
						time: editingEntry.time,
						value: +formattedValue,
						valueTwo: +formattedValueTwo
				  };
		//Add unique ID to formatted vital obj
		const objVal = { ...formattedObj, id };

		//Finally, save vital object to firebase
		dispatch(
			saveVital(name, objVal, id, date !== formattedObj.time ? date : null)
		);
		_onClose();
	};

	//If textinput value is 0 on focus, erase to allow easier text entry
	const onFocusEdit = () => {
		if (editingEntry.value === '0.0') {
			return _textInput.current.setNativeProps({ text: '' });
		}
	};

	//If textinput value is 0 on focus, erase to allow easier text entry
	const onFocusEditTwo = () => {
		if (editingEntry.valueTwo === '0.0') {
			return _textInputTwo.current.setNativeProps({ text: '' });
		}
	};

	//When clicking a previous entry, set editing form to previous entries data. IF previous entry is already set (highlighted), then de-select it on another click
	const onEditPreviousEntry = (item: Object, userPrefValue: string) => {
		//Previous entry is already highlighted, deselect it
		if (item.id === editingID) {
			toggleEditing(false);
			setEditingID('0');
			setEditingEntry({
				time: returnTimeToDate(date).format(),
				value: '0.0',
				valueTwo: '0.0'
			});
		} else {
			//Editing is blank, use entry data to load into editing form for editing aready saved entry
			toggleEditing(true);
			setEditingID(item.id);
			setEditingEntry({
				time: moment(item.time).toISOString(),
				value: userPrefValue,
				valueTwo: item.valueTwo ? item.valueTwo.toFixed(1) : '0.0'
			});
		}
	};

	//Renders textinput for number inputs
	const renderNumberTextInput = (unitType: string, unitString: string) => {
		return (
			<View style={[styles.numberRow, { borderBottomColor: theme.border }]}>
				<TextInput
					style={[
						styles.numberInput,
						{ color: theme.themeAccent, borderColor: theme.placeholderGray }
					]}
					onChangeText={(value) =>
						setEditingEntry({ time: editingEntry.time, value: value })
					}
					keyboardType={'numeric'}
					value={editingEntry.value}
					underlineColorAndroid="rgba(0,0,0,0)"
					onFocus={() => onFocusEdit()}
					onBlur={() =>
						_textInput.current.setNativeProps({
							text: editingEntry.value
						})
					}
					ref={_textInput}
				/>
				<Gram
					style={{ alignSelf: 'center', color: theme.darkFont }}
					customText={returnCorrectUnitsString(unitType, unitString)}
				/>
			</View>
		);
	};

	//Since blood pressure requires 2 inputs, we need a different render form for the textinputs
	const renderBloodPressure = () => {
		return (
			<View
				style={{
					borderBottomWidth: 1,
					borderBottomColor: theme.border,
					flexDirection: 'row',
					justifyContent: 'space-around',
					alignItems: 'center'
				}}>
				<View
					style={[
						[styles.numberRow, { borderBottomColor: theme.border }],
						{ borderBottomColor: theme.border }
					]}>
					<TextInput
						style={[
							styles.numberInput,
							{ color: theme.themeAccent, borderColor: theme.placeholderGray }
						]}
						onChangeText={(value) =>
							setEditingEntry({
								time: editingEntry.time,
								value: value,
								valueTwo: editingEntry.valueTwo
							})
						}
						keyboardType={'numeric'}
						value={editingEntry.value}
						underlineColorAndroid="rgba(0,0,0,0)"
						onFocus={() => onFocusEdit()}
						onBlur={() =>
							_textInput.current.setNativeProps({
								text: editingEntry.value
							})
						}
						ref={_textInput}
						onSubmitEditing={() => _textInputTwo.current.focus()}
					/>
					<Gram
						style={{ alignSelf: 'center', color: theme.darkFont }}
						customText={i18n.t('Systolic')}
					/>
				</View>
				<Text style={[styles.spacer, { color: theme.themeAccent }]}>|</Text>
				<View
					style={[
						[styles.numberRow, { borderBottomColor: theme.border }],
						{ borderBottomColor: theme.border }
					]}>
					<TextInput
						style={[
							styles.numberInput,
							{ color: theme.themeAccent, borderColor: theme.placeholderGray }
						]}
						onChangeText={(value) =>
							setEditingEntry({
								time: editingEntry.time,
								value: editingEntry.value,
								valueTwo: value
							})
						}
						keyboardType={'numeric'}
						value={editingEntry.valueTwo}
						underlineColorAndroid="rgba(0,0,0,0)"
						onFocus={() => onFocusEditTwo()}
						onBlur={() =>
							_textInputTwo.current.setNativeProps({
								text: editingEntry.valueTwo
							})
						}
						ref={_textInputTwo}
					/>
					<Gram
						style={{ alignSelf: 'center', color: theme.darkFont }}
						customText={i18n.t('Diastolic')}
					/>
				</View>
			</View>
		);
	};

	//Render the time picker at top of editing form, native (android, ios) use a different picker library than web
	const renderTimePicker = () => {
		if (Platform.OS !== 'web') {
			const formattedDate =
				dateMode === 'time' ? 'hh:mm a' : 'MMM-DD-YYYY hh:mm a';
			return (
				<DatePicker
					style={{ width: 200, alignSelf: 'center', marginTop: 10 }}
					mode={dateMode}
					date={moment(editingEntry.time).format(formattedDate)}
					placeholder={i18n.t('edit')}
					confirmBtnText={i18n.t('confirm')}
					cancelBtnText={i18n.t('cancel')}
					iconComponent={
						<FontAwesome name="caret-down" size={20} color={theme.darkFont} />
					}
					format={formattedDate}
					hideText={false}
					is24Hour={false}
					customStyles={{
						dateInput: {
							borderWidth: 0
						},
						dateText: {
							color: theme.themeAccent,
							fontFamily: 'Comfortaa'
						},
						datePicker: {
							backgroundColor: theme.baseBG,
							justifyContent: 'center'
						},
						btnTextConfirm: { fontFamily: 'Comfortaa', color: theme.teal },
						btnTextCancel: { fontFamily: 'Comfortaa', color: '#333' }
					}}
					onDateChange={(data) => {
						const fin =
							dateMode === 'time'
								? moment(date + ' ' + data, 'YYYY-MM-DD hh:mm a')
								: moment(data, 'MMM-DD-YYYY hh:mm a');
						setEditingEntry({
							value: editingEntry.value,
							valueTwo: editingEntry.valueTwo,
							time: fin.toISOString()
						});
					}}
				/>
			);
		} else {
			return (
				<View
					style={[
						styles.webTimeContainer,
						{ backgroundColor: theme.base, borderBottomColor: theme.border }
					]}>
					<WebDatePicker
						selected={moment(editingEntry.time).toDate()}
						onChange={(dateObj) => {
							setEditingEntry({
								value: editingEntry.value,
								valueTwo: editingEntry.valueTwo,
								time: dateObj.toISOString()
							});
						}}
						showTimeSelect
						showTimeSelectOnly={dateMode === 'time' ? true : false}
						timeIntervals={1}
						timeCaption="Time"
						dateFormat={dateMode === 'time' ? 'hh:mm a' : 'MMM d, yyyy hh:mm a'}
					/>
				</View>
			);
		}
	};

	//Renders the time picker and the correct textinput based off the vital type
	const renderEditForm = (
		name: string,
		unitType: string,
		unitString: string
	) => {
		return (
			<ScrollView keyboardShouldPersistTaps={'handled'}>
				{renderTimePicker()}
				{unitType === 'mmHg' && renderBloodPressure()}
				{unitType !== 'mmHg' && renderNumberTextInput(unitType, unitString)}
				<View>
					{!fromProgressPage &&
						valueArr.map((item) => {
							const userPrefValue = returnCorrectUnitsValue(
								unitType,
								unitString,
								item.value
							).toFixed(2);
							return (
								<View
									key={item.id}
									style={[
										styles.itemContainer,
										{
											borderBottomColor: theme.border,
											backgroundColor: theme.popMenuBg
										},
										isEditing && editingID === item.id
											? { backgroundColor: theme.border }
											: {}
									]}>
									<TouchableOpacity
										style={styles.valueCtn}
										onPress={() => onEditPreviousEntry(item, userPrefValue)}>
										<View
											style={{
												flex: 1,
												flexDirection: 'row',
												alignItems: 'center'
											}}>
											<Text
												style={[
													styles.itemTitle,
													{ color: theme.themeAccent }
												]}>{`${moment(item.time).format('hh:mm a')}`}</Text>
											<Text
												style={[styles.spacer, { color: theme.themeAccent }]}>
												|
											</Text>
											{unitType !== 'mmHg' ? (
												<Text
													style={[
														styles.itemTitle,
														{ color: theme.themeAccent }
													]}>
													{userPrefValue}
												</Text>
											) : (
												<Text
													style={[
														styles.itemTitle,
														{ color: theme.themeAccent }
													]}>{`${item.value}/${item.valueTwo}`}</Text>
											)}
											<Gram
												style={{ paddingLeft: 5, color: theme.darkFont }}
												customText={returnCorrectUnitsString(
													unitType,
													unitString
												)}
												addPar={false}
											/>
										</View>
									</TouchableOpacity>
									<TouchableOpacity
										style={[
											styles.quickDelete,
											{
												backgroundColor: theme.grey,
												borderLeftColor: theme.border
											}
										]}
										onPress={() => onDelete(item, name)}>
										<FontAwesome
											name="close"
											size={14}
											color={theme.red}
											style={styles.quickDeleteIcon}
										/>
									</TouchableOpacity>
								</View>
							);
						})}
				</View>
			</ScrollView>
		);
	};

	//Combine Textinput form with the add/update button components. Slightly change submit function and text based on if editing previous entry
	const renderEditingCtn = (name: string) => {
		const unitType = unitsArray[name];
		const unitString =
			typeof units[`${unitType}Units`] !== 'undefined'
				? units[`${unitType}Units`]
				: '';
		const submitFn =
			editingID === '0'
				? () => onSubmit(name, unitType, unitString, editingEntry)
				: () => onSubmit(name, unitType, unitString, editingEntry, editingID);
		const textBtn =
			editingID === '0'
				? `${i18n.t('add')} - ${editingEntry.value}`
				: `${i18n.t('update')} - ${editingEntry.value}`;
		return (
			<View style={{ flex: 1 }}>
				{renderEditForm(name, unitType, unitString)}
				<KeyboardAccessoryView
					alwaysVisible={true}
					avoidKeyboard={true}
					style={{
						backgroundColor: theme.teal
					}}
					androidAdjustResize>
					<Button
						iconName="ios-checkmark-circle"
						color={theme.white}
						iconSize={16}
						style={{
							backgroundColor: theme.teal,
							padding: 0,
							marginHorizontal: 0,
							marginVertical: 5
						}}
						textStyle={styles.btn}
						fullWidth={true}
						isFlat={true}
						onPress={submitFn}>
						{textBtn}
					</Button>
				</KeyboardAccessoryView>
			</View>
		);
	};

	return (
		<Modal.ReactNativeModal
			isVisible={isModalVisible}
			hasBackdrop={false}
			style={{ margin: 0 }}
			useNativeDriver={false}
			hideModalContentWhileAnimating={true}
			animationIn={'slideInLeft'}
			animationOut={'slideOutRight'}
			onModalShow={() => _textInput && _textInput.current.focus()}>
			<SafeAreaView
				style={[styles.topCtn, { backgroundColor: theme.navBar }]}
			/>
			<SafeAreaView style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<NavigationBar
					statusBar={
						Platform.OS === 'ios' ? { hidden: false } : { hidden: true }
					}
					title={
						<Text
							style={{
								color: theme.navText,
								fontWeight: null,
								fontFamily: 'Comfortaa',
								fontSize: 14
							}}>
							{i18n.t(name.replace(/\s+/g, ''))}
						</Text>
					}
					containerStyle={{
						backgroundColor: theme.navBar,
						paddingHorizontal: 10
					}}
					leftButton={
						<HeaderTextButton
							title={i18n.t('close')}
							onPress={() => _onClose()}
						/>
					}
				/>
				{renderEditingCtn(name)}
			</SafeAreaView>
		</Modal.ReactNativeModal>
	);
};

const returnTimeToDate = (date) => {
	const currentTime = moment().format('HH:mm a');
	return moment(`${date} ${currentTime}`, 'YYYY-MM-DD HH:mm a');
};

const styles = StyleSheet.create({
	numberInput: {
		fontSize: moderateScale(15),
		fontFamily: 'Comfortaa',
		justifyContent: 'flex-end',
		alignItems: 'center',
		textAlign: 'center',
		paddingRight: 5,
		marginRight: 10,
		borderWidth: 1,
		minWidth: 100,
		minHeight: 45
	},
	btn: {
		fontSize: 12
	},
	numberRow: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingVertical: 20,
		borderBottomWidth: 1
	},
	itemContainer: {
		borderBottomWidth: 1,
		flexDirection: 'row',
		backgroundColor: '#FFF'
	},
	itemTitle: {},
	spacer: {
		paddingHorizontal: 10
	},
	valueCtn: {
		flex: 4,
		alignItems: 'center',
		flexDirection: 'row',
		paddingVertical: 10,
		paddingLeft: 20,
		paddingRight: 8
	},
	valueText: {
		fontSize: 16,
		textAlign: 'left',
		lineHeight: 20,
		paddingRight: moderateScale(5)
	},
	timeText: {
		color: '#333',
		fontSize: 13,
		textAlign: 'left',
		lineHeight: 13,
		paddingVertical: 5
	},
	quickDelete: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'stretch',
		flex: 0.75,
		paddingVertical: 15,
		borderLeftWidth: 1
	},
	quickDeleteIcon: {
		alignSelf: 'center'
	},
	webTimeContainer: {
		zIndex: 9999,
		flexDirection: 'row',
		height: 50,
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1
	}
});
