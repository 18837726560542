//@flow
import React, { useEffect } from 'react';
import { View, FlatList } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { RedditThreads } from './components/RedditThreads';
import { fetchPosts } from './actions/FeedActions';
import { Loader } from 'Components/common';

export const RecipeFeedScreen = (props) => {
	const dispatch = useDispatch();
	const posts = useSelector((state) => state.reddit.items);
	const isFetching = useSelector((state) => state.reddit.isFetching);
	const { theme } = useSelector((state) => state.startup);

	useEffect(() => {
		dispatch(fetchPosts());
	}, []);

	if (isFetching && posts.length === 0)
		return (
			<Loader
				full={true}
				loaderBG={theme.baseBG}
				loaderColor={theme.darkFont}
			/>
		);

	if (posts.length > 0)
		return (
			<FlatList
				data={posts}
				keyExtractor={(item) => item.id}
				renderItem={({ item }) => (
					<RedditThreads post={item} id={item.id} theme={theme} />
				)}
				style={{
					opacity: isFetching ? 0.5 : 1,
					backgroundColor: theme.backdropGray
				}}
			/>
		);

	return <View />;
};
