//@flow
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { i18n } from 'Theme';
import {
	NavOptionsHeader,
	NavOptionsHeaderNoDraw,
	NavOptionsBottomTab
} from '../';
import { FontAwesome } from '@expo/vector-icons';
import { HeaderMenuDropdown } from 'Components/common';
import { CustomFoodTab } from 'Components/tracker/foodtabs/CustomFoodTab';
import { CustomFoodTabSingle } from 'Components/tracker/foodtabs/CustomFoodTabSingle';
import { CustomMealTab } from 'Components/tracker/foodtabs/CustomMealTab';
import { CustomMealTabSingle } from 'Components/tracker/foodtabs/CustomMealTabSingle';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const MyFoodStack = () => {
	return (
		<Stack.Navigator initialRouteName="ManageFood">
			<Stack.Screen
				name="ManageFood"
				component={CustomFoodTab}
				initialParams={{ manage: true }}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('manageFoods')}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="ManageFoodSingle"
				component={CustomFoodTabSingle}
				initialParams={{ manage: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('manageFoods')}
								right={<HeaderMenuDropdown array={params.menuArray} />}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const MyMealStack = () => {
	return (
		<Stack.Navigator initialRouteName="ManageMeal">
			<Stack.Screen
				name="ManageMeal"
				component={CustomMealTab}
				initialParams={{ manage: true }}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('manageMeals')}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="ManageMealSingle"
				component={CustomMealTabSingle}
				initialParams={{ manage: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('manageMeals')}
								right={<HeaderMenuDropdown array={params.menuArray} />}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const ManageRouter = ({ route }) => {
	return (
		<Tab.Navigator
			initialRouteName="ManageFood"
			backBehavior={'none'}
			lazy={true}
			swipeEnabled={false}
			tabBarPosition={'bottom'}
			tabBar={(props) => <NavOptionsBottomTab {...props} />}>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('foods'),
					gesturesEnabled: false,
					tabBarIcon: ({ color, size }) => (
						<FontAwesome name={'spoon'} size={20} color={color} />
					)
				}}
				name="ManageFood"
				component={MyFoodStack}
			/>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('meals'),
					tabBarIcon: ({ color, size }) => (
						<FontAwesome name={'cutlery'} size={20} color={color} />
					)
				}}
				name="ManageMeal"
				component={MyMealStack}
			/>
		</Tab.Navigator>
	);
};

export default ManageRouter;
