//@flow
import React from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import { Text, ClickableHeader, Collapsible } from 'Components/common';
import { i18n } from 'Theme';
import type { ingredientType } from 'Utilities/FlowTypes';

type Props = {
	ingredients: Array<ingredientType>,
	viewMore: boolean,
	toggle: Function,
	theme: Object,
	noCollapse?: boolean
};

export const Ingredients = (props: Props) => {
	const { ingredients, toggle, viewMore, noCollapse = false, theme } = props;

	const renderItem = ({ item }) => {
		return (
			<View
				style={[
					styles.mealItemsContainer,
					{ borderBottomColor: theme.border }
				]}>
				<Text style={styles.textStyle}>{item.text}</Text>
			</View>
		);
	};

	if (noCollapse)
		return (
			<FlatList
				data={ingredients}
				keyExtractor={(item) => item.key}
				renderItem={renderItem}
			/>
		);

	return (
		<View>
			<ClickableHeader
				leftText={`${i18n.t('ingredients')}:`}
				noMargin
				onPress={() => toggle()}
				rightIconName={!viewMore ? 'arrow-right' : 'arrow-down'}
				style={{ backgroundColor: theme.base }}
				iconColor={theme.darkFont}
			/>
			<Collapsible collapsed={!viewMore}>
				<FlatList
					data={ingredients}
					keyExtractor={(item) => item.key}
					renderItem={renderItem}
				/>
			</Collapsible>
		</View>
	);
};

const styles = StyleSheet.create({
	mealItemsContainer: {
		paddingHorizontal: 18,
		paddingVertical: 15,
		borderBottomWidth: 1
	},
	textStyle: {
		paddingLeft: 0,
		paddingRight: 0,
		fontSize: 12,
		lineHeight: 15
	}
});
