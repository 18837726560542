//@flow
import React, { useState, useEffect, useRef } from "react";
import { ScrollView, View, Platform, StyleSheet, SafeAreaView } from "react-native";
import NavigationBar from "react-native-navbar";
import { useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import { useForm, Controller } from "react-hook-form";
import { Text, HeaderTextButton, RenderSwitchSelector, RenderEdit, RenderListItemTextInput, Gram, ClickableHeader, Collapsible, Button } from "Components/common";
import { i18n } from "Theme";
import { validateNumber, isValidString, isValidTotalCarb } from "Utilities";
import { calculateCaloriesFromMacros } from "Utilities/MacrosUtil";
import { PreviousNextView } from "react-native-keyboard-manager";
import { defaultNutrientUnits, defaultNutrients } from "Redux/selectors";
import { Feature } from "Components/purchase/components";

type Props = {
	item: {
		name: string,
		calories: string,
		fats: string,
		proteins: string,
		carbs: string,
		totalcarbs: string,
		fiber: string,
		manualCalories: boolean,
		nutrientsV2: Object,
	},
	modalTitle: string,
	closeModal: Function,
	onSubmit: Function,
	confirmText?: string,
};

const options = [
	{ label: i18n.t("auto"), value: false },
	{ label: i18n.t("manual"), value: true },
];

export const EditingItemModal = (props: Props) => {
	const { control, handleSubmit, errors, watch, reset, getValues } = useForm();
	const navigation = useNavigation();

	const [calculatedCalories, setCalculatedCalories] = useState(props.item.calories);
	const [viewNutrients, toggleNutrients] = useState(false);
	const { theme } = useSelector((state) => state.startup);
	const { premium } = useSelector((state) => state.firebase.profile);
	const carbUnits = useSelector((state) => state.firebase.profile.settings.units.carbUnits);

	const mergedNutrients = { ...defaultNutrients, ...props.item.nutrientsV2 };

	const nameInput = useRef(null);
	const fatInput = useRef(null);
	const proteinInput = useRef(null);
	const carbInput = useRef(null);
	const totalCarbInput = useRef(null);
	const fiberInput = useRef(null);
	const caloriesInput = useRef(null);
	const itemsRef = useRef([]);

	useEffect(() => {
		itemsRef.current = itemsRef.current.slice(0, defaultNutrients.length);
	}, [props.item]);

	useEffect(() => {
		reset();
	}, [props.item]);

	useEffect(() => {
		const { fats, carbs, proteins, fiber, totalcarbs } = watch();

		let netCarb = 0;

		if (carbUnits === "total") {
			netCarb = +totalcarbs - +fiber;
		} else {
			netCarb = +carbs;
		}

		setCalculatedCalories(calculateCaloriesFromMacros(+fats, netCarb, +proteins, +fiber));
	}, [watch, carbUnits]);

	const conditionalSubmit = (formValues) => {
		let formattedValues = { ...formValues };
		formattedValues.name = formattedValues.name.trim();
		formattedValues.calories = +(+formattedValues.calories).toFixed(2);
		formattedValues.fats = +(+formattedValues.fats).toFixed(2);
		formattedValues.carbs = +(+formattedValues.carbs).toFixed(2);
		formattedValues.proteins = +(+formattedValues.proteins).toFixed(2);
		formattedValues.totalcarbs = +(+formattedValues.totalcarbs).toFixed(2);
		formattedValues.fiber = +(+formattedValues.fiber).toFixed(2);
		formattedValues.nutrientsV2 = {};

		if (typeof formValues.nutrientsV2 !== "undefined") {
			for (const [key, value] of Object.entries(formValues.nutrientsV2)) {
				if (value === "0") {
					delete formattedValues.nutrientsV2[key];
				} else {
					formattedValues.nutrientsV2[key] = +(+value).toFixed(2);
				}
			}
		}

		if (carbUnits === "total") {
			formattedValues.carbs = formattedValues.totalcarbs - formattedValues.fiber;
		} else {
			formattedValues.totalcarbs = formattedValues.carbs + formattedValues.fiber;
		}

		if (formattedValues.manualCalories !== true) {
			formattedValues.calories = calculatedCalories;
		}

		//Save Editing Item from Log Home and Custom Food Single, Needs the original Item as a parameter in order to use same ID and Name Change Check
		if (props.modalTitle === "Editing Item") {
			return props.onSubmit(formattedValues, props.item);
		}

		return props.onSubmit(formattedValues);
	};

	const renderForm = (item) => {
		//const { item } = props;
		const { manualCalories, fiber, fats, proteins, carbs, totalcarbs, nutrientsV2, calories } = watch();

		return (
			<View>
				<View style={[styles.foodHeader, { backgroundColor: theme.baseAlt, borderBottomColor: theme.border }]}>
					<View style={[styles.nameRow, { width: Platform.OS === "web" ? "100%" : null }]}>
						<Controller
							control={control}
							render={({ onChange, value }) => (
								<RenderEdit
									onChange={onChange}
									value={value}
									isError={errors.name}
									placeholder={i18n.t("name")}
									refInput={nameInput}
									onSubmitEditing={() => {
										fatInput.current.focus();
									}}
									inputContainerStyle={{
										backgroundColor: theme.baseBG,
										paddingLeft: 0,
										borderColor: theme.border,
									}}
									style={{
										color: theme.themeAccent,
										borderColor: theme.placeholderGray,
									}}
								/>
							)}
							name="name"
							rules={{
								required: true,
								validate: (value) => isValidString(value),
							}}
							defaultValue={item.name}
						/>
					</View>
				</View>
				<Controller
					control={control}
					render={({ onChange, value }) => (
						<RenderListItemTextInput
							onChange={onChange}
							value={value}
							isError={errors.fats}
							labelText={i18n.t("fat")}
							refInput={fatInput}
							onFocus={() =>
								fatInput.current.setNativeProps({
									text: "",
								})
							}
							onBlur={() =>
								fatInput.current.setNativeProps({
									text: fats,
								})
							}
							onSubmitEditing={() => {
								proteinInput.current.focus();
							}}
							style={{
								backgroundColor: theme.baseBG,
								borderColor: theme.border,
							}}
							textInputStyle={{
								color: theme.themeAccent,
								borderColor: theme.border,
							}}
						/>
					)}
					name="fats"
					rules={{
						required: true,
						validate: (value) => validateNumber(value),
					}}
					defaultValue={item.fats}
				/>
				<Controller
					control={control}
					render={({ onChange, value }) => (
						<RenderListItemTextInput
							onChange={onChange}
							value={value}
							isError={errors.proteins}
							labelText={i18n.t("protein")}
							refInput={proteinInput}
							onFocus={() =>
								proteinInput.current.setNativeProps({
									text: "",
								})
							}
							onBlur={() =>
								proteinInput.current.setNativeProps({
									text: proteins,
								})
							}
							onSubmitEditing={() => {
								carbUnits === "total" ? totalCarbInput.current.focus() : carbInput.current.focus();
							}}
							style={{
								backgroundColor: theme.baseBG,
								borderColor: theme.border,
							}}
							textInputStyle={{
								color: theme.themeAccent,
								borderColor: theme.border,
							}}
						/>
					)}
					name="proteins"
					rules={{
						required: true,
						validate: (value) => validateNumber(value),
					}}
					defaultValue={item.proteins}
				/>
				{carbUnits === "total" ? (
					<Controller
						control={control}
						render={({ onChange, value }) => (
							<RenderListItemTextInput
								onChange={onChange}
								value={value}
								isError={errors.totalcarbs}
								labelText={i18n.t("totalcarb")}
								errorMessage={i18n.t("totalcarbError")}
								refInput={totalCarbInput}
								onFocus={() =>
									totalCarbInput.current.setNativeProps({
										text: "",
									})
								}
								onBlur={() =>
									totalCarbInput.current.setNativeProps({
										text: totalcarbs,
									})
								}
								onSubmitEditing={() => {
									fiberInput.current.focus();
								}}
								style={{
									backgroundColor: theme.baseBG,
									borderColor: theme.border,
								}}
								textInputStyle={{
									color: theme.themeAccent,
									borderColor: theme.border,
								}}
							/>
						)}
						name="totalcarbs"
						rules={{
							required: true,
							validate: (value) => isValidTotalCarb(value, fiber),
						}}
						defaultValue={item.totalcarbs}
					/>
				) : (
					<Controller
						control={control}
						render={({ onChange, value }) => (
							<RenderListItemTextInput
								onChange={onChange}
								value={value}
								isError={errors.carbs}
								labelText={i18n.t("netcarb")}
								refInput={carbInput}
								onFocus={() =>
									carbInput.current.setNativeProps({
										text: "",
									})
								}
								onBlur={() =>
									carbInput.current.setNativeProps({
										text: carbs,
									})
								}
								onSubmitEditing={() => {
									fiberInput.current.focus();
								}}
								style={{
									backgroundColor: theme.baseBG,
									borderColor: theme.border,
								}}
								textInputStyle={{
									color: theme.themeAccent,
									borderColor: theme.border,
								}}
							/>
						)}
						name="carbs"
						rules={{
							required: true,
							validate: (value) => validateNumber(value),
						}}
						defaultValue={item.carbs}
					/>
				)}
				<Controller
					control={control}
					render={({ onChange, value }) => (
						<RenderListItemTextInput
							onChange={onChange}
							value={value}
							isError={errors.fiber}
							labelText={i18n.t("fiber")}
							refInput={fiberInput}
							onFocus={() =>
								fiberInput.current.setNativeProps({
									text: "",
								})
							}
							onBlur={() =>
								fiberInput.current.setNativeProps({
									text: fiber,
								})
							}
							onSubmitEditing={() => {
								//proteinInput.current.focus();
							}}
							style={{
								backgroundColor: theme.baseBG,
								borderColor: theme.border,
							}}
							textInputStyle={{
								color: theme.themeAccent,
								borderColor: theme.border,
							}}
						/>
					)}
					name="fiber"
					rules={{
						required: true,
						validate: (value) => validateNumber(value),
					}}
					defaultValue={item.fiber}
				/>
				<View style={[styles.secondRow, { borderBottomColor: theme.border, backgroundColor: theme.baseAlt }]}>
					<View style={[styles.macroBox, { backgroundColor: theme.baseAlt }]}>
						<Controller
							control={control}
							render={({ onChange, value }) => <RenderSwitchSelector onChange={onChange} value={value} options={options} buttonColor={theme.themeAccent} />}
							name="manualCalories"
							defaultValue={item.manualCalories}
						/>
					</View>
					<View style={[styles.macroBox, { backgroundColor: theme.baseAlt }]}>
						{manualCalories && (
							<Controller
								control={control}
								render={({ onChange, value }) => (
									<RenderEdit
										onChange={onChange}
										value={value}
										isError={errors.calories}
										placeholder={""}
										style={{ textAlign: "center" }}
										refInput={caloriesInput}
										keyboardType={"decimal-pad"}
										onSubmitEditing={() => {
											//proteinInput.current.focus();
										}}
										inputContainerStyle={{
											backgroundColor: theme.baseBG,
											paddingLeft: 0,
											borderColor: theme.placeholderGray,
										}}
										style={{
											color: theme.themeAccent,
										}}
									/>
								)}
								name="calories"
								rules={{
									required: true,
									validate: (value) => validateNumber(value),
								}}
								defaultValue={item.calories}
							/>
						)}
						{!manualCalories && !isNaN(calculatedCalories) && (
							<Text style={[styles.macroAmount, { color: theme.darkFont }]}>
								{calculatedCalories} <Gram style={{ color: theme.darkFont, fontSize: 10 }} customText={" kCal"} />
							</Text>
						)}
					</View>
				</View>
				<ClickableHeader
					leftText="Nutrients:"
					noMargin
					onPress={() => toggleNutrients(!viewNutrients)}
					rightIconName={!viewNutrients ? "arrow-right" : "arrow-down"}
					style={{ backgroundColor: theme.base }}
					iconColor={theme.darkFont}
				/>
				<Collapsible collapsed={!viewNutrients}>
					{!premium ? (
						<View>
							<Feature
								icon="check"
								title="Track Nutrients"
								body="With Premium, follow more than just your macros. Keep track of: Cholesterol, Sodium, Calcium, Magnesium, Potassium, Iron, Zinc, Phosphorus, Folate, Thiamin (B1), Riboflavin (B2), Niacin (B3), Vitamin B6, Vitamin B12, Vitamin A,C,D,E,K, "
							/>
							<Button
								onPress={() => {
									navigation.navigate("Purchase");
									closeModal();
								}}
								style={[styles.exportBtn, { backgroundColor: theme.teal }]}
							>
								{i18n.t("premium")}
							</Button>
						</View>
					) : (
						Object.keys(mergedNutrients).map((keyName, i) => {
							return (
								<Controller
									control={control}
									key={keyName}
									render={({ onChange, value }) => (
										<RenderListItemTextInput
											onChange={onChange}
											value={value}
											isError={errors.nutrientsV2 && errors.nutrientsV2[`${keyName}`]}
											labelText={`${keyName} (${defaultNutrientUnits[keyName]})`}
											refInput={(el) => (itemsRef.current[i] = el)}
											onFocus={() =>
												itemsRef.current[i].setNativeProps({
													text: "",
												})
											}
											onBlur={() =>
												itemsRef.current[i].setNativeProps({
													text: nutrientsV2[`${keyName}`],
												})
											}
											onSubmitEditing={() => {
												if (Object.keys(mergedNutrients).length - 1 !== i) {
													itemsRef.current[i + 1].focus();
												}
											}}
											style={{
												backgroundColor: theme.baseBG,
												borderColor: theme.border,
											}}
											textInputStyle={{
												color: theme.themeAccent,
												borderColor: theme.border,
											}}
										/>
									)}
									name={`nutrientsV2.${keyName}`}
									rules={{
										required: true,
										validate: (value) => validateNumber(value),
									}}
									defaultValue={mergedNutrients[keyName].toFixed(0)}
								/>
							);
						})
					)}
				</Collapsible>
			</View>
		);
	};

	const { closeModal, modalTitle, confirmText = `${i18n.t("add")} +`, item } = props;

	return (
		<>
			<SafeAreaView style={[styles.topCtn, { backgroundColor: theme.navBar }]} />
			<SafeAreaView style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<NavigationBar
					statusBar={Platform.OS === "ios" ? { hidden: false } : { hidden: true }}
					title={{
						title: modalTitle,
						style: {
							color: theme.navText,
							fontFamily: "Comfortaa",
							fontSize: 14,
						},
					}}
					containerStyle={{
						backgroundColor: theme.navBar,
						justifyContent: "center",
						paddingHorizontal: 10,
					}}
					leftButton={<HeaderTextButton title={i18n.t("cancel")} onPress={closeModal} />}
					rightButton={<HeaderTextButton title={confirmText} onPress={handleSubmit((values) => conditionalSubmit(values))} />}
				/>
				<ScrollView keyboardShouldPersistTaps="handled" bounces={false}>
					<PreviousNextView>{renderForm(item)}</PreviousNextView>
				</ScrollView>
			</SafeAreaView>
		</>
	);
};

const styles = StyleSheet.create({
	foodHeader: {
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		paddingTop: 20,
		paddingBottom: 10,
		borderBottomWidth: 1,
	},
	nameRow: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		paddingVertical: 20,
	},
	secondRow: {
		flexDirection: "row",
		alignItems: "center",
		borderBottomWidth: 1,
		paddingVertical: 20,
		paddingHorizontal: 10,
		height: 100,
	},
	macroBox: {
		alignItems: "center",
		justifyContent: "center",
		height: 50,
		flex: 1,
		marginHorizontal: 7,
	},
	macroAmount: {
		fontFamily: "Comfortaa",
		fontSize: 14,
	},
});
