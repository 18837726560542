//@flow
import React, { useEffect, useState } from 'react';
import { View, Keyboard, FlatList, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RecipeThread } from './components/search';
import {
	clearEdamamRecipes,
	fetchEdamamRecipes,
	loadMoreRecipes
} from './actions';
import { SearchBar, Text, Loader, WhiteButton } from 'Components/common';
import { i18n } from 'Theme';
import { FilterModal } from './FilterModal';

export const RecipeScreen = ({ navigation }) => {
	const dispatch = useDispatch();
	const { theme } = useSelector((state) => state.startup);
	const [filterModal, toggleFilterModal] = useState(false);
	const searchResults = useSelector(
		(state) => state.edamamRecipeApi.searchResults
	);

	const isFetching = useSelector((state) => state.edamamRecipeApi.isFetching);
	const loadMoreSearchText = useSelector(
		(state) => state.edamamRecipeApi.loadMoreSearchText
	);
	const canLoadMore = useSelector((state) => state.edamamRecipeApi.canLoadMore);
	const units = useSelector((state) => state.firebase.profile.settings.units);
	const { mealType, cuisineType } = useSelector(
		(state) => state.edamamRecipeApi.filter
	);

	const renderItem = ({ item }) => {
		return <RecipeThread item={item} units={units} theme={theme} />;
	};

	useEffect(() => {
		if (searchResults.length === 0) {
			dispatch(fetchEdamamRecipes('lunch', mealType, cuisineType));
		}
	}, []);

	useEffect(() => {
		navigation.setParams({
			headerBtns: [
				{
					size: 24,
					name: 'filter',
					color: theme.navButton,
					onPress: () => toggleFilterModal(true)
				}
			]
		});
	}, []);

	//Submit function for Search Bar, Gets Results and Clears Last Search
	const onSubmit = (text) => {
		Keyboard.dismiss();
		dispatch(clearEdamamRecipes());
		dispatch(fetchEdamamRecipes(text, mealType, cuisineType));
	};

	const returnFlatListHeader = () => {
		if (!isFetching && searchResults.length === 0 && loadMoreSearchText !== '')
			return (
				<View>
					<SearchBar
						onSubmit={(text) => onSubmit(text)}
						placeholder={'"Lunch"'}
						theme={theme}
					/>
					<Text style={styles.noResults}>{i18n.t('zeroResults')}</Text>
				</View>
			);

		return (
			<SearchBar
				onSubmit={(text) => onSubmit(text)}
				placeholder={'"Lunch"'}
				theme={theme}
			/>
		);
	};

	return (
		<View style={{ flex: 1, backgroundColor: theme.backdropGray }}>
			<FlatList
				data={searchResults}
				keyExtractor={(item, index) => `${item.recipe.label}-${index}`}
				renderItem={renderItem}
				style={styles.flatList}
				horizontal={false}
				numColumns={1}
				ListHeaderComponent={returnFlatListHeader()}
				ListFooterComponent={
					searchResults.length > 0 &&
					canLoadMore &&
					!isFetching && (
						<WhiteButton onPress={() => dispatch(loadMoreRecipes())}>
							{i18n.t('loadMore')}
						</WhiteButton>
					)
				}
			/>

			{isFetching && (
				<Loader
					full={true}
					loaderBG={theme.baseBG}
					loaderColor={theme.darkFont}
				/>
			)}
			<FilterModal isOpen={filterModal} onClose={toggleFilterModal} />
		</View>
	);
};

const styles = StyleSheet.create({
	noResults: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
		flex: 1,
		marginTop: 100
	},
	container: {
		flex: 1,
		flexDirection: 'column'
	}
});
