//@flow
import React, { useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { ProgressPieMacros } from 'Components/tracker/progress/components/ProgressPieMacros';
import { Text } from 'Components/common';
import { i18n } from 'Theme';

const { width } = Dimensions.get('window');

type Props = {
	data: Object,
	carbUnits: string,
	settings: Object,
	theme: Object
};

export const LogViewPieChart = (props: Props) => {
	const [activeLabels, toggleLabels] = useState(false);

	const { data, carbUnits, settings, theme } = props;
	const { macrosEaten } = data;

	return (
		<View style={styles.container}>
			<Text style={styles.chartHeadingText}>
				{i18n.t('caloriesFromMacros')}
			</Text>
			<ProgressPieMacros
				data={macrosEaten}
				width={width}
				height={300}
				activeLabels={activeLabels}
				onClick={() => toggleLabels(activeLabels)}
				carbUnits={carbUnits}
				settings={settings}
				showGoals={true}
				theme={theme}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	chartHeadingText: {
		alignSelf: 'center',
		paddingVertical: 10,
		fontFamily: 'Raleway',
		fontWeight: '900'
	}
});
