import { StyleSheet } from 'react-native';

export default StyleSheet.create({
	buttonRow: {
		justifyContent: 'center',
		alignItems: 'flex-start',
		flexDirection: 'row'
	},
	buttonStyle: {
		flex: 1,
		margin: 0
	}
});
