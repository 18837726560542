//@flow
import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import Elevations from 'react-native-elevation';
import { Text } from './';

export const ListHeaderSorting = (props) => {
	return (
		<View
			style={[
				styles.container,
				props.noMargin ? { marginTop: 0 } : { marginTop: 25 },
				props.style,
				{ backgroundColor: props.theme.base }
			]}>
			<View style={styles.leftButtonCtn}>
				{props.leftPress && (
					<TouchableOpacity
						style={[styles.iconBtn, { backgroundColor: props.theme.baseAlt }]}
						onPress={() => props.leftPress()}>
						<Icon name={'history'} size={16} color={props.theme.darkFont} />
					</TouchableOpacity>
				)}
				{props.leftPressTwo && (
					<TouchableOpacity
						style={[styles.iconBtn, { backgroundColor: props.theme.baseAlt }]}
						onPress={() => props.leftPressTwo()}>
						<Icon
							name={props.leftIconTwo}
							size={16}
							color={props.theme.darkFont}
						/>
					</TouchableOpacity>
				)}
			</View>
			{props.rightPress && (
				<TouchableOpacity
					style={[styles.btn, { backgroundColor: props.theme.themeAccent }]}
					onPress={() => props.rightPress()}>
					<Text style={[styles.rightTitle, { color: props.theme.white }]}>
						{props.rightText}
					</Text>
					<Icon
						name={props.rightIconName}
						size={16}
						color={props.theme.white}
					/>
				</TouchableOpacity>
			)}
		</View>
	);
};

var styles = StyleSheet.create({
	container: {
		height: 50,
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 1,
		...Elevations[2]
	},
	leftButtonCtn: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	rightTitle: {
		paddingRight: 10,
		alignSelf: 'center'
	},
	btn: {
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		paddingVertical: 5,
		paddingHorizontal: 10,
		borderRadius: 5,
		marginHorizontal: 10
	},
	iconBtn: {
		alignItems: 'center',
		padding: 10,
		width: 40,
		height: 40,
		marginHorizontal: 10,
		borderRadius: 5,
		...Elevations[2]
	}
});
