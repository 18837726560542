//@flow
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { Text } from 'Components/common';
import { openWheel } from 'Utilities/ServingSelectorLogic';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
	servingWheelChange,
	servingWheelCancel,
	servingWheelConfirm
} from 'Components/fatsecret/actions';

type Props = {
	theme: Object
};

export const FsServingSelectorWheel = (props: Props) => {
	const dispatch = useDispatch();
	const { theme } = props;
	const reducerData = useSelector((state) => state.fatSecretSingleFood);
	const { placeholder, finalServingLabels, lastValueConfirmed } = reducerData;

	return (
		<View
			style={[
				styles.servingSelectContainer,
				{ borderBottomColor: theme.border }
			]}>
			<TouchableOpacity
				style={[styles.wheelServingHolder, { backgroundColor: theme.baseBG }]}
				onPress={() =>
					openWheel(
						finalServingLabels,
						(x) => dispatch(servingWheelConfirm(x)),
						(x) => dispatch(servingWheelCancel(x)),
						(x) => dispatch(servingWheelChange(x)),
						lastValueConfirmed,
						theme
					)
				}>
				<Text style={[styles.wheelServingQty, { color: theme.themeAccent }]}>
					{placeholder}
				</Text>
				<MaterialCommunityIcons
					style={[styles.wheelButton, { color: theme.themeAccent }]}
					name={'counter'}
					size={20}
				/>
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	servingSelectContainer: {
		flex: 1,
		flexDirection: 'column',
		alignSelf: 'stretch',
		borderBottomWidth: 1
	},
	wheelServingHolder: {
		flex: 1,
		height: 60,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	},
	wheelServingQty: {
		flex: 4,
		paddingLeft: 20,
		paddingRight: 20,
		fontSize: 12,
		lineHeight: 15
	},
	wheelButton: {
		borderRadius: 3,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 0,
		marginLeft: 10,
		marginRight: 10,
		marginBottom: 0
	}
});
