//@flow
import { Alert as AlertRN, Platform } from 'react-native';
import { i18n } from 'Theme';

export const WebCompatAlert = (
	title: string,
	message: string,
	onConfirmText?: string,
	onConfirm?: Function
) => {
	if (Platform.OS === 'web') {
		if (window.confirm(message)) {
			if (onConfirm) {
				onConfirm();
			}
		}
	} else {
		AlertRN.alert(
			title,
			message,
			[
				{
					text: i18n.t('cancel'),
					onPress: () => false
				},
				{
					text: onConfirmText || 'OK',
					onPress: () => onConfirm && onConfirm()
				}
			],
			{ cancelable: false }
		);
	}
};
