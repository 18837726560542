import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '../../components/common';
import Icon from 'react-native-vector-icons/Ionicons';

export const SideMenuItem = (props) => {
	const { color, name, children } = props;
	return (
		<View style={styles.itemContainer}>
			{name && (
				<Icon
					style={[styles.iconStyle, { color: color }]}
					name={name}
					size={20}
				/>
			)}
			<Text style={[styles.itemText, { color: color }]}>{children}</Text>
		</View>
	);
};

var styles = StyleSheet.create({
	itemContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: 20
	},
	iconStyle: {
		width: 30
	},
	itemText: {
		paddingLeft: 7,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 14
	}
});
