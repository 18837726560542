//@flow
import React from 'react';
import { StyleSheet, View, Switch, Platform } from 'react-native';
import { Text } from 'Components/common';

const thumbTintColor = Platform.OS === 'ios' ? null : '#FFF';

export const OptionSwitch = (props) => {
	const { onSwitch, switchValue, theme } = props;
	return (
		<View style={[styles.otherItem, { borderTopColor: theme.border }]}>
			<Text style={styles.switchText}>{props.text}</Text>
			<Switch
				style={styles.switchStyle}
				onValueChange={(value) => onSwitch(value)}
				value={switchValue}
				trackColor={theme.grey}
				onTintColor={theme.themeAccent}
				thumbColor={thumbTintColor}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	otherItem: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingTop: 15,
		paddingBottom: 15,
		borderTopWidth: 1,
		alignItems: 'center',
		paddingLeft: 15,
		paddingRight: 10
	},
	switchText: {
		flex: 3
	},
	switchStyle: {
		alignSelf: 'center'
	}
});
