//@flow
import React, { useState } from 'react';
import {
	View,
	TouchableOpacity,
	ImageBackground,
	Image,
	ScrollView
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
	Prompt,
	Loader,
} from 'Components/common';
import { forgotPassword } from './actions/FirebaseActionsAuth';
import { LoginForm } from './components';
import { Images, i18n } from 'Theme';
import styles from './Styles/Styles';

export const LoginScreen = () => {
	const dispatch = useDispatch();
	const [forgotModal, toggleForgotPw] = useState(false);
	const { auth, profile } = useSelector((state) => state.firebase);
	const { theme } = useSelector((state) => state.startup);
	//Sends forgotten password reset email to user
	const forgotPasswordSubmit = (values) => {
		dispatch(forgotPassword(values)).then(() => toggleForgotPw(false));
	};

	if (auth && auth.uid && profile.isEmpty) {
		return (
			<ImageBackground
				source={Images.login}
				style={{ width: '100%', height: '100%' }}>
				<Loader full={true} />
			</ImageBackground>
		);
	}
	return (
		<ImageBackground
			source={Images.login}
			style={{ width: '100%', height: '100%' }}>
			<ScrollView
				contentContainerStyle={{
					flexGrow: 1,
					justifyContent: 'space-between'
				}}
				keyboardShouldPersistTaps={'handled'}>
				<View style={styles.logoContainer}>
					<Image source={Images.logoText} style={styles.logoStyle} />
				</View>
				<LoginForm
					isVisible={false}
					toggleForgotPw={() => toggleForgotPw(true)}
				/>
				<Prompt
					title={i18n.t('forgotPassword')}
					placeholder={i18n.t('email')}
					defaultValue=""
					visible={forgotModal}
					onCancel={() => toggleForgotPw(false)}
					onSubmit={(value) => forgotPasswordSubmit(value)}
					onClosed={() => toggleForgotPw(false)}
					submitText={i18n.t('send')}
					theme={theme}
				/>
			</ScrollView>
		</ImageBackground>
	);
};
