// @flow

export const estimateExercise = (
	weight: number,
	wUnits: string,
	time: number,
	tUnits: string,
	activity: Object,
	userPrefkCal: boolean
) => {
	let estimatedCalories;
	let formatteddWeight;
	let formattedTime;
	//console.log(weight, time, activity);
	if (!weight || isNaN(weight) || +weight <= 0) {
		estimatedCalories = 0;
		return estimatedCalories;
	}

	if (!time || isNaN(time) || +time <= 0) {
		estimatedCalories = 0;
		return estimatedCalories;
	}

	//Weight needs to be in lbs
	formatteddWeight = wUnits === 'kg' ? weight / 0.453592 : weight;

	//Time needs to be in Hrs
	formattedTime = tUnits === 'hrs' ? time : time / 60;

	//Equation for calories
	estimatedCalories = formatteddWeight * formattedTime * activity.value;
	if (userPrefkCal) {
		return estimatedCalories.toFixed(0);
	} else {
		return (estimatedCalories * 4.184).toFixed(0);
	}
};
