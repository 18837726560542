//@flow
import React from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import { Text, ListHeader } from 'Components/common';
import { i18n } from 'Theme';
import Elevations from 'react-native-elevation';

type Props = {
	changeServingSize: Function,
	changeServingName: Function,
	changeItemName: Function,
	servingSize: string,
	servingName: string,
	itemName: string,
	theme: Object
};

export const HeaderEditing = (props: Props) => {
	const {
		changeServingSize,
		changeItemName,
		changeServingName,
		servingSize,
		itemName,
		servingName,
		theme
	} = props;
	return (
		<View style={[styles.nameServContainer, { backgroundColor: theme.base }]}>
			<View
				style={[
					styles.inputRow,
					{ backgroundColor: theme.base, borderBottomColor: theme.border }
				]}>
				<Text style={[styles.inputLabel]}>{i18n.t('mealName')}:</Text>
				<TextInput
					style={[
						styles.textInput,
						{
							borderLeftColor: theme.base,
							color: theme.themeAccent,
							backgroundColor: theme.baseBG
						}
					]}
					onChangeText={(value) => changeItemName(value)}
					value={itemName}
					returnKeyType={'next'}
					keyboardType={'default'}
					underlineColorAndroid="rgba(0,0,0,0)"
				/>
			</View>
			<View
				style={[
					styles.inputRow,
					{ backgroundColor: theme.base, borderBottomColor: theme.border }
				]}>
				<Text style={styles.inputLabel}>{i18n.t('servingName')}:</Text>
				<TextInput
					style={[
						styles.textInput,
						{
							borderLeftColor: theme.base,
							color: theme.themeAccent,
							backgroundColor: theme.baseBG
						}
					]}
					onChangeText={(value) => changeServingName(value)}
					value={servingName}
					keyboardType={'default'}
					underlineColorAndroid="rgba(0,0,0,0)"
				/>
			</View>
			<View
				style={[
					styles.inputRow,
					{ backgroundColor: theme.base, borderBottomColor: theme.border }
				]}>
				<Text style={styles.inputLabel}>{i18n.t('totalServings')}:</Text>
				<TextInput
					style={[
						styles.textInput,
						{
							borderLeftColor: theme.base,
							color: theme.themeAccent,
							backgroundColor: theme.baseBG
						}
					]}
					onChangeText={(value) => changeServingSize(value)}
					value={servingSize}
					keyboardType={'decimal-pad'}
					underlineColorAndroid="rgba(0,0,0,0)"
					autoCapitalize="none"
				/>
			</View>
			<ListHeader
				leftText={`${i18n.t('wholeRecipe')}:`}
				noMargin
				rightIconColor={theme.darkFont}
				style={{ backgroundColor: theme.base }}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	nameServContainer: {
		elevation: 1.3,
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginBottom: 1,
		...Elevations[4]
	},
	inputRow: {
		flex: 1,
		paddingRight: 10,
		flexDirection: 'row',
		alignItems: 'center',
		borderBottomWidth: 1
	},
	inputLabel: {
		width: 150,
		paddingLeft: 20,
		fontSize: 13,
		alignSelf: 'center'
	},
	textInput: {
		flex: 1,
		height: 60,
		borderLeftWidth: 1,
		padding: 10,
		textAlign: 'center',
		fontFamily: 'Raleway',
		fontSize: 15
	}
});
