//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Text } from 'Components/common';
import Icon from 'react-native-vector-icons/FontAwesome';

type Props = {
	onPress: Function,
	noBorderTop?: boolean,
	text: string,
	iconColor: string,
	borderColor: string,
	textStyle?: Object
};

export const SettingsLink = (props: Props) => {
	const {
		onPress,
		noBorderTop,
		text,
		iconColor,
		borderColor,
		textStyle
	} = props;
	return (
		<TouchableOpacity
			onPress={onPress}
			style={[
				styles.otherItem,
				{ borderTopColor: borderColor },
				noBorderTop ? { borderTopWidth: 0 } : {}
			]}>
			<Text style={[{ flex: 1 }, textStyle]}>{text}</Text>
			<Icon style={styles.iconStyle} name="chevron-right" color={iconColor} />
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	otherItem: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingTop: 16,
		paddingBottom: 16,
		borderTopWidth: 1,
		paddingLeft: 15
	},
	iconStyle: {
		alignSelf: 'center',
		justifyContent: 'flex-end',
		paddingRight: 15
		//flex: 0.1
	}
});
