//@flow
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	View,
	TouchableOpacity,
	FlatList,
	StyleSheet,
	Dimensions,
	ScrollView
} from 'react-native';
import { Text, Gram, WebCompatAlert, ClickableHeader } from 'Components/common';
import {
	setBMProgressFilter,
	loadBodyMeasurementProgress,
	setBMProgressFromDate,
	setBMProgressToDate
} from './actions/ProgressActions';
import { i18n } from 'Theme';
import { FontAwesome } from '@expo/vector-icons';
import {
	moderateScale,
	returnCorrectUnitsString,
	getDates,
	returnCorrectUnitsValue
} from 'Utilities';
import {
	getActiveMeasurements,
	defaultMeasurementUnits,
	getBodyMeasurementChartData
} from 'Redux/selectors';
import {
	saveBodyMeasurement,
	deleteBodyMeasurement,
	changeReviewName
} from 'Redux/actions';
import {
	ActiveItemFilter,
	ProgressLine,
	ProgressDatePicker,
	ProgressFilter
} from './components';
import { EditModal } from 'Components/tracker/bodymeasurements/EditModal';
import moment from 'moment';
import * as Animatable from 'react-native-animatable';

const { width } = Dimensions.get('window');

export const BodyProgressScreen = (props) => {
	const dispatch = useDispatch();
	const [isModalVisible, toggleModal] = useState(false);
	const [viewMore, toggleViewMore] = useState(false);
	const [editingText, setEditingText] = useState('0.0');
	const [editingDate, setEditingDate] = useState(moment().format('YYYY-MM-DD'));

	const {
		settings: {
			units: { bodymeasurementsUnits }
		},
		bodymeasurements,
		profilePage: { goalWeight }
	} = useSelector((state) => state.firebase.profile);
	const { theme } = useSelector((state) => state.startup);
	const { auth } = useSelector((state) => state.firebase);
	const { premium } = useSelector((state) => state.firebase.profile);
	const {
		progressBMFilter,
		progressBMFromDate,
		progressBMToDate
	} = useSelector((state) => state.progress);

	const {
		activeReviewIndex,
		activeReviewLabel,
		activeReviewValue
	} = useSelector((state) => state.bodymeasurements);

	const activeData = useSelector((state) => getActiveMeasurements(state));
	const bodyChartsData = useSelector((state) =>
		getBodyMeasurementChartData(state)
	);

	// Re-run the filter whenever the list array or filter text changes:
	const changeData = useMemo(
		() => calculateMeasurementChangesOverTime(activeData),
		[activeData]
	);

	const realGoalWeight = returnCorrectUnitsValue(
		'weight',
		bodymeasurementsUnits,
		goalWeight
	).toFixed(0);

	let filterItems = [];
	filterItems = premium
		? [
				`${i18n.t('Weight')}`,
				`${i18n.t('BodyFat')}`,
				`${i18n.t('BMI')}`,
				`${i18n.t('LeanBodyMass')}`,
				`${i18n.t('WaistSize')}`,
				`${i18n.t('HipSize')}`,
				`${i18n.t('ChestSize')}`,
				`${i18n.t('NeckSize')}`,
				`${i18n.t('ArmSize')}`,
				`${i18n.t('ForearmSize')}`,
				`${i18n.t('CalfSize')}`,
				`${i18n.t('ThighSize')}`
		  ]
		: [`${i18n.t('Weight')}`, `${i18n.t('BodyFat')}`];

	useEffect(() => {
		dispatch(
			loadBodyMeasurementProgress(
				progressBMFilter,
				progressBMFromDate,
				progressBMToDate
			)
		);
	}, [progressBMFilter, progressBMFromDate, progressBMToDate]);

	const editItem = (date, value) => {
		//Preset the editing modal to have the correct items name, text (value)
		setEditingText(value);
		setEditingDate(date);
		toggleModal(true);
	};

	//On close, reset all editing forms and close modal
	const _onClose = () => {
		setEditingText('0.0');
		setEditingDate(moment().format('YYYY-MM-DD'));
		toggleModal(false);
	};

	//Saves measurement to Firebase
	const saveEdit = (name, unitType) => {
		//Remove , which non-usa countries use as a decimal because ","" does not work in firebase saving
		const formattedValue = editingText.replace(',', '.');
		//Make sure value is a real number
		if (isNaN(formattedValue) || +formattedValue <= 0) {
			return WebCompatAlert(i18n.t('error'), i18n.t('enterNumber'));
		}
		//Save value to Firebase node and close modal
		dispatch(saveBodyMeasurement(editingDate, name, formattedValue, unitType));
		return _onClose();
	};

	const renderItem = ({ item, index }) => {
		return (
			<View
				style={
					index % 2 === 0
						? styles.itemContainer
						: [styles.itemContainerOdd, { backgroundColor: theme.grey }]
				}>
				<TouchableOpacity
					style={styles.itemTitle}
					onPress={() => editItem(item.date, item.formattedValue)}>
					<View style={styles.valueCtn}>
						<Text style={[styles.valueText, { color: theme.blue }]}>
							{item.formattedValue}
						</Text>
						<Gram customText={item.units} />
					</View>
					<Text style={styles.timeText}>{item.formattedDate}</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={[
						styles.quickDelete,
						{ backgroundColor: theme.grey, borderLeftColor: theme.border }
					]}
					onPress={() =>
						dispatch(deleteBodyMeasurement(item.date, activeReviewLabel))
					}>
					<FontAwesome
						name="close"
						size={14}
						color={theme.red}
						style={styles.quickDeleteIcon}
					/>
				</TouchableOpacity>
			</View>
		);
	};

	const returnColoredValue = (value) => {
		if (+value >= 0) {
			return (
				<Text style={[styles.changeBoxValue, { color: theme.green }]}>
					<FontAwesome
						name={'caret-down'}
						size={8}
						color={theme.green}
						style={styles.statsIcon}
					/>{' '}
					{value}{' '}
					{returnCorrectUnitsString(
						defaultMeasurementUnits[activeReviewValue],
						bodymeasurementsUnits
					)}
				</Text>
			);
		} else {
			return (
				<Text style={[styles.changeBoxValue, { color: theme.red }]}>
					<FontAwesome
						name={'caret-up'}
						size={8}
						color={theme.red}
						style={styles.statsIcon}
					/>{' '}
					{Math.abs(+value)}{' '}
					{returnCorrectUnitsString(
						defaultMeasurementUnits[activeReviewValue],
						bodymeasurementsUnits
					)}
				</Text>
			);
		}
	};

	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<View style={styles.filterBlock}>
				<ProgressFilter
					progressFilter={progressBMFilter}
					setProgressFilter={setBMProgressFilter}
					style={{
						borderBottomWidth: 1,
						borderBottomColor: theme.border,
						height: 50
					}}
					theme={theme}
				/>
				{progressBMFilter === 6 && (
					<ProgressDatePicker
						progressFromDate={progressBMFromDate}
						setFromDate={setBMProgressFromDate}
						progressToDate={progressBMToDate}
						setToDate={setBMProgressToDate}
						theme={theme}
					/>
				)}
				<ActiveItemFilter
					activeReviewIndex={activeReviewIndex}
					activeReviewLabel={activeReviewLabel}
					changeReviewName={changeReviewName}
					addEntry={() => toggleModal(true)}
					filterItems={filterItems}
					theme={theme}
				/>
			</View>
			<ProgressLine
				xLabel={i18n.t('date')}
				yLabel={returnCorrectUnitsString(
					defaultMeasurementUnits[activeReviewValue],
					bodymeasurementsUnits
				)}
				data={bodyChartsData}
				progressFilter={progressBMFilter}
				width={width}
				theme={theme}
				//goalWeight={+realGoalWeight}
			/>
			<View style={[styles.dividerBtm, { borderColor: theme.teal }]} />
			<View style={styles.changeBoxesCtn}>
				<View style={styles.changeBox}>
					<Text style={styles.changeBoxTitle}>1 {i18n.t('week')}</Text>
					<Text style={styles.changeBoxValue}>
						{returnColoredValue(changeData.week)}
					</Text>
				</View>
				<View style={styles.changeBox}>
					<Text style={styles.changeBoxTitle}>1 {i18n.t('month')}</Text>
					<Text style={styles.changeBoxValue}>
						{returnColoredValue(changeData.month)}
					</Text>
				</View>
				<View style={styles.changeBox}>
					<Text style={styles.changeBoxTitle}>{i18n.t('allTime')}</Text>
					<Text style={styles.changeBoxValue}>
						{returnColoredValue(changeData.allTime)}
					</Text>
				</View>
			</View>
			<View style={[styles.divider, { borderColor: theme.teal }]} />
			<ClickableHeader
				leftText={i18n.t('viewAllData')}
				noMargin
				onPress={() => toggleViewMore(!viewMore)}
				rightIconName={!viewMore ? 'arrow-right' : 'arrow-down'}
				style={{ backgroundColor: theme.base }}
				iconColor={theme.darkFont}
			/>
			<Animatable.View animation={viewMore ? 'fadeInLeft' : 'fadeOutRight'}>
				<FlatList
					data={viewMore ? activeData : []}
					keyExtractor={(item) => item.date}
					renderItem={renderItem}
					style={{ flex: 1 }}
				/>
			</Animatable.View>
			<EditModal
				isModalVisible={isModalVisible}
				onClose={() => _onClose()}
				onChangeValue={(value) => setEditingText(value)}
				saveEdit={(name, unitType) => saveEdit(name, unitType)}
				value={editingText}
				name={activeReviewValue}
				bodymeasurementsUnits={bodymeasurementsUnits}
				unitsArray={props.unitsArray}
				date={editingDate}
				changeDate={setEditingDate}
				theme={theme}
			/>
		</ScrollView>
	);
};

//Default unit types to use for formatting correct value based on user's unit preference (lbs vs kg, in vs cm, etc)
BodyProgressScreen.defaultProps = { unitsArray: defaultMeasurementUnits };

const styles = StyleSheet.create({
	container: {},
	filterBlock: {
		zIndex: 9999
	},
	itemContainer: {
		flex: 1,
		flexDirection: 'row'
	},
	itemContainerOdd: {
		flexDirection: 'row'
	},
	itemTitle: {
		flex: 4,
		paddingVertical: 10,
		paddingLeft: 20,
		paddingRight: 8,
		alignItems: 'flex-start'
	},
	valueCtn: {
		alignItems: 'center',
		flexDirection: 'row'
	},
	valueText: {
		fontSize: 16,
		textAlign: 'left',
		lineHeight: 20,
		paddingRight: moderateScale(5)
	},
	timeText: {
		fontSize: 13,
		textAlign: 'left',
		lineHeight: 13,
		paddingVertical: 5
	},
	quickDelete: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'stretch',
		flex: 0.75,
		paddingVertical: 15,
		borderLeftWidth: 1
	},
	quickDeleteIcon: {
		alignSelf: 'center'
	},
	center: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1
	},
	chartTitle: {
		fontSize: 14,
		textAlign: 'center'
	},
	divider: {
		width: width * 0.75,
		borderWidth: 1,
		height: 1,
		alignSelf: 'center',
		marginVertical: 30
	},
	dividerBtm: {
		width: width * 0.75,
		borderWidth: 1,
		height: 1,
		alignSelf: 'center',
		marginBottom: 30
	},
	changeBoxesCtn: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		marginVertical: 10
	},
	changeBox: {
		padding: 15,
		borderRadius: 5
	},
	changeBoxTitle: {
		fontSize: 14,
		fontFamily: 'Comfortaa'
	},

	changeBoxValue: {
		fontSize: 10,
		textAlign: 'center'
	}
});

//Sets up date arrays from current day counting back: weekly, monthly, 3 month, 6 month, 1 year
const dateTo = moment().format('YYYY-MM-DD');
const dateFromWeek = moment().subtract(7, 'd').format('YYYY-MM-DD');
const dateFromMonth = moment().subtract(1, 'months').format('YYYY-MM-DD');

const calculateMeasurementChangesOverTime = (data) => {
	//new user default data handler
	if (typeof data === 'undefined' || data.length === 0) {
		return {
			week: 0,
			month: 0,
			allTime: 0
		};
	}

	let finalWeek = [];
	let finalMonth = [];

	const weekly = getDates(dateFromWeek, dateTo);
	const monthly = getDates(dateFromMonth, dateTo);

	for (let i = 0; i < data.length; i++) {
		if (weekly.indexOf(data[i].date) > -1) {
			finalWeek.push(data[i]);
		}
	}

	for (let z = 0; z < data.length; z++) {
		if (monthly.indexOf(data[z].date) > -1) {
			finalMonth.push(data[z]);
		}
	}

	const week =
		finalWeek.length === 0
			? 0
			: (
					+finalWeek[finalWeek.length - 1].formattedValue -
					+finalWeek[0].formattedValue
			  ).toFixed(1);
	const month =
		finalMonth.length === 0
			? 0
			: (
					+finalMonth[finalMonth.length - 1].formattedValue -
					+finalMonth[0].formattedValue
			  ).toFixed(1);
	const allTime = (
		+data[data.length - 1].formattedValue - +data[0].formattedValue
	).toFixed(1);

	return {
		week,
		month,
		allTime
	};
};
