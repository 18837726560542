//@flow
const Colors = {
	base: "#e6e6e6",
	baseBG: "#FFF",
	baseAlt: "#FAFAFA",
	themeAccent: "#b4840d",
	themeAccentDesat: "#9f7b22",
	navBar: "#b4840d",
	navButton: "#FFF",
	navText: "#FFF",
	tabBase: "#e6e6e6",
	tabActive: "#b4840d",
	tabInactive: "#333",
	popMenuBg: "#FFF",
	grey: "#f5f5f5",
	border: "#dddddd",
	backdropGray: "#e6e6e6",
	placeholderGray: "#5d5d5d",
	darkFont: "#333",
	lightFont: "#333",
	green: "#008989",
	teal: "#008989",
	red: "#d05d47",
	orange: "#FFC107",
	blue: "#546978",
	yellow: "#fdcb6e",
	white: "#FFF",
	pickerBg: [180, 132, 13, 1],
	pickerBase: [255, 255, 255, 1],
	graphFat: "#0984e3",
	graphCarb: "#d05d47",
	graphProtein: "#00b894",
	graphFatSelected: "#68caff",
	graphCarbSelected: "#ff8b6a",
	graphProteinSelected: "#ffd562",
	submit: "#2ecc71",
	highlightedText: "#dddddd",
	loaderBG: "rgba(255,255,255,0.18)",
	nutrientBG: "#f5f5f5",
};

const Dark = {
	base: "#272727",
	baseBG: "#131313",
	baseAlt: "#212121",
	themeAccent: "#9f7b22",
	themeAccentDesat: "#8f7432",
	navBar: "#272727",
	navButton: "#f9f9f9",
	navText: "#f9f9f9",
	tabBase: "#272727",
	tabActive: "#9f7b22",
	tabInactive: "#777d87",
	popMenuBg: "#272727",
	grey: "#272727",
	border: "#212121",
	backdropGray: "#131313",
	placeholderGray: "#5d5d5d",
	darkFont: "#f9f9f9",
	lightFont: "#f9f9f9",
	green: "#008989",
	teal: "#008989",
	red: "#bf6958",
	orange: "#b59c51",
	blue: "#596873",
	yellow: "#d2be99",
	white: "#FFF",
	pickerBg: [180, 132, 13, 1],
	pickerBase: [0, 0, 0, 1],
	graphFat: "#4a7ca2",
	graphCarb: "#bf6958",
	graphProtein: "#378172",
	graphFatSelected: "#95bcd2",
	graphCarbSelected: "#d2a497",
	graphProteinSelected: "#d0bf91",
	submit: "#5d9d78",
	highlightedText: "#5d5d5d",
	loaderBG: "rgba(0,0,0,0.8)",
	nutrientBG: "#131313",
};

export { Colors, Dark };
