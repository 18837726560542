import React from 'react';
import { View, StyleSheet } from 'react-native';
import { WhiteButton } from './';
import { Colors } from 'Theme';

export const AcceptCancelWhiteButtons = (props) => {
	const { onAccept, onCancel, style } = props;
	return (
		<View style={[styles.buttonRow, style]}>
			<WhiteButton
				iconName="check-circle"
				iconSize={24}
				iconStyle={{ paddingRight: 0 }}
				color={Colors.green}
				style={styles.buttonStyle}
				onPress={onAccept}
			/>
			<WhiteButton
				iconName="times-circle"
				iconSize={24}
				iconStyle={{ paddingRight: 0 }}
				color={Colors.red}
				style={styles.buttonStyle}
				onPress={onCancel}
			/>
		</View>
	);
};

var styles = StyleSheet.create({
	buttonRow: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		flexDirection: 'row'
	},
	buttonStyle: {
		flex: 1,
		margin: 0
	}
});
