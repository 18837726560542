//@flow
import React from 'react';
import { View } from 'react-native';
import { WhiteButton, AcceptCancelWhiteButtons } from 'Components/common';
import { Colors, i18n } from 'Theme';
import styles from '../Styles/Styles';
import _ from 'lodash';

type Props = {
	isMulti: boolean,
	isManage: boolean,
	onCreate: Function,
	enableMulti: Function,
	disableMulti: Function,
	onMulti: Function,
	theme: Object
};

export const HeaderActionButtons = (props: Props) => {
	const {
		isMulti,
		isManage,
		onCreate,
		enableMulti,
		disableMulti,
		onMulti,
		theme
	} = props;
	return (
		<View>
			{!isMulti && (
				<View style={[styles.buttonHolder, {borderBottomColor: theme.border}]}>
					<WhiteButton
						style={[styles.buttonStyle, {borderColor: theme.blue}]}
						btnTextStyle={{color: theme.blue}}
						onPress={onCreate}
						iconName={'plus-circle'}
						iconSize={16}
						iconStyle={{color:theme.blue}}>
						{i18n.t('create')}
					</WhiteButton>
					{!isManage && (
						<WhiteButton btnTextStyle={{color: theme.blue}} style={[styles.buttonStyle, {borderColor: theme.blue}]} onPress={enableMulti}>
							{i18n.t('multiAdd')}
						</WhiteButton>
					)}
				</View>
			)}
			{isMulti && (
				<AcceptCancelWhiteButtons
					onAccept={_.debounce(onMulti, 1000, {
						leading: true,
						trailing: false
					})}
					onCancel={disableMulti}
					style={{
						borderBottomWidth: 1,
						borderBottomColor: theme.border
					}}
				/>
			)}
		</View>
	);
};
