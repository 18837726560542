//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Button } from './';

export const ButtonDouble = ({
	leftPress,
	leftText,
	rightPress,
	rightText,
	bgColor
}) => {
	return (
		<View style={styles.buttonRow}>
			{leftText && (
				<Button
					style={[styles.buttonStyle, { backgroundColor: bgColor }]}
					onPress={leftPress}>
					{leftText}
				</Button>
			)}
			{rightText && (
				<Button
					style={[styles.buttonStyle, { backgroundColor: bgColor }]}
					onPress={rightPress}>
					{rightText}
				</Button>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	buttonRow: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		flexDirection: 'row'
	},
	buttonStyle: {
		flex: 1,
		margin: 0
	}
});
