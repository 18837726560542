import React from 'react';
import { TouchableWithoutFeedback, View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		alignItems: 'center'
	}
});

export class MultiSelectRow extends React.Component {
	constructor() {
		super();
		this.isSelected = false;
	}

	shouldComponentUpdate(nextProps) {
		if (this.isSelected === nextProps.isSelected) {
			return false;
		}
		return true;
	}

	render() {
		const { row, isSelected, renderRow, selectRow } = this.props;

		this.isSelected = isSelected;

		return (
			<TouchableWithoutFeedback onPress={() => selectRow(row)}>
				<View style={styles.row}>{renderRow(row, isSelected)}</View>
			</TouchableWithoutFeedback>
		);
	}
}
