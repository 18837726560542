//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity, View, Picker } from 'react-native';
import { Colors, Fonts } from 'Theme';
import { Text } from 'Components/common';

//Drop-down select "wheel" picker used for various input forms and setting changes
export const OptionNumber = (props) => {
	const {
		onChange,
		currentValue,
		placeholder,
		data,
		formatData,
		containerStyle,
		buttonStyle,
		textStyle,
		min,
		max
	} = props;

	return (
		<View style={[styles.selectContainer, containerStyle]}>
			{placeholder && (
				<Text style={styles.placeholderStyle}>{`${placeholder}: `}</Text>
			)}
			<View style={[styles.pickerButton, buttonStyle]}>
				<Picker
					selectedValue={currentValue[0]}
					itemStyle={{ color: Colors.themeAccent }}
					onValueChange={(itemValue, itemIndex) =>
						onChange([itemValue, currentValue[1]])
					}>
					{data[0].map((item, index) => (
						<Picker.Item label={`${item}`} value={item} key={item} />
					))}
				</Picker>
				<Picker
					selectedValue={currentValue[1]}
					itemStyle={{ color: Colors.themeAccent }}
					onValueChange={(itemValue, itemIndex) =>
						onChange([currentValue[0], itemValue])
					}>
					{data[1].map((item, index) => (
						<Picker.Item label={`${item}`} value={item} key={item} />
					))}
				</Picker>
			</View>
			{props.children}
		</View>
	);
};

const styles = StyleSheet.create({
	selectContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1,
		borderBottomColor: Colors.border
	},
	pickerButton: {
		flexDirection: 'row',
		borderWidth: 0,
		paddingRight: 15
	},
	placeholderStyle: {
		flex: 1,
		fontSize: 12,
		textAlign: 'left'
	}
});
