//@flow
import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Button } from 'Components/common';
import { useDispatch } from 'react-redux';
import styles from './Styles/SingleViewButtons';
import { logEntry } from '../actions';
import { updateLogEntry } from 'Components/tracker/log/actions';
import {
	addUserIngredient,
	updateFoodIngredientEditingMeal
} from 'Redux/actions';
import { i18n } from 'Theme';

type Props = {
	mealTime: string,
	editMealTime: string,
	date: string,
	servingMacros: Object,
	params: Object,
	theme: Object
};

export const SingleViewButtons = (props: Props) => {
	const dispatch = useDispatch();
	const { mealTime, date, servingMacros, params, editMealTime, theme } = props;

	//When editing a meal and adding a Single Food as an ingredient
	if (params.editingMeal && !params.editingIngredient) {
		return (
			<View style={styles.buttonRow}>
				<Button
					style={[styles.buttonStyle, { backgroundColor: theme.themeAccent }]}
					onPress={() => dispatch(addUserIngredient(servingMacros, false))}>
					{i18n.t('addToMeal')}
				</Button>
			</View>
		);
	}

	//When editing a meal, use Update Ingredient button
	if (params.editingIngredient) {
		return (
			<View style={styles.buttonRow}>
				<Button
					style={[styles.buttonStyle, { backgroundColor: theme.themeAccent }]}
					onPress={() =>
						dispatch(
							updateFoodIngredientEditingMeal(
								servingMacros,
								params.ingredientRandom
							)
						)
					}>
					{i18n.t('updateIngredient')}
				</Button>
			</View>
		);
	}

	//When Editing A Log Entry
	if (params.editingLogEntry) {
		return (
			<View style={styles.buttonRow}>
				<Button
					style={[styles.buttonStyle, { backgroundColor: theme.themeAccent }]}
					onPress={() =>
						dispatch(
							updateLogEntry(servingMacros, date, editMealTime, params.random)
						)
					}>
					{i18n.t('updateEntry')}
				</Button>
			</View>
		);
	}

	if (params.manage) {
		return <View />;
	}

	return (
		<View style={styles.buttonRow}>
			<Button
				style={[styles.buttonStyle, { backgroundColor: theme.themeAccent }]}
				onPress={() => dispatch(logEntry(servingMacros, date, mealTime))}>
				{`${i18n.t('addTo')} ${
					mealTime.charAt(0).toUpperCase() + mealTime.slice(1)
				}`}
			</Button>
		</View>
	);
};

SingleViewButtons.propTypes = {
	servingSize: PropTypes.string,
	manage: PropTypes.bool,
	addFood: PropTypes.func,
	deleteFood: PropTypes.func,
	servingMacros: PropTypes.object,
	name: PropTypes.string,
	editing: PropTypes.bool
};
