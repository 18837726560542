//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Gram } from 'Components/common';
import { Colors, i18n } from 'Theme';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type Props = {
	macrosEaten: Object,
	macrosRemaining: Object,
	macroGoals: Object,
	exercise: number,
	carbUnits: Object,
	customDateGoal: boolean,
	theme: Object
};

export const LogViewMacroGrid = (props: Props) => {
	const {
		macrosEaten,
		macrosRemaining,
		macroGoals,
		carbUnits,
		customDateGoal,
		theme
	} = props;

	const colorCheck = (macroValue) => {
		if (macroValue < 0) {
			return { color: theme.red };
		}
		return false;
	};
	const labelCtn = [styles.labelContainer, { borderTopColor: theme.border }];
	const labelTxt = [styles.label, { color: theme.placeholderGray }];
	return (
		<View style={styles.macroGridContainer}>
			<View style={styles.center}>
				<View style={styles.customDateGoalsContainer}>
					<Text allowFontScaling={false} style={styles.header}>
						{i18n.t('goals')}
					</Text>
					{customDateGoal && (
						<MaterialCommunityIcons
							name="calendar"
							style={styles.customDateGoalsIcon}
							size={10}
							color={'#565656'}
						/>
					)}
				</View>
				<View style={labelCtn}>
					<Text style={styles.macroNumberLabel}>
						{macroGoals.cal.toFixed(0)} Cal
					</Text>
					<Text style={labelTxt}>{i18n.t('calories')}</Text>
				</View>
				<View style={labelCtn}>
					<Text style={styles.macroNumberLabel}>
						{macroGoals.carbgrams.toFixed(1)}
						<Gram />
					</Text>
					{carbUnits === 'total' ? (
						<Text style={labelTxt}>{i18n.t('totalcarb')}</Text>
					) : (
						<Text style={labelTxt}>{i18n.t('netcarb')}</Text>
					)}
				</View>

				<View style={labelCtn}>
					<Text style={styles.macroNumberLabel}>
						{macroGoals.fatgrams.toFixed(1)}
						<Gram />
					</Text>
					<Text style={labelTxt}>{i18n.t('fat')}</Text>
				</View>
				<View style={labelCtn}>
					<Text style={styles.macroNumberLabel}>
						{macroGoals.proteingrams.toFixed(1)}
						<Gram />
					</Text>
					<Text style={labelTxt}>{i18n.t('protein')}</Text>
				</View>
			</View>
			<View style={styles.center}>
				<Text allowFontScaling={false} style={styles.header}>
					{i18n.t('eaten')}
				</Text>
				<View style={labelCtn}>
					<Text style={styles.macroNumberLabel}>
						{macrosEaten.calories.toFixed(0)} Cal
					</Text>
					<Text style={labelTxt}> </Text>
				</View>
				<View style={labelCtn}>
					<Text style={styles.macroNumberLabel}>
						{carbUnits === 'total'
							? macrosEaten.totalcarbs.toFixed(1)
							: macrosEaten.carbs.toFixed(1)}
						<Gram />
					</Text>
					{macrosEaten.fiber > 0 && (
						<View style={styles.exerciseCalories}>
							<Text style={[styles.label, { paddingLeft: 0, paddingRight: 0 }]}>
								{`Fiber: ${macrosEaten.fiber.toFixed(1)}`}
								<Gram style={{ color: Colors.grey }} />
							</Text>
						</View>
					)}
					{macrosEaten.fiber <= 0 && <Text style={labelTxt}> </Text>}
				</View>
				<View style={labelCtn}>
					<Text style={styles.macroNumberLabel}>
						{macrosEaten.fats.toFixed(1)}
						<Gram />
					</Text>
					<Text style={labelTxt}> </Text>
				</View>
				<View style={labelCtn}>
					<Text style={styles.macroNumberLabel}>
						{macrosEaten.proteins.toFixed(1)}
						<Gram />
					</Text>
					<Text style={labelTxt}> </Text>
				</View>
			</View>
			<View style={styles.center}>
				<Text allowFontScaling={false} style={styles.header}>
					{i18n.t('remaining')}
				</Text>
				<View style={labelCtn}>
					<Text
						style={[
							styles.macroNumberLabel,
							colorCheck(macrosRemaining.calories)
						]}>
						{macrosRemaining.calories.toFixed(0)} Cal
					</Text>
					{/*exercise > 0 && (
						<View style={styles.exerciseCalories}>
							<MaterialCommunityIcons name="run" size={10} color={'#565656'} />
							<Text
								style={[
									styles.label,
									{ paddingLeft: 0, paddingRight: 0 }
								]}>{` +${exercise.toString()}`}</Text>
						</View>
					)}
					{exercise <= 0 && <Text style={labelTxt}> </Text>}
					{typeof exercise === 'undefined' && (
						<Text style={labelTxt}> </Text>
					)*/}
					<Text style={labelTxt}> </Text>
				</View>
				<View style={labelCtn}>
					<Text
						style={[
							styles.macroNumberLabel,
							colorCheck(
								carbUnits === 'total'
									? macrosRemaining.totalcarbs
									: macrosRemaining.carbs
							)
						]}>
						{carbUnits === 'total'
							? macrosRemaining.totalcarbs.toFixed(1)
							: macrosRemaining.carbs.toFixed(1)}
						<Gram />
					</Text>
					<Text style={labelTxt}> </Text>
				</View>
				<View style={labelCtn}>
					<Text
						style={[styles.macroNumberLabel, colorCheck(macrosRemaining.fats)]}>
						{macrosRemaining.fats.toFixed(1)}
						<Gram />
					</Text>
					<Text style={labelTxt}> </Text>
				</View>
				<View style={labelCtn}>
					<Text
						style={[
							styles.macroNumberLabel,
							colorCheck(macrosRemaining.proteins)
						]}>
						{macrosRemaining.proteins.toFixed(1)}
						<Gram />
					</Text>
					<Text style={labelTxt}> </Text>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	macroGridContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'flex-start',
		paddingTop: 10
	},
	header: {
		fontSize: 12,
		fontFamily: 'Raleway',
		fontWeight: '900',
		paddingBottom: 10,
		alignSelf: 'center',
		paddingLeft: 0,
		paddingRight: 0
	},
	center: {
		justifyContent: 'center',
		alignItems: 'stretch',
		flex: 1
	},
	labelContainer: {
		borderTopWidth: 1,
		paddingVertical: 11
	},
	macroNumberLabel: {
		fontSize: 12,
		alignSelf: 'center',
		paddingLeft: 8,
		paddingRight: 8,
		lineHeight: 12,
		paddingTop: 10
	},
	label: {
		fontSize: 10,
		alignSelf: 'center',
		marginTop: -5
	},
	exerciseCalories: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	noBorder: {
		borderWidth: 0
	},
	customDateGoalsContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	customDateGoalsIcon: {
		paddingLeft: 4,
		paddingBottom: 8
	}
});
