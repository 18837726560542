//@flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet, TouchableOpacity, FlatList } from 'react-native';
import { Text, Checkbox, WebCompatAlert, Prompt } from 'Components/common';
import { Colors, i18n } from 'Theme';
import { SwipeRow } from 'react-native-swipe-list-view';
import {
	addShoppingItem,
	deleteShoppingItem,
	toggleCompletedItem
} from './actions/ShoppingListActions';
import { FontAwesome } from '@expo/vector-icons';

export const ShoppingListScreen = ({ navigation, route }) => {
	const dispatch = useDispatch();
	const [isAddingEntry, toggleAddingEntry] = useState(false);
	const { items } = useSelector((state) => state.shoppinglist);
	const { theme } = useSelector((state) => state.startup);

	useEffect(() => {
		navigation.setParams({
			headerBtns: [
				{
					size: 24,
					name: 'plus',
					color: theme.navButton,
					onPress: () => toggleAddingEntry(true)
				}
			]
		});
	}, []);

	const submitPrompt = (name: string) => {
		if (name === '' || name.trim().length === 0) {
			return WebCompatAlert(i18n.t('nameEmpty'), i18n.t('pleaseEnterName'));
		}
		dispatch(addShoppingItem(name));
		toggleAddingEntry(false);
	};

	const renderItem = ({ item, index }) => {
		if (item.completed === true) {
			return (
				<SwipeRow
					swipeKey={`${item.key}`}
					style={{ backgroundColor: theme.border }}
					disableRightSwipe={true}
					recalculateHiddenLayout={true}
					swipeToOpenPercent={1}
					rightOpenValue={-100}
					closeOnRowPress={true}
					friction={10}
					tension={10}>
					<View style={styles.hiddenCtn}>
						<TouchableOpacity
							style={[styles.deleteBtn, { backgroundColor: theme.red }]}
							onPress={() => dispatch(deleteShoppingItem(item))}>
							<FontAwesome
								style={{ alignSelf: 'center' }}
								name="times"
								size={14}
								color={theme.white}
							/>
						</TouchableOpacity>
					</View>
					<View
						style={[
							styles.rowContainer,
							{ backgroundColor: theme.baseBG, borderColor: theme.border }
						]}>
						<Checkbox
							selected={true}
							color={theme.blue}
							size={24}
							onPress={() => dispatch(toggleCompletedItem(item))}
							textStyle={{
								color: theme.darkFont,
								marginLeft: 10,
								fontSize: 12
							}}
							style={{
								marginVertical: 5,
								marginLeft: 0,
								padding: 5
							}}
						/>
						<Text style={[styles.titleCompletedStyle, { color: theme.highlightedText }]}>
							{item.name}
						</Text>
					</View>
				</SwipeRow>
			);
		}
		return (
			<SwipeRow
				swipeKey={`${item.key}`}
				disableRightSwipe={true}
				recalculateHiddenLayout={true}
				swipeToOpenPercent={1}
				rightOpenValue={-100}
				closeOnRowPress={true}
				friction={10}
				tension={10}>
				<View style={styles.hiddenCtn}>
					<TouchableOpacity
						style={[styles.deleteBtn, { backgroundColor: theme.red }]}
						onPress={() => dispatch(deleteShoppingItem(item))}>
						<FontAwesome
							style={{ alignSelf: 'center' }}
							name="times"
							size={14}
							color={'#FFF'}
						/>
					</TouchableOpacity>
				</View>
				<View
					style={[
						styles.rowContainer,
						{ backgroundColor: theme.baseBG, borderColor: theme.border }
					]}>
					<Checkbox
						selected={false}
						color={theme.blue}
						size={24}
						onPress={() => dispatch(toggleCompletedItem(item))}
						textStyle={{ color: theme.darkFont, marginLeft: 10, fontSize: 12 }}
						style={{
							marginVertical: 5,
							marginLeft: 0,
							padding: 5
						}}
					/>
					<Text style={styles.titleStyle}>{item.name}</Text>
				</View>
			</SwipeRow>
		);
	};

	return (
		<View style={[styles.container, { backgroundColor: theme.baseBG }]}>
			<FlatList
				data={items}
				renderItem={renderItem}
				keyExtractor={(item) => `${item.key}`}
			/>
			<Prompt
				title={i18n.t('addItem')}
				placeholder={i18n.t('name')}
				defaultValue=""
				visible={isAddingEntry}
				onCancel={() => toggleAddingEntry(false)}
				onSubmit={(value) => submitPrompt(value)}
				onClosed={() => toggleAddingEntry(false)}
				submitText={i18n.t('add')}
				doneSubmit={true}
				theme={theme}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	rowContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		borderWidth: 1,
		paddingLeft: 15,
		paddingRight: 5,
		paddingVertical: 10
	},
	titleStyle: {
		fontSize: 16
	},
	titleCompletedStyle: {
		fontSize: 16
	},
	hiddenCtn: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	deleteBtn: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 100
	},
	deleteText: {
		color: '#FFF'
	}
});
