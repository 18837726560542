//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Gram } from 'Components/common';
import { i18n } from 'Theme';
import Elevations from 'react-native-elevation';

type Props = {
	fats: number,
	carbs: number,
	totalcarbs: number,
	proteins: number,
	calories: number,
	fiber: number,
	carbUnits: string,
	theme: Object
};

export const MacroGrid = (props: Props) => {
	const {
		fats,
		carbs,
		totalcarbs = carbs,
		proteins,
		calories,
		fiber = 0,
		carbUnits,
		theme
	} = props;


	return (
		<View style={styles.macroGridContainer}>
			<View style={[styles.firstRow, { backgroundColor: theme.grey }]}>
				<View style={styles.macroBox}>
					<Text style={styles.macroAmount}>
						{totalcarbs}
						<Gram />
					</Text>
					<Text style={[styles.macroLabel, { color: theme.darkFont }]}>
						{i18n.t('totalcarb')}
					</Text>
				</View>
				<View style={styles.macroBox}>
					<Text style={styles.macroAmount}>
						{fiber}
						<Gram />
					</Text>
					<Text style={[styles.macroLabel, { color: theme.darkFont }]}>
						{i18n.t('fiber')}
					</Text>
				</View>
				<View style={styles.macroBox}>
					<Text style={styles.macroAmount}>
						{carbs}
						<Gram />
					</Text>
					<Text style={[styles.macroLabel, { color: theme.darkFont }]}>
						{i18n.t('netcarb')}
					</Text>
				</View>
			</View>
			<View
				style={[
					styles.secondRow,
					{ backgroundColor: theme.grey, borderTopColor: theme.border }
				]}>
				<View style={styles.macroBox}>
					<Text style={styles.macroAmount}>
						{fats}
						<Gram />
					</Text>
					<Text style={[styles.macroLabel, { color: theme.darkFont }]}>
						{i18n.t('fat')}
					</Text>
				</View>
				<View style={styles.macroBox}>
					<Text style={styles.macroAmount}>
						{proteins}
						<Gram />
					</Text>
					<Text style={[styles.macroLabel, { color: theme.darkFont }]}>
						{i18n.t('protein')}
					</Text>
				</View>
				<View style={styles.macroBox}>
					<Text style={styles.macroAmount}>{calories} kcal</Text>
					<Text style={[styles.macroLabel, { color: theme.darkFont }]}>
						{i18n.t('calories')}
					</Text>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	firstRow: {
		flexDirection: 'row',
		alignItems: 'center',
		height: 65
	},
	secondRow: {
		flexDirection: 'row',
		alignItems: 'center',
		height: 65,
		borderTopWidth: 1
	},
	macroBox: {
		alignItems: 'center',
		flex: 1,
	},
	macroLabel: {
		fontSize: 10,
		marginTop: -8
	},
	macroAmount: {
		fontSize: 13
	},
	netCarbAmount: {
		fontSize: 18
	},
	macroGridContainer: {
		...Elevations[1]
	}
});
