//@flow
import {
	ADD_REGULAR_INTER_COUNT,
	RESET_REGULAR_INTER_COUNT
} from '../actions/types';

const INITIAL_STATE = {
	regularInterCounter: 0
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_REGULAR_INTER_COUNT:
			return { ...state, regularInterCounter: state.regularInterCounter + 1 };
		case RESET_REGULAR_INTER_COUNT:
			return { ...state, regularInterCounter: 0 };
		default:
			return state;
	}
};
