//@flow
import React from 'react';
import {
	FlatList,
	View,
	StyleSheet,
	TouchableOpacity,
	Keyboard
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { i18n } from 'Theme';
import { SwipeRow } from 'react-native-swipe-list-view';
import { calculateMacroDescription } from 'Utilities/MacrosUtil';
import { Text } from 'Components/common';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
	deletePreset,
	quickAddEntry,
	favoriteAddItem,
	quickRemoveFavorite
} from '../../actions';

type Props = {
	data: Object,
	onItemClick: Function,
	carbUnits: string,
	routeName: string,
	type: string,
	mealTime: string,
	manage: boolean,
	logDate: string,
	theme: Object
};

export const FlatListSingle = (props: Props) => {
	const {
		data,
		carbUnits,
		routeName,
		mealTime,
		logDate,
		manage,
		theme
	} = props;
	const navigation = useNavigation();
	const dispatch = useDispatch();

	const navigateCloseKeyboard = (item) => {
		Keyboard.dismiss();
		navigation.navigate(routeName, { foodID: item.id });
	};

	const renderItem = ({ item }) => {
		return (
			<SwipeRow
				swipeKey={`${item.id}`}
				disableRightSwipe={true}
				swipeToOpenPercent={1}
				rightOpenValue={-200}
				closeOnRowPress={true}
				style={{ backgroundColor: theme.baseBG }}
				friction={10}
				tension={10}>
				<View style={styles.hiddenCtn}>
					<TouchableOpacity
						style={[styles.deleteBtn, { backgroundColor: theme.red }]}
						onPress={() => dispatch(deletePreset(item))}>
						<Text style={styles.swipeButtonText}>{i18n.t('delete')}</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={[styles.favBtn, { backgroundColor: theme.yellow }]}
						onPress={() =>
							item.favorite === true
								? dispatch(quickRemoveFavorite(item))
								: dispatch(favoriteAddItem(item))
						}>
						{item.favorite === true ? (
							<FontAwesome name={'star'} color={theme.grey} size={20} />
						) : (
							<FontAwesome name={'star-o'} color={theme.grey} size={20} />
						)}
					</TouchableOpacity>
				</View>
				<View
					style={[
						styles.rowContainer,
						{ backgroundColor: theme.baseBG, borderColor: theme.border }
					]}>
					<View style={styles.textHolder}>
						<View style={styles.textContainer}>
							<TouchableOpacity onPress={() => navigateCloseKeyboard(item)}>
								<Text style={[styles.itemTitle, { color: theme.themeAccent }]}>
									{item.name}
								</Text>
								<Text style={styles.itemDescription}>
									{calculateMacroDescription(item, carbUnits)}
								</Text>
							</TouchableOpacity>
						</View>
						{item.favorite === true ? (
							<FontAwesome
								style={{ alignSelf: 'center', flex: 0.1 }}
								name={'star'}
								color={'#f1c40f'}
								size={12}
							/>
						) : null}
					</View>
					{!manage && (
						<TouchableOpacity
							style={[styles.quickAddHolder, { backgroundColor: theme.grey }]}
							onPress={() => dispatch(quickAddEntry(item, mealTime, logDate))}>
							<FontAwesome
								style={styles.quickAdd}
								name="plus"
								color={theme.darkFont}
								size={12}
							/>
						</TouchableOpacity>
					)}
				</View>
			</SwipeRow>
		);
	};

	return (
		<FlatList
			data={data}
			keyExtractor={(item) => item.id}
			renderItem={renderItem}
			style={styles.foodListStyle}
			keyboardShouldPersistTaps="handled"
		/>
	);
};

const styles = StyleSheet.create({
	foodListStyle: {
		flex: 1
	},
	rowContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottomWidth: 1,
		paddingLeft: 15
	},
	itemTitle: {
		fontSize: 14,
		lineHeight: 16,
		marginTop: 17,
		fontFamily: 'Raleway'
	},
	itemDescription: {
		marginLeft: 0,
		fontSize: 10,
		lineHeight: 14,
		marginBottom: 19,
		marginTop: 5
	},
	textContainer: {
		flex: 1
	},
	textHolder: {
		flex: 4,
		flexDirection: 'row',
		paddingRight: 5
	},
	quickAddHolder: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignSelf: 'stretch',
		paddingLeft: 3
	},
	quickAdd: {
		alignSelf: 'center'
	},
	deleteBtn: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 100
	},
	favBtn: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 100
	},
	swipeButtonText: {
		color: '#FFF'
	},
	hiddenCtn: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end'
	}
});
