//@flow
import React from "react";
import { ScrollView, View, Image } from "react-native";
import { Paragraph, Heading, Divider } from "../common";
import styles from "./Styles/Styles";
import { i18n, Images } from "../../constants";

export const GetStarted = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("getstartedh1")}</Heading>
			<Paragraph>{i18n.t("getstartedp1")}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("getstartedh2")}</Heading>
			<Paragraph>{i18n.t("getstartedp2")}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("getstartedh3")}</Heading>
			<Paragraph>{i18n.t("getstartedp3")}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("getstartedh4")}</Heading>
			<Paragraph>{i18n.t("getstartedp4")}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("getstartedh5")}</Heading>
			<Paragraph>{i18n.t("getstartedp5")}</Paragraph>
			<Divider />
		</ScrollView>
	);
};
