//@flow
import React, { useState, useEffect } from 'react';
import {
	Dimensions,
	ScrollView,
	View,
	StyleSheet
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
	setProgressFilter,
	loadDietProgress,
	setProgressFromDate,
	setProgressToDate
} from './actions/ProgressActions';
import {
	getMarcroPieData,
	calculateStatBoxes,
	getMarcroLineData
} from 'Redux/selectors';
import { Text } from 'Components/common';
import {
	ProgressDatePicker,
	ProgressFilter,
	ProgressPieMacros,
	ProgressLineMacros,
	StatsBox
} from './components';
import { i18n } from 'Theme';

export const DietProgressScreen = () => {
	const dispatch = useDispatch();
	const { carbUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);
	const { theme } = useSelector((state) => state.startup);
	const { progressFilter, progressToDate, progressFromDate } = useSelector(
		(state) => state.progress
	);
	const [activeLabels, toggleLabels] = useState(false);
	const [width, changeWidth] = useState(Dimensions.get('window').width);

	const pieData = useSelector((state) => getMarcroPieData(state));
	const lineData = useSelector((state) => getMarcroLineData(state));
	const statBox = useSelector((state) => calculateStatBoxes(state));

	useEffect(() => {
		dispatch(
			loadDietProgress(progressFilter, progressFromDate, progressToDate)
		);
	}, [progressFilter, progressFromDate, progressToDate]);

	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<ProgressFilter
				progressFilter={progressFilter}
				setProgressFilter={setProgressFilter}
				style={{
					borderBottomWidth: 1,
					borderBottomColor: theme.border,
					height: 50
				}}
				theme={theme}
			/>
			{progressFilter === 6 && (
				<ProgressDatePicker
					progressFromDate={progressFromDate}
					progressToDate={progressToDate}
					setFromDate={setProgressFromDate}
					setToDate={setProgressToDate}
					theme={theme}
				/>
			)}
			{/*<View style={styles.center}>
				<View style={styles.statsCard}>
					<View style={styles.statsRow}>
						<StatsBox
							iconName="av-timer"
							label={i18n.t('carbs')}
							amount={statBox.totalCarbs}
							tinyText={'g'}
							style={{
								borderRightWidth: 1,
								borderBottomWidth: 1,
								borderColor: Colors.border
							}}
						/>
						<StatsBox
							iconName="check-decagram"
							label={i18n.t('carbs')}
							amount={statBox.dayStreak}
							tinyText={'g'}
							style={{
								borderBottomWidth: 1,
								borderColor: Colors.border
							}}
						/>
					</View>
					<View style={styles.statsRow}>
						<StatsBox
							iconName="clock-fast"
							label={i18n.t('carbs')}
							amount={statBox.daysOverCarbLimit}
							tinyText={'g'}
							style={{
								borderRightWidth: 1,
								borderColor: Colors.border
							}}
						/>
						<StatsBox
							iconName="chart-gantt"
							label={i18n.t('carbs')}
							amount={statBox.daysOverCarbLimit}
							tinyText={'g'}
						/>
					</View>
				</View>
			</View>
			<View style={[styles.divider, {borderColor: theme.teal,}]} />*/}
			<View style={styles.pieCtn}>
				<View style={styles.headerContainer}>
					<Text style={styles.headingText}>{i18n.t('caloriesFromMacros')}</Text>
				</View>
				<ProgressPieMacros
					data={pieData}
					width={width}
					height={300}
					activeLabels={activeLabels}
					onClick={() => toggleLabels(!activeLabels)}
					carbUnits={carbUnits}
					showGoals={false}
					theme={theme}
				/>
			</View>
			<View style={[styles.divider, { borderColor: theme.teal }]} />
			<ProgressLineMacros
				xLabel={i18n.t('date')}
				data={lineData}
				width={width}
				theme={theme}
			/>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	center: {
		justifyContent: 'center',
		alignItems: 'center'
	},
	divider: {
		width: '75%',
		borderWidth: 1,
		height: 1,
		alignSelf: 'center',
		marginVertical: 50
	},
	chartTitle: {
		fontSize: 14,
		textAlign: 'center'
	},
	statsCard: {
		flex: 1,
		paddingTop: 20
	},
	statsRow: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'stretch'
	},
	addWeightError: {
		fontSize: 14,
		textAlign: 'center',
		paddingBottom: 50,
		paddingTop: 10
	},
	pieCtn: {
		paddingVertical: 20,
		justifyContent: 'center'
	},
	headingText: {
		textAlign: 'center'
	}
});
