import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Colors } from 'App/constants';
import { Text } from './';
import { Ionicons, SimpleLineIcons } from '@expo/vector-icons';

export const DropdownButton = (props) => {
	return (
		<TouchableOpacity style={styles.pageSelect} onPress={() => props.onPress()}>
			<Text style={styles.titleStyle}>{props.title}</Text>
			<SimpleLineIcons
				style={styles.pageSelectIcon}
				name={'arrow-down'}
				size={10}
				color={Colors.icon}
			/>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	pageSelect: {
		height: 40,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row'
	},
	pageSelectIcon: {
		paddingLeft: 10,
		alignSelf: 'center'
	},
	titleStyle: {
		color: Colors.blue
	},
	activeTitleStyle: {
		fontWeight: 'bold'
	},
	iconStyle: {
		width: 30,
		color: '#483F53'
	},
	activeIconStyle: {
		width: 30,
		color: Colors.blue
	},
	activeIconStyle: {
		width: 30,
		color: Colors.blue
	}
});
