//@flow
import React from 'react';
import { Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { createCustomFood } from 'Components/tracker/foodtabs/actions';
import { EditingItemModal } from 'Components/modal/EditingItemModal';
import { i18n } from 'Theme';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

type Props = {
	data: { CurrentServingData: Object, placeholder: string, foodData: Object },
	closeModal: Function,
	isVisible: boolean
};

export const FsSaveAsModal = (props: Props) => {
	const dispatch = useDispatch();
	const {
		data: { CurrentServingData, placeholder, foodData },
		closeModal,
		isVisible
	} = props;

	//Formats the name for the input form that OnSavePreset creates.
	const formatName = (foodData) => {
		return foodData.brand_name
			? `${foodData.brand_name} - ${foodData.food_name} - ${placeholder}`
			: `${foodData.food_name} - ${placeholder}`;
	};

	const onSubmit = (values) => {
		dispatch(createCustomFood(values));
		closeModal();
	};

	return (
		<Modal.ReactNativeModal
			isVisible={isVisible}
			style={{
				padding: 0,
				margin: 0
			}}
			animationIn={'slideInLeft'}
			animationOut={'slideOutLeft'}
			onSwipeComplete={() => closeModal()}>
			<EditingItemModal
				closeModal={() => closeModal()}
				onSubmit={(values) => onSubmit(values)}
				modalTitle={i18n.t('saveAsFood')}
				item={{
					name: formatName(foodData),
					calories: CurrentServingData.calories.toFixed(2),
					fats: CurrentServingData.fats.toFixed(2),
					proteins: CurrentServingData.proteins.toFixed(2),
					carbs: CurrentServingData.carbs.toFixed(2),
					totalcarbs: CurrentServingData.totalcarbs.toFixed(2),
					fiber: CurrentServingData.fiber.toFixed(2),
					nutrientsV2: CurrentServingData.nutrientsV2,
					manualCalories: false
				}}
			/>
		</Modal.ReactNativeModal>
	);
};
