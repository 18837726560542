//@flow
import React, { useRef } from "react";
import { TextInput, StyleSheet, TouchableOpacity } from "react-native";
import { Button, Text } from "Components/common";
import { View as ViewA } from "react-native-animatable";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { signInSubmit } from "../actions/FirebaseActionsAuth";
import { Colors, i18n } from "Theme";

type Props = {
	isVisible: boolean,
	toggleForgotPw: Function,
};

export const LoginForm = (props: Props) => {
	const { isVisible, toggleForgotPw } = props;
	const dispatch = useDispatch();
	const emailInput = useRef(null);
	const passwordInput = useRef(null);

	const { control, handleSubmit, errors } = useForm();

	//Sings the user in via Email/Password
	const onSubmit = (values) => {
		dispatch(signInSubmit(values));
	};

	return (
		<ViewA animation={isVisible ? "fadeOutDown" : "fadeInRight"} style={styles.formContainer} useNativeDriver duration={1000}>
			<Controller
				control={control}
				render={({ onChange, value }) => (
					<TextInput
						style={styles.loginInput}
						onChangeText={(value) => onChange(value)}
						value={value}
						autoCapitalize="none"
						autoCorrect={false}
						placeholder={i18n.t("email")}
						placeholderTextColor={Colors.placeholderGray}
						ref={emailInput}
						onSubmitEditing={() => {
							passwordInput.current.focus();
						}}
					/>
				)}
				name="email"
				rules={{
					required: true,
					pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				}}
				defaultValue=""
			/>
			{errors.email && <Text style={styles.errorText}>{i18n.t("emailError")}</Text>}

			<Controller
				control={control}
				render={({ onChange, value }) => (
					<TextInput
						style={styles.loginInput}
						onChangeText={(value) => onChange(value)}
						value={value}
						autoCapitalize="none"
						placeholder={i18n.t("password")}
						placeholderTextColor={Colors.placeholderGray}
						ref={passwordInput}
						autoCorrect={false}
						onSubmitEditing={handleSubmit((values) => onSubmit(values))}
					/>
				)}
				name="password"
				rules={{ required: true }}
				defaultValue=""
			/>
			{errors.password && <Text style={styles.errorText}>{i18n.t("passwordError")}</Text>}
			<TouchableOpacity
				style={{
					alignSelf: "flex-end",
					marginHorizontal: 40,
					marginBottom: 10,
				}}
				onPress={toggleForgotPw}
			>
				<Text style={styles.forgotPasswordLink}>{i18n.t("forgotPassword")}</Text>
			</TouchableOpacity>
			<Button onPress={handleSubmit((values) => onSubmit(values))} style={[styles.signUpBtn, { backgroundColor: "#008989" }]}>
				{i18n.t("logIn")}
			</Button>
		</ViewA>
	);
};

const styles = StyleSheet.create({
	//LoginForm
	loginInput: {
		fontFamily: "Raleway",
		fontWeight: "normal",
		color: "#333",
		marginHorizontal: 30,
		padding: 15,
		backgroundColor: "#FFF",
		flexDirection: "column",
		marginVertical: 10,
		borderRadius: 5,
	},
	loginLabel: {
		fontFamily: "Raleway",
		fontWeight: "normal",
		color: "#FFF",
		marginTop: 0,
	},
	loginErrorLabel: {
		fontFamily: "Raleway",
		fontWeight: "normal",
		color: "red",
		marginTop: 0,
	},
	errorText: {
		color: Colors.red,
		marginHorizontal: 15,
		fontSize: 10,
	},
	forgotPasswordLink: {
		fontSize: 10,
		color: "#FFF",
	},
	signUpBtn: {
		marginHorizontal: 30,
	},
});
