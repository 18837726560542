import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { Images } from 'Theme';

export const EdamamImage = () => {
	return (
		<Image
			resizeMode="contain"
			style={styles.imgStyle}
			source={Images.edamam}
		/>
	);
};

const styles = StyleSheet.create({
	imgStyle: {
		width: 200,
		height: 40,
		alignSelf: 'center',
		marginVertical: 10
	}
});
