import React from 'react';
import { View, StyleSheet } from 'react-native';

export const CardSection = (props) => {
	return (
		<View style={[styles.containerStyle, props.style]}>{props.children}</View>
	);
};

var styles = StyleSheet.create({
	containerStyle: {
		padding: 15,
		justifyContent: 'flex-start',
		flexDirection: 'column',
		position: 'relative'
	}
});
