//@flow
import Picker from 'react-native-picker';
import { i18n } from 'Theme';
import { defaultNutrients } from 'Redux/selectors';
import _ from 'lodash';

export const calculatePlaceholder = (
	wheelArray: Array<[number, string, string]>,
	customName: string = i18n.t('servings')
) => {
	let first = +wheelArray[0];
	let second = '';
	if (wheelArray[1] !== '-') {
		second = Number(eval(wheelArray[1]).toFixed(6));
		first += second;
	}
	let third = customName ? customName : wheelArray[2];
	const placeholder = first + ' x ' + third;
	return placeholder;
};

export const calculateMultiplier = (
	wheelArray: Array<[number, string, string]>
) => {
	let fraction = 0;
	if (wheelArray[1] !== '-') {
		fraction = Number(eval(wheelArray[1]).toFixed(6));
	}
	const addedFractionServing = Number(wheelArray[0]) + fraction;
	return addedFractionServing;
};

const nutrientsHelped = (
	servingData,
	excludeArray,
	nutrientArray,
	divider = 1
) => {
	console.log(Object.keys(servingData), nutrientArray);
	Object.keys(servingData).forEach((element) => {
		if (excludeArray.indexOf(element) === -1) {
			let obj = { key: element, value: servingData[element] / divider };
			nutrientArray.push(obj);
		}
	});
	return nutrientsFormatting(nutrientArray);
};

const macroAssign = (servingData, divider = 1) => {
	let obj = {
		calories: 0,
		proteins: 0,
		fats: 0,
		totalcarbs: 0,
		fiber: 0,
		totalWeightG: 0,
		totalUnits: 'g'
	};
	obj.calories = servingData.calories / divider;
	obj.proteins = servingData.protein / divider;
	obj.fats = servingData.fat / divider;
	obj.totalcarbs = servingData.carbohydrate / divider;
	if (servingData.fiber) {
		obj.fiber = servingData.fiber / divider;
	}

	if (servingData.metric_serving_amount) {
		obj.totalWeightG = +servingData.metric_serving_amount / divider;
		obj.totalUnits = servingData.metric_serving_unit;
	}

	return obj;
};

//Calculate the Macros for FatSecret API Searched Foods
export const calculateMacros = (data, pickedValue, multiplier = 1) => {
	multiplier = +multiplier;
	const servings_data = data.servings.serving;

	let nutrientsV2 = [];
	let macroObj = {
		calories: 0,
		proteins: 0,
		fats: 0,
		totalcarbs: 0,
		fiber: 0,
		carbs: 0,
		totalWeightG: 0,
		totalUnits: 'g',
		nutrientsV2
	};

	const dontIncludeNutrients = [
		'calories',
		'carbohydrate',
		'protein',
		'fat',
		'fiber',
		'measurement_description',
		'metric_serving_unit',
		'metric_serving_amount',
		'number_of_units',
		'serving_description',
		'serving_id',
		'serving_url',
		'monounsaturated_fat',
		'polyunsaturated_fat',
		'saturated_fat',
		'trans_fat',
		'sugar'
	];

	if (isNaN(multiplier) || multiplier < 0) {
		return {
			calories: 0,
			proteins: 0,
			fats: 0,
			totalcarbs: 0,
			fiber: 0,
			carbs: 0,
			totalWeightG: 0,
			totalUnits: 'g',
			nutrientsV2: { ...defaultNutrients }
		};
	}

	if (data.brand_name) {
		//Brand Name Data API
		if (pickedValue === servings_data.serving_description) {
			//Brand Name Original Serving Item
			macroObj = macroAssign(servings_data);
			macroObj.nutrientsV2 = nutrientsHelped(
				servings_data,
				dontIncludeNutrients,
				nutrientsV2
			);
		} else if (pickedValue === 'g') {
			//User selects grams
			if (servings_data.metric_serving_unit === 'g') {
				//User selects grams, and the original brand serving is suppled in grams
				macroObj = macroAssign(
					servings_data,
					+servings_data.metric_serving_amount
				);
				macroObj.nutrientsV2 = nutrientsHelped(
					servings_data,
					dontIncludeNutrients,
					nutrientsV2,
					+servings_data.metric_serving_amount
				);
			} else if (servings_data.metric_serving_unit === 'oz') {
				//User selects grams but the original brand serving is suppled in Ounces
				macroObj = macroAssign(
					servings_data,
					+servings_data.metric_serving_amount * 28.3495
				);
				macroObj.nutrientsV2 = nutrientsHelped(
					servings_data,
					dontIncludeNutrients,
					nutrientsV2,
					+servings_data.metric_serving_amount * 28.3495
				);
				if (servings_data.metric_serving_amount) {
					macroObj.totalWeightG =
						(+servings_data.metric_serving_amount * 28.3495) /
						(+servings_data.metric_serving_amount * 28.3495);
					macroObj.totalUnits = 'g';
				}
			}
		} else if (pickedValue === 'oz') {
			//User selects OZ
			if (servings_data.metric_serving_unit === 'oz') {
				//User selects OZ, and the original brand serving is suppled in OZ
				macroObj = macroAssign(
					servings_data,
					+servings_data.metric_serving_amount
				);
				macroObj.nutrientsV2 = nutrientsHelped(
					servings_data,
					dontIncludeNutrients,
					nutrientsV2,
					+servings_data.metric_serving_amount
				);
				if (servings_data.metric_serving_amount) {
					macroObj.totalWeightG =
						+servings_data.metric_serving_amount /
						(+servings_data.metric_serving_amount / 28.3495);
					macroObj.totalUnits = 'g';
				}
			} else if (servings_data.metric_serving_unit === 'g') {
				//User selects OZ, but the original brand serving is supplied in G
				macroObj = macroAssign(
					servings_data,
					+servings_data.metric_serving_amount / 28.3495
				);
				macroObj.nutrientsV2 = nutrientsHelped(
					servings_data,
					dontIncludeNutrients,
					nutrientsV2,
					+servings_data.metric_serving_amount / 28.3495
				);
			}
		} else if (pickedValue === 'ml') {
			//User selects ML
			if (servings_data.metric_serving_unit === 'ml') {
				//User selects ML, and the original brand serving is suppled in ML
				macroObj = macroAssign(
					servings_data,
					+servings_data.metric_serving_amount
				);
				macroObj.nutrientsV2 = nutrientsHelped(
					servings_data,
					dontIncludeNutrients,
					nutrientsV2,
					+servings_data.metric_serving_amount
				);
			}
		} else if (pickedValue === 'fl oz') {
			//User selects fl oz
			if (servings_data.metric_serving_unit === 'fl oz') {
				//User selects fl oz, and the original brand serving is suppled in fl oz
				macroObj = macroAssign(
					servings_data,
					+servings_data.metric_serving_amount
				);
				macroObj.nutrientsV2 = nutrientsHelped(
					servings_data,
					dontIncludeNutrients,
					nutrientsV2,
					+servings_data.metric_serving_amount
				);
			} else if (servings_data.metric_serving_unit === 'ml') {
				//User selects fl oz, but the original brand serving is supplied in ml
				macroObj = macroAssign(
					servings_data,
					+servings_data.metric_serving_amount / 29.5735
				);
				macroObj.nutrientsV2 = nutrientsHelped(
					servings_data,
					dontIncludeNutrients,
					nutrientsV2,
					+servings_data.metric_serving_amount / 29.5735
				);
			}
		}
	} else {
		//Generic Name Data API
		servings_data.forEach((element) => {
			if (pickedValue === element.measurement_description) {
				//User Picks g or ml from serving_data, Standardize (since generic api data comes per 100g or 100ml)
				if (pickedValue === 'g' || pickedValue === 'ml') {
					macroObj = macroAssign(element, 100);
					macroObj.nutrientsV2 = nutrientsHelped(
						element,
						dontIncludeNutrients,
						nutrientsV2,
						100
					);
				} else {
					//User Picks serving like cup or tbsp etc, item is 1 to 1 ratio
					macroObj.totalWeightG = +element.metric_serving_amount;
					macroObj.totalUnits = element.metric_serving_unit;
					macroObj.calories = element.calories;
					macroObj.proteins = element.protein;
					macroObj.fats = element.fat;
					macroObj.totalcarbs = element.carbohydrate;
					if (element.fiber) {
						macroObj.fiber = element.fiber;
					}
					macroObj.nutrientsV2 = nutrientsHelped(
						element,
						dontIncludeNutrients,
						nutrientsV2
					);
				}
			}
		});
	}

	//Apply the Multiplier to the baseline Macro amounts, Multiplier being the number value of the Scroller/Input on Single Serving Pages
	if (macroObj.totalWeightG) {
		macroObj.totalWeightG = (macroObj.totalWeightG * multiplier).toFixed(2);
	}
	macroObj.calories = Number((macroObj.calories * multiplier).toFixed(2));
	macroObj.proteins = Number((macroObj.proteins * multiplier).toFixed(2));
	macroObj.fats = Number((macroObj.fats * multiplier).toFixed(2));
	macroObj.totalcarbs = Number((macroObj.totalcarbs * multiplier).toFixed(2));
	macroObj.fiber = Number((macroObj.fiber * multiplier).toFixed(2));
	macroObj.carbs = Number((macroObj.totalcarbs - macroObj.fiber).toFixed(2));

	for (const [key, value] of Object.entries(macroObj.nutrientsV2)) {
		macroObj.nutrientsV2[key] = +(value * multiplier).toFixed(0);
	}


	return {
		...macroObj
	};
};

//Format the Units For the More Nutrients Table on the Single Serving Pages
export const nutrientsFormatting = (nutrientArrayOfObjects: Array<Object>) => {
	if (
		typeof nutrientArrayOfObjects !== 'undefined' &&
		nutrientArrayOfObjects.length > 0
	) {
		const newArray = nutrientArrayOfObjects.map((item) => {
			let newKey = item.key.replace(/_/g, ' ');
			newKey = newKey.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
				letter.toUpperCase()
			);
			return {
				name: newKey,
				amount: item.value
			};
		});

		return _.mapValues(_.keyBy(newArray, 'name'), 'amount');
	} else {
		return { ...defaultNutrients };
	}
};

//Serving Selector Set-up for Wheel
export const openWheel = (
	labelArray: Array<string>,
	onConfirm: Function,
	onCancel: Function,
	onChange: Function,
	lastValue: Array<[number, string, string]>,
	theme: Object
) => {
	//Initialize data for serving selector picker wheel
	const _createDateData = () => {
		let x = [];
		for (let t = 0; t <= 999; t++) {
			x.push(t);
		}
		return [
			x,
			['-', '1/8', '1/4', '1/3', '3/8', '1/2', '5/8', '2/3', '3/4', '7/8'],
			labelArray
		];
	};

	Picker.init({
		pickerTitleText: '',
		pickerConfirmBtnText: i18n.t('confirm'),
		pickerCancelBtnText: i18n.t('cancel'),
		pickerTextEllipsisLen: 24,
		pickerConfirmBtnColor: [255, 255, 255, 1],
		pickerCancelBtnColor: [255, 255, 255, 1],
		pickerTitleColor: [255, 255, 255, 1],
		pickerBg: theme.pickerBase,
		pickerToolBarBg: theme.pickerBg,
		pickerFontColor: theme.pickerBg,
		pickerFontFamily: 'Comfortaa',
		pickerFontSize: 14,
		wheelFlex: [1, 1, 4],
		pickerData: _createDateData(),
		selectedValue: lastValue,
		onPickerConfirm: (data) => {
			onConfirm(data);
		},
		onPickerCancel: () => {
			onCancel(lastValue);
			Picker.select(lastValue);
		},
		onPickerSelect: (data) => {
			onChange(data);
		}
	});

	//Actually shows the serving selector picker wheel
	Picker.show();
};
