//@flow
import * as React from 'react';
import { View, Image, StyleSheet, Dimensions } from 'react-native';
import { Text } from 'Components/common';
import Elevations from 'react-native-elevation';

type Props = {
	title: string,
	imageURL?: string,
	children?: React.Node,
	bgColor: string
};

export const Header = (props: Props) => {
	const { title, imageURL, children, bgColor } = props;
	return (
		<View>
			{typeof imageURL !== 'undefined' && (
				<View style={styles.imageContainer}>
					<Image
						style={styles.imageStyle}
						source={{
							uri: imageURL
						}}
					/>
				</View>
			)}
			<View
				style={[styles.headerContainer, { backgroundColor: bgColor }]}>
				<View style={styles.titleContainer}>
					<Text style={styles.recipeTitle}>{title}</Text>
				</View>
				<View style={styles.metaContainer}>{children}</View>
			</View>
		</View>
	);
};

const window = Dimensions.get('window');
const styles = StyleSheet.create({
	//Header.js
	headerContainer: {
		paddingHorizontal: 15,
		paddingTop: 10,
		paddingBottom: 12,
		elevation: 1.3,
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		overflow:'visible',
		marginBottom: 1,
		...Elevations[2]
	},
	recipeTitle: {
		fontSize: 16,
		fontWeight: 'bold'
	},
	imageContainer: {
		justifyContent: 'center',
		alignItems: 'center'
	},
	metaContainer: {
		flex: 1,
		flexDirection: 'column'
	},
	metaText: {
		fontSize: 12,
		lineHeight: 18
	},
	imageStyle: {
		width: window.width,
		height: (238 / 375) * window.width,
		alignSelf: 'center'
	}
});
