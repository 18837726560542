//@flow
import React from 'react';
import { StyleSheet } from 'react-native';
import { Text } from './';
import { Fonts } from '../../constants';

type Props = {
	style?: Object,
	children: string
};

export const Paragraph = (props: Props) => {
	return <Text style={[styles.learnText, props.style]}>{props.children}</Text>;
};

var styles = StyleSheet.create({
	learnText: {
		paddingLeft: 20,
		paddingRight: 20,
		fontFamily: 'Comfortaa',
		fontSize: Fonts.size.regular,
		lineHeight: Fonts.size.regular * 2
	}
});
