//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Fonts, Colors } from 'Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import { Text } from './';

export const Button = (props) => {
	const { onPress, color = '#FFF', isFlat = false } = props;

	const finalStyle = isFlat
		? [styles.flatContainer, props.style]
		: [styles.container, props.style];

	return (
		<TouchableOpacity style={finalStyle} onPress={onPress}>
			{props.iconName && (
				<Icon
					style={[styles.iconStyle, props.iconStyle]}
					name={props.iconName}
					color={color}
					size={props.iconSize}
				/>
			)}
			<Text style={[styles.ButtonText, props.textStyle]}>{props.children}</Text>
		</TouchableOpacity>
	);
};

var styles = StyleSheet.create({
	container: {
		borderRadius: 3,
		backgroundColor: Colors.themeAccent,
		justifyContent: 'center',
		alignItems: 'center',
		height: 50,
		marginVertical: 20,
		marginHorizontal: 15,
		flexDirection: 'row',
		elevation: 1,
		shadowColor: '#333',
		shadowOffset: {
			width: 0,
			height: 1
		},
		shadowRadius: 1,
		shadowOpacity: 0.5
	},
	flatContainer: {
		borderRadius: 3,
		backgroundColor: Colors.themeAccent,
		justifyContent: 'center',
		alignItems: 'center',
		height: 50,
		marginVertical: 20,
		marginHorizontal: 15,
		flexDirection: 'row'
	},
	ButtonText: {
		color: '#FFF',
		fontSize: 17,
		fontFamily: 'Raleway'
	},
	iconStyle: {
		paddingRight: 10,
		alignSelf: 'center'
	}
});
