//@flow
import React from "react";
import { ScrollView, View } from "react-native";
import { Paragraph, Heading, Divider, BlockQuote, Text } from "../common";
import styles from "./Styles/Styles";
import { i18n } from "../../constants";

export const Faq = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("faqh1")}</Heading>
			<Paragraph>{i18n.t("faqp1")}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("faqh2")}</Heading>
			<Paragraph>{i18n.t("faqp2")}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("faqh3")}</Heading>
			<Paragraph>{i18n.t("faqp3")}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("faqh4")}</Heading>
			<Paragraph>{i18n.t("faqp4")}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("faqh5")}</Heading>
			<Paragraph>{i18n.t("faqp5")}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("faqh6")}</Heading>
			<Paragraph>{i18n.t("faqp6")}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("faqh7")}</Heading>
			<Paragraph>{i18n.t("faqp7")}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("faqh8")}</Heading>
			<Paragraph>{i18n.t("faqp8")}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t("faqh9")}</Heading>
			<Paragraph>{i18n.t("faqp9")}</Paragraph>
			<Divider />
			<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }} style={{ paddingTop: 0, alignItems: "center" }}>
				<Text style={styles.linkText} onPress={() => Linking.openURL("https://www.hsph.harvard.edu/nutritionsource/healthy-weight/diet-reviews/mediterranean-diet/")}>
					{"\n"}Source
				</Text>
				<Text style={styles.linkText} onPress={() => Linking.openURL("https://pubmed.ncbi.nlm.nih.gov/16512956/")}>
					{"\n"}Source 2
				</Text>
				<Text
					style={styles.linkText}
					onPress={() => Linking.openURL(" https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/mediterranean-diet/art-20047801")}
				>
					{"\n"}Source 3
				</Text>
			</BlockQuote>
		</ScrollView>
	);
};
