//@flow
import React, { useState } from 'react';
import {
	View,
	TouchableOpacity,
	StyleSheet,
	Platform,
	Picker as PickerA,
	Image
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { i18n, Images } from 'Theme';
import { Text, Button } from 'Components/common';
import Picker from 'react-native-picker';
import Elevations from 'react-native-elevation';
import {
	exportBodyMeasurements,
	exportVitals,
	exportOtherTracking,
	exportDietLog
} from 'Utilities';
import { Feature } from 'Components/purchase/components';
import Lightbox from 'react-native-lightbox';

export const ProgressExportScreen = ({ navigation }) => {
	const { units } = useSelector((state) => state.firebase.profile.settings);
	const {
		auth,
		profile: { premium }
	} = useSelector((state) => state.firebase);
	const { vitals, bodymeasurements, othertracking, trackerdate } = useSelector(
		(state) => state.firebase.profile
	);
	const { theme } = useSelector((state) => state.startup);
	const [activeType, changeType] = useState(i18n.t('bodyMeasurements'));
	const [activeSubType, changeSubType] = useState(i18n.t('ketones'));

	const showPicker = (dataArr, onConfirm) => {
		Picker.init({
			pickerTitleText: '',
			pickerConfirmBtnText: i18n.t('confirm'),
			pickerCancelBtnText: i18n.t('cancel'),
			pickerTextEllipsisLen: 12,
			pickerConfirmBtnColor: [255, 255, 255, 1],
			pickerCancelBtnColor: [255, 255, 255, 1],
			pickerTitleColor: [255, 255, 255, 1],
			pickerBg: theme.pickerBase,
			pickerToolBarBg: theme.pickerBg,
			pickerFontColor: theme.pickerBg,
			pickerFontFamily: 'Raleway',
			pickerFontSize: 20,
			pickerRowHeight: 28,
			pickerData: dataArr,
			onPickerConfirm: (data) => {
				if (data == i18n.t('vitals')) {
					changeSubType(vitalsOptions[0]);
				} else if (data == i18n.t('otherTracking')) {
					changeSubType(otherOptions[0]);
				}
				onConfirm(data);
			}
		});
		Picker.show();
	};

	const typeOptions = [
		i18n.t('bodyMeasurements'),
		i18n.t('vitals'),
		i18n.t('otherTracking'),
		i18n.t('dietLog')
	];

	const vitalsOptions = [
		i18n.t('Ketones'),
		i18n.t('bloodGlucose'),
		i18n.t('Potassium'),
		i18n.t('Sodium'),
		i18n.t('Magnesium'),
		i18n.t('HeartRate'),
		i18n.t('BloodPressure')
	];

	const otherOptions = [i18n.t('exercise'), i18n.t('water'), i18n.t('notes')];

	const finalSubmitExport = (activeType, activeSubType) => {
		if (activeType == i18n.t('dietLog')) {
			exportDietLog(trackerdate, auth.email);
		} else if (activeType == i18n.t('vitals')) {
			exportVitals(vitals, units, auth.email, activeSubType);
		} else if (activeType == i18n.t('otherTracking')) {
			exportOtherTracking(othertracking, units, auth.email, activeSubType);
		} else {
			exportBodyMeasurements(bodymeasurements, units, auth.email);
		}
	};

	const renderSecondPicker = (activeT) => {
		return (
			<View
				style={[styles.listItemContainer, { borderBottomColor: theme.border }]}>
				<Text style={styles.listLabel}>{i18n.t('data')}:</Text>
				{activeT == i18n.t('bodyMeasurements') ||
				activeT == i18n.t('dietLog') ? (
					<View style={styles.listInput}>
						<Text style={[styles.inputText]}>{i18n.t('allData')}</Text>
					</View>
				) : (
					<View style={styles.listInput}>
						{Platform.OS !== 'ios' ? (
							<PickerA
								mode="dropdown"
								style={[
									styles.unitPicker,
									{
										backgroundColor: theme.baseBG,
										color: theme.themeAccent
									}
								]}
								selectedValue={activeSubType}
								onValueChange={(itemValue) => {
									changeSubType(itemValue);
								}}></PickerA>
						) : (
							<TouchableOpacity
								style={styles.iosPicker}
								onPress={() =>
									showPicker(
										activeType == i18n.t('vitals')
											? vitalsOptions
											: otherOptions,
										changeSubType
									)
								}>
								<Text style={[styles.inputText, { color: theme.themeAccent }]}>
									{activeSubType}
								</Text>
							</TouchableOpacity>
						)}
					</View>
				)}
			</View>
		);
	};

	if (!premium) {
		return (
			<View>
				<Feature
					icon="check"
					title="CSV Data Export"
					body="Export all of your Diet Log, Body Measurements, Vitals and Other Tracking items to CSV with Premium"
				/>
				<View style={styles.imageRow}>
					<Lightbox
						renderContent={() => (
							<View style={styles.modalImgCtn}>
								<Image style={styles.modalImg} source={Images.purchase3b} />
							</View>
						)}>
						<View style={styles.thumbCtn}>
							<Image style={styles.thumbImg} source={Images.purchase3b} />
						</View>
					</Lightbox>
					<Lightbox
						renderContent={() => (
							<View style={styles.modalImgCtn}>
								<Image style={styles.modalImg} source={Images.purchase3c} />
							</View>
						)}>
						<View style={styles.thumbCtn}>
							<Image style={styles.thumbImg} source={Images.purchase3c} />
						</View>
					</Lightbox>
				</View>
				
				<Button
					onPress={() => navigation.navigate('Purchase')}
					style={[styles.exportBtn, { backgroundColor: theme.themeAccent }]}>
					{i18n.t('premium')}
				</Button>
			</View>
		);
	}

	return (
		<View>
			<View
				style={[styles.listItemContainer, { borderBottomColor: theme.border }]}>
				<Text style={styles.listLabel}>{i18n.t('type')}:</Text>
				<View style={styles.listInput}>
					{Platform.OS !== 'ios' ? (
						<PickerA
							mode="dropdown"
							style={[
								styles.unitPicker,
								{
									backgroundColor: theme.baseBG,
									color: theme.themeAccent
								}
							]}
							selectedValue={activeType}
							onValueChange={(itemValue) => {
								changeType(itemValue);
							}}>
							{typeOptions.map((item) => {
								const label = item;
								return <PickerA.Item key={label} label={label} value={label} />;
							})}
						</PickerA>
					) : (
						<TouchableOpacity
							style={styles.iosPicker}
							onPress={() => showPicker(typeOptions, changeType)}>
							<Text style={[styles.inputText, { color: theme.themeAccent }]}>
								{activeType}
							</Text>
						</TouchableOpacity>
					)}
				</View>
			</View>
			{renderSecondPicker(activeType)}
			<Button
				onPress={() => finalSubmitExport(activeType, activeSubType)}
				style={[styles.exportBtn, { backgroundColor: theme.themeAccent }]}>
				{i18n.t('export')}
			</Button>
		</View>
	);
};

const styles = StyleSheet.create({
	listLabel: {
		flex: 2,
		alignSelf: 'center'
	},
	listItemContainer: {
		flexDirection: 'row',
		borderBottomWidth: 1,
		paddingVertical: Platform.OS === 'ios' ? 15 : 0,
		paddingLeft: 15,
		paddingRight: 15
	},
	listInput: {
		flex: 1,
		minWidth: 1,
		height: Platform.OS === 'ios' ? 30 : 50,
		justifyContent: 'center'
	},
	inputText: {
		textAlign: 'center',
		fontSize: 14,
		fontFamily: 'Raleway'
	},
	foodItem: {
		fontSize: 12,
		textAlign: 'left',
		lineHeight: 16
	},
	foodItemContainer: {
		borderBottomWidth: 1,
		flex: 1,
		flexDirection: 'row'
	},
	itemTitle: {
		flex: 4,
		paddingVertical: 15,
		paddingLeft: 20,
		paddingRight: 8,
		alignItems: 'flex-start'
	},
	quickDelete: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'stretch',
		flex: 0.75,
		paddingVertical: 15,
		borderLeftWidth: 1
	},
	quickDeleteIcon: {
		alignSelf: 'center'
	},
	copyheader: {
		paddingLeft: 15,
		paddingVertical: 10,
		...Elevations[1]
	},
	webTimeContainer: {
		zIndex: 9999,
		flexDirection: 'row',
		height: 50,
		justifyContent: 'center',
		alignItems: 'center'
	},
	exportBtn: {
		marginVertical: 50
	},
	//LIGHTBOX
	closeButton: {
		color: 'white',
		borderWidth: 1,
		borderColor: 'white',
		padding: 8,
		borderRadius: 3,
		textAlign: 'center',
		margin: 10,
		alignSelf: 'flex-end'
	},
	modalImgCtn: {
		width: '100%'
	},
	modalImg: {
		width: '90%',
		resizeMode: 'contain',
		alignSelf: 'center'
	},
	thumbCtn: {
		justifyContent: 'center',
		paddingHorizontal: 10
	},
	thumbImg: {
		width: 110,
		height: 190,
		resizeMode: 'contain',
		borderWidth: 1,
		padding: 4
	},
	imageRow: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
});
