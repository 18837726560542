//@flow
import { StyleSheet } from 'react-native';
import { Colors } from 'Theme';

export default StyleSheet.create({
	foodListStyle: {
		flex: 1
	},
	rowContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottomWidth: 1,
		paddingLeft: 15
	},
	itemName: {
		fontSize: 14,
		lineHeight: 16,
		marginTop: 17,
		fontFamily: 'Raleway'
	},
	itemTitle: {
		fontSize: 16,
		marginVertical: 20,
		fontFamily: 'Raleway'
	},
	itemDescription: {
		marginLeft: 0,
		fontSize: 10,
		lineHeight: 14,
		marginBottom: 19,
		marginTop: 5
	},
	textContainer: {
		flex: 1
	},
	textHolder: {
		flex: 4,
		flexDirection: 'row',
		paddingRight: 5
	},
	quickAddHolder: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignSelf: 'stretch',
		paddingLeft: 3
	},
	quickAdd: {
		alignSelf: 'center'
	},
	noFriendsContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		marginTop: 20
	},
	noFriendsText: {
		alignSelf: 'center',
		fontSize: 20
	},
	pendingTitleContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		borderWidth: 1
	},
	borderTopNo: {
		borderTopWidth: 0
	},
	pendingTitle: {
		flex: 1,
		alignSelf: 'center',
		paddingVertical: 10,
		paddingRight: -20,
		paddingLeft: 15,
		fontSize: 14
	},
	headerIcon: {
		marginRight: 20
	},

	//Friend Single Profile View
	friendFoodSwitchContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'flex-end'
	},
	tabIcon: {
		alignSelf: 'center',
		justifyContent: 'center'
	},
	tabText: {
		marginLeft: 10
	},
	sectionTab: {
		flex: 1,
		flexDirection: 'row',
		padding: 15,
		justifyContent: 'center',
		alignItems: 'center',
		borderTopWidth: 1,
		
	},
	noBorderLeft: {
		borderLeftWidth: 0
	},
	itemListStyle: {
		flex: 1
	},
	copyAll: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'flex-start',
		paddingVertical: 20,
		paddingHorizontal: 50,
		borderBottomWidth: 1,
		
	},
	copyAllTab: {
		flex: 1,
		flexDirection: 'row',
		padding: 10,
		justifyContent: 'center',
		alignItems: 'flex-start',
		borderWidth: 1,
	},

	//Single Food/Meal
	buttonRow: {
		justifyContent: 'center',
		alignItems: 'flex-start',
		flexDirection: 'row',
	},
	buttonStyle: {
		flex: 1,
		margin: 0
	},
	deleteBtn: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 100
	},
	acceptBtn:{
		justifyContent: 'center',
		alignItems: 'center',
		width: 100
	},
	swipeButtonText: {
		color: '#FFF'
	},
	hiddenCtn: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},

});
