//@flow
import * as types from './types';
import { i18n } from 'Theme';

export const servingWheelChange = (itemType, activeWheelValue) => {
	return {
		type: types.items.SERVING_WHEEL_CHANGE,
		itemType,
		activeWheelValue
	};
};

export const servingWheelCancel = (itemType) => {
	return {
		type: types.items.SERVING_WHEEL_CANCEL,
		itemType
	};
};

export const servingWheelConfirm = (itemType, activeWheelValue) => {
	return {
		type: types.items.SERVING_WHEEL_CONFIRM,
		itemType,
		activeWheelValue
	};
};

export const inputQtyChange = (itemType, value) => {
	return {
		type: types.items.SERVING_INPUT_QTY_CHANGE,
		itemType,
		value
	};
};

export const inputLabelChange = (itemType, activeLabel) => {
	return {
		type: types.items.SERVING_INPUT_LABEL_CHANGE,
		itemType,
		activeLabel
	};
};

export const servingSelectTypeChange = (itemType, activeType) => {
	return {
		type: types.items.SWITCH_SERVING_SELECTOR,
		itemType,
		activeType
	};
};

export const resetItem = (itemType) => {
	return {
		type: types.items.RESET_ITEM,
		itemType
	};
};

export const receiveItem = (itemType, itemData) => {
	return {
		type: types.items.RECEIVE_ITEM,
		itemType,
		itemData
	};
};

export const editItemPreloadServing = (itemType, itemData) => {
	const wholeD = itemData.name.match(/([\d.]+) x*/g).pop();
	const arr = [+wholeD, '-', i18n.t('servings')];
	return {
		type: types.items.PRELOAD_EDIT_SERVING,
		itemType,
		arr
	};
};
