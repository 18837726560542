//@flow
import type {
	FirebaseLogEntry,
	FirebaseCustomFoodv3,
	FirebaseSavedMealv3
} from 'Utilities/FlowTypes';
import { i18n } from 'Theme';
import { defaultNutrients } from 'Redux/selectors';

//Calculates the Calories from Fat, Carb, and Proteins in Grams
export const calculateCaloriesFromMacros = (
	Fat: number = 0,
	netCarb: number = 0,
	Protein: number = 0,
	Fiber: number = 0
) => {
	const FatCalories = Fat * 9;
	const CarbCalories = netCarb * 4;
	const FiberCalories = Fiber * 0;
	const ProteinCalories = Protein * 4;
	return +(
		FatCalories +
		ProteinCalories +
		CarbCalories +
		FiberCalories
	).toFixed(2);
};

//Returns an Object with each respective Macro Total (G) when given an array of Log Entry Objects (ie Breakfast - [{appleObj},{beefObj},{eggObj}])
export const calculateMacroTotals = (arrOfFoodObjects: Array<Object>) => {
	var objectHolder = {
		fats: 0,
		carbs: 0,
		totalcarbs: 0,
		fiber: 0,
		proteins: 0,
		calories: 0,
		nutrientsV2: { ...defaultNutrients }
	};

	for (const x of arrOfFoodObjects) {

		objectHolder.fats += +x.fats;
		objectHolder.carbs += +x.carbs;
		x.totalcarbs
			? (objectHolder.totalcarbs += +x.totalcarbs)
			: (objectHolder.totalcarbs += +x.carbs);
		x.fiber ? (objectHolder.fiber += +x.fiber) : (objectHolder.fiber += 0);
		objectHolder.proteins += +x.proteins;
		objectHolder.calories += +x.calories;
		if (typeof x.nutrientsV2 !== 'undefined') {
			for (const [key, value] of Object.entries(x.nutrientsV2)) {
				objectHolder.nutrientsV2[key] += +value;
			}
		}
	}
	return objectHolder;
};

const mealDescription = (
	foodsArray: Array<Object>,
	suggestedServingSize: number,
	customServingName: string,
	carbUnits: string
) => {
	let calories = 0;
	let proteins = 0;
	let fats = 0;
	let carbs = 0;
	let totalcarbs = 0;
	let fiber = 0;

	for (let i = 0, len = foodsArray.length; i < len; i++) {
		calories += foodsArray[i].calories;
		proteins += foodsArray[i].proteins;
		fats += foodsArray[i].fats;
		carbs += foodsArray[i].carbs;
		totalcarbs += foodsArray[i].totalcarbs;
		fiber += foodsArray[i].fiber;
	}

	calories = +calories.toFixed(2);
	proteins = +proteins.toFixed(2);
	fats = +fats.toFixed(2);
	carbs = +carbs.toFixed(2);
	totalcarbs = +(+totalcarbs).toFixed(2);
	fiber = +(+fiber).toFixed(2);
	const carbLine =
		carbUnits === 'total'
			? `${i18n.t('totalcarb')}: ${(totalcarbs / suggestedServingSize).toFixed(
					2
			  )}g`
			: `${i18n.t('netcarb')}: ${(carbs / suggestedServingSize).toFixed(2)}g`;
	return `${i18n.t('per')} ${customServingName}: ${(
		calories / suggestedServingSize
	).toFixed(2)} kcal
${i18n.t('fat')}: ${(fats / suggestedServingSize).toFixed(
		2
	)}g  |  ${carbLine}  |  ${i18n.t('protein')}: ${(
		proteins / suggestedServingSize
	).toFixed(2)}g`;
};

const foodDescription = (
	item: Object,
	suggestedServingSize: number,
	carbUnits: string
) => {
	const carbLine =
		carbUnits === 'total'
			? `${i18n.t('totalcarb')}: ${(
					item.totalcarbs / suggestedServingSize
			  ).toFixed(2)}g`
			: `${i18n.t('netcarb')}: ${(item.carbs / suggestedServingSize).toFixed(
					2
			  )}g`;
	return `${item.calories / suggestedServingSize} kcal
${i18n.t('fat')}: ${
		item.fats / suggestedServingSize
	}g  |  ${carbLine}  |  ${i18n.t('protein')}: ${
		item.proteins / suggestedServingSize
	}g`;
};

//Used to display the "Macro Serving Description" underneath an item's name in the Tab List Views
export const calculateMacroDescription = (item: Object, carbUnits: string) => {
	const suggestedServingSize = item.suggestedServingSize
		? +item.suggestedServingSize
		: 1;
	const customServingName = item.customServingName
		? `${item.customServingName}`
		: i18n.t('servings');
	if (Array.isArray(item.foods)) {
		return mealDescription(
			item.foods,
			suggestedServingSize,
			customServingName,
			carbUnits
		);
	} else if (typeof item.calories !== 'undefined') {
		return foodDescription(item, suggestedServingSize, carbUnits);
	} else {
		return `0 kcal
${i18n.t('fat')}: 0g  |  ${
			carbUnits === 'total'
				? `${i18n.t('totalcarb')}: 0g`
				: `${i18n.t('netcarb')}: 0g`
		}  |  ${i18n.t('protein')}: 0g`;
	}
};

/////Calculates the Macros based off the item and Serving Size. It is used by Single Foods/Meals to calculate serving macros/nutrients
export const servingUpdate = (
	item: FirebaseSavedMealv3 | FirebaseCustomFoodv3,
	servingSize: string = '1',
	isEditing: boolean = false
) => {
	let macroHolder = {
		...item,
		fats: 0,
		carbs: 0,
		totalcarbs: 0,
		fiber: 0,
		proteins: 0,
		calories: 0,
		nutrientsV2: { ...defaultNutrients, ...item.nutrientsV2 },
		servingAmount: item.name
	};
	macroHolder.suggestedServingSize = item.suggestedServingSize
		? +item.suggestedServingSize
		: 1;

	//Returns 0's for all values if a invalid number is entered for serving size
	if (isNaN(servingSize) || +servingSize < 0) {
		return macroHolder;
	}

	//Convert serving size to a number type
	let qty = +servingSize;

	//Update Macros for Single Meal View
	if (item.type === 'meal') {
		if (typeof item.foods === 'undefined') {
			return macroHolder;
		}

		for (var i = 0, len2 = item.foods.length; i < len2; i++) {
			macroHolder.calories += +item.foods[i].calories;
			macroHolder.proteins += +item.foods[i].proteins;
			macroHolder.fats += +item.foods[i].fats;
			macroHolder.carbs += +item.foods[i].carbs;
			macroHolder.totalcarbs += +item.foods[i].totalcarbs;
			macroHolder.fiber += +item.foods[i].fiber;

			if (typeof item.foods[i].nutrientsV2 !== 'undefined') {
				for (const [key, value] of Object.entries(item.foods[i].nutrientsV2)) {
					macroHolder.nutrientsV2[key] += +item.foods[i].nutrientsV2[key];
				}
			}
		}

		//If NOT editing, divide by suggested meal serving size to get default 1 serving from meal/recipe
		if (!isEditing) {
			macroHolder.calories /= macroHolder.suggestedServingSize;
			macroHolder.proteins /= macroHolder.suggestedServingSize;
			macroHolder.fats /= macroHolder.suggestedServingSize;
			macroHolder.carbs /= macroHolder.suggestedServingSize;
			macroHolder.totalcarbs /= macroHolder.suggestedServingSize;
			macroHolder.fiber /= macroHolder.suggestedServingSize;

			if (typeof macroHolder.nutrientsV2 !== 'undefined') {
				for (const [key, value] of Object.entries(macroHolder.nutrientsV2)) {
					macroHolder.nutrientsV2[key] =
						+value / macroHolder.suggestedServingSize;
				}
			}
		}

		//Update Macros for Single Food View
	} else if (typeof item.calories !== 'undefined') {
		macroHolder.calories = +item.calories;
		macroHolder.proteins = +item.proteins;
		macroHolder.fats = +item.fats;
		macroHolder.carbs = +item.carbs;
		macroHolder.fiber = +item.fiber;
		macroHolder.totalcarbs = +item.totalcarbs;
		if (typeof macroHolder.nutrientsV2 !== 'undefined') {
			for (const [key, value] of Object.entries(macroHolder.nutrientsV2)) {
				macroHolder.nutrientsV2[key] = +value;
			}
		}
	}

	//Calculate in the serving size qty added in the input box, round to 2 decimals
	macroHolder.calories = (macroHolder.calories * qty).toFixed(2);
	macroHolder.proteins = (macroHolder.proteins * qty).toFixed(2);
	macroHolder.fats = (macroHolder.fats * qty).toFixed(2);
	macroHolder.carbs = (macroHolder.carbs * qty).toFixed(2);
	macroHolder.fiber = (macroHolder.fiber * qty).toFixed(2);
	macroHolder.totalcarbs = (macroHolder.totalcarbs * qty).toFixed(2);
	if (typeof macroHolder.nutrientsV2 !== 'undefined') {
		for (const [key, value] of Object.entries(macroHolder.nutrientsV2)) {
			macroHolder.nutrientsV2[key] = +(value * qty).toFixed(0);
			if (value === 0) {
				delete macroHolder.nutrientsV2[key];
			} else {
				macroHolder.nutrientsV2[key] = +(value * qty).toFixed(0);
			}
		}
	}

	macroHolder.servingAmount = macroHolder.customServingName
		? `${item.name} x ${servingSize} ${macroHolder.customServingName}`
		: `${item.name} x ${servingSize} ${i18n.t('servings')}`;
	return macroHolder;
};
