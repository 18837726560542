//@flow
import { ADD_REGULAR_INTER_COUNT, RESET_REGULAR_INTER_COUNT } from './types';

export const incrementTrackerInterAdCount = () => {
	return {
		type: ADD_REGULAR_INTER_COUNT
	};
};

export const resetRegularInterCount = () => {
	return {
		type: RESET_REGULAR_INTER_COUNT
	};
};
