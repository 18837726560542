//@flow
import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import {
	NavOptionsBottomTab,
	NavOptionsHeader,
	NavOptionsHeaderNoDraw
} from '../';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { DietProgressScreen } from 'Components/tracker/progress/DietProgressScreen';
import { BodyProgressScreen } from 'Components/tracker/progress/BodyProgressScreen';
import { VitalsProgressScreen } from 'Components/tracker/progress/VitalsProgressScreen';
import { OtherProgressScreen } from 'Components/tracker/progress/OtherProgressScreen';
import { ProgressExportScreen } from 'Components/tracker/progress/ProgressExportScreen';
import { i18n } from 'Theme';
import { HeaderMenuDropdown } from 'Components/common';

const Stack = createStackNavigator();
const Tab = createMaterialTopTabNavigator();

const ProgressRouter = (props2) => {
	return (
		<Tab.Navigator
			initialRouteName="DietTab"
			backBehavior={'none'}
			lazy={true}
			swipeEnabled={false}
			tabBarPosition={'top'}
			tabBar={(props) => {
				return (
					<NavOptionsBottomTab
						{...props}
						{...{
							showIcon: true,
							showLabel: false,
							upperCaseLabel: false,
							allowFontScaling: false
						}}
					/>
				);
			}}>
			<Tab.Screen
				options={{
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons
							name={'silverware-fork-knife'}
							size={20}
							color={color}
						/>
					)
				}}
				name="DietTab"
				component={DietProgressScreen}
				initialParams={{ key: props2.route.key }}
			/>
			<Tab.Screen
				options={{
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons
							name={'scale-bathroom'}
							size={20}
							color={color}
						/>
					)
				}}
				name="BodyMeasurementsTab"
				component={BodyProgressScreen}
				initialParams={{ key: props2.route.key }}
			/>
			<Tab.Screen
				options={{
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons
							name={'stethoscope'}
							size={20}
							color={color}
						/>
					)
				}}
				name="VitalsTab"
				component={VitalsProgressScreen}
				initialParams={{ key: props2.route.key }}
			/>
			<Tab.Screen
				options={{
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons
							name={'cup-water'}
							size={20}
							color={color}
						/>
					)
				}}
				name="OtherTab"
				component={OtherProgressScreen}
				initialParams={{ key: props2.route.key }}
			/>
		</Tab.Navigator>
	);
};

const ProgressStack = () => {
	return (
		<Stack.Navigator initialRouteName="ProgressScreen">
			<Stack.Screen
				name="ProgressScreen"
				component={ProgressRouter}
				options={{
					header: ({ navigation, scene }) => {
						const menuArray = [
							{
								iconText: `${i18n.t('export')}`,
								onPress: () => navigation.navigate('ProgressExportScreen'),
								iconName: 'download',
								iconColor: '#68c3b9'
							}
						];
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('progress')}
								right={<HeaderMenuDropdown array={menuArray} />}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="ProgressExportScreen"
				component={ProgressExportScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('export')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

export default ProgressStack;
