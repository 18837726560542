//@flow
import React, { useState } from "react";
import { ScrollView, View, StyleSheet, FlatList, Image, SectionList, Picker, Platform } from "react-native";
import { Paragraph, Heading, Divider, BlockQuote, Text, Selectbox } from "../common";
import { i18n, Images } from "../../constants";
import ModalSelector from "react-native-modal-selector";
import DATA from "./data/forbiddenfoods";
import { useSelector } from "react-redux";

let dataFilter;

const FOOD_CATEGORIES = [
	{ key: 0, label: "All" },
	{ key: 1, label: "Red Meat" },
	{ key: 2, label: "Most Dairy" },
	{ key: 3, label: "Beverages" },
	{ key: 4, label: "Processed Foods" },
];

const filterData = () => {};

export const ForbiddenFoodScreen = () => {
	const [filter, setFilter] = useState(FOOD_CATEGORIES[0]);
	const [data, setData] = useState(DATA);
	const { theme } = useSelector((state) => state.startup);

	//Render Each Food and NetCarb Number
	const renderItem = ({ item }) => {
		return (
			<View style={[styles.row, {borderBottomColor: theme.border}]}>
				<Text style={styles.title}>{item.title}</Text>
			</View>
		);
	};

	//Render Heading
	const renderHeading = (title, subtitle) => {
		return (
			<View style={[styles.titleRow, { borderBottomColor: theme.border }]}>
				<Heading style={{ backgroundColor: theme.base, marginBottom: 0 }} titleStyle={{ paddingTop: 10, paddingBottom: 10 }}>
					{title}
				</Heading>
				<Text style={styles.subtitle}>{subtitle}</Text>
			</View>
		);
	};

	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<View>
				<Heading style={{ backgroundColor: theme.base }}>{i18n.t("foodlisth2")}</Heading>
				<Paragraph>{i18n.t("foodlistp2")}</Paragraph>
				<Divider />
				{Platform.OS !== "web" ? (
					<ModalSelector
						data={FOOD_CATEGORIES}
						initValueTextStyle={{ color: theme.blue }}
						initValue={FOOD_CATEGORIES[0].label}
						onChange={(option) => {
							FOOD_CATEGORIES[option.key].label === "All" ? setData(DATA) : setData(DATA.filter((obj) => obj.title === option.label));
						}}
					/>
				) : (
					<Picker
						selectedValue={filter.key}
						style={styles.webPicker}
						onValueChange={(itemValue, itemIndex) => {
							setFilter(FOOD_CATEGORIES[itemIndex]);
							FOOD_CATEGORIES[itemIndex].label === "All" ? setData(DATA) : setData(DATA.filter((obj) => obj.title === FOOD_CATEGORIES[itemIndex].label));
						}}
					>
						{FOOD_CATEGORIES.map((item, index) => (
							<Picker.Item label={item.label} value={item.key} key={item.key} />
						))}
					</Picker>
				)}
				<SectionList
					style={{ marginTop: 40 }}
					sections={data}
					keyExtractor={(item, index) => item + index}
					renderItem={renderItem}
					renderSectionHeader={({ section: { title, subtitle } }) => renderHeading(title, subtitle)}
				/>
				<Divider />
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {},
	img: {
		width: 75,
		height: 75,
		borderRadius: 50,
	},
	row: {
		flexDirection: "row",
		flex: 1,
		borderBottomWidth: 1,
		paddingVertical: 10,
		paddingHorizontal: 10,
		alignItems: "center",
	},
	titleRow: {
		flex: 1,
		borderBottomWidth: 1,
		paddingVertical: 10,
	},
	title: {
		paddingLeft: 10,
		fontSize: 18,
		paddingBottom: 0,
		marginBottom: 0,
	},
	subtitle: {
		fontSize: 12,
		paddingHorizontal: 20,
		paddingTop: 10
	},
	webPicker: {
		marginLeft: 20,
		height: 50,
		width: "90%",
	},
});
