//@flow

//Init
export const ahk_Auth = () => {
	return false;
};

export const ahk_saveWeightWaterGlucose = () => {
	return false;
};

export const ahk_deleteGlucose = () => {
	return false;
};

export const ahk_updateMacros = () => {};
