//@flow
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { FriendsScreen } from 'Components/tracker/social/FriendsScreen';
import { FriendsSingleScreen } from 'Components/tracker/social/FriendsSingleScreen';
import { FriendsSingleFoodScreen } from 'Components/tracker/social/FriendsSingleFoodScreen';
import { FriendsSingleMealScreen } from 'Components/tracker/social/FriendsSingleMealScreen';
import { PendingScreen } from 'Components/tracker/social/PendingScreen';
import { i18n } from 'Theme';
import {
	NavOptionsHeaderNoDraw,
	NavOptionsHeader,
	NavOptionsBottomTab
} from '../';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { HeaderIconButton } from 'Components/common';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const FriendsStack = ({ route }) => {
	return (
		<Stack.Navigator initialRouteName="FriendsScreen">
			<Stack.Screen
				name="FriendsScreen"
				component={FriendsScreen}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('friends')}
								right={
									<HeaderIconButton
										name={'md-add'}
										onPress={params.handleFriendRequest}
									/>
								}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="FriendsSingleScreen"
				component={FriendsSingleScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('friends')}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="FriendsSingleFoodScreen"
				component={FriendsSingleFoodScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('copyFood')}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="FriendsSingleMealScreen"
				component={FriendsSingleMealScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('copyMeal')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const PendingStack = ({ route }) => {
	return (
		<Stack.Navigator initialRouteName="PendingFriends">
			<Stack.Screen
				name="PendingFriends"
				component={PendingScreen}
				initialParams={{ manage: false }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('pending')}
								right={
									<HeaderIconButton
										name={'md-add'}
										onPress={params.handleFriendRequest}
									/>
								}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const SocialScreenRouter = ({ route }) => {
	return (
		<Tab.Navigator
			initialRouteName="FriendsScreen"
			backBehavior={'none'}
			lazy={true}
			swipeEnabled={false}
			tabBarPosition={'bottom'}
			tabBar={(props) => <NavOptionsBottomTab {...props} />}>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('friends'),
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons
							name={'account-multiple'}
							size={20}
							color={color}
						/>
					)
				}}
				name="FriendsScreen"
				component={FriendsStack}
			/>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('pending'),
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons
							name={'account-multiple-plus'}
							size={20}
							color={color}
						/>
					)
				}}
				name="PendingFriends"
				component={PendingStack}
			/>
		</Tab.Navigator>
	);
};

export default SocialScreenRouter;
