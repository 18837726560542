//Start Up Types
export const START_UP_COUNTER = 'START_UP_COUNTER';
export const NEVER_RATE_APP = 'NEVER_RATE_APP';
export const CHANGE_LANG = 'CHANGE_LANG';
export const DB_CHECKED = 'DB_CHECKED';
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';

//Ad Types
export const ADD_REGULAR_INTER_COUNT = 'ADD_REGULAR_INTER_COUNT';
export const RESET_REGULAR_INTER_COUNT = 'RESET_REGULAR_INTER_COUNT';

//Firebase Auth
export const LOG_OUT_USER = 'LOG_OUT_USER';

//Editing User Saved Meals Types
export const COPY_MEAL_EDITING_INGREDIENTS = 'COPY_MEAL_EDITING_INGREDIENTS';
export const DELETE_MEAL_EDIT_INGREDIENT = 'DELETE_MEAL_EDIT_INGREDIENT';
export const EDITING_MEAL_ADD_INGREDIENT = 'EDITING_MEAL_ADD_INGREDIENT';
export const EDITING_MEAL_UPDATE_INGREDIENT = 'EDITING_MEAL_UPDATE_INGREDIENT';
export const SET_EDIT_INGREDIENT_OBJECT = 'SET_EDIT_INGREDIENT_OBJECT';

//Body Measurements, Vitals, Other, Progress Page
export const CHANGE_BM_REVIEW_NAME = 'CHANGE_BM_REVIEW_NAME';
export const CHANGE_VITAL_REVIEW_NAME = 'CHANGE_VITAL_REVIEW_NAME';
export const CHANGE_OTHER_REVIEW_NAME = 'CHANGE_OTHER_REVIEW_NAME';

//Firebase Data
export const firebase = {
	FIREBASE_UPDATE_REQUESTED: 'FIREBASE_UPDATE_REQUESTED',
	FIREBASE_UPDATE_FULFILLED: 'FIREBASE_UPDATE_FULFILLED',
	FIREBASE_UPDATE_REJECTED: 'FIREBASE_UPDATE_REJECTED',
	FIREBASE_REMOVE_REQUESTED: 'FIREBASE_REMOVE_REQUESTED',
	FIREBASE_REMOVE_FULFILLED: 'FIREBASE_REMOVE_FULFILLED',
	FIREBASE_REMOVE_REJECTED: 'FIREBASE_REMOVE_REJECTED',
	FIREBASE_LISTEN_REQUESTED: 'FIREBASE_LISTEN_REQUESTED',
	FIREBASE_LISTEN_FULFILLED: 'FIREBASE_LISTEN_FULFILLED',
	FIREBASE_LISTEN_REJECTED: 'FIREBASE_LISTEN_REJECTED',
	FIREBASE_LISTEN_CHILD_ADDED: 'FIREBASE_LISTEN_CHILD_ADDED',
	FIREBASE_LISTEN_CHILD_CHANGED: 'FIREBASE_LISTEN_CHILD_CHANGED',
	FIREBASE_LISTEN_CHILD_REMOVED: 'FIREBASE_LISTEN_CHILD_REMOVED',
	FIREBASE_LISTEN_REMOVED: 'FIREBASE_LISTEN_REMOVED',
	FIREBASE_RESET_DATA: 'FIREBASE_RESET_DATA'
};

export const metaTypes = {
	customFoods: 'customFoods',
	singleCustomFood: 'singleCustomFood',
	savedMeals: 'savedMeals',
	singleSavedMeal: 'singleSavedMeal'
};

//Single Item Reducer Types
export const itemTypes = {
	food: 'food',
	meal: 'meal',
	editMeal: 'editMeal',
	mealClone: 'mealClone',
	recipe: 'recipe'
};

//Single Item Actions
export const items = {
	RESET_ITEM: 'RESET_ITEM',
	RECEIVE_ITEM: 'RECEIVE_ITEM',
	SERVING_WHEEL_CANCEL: 'SERVING_WHEEL_CANCEL',
	SERVING_WHEEL_CHANGE: 'SERVING_WHEEL_CHANGE',
	SERVING_WHEEL_CONFIRM: 'SERVING_WHEEL_CONFIRM',
	SERVING_INPUT_QTY_CHANGE: 'SERVING_INPUT_QTY_CHANGE',
	SERVING_INPUT_LABEL_CHANGE: 'SERVING_INPUT_LABEL_CHANGE',
	SWITCH_SERVING_SELECTOR: 'SWITCH_SERVING_SELECTOR',
	PRELOAD_EDIT_SERVING: 'PRELOAD_EDIT_SERVING'
};

//UI Manager
export const GENERIC_LOADING_TOGGLE = 'GENERIC_LOADING_TOGGLE';
export const ui = {
	SHOW_ITEM: 'SHOW_ITEM',
	HIDE_ITEM: 'HIDE_ITEM',
	TOGGLE_ITEM: 'TOGGLE_ITEM'
};
