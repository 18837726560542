//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import * as Animatable from 'react-native-animatable';
let height = 60;

type Props = {
	name: string,
	onPress: Function,
	color?: string,
	style?: Object,
	iconStyle?: Object,
	isAnimated?: boolean
};

export const HeaderIconButton = (props: Props) => {
	const { theme } = useSelector((state) => state.startup);
	const {
		onPress,
		name,
		color = theme.navButton,
		style,
		isAnimated = false
	} = props;
	if (isAnimated) {
		return (
			<TouchableOpacity style={[styles.container, style]} onPress={onPress}>
				<Animatable.View
					animation={'pulse'}
					iterationCount="infinite"
					direction="alternate">
					<Ionicons
						style={[styles.iconStyle, props.iconStyle]}
						name={name}
						color={color}
						size={24}
					/>
				</Animatable.View>
			</TouchableOpacity>
		);
	}

	return (
		<TouchableOpacity style={[styles.container, style]} onPress={onPress}>
			<Ionicons
				style={[styles.iconStyle, props.iconStyle]}
				name={name}
				color={color}
				size={24}
			/>
		</TouchableOpacity>
	);
};

var styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
		height: height,
		paddingHorizontal: 15
	}
});
