//@flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { View, TouchableOpacity } from 'react-native';
import { Text } from 'Components/common';
import Icon from 'react-native-vector-icons/FontAwesome';
import { calculateMacroDescription } from 'Utilities/MacrosUtil';
import styles from '../Styles/FlatList';
import { iconType, typeText } from '../../utilities/helper';
import { servingUpdate } from 'Utilities/MacrosUtil';

type Props = {
	carbUnits: string,
	addIngredient: Function,
	item: Object,
	navigation: Object,
	route: Object,
	type: string,
	theme: Object
};

export const ModalFlatList = (props: Props) => {
	const dispatch = useDispatch();
	const {
		item,
		type,
		addIngredient,
		carbUnits,
		navigation,
		route,
		theme
	} = props;
	let linkType = false;
	if (type === 'favorite' || type === 'recent') {
		linkType = true;
	}
	const routeConfig = (itemType) => {
		switch (itemType) {
			case 'api':
				return 'MealEditingSingleSearch';
			case 'food':
				return 'MealEditingSingleFood';
			case 'meal':
				return 'MealEditingSingleMeal';
			default:
				return '';
		}
	};

	const navigateCloseKeyboard = (item) => {
		navigation.navigate(routeConfig(item.type), {
			...route.params,
			foodID: item.id,
			tracker: false
		});
	};

	return (
		<View style={[styles.rowContainer, { borderBottomColor: theme.border }]}>
			<View style={styles.textHolder}>
				<View style={styles.textContainer}>
					<TouchableOpacity onPress={() => navigateCloseKeyboard(item)}>
						<Text style={[styles.itemTitle, { color: theme.themeAccent }]}>
							{item.name}
						</Text>
						{!linkType && (
							<Text style={styles.itemDescription}>
								{calculateMacroDescription(item, carbUnits)}
							</Text>
						)}
						{linkType && (
							<View style={styles.favDescription}>
								<Icon
									style={[styles.typeIcon, { color: theme.themeAccent }]}
									name={iconType(item.type)}
									size={10}
								/>
								<Text style={styles.typeDescription}>
									{typeText(item.type)}
								</Text>
							</View>
						)}
					</TouchableOpacity>
				</View>
				{item.favorite === true ? (
					<Icon
						style={{ alignSelf: 'center', flex: 0.1 }}
						name={'star'}
						color={'#f1c40f'}
						size={12}
					/>
				) : null}
			</View>
			{!linkType && (
				<TouchableOpacity
					style={[styles.quickAddHolder, { backgroundColor: theme.grey }]}
					onPress={() => dispatch(addIngredient(servingUpdate(item), true))}>
					<Icon style={styles.quickAdd} name="plus" color={theme.darkFont} size={12} />
				</TouchableOpacity>
			)}
		</View>
	);
};
