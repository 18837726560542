//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'Components/common';
import {
	VictoryChart,
	VictoryLabel,
	VictoryAxis,
	VictoryTheme,
	VictoryLine,
	VictoryLegend
} from 'victory-native';
import Svg from 'react-native-svg';
import { i18n } from 'Theme';
import moment from 'moment';

type Props = {
	width: number,
	data: Object,
	yLabel?: string,
	xLabel?: string
};

export const ProgressLineMacros = (props: Props) => {
	const { width, data, yLabel, xLabel, theme } = props;
	//Legend labels switch back and forth on click
	const carbLabel = i18n.t('carbs');
	const fatLabel = i18n.t('fat');
	const proteinLabel = i18n.t('protein');

	if (data.carb.length < 2) {
		return (
			<View style={styles.chartContainer}>
				<View style={styles.chartErrorContainer}>
					<Text style={styles.weightErrorText}>
						{i18n.t('addMoreEntriesToSeeLine')}
					</Text>
				</View>
			</View>
		);
	}

	if (data.carb.length >= 2) {
		return (
			<View style={styles.chartContainer}>
				<VictoryChart
					theme={VictoryTheme.material}
					padding={{ top: 55, bottom: 65, left: 70, right: 50 }}
					width={width}
					disableContainerEvents={false}
					animate={{
						duration: 250,
						onLoad: { duration: 0 }
					}}>
					<VictoryAxis
						dependentAxis
						axisLabelComponent={<VictoryLabel dy={-120} dx={5} angle={0} />}
						tickFormat={(y) => `${y.toFixed(0)}g`}
						style={{
							grid: { stroke: () => theme.border },
							tickLabels: {
								fontSize: 12,
								fontFamily: 'Raleway',
								fill: theme.darkFont
							},
							axisLabel: {
								padding: 40,
								fontSize: 14,
								fontFamily: 'Raleway',
								color: theme.darkFont,
								fill: theme.darkFont
							},
							axis: { stroke: theme.placeholderGray }
						}}
					/>
					<VictoryAxis
						label={xLabel}
						tickLabelComponent={<VictoryLabel angle={40} />}
						fixLabelOverlap={true}
						tickFormat={(x) => moment(x).format('MM/DD')}
						style={{
							grid: { stroke: () => theme.border },
							tickLabels: {
								fontSize: 8,
								fill: theme.darkFont,
								fontFamily: 'Raleway'
							},
							axisLabel: {
								padding: 40,
								fontSize: 14,
								fontFamily: 'Raleway',
								color: theme.darkFont,
								fill: theme.darkFont
							},
							axis: { stroke: theme.placeholderGray }
						}}
					/>
					<VictoryLine
						style={{
							data: { stroke: theme.graphCarb, strokeWidth: 3 },
							labels: {
								fontSize: 9,
								fontFamily: 'Raleway',
								fill: theme.darkFont
							}
						}}
						data={data.carb}
					/>
					<VictoryLine
						style={{
							data: { stroke: theme.graphProtein, strokeWidth: 3 },
							labels: {
								fontSize: 9,
								fontFamily: 'Raleway',
								fill: theme.darkFont
							}
						}}
						data={data.protein}
					/>
					<VictoryLine
						style={{
							data: { stroke: theme.graphFat, strokeWidth: 3 },
							labels: {
								fontSize: 9,
								fontFamily: 'Raleway',
								fill: theme.darkFont
							}
						}}
						data={data.fat}
					/>
				</VictoryChart>
				<View style={styles.legendContainer}>
					<Svg width={width * 0.5} height={100}>
						<VictoryLegend
							titleComponent={<VictoryLabel dy={10} style={{ fontSize: 10 }} />}
							style={{
								labels: {
									fontSize: 12,
									fontFamily: 'Raleway',
									strokeWidth: 10,
									fill: theme.darkFont
								},
								tickLabels: {}
							}}
							standalone={false}
							orientation="horizontal"
							width={width * 0.5}
							height={50}
							colorScale={[theme.graphCarb, theme.graphFat, theme.graphProtein]}
							data={[
								{
									name: carbLabel,
									symbol: { type: 'square' }
								},
								{
									name: fatLabel,
									symbol: { type: 'square' }
								},
								{
									name: proteinLabel,
									symbol: { type: 'square' }
								}
							]}
						/>
					</Svg>
				</View>
			</View>
		);
	}
};
const styles = StyleSheet.create({
	chartContainer: {
		borderTopWidth: 0,
		justifyContent: 'center',
		alignItems: 'center'
	},
	weightErrorText: {
		fontSize: 14
	},
	chartErrorContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		marginVertical: 50,
		paddingHorizontal: 50
	},

	//Weight Line
	weightNetContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 5
	},
	weightNetText: {
		fontSize: 10
	}
});

/*
						{goalWeight && (
						<VictoryLine
							style={{
								data: { stroke: theme.blue, strokeWidth: 2 },
								labels: { fontSize: 9, fontFamily: 'Raleway' }
							}}
							data={[
								{ x: 0, y: goalWeight },
								{ x: data.length, y: goalWeight }
							]}
						/>
					)}*/
