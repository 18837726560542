//@flow
import {
	UPDATE_LOG_DATE,
	CHANGE_LOG_VIEW,
	SET_COPY_TIME,
	SET_COPY_DATE,
	SET_COPY_ITEMS,
	SET_EDIT_VALUES,
	TOGGLE_LOG_MODAL,
	SET_MEAL_TIME
} from './types';
import moment from 'moment';

export const updateDate = (newDate) => {
	return {
		type: UPDATE_LOG_DATE,
		payload: newDate
	};
};

//Goes back one day from the Active Date of the Log ( "<" Arrow )
export const backOneDay = (activeDate) => (dispatch) => {
	const previousDay = moment(activeDate).add(-1, 'day').format('YYYY-MM-DD');
	return dispatch(updateDate(previousDay));
};

//Goes forward one day from the Active Date of the Log ( ">" Arrow )
export const forwardOneDay = (activeDate) => (dispatch) => {
	const nextDay = moment(activeDate).add(1, 'day').format('YYYY-MM-DD');

	return dispatch(updateDate(nextDay));
};

//Changes the "View" of the Log Home (Macro Grid, Bar Graph, or Pie Chart tab)
export const changeTab = (newView) => {
	return {
		type: CHANGE_LOG_VIEW,
		payload: newView
	};
};

//Toggles the Modals in the Log PAge
export const toggleModal = (modalName, status) => {
	const key = modalName;
	return {
		type: TOGGLE_LOG_MODAL,
		payload: { [key]: status }
	};
};

//Sets the Copy Date of the Copy Meal feature
export const setCopyDate = (date) => {
	return {
		type: SET_COPY_DATE,
		payload: date
	};
};

//Sets the Copy Meal Time of the Copy Meal feature
export const setCopyTime = (newTime) => {
	return {
		type: SET_COPY_TIME,
		payload: newTime
	};
};

export const setMealTime = (mealTime) => {
	return {
		type: SET_MEAL_TIME,
		payload: mealTime
	};
};

//Sets the items to be copied by the Copy Meal feature
export const setCopyItems = (items) => {
	const backwardsCompatPre7_4 = [...items];

	for (const x of backwardsCompatPre7_4) {
		if (typeof x.totalcarbs === 'undefined') {
			x.totalcarbs = x.carbs;
		}
		if (typeof x.fiber === 'undefined') {
			x.fiber = 0;
		}
	}

	return {
		type: SET_COPY_ITEMS,
		payload: backwardsCompatPre7_4
	};
};

//Sets the EditingItemModal default values for when you click on a log entry and it doesn't have an id/was a quick macro type.
export const setEditValues = (editValues, mealTime) => {
	return {
		type: SET_EDIT_VALUES,
		payload: editValues,
		mealTime
	};
};
