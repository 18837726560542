//@flow
import React from 'react';
import { View, TextInput, Picker, StyleSheet } from 'react-native';
import { Colors } from 'Theme';
import { useSelector, useDispatch } from 'react-redux';
import {
	servingInputQtyChange,
	servingInputLabelChange
} from 'Components/fatsecret/actions';

type Props = {
	theme: Object
};

export const FsServingSelectorTextInput = (props: Props) => {
	const dispatch = useDispatch();
	const { theme } = props;
	const reducerData = useSelector((state) => state.fatSecretSingleFood);
	const { finalServingLabels, lastValueConfirmed } = reducerData;

	return (
		<View
			style={[
				styles.servingSelectContainer,
				{ borderBottomColor: theme.border }
			]}>
			<View style={styles.inputServingHolder}>
				<TextInput
					style={[
						styles.inputServingTextInputWeb,
						{ color: theme.themeAccent }
					]}
					value={lastValueConfirmed[0].toString()}
					keyboardType={'numeric'}
					onChangeText={(value) =>
						dispatch(servingInputQtyChange(value.replace(',', '.')))
					}
				/>
				<View
					style={[
						styles.inputQtyPicker,
						{ borderLeftColor: theme.border, marginTop: 1 }
					]}>
					<Picker
						style={[
							styles.webDropdown,
							{
								color: theme.themeAccent,
								backgroundColor: theme.baseBG,
								borderWidth: 0
							}
						]}
						mode="dropdown"
						selectedValue={lastValueConfirmed[2]}
						itemStyle={{
							textAlign: 'center',
							alignItems: 'center',
							fontFamily: 'Comfortaa'
						}}
						onValueChange={(itemValue) =>
							dispatch(servingInputLabelChange(itemValue))
						}>
						{finalServingLabels.map((item) => {
							const label = item;
							return <Picker.Item key={label} label={label} value={label} />;
						})}
					</Picker>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	//ServingSelector
	servingSelectContainer: {
		flex: 1,
		flexDirection: 'column',
		borderBottomWidth: 3
	},
	inputServingHolder: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center'
	},
	inputServingTextInputWeb: {
		flex: 1,
		padding: 10,
		textAlign: 'center',
		fontSize: 25
	},
	inputQtyPicker: {
		flex: 2,
		borderRadius: 0,
		borderLeftWidth: 1
	},
	webDropdown: {
		height: 60
	}
});
