//@flow
import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Colors } from 'Theme';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text } from './';
import Elevations from 'react-native-elevation';

export const ListHeader = (props) => {
	return (
		<View
			style={[
				styles.container,
				props.noMargin ? { marginTop: 0 } : { marginTop: 25 },
				props.style
			]}>
			{props.leftIconName && (
				<Icon
					name={props.leftIconName}
					style={styles.headerIcon}
					size={14}
					color={props.leftIconColor ? props.leftIconColor : '#333'}
				/>
			)}
			{props.leftText && <Text style={styles.leftTitle}>{props.leftText}</Text>}
			{props.onPress && (
				<TouchableOpacity style={styles.btn} onPress={() => props.onPress()}>
					<Text style={styles.rightTitle}>{props.rightText}</Text>
					<Icon name={props.rightIconName} size={14} color={props.rightIconColor ? props.rightIconColor : '#333'} />
				</TouchableOpacity>
			)}
		</View>
	);
};

var styles = StyleSheet.create({
	container: {
		height: 50,
		flexDirection: 'row',
		alignItems: 'center',
		...Elevations[2]
	},
	title: {
		flex: 1,
		alignSelf: 'center',
		paddingVertical: 10,
		paddingRight: -20,
		paddingLeft: 15
	},
	leftTitle: {
		flex: 1,
		paddingVertical: 10,
		paddingLeft: 15
	},
	rightTitle: {
		paddingRight: 10
	},
	btn: {
		justifyContent: 'flex-end',
		alignItems: 'center',
		flexDirection: 'row',
		padding: 10
	},
	headerIcon: {
		flex: 1,
		alignSelf: 'center',
		paddingVertical: 10,
		paddingRight: -20,
		paddingLeft: 15
	}
});
