//@flow
import React from "react";
import { ScrollView, Linking, View } from "react-native";
import { Paragraph, Heading, BlockQuote, Divider, Text } from "../common";
import styles from "./Styles/Styles";
import { i18n } from "../../constants";

export const Benefits = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<View>
				<Divider />
				<Paragraph>{i18n.t("benefitsp0")}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>{i18n.t("benefitsh1")}</Heading>
				<Paragraph>{i18n.t("benefitsp1")}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>{i18n.t("benefitsh2")}</Heading>
				<Paragraph>{i18n.t("benefitsp2")}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>{i18n.t("benefitsh3")}</Heading>
				<Paragraph>{i18n.t("benefitsp3")}</Paragraph>
				<Divider />
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>{i18n.t("benefitsh4")}</Heading>
				<Paragraph>{i18n.t("benefitsp4")}</Paragraph>
				<Divider />
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>{i18n.t("benefitsh5")}</Heading>
				<Paragraph>{i18n.t("benefitsp5")}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }} style={{ paddingTop: 0, alignItems: "center" }}>
					<Text style={styles.linkText} onPress={() => Linking.openURL("https://www.hsph.harvard.edu/nutritionsource/healthy-weight/diet-reviews/mediterranean-diet/")}>
						{"\n"}Source
					</Text>
					<Text style={styles.linkText} onPress={() => Linking.openURL("https://pubmed.ncbi.nlm.nih.gov/16512956/")}>
						{"\n"}Source 2
					</Text>
					<Text
						style={styles.linkText}
						onPress={() => Linking.openURL(" https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/mediterranean-diet/art-20047801")}
					>
						{"\n"}Source 3
					</Text>
				</BlockQuote>
			</View>
		</ScrollView>
	);
};
