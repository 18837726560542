import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'Components/common';

export const SideMenuHeader = ({ title, bgColor }) => {
	return (
		<View style={[styles.suggestedCollapse, { backgroundColor: bgColor }]}>
			<Text style={styles.suggestedLabel}>{title}</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	suggestedCollapse: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center'
	},
	suggestedLabel: {
		paddingTop: 1,
		paddingBottom: 1,
		paddingLeft: 10,
		color: '#333',
		flex: 1
	}
});
