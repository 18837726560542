import {
	REQUEST_FATSECRET_FOOD,
	RECEIVE_FATSECRET_FOOD,
	SERVING_WHEEL_CHANGE_FS,
	SERVING_WHEEL_CANCEL_FS,
	SERVING_WHEEL_CONFIRM_FS,
	SERVING_INPUT_QTY_CHANGE_FS,
	SERVING_INPUT_LABEL_CHANGE_FS,
	SWITCH_SERVING_SELECTOR_FS,
	PRELOAD_SERVING_WHEEL
} from '../actions/types';
import {
	calculateMultiplier,
	calculateMacros,
	calculatePlaceholder
} from 'Utilities/ServingSelectorLogic';

const INITIAL_STATE = {
	isFetching: false,
	foodData: [],
	placeholder: [],
	lastValueConfirmed: [1, '-', 'g'],
	CurrentServingData: {
		fat: 0,
		carb: 0,
		netcarb: 0,
		protein: 0,
		calorie: 0,
		fiber: 0,
		totalUnits: 'g',
		totalWeightG: 0,
		nutrientsV2: {}
	},
	servingSelectType: 'wheel'
};

let currentServing = {};
let placeholder = '';
let lastValue = [];
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REQUEST_FATSECRET_FOOD:
			return { ...state, isFetching: true };
		case RECEIVE_FATSECRET_FOOD:
			return {
				...state,
				isFetching: false,
				foodData: action.payload,
				placeholder: action.placeholder,
				CurrentServingData: action.CurrentServingData,
				finalServingLabels: action.servingLabels,
				lastValueConfirmed: action.lastValueConfirmed,
				servingSelectType: 'wheel'
			};
		case SERVING_WHEEL_CHANGE_FS:
			currentServing = calculateMacros(
				state.foodData,
				action.currentWheelValue[2],
				action.payload
			);

			return {
				...state,
				CurrentServingData: currentServing,
				placeholder: action.placeholder
			};
		case SERVING_WHEEL_CANCEL_FS:
			currentServing = calculateMacros(
				state.foodData,
				state.lastValueConfirmed[2],
				action.payload
			);
			return {
				...state,
				CurrentServingData: currentServing,
				placeholder: action.placeholder
			};
		case SERVING_WHEEL_CONFIRM_FS:
			return {
				...state,
				lastValueConfirmed: action.confirmedValue
			};
		case SERVING_INPUT_QTY_CHANGE_FS:
			currentServing = calculateMacros(
				state.foodData,
				state.lastValueConfirmed[2],
				action.payload
			);
			placeholder = calculatePlaceholder(
				[
					isNaN(action.payload) || action.payload < 0 ? 0 : action.payload,
					'-',
					state.lastValueConfirmed[2]
				],
				false
			);
			return {
				...state,
				CurrentServingData: currentServing,
				lastValueConfirmed: [action.payload, '-', state.lastValueConfirmed[2]],
				placeholder
			};
		case SERVING_INPUT_LABEL_CHANGE_FS:
			currentServing = calculateMacros(
				state.foodData,
				action.payload,
				state.lastValueConfirmed[0]
			);
			placeholder = calculatePlaceholder(
				[state.lastValueConfirmed[0], '-', action.payload],
				false
			);
			return {
				...state,
				CurrentServingData: currentServing,
				lastValueConfirmed: [state.lastValueConfirmed[0], '-', action.payload],
				placeholder
			};
		case SWITCH_SERVING_SELECTOR_FS:
			if (action.payload === 'wheel') {
				placeholder = calculatePlaceholder(
					[state.lastValueConfirmed[0], '-', state.lastValueConfirmed[2]],
					false
				);
				lastValue = state.lastValueConfirmed;
			} else {
				lastValue = [
					calculateMultiplier(state.lastValueConfirmed),
					'-',
					state.lastValueConfirmed[2]
				];
				placeholder = calculatePlaceholder(
					[
						calculateMultiplier(state.lastValueConfirmed),
						'-',
						state.lastValueConfirmed[2]
					],
					false
				);
			}
			return {
				...state,
				servingSelectType: action.payload,
				lastValueConfirmed: lastValue,
				placeholder
			};
		case PRELOAD_SERVING_WHEEL:
			placeholder = calculatePlaceholder(action.arr, false);
			return {
				...state,
				CurrentServingData: action.arr,
				lastValueConfirmed: action.arr,
				placeholder: action.placeholder
			};
		default:
			return state;
	}
};
