//@flow
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { listenToFriendsSingleMeal } from 'Redux/actions';
import { copyFriendItem } from 'Components/tracker/social/actions/FirebaseActionsFriends';
import {
	Loader,
	ErrorText,
	Button,
	Header,
	MacroGrid,
	Ingredients
} from 'Components/common';
import styles from './Styles/Styles';
import { friendServingDataMeal } from './selectors';
import { i18n } from 'Theme';

export const FriendsSingleMealScreen = ({ navigation, route }) => {
	const dispatch = useDispatch();
	const [isLoading, toggleLoading] = useState(true);
	const [viewMoreIngredients, toggleIngredients] = useState(true);
	const { carbUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);
	const { theme } = useSelector((state) => state.startup);
	const {
		singleSavedMeal: { items }
	} = useSelector((state) => state.friendsListener);
	const servingData = useSelector((state) => friendServingDataMeal(state));
	useEffect(() => {
		//Fetch Single Food Data From FireBase User Custom Foods On Load
		dispatch(
			listenToFriendsSingleMeal(route.params.friendUID, route.params.foodID)
		);
	}, []);

	useEffect(() => {
		//Fetch Single Food Data From FireBase User Custom Foods On Load
		if (typeof items !== 'undefined') {
			toggleLoading(false);
		}
	}, [items]);

	const formatIngredients = (ingredients) => {
		if (typeof ingredients === 'undefined' || ingredients === '') {
			return [];
		}
		const newIngre = ingredients.map((item) => {
			return { text: item.name, key: item.random };
		});
		return newIngre;
	};

	if (!items) {
		return (
			<Loader
				full={true}
				loaderBG={theme.baseBG}
				loaderColor={theme.darkFont}
			/>
		);
	}

	if (items && !isLoading) {
		return (
			<ScrollView
				style={{ flex: 1, backgroundColor: theme.baseBG }}
				keyboardShouldPersistTaps="handled">
				<Header title={items.name} bgColor={theme.base} />
				<MacroGrid {...servingData.servingMacros} carbUnits={carbUnits} theme={theme} />
				<Ingredients
					ingredients={formatIngredients(items.foods)}
					viewMore={viewMoreIngredients}
					toggle={() => toggleIngredients(!viewMoreIngredients)}
					theme={theme}
				/>
				<View style={styles.buttonRow}>
					<Button
						style={[styles.buttonStyle, {backgroundColor: theme.themeAccent}]}
						onPress={() =>
							dispatch(copyFriendItem(items, () => navigation.goBack()))
						}>
						{i18n.t('copyPlus')}
					</Button>
				</View>
				{isLoading && (
					<Loader
						full={true}
						loaderBG={theme.baseBG}
						loaderColor={theme.darkFont}
					/>
				)}
			</ScrollView>
		);
	}

	return <ErrorText showError={!items}>{i18n.t('foodNotFound')}</ErrorText>;
};
