//@flow
import { createSelector } from 'reselect';
import { servingUpdate } from 'Utilities/MacrosUtil';
import { calculateMultiplier } from 'Utilities/ServingSelectorLogic';
import { i18n } from 'Theme';

const defaultData = {
	servingMacros: {
		fat: 0,
		carb: 0,
		netcarb: 0,
		protein: 0,
		calorie: 0,
		fiber: 0,
		totalUnits: 'g',
		totalWeightG: 0,
		nutrientsV2: []
	}
};

const getFriendFood = (state) => state.friendsListener.singleCustomFood.items;
const getFriendMeal = (state) => state.friendsListener.singleSavedMeal.items;

export const friendServingDataFood = createSelector(
	getFriendFood,
	(foodData) => {
		switch (typeof foodData) {
			case 'undefined':
				return { ...defaultData };
			case 'object':
				return {
					servingMacros: {
						...servingUpdate(
							foodData,
							calculateMultiplier([1, '-', i18n.t('servings')])
						)
					}
				};
			default:
				return { ...defaultData };
		}
	}
);

export const friendServingDataMeal = createSelector(
	getFriendMeal,
	(foodData) => {
		switch (typeof foodData) {
			case 'undefined':
				return { ...defaultData };
			case 'object':
				return {
					servingMacros: {
						...servingUpdate(
							foodData,
							calculateMultiplier([1, '-', i18n.t('servings')])
						)
					}
				};
			default:
				return { ...defaultData };
		}
	}
);
