//@flow
import React, { useState, useEffect } from 'react';
import {
	ScrollView,
	View,
	TouchableWithoutFeedback,
	TouchableOpacity,
	FlatList
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
	sendFriendRequest,
	unsendFriendRequest,
	acceptFriendRequest,
	declineFriendRequest
} from './actions/FirebaseActionsFriends';
import { FontAwesome } from '@expo/vector-icons';
import styles from './Styles/Styles';
import { Loader, Text, Prompt, ErrorText } from 'Components/common';
import { SwipeRow } from 'react-native-swipe-list-view';
import _ from 'lodash';
import { i18n } from 'Theme';

export const PendingScreen = ({ navigation, route }) => {
	const dispatch = useDispatch();
	const [friendRequestPopUp, toggleFriendRequestPopUp] = useState(false);
	const { loading } = useSelector((state) => state.ui);
	const { isAnonymous } = useSelector((state) => state.firebase.auth);
	const {
		profile: { friends }
	} = useSelector((state) => state.firebase);
	const { theme } = useSelector((state) => state.startup);
	useEffect(() => {
		if (!isAnonymous) {
			navigation.setParams({
				handleFriendRequest: () => toggleFriendRequestPopUp(true)
			});
		}
	}, []);

	const searchFriend = (email) => {
		dispatch(
			sendFriendRequest(email, () => toggleFriendRequestPopUp(false), friends)
		);
	};

	const renderSent = ({ item }) => {
		return (
			<SwipeRow
				swipeKey={`${item.id}`}
				style={{ backgroundColor: theme.border }}
				disableRightSwipe={true}
				swipeToOpenPercent={1}
				rightOpenValue={-100}
				closeOnRowPress={true}
				friction={10}
				tension={10}>
				<View style={styles.hiddenCtn}>
					<TouchableOpacity
						style={[styles.deleteBtn, { backgroundColor: theme.red }]}
						onPress={() => dispatch(unsendFriendRequest(item.id))}>
						<FontAwesome
							style={{ alignSelf: 'center' }}
							name="times"
							size={14}
							color={theme.white}
						/>
					</TouchableOpacity>
				</View>
				<View
					style={[
						styles.rowContainer,
						{ backgroundColor: theme.baseBG, borderColor: theme.border }
					]}>
					<View style={styles.textHolder}>
						<View style={styles.textContainer}>
							<View>
								<Text style={styles.itemTitle}>{item.email}</Text>
							</View>
						</View>
					</View>
				</View>
			</SwipeRow>
		);
	};

	const renderReceieved = ({ item }) => {
		return (
			<SwipeRow
				swipeKey={`${item.id}`}
				style={{ backgroundColor: theme.baseBG }}
				disableRightSwipe={true}
				swipeToOpenPercent={1}
				rightOpenValue={-200}
				closeOnRowPress={true}
				friction={10}
				tension={10}>
				<View style={styles.hiddenCtn}>
					<TouchableOpacity
						style={[styles.acceptBtn, { backgroundColor: theme.green }]}
						onPress={() => dispatch(acceptFriendRequest(item))}>
						<FontAwesome
							style={{ alignSelf: 'center' }}
							name="check"
							size={14}
							color={theme.white}
						/>
					</TouchableOpacity>
					<TouchableOpacity
						style={[styles.deleteBtn, { backgroundColor: theme.red }]}
						onPress={() => dispatch(declineFriendRequest(item))}>
						<FontAwesome
							style={{ alignSelf: 'center' }}
							name="times"
							size={14}
							color={theme.white}
						/>
					</TouchableOpacity>
				</View>
				<View
					style={[
						styles.rowContainer,
						{ backgroundColor: theme.baseBG, borderColor: theme.border }
					]}>
					<View style={styles.textHolder}>
						<View style={styles.textContainer}>
							<View>
								<Text style={styles.itemTitle}>{item.email}</Text>
							</View>
						</View>
					</View>
				</View>
			</SwipeRow>
		);
	};

	const pendingArr =
		typeof friends !== 'undefined' ? _.values(friends.pending) : [];
	const sentArr = typeof friends !== 'undefined' ? _.values(friends.sent) : [];

	if (isAnonymous) {
		return (
			<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<ErrorText showError={true}>{i18n.t('friendNotAnon')}</ErrorText>
			</View>
		);
	}

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<ScrollView keyboardShouldPersistTaps="handled">
				<View style={styles.foodListStyle}>
					<View
						style={[
							styles.pendingTitleContainer,
							styles.borderTopNo,
							{ backgroundColor: theme.grey, borderColor: theme.border }
						]}>
						<Text style={styles.pendingTitle}>{i18n.t('received')}</Text>
						<FontAwesome
							style={styles.headerIcon}
							name="inbox"
							size={14}
							color={theme.darkFont}
						/>
					</View>
					<FlatList
						data={pendingArr}
						keyExtractor={(item) => item.id}
						renderItem={renderReceieved}
						keyboardShouldPersistTaps="handled"
					/>
				</View>
				<View style={styles.foodListStyle}>
					<View
						style={[
							styles.pendingTitleContainer,
							{ backgroundColor: theme.grey, borderColor: theme.border }
						]}>
						<Text style={styles.pendingTitle}>{i18n.t('sent')}</Text>
						<FontAwesome
							style={styles.headerIcon}
							name="share"
							size={14}
							color={theme.darkFont}
						/>
					</View>
					<FlatList
						data={sentArr}
						keyExtractor={(item) => item.id}
						renderItem={renderSent}
						keyboardShouldPersistTaps="handled"
					/>
				</View>
				<Prompt
					title={i18n.t('friendRequest')}
					placeholder={i18n.t('email')}
					defaultValue=""
					visible={friendRequestPopUp}
					onCancel={() => toggleFriendRequestPopUp(false)}
					onSubmit={(value) => searchFriend(value)}
					onClosed={() => toggleFriendRequestPopUp(false)}
					submitText={i18n.t('send')}
					theme={theme}
				/>
			</ScrollView>
			{loading && (
				<Loader
					full={true}
					loaderBG={theme.baseBG}
					loaderColor={theme.darkFont}
				/>
			)}
		</View>
	);
};
