//@flow
import React from 'react';
import {
	View,
	Picker as PickerA,
	Platform,
	TouchableOpacity,
	StyleSheet
} from 'react-native';
import { Text } from 'Components/common';
import Picker from 'react-native-picker';
import { i18n } from 'Theme';
import { useDispatch } from 'react-redux';
import { SimpleLineIcons } from '@expo/vector-icons';

//Food Filters
const FILTER_ITEMS = [
	{ label: i18n.t('oneWeek') },
	{ label: i18n.t('oneMonth') },
	{ label: i18n.t('threeMonths') },
	{ label: i18n.t('sixMonths') },
	{ label: i18n.t('oneYear') },
	{ label: i18n.t('allTime') },
	{ label: i18n.t('custom') }
];

const IOS_FILTER_ITEMS = [
	i18n.t('oneWeek'),
	i18n.t('oneMonth'),
	i18n.t('threeMonths'),
	i18n.t('sixMonths'),
	i18n.t('oneYear'),
	i18n.t('allTime'),
	i18n.t('custom')
];

type Props = {
	progressFilter: string,
	setProgressFilter: Function,
	style?: Object,
	theme: Object
};

export const ProgressFilter = (props: Props) => {
	const { progressFilter, setProgressFilter, style, theme } = props;
	const dispatch = useDispatch();
	const showPicker = () => {
		Picker.init({
			pickerTitleText: '',
			pickerConfirmBtnText: i18n.t('confirm'),
			pickerCancelBtnText: i18n.t('cancel'),
			pickerTextEllipsisLen: 12,
			pickerConfirmBtnColor: [255, 255, 255, 1],
			pickerCancelBtnColor: [255, 255, 255, 1],
			pickerTitleColor: [255, 255, 255, 1],
			pickerBg: theme.pickerBase,
			pickerToolBarBg: theme.pickerBg,
			pickerFontColor: theme.pickerBg,
			pickerFontFamily: 'Comfortaa',
			pickerFontSize: 20,
			selectedValue: [IOS_FILTER_ITEMS[progressFilter]],
			pickerData: IOS_FILTER_ITEMS,
			onPickerConfirm: (data, pickedIndex) => {
				dispatch(setProgressFilter(pickedIndex[0]));
			},
			onPickerCancel: () => {},
			onPickerSelect: () => {}
		});
		Picker.show();
	};

	return (
		<View style={[styles.filter, style, { backgroundColor: theme.grey }]}>
			{Platform.OS !== 'ios' ? (
				<PickerA
					style={[
						styles.androidWebPicker,
						{
							borderBottomColor: theme.border,
							backgroundColor: theme.grey,
							color: theme.darkFont
						}
					]}
					mode="dropdown"
					selectedValue={progressFilter}
					itemStyle={{
						textAlign: 'center',
						alignItems: 'center'
					}}
					onValueChange={(itemValue, index) =>
						dispatch(setProgressFilter(index))
					}>
					{FILTER_ITEMS.map((item, index) => {
						const label = item.label;
						return <PickerA.Item key={label} label={label} value={index} />;
					})}
				</PickerA>
			) : (
				<TouchableOpacity style={styles.iosFilter} onPress={() => showPicker()}>
					<Text style={styles.iosFilterText}>
						{IOS_FILTER_ITEMS[progressFilter]}
					</Text>
					<SimpleLineIcons
						name={'arrow-down'}
						size={14}
						color={theme.darkFont}
						style={styles.statsIcon}
					/>
				</TouchableOpacity>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	filter: {
		paddingLeft: Platform.OS === 'android' ? 15 : 0,
		marginTop: Platform.OS !== 'ios' ? 0 : 1
	},
	androidWebPicker: {
		padding: 15,
		borderWidth: 0,
		borderBottomWidth: 1
	},
	iosFilter: {
		flex: 1,
		paddingHorizontal: 15,
		paddingVertical: 10,
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	iosFilterText: {},
	statsIcon: {
		paddingLeft: 15,
		alignSelf: 'center'
	}
});
