import { createSelector } from 'reselect';
import { servingUpdate } from 'Utilities/MacrosUtil';
import {
	calculateMultiplier,
	calculatePlaceholder
} from 'Utilities/ServingSelectorLogic';
import { i18n } from 'Theme';
import { defaultNutrients } from 'Redux/selectors';

const defaultData = {
	servingMacros: {
		fat: 0,
		carb: 0,
		netcarb: 0,
		protein: 0,
		calorie: 0,
		fiber: 0,
		totalUnits: 'g',
		totalWeightG: 0,
		nutrientsV2: { ...defaultNutrients }
	},
	placeholder: `1 x ${i18n.t('servings')}`
};

//Single Foods
const getFood = (state, route) =>
	state.firebase.profile.customfoodsv3[route.params.foodID];
const getFoodQty = (state) => state.singleItem.food.activeServing;

export const servingFoodSelector = createSelector(
	[getFood, getFoodQty],
	(foodData, servingData) => {
		switch (typeof foodData) {
			case 'undefined':
				return { ...defaultData };
			case 'object':
				return {
					servingMacros: {
						...servingUpdate(foodData, calculateMultiplier(servingData))
					},
					placeholder: calculatePlaceholder(servingData)
				};
			default:
				return { ...defaultData };
		}
	}
);

//Single Meals
const getMeal = (state, route) =>
	state.firebase.profile.savedmealsv3[route.params.foodID];
const getMealQty = (state) => state.singleItem.meal.activeServing;
const getCloneQty = (state) => state.singleItem.mealClone.activeServing;

export const servingMealSelector = createSelector(
	[getMeal, getMealQty],
	(foodData, servingData) => {
		switch (typeof foodData) {
			case 'undefined':
				return { ...defaultData };
			case 'object':
				return {
					servingMacros: {
						...servingUpdate(foodData, calculateMultiplier(servingData))
					},
					placeholder: calculatePlaceholder(
						servingData,
						foodData.customServingName
					)
				};
			default:
				return { ...defaultData };
		}
	}
);

export const servingMealCloneSelector = createSelector(
	[getMeal, getCloneQty],
	(foodData, servingData) => {
		switch (typeof foodData) {
			case 'undefined':
				return { ...defaultData };
			case 'object':
				return {
					servingMacros: {
						...servingUpdate(foodData, calculateMultiplier(servingData))
					},
					placeholder: calculatePlaceholder(
						servingData,
						foodData.customServingName
					)
				};
			default:
				return { ...defaultData };
		}
	}
);

export const ingredientsFormatSelector = createSelector(
	getMeal,
	(ingredients) => {
		if (
			typeof ingredients === 'undefined' ||
			!Array.isArray(ingredients.foods)
		) {
			return [];
		}
		return ingredients.foods.map((item) => {
			return { key: item.random, text: item.name };
		});
	}
);

//Editing Single Meal
const getEditingMeal = (state, route) =>
	state.firebase.profile.savedmealsv3[route.params.item.id];
const getEditingIngredients = (state) =>
	state.singleItemMealEdit.editingIngredientArr;
const getEditQty = (state) => state.singleItem.editMeal.activeServing;

export const servingEditingSelector = createSelector(
	[getEditingMeal, getEditingIngredients, getEditQty],
	(foodData, editingArr, servingData) => {
		switch (typeof foodData) {
			case 'undefined':
				return { ...defaultData };
			case 'object':
				return {
					servingMacros: {
						...servingUpdate(
							{ ...foodData, foods: editingArr },
							calculateMultiplier(servingData),
							true
						)
					},
					placeholder: calculatePlaceholder(servingData)
				};
			default:
				return { ...defaultData };
		}
	}
);
