//FitBit
export const FITBIT_AUTH = 'FITBIT_AUTH';
export const FITBIT_LOGOUT = 'FITBIT_LOGOUT';
export const FITBIT_SYNCED = 'FITBIT_SYNCED';

//Apple HealthKit
export const AUTH_APPLE_HK = 'AUTH_APPLE_HK';
export const AHK_SYNCED = 'AHK_SYNCED';

//Google Fit
export const GFIT_SYNCED = 'GFIT_SYNCED';
