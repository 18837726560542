//@flow
import React, { useState, useEffect } from 'react';
import { ScrollView, View, TouchableOpacity, FlatList } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { unFriend, sendFriendRequest } from './actions/FirebaseActionsFriends';
import styles from './Styles/Styles';
import { Loader, ErrorText, Text, Prompt } from 'Components/common';
import { SwipeRow } from 'react-native-swipe-list-view';
import _ from 'lodash';
import { i18n } from 'Theme';

export const FriendsScreen = ({ navigation, route }) => {
	const dispatch = useDispatch();
	const [friendRequestPopUp, toggleFriendRequestPopUp] = useState(false);
	const {
		profile: { friends }
	} = useSelector((state) => state.firebase);
	const { isAnonymous } = useSelector((state) => state.firebase.auth);
	const { loading } = useSelector((state) => state.ui);
	const { theme } = useSelector((state) => state.startup);
	useEffect(() => {
		if (!isAnonymous) {
			navigation.setParams({
				handleFriendRequest: () => toggleFriendRequestPopUp(true)
			});
		}
	}, []);

	const searchFriend = (email) => {
		dispatch(sendFriendRequest(email, () => toggleFriendRequestPopUp(false)));
	};

	const renderItem = ({ item }) => {
		return (
			<SwipeRow
				swipeKey={`${item.id}`}
				style={{ backgroundColor: theme.border }}
				disableRightSwipe={true}
				swipeToOpenPercent={1}
				rightOpenValue={-100}
				closeOnRowPress={true}
				friction={10}
				tension={10}>
				<View style={styles.hiddenCtn}>
					<TouchableOpacity
						style={[styles.deleteBtn, { backgroundColor: theme.red }]}
						onPress={() => dispatch(unFriend(item))}>
						<Text style={styles.swipeButtonText}>{i18n.t('delete')}</Text>
					</TouchableOpacity>
				</View>
				<View
					style={[
						styles.rowContainer,
						{ backgroundColor: theme.baseBG, borderColor: theme.border }
					]}>
					<View style={styles.textHolder}>
						<View style={styles.textContainer}>
							<TouchableOpacity
								onPress={() =>
									navigation.navigate('FriendsSingleScreen', {
										friendUID: item.id,
										title: item.email
									})
								}>
								<Text style={styles.itemTitle}>{item.email}</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</SwipeRow>
		);
	};

	const acceptedArr =
		typeof friends !== 'undefined' ? _.values(friends.accepted) : [];

	if (isAnonymous) {
		return (
			<View style={{ flex: 1, backgroundColor: '#FFF' }}>
				<ErrorText showError={true}>{i18n.t('friendNotAnon')}</ErrorText>
			</View>
		);
	}

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<ScrollView keyboardShouldPersistTaps="handled">
				{acceptedArr.length === 0 && (
					<ErrorText showError={true}>{i18n.t('tryAddFriend')}</ErrorText>
				)}

				{acceptedArr.length > 0 && (
					<View style={styles.foodListStyle}>
						<FlatList
							data={acceptedArr}
							keyExtractor={(item) => item.id}
							renderItem={renderItem}
							keyboardShouldPersistTaps="handled"
						/>
					</View>
				)}
				<Prompt
					title={i18n.t('friendRequest')}
					placeholder={i18n.t('email')}
					defaultValue=""
					visible={friendRequestPopUp}
					onCancel={() => toggleFriendRequestPopUp(false)}
					onSubmit={(value) => searchFriend(value)}
					onClosed={() => toggleFriendRequestPopUp(false)}
					submitText={i18n.t('send')}
					theme={theme}
				/>
			</ScrollView>
			{loading && (
				<Loader
					full={true}
					loaderBG={theme.baseBG}
					loaderColor={theme.darkFont}
				/>
			)}
		</View>
	);
};
