//@flow
import React from 'react';
import { useDispatch } from 'react-redux';
import {
	View,
	Picker as PickerA,
	Platform,
	TouchableOpacity,
	StyleSheet
} from 'react-native';
import { Text } from 'Components/common';
import Picker from 'react-native-picker';
import { Colors, i18n } from 'Theme';
import { SimpleLineIcons } from '@expo/vector-icons';

type Props = {
	activeReviewLabel: string,
	activeReviewIndex: number,
	changeReviewName: Function,
	addEntry: Function,
	filterItems: Array<string>,
	theme: Object
};

export const ActiveItemFilter = ({
	activeReviewIndex,
	activeReviewLabel,
	changeReviewName,
	addEntry,
	filterItems,
	theme
}: Props) => {
	const dispatch = useDispatch();

	//Time Filters
	const FILTER_ITEMS = filterItems.map((item) => {
		return { label: item };
	});

	const IOS_FILTER_ITEMS = filterItems;

	const showPicker = () => {
		Picker.init({
			pickerTitleText: '',
			pickerConfirmBtnText: i18n.t('confirm'),
			pickerCancelBtnText: i18n.t('cancel'),
			pickerTextEllipsisLen: 12,
			pickerConfirmBtnColor: [255, 255, 255, 1],
			pickerCancelBtnColor: [255, 255, 255, 1],
			pickerTitleColor: [255, 255, 255, 1],
			pickerBg: theme.pickerBase,
			pickerToolBarBg: theme.pickerBg,
			pickerFontColor: theme.pickerBg,
			pickerToolBarFontSize: 11,
			pickerFontSize: 20,
			pickerRowHeight: 28,
			pickerFontFamily: 'Comfortaa',
			selectedValue: [IOS_FILTER_ITEMS[activeReviewIndex]],
			pickerData: IOS_FILTER_ITEMS,
			onPickerConfirm: (data, pickedIndex) => {
				dispatch(changeReviewName(data[0], pickedIndex[0]));
			},
			onPickerCancel: () => {},
			onPickerSelect: () => {}
		});
		Picker.show();
	};

	return (
		<View
			style={[
				styles.container,
				{ backgroundColor: theme.grey, borderBottomColor: theme.border }
			]}>
			<View style={styles.filter}>
				{Platform.OS !== 'ios' ? (
					<PickerA
						style={[
							styles.androidWebPicker,
							{
								borderBottomColor: theme.border,
								backgroundColor: theme.grey,
								color: theme.darkFont
							}
						]}
						mode="dropdown"
						selectedValue={activeReviewLabel}
						itemStyle={{
							textAlign: 'center',
							alignItems: 'center'
						}}
						onValueChange={(itemValue, index) =>
							dispatch(changeReviewName(itemValue, index))
						}>
						{FILTER_ITEMS.map((item, index) => {
							const label = item.label;
							return (
								<PickerA.Item
									key={label}
									label={label}
									value={label}
									index={index}
								/>
							);
						})}
					</PickerA>
				) : (
					<TouchableOpacity
						style={styles.iosFilter}
						onPress={() => showPicker()}>
						<Text style={styles.iosFilterText}>
							{IOS_FILTER_ITEMS[activeReviewIndex]}
						</Text>
						<SimpleLineIcons
							name={'arrow-down'}
							size={14}
							color={theme.darkFont}
							style={styles.statsIcon}
						/>
					</TouchableOpacity>
				)}
			</View>
			<TouchableOpacity style={[styles.addBtn, {borderLeftColor: theme.border}]} onPress={() => addEntry()}>
				<SimpleLineIcons
					name={'plus'}
					size={20}
					color={theme.teal}
					style={styles.addIcon}
				/>
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		paddingLeft: Platform.OS === 'android' ? 15 : 0,
		borderTopWidth: 0,
		marginTop: 1,
		borderBottomWidth: 1
	},
	filter: {
		flex: 4
	},
	androidWebPicker: {
		padding: 15,
		borderWidth: 0
	},
	iosFilter: {
		padding: 15,
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	iosFilterText: {
	},
	statsIcon: {
		paddingLeft: 15,
		alignSelf: 'center'
	},
	addBtn: {
		flex: 1,
		padding: 15,
		borderLeftWidth: 1,
	},
	addIcon: {
		alignSelf: 'center'
	}
});
