//@flow
import React, { useState } from "react";
import { ScrollView, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Communications from "react-native-communications";
import DeviceInfo from "react-native-device-info";
import { i18n } from "Theme";
import { logOut, updateGoalWeight } from "./actions";
import { updateLocalLanguage, toggleDarkMode } from "Redux/actions";
import { WebCompatAlert, RowTextInput, OptionSwitch } from "Components/common";
import { ModalPrompt } from "Components/modal/ModalPrompt";
import { deleteAccount } from "Components/tracker/login/actions/FirebaseActionsAuth";
import { SignUpForm } from "Components/tracker/login/components";
import { HeaderTitle, Account, SettingsLink, ProfileHeader } from "./components";
import { GoalsUpdaterManual } from "./GoalsUpdaterManual";
import * as RNIap from "react-native-iap";
import { savePurchaseData } from "Components/purchase/actions/PurchaseActions";
import { validateNumber, returnCorrectUnitsValue } from "Utilities";

const feedbackSubject = Platform.OS === "ios" ? `MyMedi Feedback - IOS - ${DeviceInfo.getVersion()}` : `MyMedi Feedback - Android - ${DeviceInfo.getVersion()}`;

export const SettingsScreen = ({ route, navigation }) => {
	const dispatch = useDispatch();
	const settings = useSelector((state) => state.firebase.profile.settings);
	const { goalWeight } = useSelector((state) => state.firebase.profile.profilePage);
	const { language, darkMode, theme } = useSelector((state) => state.startup);
	const auth = useSelector((state) => state.firebase.auth);
	const [isManual, toggleManual] = useState(false);
	const [isAnonModal, toggleAnonModal] = useState(false);
	const [goalWeightPrompt, toggleGoalWeight] = useState(false);

	const Modal = Platform.select({
		ios: () => require("react-native-modal"),
		web: () => require("modal-enhanced-react-native-web"),
		android: () => require("react-native-modal"),
	})();

	const onFeedback = () => {
		Communications.email(["prestigeworldwide.app@gmail.com"], null, null, feedbackSubject, "");
	};

	const languageOptions = [
		{ label: i18n.t("english"), value: "en" },
		{ label: i18n.t("spanish"), value: "es" },
		{ label: i18n.t("portuguese"), value: "pt" },
		{ label: i18n.t("french"), value: "fr" },
		{ label: i18n.t("german"), value: "de" },
	];

	const onClosed = () => {
		toggleManual(false);
	};

	const restoreAdFree = async () => {
		const trackerSku = "com.prestigeworldwide.medidiet.premium";
		try {
			await RNIap.initConnection();
			const purchases = await RNIap.getAvailablePurchases();
			if (purchases.length === 0) {
				WebCompatAlert(i18n.t("noPurchaseFound"), i18n.t("noPurchaseFoundDesc"));
			}

			purchases.forEach((purchase) => {
				if (purchase.productId === trackerSku) {
					dispatch(savePurchaseData(purchase));
					RNIap.endConnection();
				}
			});
		} catch (error) {
			RNIap.endConnection();
			WebCompatAlert(i18n.t("error"), error.message ? error.message : i18n.t("error"));
		}
	};

	const submitGoalWeight = (value) => {
		if (!validateNumber(value)) {
			return WebCompatAlert(i18n.t("error"), i18n.t("enterNumber"));
		}
		dispatch(updateGoalWeight(value));
		toggleGoalWeight(false);
	};

	const realGoalWeight = returnCorrectUnitsValue("weight", settings.units.bodymeasurementsUnits, goalWeight).toFixed(0);

	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<ProfileHeader settings={settings} theme={theme} />
			<RowTextInput
				onPress={() => toggleGoalWeight(true)}
				text={i18n.t("goalWeight")}
				units={settings.units.bodymeasurementsUnits === "imperial" ? " lbs" : " kg"}
				value={realGoalWeight}
				theme={theme}
			/>
			<SettingsLink
				noBorderTop={false}
				onPress={() => navigation.navigate("Calculator", { fromSettings: true })}
				text={i18n.t("recalculateMacroGoals")}
				iconColor={theme.darkFont}
				borderColor={theme.border}
			/>
			<SettingsLink onPress={() => toggleManual(true)} text={i18n.t("manuallyChangeMacroGoals")} iconColor={theme.darkFont} borderColor={theme.border} />
			{/*<SettingsLink
				onPress={() => navigation.navigate('CustomDateGoalsScreen')}
				text="Overwrite Goals For Certain Dates"
			/>*/}
			{/*<SettingsLink onPress={() => props.navigation.navigate("Goals", { id: "Auto Goals Mode" })} text="Auto Updating Goals Mode" /> */}
			<HeaderTitle borderColor={theme.border} bgColor={theme.grey}>
				{i18n.t("tracking")}
			</HeaderTitle>
			<SettingsLink onPress={() => navigation.navigate("PreferencesScreen")} text={i18n.t("changePreferences")} iconColor={theme.darkFont} borderColor={theme.border} />
			{/*<OptionSelector
				onChange={(value) => dispatch(updateLocalLanguage(value))}
				currentValue={language}
				placeholder={i18n.t('language')}
				data={languageOptions}
				noIcon={true}
				containerStyle={styles.dropdownContainer}
			/>*/}
			<HeaderTitle borderColor={theme.border} bgColor={theme.grey}>
				{i18n.t("darkMode")}
			</HeaderTitle>
			<OptionSwitch switchValue={darkMode} onSwitch={(value) => dispatch(toggleDarkMode(value))} text={i18n.t("darkMode")} theme={theme} />
			<Account
				logOut={() => dispatch(logOut())}
				onFeedback={onFeedback}
				auth={auth}
				onAnon={() => toggleAnonModal(true)}
				deleteAccount={() => dispatch(deleteAccount())}
				restoreAdFree={restoreAdFree}
				theme={theme}
			/>
			<Modal.ReactNativeModal
				isVisible={isManual}
				onSwipeComplete={() => onClosed()}
				style={{
					padding: 0,
					margin: 0,
				}}
				animationIn={"slideInLeft"}
				animationOut={"slideOutLeft"}
			>
				<GoalsUpdaterManual onClosed={onClosed} theme={theme} />
			</Modal.ReactNativeModal>
			<Modal.ReactNativeModal
				isVisible={isAnonModal}
				onSwipeComplete={() => toggleAnonModal(false)}
				style={{
					padding: 0,
					margin: 0,
				}}
				animationIn={"slideInUp"}
				animationOut={"slideOutDown"}
			>
				<SignUpForm onClosed={() => toggleAnonModal(false)} isAnonymous={true} />
			</Modal.ReactNativeModal>
			<ModalPrompt
				onClose={() => toggleGoalWeight(false)}
				saveEdit={(value) => submitGoalWeight(value)}
				onClosed={() => toggleGoalWeight(false)}
				submitText={i18n.t("confirm")}
				isModalVisible={goalWeightPrompt}
				value={realGoalWeight}
				title={i18n.t("goalWeight")}
				theme={theme}
				textLabel={settings.units.bodymeasurementsUnits === "imperial" ? "lbs" : "kg"}
			/>
		</ScrollView>
	);
};
