//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Text } from 'Components/common';
let height = 60;

type Props = {
	title: string,
	onPress: Function,
	style?: Object,
	textStyle?: Object
};

export const HeaderTextButton = (props: Props) => {
	const { onPress, title, style, textStyle } = props;

	return (
		<TouchableOpacity style={[styles.container, style]} onPress={onPress}>
			<Text style={[styles.text, textStyle]}>{title}</Text>
		</TouchableOpacity>
	);
};

var styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
		height: height,
		paddingHorizontal: 5
	},
	text: {
		color: '#FFF',
		fontSize: 12
	}
});
