//@flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { View, Platform } from 'react-native';
import { MealBox } from './MealBox';
import { setEditValues, toggleModal, setMealTime } from '../actions';
import { getTrackerData, getDatesTotals } from '../selectors';
import { editItemPreloadServing } from 'Redux/actions';
import { ahk_updateMacros } from 'Components/tracker/fitnesstrackers/utilities/AppleHealthKit';
import { fitbit_saveMacros } from 'Components/tracker/fitnesstrackers/utilities/Fitbit';
import { i18n } from 'Theme';

export const MealBoxContainer = () => {
	const navigation = useNavigation();
	const dispatch = useDispatch();
	const { date } = useSelector((state) => state.log);
	const { customfoodsv3, savedmealsv3 } = useSelector(
		(state) => state.firebase.profile
	);
	const {
		auth: fitbitAuth,
		shouldSync: fitbit_shouldSync,
		syncMeal: fitbit_mealToSync
	} = useSelector((state) => state.fitnessTracker.fitbit);
	const { ahkWasAuth, shouldSync: ahk_shouldSync } = useSelector(
		(state) => state.fitnessTracker.appleHealthKit
	);

	const data = useSelector((state) => getTrackerData(state));
	const macroTotalsObject = useSelector((state) => getDatesTotals(state));

	useEffect(() => {
		//is IOS and is previously Apple HK authed
		if (Platform.OS === 'ios') {
			if (ahkWasAuth && ahk_shouldSync === true) {
				dispatch(ahk_updateMacros(date, macroTotalsObject.macrosEaten));
			}
		}
	}, [ahk_shouldSync, macroTotalsObject]);

	useEffect(() => {
		//For Fitbit android/web users and non ahk ios users
		if (fitbitAuth.isAuth && fitbit_shouldSync === true) {
			dispatch(
				fitbit_saveMacros(
					fitbitAuth.access_token,
					date,
					macroTotalsObject[fitbit_mealToSync],
					fitbit_mealToSync,
					typeof data.fitbit[fitbit_mealToSync] === 'undefined'
						? false
						: data.fitbit[fitbit_mealToSync].toString()
				)
			);
		}
	}, [fitbit_shouldSync, macroTotalsObject]);

	const onAddLogPress = (mealTime) => {
		dispatch(setMealTime(mealTime));
		return navigation.navigate('AddFood');
	};

	const editEntry = (logItem, logDate, mealTime) => {
		const navObject = {
			foodID: logItem.id,
			tracker: false,
			editingMeal: false,
			editingIngredient: false,
			editingLogEntry: true,
			singleApiCall: true, //for recipes
			random: logItem.random,
			itemName: logItem.name,
			logDate,
			mealTime
		};

		const id = logItem.id;
		dispatch(setEditValues(logItem, mealTime));
		switch (logItem.type) {
			case 'api':
				navigation.navigate('LogEntry', {
					screen: 'EditApi',
					params: navObject
				});
				break;
			case 'food':
				if (typeof customfoodsv3[id] === 'undefined') {
					return dispatch(toggleModal('editLogItemModal', true));
				}
				dispatch(editItemPreloadServing('food', logItem));
				navigation.navigate('LogEntry', {
					screen: 'EditFood',
					params: navObject
				});
				break;
			case 'meal':
				if (typeof savedmealsv3[id] === 'undefined') {
					return dispatch(toggleModal('editLogItemModal', true));
				}
				dispatch(editItemPreloadServing('meal', logItem));
				navigation.navigate('LogEntry', {
					screen: 'EditMeal',
					params: navObject
				});
				break;
			case 'recipe':
				dispatch(editItemPreloadServing('recipe', logItem));
				navigation.navigate('LogEntry', {
					screen: 'EditRecipe',
					params: navObject
				});
				dispatch(toggleModal('editLogItemModal', true));
				break;
			case 'macro':
				dispatch(toggleModal('editLogItemModal', true));
				break;
			default:
				dispatch(toggleModal('editLogItemModal', true));
				break;
		}
	};

	return (
		<View>
			<MealBox
				mealName={i18n.t('breakfast')}
				foods={data.breakfast}
				onAddPress={() => onAddLogPress('breakfast')}
				editEntry={editEntry}
			/>
			<MealBox
				mealName={i18n.t('lunch')}
				foods={data.lunch}
				onAddPress={() => onAddLogPress('lunch')}
				editEntry={editEntry}
			/>
			<MealBox
				mealName={i18n.t('dinner')}
				foods={data.dinner}
				onAddPress={() => onAddLogPress('dinner')}
				editEntry={editEntry}
			/>
			<MealBox
				mealName={i18n.t('snacks')}
				foods={data.snacks}
				onAddPress={() => onAddLogPress('snacks')}
				editEntry={editEntry}
			/>
		</View>
	);
};
