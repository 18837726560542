//@flow
import React, { useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
	PageLanguage,
	PageProfile,
	PageProfile2,
	PagePreferredCarb,
	PageMacroGoals,
	PageCheckboxes,
	PageSubmit
} from './components';
import { i18n } from 'Theme';
import { finishOnboard } from 'Components/tracker/login/actions/FirebaseActionsAuth';
import { toggleModal } from 'Components/tracker/log/actions';

export const OnboardModal = () => {
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.firebase.auth);
	const { theme, language } = useSelector((state) => state.startup);
	const [page, changePage] = useState(0);
	const [checkboxOptions, changeOptions] = useState([
		{ name: i18n.t('weightLoss'), id: 0, checked: true },
		{ name: i18n.t('diseasePrevention'), id: 1, checked: false },
		{ name: i18n.t('lowerBloodPressure'), id: 2, checked: false },
		{ name: i18n.t('improvedCholesterolProfile'), id: 3, checked: false },
		{ name: i18n.t('heartHealth'), id: 4, checked: false },
		{ name: i18n.t('insulinManagement'), id: 5, checked: false },
		{ name: i18n.t('antiCancer'), id: 6, checked: false },
		{ name: i18n.t('improvedSkin'), id: 7, checked: false },
		{ name: i18n.t('dentalHealth'), id: 8, checked: false }
	]);

	const nextPage = () => {
		changePage(page + 1);
	};

	const previousPage = () => {
		changePage(page - 1);
	};

	const onFinalSubmit = (checked) => {
		if (auth.isAnonymous) {
			dispatch(finishOnboard(false, language, checkboxOptions));
			dispatch(toggleModal('onboard', false));
		} else {
			dispatch(finishOnboard(checked, language, checkboxOptions));
			dispatch(toggleModal('onboard', false));
		}
	};

	return (
		<View
			style={{ flex: 1, backgroundColor: theme.baseBG, alignItems: 'center' }}>
			{/*page === 0 && (
				<PageLanguage page={page} next={nextPage} prev={previousPage} />
			)*/}
			{page === 0 && (
				<PageProfile
					page={page}
					next={nextPage}
					prev={previousPage}
					theme={theme}
				/>
			)}
			{page === 1 && (
				<PageProfile2
					page={page}
					next={nextPage}
					prev={previousPage}
					theme={theme}
				/>
			)}
			{page === 2 && (
				<PageMacroGoals
					page={page}
					next={nextPage}
					prev={previousPage}
					finalSubmit={false}
					theme={theme}
				/>
			)}
			{page === 3 && (
				<PageCheckboxes
					page={page}
					next={nextPage}
					prev={previousPage}
					changeOptions={changeOptions}
					checkboxOptions={checkboxOptions}
					theme={theme}
				/>
			)}
			{page === 4 && (
				<PageSubmit
					page={page}
					next={onFinalSubmit}
					prev={previousPage}
					auth={auth}
					theme={theme}
				/>
			)}
		</View>
	);
};
