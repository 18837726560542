//@flow
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { Text } from 'Components/common';
import { i18n } from 'Theme';
import { useNavigation } from '@react-navigation/native';

type Props = {
	route: string,
	food: Object,
	border: string,
	themeColor: string
};

export const FsSearchResults = (props: Props) => {
	const navigation = useNavigation();
	const { food } = props;

	const determineRoute = (currentRoute) => {
		let nextRoute = '';
		if (currentRoute === 'SearchScreen') {
			// Add Food Stack -> Search Tab -> Single Search
			nextRoute = 'SearchSingleScreen';
		} else if (currentRoute === 'MealEditingSearch') {
			// Add Food Stack -> Meal Tab -> Editing Meal -> Single Search
			nextRoute = 'MealEditingSingleSearch';
		} else if (currentRoute === 'ManageMealEditingSearch') {
			//Manage -> Meal Tab -> Editing Meal -> Search Single
			nextRoute = 'ManageMealEditingSingleSearch';
		} else {
			//Net Carb Look-Up Search Single
			nextRoute = 'NetCarbSearchSingle';
		}
		return nextRoute;
	};

	return (
		<TouchableOpacity
			style={[styles.rowContainer, { borderBottomColor: props.border }]}
			onPress={() =>
				navigation.navigate(determineRoute(props.route), {
					foodID: food.food_id
				})
			}>
			<View>
				<Text style={[styles.foodTitle, { color: props.themeColor }]}>
					{food.food_name}
				</Text>
				<View>
					{food.brand_name && (
						<Text style={styles.brandTitle}>{food.brand_name}</Text>
					)}
					{!food.brand_name && (
						<Text style={styles.brandTitle}>{i18n.t('generic')}</Text>
					)}
				</View>
				<View>
					<Text style={styles.foodDescription}> {food.food_description}</Text>
				</View>
			</View>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	rowContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottomWidth: 1,
		paddingLeft: 15,
		paddingRight: 15,
		paddingTop: 12,
		paddingBottom: 17
	},
	foodTitle: {
		fontSize: 14,
		lineHeight: 17
	},
	foodDescription: {
		fontSize: 11,
		marginLeft: -2,
		marginBottom: 0,
		lineHeight: 15
	},
	brandTitle: {
		fontSize: 10,
		lineHeight: 14,
		paddingVertical: 5
	}
});
