// @flow

//Returns Weight in correct units as a string with or without unit labels
export const returnWeightString = (
	weightKg: string,
	isMetric: boolean,
	units: boolean
) => {
	let weight = '';
	if (isMetric === true) {
		weight = +weightKg;
	} else {
		weight = +weightKg / 0.453592;
	}
	return units
		? `${+weight.toFixed(1)} ${isMetric ? 'kg' : 'lb'}`
		: `${+weight.toFixed(1)}`;
};

export const returnWeightStringNull = (
	weightKg: string,
	isMetric: boolean,
	units: boolean
) => {
	if (weightKg === '') {
		return null;
	}
	let weight = '';
	if (isMetric === true) {
		weight = +weightKg;
	} else {
		weight = +weightKg / 0.453592;
	}
	return units
		? `${+weight.toFixed(1)} ${isMetric ? 'kg' : 'lb'}`
		: `${+weight.toFixed(1)}`;
};

//Returns weight in correct units as a number without labels
export const returnWeightNumber = (weight: string, isMetric: boolean) => {
	let weights = '';
	if (isMetric === true) {
		weights = +weight;
	} else {
		weights = +weight / 0.453592;
	}

	return weights;
};

//Returns weight in kg number, in order to be saved (always saved as kg)
export const returnWeightKg = (weight: string, isMetric: boolean) => {
	let weightKg = '';
	if (isMetric === true) {
		weightKg = +weight;
	} else {
		weightKg = +weight * 0.453592;
	}
	return weightKg;
};
