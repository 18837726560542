import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FlatList } from 'react-native';
import { MultiSelectRow } from './';

export class MultiSelect extends Component {
	constructor(props) {
		super(props);

		this.renderRow = this.renderRow.bind(this);
		this.selectRow = this.selectRow.bind(this);

		this.state = {
			selectedRows: []
		};
	}

	selectRow(row) {
		const { selectedRows } = this.state;
		const indexToRemove = selectedRows.findIndex((i) => i.name === row.name);
		this.setState(
			(() => {
				if (indexToRemove !== -1) {
					return {
						selectedRows: [
							...selectedRows.slice(0, indexToRemove),
							...selectedRows.slice(indexToRemove + 1)
						]
					};
				}
				return {
					selectedRows: [...selectedRows].concat(row)
				};
			})(),
			() => this.props.onSelectionChange(row, this.state.selectedRows)
		);
	}

	pluckByName(inArr, name) {
		let i;
		for (i = 0; i < inArr.length; i++) {
			if (inArr[i].name === name) {
				return true;
			}
		}
		return false;
	}

	renderRow(row) {
		if (!row.item) {
			return false;
		}
		const { renderRow: _renderRow } = this.props;
		const { selectedRows } = this.state;
		const isSelected = this.pluckByName(selectedRows, row.item.name);
		return (
			<MultiSelectRow
				row={row.item}
				isSelected={isSelected}
				renderRow={_renderRow}
				selectRow={this.selectRow}
			/>
		);
	}

	render() {
		const { options, style } = this.props;
		return (
			<FlatList data={options} renderItem={this.renderRow} style={style} />
		);
	}
}

MultiSelect.propTypes = {
	options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	renderRow: PropTypes.func.isRequired,
	onSelectionChange: PropTypes.func
};
