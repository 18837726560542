//@flow
import Toast from 'react-native-root-toast';
import { randomString } from 'Utilities';
import * as NavigationService from 'Navigation/NavigationService';
import {
	COPY_MEAL_EDITING_INGREDIENTS,
	DELETE_MEAL_EDIT_INGREDIENT,
	EDITING_MEAL_UPDATE_INGREDIENT,
	EDITING_MEAL_ADD_INGREDIENT,
	SET_EDIT_INGREDIENT_OBJECT
} from './types';
import { i18n } from 'Theme';

//=================================================CustomMealTabSingleEdit.js============================================

/*---------------------------All Ingredients-----------------------------------------*/
//On Editing Change, Copies all Ingredients into Temp Object
export const copyIngredientsToEdit = (mealIngredients) => {
	return {
		type: COPY_MEAL_EDITING_INGREDIENTS,
		payload: mealIngredients
	};
};

//Deletes Ingredient from Copied Editing List
export const removeIngredient = (itemKey, ingredientsArr) => {
	const newArray = ingredientsArr.filter((obj) => {
		return obj.random !== itemKey;
	});

	return {
		type: DELETE_MEAL_EDIT_INGREDIENT,
		payload: newArray
	};
};

//Final Function to Update Ingredient for All Types
export const updateIngredient = (ingredientArr) => {
	return {
		type: EDITING_MEAL_UPDATE_INGREDIENT,
		payload: ingredientArr
	};
};

/*--------------------------Quick Macros Ingredients --------------------------------*/
export const addMacrosToMeal = (values) => {
	let ingredientArr = [];
	const uniqid = randomString(14);

	ingredientArr.push({
		name: values.name,
		calories: values.calories,
		fats: values.fats,
		proteins: values.proteins,
		carbs: values.carbs,
		totalcarbs: values.totalcarbs,
		fiber: values.fiber,
		manualCalories: values.manualCalories,
		nutrientsV2: values.nutrientsV2,
		random: uniqid,
		type: 'macro'
	});

	// Add a Toast on screen.
	Toast.show(i18n.t('macrosAddedToMeal'), {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
	return {
		type: EDITING_MEAL_ADD_INGREDIENT,
		payload: ingredientArr
	};
};

//Sets Temp Obj for Editing A Quick Macros Type ==> Modal Editing
export const setEditIngredientValue = (ingredientObject, callback) => (
	dispatch
) => {
	dispatch({
		type: SET_EDIT_INGREDIENT_OBJECT,
		payload: ingredientObject
	});
	return callback();
};

export const updateQuickMacroIngredient = (
	values,
	originalItem,
	closeModalCallback
) => (dispatch, getState) => {
	const { editingIngredientArr } = getState().singleItemMealEdit;

	const updatedIngredient = [
		{
			name: values.name,
			calories: values.calories,
			fats: values.fats,
			proteins: values.proteins,
			carbs: values.carbs,
			totalcarbs: values.totalcarbs,
			fiber: values.fiber,
			manualCalories: values.manualCalories,
			nutrientsV2: values.nutrientsV2,
			random: originalItem.random,
			type: 'macro'
		}
	];

	const updatedArr = editingIngredientArr.map(
		(obj) => updatedIngredient.find((o) => o.random === obj.random) || obj
	);

	// Add a Toast on screen.
	Toast.show(`${values.name} ${i18n.t('ingredientUpdated')}`, {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});

	dispatch(updateIngredient(updatedArr));
	return closeModalCallback();
};

/*--------------------------Custom Food Ingredients --------------------------------*/
export const addUserIngredient = (food, quickAdd) => {
	let ingredientArr = [];
	const uniqid = randomString(14);
	ingredientArr.push({
		name: food.servingAmount,
		calories: +food.calories,
		fats: +food.fats,
		proteins: +food.proteins,
		carbs: +food.carbs,
		totalcarbs: +food.totalcarbs,
		fiber: +food.fiber,
		nutrientsV2: food.nutrientsV2,
		random: uniqid,
		id: food.id,
		type: food.type
	});

	// Add a Toast on screen.
	Toast.show(`${food.servingAmount} ${i18n.t('addedToMeal')}`, {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});

	!quickAdd && NavigationService.goBack();

	return {
		type: EDITING_MEAL_ADD_INGREDIENT,
		payload: ingredientArr
	};
};

export const updateFoodIngredientEditingMeal = (food, ingredientRandom) => (
	dispatch,
	getState
) => {
	const { editingIngredientArr } = getState().singleItemMealEdit;
	const updatedIngredient = [
		{
			name: food.servingAmount,
			calories: +food.calories,
			fats: +food.fats,
			proteins: +food.proteins,
			carbs: +food.carbs,
			totalcarbs: +food.totalcarbs,
			fiber: +food.fiber,
			nutrientsV2: food.nutrientsV2,
			random: ingredientRandom,
			id: food.id,
			type: food.type
		}
	];

	const updatedArr = editingIngredientArr.map(
		(obj) => updatedIngredient.find((o) => o.random === obj.random) || obj
	);

	// Add a Toast on screen.
	Toast.show(`${food.servingAmount} ${i18n.t('ingredientUpdated')}`, {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
	dispatch(updateIngredient(updatedArr));
	return NavigationService.goBack();
};

/*--------------------------API Search Ingredients ---------------------------------*/

//Adds Searched Individual to the Current Meal Being Edited, used on FsSingleTrackerEditingMealButtons.js
export const addSearchIngredient = (
	pushedItemData,
	pushedPlaceholder,
	pushedId
) => {
	let ingredientArr = [];
	const uniqid = randomString(14);
	ingredientArr.push({
		name: pushedPlaceholder,
		calories: pushedItemData.calories,
		fats: pushedItemData.fats,
		proteins: pushedItemData.proteins,
		carbs: pushedItemData.carbs,
		totalcarbs: pushedItemData.totalcarbs,
		fiber: pushedItemData.fiber,
		nutrientsV2: pushedItemData.nutrientsV2,
		random: uniqid,
		id: pushedId,
		type: 'api'
	});

	// Add a Toast on screen.
	Toast.show(`${pushedPlaceholder} ${i18n.t('addedToMeal')}`, {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});

	NavigationService.navigate('CustomMealSingleEdit');

	return {
		type: EDITING_MEAL_ADD_INGREDIENT,
		payload: ingredientArr
	};
};

export const updateIngredientEditingMeal = (
	pushedItemData,
	pushedPlaceholder,
	pushedId,
	ingredientRandom
) => (dispatch, getState) => {
	const { editingIngredientArr } = getState().singleItemMealEdit;
	const updatedIngredient = [
		{
			name: pushedPlaceholder,
			calories: pushedItemData.calories,
			fats: pushedItemData.fats,
			proteins: pushedItemData.proteins,
			carbs: pushedItemData.carbs,
			totalcarbs: pushedItemData.totalcarbs,
			fiber: pushedItemData.fiber,
			nutrientsV2: pushedItemData.nutrientsV2,
			random: ingredientRandom,
			id: pushedId,
			type: 'api'
		}
	];

	const updatedArr = editingIngredientArr.map(
		(obj) => updatedIngredient.find((o) => o.random === obj.random) || obj
	);

	// Add a Toast on screen.
	Toast.show(i18n.t('ingredientUpdated'), {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
	dispatch(updateIngredient(updatedArr));
	return NavigationService.goBack();
};
