import { GENERIC_LOADING_TOGGLE } from "./types";

export function toggleModal(modalType) {
	return {
		type: "SHOW_MODAL",
		modalType
	};
}

export function hideModal(modalType) {
	return {
		type: "HIDE_MODAL",
		modalType
	};
}

export function toggleLoading(boolean) {
	return {
		type: GENERIC_LOADING_TOGGLE,
		payload: boolean
	};
}
