//@flow
import React from 'react';
import { StyleSheet } from 'react-native';
import { Text } from 'Components/common'

type Props = {
	customText?: string,
	style?: Object
};

export const Gram = (props: Props) => {
	return (
		<Text style={[styles.learnText, props.style]}>
			{props.customText ? props.customText : 'g'}
		</Text>
	);
};

var styles = StyleSheet.create({
	learnText: {
		fontFamily: 'Raleway',
		fontSize: 10
	}
});
