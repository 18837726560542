//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'Components/common';
import { i18n } from 'Theme';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useDispatch } from 'react-redux';

type Props = {
	progressFromDate: string,
	progressToDate: string,
	setToDate: Function,
	setFromDate: Function,
	theme: Object
};
export const ProgressDatePicker = (props: Props) => {
	const {
		progressFromDate,
		progressToDate,
		setToDate,
		setFromDate,
		theme
	} = props;
	const dispatch = useDispatch();
	return (
		<View
			style={[
				styles.customDateContainer,
				{ backgroundColor: theme.baseBG, borderColor: theme.border }
			]}>
			<DatePicker
				onChange={(x) => dispatch(setFromDate(moment(x).format('YYYY-MM-DD')))}
				selected={moment(progressFromDate, 'YYYY-MM-DD').toDate()}
			/>
			<Text> {i18n.t('to')} </Text>
			<DatePicker
				onChange={(x) => dispatch(setToDate(moment(x).format('YYYY-MM-DD')))}
				selected={moment(progressToDate, 'YYYY-MM-DD').toDate()}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	customDateContainer: {
		zIndex: 9999,
		borderWidth: 1,
		padding: 15,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
});
