//@flow
import React from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	Platform,
	Picker
} from 'react-native';
import { Text, DropdownButton } from 'Components/common';
import { i18n } from 'Theme';
import { useDispatch, useSelector } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';
import { updateRecipeFilter } from './actions';
import ModalSelector from 'react-native-modal-selector';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

export const FilterModal = ({ isOpen, onClose }) => {
	const { mealType, cuisineType } = useSelector(
		(state) => state.edamamRecipeApi.filter
	);
	const { theme } = useSelector((state) => state.startup);
	const dispatch = useDispatch();
	const closeMe = () => onClose(false);
	const MEAL_TIME_CATEGORIES = [
		{ key: 'All', label: i18n.t('all') },
		{ key: 'breakfast', label: i18n.t('breakfast') },
		{ key: 'Lunch', label: i18n.t('lunch') },
		{ key: 'Dinner', label: i18n.t('dinner') },
		{ key: 'Snack', label: i18n.t('snacks') }
	];

	const CUISINE_TYPE_CATEGORIES = [
		{ key: 'All', label: i18n.t('all') },
		{ key: 'American', label: i18n.t('american') },
		{ key: 'Asian', label: i18n.t('asian') },
		{ key: 'British', label: i18n.t('british') },
		{ key: 'Caribbean', label: i18n.t('caribbean') },
		{ key: 'Central Europe', label: i18n.t('centralEurope') },
		{ key: 'Chinese', label: i18n.t('chinese') },
		{ key: 'Eastern Europe', label: i18n.t('easternEurope') },
		{ key: 'French', label: i18n.t('french') },
		{ key: 'Indian', label: i18n.t('indian') },
		{ key: 'Italian', label: i18n.t('italian') },
		{ key: 'Japanese', label: i18n.t('japanese') },
		{ key: 'Kosher', label: i18n.t('kosher') },
		{ key: 'Mediterranean', label: i18n.t('mediterranean') },
		{ key: 'Mexican', label: i18n.t('mexican') },
		{ key: 'Middle Eastern', label: i18n.t('middleEastern') },
		{ key: 'Nordic', label: i18n.t('nordic') },
		{ key: 'South American', label: i18n.t('southAmerican') },
		{ key: 'South East Asian', label: i18n.t('southEastAsian') }
	];

	const renderSelect = (cat) => {
		return Platform.OS === 'web' ? (
			<View style={styles.webPicker}>
				<Text style={styles.label}>{i18n.t('mealType')}:</Text>
				<Picker
					selectedValue={cat.key}
					onValueChange={(itemValue, itemIndex) => {
						dispatch(
							updateRecipeFilter(MEAL_TIME_CATEGORIES[itemIndex]),
							cuisineType
						);
					}}>
					{MEAL_TIME_CATEGORIES.map((item) => (
						<Picker.Item label={item.label} value={item.key} key={item.key} />
					))}
				</Picker>
			</View>
		) : (
			<ModalSelector
				data={MEAL_TIME_CATEGORIES}
				initValue={cat.label}
				onChange={(option) => dispatch(updateRecipeFilter(option, cuisineType))}
				style={{ justifyContent: 'center', paddingHorizontal: 10 }}>
				<View style={styles.mobilePicker}>
					<Text style={styles.label}>{i18n.t('mealType')}:</Text>
					<DropdownButton title={cat.label} />
				</View>
			</ModalSelector>
		);
	};

	const renderCuisineSelect = (cuis) => {
		return Platform.OS === 'web' ? (
			<View style={styles.webPicker}>
				<Text style={styles.label}>{i18n.t('cuisineType')}:</Text>
				<Picker
					selectedValue={cuis.key}
					onValueChange={(itemValue, itemIndex) => {
						dispatch(
							updateRecipeFilter(mealType, CUISINE_TYPE_CATEGORIES[itemIndex])
						);
					}}>
					{CUISINE_TYPE_CATEGORIES.map((item) => (
						<Picker.Item label={item.label} value={item.key} key={item.key} />
					))}
				</Picker>
			</View>
		) : (
			<ModalSelector
				data={CUISINE_TYPE_CATEGORIES}
				initValue={cuis.label}
				onChange={(option) => dispatch(updateRecipeFilter(mealType, option))}
				style={{ justifyContent: 'center', paddingHorizontal: 10 }}>
				<View style={styles.mobilePicker}>
					<Text style={styles.label}>{i18n.t('cuisineType')}:</Text>
					<DropdownButton title={cuis.label} />
				</View>
			</ModalSelector>
		);
	};

	return (
		<Modal.ReactNativeModal
			style={[styles.modal, { backgroundColor: theme.tabBase }]}
			isVisible={isOpen}
			onBackButtonPress={closeMe}
			onBackdropPress={closeMe}
			onSwipeComplete={closeMe}
			swipeDirection={['down']}
			hasBackdrop={true}
			backdropOpacity={0.5}
			animationInTiming={600}
			animationOutTiming={600}
			animationIn={'slideInDown'}
			animationOut={'slideOutDown'}>
			<View style={styles.modalContainer}>
				<View style={styles.modalHeader}>
					<TouchableOpacity
						style={[
							styles.closeContainer,
							{ backgroundColor: theme.themeAccent }
						]}
						onPress={closeMe}>
						<Ionicons
							style={styles.closeIcon}
							name={'md-close'}
							color={theme.darkFont}
							size={24}
						/>
					</TouchableOpacity>
				</View>
				<View style={styles.modalSubContainer}>
					{renderSelect(mealType)}
					{renderCuisineSelect(cuisineType)}
					<View style={[styles.border, { borderColor: theme.border }]} />
				</View>
				<View style={[styles.borderTwo, { borderColor: theme.border }]} />
			</View>
		</Modal.ReactNativeModal>
	);
};

const styles = StyleSheet.create({
	circleGuide: {
		flexDirection: 'row',
		justifyContent: 'center'
	},
	circleCtn: {
		alignItems: 'center',
		paddingVertical: 10,
		marginHorizontal: 20
	},
	sugarLabel: {
		fontSize: 10
	},
	titleCircle: {
		width: 30,
		height: 30,
		borderRadius: 30,
		alignSelf: 'center'
	},
	btn: {
		marginTop: 40,
		padding: 20,
		alignItems: 'center',
		justifyContent: 'flex-end',
		borderWidth: 1
	},
	modal: {
		margin: 10,
		borderRadius: 7
	},
	modalContainer: {
		flex: 1
	},
	modalSubContainer: {
		flex: 1,
		justifyContent: 'center',
		paddingHorizontal: 25
	},
	modalHeader: {
		flexDirection: 'row'
	},
	closeContainer: {
		height: 50,
		width: 50,
		borderRadius: 30,
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 20,
		marginTop: 20
	},
	closeIcon: {},
	border: {
		borderTopWidth: 1,
		width: 50,
		alignSelf: 'center',
		marginBottom: 50
	},
	borderTwo: {
		borderTopWidth: 1,
		width: '95%',
		alignSelf: 'center',
		paddingVertical: 10
	},
	webPicker: {
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '5%'
	},
	mobilePicker: {
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 50
	},
	sensitivityCtn: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
		marginVertical: 15
	},
	switchCtn: {},
	label: {
		textAlign: 'center'
	}
});
