//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type Props = {
	onPress: Function,
	style?: Object,
	iconStyle?: Object,
	size?: number,
	btnArray: Array<Object>
};

export const HeaderIconMultiButtons = (props: Props) => {
	const { btnArray = [] } = props;
	if (btnArray.length < 1) {
		return <View />;
	}

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center'
			}}>
			{btnArray.map((item, index) => {
				const { onPress, name, color, style, size } = item;

				if (!onPress) {
					return;
				}

				return (
					<TouchableOpacity
						key={index}
						style={[styles.container, style]}
						onPress={onPress}>
						<MaterialCommunityIcons
							style={[styles.iconStyle, props.iconStyle]}
							name={name}
							color={color}
							size={size ? size : 24}
						/>
					</TouchableOpacity>
				);
			})}
		</View>
	);
};

var styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
		height: 40,
		width: 40
	}
});
