//@flow
import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { Text, Gram } from 'Components/common';
import { Colors } from 'Theme';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type Props = {
	iconName: string,
	amount: string,
	label: string,
	hrs: boolean,
	style?: Object
};

const { width } = Dimensions.get('window');

export const StatsBox = ({ iconName, amount, label, hrs, style }: Props) => {
	return (
		<View style={[styles.statsBox, style]}>
			<MaterialCommunityIcons
				name={iconName}
				size={24}
				color={Colors.blue}
				style={styles.statsIcon}
			/>
			<View style={styles.label}>
				<Text style={styles.statsAmount}>
					{amount}
					{<Gram />}
				</Text>
			</View>
			<Text style={styles.statsLabel}>{label}</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	statsBox: {
		width: width * 0.5 - 25,
		backgroundColor: '#FFF',
		padding: 15,
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	statsIcon: {},
	statsAmount: {
		fontSize: 24,
		color: Colors.blue,
		paddingTop: 10
	},
	statsLabel: {
		color: Colors.blue,
		fontSize: 12,
		textAlign: 'center'
	}
});
