import React from 'react';
import { View, StyleSheet } from 'react-native';
import Elevations from 'react-native-elevation';

export const Card = (props) => {
	return (
		<View style={[styles.containerStyle, props.style]}>{props.children}</View>
	);
};

var styles = StyleSheet.create({
	containerStyle: {
		borderRadius: 2,
		marginHorizontal: 15,
		marginVertical: 10,
		...Elevations[2]
	}
});
