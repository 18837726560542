//@flow
import moment from 'moment';
import _ from 'lodash';
import { calculateMacroTotals } from 'Utilities';

//Given a Start and Stop Date, function returns every possible date between them in an array
export const getDates = (startDate: String, stopDate: String) => {
	var dateArray = [];
	var currentDate = moment(startDate);
	stopDate = moment(stopDate);
	while (currentDate <= stopDate) {
		dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
		currentDate = moment(currentDate).add(1, 'days');
	}
	return dateArray;
};

//Returns an Array of grams for Carbs/Fats/Proteins from a data array of a time period
export const addChartDataTotals = (timeFrameArray: Array<Object>) => {
	let arrBreakfast = [];
	let arrLunch = [];
	let arrDinner = [];
	let arrSnacks = [];
	//Combine them all into one big array
	let arrTotal = [];

	let finalArr = [];

	for (let x = 0; x < timeFrameArray.length; x++) {
		arrBreakfast = _.values(timeFrameArray[x].breakfast);
		arrLunch = _.values(timeFrameArray[x].lunch);
		arrDinner = _.values(timeFrameArray[x].dinner);
		arrSnacks = _.values(timeFrameArray[x].snacks);
		arrTotal = arrBreakfast.concat(arrLunch, arrDinner, arrSnacks);
		finalArr = finalArr.concat(arrTotal);
	}

	const macroTotalGrams = calculateMacroTotals(finalArr);
	return macroTotalGrams;
};

//Returns an Array of grams for Carbs/Fats/Proteins from a data array of a time period
export const addLineDataTotals = (timeFrameArray: Object) => {
	let arrBreakfast = [];
	let arrLunch = [];
	let arrDinner = [];
	let arrSnacks = [];
	//Combine them all into one big array
	let arrTotal = [];

	let finalArr = [];

	arrBreakfast = _.values(timeFrameArray.breakfast);
	arrLunch = _.values(timeFrameArray.lunch);
	arrDinner = _.values(timeFrameArray.dinner);
	arrSnacks = _.values(timeFrameArray.snacks);
	arrTotal = arrBreakfast.concat(arrLunch, arrDinner, arrSnacks);
	finalArr = finalArr.concat(arrTotal);

	const macroTotalGrams = calculateMacroTotals(finalArr);
	return macroTotalGrams;
};

//Given Array of Carb/Fat/Protein Grams, returns % of calories and calories from those macros
export const calculatePercents = (
	macroTotalGrams: Object,
	carbUnits: string
) => {
	let carbCal =
		carbUnits === 'total'
			? (Math.round(macroTotalGrams.totalcarbs * 100) / 100) * 4
			: (Math.round(macroTotalGrams.carbs * 100) / 100) * 4;
	let fatCal = (Math.round(macroTotalGrams.fats * 100) / 100) * 9;
	let proteinCal = (Math.round(macroTotalGrams.proteins * 100) / 100) * 4;

	const totalpiecount = carbCal + proteinCal + fatCal;
	let carbpercent = (carbCal / totalpiecount) * 100;
	let fatpercent = (fatCal / totalpiecount) * 100;
	let proteinpercent = (proteinCal / totalpiecount) * 100;
	if (totalpiecount === 0) {
		carbpercent = 33.3;
		fatpercent = 33.3;
		proteinpercent = 33.3;
		carbCal = 4;
		fatCal = 9;
		proteinCal = 4;
	}

	return [carbpercent, fatpercent, proteinpercent, carbCal, fatCal, proteinCal];
};

export const returnScatterRange = (units: string, unitBool: boolean) => {
	switch (units) {
		case 'length':
			return [];
		case 'weight':
			return [];
		case 'volume':
			return [];
		case 'percent':
			return [];
		case 'mg':
			return [];
		case 'glucose':
			return unitBool === 'mg/dL' ? [0, 300] : [0, 20];
		case 'ketones':
			return unitBool === 'mg/dL' ? [0, 140] : [0, 7];
		case 'exercise':
			return [];
		case 'bpm':
			return [30, 200];
		case 'mmHg':
			return [40, 200];
		case 'none':
			return [];
		default:
			return [];
	}
};
