//@flow
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, ClickableHeader } from 'Components/common';
import { i18n } from 'Theme';
import Elevations from 'react-native-elevation';
import { defaultNutrientUnits, defaultNutrientDaily } from 'Redux/selectors';

type Props = {
	theme: Object,
	nutrients: Array<Object>,
	withHeader?: boolean
};

export const Nutrients = (props: Props) => {
	const [isVisible, toggleVisible] = useState(false);
	const { nutrients, withHeader = false, theme } = props;
	const renderRow = (key, index) => {
		const dailyPercent = (
			(nutrients[key] / defaultNutrientDaily[key]) *
			100
		).toFixed(0);
		if (index === Object.keys(nutrients).length - 1) {
			return (
				<View key={0}>
					<View
						style={[
							styles.viewMoreRow,
							{
								borderBottomColor: theme.border,
								backgroundColor: theme.nutrientBG
							}
						]}>
						<Text style={styles.nutrientName}>{key}</Text>
						<Text style={styles.nutrientAmount}>
							{nutrients[key]}
							{defaultNutrientUnits[key]} {`(${dailyPercent}%)`}
						</Text>
					</View>
					<View
						style={[
							[
								styles.viewMoreRow,
								{
									borderBottomColor: theme.border,
									backgroundColor: theme.nutrientBG
								}
							],
							styles.borderNone
						]}>
						<Text style={styles.disclaimer}>{i18n.t('basedDiet')}</Text>
					</View>
				</View>
			);
		}

		return (
			<View
				key={key}
				style={[
					styles.viewMoreRow,
					{ borderBottomColor: theme.border, backgroundColor: theme.nutrientBG }
				]}>
				<Text style={styles.nutrientName}>{key}</Text>
				<Text style={styles.nutrientAmount}>
					{nutrients[key]}
					{defaultNutrientUnits[key]} {`(${dailyPercent}%)`}
				</Text>
			</View>
		);
	};

	if (withHeader) {
		return (
			<View>
				<ClickableHeader
					leftText={`${i18n.t('nutrients')}:`}
					noMargin
					onPress={() => toggleVisible(!isVisible)}
					rightIconName={!isVisible ? 'arrow-right' : 'arrow-down'}
					style={{ backgroundColor: theme.base }}
					iconColor={theme.darkFont}
				/>
				{isVisible && (
					<View style={styles.viewMoreContainer}>
						<View style={styles.flatList}>
							{Object.keys(nutrients).map((keyName, i) =>
								renderRow(keyName, i)
							)}
						</View>
					</View>
				)}
			</View>
		);
	}

	return (
		<View style={styles.viewMoreContainer}>
			<View style={styles.flatList}>
				{Object.keys(nutrients).map((keyName, i) => renderRow(keyName, i))}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	viewMoreContainer: {
		flexDirection: 'column',
		padding: 20
	},
	viewMoreRow: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingHorizontal: 15,
		paddingVertical: 10,
		borderBottomWidth: 1
	},
	flatList: {
		...Elevations[2]
	},
	borderNone: {
		borderBottomWidth: 0
	},
	disclaimer: {
		flex: 5,
		fontSize: 12,
		textAlign: 'center'
	},
	nutrientName: {
		flex: 2,
		fontSize: 12,
		alignSelf: 'center'
	},
	nutrientAmount: {
		flex: 1,
		fontSize: 12,
		alignSelf: 'center',
		textAlign: 'left'
	}
});
