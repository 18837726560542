//@flow
import Toast from 'react-native-root-toast';
import { i18n } from 'Theme';
/*-------------------General Favorites-------------*/

//Remove Food Or Meal from Recently Used
export const quickRemoveRecent = (item) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	firebase.remove(`userdata/${uid}/recentlyused/${item.id}`);
	return Toast.show(i18n.t('removedFromRecentlyUsed'), {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
};

export const clearAllRecentlyUsed = () => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	firebase.update(`userdata/${uid}/`, {
		recentlyused: {}
	});
};
