const veg = {
  title: "Vegetables",
  subtitle: "4-8 daily servings, although starchy veggies such as potatoes, corn, peas, and winter squash count towards whole grains instead.",
  data: [
    { title: "Artichokes" },
    { title: "Arugula" },
    { title: "Asparagus" },
    { title: "Avocado" },
    { title: "Beets & Greens" },
    { title: "Bok Choy" },
    { title: "Broccoli" },
    { title: "Brussels Sprouts" },
    { title: "Cabbage" },
    { title: "Carrots" },
    { title: "Cauliflower" },
    { title: "Celery" },
    { title: "Chard" },
    { title: "Collard Greens" },
    { title: "Cucumbers" },
    { title: "Dandelion Greens" },
    { title: "Eggplant" },
    { title: "Endive" },
    { title: "Escarole" },
    { title: "Fennel" },
    { title: "Gourds" },
    { title: "Green Beans" },
    { title: "Green Onions" },
    { title: "Jicama" },
    { title: "Kale" },
    { title: "Lettuce" },
    { title: "Mustard Greens" },
    { title: "Okra" },
    { title: "Olives" },
    { title: "Onions, leeks, shallots" },
    { title: "Palm Hearts" },
    { title: "Parsnips" },
    { title: "Peppers" },
    { title: "Pumpkin" },
    { title: "Radishes" },
    { title: "Rhubarb" },
    { title: "Shallots" },
    { title: "Spinach" },
    { title: "Sweet Potato" },
    { title: "Tomato" },
    { title: "Turnip & Greens" },
    { title: "Water Chestnuts" },
    { title: "Watercress" },
    { title: "Yam" },
    { title: "Zucchini" },
  ],
};

const fruits = {
  title: "Fruits",
  subtitle: "(2-4 daily servings, usually in place of dessert. Whole fruits, avoid juice and added sugar.)",
  data: [
    { title: "Apples" },
    { title: "Apricot" },
    { title: "Avocado" },
    { title: "Banana" },
    { title: "Bilberries" },
    { title: "Blackberries" },
    { title: "Blueberries" },
    { title: "Boysenberries" },
    { title: "Cantaloupe" },
    { title: "Carambolas" },
    { title: "Cherimoyas" },
    { title: "Cherries" },
    { title: "Cranberries" },
    { title: "Currants" },
    { title: "Dates" },
    { title: "Dragon fruit" },
    { title: "Durians" },
    { title: "Elderberries" },
    { title: "Figs" },
    { title: "Goji Berries" },
    { title: "Grapes" },
    { title: "Grapefruit" },
    { title: "Guava" },
    { title: "Honeydew" },
    { title: "Jackfruit" },
    { title: "Kiwi" },
    { title: "Kumquat" },
    { title: "Lemons" },
    { title: "Limes" },
    { title: "Lychee" },
    { title: "Mango" },
    { title: "Melons" },
    { title: "Nectarines" },
    { title: "Oranges" },
    { title: "Papaya" },
    { title: "Passion fruit" },
    { title: "Peach" },
    { title: "Pears" },
    { title: "Persimmons" },
    { title: "Pineapples" },
    { title: "Plantains" },
    { title: "Pomegranate" },
    { title: "Plums" },
    { title: "Prunes" },
    { title: "Quinces" },
    { title: "Raisins" },
    { title: "Raspberries" },
    { title: "Strawberries" },
    { title: "Tangerines" },
    { title: "Watermelon" },
  ],
};
const grains = {
  title: "Whole Grains",
  subtitle: "(4-6 servings per day)",
  data: [
    { title: "Amaranth" },
    { title: "Barley" },
    { title: "Brown Rice" },
    { title: "Buckwheat" },
    { title: "Bulgur" },
    { title: "Corn" },
    { title: "Corn Flour" },
    { title: "Corn Meal" },
    { title: "Durum Wheat" },
    { title: "Einkorn" },
    { title: "Emmer" },
    { title: "Farina" },
    { title: "Faro" },
    { title: "Kamut" },
    { title: "Millet" },
    { title: "Oats" },
    { title: "Peas" },
    { title: "Potato" },
    { title: "Quinoa" },
    { title: "Rye" },
    { title: "Spelt" },
    { title: "Squash" },
    { title: "Tef" },
    { title: "Triticale" },
    { title: "Whole Wheat (Can be bread or crackers, etc. As { title:Wheat Bran" },
    { title: "Wheat Germ" },
    { title: "Whole Wheat Flour" },
  ],
};

const beans = {
  title: "Beans, Legumes, Nuts, Seeds",
  subtitle: "1-3 servings per day combined with Nuts and Seeds",
  data: [
    { title: "Adzuki Beans" },
    { title: "Black Beans" },
    { title: "Black Eyed Peas" },
    { title: "Chickpeas" },
    { title: "Fava Beans" },
    { title: "Kidney Beans" },
    { title: "Lentils" },
    { title: "Lima Beans" },
    { title: "Mung Beans" },
    { title: "Navy Beans" },
    { title: "Pinto Beans" },
    { title: "Soy Beans" },
    { title: "Tofu" },
    { title: "Almonds" },
    { title: "Brazil Nuts" },
    { title: "Cashews" },
    { title: "Chestnuts" },
    { title: "Chia Seed" },
    { title: "Flax Seeds" },
    { title: "Hazelnuts" },
    { title: "Hemp Seeds" },
    { title: "Macadamia" },
    { title: "Pecans" },
    { title: "Peanuts" },
    { title: "Pine Nuts" },
    { title: "Pistachio" },
    { title: "Poppy Seeds" },
    { title: "Pumpkin Seeds" },
    { title: "Sunflower Seeds" },
    { title: "Walnuts" },
  ],
};

const dairy = {
  title: "Low Fat Dairy / Dairy Alternatives",
  subtitle: "(1-3 servings per day) ",
  data: [{ title: "Buttermilk" }, { title: "Cheese (Hard)" }, { title: "Cheese (Soft) " }, { title: "Cottage Cheese" }, { title: "Milk" }, { title: "Kefir" }, { title: "Sour Cream" }, { title: "Yogurt" }],
};

const fish = {
  title: "Fish or Shellfish",
  subtitle: "2-3 servings per week, fatty-Low mercury is best",
  data: [
    { title: "Anchovies" },
    { title: "Arctic char" },
    { title: "Butterfish" },
    { title: "Catfish" },
    { title: "Caviar" },
    { title: "Clams" },
    { title: "Cod" },
    { title: "Crab" },
    { title: "Crawfish" },
    { title: "Flounder" },
    { title: "Haddock" },
    { title: "Halibut" },
    { title: "Herring" },
    { title: "Lobster" },
    { title: "Mackerel" },
    { title: "Mahi Mahi" },
    { title: "Monkfish" },
    { title: "Mussels" },
    { title: "Orange Roughly" },
    { title: "Pollock" },
    { title: "Red Snapper" },
    { title: "Salmon" },
    { title: "Sanddabs" },
    { title: "Sardines (Packed in water or olive oil)" },
    { title: "Scallops" },
    { title: "Sea Bass" },
    { title: "Shrimp" },
    { title: "Sole" },
    { title: "Squid" },
    { title: "Sturgeon" },
    { title: "Tilapia" },
    { title: "Trout" },
    { title: "Tuna (Fresh or Canned Light) " },
  ],
};

const poultry = {
  title: "Lean Skinless Poultry",
  subtitle: "optional, 1-3 servings per week",
  data: [{ title: "Chicken" }, { title: "Chicken Liver" }, { title: "Chicken Sausage" }, { title: "Cornish Hen" }, { title: "Duck" }, { title: "Chicken and Turkey Deli meat (low-fat)" }, { title: "Ostrich" }, { title: "Quail" }, { title: "Turkey" }, { title: "Turkey Sausage" }],
};

const eggs = {
  title: "Eggs",
  subtitle: "Yolks - no more than 4 per week, Whites - Unlimited",
  data: [{ title: "Eggs (Chicken, Duck, Quail) " }],
};

const fats = {
  title: "Fats",
  subtitle: "4-6 servings per day, mainly unsaturated, no trans fats",
  data: [{ title: "Avocado Oil" }, { title: "Canola Oil" }, { title: "Flaxseed Oil" }, { title: "Hempseed Oil" }, { title: "Olive Oil" }],
};

const alc = {
  title: "Alcohol",
  subtitle: "Optional, no more than 1 daily drink for women or 2 daily drinks for men",
  data: [{ title: "Beer (12oz)" }, { title: "Liquor (1.5oz)" }, { title: "Wine (4oz)" }],
};

const herbs = {
  title: "Herbs & Spices",
  subtitle: "not limited to these, go crazy",
  data: [
    { title: "Basil" },
    { title: "Cayenne" },
    { title: "Chili powder" },
    { title: "Chocolate (raw)" },
    { title: "Cinnamon" },
    { title: "Coriander" },
    { title: "Cumin" },
    { title: "Dill" },
    { title: "Fresh garlic" },
    { title: "Garlic powder" },
    { title: "Onion powder" },
    { title: "Parsley" },
    { title: "Pepper" },
    { title: "Rosemary" },
    { title: "Sea salt" },
    { title: "Stevia" },
    { title: "Thyme" },
    { title: "Vinegars" },
  ],
};

const DATA = [veg, fruits, grains, beans, dairy, fish, poultry, eggs, fats, alc, herbs];
export default DATA;
