//@flow
import React from 'react';
import { StyleSheet, FlatList } from 'react-native';
import { Comment } from './';

const renderComment = ({ item }) => {
	return <Comment comment={item} />;
};

const _keyExtractor = (item) => item.id;

export const CommentList = (props) => {
	const { comments } = props;
	return (
		<FlatList
			style={styles.container}
			data={comments}
			renderItem={renderComment}
			keyExtractor={_keyExtractor}
		/>
	);
};

var styles = StyleSheet.create({
	container: {
		flex: 1
	}
});
