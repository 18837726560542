//@flow
import React, { useState, useRef } from 'react';
import {
	TextInput,
	StyleSheet,
	View,
	Platform,
	ImageBackground
} from 'react-native';
import NavigationBar from 'react-native-navbar';
import { Button, Text, Checkbox, HeaderTextButton } from 'Components/common';
import { View as ViewA } from 'react-native-animatable';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
	signUpSubmit,
	createUserFromAnon
} from '../actions/FirebaseActionsAuth';
import { Colors, i18n, Images } from 'Theme';

type Props = {
	isVisible: boolean,
	isAnonymous?: boolean,
	onClosed?: Function
};

export const SignUpForm = (props: Props) => {
	const { isVisible, isAnonymous = false, onClosed, theme } = props;
	const dispatch = useDispatch();
	const [checked, handleCheckbox] = useState(true);
	const { control, handleSubmit, errors } = useForm();
	const emailInput = useRef(null);
	const passwordInput = useRef(null);

	const onSubmit = (values) => {
		if (isAnonymous) {
			//Converts the current anonymous user to Email/Password user
			dispatch(createUserFromAnon(values, checked)).then(() => onClosed());
		} else {
			//Signs up the user via Email/Password
			dispatch(signUpSubmit(values, checked));
		}
	};

	const renderForm = () => {
		return (
			<ViewA
				animation={isVisible ? 'fadeOutDown' : 'fadeInRight'}
				style={styles.formContainer}
				useNativeDriver
				duration={1000}>
				<Controller
					control={control}
					rules={{
						required: true,
						pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					}}
					render={({ onChange, value }) => (
						<TextInput
							style={styles.loginInput}
							onChangeText={(value) => onChange(value)}
							value={value}
							autoCapitalize="none"
							autoCorrect={false}
							placeholder={i18n.t('email')}
							placeholderTextColor={Colors.placeholderGray}
							ref={emailInput}
							onSubmitEditing={() => {
								passwordInput.current.focus();
							}}
						/>
					)}
					name="email"
					defaultValue=""
				/>
				{errors.email && (
					<Text style={styles.errorText}>{i18n.t('emailError')}</Text>
				)}

				<Controller
					control={control}
					render={({ onChange, value }) => (
						<TextInput
							style={styles.loginInput}
							onChangeText={(value) => onChange(value)}
							value={value}
							autoCapitalize="none"
							autoCorrect={false}
							placeholder={i18n.t('password')}
							placeholderTextColor={Colors.placeholderGray}
							ref={passwordInput}
							onSubmitEditing={handleSubmit((values) => onSubmit(values))}
						/>
					)}
					name="password"
					rules={{ required: true }}
					defaultValue=""
				/>
				{errors.password && (
					<Text style={styles.errorText}>{i18n.t('passwordError')}</Text>
				)}
				{isAnonymous && (
					<View style={styles.emailSubscribeCtn}>
						<Checkbox
							selected={checked}
							onPress={() => handleCheckbox(!checked)}
							text={i18n.t('emailSubscribe')}
						/>
					</View>
				)}
				<Button
					onPress={handleSubmit((values) => onSubmit(values))}
					style={[styles.signUpBtn, {backgroundColor: "#008989"}]}>
					{i18n.t('signUp')}
				</Button>
				<Text
					style={{
						fontSize: 8,
						lineHeight: 10,
						color: '#fff',
						marginHorizontal: 35
					}}>
					{i18n.t('disclaim')}
				</Text>
			</ViewA>
		);
	};

	//Needs header if is coming from Anonymous user because it is a modal
	if (isAnonymous) {
		return (
			<View style={{ flex: 1 }}>
				<NavigationBar
					statusBar={
						Platform.OS === 'ios' ? { hidden: false } : { hidden: true }
					}
					style={{ height: 60 }}
					title={{
						title: i18n.t('signUp'),
						style: {
							color: '#FFF',
							fontWeight: null,
							fontFamily: 'Comfortaa',
							fontSize: 14
						}
					}}
					containerStyle={{
						backgroundColor: Colors.themeAccent,
						paddingHorizontal: 10
					}}
					leftButton={
						<HeaderTextButton title={i18n.t('cancel')} onPress={onClosed} />
					}
				/>
				<ImageBackground
					source={Images.login}
					style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
					{renderForm()}
				</ImageBackground>
			</View>
		);
	}

	//For new user signing up it needs nothing additional since its embedded in LoginScreen.js
	return renderForm();
};

const styles = StyleSheet.create({
	//LoginForm
	loginInput: {
		fontFamily: 'Raleway',
		fontWeight: 'normal',
		color: '#333',
		marginHorizontal: 30,
		padding: 15,
		backgroundColor: '#FFF',
		flexDirection: 'column',
		marginVertical: 10,
		borderRadius: 5
	},
	loginLabel: {
		fontFamily: 'Raleway',
		fontWeight: 'normal',
		color: '#FFF',
		marginTop: 0
	},
	loginErrorLabel: {
		fontFamily: 'Raleway',
		fontWeight: 'normal',
		color: 'red',
		marginTop: 0
	},
	emailSubscribeCtn: {
		alignItems: 'center',
		marginVertical: 25,
		marginLeft: -10
	},
	errorText: {
		color: Colors.red,
		marginHorizontal: 15,
		fontSize: 10
	},
	signUpBtn: {
		marginHorizontal: 30
	},
	formContainer: {
	}
});
