import * as types from "../actions/types";
import createReducer from "../createReducer";

export function getInitialState() {
	let state = {};
	Object.keys(types.metaTypes).forEach(key => {
		let subState = { ref: null, inProgress: false, error: "", items: {} };
		state[key] = subState;
	});

	return state;
}

const initialState = getInitialState();

export const friendsListenerReducer = createReducer(initialState, {
	[types.firebase.FIREBASE_RESET_DATA](state) {
		const newState = getInitialState();
		return newState;
	},
	[types.firebase.FIREBASE_REMOVE_REQUESTED](state, action) {
		const property = action.metaType;
		let newState = { ...state, [property]: { inProgress: true, error: "" } };
		return newState;
	},
	[types.firebase.FIREBASE_REMOVE_FULFILLED](state, action) {
		const property = action.metaType;
		let newState = { ...state, [property]: { inProgress: false, error: "" } };
		return newState;
	},
	[types.firebase.FIREBASE_REMOVE_REJECTED](state, action) {
		const property = action.metaType;
		const error = action.error;
		let newState = { ...state, [property]: { inProgress: false, error } };
		return newState;
	},
	[types.firebase.FIREBASE_LISTEN_REQUESTED](state, action) {
		const property = action.metaType;
		const propertyState = state[property];
		let newState = { ...state, [property]: { ...propertyState, inProgress: true, error: "" } };
		return newState;
	},
	[types.firebase.FIREBASE_LISTEN_FULFILLED](state, action) {
		let items = action.items;
		const property = action.metaType;
		const propertyState = state[property];
		let newState;
		let error = "";
		if (Object.keys(action.items).length === 0 && action.items.constructor === Object) {
			error = "undefined";
		}
		newState = { ...state, [property]: { ...propertyState, inProgress: false, error: error, items } };

		return newState;
	},
	[types.firebase.FIREBASE_LISTEN_REJECTED](state, action) {
		const property = action.metaType;
		const propertyState = state[property];
		const error = action.error;

		let newState = { ...state, [property]: { ...propertyState, inProgress: false, error } };
		return newState;
	},
	[types.firebase.FIREBASE_LISTEN_REMOVED](state, action) {
		const property = action.metaType;
		const propertyState = state[property];
		const items = action.clearItems ? {} : propertyState.items;

		let newState = { ...state, [property]: { ...propertyState, ref: null, inProgress: false, error: "", items } };
		return newState;
	}
});
