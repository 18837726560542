//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'Components/common';

type Props = {
	bgColor: string,
	borderColor: string
};

export const HeaderTitle = (props: Props) => {
	const { bgColor, borderColor } = props;
	return (
		<View style={styles.otherContainer}>
			<Text
				style={[
					styles.otherHeader,
					{ backgroundColor: bgColor, borderTopColor: borderColor }
				]}>
				{props.children}
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	otherContainer: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'stretch'
	},
	otherHeader: {
		flex: 1,
		fontSize: 15,
		paddingTop: 12,
		paddingBottom: 12,
		paddingLeft: 15,
		borderTopWidth: 1
	}
});
