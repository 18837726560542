//@flow
import React from 'react';
import { View } from 'react-native';
import { Text } from 'Components/common';
import { VictoryPie, VictoryLegend, VictoryLabel } from 'victory-native';
import styles from '../Styles/Styles';
import { i18n } from 'Theme';
import Svg from 'react-native-svg';
import { calculatePercents } from 'Utilities';

type Props = {
	data: Object,
	width: number,
	height: number,
	activeLabels: boolean,
	onClick: Function,
	carbUnits: string,
	settings: {
		goals: { carb: number, fat: number, protein: number },
		carbgrams: number,
		fatgrams: number,
		proteingrams: number
	},
	showGoals: boolean,
	theme: Object
};

export const ProgressPieMacros = (props: Props) => {
	const {
		width,
		height,
		activeLabels,
		onClick,
		data,
		carbUnits,
		settings,
		showGoals,
		theme
	} = props;

	//Calculate Calories from Macro's Grams and Percent of Calories from them respectively
	const percentArr = calculatePercents(data, carbUnits);

	//Use correct Total vs Net carb preference
	const activeCarb = carbUnits === 'total' ? data.totalcarbs : data.carbs;

	//Legend labels switch back and forth on click
	let carbLabel = activeLabels
		? `${i18n.t('carbs')}: ${activeCarb.toFixed(0)}g / ${percentArr[3].toFixed(
				0
		  )} kCal`
		: `${i18n.t('carbs')}: ${percentArr[0].toFixed(1)}%`;
	let fatLabel = activeLabels
		? `${i18n.t('fat')}: ${data.fats.toFixed(0)}g / ${percentArr[4].toFixed(
				0
		  )} kCal`
		: `${i18n.t('fat')}: ${percentArr[1].toFixed(1)}%`;
	let proteinLabel = activeLabels
		? `${i18n.t('protein')}: ${data.proteins.toFixed(
				0
		  )}g / ${percentArr[5].toFixed(0)} kCal`
		: `${i18n.t('protein')}: ${percentArr[2].toFixed(1)}%`;

	//If default data (no entries logged), show an error message
	if (
		(data.proteins === 0 && data.fats === 0 && activeCarb === 0) ||
		data.length === 0
	) {
		return (
			<View
				style={[
					styles.chartErrorContainer,
					{ height: height * 0.9, backgroundColor: theme.baseBG }
				]}>
				<Text style={styles.weightErrorText}>{i18n.t('addFoodEntries')}</Text>
			</View>
		);
	}

	const returnGoalsLegend = () => {
		let carbGoalLabel = activeLabels
			? `${settings.carbgrams}g`
			: `${settings.goals.carb}%`;
		let fatGoalLabel = activeLabels
			? ` ${settings.fatgrams}g`
			: `${settings.goals.fat}%`;
		let proteinGoalLabel = activeLabels
			? `${settings.proteingrams}g`
			: `${settings.goals.protein}%`;

		return (
			<View
				style={[
					styles.pieLegendContainer,
					{
						borderTopColor: theme.border,
						borderTopWidth: 1,
						backgroundColor: theme.baseBG
					}
				]}>
				<View style={styles.pieContainer}>
					<Svg width={width * 0.666} height={height * 0.3}>
						<VictoryPie
							thing={activeLabels}
							padding={10}
							standalone={false}
							labels={() => ''}
							animate={{
								duration: 1000,
								onLoad: { duration: 500 }
							}}
							colorScale={[theme.graphCarb, theme.graphFat, theme.graphProtein]}
							data={[
								{
									x: i18n.t('carbs'),
									y: settings.goals.carb
								},
								{
									x: i18n.t('fat'),
									y: settings.goals.fat
								},
								{
									x: i18n.t('protein'),
									y: settings.goals.protein
								}
							]}
							width={width * 0.666}
							height={height * 0.3}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onPressIn: () => {
											return [
												{
													target: 'data',
													eventKey: 'all',
													mutation: () => {
														return onClick();
													}
												}
											];
										}
									}
								}
							]}
						/>
					</Svg>
				</View>
				<View style={styles.legendContainer}>
					<Svg width={width * 0.666} height={height * 0.25}>
						<VictoryLegend
							thing={activeLabels}
							title={'Goal'}
							titleComponent={<VictoryLabel dy={10} style={{ fontSize: 11 }} />}
							style={{
								labels: {
									fontSize: 12,
									fontFamily: 'Raleway',
									strokeWidth: 10,
									fill: theme.darkFont
								},
								title: {
									fill: theme.darkFont
								}
							}}
							standalone={false}
							orientation="horizontal"
							width={width * 0.666}
							height={height * 0.25}
							colorScale={[theme.graphCarb, theme.graphFat, theme.graphProtein]}
							data={[
								{
									name: carbGoalLabel,
									symbol: { type: 'square' }
								},
								{
									name: fatGoalLabel,
									symbol: { type: 'square' }
								},
								{
									name: proteinGoalLabel,
									symbol: { type: 'square' }
								}
							]}
						/>
					</Svg>
				</View>
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			<View
				style={[styles.pieLegendContainer, { backgroundColor: theme.baseBG }]}>
				<View style={styles.pieContainer}>
					<Svg width={width * 0.6} height={height * 0.6}>
						<VictoryPie
							thing={activeLabels}
							padding={height * 0.1}
							standalone={false}
							labels={() => ''}
							animate={{
								duration: 1000,
								onLoad: { duration: 500 }
							}}
							colorScale={[theme.graphCarb, theme.graphFat, theme.graphProtein]}
							data={[
								{
									x: i18n.t('carbs'),
									y: percentArr[3],
									grams: activeCarb,
									calories: percentArr[3]
								},
								{
									x: i18n.t('fat'),
									y: percentArr[4],
									grams: data.fats,
									calories: percentArr[4]
								},
								{
									x: i18n.t('protein'),
									y: percentArr[5],
									grams: data.proteins,
									calories: percentArr[5]
								}
							]}
							width={width * 0.6}
							height={height * 0.6}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onPressIn: () => {
											return [
												{
													target: 'data',
													eventKey: 'all',
													mutation: () => {
														return onClick();
													}
												}
											];
										}
									}
								}
							]}
						/>
					</Svg>
				</View>
				<View style={styles.legendContainer}>
					<Svg width={width * 0.5} height={height * 0.3}>
						<VictoryLegend
							thing={activeLabels}
							animate={{
								duration: 1000,
								onLoad: { duration: 500 }
							}}
							style={{
								labels: {
									fontSize: 12,
									fontFamily: 'Raleway',
									strokeWidth: 10,
									fill: theme.darkFont
								}
							}}
							standalone={false}
							rowGutter={-6}
							orientation="vertical"
							width={width * 0.5}
							height={height * 0.3}
							colorScale={[theme.graphCarb, theme.graphFat, theme.graphProtein]}
							data={[
								{
									name: carbLabel,
									symbol: { type: 'square' }
								},
								{
									name: fatLabel,
									symbol: { type: 'square' }
								},
								{
									name: proteinLabel,
									symbol: { type: 'square' }
								}
							]}
						/>
					</Svg>
				</View>
			</View>
			{showGoals && returnGoalsLegend()}
		</View>
	);
};
