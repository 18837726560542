//@flow
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { Colors } from 'Theme';
import { Text } from 'Components/common';
import { useSelector } from 'react-redux';

type Props = {
	onChange: Function,
	currentValue: Object,
	data: Array<{ [string]: mixed }>,
	containerStyle?: Object,
	buttonStyle?: Object,
	placeholder?: string,
	placeholderStyle?: Object
};

//Drop-down select "wheel" picker used for various input forms and setting changes
export const OptionSelector = (props: Props) => {
	const {
		onChange,
		currentValue,
		placeholder,
		data,
		containerStyle,
		textStyle,
		placeholderStyle
	} = props;
	const { theme } = useSelector((state) => state.startup);

	return (
		<View
			style={[
				styles.selectContainer,
				containerStyle,
				{ borderBottomColor: theme.border }
			]}>
			{placeholder && (
				<Text
					style={[
						styles.placeholderStyle,
						placeholderStyle
					]}>{`${placeholder} `}</Text>
			)}
			<Picker
				selectedValue={currentValue}
				style={[
					styles.pickerButton,
					textStyle,
					{ color: theme.blue, backgroundColor: theme.baseBG }
				]}
				onValueChange={(itemValue) => onChange(itemValue)}>
				{data.map((item, index) => (
					<Picker.Item label={item.label} value={item.value} key={index} />
				))}
			</Picker>
		</View>
	);
};

const styles = StyleSheet.create({
	selectContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1,
		marginRight: 10
	},
	pickerButton: {
		paddingVertical: 15,
		borderWidth: 0,
		fontFamily: 'Comfortaa'
	},
	placeholderStyle: {
		flex: 1
	}
});
