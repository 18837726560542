import * as React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { SideBar } from './components';
import { FinalAuthRoutes, returnDefaultRoute } from './routers/AuthRouter';

const Drawer = createDrawerNavigator();

export default function HomeScreenRouter() {
	return (
		<Drawer.Navigator
			initialRouteName={returnDefaultRoute()}
			drawerContent={(props) => <SideBar {...props} />}>
			{FinalAuthRoutes()}
		</Drawer.Navigator>
	);
}
