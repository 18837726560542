//@flow
import React from 'react';
import PropTypes from 'prop-types';
import {
	View,
	TouchableOpacity,
	Keyboard,
	FlatList,
	StyleSheet
} from 'react-native';
import { i18n } from 'Theme';
import { iconType, typeText } from '../../utilities/helper';
import { FontAwesome } from '@expo/vector-icons';
import { Text } from 'Components/common';
import { SwipeRow } from 'react-native-swipe-list-view';

export const FavFlatList = (props: Props) => {
	const { data, navigation, quickRemove, params, theme } = props;

	const routeConfig = (itemType) => {
		switch (itemType) {
			case 'api':
				return ['SearchScreen', 'SearchSingleScreen'];
			case 'food':
				return ['CustomFood', 'CustomFoodSingle'];
			case 'meal':
				return ['CustomMeal', 'CustomMealSingle'];
			case 'macro':
				return 'plus';
			default:
				return '';
		}
	};

	const navigateCloseKeyboard = (item) => {
		Keyboard.dismiss();
		const routeArr = routeConfig(item.type);
		navigation.navigate(routeArr[0], {
			screen: routeArr[1],
			params: {
				foodID: item.id
			}
		});
	};

	const renderItem = ({ item }) => {
		return (
			<SwipeRow
				swipeKey={`${item.id}`}
				disableRightSwipe={true}
				swipeToOpenPercent={1}
				rightOpenValue={-100}
				closeOnRowPress={true}
				style={{ backgroundColor: theme.baseBG }}
				friction={10}
				tension={10}>
				<View style={styles.hiddenCtn}>
					<TouchableOpacity
						style={[styles.deleteBtn, { backgroundColor: theme.red }]}
						onPress={() => quickRemove(item)}>
						<Text style={styles.swipeButtonText}>{i18n.t('delete')}</Text>
					</TouchableOpacity>
				</View>
				<View style={[styles.rowContainer, {borderBottomColor: theme.border, backgroundColor: theme.baseBG}]}>
					<TouchableOpacity
						style={{ flex: 1 }}
						onPress={() => navigateCloseKeyboard(item)}>
						<Text style={[styles.itemTitle, {color: theme.themeAccent}]}>{item.name}</Text>
						<View style={styles.favDescription}>
							<FontAwesome
								style={[styles.typeIcon, {color: theme.themeAccent}]}
								name={iconType(item.type)}
								size={10}
							/>
							<Text style={styles.typeDescription}>{typeText(item.type)}</Text>
						</View>
					</TouchableOpacity>
				</View>
			</SwipeRow>
		);
	};

	return (
		<FlatList
			data={data}
			keyExtractor={(item) => item.id}
			renderItem={renderItem}
			style={styles.foodListStyle}
			keyboardShouldPersistTaps="handled"
		/>
	);
};

FavFlatList.propTypes = {
	listArray: PropTypes.array,
	onSelectionChange: PropTypes.func
};

const styles = StyleSheet.create({
	rowContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottomWidth: 1,
		paddingLeft: 15
	},
	itemTitle: {
		fontSize: 14,
		lineHeight: 16,
		marginTop: 17,
		fontFamily: 'Raleway'
	},
	deleteBtn: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 100
	},
	swipeButtonText: {},
	hiddenCtn: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	//Favorites
	favDescription: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginTop: -5,
		marginBottom: 10
	},
	typeIcon: {
		alignSelf: 'center',
		width: 20,
		paddingLeft: 1
	},
	typeDescription: {
		fontSize: 10,
		fontFamily: 'Raleway'
	}
});
