import { StyleSheet } from 'react-native';
import { Colors } from 'Theme';

export default StyleSheet.create({
	//CustomSingleMeal.js
	container: {
		flex: 1,
		flexDirection: 'column'
	},
	firstRow: {
		backgroundColor: '#F5F5F5',
		flexDirection: 'row',
		alignItems: 'center',
		height: 75,
		borderTopWidth: 1,
		borderTopColor: '#e6e6e6'
	},
	foodHeader: {
		flexDirection: 'column',
		backgroundColor: '#f5f5f5',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		padding: 20
	},
	nameRow: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		height: 70,
		padding: 10,
		borderBottomWidth: 1,
		borderBottomColor: Colors.border
	},
	macroBox: {
		alignItems: 'center',
		flex: 1
	},
	macroLabel: {
		color: Colors.labelGrey,
		fontFamily: 'Raleway',
		fontSize: 12
	},
	macroAmount: {
		fontFamily: 'Raleway',
		fontWeight: 'bold',
		fontSize: 14
	},
	netCarbAmount: {
		fontSize: 18
	},
	buttonRow: {
		justifyContent: 'center',
		alignItems: 'flex-start',
		flex: 2,
		flexDirection: 'row',
		borderTopWidth: 1,
		borderTopColor: Colors.border
	},
	buttonStyle: {
		flex: 1,
		margin: 0
	},
	iconBtnStyle: {
		paddingRight: 0
	},
	foodListStyle: {
		borderTopWidth: 1,
		borderTopColor: Colors.border,
		flex: 1
	},
	ingredientHeader: {
		fontSize: 16,
		fontWeight: '900',
		paddingLeft: 0,
		paddingRight: 0
	},

	//Normal - View - Unique -----------------------------------
	foodName: {
		fontWeight: '900',
		flex: 1,
		fontSize: 16,
		alignSelf: 'center',
		paddingLeft: 10,
		paddingRight: 0
	},
	editStyle: {
		marginTop: 0,
		marginLeft: 0,
		marginRight: 0,
		marginBottom: 0,
		paddingHorizontal: 20
	},
	servingHolder: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center'
	},
	qtyStyle: {
		paddingLeft: 20
	},
	textInput: {
		flex: 4,
		height: 70,
		padding: 10,
		textAlign: 'right',
		paddingRight: 20,
		color: Colors.themeAccent
	},
	mealItemsContainer: {
		paddingLeft: 18,
		paddingVertical: 15,
		borderBottomWidth: 1,
		borderBottomColor: Colors.border
	},
	textStyle: {
		paddingLeft: 0,
		paddingRight: 0
	},

	//Edit - View Unique-------------------------------------
	editMealItemsContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: 18,
		paddingVertical: 15,
		borderBottomWidth: 1,
		borderBottomColor: Colors.border
	},
	deleteItem: {
		width: 30,
		height: 30,
		borderRadius: 30,
		backgroundColor: Colors.red,
		alignItems: 'center',
		justifyContent: 'center'
	},
	deleteIcon: {
		alignSelf: 'center'
	},
	titleItem: {
		flex: 1
	},
	activityRow: {
		flex: 1
	},
	actionButtonIcon: {
		fontSize: 20,
		height: 22,
		color: '#FFF'
	},
	textInputHolder: {
		flexDirection: 'row'
	},
	textinput: {
		color: Colors.themeAccent,
		flex: 1,
		fontSize: 16,
		fontWeight: '900',
		paddingLeft: 10
	},

	//Custom Food Tab ----------------------------
	buttonHolder: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		flexDirection: 'row',
		borderBottomWidth: 1,
		borderBottomColor: Colors.border
	},
});
