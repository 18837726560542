import {
	SET_PROGRESS_FILTER,
	SET_PROGRESS_FROM_DATE,
	SET_PROGRESS_TO_DATE,
	SET_PROGRESS_TIMEFRAME,
	SET_PROGRESS_BM_TIMEFRAME,
	SET_PROGRESS_BM_FILTER,
	SET_PROGRESS_BM_FROM_DATE,
	SET_PROGRESS_BM_TO_DATE,
	SET_PIE_CHART_DATA
} from 'Components/tracker/progress/actions/types';
import moment from 'moment';
import { i18n } from 'Theme';

const INITIAL_STATE = {
	progressFilter: 0,
	progressFromDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
	progressToDate: moment().format('YYYY-MM-DD'),
	progressTimeFrame: [],
	bodymeasurementsTimeFrame: [],
	progressBMFilter: 0,
	progressBMFromDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
	progressBMToDate: moment().format('YYYY-MM-DD')
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_PROGRESS_FILTER:
			return { ...state, progressFilter: action.payload };
		case SET_PROGRESS_FROM_DATE:
			return { ...state, progressFromDate: action.payload };
		case SET_PROGRESS_TO_DATE:
			return { ...state, progressToDate: action.payload };
		case SET_PROGRESS_TIMEFRAME:
			return { ...state, progressTimeFrame: action.payload };
		case SET_PROGRESS_BM_TIMEFRAME:
			return { ...state, bodymeasurementsTimeFrame: action.payload };
		case SET_PROGRESS_BM_FILTER:
			return { ...state, progressBMFilter: action.payload };
		case SET_PROGRESS_BM_FROM_DATE:
			return { ...state, progressBMFromDate: action.payload };
		case SET_PROGRESS_BM_TO_DATE:
			return { ...state, progressBMToDate: action.payload };
		default:
			return state;
	}
};
