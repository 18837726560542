//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text } from './';

export const FButton = (props) => {
	const { onPress } = props;
	return (
		<TouchableOpacity style={[styles.container, props.style]} onPress={onPress}>
			<Icon
				style={styles.iconStyle}
				name="facebook-square"
				color="#FFF"
				size={20}
			/>
			<Text style={styles.btnText}>{props.children}</Text>
		</TouchableOpacity>
	);
};

var styles = StyleSheet.create({
	container: {
		borderRadius: 3,
		borderWidth: 0,
		justifyContent: 'center',
		alignItems: 'center',
		height: 50,
		flexDirection: 'row',
		backgroundColor: '#3b5998',
		marginVertical: 20,
		marginHorizontal: 15,
		elevation: 1,
		shadowColor: '#333',
		shadowOffset: {
			width: 0,
			height: 1
		},
		shadowRadius: 1,
		shadowOpacity: 0.5
	},
	btnText: {
		color: '#FFF',
		fontSize: 15,
		fontFamily: 'Raleway'
	},
	iconStyle: {
		paddingRight: 10,
		alignSelf: 'center'
	}
});
