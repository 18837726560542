//@flow
import React from 'react';
import { View, TouchableOpacity, Keyboard, StyleSheet } from 'react-native';
import { Text } from 'Components/common';
import { useDispatch } from 'react-redux';
import { i18n } from 'Theme';
import { SwipeRow } from 'react-native-swipe-list-view';

type Props = {
	food: Object,
	nav: Object,
	nextRoute: string,
	quickRemoveRecentScanned: Function,
	theme: Object
};

export const BarcodeScreenRecentFlatList = (props: Props) => {
	const { food, nav, nextRoute, quickRemoveRecentScanned, theme } = props;
	const dispatch = useDispatch();
	const navigateCloseKeyboard = (food) => {
		Keyboard.dismiss();
		nav.navigate(nextRoute, { foodID: food.food_id });
	};

	return (
		<SwipeRow
			swipeKey={`${food.id}`}
			disableRightSwipe={true}
			swipeToOpenPercent={1}
			rightOpenValue={-100}
			closeOnRowPress={true}
			friction={10}
			tension={10}>
			<View style={styles.hiddenCtn}>
				<TouchableOpacity
					style={[styles.deleteBtn, { backgroundColor: theme.red }]}
					onPress={() => dispatch(quickRemoveRecentScanned(food))}>
					<Text style={styles.swipeButtonText}>{i18n.t('delete')}</Text>
				</TouchableOpacity>
			</View>
			<TouchableOpacity
				style={[styles.rowContainer, { borderBottomColor: theme.border, backgroundColor: theme.baseBG }]}
				onPress={() => navigateCloseKeyboard(food)}>
				<View>
					<Text style={[styles.foodTitle, { color: theme.themeAccent }]}>
						{food.food_name}
					</Text>
					<View>
						{food.brand_name && (
							<Text style={styles.brandTitle}>{food.brand_name}</Text>
						)}
						{!food.brand_name && (
							<Text style={styles.brandTitle}>{i18n.t('generic')}</Text>
						)}
					</View>
				</View>
			</TouchableOpacity>
		</SwipeRow>
	);
};

var styles = StyleSheet.create({
	rowContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottomWidth: 1
	},
	foodTitle: {
		fontSize: 14,
		lineHeight: 16,
		marginTop: 17,
		paddingLeft: 15
	},
	deleteBtn: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 100
	},
	brandTitle: {
		fontSize: 10,
		marginBottom: 10,
		paddingLeft: 15,
		marginTop: -5
	},
	swipeButtonText: {
		color: '#FFF'
	},
	hiddenCtn: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end'
	}
});
