import { i18n } from 'Theme';

export const iconType = (ingredientType) => {
	switch (ingredientType) {
		case 'api':
			return 'search';
		case 'food':
			return 'spoon';
		case 'meal':
			return 'cutlery';
		case 'macro':
			return 'plus';
		default:
			return '';
	}
};

export const typeText = (ingredientType) => {
	switch (ingredientType) {
		case 'api':
			return i18n.t('searched');
		case 'food':
			return i18n.t('myfoods');
		case 'meal':
			return i18n.t('mymeals');
		case 'macro':
			return i18n.t('quickmacro');
		default:
			return '';
	}
};
