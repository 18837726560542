//@flow
import React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { EditingItemModal as QuickAddMacros } from 'Components/modal/EditingItemModal';
import { EditingItemModal as EdtingLogEntry } from 'Components/modal/EditingItemModal';
import { OnboardModal } from 'Components/calculator/OnboardModal';
import { CopyMealModal } from './Modals';
import {
	submitQuickMacros,
	copyMeal,
	toggleModal,
	submitEditLogItem,
	saveAsMeal
} from '../actions';
import { Prompt } from 'Components/common';
import { i18n } from 'Theme';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

export const ModalsContainer = (props) => {
	const dispatch = useDispatch();

	const { modal, editValues } = useSelector((state) => state.log);

	return (
		<View style={{ flex: 1 }}>
			<Modal.ReactNativeModal
				isVisible={modal.quickAddModal}
				style={styles.fullScreen}
				animationIn={'slideInLeft'}
				animationOut={'slideOutLeft'}
				onSwipeComplete={() => dispatch(toggleModal('quickAddModal', false))}>
				<QuickAddMacros
					closeModal={() => dispatch(toggleModal('quickAddModal', false))}
					onSubmit={(values) => dispatch(submitQuickMacros(values))}
					modalTitle={i18n.t('quickAdd')}
					item={{
						name: i18n.t('quickAdd'),
						calories: '0',
						fats: '0',
						proteins: '0',
						carbs: '0',
						totalcarbs: '0',
						fiber: '0',
						manualCalories: false,
						nutrientsV2: {}
					}}
				/>
			</Modal.ReactNativeModal>
			<Modal.ReactNativeModal
				isVisible={modal.editLogItemModal}
				style={styles.fullScreen}
				animationIn={'slideInLeft'}
				animationOut={'slideOutLeft'}
				onSwipeComplete={() =>
					dispatch(toggleModal('editLogItemModal', false))
				}>
				{modal.editLogItemModal && (
					<EdtingLogEntry
						closeModal={() => dispatch(toggleModal('editLogItemModal', false))}
						onSubmit={(values) =>
							dispatch(submitEditLogItem(values, editValues))
						}
						modalTitle={i18n.t('editLogEntry')}
						confirmText={i18n.t('update')}
						item={{
							...editValues,
							name: editValues.name,
							calories: editValues.calories.toString(),
							carbs: editValues.carbs.toString(),
							fats: editValues.fats.toString(),
							proteins: editValues.proteins.toString(),
							manualCalories: editValues.manualCalories || false,
							totalcarbs: editValues.totalcarbs.toString(),
							fiber: editValues.fiber.toString(),
							nutrientsV2: editValues.nutrientsV2
						}}
					/>
				)}
			</Modal.ReactNativeModal>
			<Modal.ReactNativeModal
				isVisible={modal.copyMealModal}
				style={styles.fullScreen}
				animationIn={'slideInLeft'}
				animationOut={'slideOutLeft'}
				onSwipeComplete={() => dispatch(toggleModal('copyMealModal', false))}>
				<CopyMealModal
					title={i18n.t('copyItems')}
					closeModal={() => dispatch(toggleModal('copyMealModal', false))}
					onSubmit={() => dispatch(copyMeal())}
				/>
			</Modal.ReactNativeModal>
			<Prompt
				visible={modal.saveMealModal}
				onCancel={() => dispatch(toggleModal('saveMealModal', false))}
				onClosed={() => dispatch(toggleModal('saveMealModal', false))}
				onSubmit={(value) => dispatch(saveAsMeal(value))}
				title={i18n.t('saveMeal')}
				placeholder={i18n.t('name')}
				defaultValue=""
				submitText={i18n.t('save')}
				theme={props.theme}
			/>
			<Modal.ReactNativeModal
				isVisible={modal.onboard}
				style={styles.onBoard}
				animationIn={'slideInDown'}
				animationOut={'slideOutDown'}
				onSwipeComplete={() => dispatch(toggleModal('onboard', false))}>
				<OnboardModal />
			</Modal.ReactNativeModal>
		</View>
	);
};

const styles = StyleSheet.create({
	fullScreen: {
		padding: 0,
		margin: 0
	},
	modalScreen: {
		margin: 0
	},
	onBoard: {
		margin: Platform.OS === 'web' ? 30 : 15,
		marginVertical: 30,
		marginHorizontal: 10,
		padding: 8,
		borderRadius: 5
	}
});
