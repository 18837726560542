//@flow
import React from 'react';
import { View, TouchableOpacity, FlatList, StyleSheet } from 'react-native';
import { Text, HeaderMenuDropdown } from 'Components/common';
import { i18n } from 'Theme';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesome } from '@expo/vector-icons';
import _ from 'lodash';
import { calculateMacroTotals } from 'Utilities';
import { convertMealNameString } from 'Components/tracker/log/utilities/LogHelper';
import { MealEntry, HeaderMacros } from './';
import { toggleModal, setCopyTime, setCopyItems } from '../../actions';
import Elevations from 'react-native-elevation';

type Props = {
	foods: Object,
	mealName: string,
	onAddPress: Function,
	editEntry: Function
};

export const MealBox = (props: Props) => {
	const dispatch = useDispatch();
	const { date } = useSelector((state) => state.log);
	const { theme } = useSelector((state) => state.startup);
	const { mealName, foods, onAddPress, editEntry } = props;

	//Lowercase mealName so it can be used for Firebase Saving as opposed to Displaying in the View with a Capital... Firebase needs it lowercase to save
	const mealTime = convertMealNameString(mealName);
	//Convert Object of Food Objects into Array of Food Objects
	const arr = _.values(foods);
	//Add All Array of Food Objects Together To Get Meal Macro Totals
	const mealMacros = calculateMacroTotals(arr);

	const renderItem = ({ item }) => {
		return (
			<MealEntry
				item={item}
				mealTime={mealTime}
				date={date}
				editEntry={editEntry}
				theme={theme}
			/>
		);
	};

	const openQuickAdd = () => {
		dispatch(toggleModal('quickAddModal', true));
		dispatch(setCopyTime(mealTime));
	};

	const openCopyMeal = (copyItems) => {
		dispatch(setCopyItems(copyItems));
		dispatch(toggleModal('copyMealModal', true));
	};

	const openSaveMeal = (mealSaveItems) => {
		dispatch(setCopyItems(mealSaveItems));
		dispatch(toggleModal('saveMealModal', true));
	};

	let actionItems = [
		{
			iconText: i18n.t('quickAddMacros'),
			onPress: () => openQuickAdd(),
			iconName: 'plus',
			iconColor: theme.darkFont
		}
	];
	if (arr.length > 0) {
		actionItems = [
			{
				iconText: i18n.t('quickAddMacros'),
				onPress: () => openQuickAdd(),
				iconName: 'plus',
				iconColor: theme.darkFont
			},
			{
				iconText: i18n.t('copyTo'),
				onPress: () => openCopyMeal(arr),
				iconName: 'copy',
				iconColor: theme.darkFont
			},
			{
				iconText: i18n.t('saveAsMeal'),
				onPress: () => openSaveMeal(arr),
				iconName: 'cutlery',
				iconColor: theme.darkFont
			}
		];
	}

	return (
		<View style={[styles.logMealContainer, { backgroundColor: theme.grey }]}>
			<View
				style={[styles.headerContainer, { borderBottomColor: theme.border }]}>
				<Text style={styles.mealName}>{mealName}</Text>
				<HeaderMenuDropdown
					customHeight={50}
					menuIconColor={theme.darkFont}
					menuIconSize={15}
					array={actionItems}
				/>
			</View>
			{arr.length > 0 && <HeaderMacros mealMacros={mealMacros} theme={theme} />}
			<FlatList
				data={arr}
				keyExtractor={(item) => item.random}
				renderItem={renderItem}
				style={{ backgroundColor: theme.base, flexGrow: 0 }}
			/>
			<TouchableOpacity
				style={[styles.footer, { backgroundColor: theme.grey }]}
				onPress={onAddPress}>
				<FontAwesome name="plus" size={14} color={theme.darkFont} />
				<Text style={{ fontSize: 14, paddingLeft: 10 }}>
					{i18n.t('addEntry')}{' '}
				</Text>
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	logMealContainer: {
		marginVertical: 15,
		marginHorizontal: 15,
		...Elevations[2],
		overflow:'visible'
	},
	headerContainer: {
		borderBottomWidth: 1,
		flexDirection: 'row',
		flex: 1,
		height: 50,
		alignItems: 'stretch'
	},
	mealName: {
		paddingLeft: 17,
		alignSelf: 'center',
		flex: 4,
		fontSize: 15
	},
	mealBreakdown: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	footer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: 15,
		paddingRight: 15,
		paddingTop: 7,
		paddingBottom: 7
	}
});
