//@flow
import { createSelector } from 'reselect';
import { addUpMealMacros, customDateMacroGoals } from '../utilities/LogHelper';

const getData = (state) => state.firebase.profile.trackerdate;
const getDate = (state) => state.log.date;
const defaultData = (state) => state.log.defaultData;
const getSettings = (state) => state.firebase.profile.settings;
const getSpecificDate = (state, props) => props.date;

export const getProfileSettings = createSelector(
	getSettings,
	(settings) => settings
);

export const getTrackerData = createSelector(
	[getData, getDate, defaultData],
	(getData, getDate, defaultData) => {
		//new user handler
		if (typeof getData === 'undefined') {
			return defaultData;
		}

		switch (typeof getData[getDate]) {
			case 'undefined':
				return defaultData;
			case 'object':
				return { ...defaultData, ...getData[getDate] };
			default:
				return defaultData;
		}
	}
);

export const getDatesTotals = createSelector(
	[getTrackerData, getProfileSettings],
	(data, settings) => {
		let goals = {};
		if (settings === undefined) {
			return addUpMealMacros(
				data.breakfast,
				data.lunch,
				data.dinner,
				data.snacks,
				goals
			);
		}
		goals =
			typeof data.dailygoals !== 'undefined'
				? customDateMacroGoals(data.dailygoals)
				: {
						cal: settings.goals.cal,
						fatgrams: settings.fatgrams,
						carbgrams: settings.carbgrams,
						proteingrams: settings.proteingrams
				  };
		return addUpMealMacros(
			data.breakfast,
			data.lunch,
			data.dinner,
			data.snacks,
			goals
		);
	}
);

export const getSpecificTrackerData = createSelector(
	[getData, getSpecificDate, defaultData],
	(getData, getSpecificDate, defaultData) => {
		//new user handler
		if (typeof getData === 'undefined') {
			return defaultData;
		}

		switch (typeof getData[getSpecificDate]) {
			case 'undefined':
				return defaultData;
			case 'object':
				return { ...defaultData, ...getData[getSpecificDate] };
			default:
				return defaultData;
		}
	}
);

export const getSpecificDateTotals = createSelector(
	[getData, getSpecificDate, getSettings, defaultData],
	(getData, getSpecificDate, settings, defaultData) => {
		//new user handler
		if (typeof getData === 'undefined') {
			return defaultData;
		}
		let mealsHolder = {};
		let goals = {};

		switch (typeof getData[getSpecificDate]) {
			case 'undefined':
				mealsHolder = { ...defaultData };
				goals = {
					cal: settings.goals.cal,
					fatgrams: settings.fatgrams,
					carbgrams: settings.carbgrams,
					proteingrams: settings.proteingrams
				};
				return addUpMealMacros(
					mealsHolder.breakfast,
					mealsHolder.lunch,
					mealsHolder.dinner,
					mealsHolder.snacks,
					goals
				);
			case 'object':
				mealsHolder = { ...defaultData, ...getData[getSpecificDate] };
				goals =
					typeof getData[getSpecificDate].dailygoals !== 'undefined'
						? customDateMacroGoals(getData[getSpecificDate].dailygoals)
						: {
								cal: settings.goals.cal,
								fatgrams: settings.fatgrams,
								carbgrams: settings.carbgrams,
								proteingrams: settings.proteingrams
						  };
				return addUpMealMacros(
					mealsHolder.breakfast,
					mealsHolder.lunch,
					mealsHolder.dinner,
					mealsHolder.snacks,
					goals
				);
			default:
				return defaultData;
		}
	}
);
