//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { i18n } from 'Theme';
import { Text, Gram } from 'Components/common';

type Props = {
	onPress?: Function,
	subtitle?: string | boolean,
	text: string,
	units: string,
	value: string,
	theme: Object
};

export const RowTextInput = (props: Props) => {
	const { onPress, subtitle = false, theme } = props;

	if (typeof onPress === 'undefined') {
		return (
			<View style={[styles.otherItem, { borderTopColor: theme.border }]}>
				<Text style={{ flex: 1, paddingLeft: 15 }}>{props.text}</Text>
				{props.text === i18n.t('notes') ? (
					<Text style={[styles.notesStyle, { color: theme.themeAccent }]}>
						{props.value} {props.units}
					</Text>
				) : (
					<View style={styles.amountContainer}>
						<Text style={[styles.amountStyle, { color: theme.themeAccent }]}>
							<Text style={{ color: theme.themeAccent }}>{props.value}</Text>{' '}
							<Gram customText={props.units} />
						</Text>
						{subtitle && <Text style={styles.subtitleStyle}>{subtitle}</Text>}
					</View>
				)}
			</View>
		);
	}

	return (
		<TouchableOpacity
			onPress={onPress}
			style={[styles.otherItem, { borderTopColor: theme.border }]}>
			<Text style={{ flex: 1, paddingLeft: 15 }}>{props.text}</Text>
			{props.text === i18n.t('notes') ? (
				<Text style={[styles.notesStyle, { color: theme.themeAccent }]}>
					{props.value} {props.units}
				</Text>
			) : (
				<Text style={[styles.amountStyle, { color: theme.themeAccent }]}>
					<Text style={{ color: theme.themeAccent }}>{props.value}</Text>
					<Gram customText={props.units} />
				</Text>
			)}
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	otherItem: {
		flex: 2,
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingTop: 15,
		paddingBottom: 15,
		borderTopWidth: 1
	},
	amountContainer: {
		justifyContent: 'flex-end'
	},
	amountStyle: {
		paddingRight: 15
	},
	subtitleStyle: {
		fontSize: 10,
		lineHeight: 10,
		textAlign: 'right',
		marginRight: 20
	},
	notesStyle: {
		justifyContent: 'flex-end',
		paddingRight: 15
	}
});
