import { RECEIVE_FATSECRET_FOOD } from '../actions/types';

var end = new Date();
end.setHours(23, 59, 59, 999);

const INITIAL_STATE = {
	cachedFoods: [],
	persistExpiresAt: end.toISOString()
};

const checkIfNameExists = (arr, prop, newVal) => {
	return arr.some((e) => {
		return e[prop] ? e[prop] === newVal : false;
	});
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RECEIVE_FATSECRET_FOOD:
			if (
				checkIfNameExists(state.cachedFoods, 'food_id', action.payload.food_id)
			) {
				return { ...state };
			}
			return { ...state, cachedFoods: [...state.cachedFoods, action.payload] };
		default:
			return state;
	}
};
