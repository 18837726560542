import { GENERIC_LOADING_TOGGLE } from "../actions/types";

const initialState = {
	modalType: false,
	modalProps: {},
	loading: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "SHOW_MODAL":
			return {
				modalType: !state.modalType,
				modalProps: action.modalProps
			};
		case "HIDE_MODAL":
			return {
				modalType: false
			};
		case GENERIC_LOADING_TOGGLE:
			return {
				loading: action.payload
			};
		default:
			return state;
	}
};
