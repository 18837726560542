//@flow
import React, { useState } from 'react';
import {
	View,
	KeyboardAvoidingView,
	Platform,
	TouchableWithoutFeedback,
	Keyboard
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Text, OptionSelector, OptionTextInput, Gram } from 'Components/common';
import { i18n } from 'Theme';
import styles from './styles';
import { Nav } from './';
import {
	calculateBMR,
	updateCalcValue
} from 'Components/calculator/actions/MacroCalculatorActions';
import { updateUnitsFormat } from 'Components/tracker/settings/actions';
import {
	convertHeightToFeet,
	convertWeightToLbs,
	convertWeightToMetric,
	ftToCm
} from 'Components/calculator/utilities/MacroCalculatorHelper';
import { validateAll } from 'Utilities';

type Props = {
	page: number,
	next: Function,
	prev: Function,
	dotNumber?: number
};

export const PageProfile = (props: Props) => {
	const { next, prev, page, dotNumber, theme } = props;
	const dispatch = useDispatch();
	const { age, gender, height, weight } = useSelector(
		(state) => state.firebase.profile.profilePage
	);

	const { bodymeasurementsUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);

	const [heightState, changeHeight] = useState(
		bodymeasurementsUnits === 'metric' ? height : convertHeightToFeet(height)
	);
	const [weightState, changeWeight] = useState(
		bodymeasurementsUnits === 'metric' ? weight : convertWeightToLbs(weight)
	);

	const ageData = createNumberData(18, 80);
	const heightData = createHeightData(heightArr);

	//Constant options for the dropdown fields
	const optionsGender = [
		{ label: i18n.t('male'), value: 'male' },
		{ label: i18n.t('female'), value: 'female' }
	];

	const metricOptions = [
		{ label: i18n.t('ftcm'), value: 'imperial' },
		{ label: i18n.t('metric'), value: 'metric' }
	];

	const pageSubmit = () => {
		const formattedWeight =
			bodymeasurementsUnits === 'metric'
				? weightState
				: convertWeightToMetric(weightState);
		const formattedHeight =
			bodymeasurementsUnits === 'metric' ? heightState : ftToCm(heightState);
		if (!validateAll([formattedHeight, formattedWeight])) return;
		dispatch(updateCalcValue('height', (+formattedHeight).toFixed(2)));
		dispatch(updateCalcValue('weight', (+formattedWeight).toFixed(2)));
		dispatch(calculateBMR(age, gender, formattedWeight, formattedHeight));
		return next();
	};

	const changeMetric = (newVal) => {
		if (newVal === 'metric') {
			changeHeight(ftToCm(heightState));
			changeWeight(convertWeightToMetric(weightState));
		} else {
			changeHeight(convertHeightToFeet(heightState));
			changeWeight(convertWeightToLbs(weightState));
		}
		dispatch(updateUnitsFormat('bodymeasurementsUnits', newVal));
	};

	const returnHeightForm = () => {
		return bodymeasurementsUnits === 'metric' ? (
			<OptionTextInput
				onChange={(value) => changeHeight(value.replace(',', '.'))}
				currentValue={heightState}
				placeholder={i18n.t('height')}>
				<Gram customText={'cm'} />
			</OptionTextInput>
		) : (
			<OptionSelector
				onChange={(value) => changeHeight(value)}
				currentValue={heightState}
				placeholder={i18n.t('height')}
				data={heightData}
			/>
		);
	};

	const returnWeightForm = (w) => {
		return bodymeasurementsUnits === 'metric' ? (
			<OptionTextInput
				onChange={(value) => changeWeight(value.replace(',', '.'))}
				currentValue={w}
				placeholder={i18n.t('weight')}>
				<Gram customText={'kg'} />
			</OptionTextInput>
		) : (
			<OptionTextInput
				onChange={(value) => changeWeight(value.replace(',', '.'))}
				currentValue={w}
				placeholder={i18n.t('weight')}>
				<Gram customText={'lbs'} />
			</OptionTextInput>
		);
	};

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS === 'ios' ? 'padding' : null}
			style={styles.pageContainer}>
			<TouchableWithoutFeedback
				onPress={Platform.OS === 'web' ? null : Keyboard.dismiss}>
				<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
					<Nav
						onForward={pageSubmit}
						//onBack={dotNumber ? null : prev}
						onBack={null}
						page={page}
						dotNumber={dotNumber}
						theme={theme}
					/>
					<Text style={styles.pageTitle}>{i18n.t('calculatorDetails')}</Text>
					<View style={styles.inputsContainer}>
						<OptionSelector
							onChange={(value) => dispatch(updateCalcValue('age', +value))}
							currentValue={age}
							placeholder={i18n.t('age')}
							data={ageData}
						/>
						<OptionSelector
							onChange={(value) => dispatch(updateCalcValue('gender', value))}
							currentValue={gender}
							placeholder={i18n.t('gender')}
							data={optionsGender}
						/>
						{returnHeightForm()}
						{returnWeightForm(weightState)}
						<OptionSelector
							onChange={(value) => changeMetric(value)}
							currentValue={bodymeasurementsUnits}
							placeholder={i18n.t('bodyMeasurements')}
							data={metricOptions}
						/>
					</View>
				</View>
			</TouchableWithoutFeedback>
		</KeyboardAvoidingView>
	);
};

const createNumberData = (min, max) => {
	let x = [];
	for (let t = min; t <= max; t++) {
		x.push({ label: t.toString(), value: t });
	}
	return x;
};

const createHeightData = (hArr) => {
	let x = [];
	for (let t = 0; t <= hArr.length - 1; t++) {
		x.push({ label: hArr[t], value: hArr[t] });
	}
	return x;
};

const heightArr = [
	'4"0',
	'4"1',
	'4"2',
	'4"3',
	'4"4',
	'4"5',
	'4"6',
	'4"7',
	'4"8',
	'4"9',
	'4"10',
	'4"11',
	'5"0',
	'5"1',
	'5"2',
	'5"3',
	'5"4',
	'5"5',
	'5"6',
	'5"7',
	'5"8',
	'5"9',
	'5"10',
	'5"11',
	'6"0',
	'6"1',
	'6"2',
	'6"3',
	'6"4',
	'6"5',
	'6"6',
	'6"7',
	'6"8',
	'6"9',
	'6"10',
	'6"11',
	'7"0',
	'7"1',
	'7"2',
	'7"3',
	'7"4',
	'7"5',
	'7"6',
	'7"7',
	'7"8',
	'7"9',
	'7"10',
	'7"11'
];
