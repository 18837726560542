const veg = {
    title: "Red Meat",
    subtitle: "3-4 servings per month at most",
    data: [
        { title: "Beef Tenderloin, Filet Mignon" },
        { title: "Buffalo" },
        { title: "Extra-lean ham" },
        { title: "Lean center-cut pork chops" },
        { title: "Lean slices of roast beef" },
        { title: "Pork tenderloin, pork loin roast" },
        { title: "Reduced-fat bacon" },
        { title: "Steak (flank, sirloin, London broil" },
        { title: "Veal" },
        { title: "Venison" },
    ],
};

const fruits = {
    title: "Most Dairy",
    subtitle: "Besides low-fat cheese and yogurt types.",

    data: [{ title: "High Fat Dairy" }],
};
const grains = {
    title: "Beverages",
    subtitle: "(4-6 servings per day)",
    data: [{ title: "Sugary drinks (soda, energy drinks, sports drinks, etc" }, { title: "Excess Alcohol" }],
};

const beans = {
    title: "Processed Foods",
    subtitle: "All Processed/Refined/Simple Carbohydrates, Sweets, and Fast Foods (not limited to these)",
    data: [
        { title: "Biscuits" },
        { title: "Bread crumbs" },
        { title: "Bread pudding" },
        { title: "Cake" },
        { title: "Chicken nuggets" },
        { title: "Cookies" },
        { title: "Corn dogs" },
        { title: "Crackers" },
        { title: "Croissants" },
        { title: "Croutons" },
        { title: "Cupcakes" },
        { title: "Custard" },
        { title: "Dough nuts" },
        { title: "Dumplings" },
        { title: "French fries" },
        { title: "Fried foods" },
        { title: "Funnel cake" },
        { title: "Gravy" },
        { title: "Hot dog and hamburger buns" },
        { title: "Ice cream cones" },
        { title: "Macaroni" },
        { title: "Margarines" },
        { title: "Meatballs" },
        { title: "Meatloaf" },
        { title: "Muffins" },
        { title: "Non-dairy creamer" },
        { title: "Noodles" },
        { title: "Pancakes" },
        { title: "Pastries" },
        { title: "Pie and pie crusts" },
        { title: "Potato chips" },
        { title: "Pudding" },
        { title: "Rolls" },
        { title: "Shortening" },
        { title: "Waffles" },
    ],
};

const DATA = [veg, fruits, grains, beans];
export default DATA;
