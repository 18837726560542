//@flow
import React, { useState } from 'react';
import { View } from 'react-native';
import { PageProfile, PageProfile2, PageMacroGoals } from './components';
import { useSelector } from 'react-redux';

export const MacroCalculatorScreen = () => {
	const [page, changePage] = useState(0);
	const { theme } = useSelector((state) => state.startup);
	const nextPage = () => {
		changePage(page + 1);
	};

	const previousPage = () => {
		changePage(page - 1);
	};

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG, alignItems: 'center' }}>
			{page === 0 && (
				<PageProfile
					page={page}
					next={nextPage}
					prev={previousPage}
					dotNumber={3}
					theme={theme}
				/>
			)}
			{page === 1 && (
				<PageProfile2
					page={page}
					next={nextPage}
					prev={previousPage}
					dotNumber={3}
					theme={theme}
				/>
			)}
			{page === 2 && (
				<PageMacroGoals
					page={page}
					next={nextPage}
					prev={previousPage}
					finalSubmit={true}
					dotNumber={3}
					theme={theme}
				/>
			)}
		</View>
	);
};
