import {
	REQUEST_EDAMAM_RECIPES,
	RECEIVE_EDAMAM_RECIPES,
	RECEIVE_LOAD_MORE_EDAMAM_RECIPES,
	CLEAR_EDAMAM_RECIPES,
	REQUEST_EDAMAM_SINGLE,
	RECEIVE_EDAMAM_SINGLE,
	UPDATE_RECIPE_FILTER
} from '../actions/types';
import { i18n } from 'Theme';

const INITIAL_STATE = {
	isFetching: false,
	searchResults: [],
	loadMoreSearchText: '',
	from: 10,
	to: 20,
	canLoadMore: false,
	singleRequest: [],
	bookmarks: [],
	filter: {
		cuisineType: { key: 'All', label: i18n.t('all') },
		mealType: { key: 'All', label: i18n.t('all') }
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REQUEST_EDAMAM_RECIPES:
			return {
				...state,
				isFetching: true,
				loadMoreSearchText: action.payload
			};
		case RECEIVE_EDAMAM_RECIPES:
			const newState = action.payload.hits.filter(
				(item) => filterAssholes.indexOf(item.recipe.source) === -1
			);
			return {
				...state,
				isFetching: false,
				searchResults: [...state.searchResults, ...newState],
				totalResults: action.payload.count,
				canLoadMore: action.payload.more
			};
		case REQUEST_EDAMAM_SINGLE:
			return {
				...state,
				isFetching: true
			};
		case RECEIVE_EDAMAM_SINGLE:
			return {
				...state,
				isFetching: false,
				singleRequest: { ...action.payload[0] }
			};
		case RECEIVE_LOAD_MORE_EDAMAM_RECIPES:
			const newStateLoaded = action.payload.hits.filter(
				(item) => filterAssholes.indexOf(item.recipe.source) === -1
			);
			return {
				...state,
				isFetching: false,
				searchResults: [...state.searchResults, ...newStateLoaded],
				from: state.to,
				to: state.to + 50
			};
		case CLEAR_EDAMAM_RECIPES:
			return { ...state, searchResults: [] };
		case UPDATE_RECIPE_FILTER:
			return {
				...state,
				filter: { cuisineType: action.cuisineType, mealType: action.mealType }
			};
		default:
			return state;
	}
};

const filterAssholes = ['ketodietapp.com'];
