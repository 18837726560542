//@flow
import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, FlatList, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
//import { authGoogleFit, disconnectGoogleFit } from "./utilities/GoogleFit";
import { fitbit_logOut, fitbit_logIn } from './utilities/Fitbit';
import Icon from 'react-native-vector-icons/FontAwesome';
import styles from './Styles/Styles';
import { Row, Text } from 'Components/common';
import { Images, Colors, i18n } from 'Theme';

export const FitnessTrackersScreen = ({ route, navigation }) => {
	const dispatch = useDispatch();
	const { auth: fitbitAuth } = useSelector(
		(state) => state.fitnessTracker.fitbit
	);
	const { theme } = useSelector((state) => state.startup);
	const [fitbit, changeFitbit] = useState({
		image: Images.fitBit,
		title: 'Fitbit',
		fn: () => {
			fitbitAuth.access_token
				? dispatch(fitbit_logOut(fitbitAuth.access_token))
				: dispatch(fitbit_logIn());
		},
		id: '1',
		hasAuth: fitbitAuth.access_token ? true : false,
		description: i18n.t('fitbitDesc')
	});

	useEffect(() => {
		changeFitbit({
			...fitbit,
			hasAuth: typeof fitbitAuth.access_token === 'undefined' ? false : true
		});
	}, [fitbitAuth]);

	const renderRow = ({ item }) => {
		return (
			<TouchableOpacity id={item.id} onPress={() => item.fn()}>
				<Row style={{ borderBottomColor: theme.border }}>
					<Image style={styles.fitnessAppImage} source={item.image} />
					<View style={{ flex: 1 }}>
						<Text style={styles.title}>{item.title}</Text>
						<Text style={styles.subTitle}>{item.description}</Text>
					</View>
					{item.hasAuth ? (
						<Icon
							style={styles.arrowIcon}
							size={22}
							color={theme.green}
							name={'check-square'}
						/>
					) : (
						<Icon style={styles.arrowIcon} name={'chevron-right'} />
					)}
				</Row>
			</TouchableOpacity>
		);
	};

	const optionsArr = [fitbit];
	return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}><Text>Web support for fitness trackers coming soon</Text></View>
	return (
		<FlatList
			data={optionsArr}
			renderItem={renderRow}
			keyExtractor={(item) => item.id}
			style={{ backgroundColor: theme.baseBG }}
		/>
	);
};
