//@flow
import React, { useState } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { i18n } from 'Theme';
import { Text, Nutrients, Ingredients, MacroGrid } from 'Components/common';
import Elevations from 'react-native-elevation';

type Props = {
	changeTab: Function,
	activeTab: number,
	data: Object,
	settings: Object,
	ingredients: Object,
	macros: Object,
	nutrients: Object,
	theme: Object,
	carbUnits: string
};

export const ViewTabBar = (props: Props) => {
	const [activeTab, changeTab] = useState(1);
	const { theme, carbUnits } = props;
	return (
		<View>
			<View style={[styles.tabViewContainer, { backgroundColor: theme.base }]}>
				<TouchableOpacity
					onPress={() => changeTab(0)}
					style={[
						styles.tab,
						activeTab === 0
							? { borderBottomColor: theme.tabActive, borderBottomWidth: 1 }
							: null
					]}>
					<Text style={activeTab === 0 ? { color: theme.tabActive } : null}>
						{i18n.t('ingredients')}
					</Text>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => changeTab(1)}
					style={[
						styles.tab,
						activeTab === 1
							? { borderBottomColor: theme.tabActive, borderBottomWidth: 1 }
							: null
					]}>
					<Text style={activeTab === 1 ? { color: theme.tabActive } : null}>
						{i18n.t('macros')}
					</Text>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => changeTab(2)}
					style={[
						styles.tab,
						activeTab === 2
							? { borderBottomColor: theme.tabActive, borderBottomWidth: 1 }
							: null
					]}>
					<Text style={activeTab === 2 ? { color: theme.tabActive } : null}>
						{i18n.t('nutrients')}
					</Text>
				</TouchableOpacity>
			</View>
			<View style={styles.tabView}>
				{activeTab === 0 && (
					<Ingredients
						ingredients={props.ingredients}
						noCollapse={true}
						theme={theme}
					/>
				)}
				{activeTab === 1 && (
					<MacroGrid {...props.macros} theme={theme} carbUnits={carbUnits} />
				)}
				{activeTab === 2 && (
					<Nutrients nutrients={props.nutrients} theme={theme} />
				)}
			</View>
		</View>
	);
};

var styles = StyleSheet.create({
	tabViewContainer: {
		height: 60,
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'stretch',
		marginBottom: 2,
		...Elevations[2]
	},
	tab: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	tabView: {},
	active: {},
	activeText: {
		color: '#FFF'
	}
});
