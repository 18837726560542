//@flow
import _ from 'lodash';
import moment from 'moment';

export const convertHeightToFeet = (HeightCM: string) => {
	var realFeet = (+HeightCM * 0.3937) / 12;
	var feet = Math.floor(realFeet);
	var inches = Math.round((realFeet - feet) * 12);
	return `${feet}"${inches}`;
};

export const convertWeightToMetric = (weightLb: string) => {
	if (typeof weightLb === 'undefined') return '';
	let weightKg = parseFloat(weightLb) * 0.453592;
	return weightKg.toFixed(1);
};

export const convertWeightToLbs = (weightKg: string) => {
	if (typeof weightKg === 'undefined' || weightKg === '') return '';
	let weightLB = +weightKg * 2.20462;
	return weightLB.toFixed(1);
};

export const ftToCm = (ftStr: string) => {
	const totalInches = +ftStr.split('"')[0] * 12 + +ftStr.split('"')[1];
	const totalCM = totalInches * 2.54;
	return totalCM.toFixed(1);
};

export const calcWeightChangePerWeek = (
	baseCalories: number,
	deficit: number,
	isWeightCM: boolean
) => {
	const deficitCalories = +baseCalories * (deficit / 100);
	let weightChange = (deficitCalories * 7) / 3500;
	if (isWeightCM) {
		weightChange *= 0.453592;
	}

	return Math.abs(weightChange).toFixed(2);
};

export const calcWeightGoalDate = (
	baseCalories: number,
	deficit: number,
	isWeightCM: boolean,
	goalWeight: number
) => {
	const deficitCalories = +baseCalories * (deficit / 100);
	let weightChange = Math.abs(deficitCalories) / 3500;
	if (isWeightCM) {
		weightChange *= 0.453592;
	}

	let numDays = (goalWeight / weightChange).toFixed(0);
	if (deficitCalories === 0) numDays = '0';
	const new_date = moment()
		.add(+numDays, 'days')
		.format('MMM-DD-YYYY');

	return new_date;
};

//Top Function for Getting Protein Suggestions
export const getProteins = (
	weightKg: string,
	bodyFat: string,
	isMetric: boolean
) => {
	const LeanMassKg = getBodyCompostionMetrics(isMetric, +bodyFat, weightKg);
	const maintain = getMaintainProtein(LeanMassKg).toFixed(0);
	const gain = getGainProtein(LeanMassKg).toFixed(0);
	return { maintain, gain };
};

const getBodyCompostionMetrics = (
	isMetric: boolean,
	bodyFatPerfect: number,
	weightKg: string
) => {
	const bodyFatWeightKg = getBodyFatWeightKg(bodyFatPerfect, +weightKg);
	const LeanMass = getLeanMasses(+bodyFatWeightKg, +weightKg);
	return LeanMass;
};

//Gets Body Fat In Kg and Lbs
const getBodyFatWeightKg = (bodyFatPerfect: number, weightKg: number) => {
	const BodyFatWeightKg = +((bodyFatPerfect / 100) * weightKg).toFixed(0);
	//const BodyFatWeightLb = +(BodyFatWeightKg * 2.20462).toFixed(0);
	return BodyFatWeightKg;
};

//Gets Lean Mass in KG and LB
const getLeanMasses = (bodyFatWeightKg: number, weightKg: number) => {
	const LeanMassKg = +(weightKg - bodyFatWeightKg).toFixed(0);
	//const LeanMassLb = +(LeanMassKg * 2.20462).toFixed(0);
	return LeanMassKg;
};

const getMaintainProtein = (LeanMassKg: number) => {
	const LowestProteinIntake = LeanMassKg * 1.4;
	return +LowestProteinIntake.toFixed(0);
};

const getGainProtein = (LeanMassKg: number) => {
	const HighestProteinIntake = LeanMassKg * 2.2;
	return +HighestProteinIntake.toFixed(0);
};

//Round final results macro grams proportionately so they add up to the correct goal calories
export const round100 = (l, target) => {
	var off =
		target -
		_.reduce(
			l,
			(acc, x) => {
				return acc + Math.round(x);
			},
			0
		);
	return _.chain(l)
		.sortBy((x) => {
			return Math.round(x) - x;
		})
		.map((x, i) => {
			return Math.round(x) + (off > i) - (i >= l.length + off);
		})
		.value();
};
