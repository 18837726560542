//@flow
import * as React from 'react';
import { StackActions } from '@react-navigation/native';

export const navigationRef = React.createRef();
export const isReadyRef = React.createRef();

export function navigate(name: string, params: Object) {
	if (isReadyRef.current && navigationRef.current) {
		// Perform navigation if the app has mounted
		navigationRef.current.navigate(name, params);
	} else {
		// You can decide what to do if the app hasn't mounted
		// You can ignore this, or add these actions to a queue you can call later
	}
}

//Go back to the previous screen in the stack
export function goBack() {
	navigationRef.current?.goBack();
}

export function addFoodReturn() {
	navigationRef.current?.dispatch(StackActions.popToTop());
}
