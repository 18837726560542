//@flow
import React from "react";
import { SafeAreaView, StatusBar as StatusBarRN, StyleSheet, Platform } from "react-native";
import { useSelector } from "react-redux";

const STATUSBAR_HEIGHT = Platform.OS === "ios" ? 0 : StatusBarRN.currentHeight;

export const StatusBar = ({ backgroundColor, ...props }) => {
	const { theme, darkMode } = useSelector((state) => state.startup);

	if (props.currentRouteName === "Login") {
		return (
			<>
				<StatusBarRN translucent backgroundColor={theme.navBar} {...props} />
				{props.children}
			</>
		);
	}
	return (
		<>
			<SafeAreaView style={[styles.topCtn, { backgroundColor: theme.navBar, height: STATUSBAR_HEIGHT }]} />
			<SafeAreaView style={[styles.container, { backgroundColor: darkMode === true ? theme.navBar : theme.grey }]}>
				<StatusBarRN translucent backgroundColor={theme.navBar} {...props} />
				{props.children}
			</SafeAreaView>
		</>
	);
};

var styles = StyleSheet.create({
	topCtn: {
		flex: 0,
	},
	container: {
		flex: 1,
	},
});
