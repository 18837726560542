//@flow
import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { StepIndicator, Text } from 'Components/common';
import { Ionicons } from '@expo/vector-icons';

export const Nav = ({
	onForward,
	onBack,
	page,
	finalSubmit = false,
	hideDots,
	canClose,
	dotNumber = 6,
	theme
}) => {
	const customStyles = {
		stepIndicatorSize: 10,
		currentStepIndicatorSize: 15,
		separatorStrokeWidth: 1,
		currentStepStrokeWidth: 2,
		stepStrokeCurrentColor: theme.blue,
		stepStrokeWidth: 2,
		stepStrokeFinishedColor: theme.blue,
		stepStrokeUnFinishedColor: '#dedede',
		separatorFinishedColor: 'transparent',
		separatorUnFinishedColor: 'transparent',
		stepIndicatorFinishedColor: theme.blue,
		stepIndicatorUnFinishedColor: '#ffffff',
		stepIndicatorCurrentColor: '#ffffff',
		stepIndicatorLabelFontSize: 0,
		currentStepIndicatorLabelFontSize: 0,
		stepIndicatorLabelCurrentColor: 'transparent',
		stepIndicatorLabelFinishedColor: 'transparent',
		stepIndicatorLabelUnFinishedColor: 'transparent',
		labelColor: '#999999',
		labelSize: 13,
		currentStepLabelColor: theme.blue
	};

	return (
		<View style={styles.top}>
			<TouchableOpacity
				style={styles.btnContainer}
				onPress={typeof onBack !== 'undefined' ? onBack : null}>
				<Ionicons
					style={styles.btnIcon}
					name={page > 0 ? 'md-arrow-back' : canClose ? 'md-close' : null}
					color={theme.darkFont}
					size={20}
				/>
			</TouchableOpacity>

			{!hideDots && (
				<View style={styles.stepContainer}>
					<StepIndicator
						customStyles={customStyles}
						currentPosition={page}
						stepCount={dotNumber}
					/>
				</View>
			)}
			<View
				style={[
					styles.btnContainer,
					{ backgroundColor: finalSubmit ? theme.baseBG : theme.blue }
				]}>
				{!finalSubmit && (
					<TouchableOpacity onPress={onForward}>
						<Ionicons
							style={styles.btnIcon}
							name={'md-arrow-forward'}
							color={'#FFF'}
							size={20}
						/>
					</TouchableOpacity>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	top: {
		height: 70,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row'
	},
	stepContainer: {
		width: '50%',
		maxWidth: 200,
		marginHorizontal: 10
	},
	btnIcon: {
		padding: 10
	},
	btnContainer: {
		height: 40,
		width: 40,
		borderRadius: 40,
		justifyContent: 'center',
		alignItems: 'center'
	}
});
