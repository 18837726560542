//Spoonacular Types
export const REQUEST_SPOON_SEARCH = 'REQUEST_SPOON_SEARCH';
export const RECEIVE_SPOON_SEARCH = 'RECEIVE_SPOON_SEARCH';
export const REQUEST_LOAD_MORE_SPOON_SEARCH = 'REQUEST_LOAD_MORE_SPOON_SEARCH';
export const RECEIVE_LOAD_MORE_SPOON_SEARCH = 'RECEIVE_LOAD_MORE_SPOON_SEARCH';
export const CLEAR_SPOON_SEARCH = 'CLEAR_SPOON_SEARCH';
export const RECEIVE_SPOON_SINGLE = 'RECEIVE_SPOON_SINGLE';
export const REQUEST_SPOON_SINGLE = 'REQUEST_SPOON_SINGLE';

//Edamam Recipe Types
export const REQUEST_EDAMAM_RECIPES = 'REQUEST_EDAMAM_RECIPES';
export const RECEIVE_EDAMAM_RECIPES = 'RECEIVE_EDAMAM_RECIPES';
export const REQUEST_LOAD_MORE_EDAMAM_RECIPES =
	'REQUEST_LOAD_MORE_EDAMAM_RECIPES';
export const RECEIVE_LOAD_MORE_EDAMAM_RECIPES =
	'RECEIVE_LOAD_MORE_EDAMAM_RECIPES';
export const CLEAR_EDAMAM_RECIPES = 'CLEAR_EDAMAM_RECIPES';
export const RECEIVE_EDAMAM_SINGLE = 'RECEIVE_EDAMAM_SINGLE';
export const REQUEST_EDAMAM_SINGLE = 'REQUEST_EDAMAM_SINGLE';
export const UPDATE_RECIPE_FILTER = 'UPDATE_RECIPE_FILTER';
