import {
	COPY_MEAL_EDITING_INGREDIENTS,
	DELETE_MEAL_EDIT_INGREDIENT,
	EDITING_MEAL_ADD_INGREDIENT,
	EDITING_MEAL_UPDATE_INGREDIENT,
	SET_EDIT_INGREDIENT_OBJECT
} from '../actions/types';

const INITIAL_STATE = {
	editingIngredientArr: [],
	tempIngredientObject: {}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case COPY_MEAL_EDITING_INGREDIENTS:
			return { ...INITIAL_STATE, editingIngredientArr: [...action.payload] };
		case DELETE_MEAL_EDIT_INGREDIENT:
			return { ...state, editingIngredientArr: [...action.payload] };
		case EDITING_MEAL_ADD_INGREDIENT:
			return {
				...state,
				editingIngredientArr: [...state.editingIngredientArr, ...action.payload]
			};
		case EDITING_MEAL_UPDATE_INGREDIENT:
			return { ...state, editingIngredientArr: [...action.payload] };
		case SET_EDIT_INGREDIENT_OBJECT:
			return { ...state, tempIngredientObject: action.payload };
		default:
			return state;
	}
};
