//@flow
import React from 'react';
import PropTypes from 'prop-types';
import {
	TextInput,
	TouchableOpacity,
	View,
	StyleSheet,
	Dimensions,
	Platform
} from 'react-native';
import { Text } from './';
import { Colors, i18n } from 'Theme';
const { height: HEIGHT } = Dimensions.get('window');

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

export default class Prompt extends React.Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		visible: PropTypes.bool,
		defaultValue: PropTypes.string,
		placeholder: PropTypes.string,
		onCancel: PropTypes.func.isRequired,
		cancelText: PropTypes.string,
		onSubmit: PropTypes.func.isRequired,
		submitText: PropTypes.string,
		onChangeText: PropTypes.func.isRequired,
		promptStyle: PropTypes.object,
		titleStyle: PropTypes.object,
		buttonStyle: PropTypes.object,
		buttonTextStyle: PropTypes.object,
		submitButtonStyle: PropTypes.object,
		submitButtonTextStyle: PropTypes.object,
		cancelButtonStyle: PropTypes.object,
		cancelButtonTextStyle: PropTypes.object,
		inputStyle: PropTypes.object,
		textInputProps: PropTypes.object,
		theme: PropTypes.object.isRequired
	};

	static defaultProps = {
		visible: false,
		defaultValue: '',
		cancelText: i18n.t('cancel'),
		submitText: 'OK',
		promptStyle: {},
		titleStyle: {},
		buttonStyle: {},
		buttonTextStyle: {},
		submitButtonStyle: {},
		submitButtonTextStyle: {},
		cancelButtonStyle: {},
		cancelButtonTextStyle: {},
		inputStyle: {},
		onChangeText: () => {}
	};

	state = {
		value: '',
		visible: false
	};

	componentDidMount() {
		this.setState({ value: this.props.defaultValue });
	}

	componentWillReceiveProps(nextProps) {
		const { visible, defaultValue } = nextProps;
		this.setState({ visible, value: defaultValue });
	}

	_onChangeText = (value) => {
		this.setState({ value });
		this.props.onChangeText(value);
	};

	_onSubmitPress = () => {
		const { value } = this.state;
		this.props.onSubmit(value);
	};

	_onCancelPress = () => {
		this.props.onCancel();
	};

	close = () => {
		this.setState({ visible: false, value: '' });
	};

	_renderCustomButtons = () => {
		const { theme } = this.props;
		const diagloStyle =
			Platform.OS === 'web' ? styles.dialogActionWeb : styles.dialogAction;
		return (
			<View style={[styles.dialogFooter, { borderColor: theme.border }]}>
				{this.props.customButtons.map((item, index) => (
					<TouchableOpacity
						key={item.text}
						onPress={item.onPress}
						style={{ flex: 1 }}>
						<View
							style={[
								diagloStyle,
								{
									borderLeftColor: theme.border,
									borderLeftWidth: index !== 0 ? 1 : 0
								}
							]}>
							<Text
								style={[
									styles.dialogActionText,
									this.props.customButtonTextStyle,
									{ color: theme.themeAccent }
								]}>
								{item.text}
							</Text>
						</View>
					</TouchableOpacity>
				))}
			</View>
		);
	};

	_renderDefaultButtons = () => {
		const {
			cancelText,
			submitText,
			buttonStyle,
			buttonTextStyle,
			submitButtonStyle,
			submitButtonTextStyle,
			cancelButtonStyle,
			cancelButtonTextStyle,
			theme
		} = this.props;
		const dialogStyle =
			Platform.OS === 'web' ? styles.dialogActionWeb : styles.dialogAction;
		return (
			<View style={[styles.dialogFooter, { borderColor: theme.border }]}>
				<TouchableOpacity
					onPress={this._onCancelPress}
					style={{ flex: 1, backgroundColor: theme.base }}>
					<View style={[dialogStyle, buttonStyle, cancelButtonStyle]}>
						<Text
							style={[
								styles.dialogActionText,
								buttonTextStyle,
								cancelButtonTextStyle,
								{ color: theme.themeAccent }
							]}>
							{cancelText}
						</Text>
					</View>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={this._onSubmitPress}
					style={{ flex: 1, backgroundColor: theme.base }}>
					<View
						style={[
							{
								borderLeftColor: theme.border,
								borderLeftWidth: 1
							},
							dialogStyle,
							buttonStyle,
							submitButtonStyle
						]}>
						<Text
							style={[
								styles.dialogActionText,
								buttonTextStyle,
								submitButtonTextStyle,
								{ color: theme.themeAccent }
							]}>
							{submitText}
						</Text>
					</View>
				</TouchableOpacity>
			</View>
		);
	};

	_renderDialog = () => {
		const {
			title,
			placeholder,
			defaultValue,
			promptStyle,
			titleStyle,
			inputStyle,
			theme
		} = this.props;
		return (
			<View style={styles.dialog} key="prompt">
				<View style={styles.dialogOverlay} />
				<View
					style={[
						styles.dialogContent,
						{ borderColor: theme.border },
						promptStyle,
						{ backgroundColor: theme.baseBG }
					]}>
					<View
						style={[
							styles.dialogTitle,
							{ borderColor: theme.border, backgroundColor: theme.grey }
						]}>
						<Text
							style={[
								styles.dialogTitleText,
								titleStyle,
								{ color: theme.darkFont }
							]}>
							{title}
						</Text>
					</View>
					<View style={styles.dialogBody}>
						{!this.props.children && (
							<TextInput
								style={[
									styles.dialogInput,
									inputStyle,
									{ color: theme.darkFont, borderColor: theme.placeholderGray }
								]}
								defaultValue={defaultValue}
								onChangeText={this._onChangeText}
								placeholder={placeholder}
								autoFocus={true}
								underlineColorAndroid="rgba(0,0,0,0)"
								onSubmitEditing={this._onSubmitPress}
								{...this.props.textInputProps}
							/>
						)}
						{this.props.children && this.props.children}
					</View>
					{this.props.customButtons && this._renderCustomButtons()}
					{!this.props.customButtons && this._renderDefaultButtons()}
				</View>
			</View>
		);
	};

	render() {
		return (
			<Modal.ReactNativeModal
				style={styles.modal}
				isVisible={this.props.visible}
				onBackButtonPress={this.props.onClosed}
				onSwipeComplete={this.props.onClosed}
				hasBackdrop={false}
				animationInTiming={600}
				animationOutTiming={600}
				animationIn={'fadeIn'}
				animationOut={'fadeOut'}>
				{this._renderDialog()}
			</Modal.ReactNativeModal>
		);
	}
}

const styles = StyleSheet.create({
	dialog: {
		flex: 1,
		alignItems: 'center'
	},
	dialogOverlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.0)',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0
	},
	dialogContent: {
		elevation: 5,
		marginTop: HEIGHT / 8,
		width: 300,
		borderRadius: 5,
		borderWidth: 1,
		overflow: 'hidden'
	},
	dialogTitle: {
		borderBottomWidth: 1,
		paddingVertical: 10,
		paddingHorizontal: 15
	},
	dialogTitleText: {
		fontSize: 18,
		textAlign: 'center',
		fontFamily: 'Comfortaa'
	},
	dialogBody: {},
	dialogInput: {
		height: 50,
		fontSize: 13,
		textAlign: 'center'
	},
	dialogFooter: {
		borderTopWidth: 1,
		flexDirection: 'row'
	},
	dialogAction: {
		flex: 1,
		padding: 25,
		justifyContent: 'center'
	},
	dialogActionWeb: {
		flex: 1,
		padding: 10,
		justifyContent: 'center'
	},
	dialogActionText: {
		fontSize: 18,
		textAlign: 'center',
		height: 30
	}
});
