import React from 'react';
import {
	View,
	Modal,
	ScrollView,
	TouchableOpacity,
	StyleSheet,
	Dimensions
} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text } from './';
const { height, width } = Dimensions.get('window');

const PADDING = 9;
const BORDER_RADIUS = 5;
const FONT_SIZE = 18;
const HIGHLIGHT_COLOR = 'rgba(0,118,255,0.9)';
const OPTION_CONTAINER_HEIGHT = 400;

export default class Selectbox extends React.Component {
	static defaultProps = {
		items: [],
		onChange: () => {},
		cancelLabel: 'cancel',
		animationType: 'slide',
		transparent: false
	};

	constructor() {
		super();
		this.state = {
			modalVisible: false
		};
	}

	_onChange = (item) => {
		this.props.onChange(item);
		this._close();
	};

	_close = () => {
		this.setState({
			modalVisible: false
		});
	};

	_open = () => {
		this.setState({
			modalVisible: true
		});
	};

	_renderSection = (section) => {
		return (
			<View key={section.key} style={styles.sectionStyle}>
				<Text style={styles.sectionLabelStyle}>{section.label + ''}</Text>
			</View>
		);
	};

	_renderOption = (option) => {
		return (
			<TouchableOpacity key={option.key} onPress={() => this._onChange(option)}>
				<View style={styles.optionStyle}>
					<Text style={[styles.optionLabelStyle, this.props.optionLabelStyle]}>
						{option.label + ''}
					</Text>
				</View>
			</TouchableOpacity>
		);
	};

	_renderOptionList = () => {
		const options = this.props.items.map((item) => {
			if (item.section) {
				return this._renderSection(item);
			}
			return this._renderOption(item);
		});

		return (
			<View style={styles.overlayStyle}>
				{this.props.promptLabel ? (
					<View style={styles.promptContainer}>
						<View style={styles.promptStyle}>
							<Text style={styles.promptLabelStyle}>
								{this.props.promptLabel + ''}
							</Text>
						</View>
					</View>
				) : null}
				<View style={styles.optionContainer}>
					<ScrollView keyboardShouldPersistTaps="always">
						<View style={styles.optionContainerInnerContainer}>{options}</View>
					</ScrollView>
				</View>
				<View style={styles.cancelContainer}>
					<TouchableOpacity onPress={this._close}>
						<View style={styles.cancelStyle}>
							<Text style={styles.cancelLabelStyle}>
								{this.props.cancelLabel + ''}
							</Text>
						</View>
					</TouchableOpacity>
				</View>
			</View>
		);
	};

	_renderChildren() {
		if (this.props.children) {
			return this.props.children;
		}

		return (
			<View style={styles.selectStyle}>
				<View style={styles.selectContainerStyle}>
					<Text style={[styles.selectLabelStyle, this.props.selectLabelStyle]}>
						{this.props.selectedItem.label + ''}
					</Text>
					<Icon name="chevron-down" />
				</View>
			</View>
		);
	}

	render() {
		const modal = (
			<Modal
				transparent={this.props.transparent}
				ref="modal"
				visible={this.state.modalVisible}
				onRequestClose={this._close}
				animationType={this.props.animationType}>
				{this._renderOptionList()}
			</Modal>
		);

		return (
			<View style={this.props.style}>
				{modal}
				<TouchableOpacity onPress={this._open}>
					{this._renderChildren()}
				</TouchableOpacity>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	overlayStyle: {
		width,
		height,
		backgroundColor: 'rgba(0,0,0,0.7)'
	},

	optionContainer: {
		borderRadius: BORDER_RADIUS,
		width: width * 0.8,
		height: OPTION_CONTAINER_HEIGHT,
		backgroundColor: '#FFF',
		left: width * 0.1,
		top: (height - OPTION_CONTAINER_HEIGHT) / 2
	},

	optionContainerInnerContainer: {
		paddingHorizontal: 10
	},

	promptContainer: {
		left: width * 0.1,
		top: (height - OPTION_CONTAINER_HEIGHT) / 2 - 10
	},

	cancelContainer: {
		left: width * 0.1,
		top: (height - OPTION_CONTAINER_HEIGHT) / 2 + 10
	},

	selectStyle: {
		flex: 1,
		padding: 8
	},

	selectContainerStyle: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center'
	},

	selectLabelStyle: {
		flex: 1,
		color: '#333',
		fontSize: FONT_SIZE
	},

	promptStyle: {
		borderRadius: BORDER_RADIUS,
		width: width * 0.8,
		backgroundColor: 'rgba(255,255,255,0.8)',
		padding: PADDING
	},

	promptLabelStyle: {
		textAlign: 'center',
		color: '#333',
		fontSize: FONT_SIZE
	},

	cancelStyle: {
		borderRadius: BORDER_RADIUS,
		width: width * 0.8,
		backgroundColor: 'rgba(255,255,255,0.8)',
		padding: PADDING
	},

	cancelLabelStyle: {
		textAlign: 'center',
		color: '#333',
		fontSize: FONT_SIZE
	},

	optionStyle: {
		padding: PADDING,
		borderBottomWidth: 1,
		borderBottomColor: '#ccc'
	},

	optionLabelStyle: {
		textAlign: 'center',
		fontSize: FONT_SIZE,
		color: HIGHLIGHT_COLOR
	},

	sectionStyle: {
		padding: PADDING * 2,
		borderBottomWidth: 1,
		borderBottomColor: '#ccc'
	},

	sectionLabelStyle: {
		textAlign: 'center',
		fontSize: FONT_SIZE
	}
});
