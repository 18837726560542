import { StyleSheet, Dimensions } from 'react-native';
import { Colors } from 'Theme';
import { isBelowAndroid5 } from 'Utilities/PlatformHelper';
const { height } = Dimensions.get('window');

export default StyleSheet.create({
	filter: {
		backgroundColor: Colors.grey,
		paddingLeft: 15,
		elevation: 1,
		borderWidth: isBelowAndroid5() ? 1 : 0,
		borderColor: isBelowAndroid5() ? Colors.border : '#FFF',
		shadowColor: '#333',
		shadowOffset: {
			width: 0,
			height: 1
		},
		shadowRadius: 1,
		shadowOpacity: 0.5
	},
	mealTimePicker: {
		padding: 15
	},
	iosFilter: {
		flex: 1,
		padding: 15
	},
	iosFilterText: {
		color: Colors.themeAccent
	},
	customDateContainer: {
		borderWidth: 1,
		padding: 15,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	dateInput: {
		borderWidth: 1,
		borderColor: Colors.border
	},
	pieLegendContainer: {
		flex: 1,
		flexDirection: 'row'
	},
	legendContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row'
	},
	pieContainer: {
		flex: 0.75,
		justifyContent: 'center',
		alignItems: 'center'
	},
	logMealContainer: {
		flex: 1,
		marginTop: 15,
		marginBottom: 15,
		marginLeft: 10,
		marginRight: 10
	},
	headerContainer: {
		backgroundColor: Colors.grey,
		paddingVertical: 10,
		borderWidth: 1,
		borderColor: Colors.border
	},
	headingText: {
		fontSize: 16,
		paddingLeft: 15
	},
	chartContainer: {
		flex: 1,
		borderWidth: 1,
		borderColor: Colors.border,
		borderTopWidth: 0
	},
	chartErrorContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		padding: 20
	}
});
