// leave off @2x/@3x
const images = {
	map: require("../../assets/images/map.jpg"),
	pyramid: require("../../assets/images/pyramid.jpg"),
	logo: require("../../assets/images/icon.png"),
	iconCutOut: require("../../assets/images/iconCutOut.png"),
	edamam: require("../../assets/images/edamam.png"),
	gplay: require("../../assets/images/gplay.png"),
	ios: require("../../assets/images/ios.png"),
	bodyFatEstimate: require("../../assets/images/Calculator/bodyfatestimate.jpg"),
	fatsecret: require("../../assets/images/fatsecret.png"),
	appleHealthKit: require("../../assets/images/fitnesstrackers/appleHealthKit.png"),
	gFit: require("../../assets/images/fitnesstrackers/gFit.png"),
	fitBit: require("../../assets/images/fitnesstrackers/fitBit.png"),
	login: require("../../assets/images/login.jpg"),

	seventeen: require("../../assets/images/OtherDiets/17day.png"),
	myfodmaps: require("../../assets/images/OtherDiets/myfodmaps.jpg"),
	alkaline: require("../../assets/images/OtherDiets/alkaline.png"),
	dash: require("../../assets/images/OtherDiets/dash.png"),
	fertility: require("../../assets/images/OtherDiets/fertility.png"),
	glutenFree: require("../../assets/images/OtherDiets/glutenfree.png"),
	hcg: require("../../assets/images/OtherDiets/hcg.png"),
	hmr: require("../../assets/images/OtherDiets/hmr.png"),
	macrobiotic: require("../../assets/images/OtherDiets/macrobiotic.png"),
	mediterranean: require("../../assets/images/OtherDiets/mediterranean.png"),
	paleo: require("../../assets/images/OtherDiets/paleo.png"),
	rawFood: require("../../assets/images/OtherDiets/rawfood.png"),
	keto: require("../../assets/images/OtherDiets/keto.png"),
	glycemic: require("../../assets/images/OtherDiets/glycemic.png"),
	peanut: require("../../assets/images/OtherDiets/peanut.png"),
	pescatarian: require("../../assets/images/OtherDiets/pescatarian.png"),
	kidney: require("../../assets/images/OtherDiets/kidney.png"),
	kosher: require("../../assets/images/OtherDiets/kosher.png"),
	immune: require("../../assets/images/OtherDiets/immune.png"),
	cocktail: require("../../assets/images/OtherDiets/cocktail.png"),
	redmeat: require("../../assets/images/OtherDiets/redmeat.png"),
	dairy: require("../../assets/images/OtherDiets/dairy.png"),
	caribbean: require("../../assets/images/OtherDiets/caribbean.png"),
	chinese: require("../../assets/images/OtherDiets/chinese.png"),
	easterneurope: require("../../assets/images/OtherDiets/easterneurope.png"),
	french: require("../../assets/images/OtherDiets/french.png"),
	indian: require("../../assets/images/OtherDiets/indian.png"),

	purchase1a: require("../../assets/images/Purchase/1a.jpg"),
	purchase1b: require("../../assets/images/Purchase/1b.jpg"),
	purchase2a: require("../../assets/images/Purchase/2a.jpg"),
	purchase2b: require("../../assets/images/Purchase/2b.jpg"),
	purchase3a: require("../../assets/images/Purchase/3a.jpg"),
	purchase3b: require("../../assets/images/Purchase/3b.jpg"),
	purchase3c: require("../../assets/images/Purchase/3c.jpg"),
	purchase4: require("../../assets/images/Purchase/4.jpg"),
};

export default images;
