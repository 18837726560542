//@flow
import React, { useState, useEffect } from 'react';
import { ScrollView, View, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { resetItem } from 'Redux/actions';
import {
	Header,
	ServingSelector,
	MacroGrid,
	Ingredients,
	Nutrients,
	Collapsible,
	ClickableHeader
} from 'Components/common';
import { SingleViewButtons } from './components';
import { ErrorText, Button } from 'Components/common';
import { Feature } from 'Components/purchase/components';
import Picker from 'react-native-picker';
import {
	servingMealCloneSelector,
	ingredientsFormatSelector
} from './selectors';
import { i18n } from 'Theme';
import { defaultNutrients } from 'Redux/selectors';

export const CustomMealTabSingleClone = ({ route, navigation }) => {
	const dispatch = useDispatch();
	const {
		savedmealsv3 = {},
		settings: { units },
		premium
	} = useSelector((state) => state.firebase.profile);
	const { mealTime, date } = useSelector((state) => state.log);
	const { theme } = useSelector((state) => state.startup);
	const item = savedmealsv3[route.params.foodID];
	const servingData = useSelector((state) =>
		servingMealCloneSelector(state, route)
	);
	const ingredients = useSelector((state) =>
		ingredientsFormatSelector(state, route)
	);
	const [viewIngredients, toggleIngredients] = useState(false);
	const [viewNutrients, toggleNutrients] = useState(false);

	useEffect(() => {
		return () => {
			if (Platform.OS !== 'web') {
				Picker.hide();
			}
			dispatch(resetItem('mealClone'));
		};
	}, []);

	const { name } = item;

	if (!item) {
		return <View />;
	}

	if (name) {
		return (
			<ScrollView style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<Header title={item.name} bgColor={theme.base} />
				<ServingSelector
					reducerType={'mealClone'}
					labelArray={[
						item.customServingName ? item.customServingName : i18n.t('servings')
					]}
					theme={theme}
				/>
				<MacroGrid
					{...servingData.servingMacros}
					carbUnits={units.carbUnits}
					theme={theme}
				/>
				<Ingredients
					ingredients={ingredients}
					viewMore={viewIngredients}
					toggle={() => toggleIngredients(!viewIngredients)}
					theme={theme}
				/>
				<ClickableHeader
					leftText="Nutrients:"
					noMargin
					onPress={() => toggleNutrients(!viewNutrients)}
					rightIconName={!viewNutrients ? 'arrow-right' : 'arrow-down'}
					style={{ backgroundColor: theme.base }}
					iconColor={theme.darkFont}
				/>
				<Collapsible collapsed={!viewNutrients}>
					{!premium ? (
						<View>
							<Feature
								icon="check"
								title="Track Nutrients"
								body="With Premium, follow more than just your macros. Keep track of: Cholesterol, Sodium, Calcium, Magnesium, Potassium, Iron, Zinc, Phosphorus, Folate, Thiamin (B1), Riboflavin (B2), Niacin (B3), Vitamin B6, Vitamin B12, Vitamin A,C,D,E,K, "
							/>
							<Button
								onPress={() => navigation.navigate('Purchase')}
								style={[{ backgroundColor: theme.teal }]}>
								{i18n.t('premium')}
							</Button>
						</View>
					) : (
						<Nutrients
							viewMore={viewNutrients}
							toggle={() => toggleNutrients(!viewNutrients)}
							theme={theme}
							nutrients={{
								...defaultNutrients,
								...servingData.servingMacros.nutrientsV2
							}}
						/>
					)}
				</Collapsible>
				<SingleViewButtons
					servingMacros={servingData.servingMacros}
					item={item}
					mealTime={mealTime}
					date={date}
					params={route.params}
					theme={theme}
				/>
			</ScrollView>
		);
	}

	return (
		<ErrorText showError={!item}>{i18n.t('ingredientNotFound')}</ErrorText>
	);
};
