//@flow
import React from 'react';
import { Animated } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Ionicons } from '@expo/vector-icons';
import {
	NavOptionsHeader,
	NavOptionsTransparent,
	NavOptionsBottomTab
} from '../NavOptionsHeader';
import { i18n } from 'Theme';
import { RecipeScreen } from 'Components/recipes/RecipeScreen';
import { RecipeSingleScreen } from 'Components/recipes/RecipeSingleScreen';
import { RecipeFeedScreen } from 'Components/reddit/RecipeFeedScreen';
import { RecipeFeedSingleScreen } from 'Components/reddit/RecipeFeedSingleScreen';
import { HeaderIconMultiButtons } from 'Components/common';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const RecipeRedditStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="RecipesReddit"
				component={RecipeFeedScreen}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;

						const progress = Animated.add(
							scene.progress.current,
							scene.progress.next || 0
						);

						const opacity = progress.interpolate({
							inputRange: [0, 1, 2],
							outputRange: [0, 1, 0]
						});
						return (
							<Animated.View style={{ opacity }}>
								<NavOptionsHeader
									navigation={navigation}
									title={'r/Mediterraneandiet'}
								/>
							</Animated.View>
						);
					}
				}}
			/>
			<Stack.Screen
				name="RecipesRedditSingle"
				component={RecipeFeedSingleScreen}
				options={({ navigation }) => {
					return NavOptionsTransparent(navigation, '');
				}}
			/>
		</Stack.Navigator>
	);
};

const RecipeEdamamStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="RecipesEdamam"
				component={RecipeScreen}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;

						const progress = Animated.add(
							scene.progress.current,
							scene.progress.next || 0
						);

						const opacity = progress.interpolate({
							inputRange: [0, 1, 2],
							outputRange: [0, 1, 0]
						});
						return (
							<Animated.View style={{ opacity }}>
								<NavOptionsHeader
									navigation={navigation}
									title={i18n.t('recipes')}
									right={
										<HeaderIconMultiButtons btnArray={params.headerBtns} />
									}
								/>
							</Animated.View>
						);
					}
				}}
			/>
			<Stack.Screen
				name="RecipesEdamamSingle"
				component={RecipeSingleScreen}
				options={({ navigation }) => {
					return NavOptionsTransparent(navigation, '');
				}}
			/>
		</Stack.Navigator>
	);
};

const RecipeTabs = () => {
	return (
		<Tab.Navigator
			tabBarPosition={'top'}
			tabBar={(props) => <NavOptionsBottomTab {...props} />}>
			<Tab.Screen
				name="Edamam"
				component={RecipeEdamamStack}
				options={{
					tabBarLabel: i18n.t('recipes'),
					tabBarIcon: ({ focused, color, size }) => (
						<Ionicons name={'ios-list'} size={size} color={color} />
					)
				}}
			/>
			<Tab.Screen
				name="Reddit"
				component={RecipeRedditStack}
				options={{
					tabBarLabel: 'r/mediterraneandiet',
					tabBarIcon: ({ focused, color, size }) => (
						<Ionicons name={'logo-reddit'} size={size} color={color} />
					)
				}}
			/>
		</Tab.Navigator>
	);
};

export default RecipeTabs;
