const tintColor = '#2f95dc';

const size = {
  h1: 38,
  h2: 34,
  h3: 30,
  h4: 26,
  h5: 20,
  h6: 19,
  input: 18,
  regular: 17,
  medium: 14,
  mediumer: 13,
  small: 12,
  tiny: 8.5,
};

const style = {
  h1: {
    fontFamily: 'Comfortaa',
    fontSize: size.h1,
  },
  h2: {
    fontFamily: 'Comfortaa',
    fontSize: size.h2,
  },
  h3: {
    fontFamily: 'Comfortaa',
    fontSize: size.h3,
  },
  h4: {
    fontFamily: 'Comfortaa',
    fontSize: size.h4,
  },
  h5: {
    fontFamily: 'Comfortaa',
    fontSize: size.h5,
  },
  h6: {
    fontFamily: 'Comfortaa',
    fontSize: size.h6,
  },
  normal: {
    fontFamily: 'Comfortaa',
    fontSize: size.regular,
  },
  description: {
    fontFamily: 'Comfortaa',
    fontSize: size.medium,
  },
};

export default {
  size,
  style,
};
