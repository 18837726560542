//@flow
import React from 'react';
import { View, Platform, Linking, StyleSheet } from 'react-native';
import { Text } from 'Components/common';
import { SettingsLink } from './';
import { i18n } from 'Theme';

type Props = {
	onFeedback: Function,
	logOut: Function,
	auth: Object,
	onAnon: Function,
	deleteAccount: Function,
	restoreAdFree: Function,
	theme: Object
};

export const Account = (props: Props) => {
	const {
		onFeedback,
		logOut,
		auth: { uid, isAnonymous },
		onAnon,
		deleteAccount,
		restoreAdFree,
		theme
	} = props;
	return (
		<View style={styles.otherContainer}>
			<Text
				style={[
					styles.otherHeader,
					{ borderTopColor: theme.border, backgroundColor: theme.grey }
				]}>
				{i18n.t('account')}
			</Text>
			<View style={[styles.otherItem, { borderTopColor: theme.border }]}>
				<Text style={styles.otherItemLabel}>ID: {uid}</Text>
			</View>
			{isAnonymous && (
				<SettingsLink
					onPress={onAnon}
					text={i18n.t('convertAccount')}
					iconColor={theme.darkFont}
					borderColor={theme.border}
				/>
			)}
			{Platform.OS !== 'web' && (<SettingsLink
				onPress={restoreAdFree}
				text={i18n.t('restoreAdFree')}
				iconColor={theme.darkFont}
				borderColor={theme.border}
			/>)}
			{Platform.OS === 'web' ? (
				<SettingsLink
					onPress={() =>
						Linking.openURL('http://mailto:prestigeworldwide.app@gmail.com')
					}
					text={`${i18n.t('feedback')}: prestigeworldwide.app@gmail.com`}
					iconColor={theme.darkFont}
					borderColor={theme.border}
				/>
			) : (
				<SettingsLink
					onPress={onFeedback}
					text={i18n.t('feedback')}
					iconColor={theme.darkFont}
					borderColor={theme.border}
				/>
			)}
			{/*<SettingsLink
				onPress={() =>
					Linking.openURL('http://myketotracker.com/feature-request/')
				}
				text={i18n.t('featureRequest')}
				iconColor={theme.darkFont}
				borderColor={theme.border}
			/>*/}
			<SettingsLink
				onPress={logOut}
				text={i18n.t('logout')}
				iconColor={theme.darkFont}
				borderColor={theme.border}
			/>
			<SettingsLink
				onPress={deleteAccount}
				text={i18n.t('deleteAccount')}
				textStyle={{ color: theme.red }}
				iconColor={theme.darkFont}
				borderColor={theme.border}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	//Settings Main------------------------
	otherContainer: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'stretch'
	},
	otherHeader: {
		flex: 1,
		fontSize: 15,
		paddingTop: 12,
		paddingBottom: 12,
		paddingLeft: 15,
		borderTopWidth: 1
	},
	otherItem: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingTop: 16,
		paddingLeft: 15,
		paddingBottom: 16,
		borderTopWidth: 1
	},
	otherItemLabel: {
		fontSize: 14
	}
});
