//@flow
import Toast from 'react-native-root-toast';
import { WebCompatAlert } from 'Components/common';
import { randomString } from 'Utilities/FirebaseHelper';
import { servingUpdate } from 'Utilities/MacrosUtil';
import * as NavigationService from 'Navigation/NavigationService';
import { incrementTrackerInterAdCount } from 'Redux/actions';
import { ADDED_MACROS_TO_LOG } from 'Components/tracker/log/actions/types';
import { i18n } from 'Theme';

//=====================================CustomMealTab.js=========================================================

//Creates the Empty Saved Food in Database, then redirects to Single Meal View
export const createMeal = (
	createMealName,
	logDate,
	mealTime,
	manage,
	callback
) => (dispatch, getState, getFirebase) => {
	let error = false;
	if (createMealName === '' || createMealName.trim().length === 0) {
		error = true;
		return WebCompatAlert(i18n.t('nameEmpty'), i18n.t('pleaseEnterName'));
	}
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const route = manage ? 'ManageMealSingle' : 'CustomMealSingle';
	let customFoodName = createMealName.trim();
	const foodId = randomString(14);
	!error &&
		firebase
			.update(`userdata/${uid}/savedmealsv3/${foodId}`, {
				name: customFoodName,
				foods: '',
				suggestedServingSize: '1',
				customServingName: i18n.t('servings'),
				favorite: false,
				id: foodId,
				type: 'meal',
				fromRecipe: false
			})
			.then(() => {
				callback();
				Toast.show(`${customFoodName} ${i18n.t('created')}`, {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
				NavigationService.navigate(route, {
					foodID: foodId,
					mealTime,
					justCreated: true
				});
			});
};

//MultiAdd Function for Meal Tab
export const multiAddMeals = (selected, logDate, mealTime, callBack) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { premium } = state.firebase.profile;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let counter = selected.length;
	const finalCall = () => {
		callBack();
		Toast.show(i18n.t('mealsAdded'), {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
		NavigationService.navigate('LogScreen', {});
	};
	let updates = {};
	selected.forEach((element) => {
		const macroHolder = servingUpdate(element, '1')
		const uniqid = randomString(14);
		const postData = {
			name: element.customServingName
				? `${element.name} x 1 ${element.customServingName}`
				: `${element.name} x 1 ${i18n.t('servings')}`,
			calories: +macroHolder.calories,
			fats: +macroHolder.fats,
			carbs: +macroHolder.carbs,
			proteins: +macroHolder.proteins,
			totalcarbs: +macroHolder.totalcarbs,
			fiber: +macroHolder.fiber,
			nutrientsV2: macroHolder.nutrientsV2,
			random: uniqid,
			id: element.id,
			type: element.type
		};

		updates[`trackerdate/${logDate}/${mealTime}/${uniqid}`] = postData;
		updates[`recentlyused/${element.id}`] = {
			name: element.name,
			id: element.id,
			type: element.type
		};
		counter -= 1;
		if (counter === 0) {
			ref.update(updates).then(() => {
				dispatch({ type: ADDED_MACROS_TO_LOG, payload: mealTime });
				if (!premium) dispatch(incrementTrackerInterAdCount());
				return finalCall();
			});
		}
	});
};

//=================================================CustomMealTabSingle.js============================================

//Saves the Edits of a meal on the Single Edit Meal Page
export const saveEditSingleMeal = (
	newName,
	ogMeal,
	foods,
	suggestedServingSize,
	customServingName,
	callback
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	let nameChange = false;
	const { favorites = {}, recentlyused = {} } = state.firebase.profile;
	let customFoodName = ogMeal.name;
	if (newName !== ogMeal.name) {
		customFoodName = newName.trim();
		nameChange = true;
	}
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};
	updates[`savedmealsv3/${ogMeal.id}`] = {
		name: customFoodName,
		foods: foods,
		suggestedServingSize: suggestedServingSize,
		customServingName: customServingName,
		id: ogMeal.id,
		type: ogMeal.type,
		favorite: typeof ogMeal.favorite !== 'undefined' ? ogMeal.favorite : false
	};

	if (nameChange === true) {
		//Name changed so got to do this tedious bs; need to also update /favorites node if its a favorited item, and also /recentlyused node if it was present there too.

		if (favorites.hasOwnProperty(ogMeal.id)) {
			updates[`favorites/${ogMeal.id}`] = {
				name: customFoodName,
				id: ogMeal.id,
				type: ogMeal.type
			};
		}

		if (recentlyused.hasOwnProperty(ogMeal.id)) {
			updates[`recentlyused/${ogMeal.id}`] = {
				name: customFoodName,
				id: ogMeal.id,
				type: ogMeal.type
			};
		}
	}

	ref.update(updates).then(() => {
		callback();
		Toast.show(`${customFoodName} ${i18n.t('updated')}`, {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
};
