//@flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { deleteOtherTracking } from '../actions';
import { Text, Gram } from 'Components/common';
import { i18n } from 'Theme';
import {
	returnCorrectUnitsString,
	returnCorrectUnitsValue,
	totalAllArrValues
} from 'Utilities';
import { OtherTrackingModal } from './Modals';
import _ from 'lodash';
import {
	getDatesOtherTracking,
	defaultOtherTrackingUnits
} from 'Redux/selectors';
import Elevations from 'react-native-elevation';

type Props = {
	theme: Object
};

export const OtherContainer = (props: Props) => {
	const { unitsArray, theme } = props;
	const dispatch = useDispatch();
	const [isModalVisible, toggleModal] = useState(false);
	const [editingName, setEditingName] = useState('Exercise');
	const [editingArr, setEditingArr] = useState([]);
	const { date } = useSelector((state) => state.log);
	const activeDateOtherTracking = useSelector((state) =>
		getDatesOtherTracking(state)
	);

	const { waterUnits, exerciseUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);

	//Preset the editing modal to have the correct items name, text (value)
	const editItem = (name, unitType, value) => {
		setEditingArr(value);
		setEditingName(name);
		toggleModal(true);
	};

	//Deletes vital entry from Firebase
	const _deleteOtherTracking = (item, name) => {
		//Find and remove entry to delete from the day's array of vital entries
		const deletedArr = editingArr.filter((obj) => {
			return obj.id !== item.id;
		});

		//Delete vital entry using entry ID from firebase, update local view with callback
		dispatch(deleteOtherTracking(item, name, () => setEditingArr(deletedArr)));
	};

	const renderItem = (item, unitPref) => {
		const name = Object.keys(item)[0]; // get string name of other tracking item
		const unitType = unitsArray[name]; // use name to get unitType (ex: water, exercise)
		const value = item[name]; // get number value of item from firebase

		//Sort arr in order from earliest to most recent entries of the current states log date to show user the latest entry
		const sortedArr = _.values(value)
			.slice()
			.sort((a, b) => {
				return a.time < b.time ? -1 : a.time > b.time ? 1 : 0;
			})
			.reverse();
		let valueText;
		if (unitType === 'water' || unitType === 'exercise') {
			//Exercise and water need to be added together and seen as a total sum rather than the latest entry.
			valueText = (
				<Text style={[styles.listLabel, { color: theme.themeAccent }]}>
					{sortedArr.length > 0
						? returnCorrectUnitsValue(
								unitType,
								unitPref,
								totalAllArrValues(sortedArr)
						  ).toFixed(1)
						: '0'}
				</Text>
			);
		} else {
			//Notes or mood just show the last entry
			valueText = (
				<Text style={[styles.listLabelNotes, { color: theme.themeAccent }]}>
					{sortedArr.length > 0
						? returnCorrectUnitsValue(unitType, unitPref, sortedArr[0].value)
						: '...'}
				</Text>
			);
		}

		return (
			<TouchableOpacity
				key={name}
				style={[styles.listItemContainer, { borderBottomColor: theme.border }]}
				onPress={() => editItem(name, unitType, sortedArr)}>
				<View style={styles.labelCtn}>
					<Text style={[styles.listLabel, { color: theme.themeAccent }]}>
						{i18n.t(name.replace(/\s+/g, ''))}
					</Text>
				</View>
				<View style={styles.valueCtn}>
					{valueText}
					<Gram
						style={{ fontSize: 9, color: theme.darkFont }}
						customText={returnCorrectUnitsString(unitType, unitPref)}
					/>
				</View>
			</TouchableOpacity>
		);
	};

	return (
		<View style={[styles.container, { backgroundColor: theme.baseBG }]}>
			<Text
				style={[
					styles.otherHeader,
					{ backgroundColor: theme.grey, borderColor: theme.border }
				]}>
				{i18n.t('other')}
			</Text>
			<View style={[styles.flatlistCtn, , { backgroundColor: theme.baseBG }]}>
				{renderItem(activeDateOtherTracking[2], exerciseUnits)}
				{renderItem(activeDateOtherTracking[1], waterUnits)}
				{renderItem(activeDateOtherTracking[0], '')}
			</View>
			<OtherTrackingModal
				isModalVisible={isModalVisible}
				onClose={() => toggleModal(false)}
				onDelete={(item, name) => _deleteOtherTracking(item, name)}
				valueArr={editingArr}
				name={editingName}
				date={date}
				unitsArray={props.unitsArray}
			/>
		</View>
	);
};

//Default unit types to use for formatting correct value based on user's unit preference (mmol/L vs mg/dL, etc)
OtherContainer.defaultProps = { unitsArray: defaultOtherTrackingUnits };

const styles = StyleSheet.create({
	container: {
		flex: 1,
		marginTop: 15
	},
	otherHeader: {
		flex: 1,
		fontSize: 15,
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 15,
		borderWidth: 1,
		borderBottomWidth: 0,
		...Elevations[1]
	},
	flatlistCtn: {
		paddingHorizontal: 20
	},
	listItemContainer: {
		flex: 1,
		flexDirection: 'row',
		borderBottomWidth: 1,
		paddingVertical: 10,
		paddingLeft: 5,
		alignItems: 'center'
	},
	labelCtn: {
		flex: 4,
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row'
	},
	listLabel: {
		alignSelf: 'center',
		paddingRight: 5,
		fontSize: 12
	},
	listLabelNotes: {
		alignSelf: 'center',
		paddingRight: 5,
		fontSize: 12,
		maxWidth: 200
	},
	valueCtn: {
		alignItems: 'center',
		flexDirection: 'row'
	},
	fullScreen: {
		margin: 0
	}
});
