//@flow
import React from "react";
import { ScrollView, View, Image } from "react-native";
import { Paragraph, Heading, Divider, BlockQuote, Text } from "../common";
import styles from "./Styles/Styles";
import { i18n, Images } from "../../constants";

export const Basics = (props) => {
  const { theme } = props;
  return (
    <ScrollView style={{ backgroundColor: theme.baseBG }}>
      <View>
        <Heading style={{ backgroundColor: theme.base }}>{i18n.t("basicsh1")}</Heading>
        <Paragraph>{i18n.t("basicsp1")}</Paragraph>
        <Divider />
        <Image resizeMode="contain" style={styles.imageStyle} source={Images.map} />
        <Paragraph>{i18n.t("basicsp2")}</Paragraph>
        <Divider />
        <Heading style={{ backgroundColor: theme.base }}>{i18n.t("basicsh2")}</Heading>
        <Paragraph>{i18n.t("basicsp3")}</Paragraph>
        <Divider />
        <Image resizeMode="contain" style={styles.imageStyle} source={Images.pyramid} />
        <Divider />
        <BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }} style={{ paddingTop: 0, alignItems: "center" }}>
          <Text style={styles.linkText} onPress={() => Linking.openURL("https://www.hsph.harvard.edu/nutritionsource/healthy-weight/diet-reviews/mediterranean-diet/")}>
            {"\n"}Source
          </Text>
          <Text style={styles.linkText} onPress={() => Linking.openURL("https://pubmed.ncbi.nlm.nih.gov/16512956/")}>
            {"\n"}Source 2
          </Text>
          <Text
            style={styles.linkText}
            onPress={() => Linking.openURL(" https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/mediterranean-diet/art-20047801")}
          >
            {"\n"}Source 3
          </Text>
        </BlockQuote>
      </View>
    </ScrollView>
  );
};
