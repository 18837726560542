//Log Home
export const CHANGE_LOG_VIEW = 'CHANGE_LOG_VIEW';
export const UPDATE_LOG_DATE = 'UPDATE_LOG_DATE';
export const SET_COPY_TIME = 'SET_COPY_TIME';
export const SET_COPY_DATE = 'SET_COPY_DATE';
export const SET_COPY_ITEMS = 'SET_COPY_ITEMS';
export const SET_MEAL_TIME = 'SET_MEAL_TIME';
export const SET_EDIT_VALUES = 'SET_EDIT_VALUES';
export const TOGGLE_LOG_MODAL = 'TOGGLE_LOG_MODAL';

//Adding and Subtracting from Log
export const ADDED_MACROS_TO_LOG = 'ADDED_MACROS_TO_LOG';
export const DELETED_MACROS_FROM_LOG = 'DELETED_MACROS_FROM_LOG';
