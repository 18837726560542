//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors, i18n } from 'Theme';
import SearchInput from 'react-native-search-filter';

type Props = {
	onChangeText: Function
};

export const SearchFilter = (props: Props) => {
	const { onChangeText, ctnStyle, inputStyle } = props;
	return (
		<View style={[styles.filterContainer, ctnStyle]}>
			<SearchInput
				style={[styles.filterTextInput, inputStyle]}
				placeholderTextColor={Colors.placeholderGray}
				placeholder={i18n.t('searchFilter')}
				onChangeText={(term) => {
					onChangeText(term);
				}}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	//SearchFilter
	filterContainer: {
		padding: 10,
		borderBottomWidth: 1,
		borderBottomColor: Colors.border
	},
	filterTextInput: {
		color: '#333',
		paddingLeft: 15,
		minHeight: 40,
		flex: 1,
		fontFamily: 'Raleway'
	}
});
