//@flow

export const enStrings = {
	//Sidebar
	appName: "MyMedi",
	foodList: "Food List",

	//Learn - Basics
	basicsh1: "What is the Mediterranean Diet?",
	basicsp1:
		"The whole diet is based on the scientific observation that the populations among Crete, Greece and southern Italy have the lowest rates of chronic disease, as well as one of the longest life expectancies. The Mediterranean diet may help you lose weight, offer cancer prevention, diabetes management, and most importantly brain & cardiovascular health.",
	basicsp2:
		'It is very similar to every other "healthy" diet, since most other ones draw their inspiration from this diet. You will eat lots of vegetables, whole-grains, legumes and nuts, fruits, fish, some poultry & low-fat dairy, tons of olive oil & herbs/spices, a few weekly eggs, and some wine (if that\'s your thing). On the other hand, you will restrict red meat, saturated fats, sweets, and processed foods.',
	basicsh2: "Why does it work?",
	basicsp3:
		"The Mediterranean diet is so good for you health because you are getting ample amounts of healthy fats (olive oil, fish, nuts), vitamins, antioxidants & fiber (fruits, veggies, herbs/spices, whole grains and legumes). Conversely you are removing foods that have been scientifically studied to increase our risk of disease like saturated fat and processed sugar. Even if you ignore the observational aspect of the Mediterranean populations' longevity, the diet is basically what medical doctors and nutritionist will recommended to you because it is nutritionally solid and does not require crazy restrictions or abnormal eating habits.",

	//Learn - Benefits
	benefitsp0:
		"The Mediterranean Diet has multiple studied benefits ranging from weight loss, to improved cardiovascular health, to prevention of Diabetes, Cancer and Alzheimer's.",
	benefitsh1: "Weight Loss",
	benefitsp1:
		"Research shows that people who adopt a strict Mediterranean diet and take regular exercise, often keep their weight under control.\n\nA 2010 study in the Diabetes, Obesity, and Metabolism Journal found after a year that dieters on a traditional Mediterranean diet lost an average of 16 pounds. Even further, those on a low-carb Mediterranean diet lost on average 22 pounds.\n\nA 2008 study in the NEJM found that dieters on a Mediterranean diet and low-carb diet both lost significantly more weight on average, than a low fat diet.\n\nA 2001 study in the International Journal of Obesity found that after 18 months on a Mediterranean diet, the men or women had lost twice as much weight as a low fat group.",
	benefitsh2: "Cardiovascular Health",
	benefitsp2:
		'A 2008 meta-analysis in BMJ found that "Greater adherence to a Mediterranean diet is associated with a significant improvement in health status, as seen by a significant reduction in mortality from cardiovascular diseases (9%).\n\nA 2010 meta-analysis published in the The American Journal of Clinical Nutrition confirmed that" in a larger number of subjects and studies, the significant and consistent protection provided by adherence to the Mediterranean diet in relation to the occurrence of major chronic degenerative diseases" including cardiovascular disease.\n\nA 2011 meta-analysis published in the Journal of the American College of Cardiology concluded that "this dietary pattern can be easily adopted by all population groups and various cultures and cost-effectively serve for primary and secondary prevention of the Metabolic Syndrome and its individual components." They also found the diet was linked to lower blood pressure, lower blood sugar, and lower triglycerides.\n\nA 2013 study in NEJM found that 30% of heart attacks, strokes and deaths from heart disease can be prevented in high-risk people if they switch to a Mediterranean diet. In fact, the study actually ended early because the results were so significantly conclusive.\n\nA 2014 study in Canadian Medical Association Journal concluded that the Mediterranean diet may reverse metabolic syndrome, a group of risk factors that raises your risk for heart disease. They also saw that they were 35% more likely than those on a low-fat diet to reverse metabolic syndrome.\n\nA 2014 study in PLOS ONE found that those on a Mediterranean diet improved their cholesterol levels, were less likely to gain weight, and also were less likely to develop metabolic syndrome.\n\nA 2015 study in Gut found that a mostly Mediterranean diet had more short chain fatty acids (or in other words,a lower risk of heart disease).',
	benefitsh3: "Diabetes Prevention",
	benefitsp3:
		'A meta-analysis published in the American Journal of Clinical Nutrition in 2013 found that "Mediterranean diets are effective in improving various markers of cardiovascular risk in people with diabetes and should be considered in the overall strategy of diabetes management."\n\nA 2014 meta-analysis in Public Health Nutrition concluded that "higher adherence to the Mediterranean diet was associated with 23% reduced risk of developing type 2 diabetes."\n\nA 2015 meta-analysis in Public Health Nutrition concluded that "greater adherence to a Mediterranean diet is associated with a significant reduction in the risk of diabetes (19 %; moderate quality evidence)."',
	benefitsh4: "Parkinson's and Alzheimer's Prevention",
	benefitsp4:
		" A 2008 meta-analysis in BMJ found that \"Greater adherence to a Mediterranean diet is associated with a significant improvement in health status, as seen by a significant reduction in incidence of Parkinson's disease and Alzheimer's disease (13%).\"\n\nA 2010 meta-analysis published in the The American Journal of Clinical Nutrition confirmed that \"in a larger number of subjects and studies, the significant and consistent protection provided by adherence to the Mediterranean diet in relation to the occurrence of major chronic degenerative diseases\" like Parkinson's and Alzheimer's.",
	benefitsh5: "Decreased Cancer Mortality",
	benefitsp5:
		'A 2008 meta-analysis in BMJ found that "Greater adherence to a Mediterranean diet is associated with a significant improvement in health status, as seen by a significant reduction in incidence of or mortality from cancer (6%)."\n\nA 2010 meta-analysis published in the The American Journal of Clinical Nutrition confirmed that "in a larger number of subjects and studies, the significant and consistent protection provided by adherence to the Mediterranean diet in relation to the occurrence of major chronic degenerative diseases" including cancer.\n\nA 2014 systematic review and meta-analysis found that a "high adherence to a Mediterranean Diet is associated with a significant reduction in the risk of overall cancer mortality (10%), colorectal cancer (14%), prostate cancer (4%) and aerodigestive cancer (56%)."',
	sideeffectsh1: "Dangers, Disadvantages, and Side-Effects",
	sideeffectsp1: 'While the Mediterranean diet doesn\'t have any "real" side-effects, there are a few potential disadvantages depending on your lifestyle.',
	sideeffectsh2: "Not intended for Weight Loss",
	sideeffectsp2:
		"If you want to start the diet because you want to lose weight, then you need to be especially careful with how many calories you consume. If you are just eating pasta soaked in olive oil all day, while technically it is allowed, you are going to consume a ton of calories with little nutrition. Make sure to have a balanced diet of vegetables, fruits, whole-grains and legumes while also watching your calories.",
	sideeffectsh3: "Required Exercise",
	sideeffectsp3:
		"A required component of the diet is daily exercise. Mediterranean populations are active people and at least get some walking in every day. It's recommended to get 2-3 hours of moderate/intense exercise each week with some additional weight training activity.",
	sideeffectsh4: "High in Gluten",
	sideeffectsp4:
		"If you have celiac disease or a gluten-sensitivity, then you may have a tough time with the diet. Whole-grains are a Mediterranean staple, and the most common forms come from wheat, although there are plenty of whole grains at your disposal that do not contain Gluten such as Quinoa, Rice, and Buckwheat.",
	sideeffectsh5: " Cooking and Prep Time",
	sideeffectsp5:
		"You will need to cook your food, since most processed and packaged foods are not recommended. This can be an inconvenience, especially if you end up making lengthy dishes. However, there are quick and easy recipes available and not everything has to be a 5 course meal. If you are the type of person who never has a free minute, you may need to adjust the diet to include more processed, but healthy packaged foods you can quickly heat up.",
	faqh1: "The diet has a lot of carbohydrates, I thought those were bad for me?",
	faqp1:
		"While the diet is high in carbohydrates from plants, these are all complex carbs that digest slowly and do not spike your blood sugar. For example, whole wheat contains a lot more fiber and nutrients than processed white wheat.\n\nAdditionally, you are consuming the carbs with healthy fats like olive oil that will slow the digestion even more. Having said that, do not think it's OK to eat a whole can of beans or package of pasta on its own. This will be way too many carbs and you need to balance them with the other healthy fats and protein that the diet recommends.",
	faqh2: "Why is exercise required?",
	faqp2:
		"When the diet was widely popularized in the 60's, the Mediterranean culture was filled with regular physical activity. It was also shown that changing the Mediterranean lifestyle to a less active one, such as a Western, was shown to negate the benefits of the diet.",
	faqh3: "What's so important about Olive Oil?",
	faqp3:
		"Olive oil is one of the main sources of fat in the Mediterranean diet. It is widely thought that a lot health benefits are attributed to the large consumption of the oil, in part due to the high amount of mono-saturated fats and antioxidants.\n\nHowever, a direct cause-and-effect between these benefits and olive oil have not yet been established. Still, it is widely accepted as a healthy oil (just don't heat it too much) and should replace harmful fats such as trans and saturated.",
	faqh4: "Wine (alcohol) is allowed and almost seems like it is encouraged. Why is that?",
	faqp4:
		"Wine is consumed in moderation in the diet, but this is definitely an optional step. Drinking small quantities of alcohol (up to one drink per day for women and one to two drinks per day for men) is associated with a decreased risk of heart disease, stroke, diabetes, metabolic syndrome and early death. However, going above those daily recommendations will actually have negative consequences for you health.",
	faqh5: "I am not genetically from the Mediterranean region, will this diet still benefit me?",
	faqp5:
		"Yes! Just as a Western diet can be harmful to non-western populations, the Mediterranean diet has shown to benefit everyone around the globe. The diet is nutritionally sound and forms the basis of good eating habits.",
	faqh6: "Do I have to completely eliminate saturated fat?",
	faqp6:
		"Not necessarily, in fact olive oil and low-fat dairy contain some amounts of saturated fat. While you should look to keep you intake low, the main source of saturated fats you want to avoid are from animals meats (like red meat).",
	faqh7: "Can vegans/vegetarians do the diet?",
	faqp7:
		"Yes, in fact most the diet is from plant foods besides the occasional fish, poultry and eggs. However you need to make sure you are getting enough Vitamin B12, Iron, and Zinc. Most people get those from animal foods, but they are poorly absorbed from plant sources (B12 is almost impossible and should be supplemented).",
	faqh8: "The diet is probably expensive though right?",
	faqp8:
		"Not really, the diet actually originated from the poorest people in the Mediterranean region. In fact, a project from the Miriam Hospital and Rhode Island Food Bank showed that a plant-based diet rich in extra-virgin olive oil is cheaper than the most economical recommendations made by the United States Department of Agriculture. This is partly due to switching Olive Oil for pricey meat.",
	faqh9: "Why is it called the Mediterranean Diet?",
	faqp9:
		"They call it that because it is generally inspired from that region on the map (Greece, Southern Italy, Spain). However, when the diet was studied and most widely publicized in the 60s, it was mainly Portugal who had the most pure form of the diet that we know today.",
	getstartedh1: "1. Speak with your doctor",
	getstartedh2: "2. Work Your Way Up",
	getstartedh3: "3. Diet Overhaul",
	getstartedh4: "4. Exercise",
	getstartedh5: "5. Eat Socially",
	getstartedp1:
		"You should always talk to your doctor before starting any new diet. They will be able to tell you if you should start a Mediterranean diet. Although unless you have a serious medical condition they will most likely give you the go ahead.",
	getstartedp2:
		"If a lot of the diet is a dramatic lifestyle change for you, then it's probably best to start slow with just some easy ones to build up your momentum. Once you get accustomed to those, you can slowly start adding in some of the harder guidelines.\n\n For starters, switch all your white breads, pastas, and cereals to whole grains. Another easy step would be to start consuming fruit in place of dessert, so change that cookie dough ice cream to a frozen yogurt topped with strawberries and kiwi. ",
	getstartedp3:
		"Slowly adding in whole-grains and fruit as dessert is a great start. However, now you really need to start incorporating as many vegetables as possible, along with the daily and weekly servings of fish, nuts, healthy fats, and dairy. Check out the Allowed Foods section for more details.",
	getstartedp4:
		"Don't forget your daily and weekly exercise activities. If you are out of shape, then talk with your physician about it during step 1. Aim for 30-60 minutes of moderate exercise at least 5 times a week. When we say moderate, this can be anything from walking to jogging, or even biking and swimming. Sports and active hobbies like yoga are usually more enjoyable then just straight up running on a treadmill.",
	getstartedp5:
		"In the Mediterranean cultures, meals are usually a time to sit down with friends and family to enjoy yourself. In fact, studies have shown that eating dinner regularly with family/friends is correlated with lower rates of obesity, eating disorders, substance abuse, teen pregnancy, and depression (especially for adolescents). ",
	tipsh1: "Grocery Shopping",
	tipsp1:
		"1) Make a shopping list. Choose which meals you're going to make during the coming week, and put all these ingredients on your list. Make sure you get breakfast and snacks. Not only will you be less likely to stray from the diet with unhealthy foods, but you will save time and money.\n\n2) Eat beforehand or at least don't shop when hungry. This is smart regardless if you follow the diet or not. Everything looks amazing when you are hungry, which makes it hard to resist those highly processed items.\n\n3) Stick to the perimeter of the store. Since there are fewer Mediterranean foods in the center aisles, focus on spending most of your shopping time in the areas of the grocery store where there's fresh-whole produce.",
	tipsh2: "Weight Control",
	tipsp2:
		"While the Mediterranean diet is very healthy for your body, that doesn't mean you get an all you can eat pass. Large portions (especially with fats), can easily put you into a calorie surplus, leading to weight gain. If your goal is weight loss, you need to be weary of your calories and portion sizes.",
	tipsh3: "Olive Oil Grades",
	tipsp3:
		"Olive oil comes in all different grades and flavors. In particular, you should just focus on Extra-Virgin and Virgin grades. Extra-Virgin has the best taste, and you should use it for drizzling on foods like salads, dips, and fish. Virgin grade still has a good taste, and you can use it for cooking such as frying, roasting, and grilling.\n\n In general, the more refined your olive oil is, the higher you can heat it without causing damage, but never go higher than 410ºF / 210ºC. Doing this will ruin the taste, as well as create harmful free radicals in the oil. Additionally, you want to store your oil in a cool dark place, since light exposure can have similar effects.",
	tipsh4: "Eat Colorful",
	tipsp4:
		"Fruits and Vegetables that are bright and colorful are full of antioxidants, and phytochemicals that help to protect your body ability by breaking down free radicals.",
	tipsh5: "Think about your meals, and how you will plan them.",
	tipsp5:
		"This will  help in buying the right foods at the grocery store, and give a framework to  follow when meal time arrives. If you know you're supposed to have salmon and  broccoli for dinner tonight, it makes it a lot easier to avoid choosing fast or processed foods.",
	tipsh6: "Fish is awesome, but choose wisely",
	tipsp6:
		"Mercury builds up in the body and can be very toxic. Aim for low-mercury sources fish and seafood such as light tuna, salmon, catfish, sardines, anchovies, mackerel (avoid King) and shrimp.",
	tipsh7: "Love Your Herbs and Spices",
	tipsp7:
		"Use fresh and dried herbs, especially oregano, rosemary, thyme, and basil, to season your dishes whenever you can. Not only will this add amazing flavors to your dishes, but will also reduce your need for added salt and sugar. Reducing your salt intake can have additional cardiovascular benefits.",
	tipsh8: "Water. Drink it.",
	tipsp8:
		"8 x 8 ounce glasses of water should be the minimum you drink everyday. Carry around a water bottle with you everywhere, and constantly sip from it. Prepare to use the bathroom more frequently as well. Once you get used to drinking so much water, you will actually realize how dehydrated you probably were before.",
	tipsh9: "Fruit is the new dessert!",
	tipsp9:
		"Try fresh melons, berries, oranges, apples, pears, plums, apricots, peaches, papaya, and mangos when they’re in season. Many of these are also great frozen and you can even makeshift your own frozen-yogurt with them.",
	tipsh10: "Don't put yourself in bad situations",
	tipsp10:
		"If you know you will be in a place or time that makes it really hard not to cheat, then plan ahead on how to avoid that. For example, if you're going to a festival or fair where all they will have is fried trans-fat filled junk-food, it would be best to eat beforehand and possibly even bring snacks with you, so you don't have to resort to cheating when you are starving. ",
	foodlisth1: "What To Eat",
	foodlistp1: "These are the types of foods and servings recommended on the diet:",
	foodlisth2: "What's Off Limits?",
	foodlistp2: "Your main focus should be on what you CAN eat from the allowed food list. However, there are some specific foods you should completely avoid or limit:",

	//foodlist
	bread: "Whole Wheat Bread",
	rolls: "Whole Wheat Rolls",
	pasta: "Whole Wheat Pasta",
	emuffin: "English Muffin",
	pita: "Pita Bread",
	bagel: "Bagel",
	cereal: "Whole Grain Cereals",
	grits: "Grits",
	oatmeal: "Oatmeal",
	brownrice: "Brown Rice",
	popcorn: "Unsalted Popcorn",
	pretzel: "Unsalted Pretzels",
	corntortilla: "Corn Tortillas",
	wheattorilla: "Whole Wheat Tortillas",
	barley: "Barley",
	bulgur: "Bulgur",
	buckwheat: "Buckwheat",
	quinoa: "Quinoa,",
	spelt: "Spelt, Triticale, Kamut",
	wildrice: "Wild Rice",
	asparagus: "Asparagus",
	artichoke: "Artichoke",
	beetgreens: "Beet Greens",
	bellpepper: "Bell Peppers",
	broccoli: "Broccoli",
	brusselsprouts: "Brussel Sprouts",
	cabbage: "Cabbage",
	carrot: "Carrots",
	cauliflower: "Cauliflower",
	celery: "Celery",
	chard: "Chard",
	corn: "Corn",
	cucumber: "Cucumbers",
	eggplant: "Eggplant",
	greenbean: "Green Beans",
	jicama: "Jicama",
	kale: "Kale",
	leeks: "Leeks",
	salad: "Lettuce & Salad Greens",
	limabeans: "Lima Beans",
	mushrooms: "Mushrooms",
	onion: "Onions",
	peas: "Peas",
	potatoe: "Potatoe",
	radish: "Radishes",
	spinach: "Spinach",
	squash: "Squash",
	sweetpotatoe: "Sweet Potatoe",
	tomatoe: "Tomatoes",
	apple: "Apples",
	apricot: "Apricot",
	banana: "Banana",
	berries: "Berries",
	cherry: "Cherries",
	dates: "Dates",
	fig: "Figs",
	grapes: "Grapes",
	grapefruit: "Grapefruit",
	kiwi: "Kiwi",
	lemon: "Lemons",
	mango: "Mango",
	melon: "Melons",
	orange: "Oranges",
	papaya: "Papaya",
	peach: "Peach",
	pear: "Pears",
	pineapple: "Pineapples",
	plum: "Plums",
	prune: "Prunes",
	raisin: "Raisins",
	strawberry: "Strawberries",
	tangerine: "Tangerines",
	buttermilk: "Buttermilk (Lowfat)",
	hardcheese: "Cheese (Hard): reduced-fat cheddar, monterey jack, parmesan",
	softcheese: "Cheese (Soft): blue, Chevre (goat cheese), feta",
	cottagecheese: "Cottage Cheese",
	milk: "Milk (Fat Free / Lowfat)",
	kefir: "Kefir",
	mozzarella: "Mozzarella",
	sourcream: "Sour Cream",
	yogurt: "Yogurt (Fat Free/ Lowfat)",
	sirloin: "Beef: flank, round or tip sirloin",
	chickenb: "Chicken or Turkey (Skinless)",
	delimeat: "Deli Meat",
	eggs: "Eggs",
	groundb: "Lean Ground Beef/Turkey/Chicken",
	wfish: "Fish fillets",
	pork: "Pork Tenderloin",
	salmon: "Salmon",
	shrimp: "Shrimp",
	almonds: "Almonds",
	beans: "Beans (dry)",
	cashews: "Cashews",
	hazelnuts: "Hazelnuts",
	lentils: "Lentils",
	nutbutter: "Nut Butter",
	peanuts: "Peanuts",
	pecans: "Pecans",
	seeds: "Seeds",
	soynuts: "Soy Nuts",
	splitpeas: "Split Peas",
	walnuts: "Walnuts",
	dressing: "Light Salad Dressing",
	mayo: "Mayonnaise (Lowfat)",
	oil: "Vegetable Oil (Canola, Olive, Safflower)",
	gelatin: "Gelatin",
	fruitice: "Fruit Ices",
	candy: "Hard Candy",
	jelly: "Jelly",
	syrup: "Maple Syrup",
	sorbet: "Sorbet",
	basil: "Basil",
	cayenne: "Cayenne",
	cilantro: "Chili powder",
	coriander: "Coriander",
	cumin: "Cumin",
	garlic: "Fresh garlic",
	garlicpowder: "Garlic powder",
	onionpowder: "Onion powder",
	parsley: "Parsley",
	pepper: "Pepper",
	rosemary: "Rosemary",
	salt: "Sea salt",
	stevia: "Stevia",
	thyme: "Thyme",
	vinegar: "Vinegar",
	search: "Search",
	searchFilter: "Search Filter",
	barcode: "Barcode",
	offline: "Offline",
	results: "Results",
	barcodeNotRecognized: "Barcode Not Recognized",
	barcodeNotRecognizedDetail: "Try searching the database or adding it as a custom food.",
	clearAllRecentlyScanned: "Clear All Recently Scanned?",
	clearAllConfirm: "This will clear your recently scanned barcodes on all synced devices",
	requestCamera: "Requesting for camera permission",
	noAccessCamera: "No access to camera",
	scanBarcode: "Scan Barcode",
	itemFound: "Item Found",
	itemNotFoundFS: "Item Not Found In Fat Secret",
	ItemNotFoundFSdesc: "Try searching the database or adding it as a custom food.",
	generic: "Generic",
	basedDiet: "% based on a 2000 calorie diet",
	all: "All",

	log: "Log",
	login: "Login",
	learn: "Learn",
	foodlist: "Food List",
	recipes: "Recipes",
	mealplan: "Meal Plan",
	otherdiet: "Other Diets",
	moreapps: "More Apps",
	macrocalculator: "Macro Calculator",
	purchase: "Purchase",
	tracker: "Tracker",
	progress: "Progress",
	review: "Review",
	manage: "Manage",
	settings: "Settings",
	preferences: "Preferences",
	customgoals: "Custom Goals",
	othertracking: "Other Tracking",
	social: "Social",
	fitnessTracker: "Fitness Trackers",
	shoppingList: "Shopping List",
	feedback: "Feedback / Support",

	//Navigation Stuff
	addFood: "Add Food",
	addIngredient: "Add Ingredient",
	mealsRecipes: "Meals / Recipes",
	editLogEntry: "Edit Log Entry",
	editMeal: "Edit Meal",
	editFood: "Edit Food",
	deleteFood: "Delete Food",
	deleteMeal: "Delete Meal",
	friends: "Friends",
	copyMeal: "Copy Meal",
	mealCopied: "Meal Copied",
	copyFood: "Copy Food",
	copyItems: "Copy Items",
	copyPlus: "Copy +",
	copyAll: "Copy All",
	copied: "Copied",
	pending: "Pending",
	manageFoods: "Manage Foods",
	manageMeals: "Manage Meals",

	//Generic
	macros: "Macros",
	ingredients: "Ingredients",
	directions: "Directions",
	nutrition: "Nutrition",
	nutrients: "Nutrients",

	//Actions
	save: "Save",
	cancel: "Cancel",
	goback: "Go Back",
	close: "Close",
	delete: "Delete",
	confirmDelete: "Are you sure you want to delete?",
	saveas: "Save As",
	add: "Add",
	confirm: "Confirm",
	addFavorite: "Add Favorite",
	removeFavorite: "Remove Favorite",
	remove: "Remove",
	removed: "Removed",
	added: "Added",
	clear: "Clear",
	send: "Send",
	required: "Required",
	macrosAddedToMeal: "Macros Added To Meal",
	ingredientUpdated: "Ingredient Updated",
	addToMeal: "Add To Meal",
	saveMeal: "Save Meal",
	addedToMeal: "Added To Meal",
	mealSaved: "Meal Saved",
	mealsAdded: "Meals Added",
	addTo: "Add To",
	addedToLog: "Added To Log",
	saveAsFood: "Save As Food",
	updateEntry: "Update Entry",
	updateIngredient: "Update Ingredient",
	otherTrackingUpdated: "Other Tracking Updated",
	nameEmpty: "Name Empty",
	pleaseEnterName: "Please Enter A Name",
	selectDate: "Select Date",
	date: "Date",
	foodNotFound: "Food Not Found",
	deleted: "Deleted",
	deleteConfirm: "Are you sure you want to delete?",
	addedToFavorites: "added to Favorites",
	favoritesCleared: "Favorites Cleared",
	removedFromFavorites: "Removed from Favorites",
	savedToFoods: "Saved To Foods",
	updated: "Updated",
	update: "Update",
	multiAdd: "Multi-Add",

	//Rate Modal
	noThanks: "No Thanks",
	giveFeedback: "Give Feedback",
	rateAppName: "Rate MyMedi",
	never: "Never",
	later: "Later",
	rate: "Rate",
	rateDesc: "If you enjoy using MyMedi, would you mind taking a second to rate it? It won't take more than a minute. Thank you for your support!",
	rateFeed: "Would you mind providing us feedback on what you would like to see in our app? Thank you!",

	//Recipes
	makes: "Makes",
	servings: "Servings",
	fat: "Fat",
	carbs: "Carbs",
	totalcarb: "Total Carbs",
	netcarb: "Net Carbs",
	protein: "Protein",
	calories: "Calories",
	zeroResults: "0 results",
	loadMore: "Load More",
	likes: "Likes",
	comments: "Comments",
	all: "All",
	dishType: "Dish Type",
	mealType: "Meal Time",
	cuisineType: "Cuisine Type",
	american: "American",
	asian: "Asian",
	british: "British",
	caribbean: "Caribbean",
	centralEurope: "Central Europe",
	chinese: "Chinese",
	easternEurope: "Eastern Europe",
	french: "French",
	indian: "Indian",
	italian: "Italian",
	japanese: "Japanese",
	kosher: "Kosher",
	mediterranean: "Mediterranean",
	mexican: "Mexican",
	middleEastern: "Middle Eastern",
	nordic: "Nordic",
	southAmerican: "South American",
	southEastAsian: "South East Asian",

	//Learn
	basics: "Basics",
	benefits: "Benefits",
	sideeffects: "Side Effects",
	myths: "Myths",
	faq: "FAQ",
	faq1: "Frequently Asked Questions",
	getstarted: "Get Started",
	fiber: "Fiber",
	tips: "Tips",
	products: "Recommended Products",

	//Macros Calculator
	calculatorDetails: "We need a few details to form your personalized Mediterranean diet plan",
	height: "Height",
	weight: "Weight",
	age: "Age",
	gender: "Gender",
	male: "Male",
	female: "Female",
	exerciseLevel: "Exercise Level",
	littleToNone: "Little to None",
	exerciseOne: "1-3 times / week",
	exerciseTwo: "3-5 times / week",
	exerciseThree: "6-7 times / week",
	exerciseFour: "2 times / day",
	goalWeight: "Goal Weight",
	faster: "Faster",
	slower: "Slower",
	weightPlan: "Your Weight Plan",
	maintain: "Maintain",
	easier: "Easier",
	mild: "Mild",
	moderate: "Moderate",
	difficult: "Difficult",
	customize: "Customize",
	dailyCalories: "Daily Calories",
	week: "Week",
	month: "Month",
	lose: "Lose",
	by: "by",
	bodyFat: "Body Fat",
	bodyFatDetails: "Your body fat % will be used to calculate your ideal protein amount based off your total muscle mass.",
	proteinDetail: "Too much protein can break ketosis, so you need just enough to maintain muscle and a bit more to build it.",
	dailyProtein: "Daily Protein",
	idealRange: "Your ideal range is",
	maintainLower: "maintain",
	build: "build",
	maintainMuscle: "Maintain Muscle",
	buildMuscle: "Build Muscle",
	netcarbDescription:
		"Eating over 50g of net carbs a day is enough to break ketosis. Most keto dieters eat around 20g-30g. The fewer carbs you eat the less chance you have of breaking ketosis.",

	//Settings and Language
	language: "Language",
	languageUpdated: "Language Updated",
	english: "English",
	french: "French",
	spanish: "Spanish",
	portuguese: "Portuguese",
	german: "German",
	logout: "Logout",
	loggedOut: "Logged Out",
	unitsUpdated: "Units Updated",
	featureRequest: "Feature Request",
	deleteAccount: "Delete Account",
	verifyDeleteMessage: "This will delete your account and all data, are you sure?",
	accountDeleted: "Account Deleted",
	net: "Net",
	total: "Total",
	preferredCarb: "Preferred Carb",
	preferredCarbDesc: "Total carbs include fiber and sugar alcohols, while Net carbs only include countable carbs that affect ketosis. Most keto dieters only track Net carbs.",
	updateMacroGoals: "Update Macro Goals",
	defaultMacroGoalsUpdated: "Default Macro Goals Updated",
	customGoalsApplied: "Custom Goals Applied To Dates",
	tracking: "Tracking",
	recalculateMacroGoals: "Re-Calculate Macro Goals",
	manuallyChangeMacroGoals: "Manually Change Macro Goals",
	changePreferences: "Change Preferences",
	restoreAdFree: "Restore Ad Free Purchase",
	darkMode: "Dark Mode",

	//Preferences
	otherTracking: "Other Tracking",
	other: "Other",
	notes: "Notes",

	//Purchase
	invalidCode: "Invalid Code",
	purchaseRestored: "Purchase Restored",
	userNotFound: "User Not Found",
	error: "Error",
	errorProducts: "There was an error trying to load products. Make sure you have a valid internet connection.",
	trackerEnabledAdsRemoved: "Purchase Restored, Ads Removed",
	purchaseTracking: "Purchase Tracking",
	gettingProductDetails: "Getting Product Details ...",
	noPurchaseFound: "No Purchase Found",
	noPurchaseFoundDesc: "No purchase was found on this account. Please email us if you think that is incorrect.",
	premium: "Premium",
	premiumEnabled: "Premium Enabled",

	//login
	account: "Account",
	signIn: "Sign In",
	logIn: "Log In",
	signedIn: "Signed In",
	accountCreated: "Account Created",
	signUp: "Sign Up",
	email: "Email",
	password: "Password",
	forgotPassword: "Forgot Password?",
	resetEmailSent: "Reset Email Sent",
	newUser: "New User?",
	emailError: "Enter a valid email",
	passwordError: "Enter a password",
	emailSubscribe: "Get updates and special offers from MyMedi",
	enterYourEmail: "Enter Your Email",
	medicalDisclaimer: "Medical Disclaimer",
	disclaim:
		"Medical Disclaimer: MyMedi is offered for educational and entertainment purposes only, and in no way intends to diagnose, cure, or treat any medical or other condition. Always seek the advice of your physician or other qualified health provider prior to changing your diet or exercise plan and ask your physician any questions you may have regarding a medical condition. Although we do our best to verify the accuracy of information contained herein, we cannot guarantee its accuracy.",
	skipAccount: "Skip Account",
	registerAccount: "Register Account",
	convertAccount: "Convert Account to Login",
	createAccountLater: "You can create an account later with your data by going to the Settings menu",
	whyLogin: "Why Login?",
	usingAccount:
		"Using an account will back up and sync your data across all your other devices. It only takes a second to register and we don't share or store any personal details.",

	//Editing Item Modal
	totalcarbError: "Total can't be less than Fiber",
	auto: "Auto",
	manual: "Manual",
	name: "Name",
	editingItem: "Editing Item",

	//Food Tabs
	foods: "Foods",
	meals: "Meals",
	meal: "Meal",
	favorites: "Favorites",
	recent: "Recents",
	recentlyused: "Recently Used",
	saveToFoods: "Save To Foods",
	searched: "Searched",
	myfoods: "My Foods",
	mymeals: "My Meals",
	quickmacro: "Quick Macros",
	create: "Create",
	created: "Created",
	createFood: "Create Food",
	createMeal: "Create Meal",
	ingredientNotFound: "Ingredient not found, try to add it again",
	totalServings: "Total Servings",
	totalServingsDesc: "Please enter a number for total servings",
	clearAllFavorites: "Clear All Favorites?",
	clearAllFavoritesDesc: "This will clear all of your favorites on all synced devices",
	clearAllRecentlyUsed: "Clear All Recently Used Items?",
	clearAllRecentlyUsedConfirm: "This will clear your recently logged items on all synced devices",
	removedFromRecentlyUsed: "Removed From Recently Used",
	itemsAdded: "Items Added",
	mealName: "Meal Name",
	servingName: "Serving Name",
	wholeRecipe: "Whole Recipe",

	//Onboard
	clickStart: "You are all set, click the button below to get started",
	privacyPolicy: "Privacy Policy",
	termsConditions: "Terms and Conditions",
	policyAgree: "By clicking below, you are agreeing to our",
	unitsOnboard: "Choose your preferred units for Body Measurements (Weight, Chest Size, etc) and Water",
	clockOnboard: "Choose how you want your start and end timer display formatted",
	scheduleOnboard: "Choose if you want to receive notifications every time you change windows and if you want a reminder before the period finishes.",
	manualOnboardSettings:
		"Choose your default fast goal (you can quickly change this on the homepage) and if you want to choose your goal in total hours or if you want to select a date and time for your goal.",
	manualOnboardNotifications:
		"Choose if you want to receive notifications such as a specific reminder to fast every day at a certain time, and reminders when the fast has ended or is about to end soon.",
	weightLoss: "Weight Loss",
	diseasePrevention: "Disease Prevention",
	lowerBloodPressure: "Lower Blood Pressure",
	improvedCholesterolProfile: "Improved Cholesterol Profile",
	antiAging: "Anti-Aging & Longevity",
	insulinManagement: "Insulin and Diabetes Management",
	antiCancer: "Anti-Cancer",
	heartHealth: "Heart Health",
	improvedSkin: "Improved Skin",
	dentalHealth: "Dental Health",
	whyKeto: "Why are you interested in the Mediterranean Diet?",
	betterTailor: "We will better tailor MyMedi to help you with your goals",
	goals: "Goals",
	goalsExplanation: "We will use this information to help you predict your progress",
	finished: "Finished",
	startTracking: "Start Tracking",

	//Body Measurements
	weightUnits: "Weight and Body Measurements",
	units: "Units",
	metric: "Metric",
	imperial: "Imperial",
	ftcm: "ft, lbs",
	bodyMeasurements: "Measurements",
	Weight: "Weight",
	BMI: "BMI",
	BodyFat: "Body Fat",
	LeanBodyMass: "Lean Body Mass",
	WaistSize: "Waist Size",
	HipSize: "Hip Size",
	ChestSize: "Chest Size",
	NeckSize: "Neck Size",
	ArmSize: "Arm Size",
	ForearmSize: "Forearm Size",
	CalfSize: "Calf Size",
	ThighSize: "Thigh Size",
	enterNumber: "Please enter a number above zero.",

	//Vitals
	vitals: "Vitals",
	water: "Water",
	bloodGlucose: "Blood Glucose",
	BloodGlucose: "Blood Glucose",
	Exercise: "Exercise",
	BloodPressure: "Blood Pressure",
	HeartRate: "Heart Rate",
	Ketones: "Ketones",
	ketones: "Ketones",
	Potassium: "Potassium",
	Sodium: "Sodium",
	Magnesium: "Magnesium",
	Systolic: "Systolic",
	Diastolic: "Diastolic",

	//Log
	breakfast: "Breakfast",
	lunch: "Lunch",
	dinner: "Dinner",
	snacks: "Snacks",
	quickAdd: "Quick Add",
	quickAddMacros: "Quick Add Macros",
	quickAddToMeal: "Quick Add To Meal",
	copyTo: "Copy To",
	saveAsMeal: "Save As Meal",
	addEntry: "Add Entry",
	caloriesFromMacros: "Calories From Macros",
	eaten: "Eaten",
	remaining: "Remaining",
	macroGoalsEaten: "Macro Goals Eaten",
	per: "Per",

	//Other Tracking
	syncExercise: "Sync Exercise",
	estimateCalories: "Estimate Calories",
	selectTime: "Select Time",
	duration: "Duration",
	caloriesBurned: "Calories Burned",
	addExerciseCal: "Add Exercise Cal",
	Notes: "Notes",
	notes: "Notes",
	exercise: "Exercise",
	Water: "Water",
	Mood: "Mood",
	mood: "Mood",

	//Progress
	custom: "Custom",
	oneWeek: "1 Week",
	oneMonth: "1 Month",
	threeMonths: "3 Months",
	sixMonths: "6 Months",
	oneYear: "1 Year",
	allTime: "All Time",
	to: "To",
	addTwoEntries: "Add 2 or more entries",
	addMoreEntriesToSeeLine: "Add more entries to see line-chart of macros over time",
	netChange: "Net Change",
	noEntriesFound: "No Entries Found",
	addFoodEntries: "Add food entries to your log to see data",
	viewAllData: "View All Data",

	//export
	exportWeight: "Export Weight",
	exportBodyMeasurements: "Export Body Measurements",
	exportVitals: "Export Vitals",
	exportOthers: "Export Others",
	permissionTitle: "File Permission",
	permissionMessage: "We need permission to save the export .csv file to your device storage.",
	pickOne: "Pick at least one option",
	emailSent: "Email Successfully Sent",
	emailNotSent: "Email Not Sent",
	export: "Export",
	type: "Type",
	dietLog: "Diet Log",
	data: "Data",
	allData: "All Data",
	noData: "No Data",
	noDataDesc: "There is no data to export",

	//Fitness Trackers
	fitbitDesc: "Send your MyMedi weight, and macro data into Fitbit. Send your Fitbit exercise activity into MyMedi",
	fitbitConnected: "Fitbit Syncing Connected",
	fitbitRemove: "Do you want to remove Fitbit access from this device?",
	fitbitDisconnected: "Fitbit disconnected",
	healthkit1: "To update your permissions for Apple Healthkit, open the health kit app on your phone, tap on sources, and select MyMedi from the list",
	healthkit2: "Send your MyMedi weight, water, and macros data into Apple HealthKit",

	//Social
	friendRequest: "Friend request",
	tryAddFriend: "Try adding a friend by email with the (+) button. Once accepted, you can copy each other's saved foods and meals.",
	alreadyCopied: "Already Copied",
	sent: "Sent",
	received: "Received",
	alreadyInvited: "Already Invited",
	alreadyPendingInvite: "This user already has a pending invite",
	alreadyFriended: "Already Friended",
	alreadyYourFriend: "This user is already your friend",
	pendingInvite: "Pending Invite",
	pendingAlready: "This user already sent you an invite",
	friendRequestSent: "Friend Request Sent",
	invalidEmail: "Invalid Email",
	cantFriendYourself: "You can't friend yourself",
	friendRequestCanceled: "Friend Request Canceled",
	friendRequestAccepted: "Friend Request Accepted",
	friendRequestDeclined: "Friend Request Declined",
	unfriended: "Unfriended",
	allFoodsCopied: "All Foods Copied",
	friendNotAnon: "Please convert your account in the settings page to enable the social features",

	//Exercise Calculator
	ex1: "Aerobic Dance (Casual)",
	ex2: "Aerobic Dance (Moderate)",
	ex3: "Aerobic Dance (Intense)",
	ex4: "Basketball (Full court)",
	ex5: "Basketball (Half court)",
	ex6: "Calisthenics",
	ex7: "Cycling (5.5 mph, 9 kmph)",
	ex8: "Cycling (10 mph, 16 kmph)",
	ex9: "Cycling (13 mph, 21 kmph)",
	ex10: "Dancing (Casual)",
	ex11: "Dancing (Moderate)",
	ex12: "Dancing (Intense)",
	ex13: "Field Sports",
	ex14: "Frisbie",
	ex15: "Golf (walking)",
	ex16: "Golf (with Cart)",
	ex17: "Gymnastics",
	ex18: "Housework",
	ex19: "Jump Rope",
	ex20: "Martial Arts",
	ex21: "Racquetball",
	ex22: "Rollerblading",
	ex23: "Rowing Machine (Intense)",
	ex24: "Rowing Machine (Moderate)",
	ex25: "Running (4 mph, 6.5 kmph)",
	ex26: "Running (5 mph, 8 kmph)",
	ex27: "Running (6 mph, 9.7 kmph)",
	ex28: "Running (7 mph, 11.3 kmph)",
	ex29: "Running (8 mph, 13 kmph)",
	ex30: "Running (10 mph, 16 kmph)",
	ex31: "Running (12 mph, 19.3 kmph)",
	ex32: "Softball",
	ex33: 'Stair Step (6", 15.2 cm)',
	ex34: 'Stair Step (8", 20.3 cm)',
	ex35: 'Stair Step (10", 25.4 cm)',
	ex36: 'Stair Step (12", 30.5 cm)',
	ex37: "Swimming (Casual)",
	ex38: "Swimming (intense)",
	ex39: "Tennis",
	ex40: "Volleyball",
	ex41: "Walking (2 mph, 3.2 kmph)",
	ex42: "Walking (3 mph, 4.8 kmph)",
	ex43: "Walking (4 mph, 6.5 kmph)",
	ex44: "Weight Training (Intense)",
	ex45: "Weight Training (Normal)",
	ex46: "Yardwork",
	ex47: "Yoga (Hatha)",
	ex48: "Yoga (Ashtanga / Power)",
	ex49: "Yoga (Hot / Bikram)",
	ex50: "Yoga (Vinyasa / Flow)",
	exerciseCalculatorDescrition: "Enter your weight, exercise duration, and the activity to estimate your energy expenditure",

	//Shopping List
	addItem: "Add Item",
	deleteItem: "Delete Item",
};
