import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Fonts } from 'Theme';
import { Text } from './';

export const HeadingSmall = (props) => {
	return (
		<View style={styles.greyBacking}>
			<Text style={styles.dividerText}>{props.children}</Text>
		</View>
	);
};

var styles = StyleSheet.create({
	greyBacking: {
		backgroundColor: '#fafafa',
		borderWidth: 1,
		borderColor: '#f2f2f2'
	},
	dividerText: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 10,
		fontWeight: 'bold',
		fontSize: Fonts.size.mediumer,
		color: '#333'
	}
});
