//@flow
import React from 'react';
import {
	View,
	TouchableOpacity,
	StyleSheet,
	Platform,
	FlatList,
	Picker as PickerA,
	SafeAreaView
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import NavigationBar from 'react-native-navbar';
import { i18n } from 'Theme';
import DatePicker from 'react-native-datepicker';
import WebDatePicker from 'react-datepicker';
import { Text, HeaderTextButton } from 'Components/common';
import moment from 'moment';
import Picker from 'react-native-picker';
import { setCopyDate, setCopyTime } from '../../actions';
import { convertMealNameString } from 'Components/tracker/log/utilities/LogHelper';
import Elevations from 'react-native-elevation';

type Props = {
	closeModal: Function,
	onSubmit: Function,
	title: string
};

export const CopyMealModal = (props: Props) => {
	const { closeModal, title, onSubmit } = props;
	const dispatch = useDispatch();
	const { copyDate, copyTime, copyItems } = useSelector((state) => state.log);
	const { theme } = useSelector((state) => state.startup);
	const formattedCopyDate = moment(copyDate, 'YYYY-MM-DD').format('MM-DD-YYYY');

	const showPicker = (setCopyTime) => {
		Picker.init({
			pickerTitleText: '',
			pickerConfirmBtnText: i18n.t('confirm'),
			pickerCancelBtnText: i18n.t('cancel'),
			pickerTextEllipsisLen: 12,
			pickerConfirmBtnColor: [255, 255, 255, 1],
			pickerCancelBtnColor: [255, 255, 255, 1],
			pickerTitleColor: [255, 255, 255, 1],
			pickerBg: theme.pickerBase,
			pickerToolBarBg: theme.pickerBg,
			pickerFontColor: theme.pickerBg,
			pickerFontFamily: 'Raleway',
			pickerFontSize: 20,
			pickerRowHeight: 28,
			pickerData: [
				i18n.t('breakfast'),
				i18n.t('lunch'),
				i18n.t('dinner'),
				i18n.t('snacks')
			],
			onPickerConfirm: (data) => {
				dispatch(setCopyTime(convertMealNameString(data[0])));
			}
		});
		Picker.show();
	};

	const renderItem = ({ item }) => {
		return (
			<View
				style={[styles.foodItemContainer, { borderBottomColor: theme.border }]}>
				<View style={styles.itemTitle}>
					<Text style={styles.foodItem}>{item.name}</Text>
				</View>
				{/*<TouchableOpacity
					style={[styles.quickDelete,{		backgroundColor: theme.grey,
		borderLeftColor: theme.border,}]}
					onPress={() => dispatch(quickDelete(item, mealTime))}>
					<FontAwesome
						name="close"
						size={14}
						color={theme.red}
						style={styles.quickDeleteIcon}
					/>
				</TouchableOpacity>*/}
			</View>
		);
	};

	//Render the time picker at top of editing form, native (android, ios) use a different picker library than web
	const renderTimePicker = () => {
		if (Platform.OS !== 'web') {
			return (
				<DatePicker
					date={formattedCopyDate}
					style={{
						flex: 1,
						height: Platform.OS === 'ios' ? 30 : 50,
						justifyContent: 'center'
					}}
					mode="date"
					placeholder={i18n.t('selectDate')}
					format="MM-DD-YYYY"
					minDate="01-01-2015"
					maxDate="01-01-2050"
					confirmBtnText={i18n.t('confirm')}
					showIcon={false}
					cancelBtnText={i18n.t('cancel')}
					androidMode="default"
					onDateChange={(date) => {
						dispatch(
							setCopyDate(moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD'))
						);
					}}
					customStyles={{
						dateInput: {
							borderWidth: 0
						},
						dateText: {
							color: theme.themeAccent,
							fontFamily: 'Comfortaa'
						},
						datePicker: {
							justifyContent: 'center'
						},
						btnTextConfirm: { fontFamily: 'Comfortaa', color: theme.teal },
						btnTextCancel: { fontFamily: 'Comfortaa', color: "#333" }
					}}
				/>
			);
		} else {
			return (
				<View
					style={[
						styles.webTimeContainer,
						{ borderBottomColor: theme.border }
					]}>
					<WebDatePicker
						selected={moment(formattedCopyDate, 'MM-DD-YYYY').toDate()}
						onChange={(dateObj) => {
							dispatch(
								setCopyDate(moment(dateObj, 'MM-DD-YYYY').format('YYYY-MM-DD'))
							);
						}}
						dateFormat={'MMM d, yyyy'}
						popperClassName="some-custom-class"
						popperPlacement="top-end"
						popperModifiers={{
							offset: {
								enabled: true,
								offset: '5px, 10px'
							},
							preventOverflow: {
								enabled: true,
								escapeWithReference: false,
								boundariesElement: 'viewport'
							}
						}}
					/>
				</View>
			);
		}
	};

	return (
		<>
			<SafeAreaView
				style={[styles.topCtn, { backgroundColor: theme.navBar }]}
			/>
			<SafeAreaView style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<NavigationBar
					statusBar={
						Platform.OS === 'ios' ? { hidden: false } : { hidden: true }
					}
					title={{
						title: title,
						style: {
							color: theme.navText,
							fontWeight: null,
							fontFamily: 'Comfortaa',
							fontSize: 14
						}
					}}
					containerStyle={{
						backgroundColor: theme.navBar,
						paddingHorizontal: 10
					}}
					leftButton={
						<HeaderTextButton title={i18n.t('cancel')} onPress={closeModal} />
					}
					rightButton={
						<HeaderTextButton title={i18n.t('copyPlus')} onPress={onSubmit} />
					}
				/>
				<View
					style={[
						styles.listItemContainer,
						{ borderBottomColor: theme.border, zIndex: 9999 }
					]}>
					<Text style={styles.listLabel}>{i18n.t('date')}:</Text>
					{renderTimePicker()}
				</View>
				<View
					style={[
						styles.listItemContainer,
						{ borderBottomColor: theme.border }
					]}>
					<Text style={styles.listLabel}>{i18n.t('meal')}:</Text>
					<View style={styles.listInput}>
						{Platform.OS !== 'ios' ? (
							<PickerA
								mode="dropdown"
								style={[
									styles.unitPicker,
									{
										backgroundColor: theme.baseBG,
										color: theme.themeAccent
									}
								]}
								selectedValue={copyTime}
								onValueChange={(itemValue) => {
									dispatch(setCopyTime(itemValue));
								}}>
								<PickerA.Item label={i18n.t('breakfast')} value={'breakfast'} />
								<PickerA.Item label={i18n.t('lunch')} value={'lunch'} />
								<PickerA.Item label={i18n.t('dinner')} value={'dinner'} />
								<PickerA.Item label={i18n.t('snacks')} value={'snacks'} />
							</PickerA>
						) : (
							<TouchableOpacity
								style={styles.iosPicker}
								onPress={() => showPicker(setCopyTime)}>
								<Text style={[styles.inputText, { color: theme.themeAccent }]}>
									{copyTime}
								</Text>
							</TouchableOpacity>
						)}
					</View>
				</View>
				<View style={styles.foods}>
					<Text style={[styles.copyheader, { backgroundColor: theme.base }]}>
						{i18n.t('copyItems')}:
					</Text>
					<FlatList
						data={copyItems}
						keyExtractor={(item) => item.random}
						renderItem={renderItem}
					/>
				</View>
			</SafeAreaView>
		</>
	);
};

const styles = StyleSheet.create({
	listLabel: {
		flex: 2,
		alignSelf: 'center'
	},
	listItemContainer: {
		flexDirection: 'row',
		borderBottomWidth: 1,
		paddingVertical: Platform.OS === 'ios' ? 15 : 0,
		paddingLeft: 15
	},
	listInput: {
		flex: 1,
		minWidth: 1,
		height: Platform.OS === 'ios' ? 30 : 50,
		justifyContent: 'center'
	},
	inputText: {
		textAlign: 'center',
		fontSize: 14,
		fontFamily: 'Raleway'
	},
	foodItem: {
		fontSize: 12,
		textAlign: 'left',
		lineHeight: 16
	},
	foodItemContainer: {
		borderBottomWidth: 1,
		flex: 1,
		flexDirection: 'row'
	},
	itemTitle: {
		flex: 4,
		paddingVertical: 15,
		paddingLeft: 20,
		paddingRight: 8,
		alignItems: 'flex-start'
	},
	quickDelete: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'stretch',
		flex: 0.75,
		paddingVertical: 15,
		borderLeftWidth: 1
	},
	quickDeleteIcon: {
		alignSelf: 'center'
	},
	copyheader: {
		paddingLeft: 15,
		paddingVertical: 10,
		...Elevations[1]
	},
	webTimeContainer: {
		zIndex: 9999,
		flexDirection: 'row',
		height: 50,
		justifyContent: 'center',
		alignItems: 'center'
	}
});
