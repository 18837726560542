//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Gram } from 'Components/common';
import { i18n } from 'Theme';

export const HeaderMacros = (props: Props) => {
	const { recipe, units, theme } = props;
	const {
		CHOCDF = { quantity: 0, label: i18n.t('netcarb'), unit: 'g' },
		ENERC_KCAL,
		FAT,
		PROCNT,
		FIBTG = { quantity: 0, label: i18n.t('fiber'), unit: 'g' }
	} = recipe.totalNutrients;
	const servings = recipe.yield;
	let netCarb = CHOCDF.quantity - FIBTG.quantity;
	return (
		<View
			style={[
				styles.mealBreakdownContainer,
				{ backgroundColor: theme.grey, borderTopColor: theme.border }
			]}>
			<View style={styles.mealBreakdown}>
				<Text style={styles.mealMacroLabel}>
					{(FAT.quantity / servings).toFixed(0)}
					<Gram />
				</Text>
				<Text style={styles.mealMacro}>{i18n.t('fat')}</Text>
			</View>
			{units.carbUnits === 'net' && (
				<View style={styles.mealBreakdown}>
					<Text style={styles.mealMacroLabel}>
						{(netCarb / servings).toFixed(0)}
						<Gram />
					</Text>
					<Text style={styles.mealMacro}>{i18n.t('netcarb')}</Text>
				</View>
			)}
			{units.carbUnits === 'total' && (
				<View style={styles.mealBreakdown}>
					<Text style={styles.mealMacroLabel}>
						{(CHOCDF.quantity / servings).toFixed(0)}
						<Gram />
					</Text>
					<Text style={styles.mealMacro}>{i18n.t('totalcarb')}</Text>
				</View>
			)}
			<View style={styles.mealBreakdown}>
				<Text style={styles.mealMacroLabel}>
					{(PROCNT.quantity / servings).toFixed(0)}
					<Gram />
				</Text>
				<Text style={styles.mealMacro}>{i18n.t('protein')}</Text>
			</View>
			<View style={styles.mealBreakdown}>
				<Text style={styles.mealMacroLabel}>
					{(ENERC_KCAL.quantity / servings).toFixed(0)}
				</Text>
				<Text style={styles.mealMacro}>kCal</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	mealMacro: {
		fontSize: 9,
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: -5
	},
	mealMacroLabel: {
		fontSize: 12,
		paddingLeft: 0,
		lineHeight: 12,
		paddingRight: 0,
		paddingTop: 8
	},
	mealBreakdownContainer: {
		flex: 1,
		flexDirection: 'row',
		borderTopWidth: 1,
		paddingVertical: 5
	},
	mealBreakdown: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
});
