//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Colors } from 'App/constants';
import { SimpleLineIcons } from '@expo/vector-icons';
import { Text } from './';
import Elevations from 'react-native-elevation';

export const ClickableHeader = (props) => {
	const { style, isBlue, leftText, rightIconName, iconColor = '#333' } = props;
	return (
		<TouchableOpacity
			onPress={() => props.onPress()}
			style={[
				styles.container,
				props.noMargin ? { marginTop: 0 } : { marginTop: 25 },
				props.style
			]}>
			<Text
				style={[styles.leftTitle, props.isBlue ? { color: Colors.blue } : {}]}>
				{props.leftText}
			</Text>
			<SimpleLineIcons
				style={styles.iconRight}
				name={props.rightIconName}
				size={14}
				color={iconColor}
			/>
		</TouchableOpacity>
	);
};

var styles = StyleSheet.create({
	container: {
		height: 50,
		flexDirection: 'row',
		backgroundColor: Colors.grey,
		alignItems: 'center',
		marginBottom: 2,
		...Elevations[2]
	},
	leftTitle: {
		flex: 1,
		paddingVertical: 10,
		paddingLeft: 15
	},
	iconRight: {
		paddingRight: 10
	}
});
