//@flow
import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory-native';
import { Text } from 'Components/common';
import { i18n } from 'Theme';
import { returnPercentage } from '../../utilities/LogHelper';

type Props = {
	macrosEaten: Object,
	macroGoals: Object,
	carbUnits: Object,
	theme: Object
};

export const LogViewBarGraph = (props: Props) => {
	const { theme } = props;
	const [activeLabels, toggleLabels] = useState(false);
	const [width, toggleWidth] = useState(Dimensions.get('window').width);

	useEffect(() => {
		Dimensions.addEventListener('change', updateWidth);
		return () => {
			Dimensions.removeEventListener('change', updateWidth);
		};
	}, []);

	useEffect(() => {
		toggleLabels(false);
	}, [props.macrosEaten]);

	const updateWidth = () => {
		toggleWidth(Dimensions.get('window').width);
	};

	const calculateColor = (value: number) => {
		if (value > 100) {
			return theme.red;
		} else if (value < 100 && value > 80) {
			return theme.yellow;
		}
		return theme.green;
	};

	const { macrosEaten, macroGoals, carbUnits } = props;

	const carbLeft =
		carbUnits === 'total'
			? returnPercentage(macrosEaten.totalcarbs, macroGoals.carbgrams)
			: returnPercentage(macrosEaten.carbs, macroGoals.carbgrams);
	const fatLeft = returnPercentage(macrosEaten.fats, macroGoals.fatgrams);
	const proteinLeft = returnPercentage(
		macrosEaten.proteins,
		macroGoals.proteingrams
	);
	const calorieLeft = returnPercentage(macrosEaten.calories, macroGoals.cal);
	const data = [
		{ macro: 1, percentEaten: carbLeft, name: 'carb' },
		{ macro: 2, percentEaten: fatLeft, name: 'fat' },
		{ macro: 3, percentEaten: proteinLeft, name: 'protein' },
		{ macro: 4, percentEaten: calorieLeft, name: 'cal' }
	];
	return (
		<View style={[styles.container, { width: width }]}>
			<Text style={styles.chartHeadingText}>
				{i18n.t('macroGoalsEaten')} (%)
			</Text>
			<VictoryChart
				domainPadding={{ x: width * 0.08, y: 0 }}
				disableContainerEvents={true}
				width={width}
				height={283}
				style={{ parent: { height: 283 } }}
				animate={{
					duration: 500,
					onLoad: { duration: 0 }
				}}>
				<VictoryAxis
					tickValues={[1, 2, 3, 4]}
					tickFormat={[
						i18n.t('carbs'),
						i18n.t('fat'),
						i18n.t('protein'),
						i18n.t('calories')
					]}
					style={{
						tickLabels: {
							fontSize: 12,
							fontFamily: 'Comfortaa',
							padding: 20,
							strokeWidth: 5,
							fill: theme.darkFont
						}
					}}
				/>
				<VictoryAxis
					dependentAxis
					domain={[0, 100]}
					style={{
						grid: { stroke: (t) => (t === 100 ? 'red' : theme.border) },
						tickLabels: {
							fontSize: 12,
							fontFamily: 'Comfortaa',
							padding: 10,
							strokeWidth: 5,
							fill: theme.darkFont
						}
					}}
				/>
				<VictoryBar
					thing={activeLabels}
					style={{
						labels: {
							fontSize: 14,
							fontFamily: 'Comfortaa',
							padding: 15,
							strokeWidth: 5,
							fill: (d) => calculateColor(d.datum.percentEaten)
						},
						data: {
							strokeWidth: 1,
							fill: (d) => calculateColor(d.datum.percentEaten)
						}
					}}
					barRatio={1}
					labels={(d) => {
						return activeLabels ? `${d.datum.percentEaten}%` : '';
					}}
					events={[
						{
							target: 'data',
							eventHandlers: {
								onPressIn: () => {
									return toggleLabels(!activeLabels);
								}
							}
						}
					]}
					data={data}
					x="macro"
					y="percentEaten"
				/>
			</VictoryChart>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	chartHeadingText: {
		paddingTop: 10,
		fontFamily: 'Comfortaa',
		fontWeight: '900'
	}
});
