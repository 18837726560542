//@flow
//Strings used throughout the app translated into English, French, Spanish, Portuguese, German

export const frStrings = {
	appName: 'MyKeto',
	log: 'Journal',
	login: "S'identifier",
	learn: 'Apprendre',
	foodlist: 'Liste des aliments',
	recipes: 'Recettes',
	mealplan: 'Plan de repas',
	otherdiet: 'Autres régimes',
	moreapps: "Plus d'applications",
	macrocalculator: 'Calculatrice macro',
	purchase: 'Achat',
	tracker: 'Traqueur',
	progress: 'Le progrès',
	manage: 'Gérer',
	settings: 'Réglages',
	preferences: 'Préférences',
	customgoals: 'Objectifs personnalisés',
	othertracking: 'Autre suivi',
	social: 'Social',
	fitnessTracker: 'Fitness Trackers',
	feedback: 'Commentaires / Assistance',
	addFood: 'Ajouter de la nourriture',
	addIngredient: 'Ajouter un ingrédient',
	mealsRecipes: 'Repas / Recettes',
	editLogEntry: "Modifier l'entrée de journal",
	editMeal: 'Modifier le repas',
	editFood: 'Modifier la nourriture',
	deleteFood: 'Supprimer la nourriture',
	deleteMeal: 'Supprimer le repas',
	friends: 'Copains',
	copyMeal: 'Copier le repas',
	mealCopied: 'Repas copié',
	copyFood: 'Copier la nourriture',
	copyItems: 'Copier les éléments',
	copyPlus: 'Copier +',
	copyAll: 'Tout copier',
	copied: 'Copié',
	pending: 'En attente',
	manageFoods: 'Gérer les aliments',
	manageMeals: 'Gérer les repas',
	macros: 'Macros',
	ingredients: 'Ingrédients',
	directions: 'Les directions',
	nutrition: 'Nutrition',
	nutrients: 'Nutriments',
	save: 'Sauvegarder',
	cancel: 'Annuler',
	goback: 'Retourner',
	close: 'Proche',
	delete: 'Supprimer',
	saveas: 'Enregistrer sous',
	add: 'Ajouter',
	confirm: 'Confirmer',
	addfavorite: 'Ajouter un favori',
	removeFavorite: 'Supprimer le favori',
	remove: 'Retirer',
	removed: 'Supprimé',
	added: 'Ajoutée',
	clear: 'Clair',
	send: 'Envoyer',
	required: 'Obligatoire',
	macrosAddedToMeal: 'Macros ajoutées au repas',
	ingredientUpdated: 'Ingrédient mis à jour',
	addtoMeal: 'Ajouter au repas',
	saveMeal: 'Enregistrer le repas',
	addedToMeal: 'Ajouté au repas',
	mealSaved: 'Repas sauvé',
	mealsAdded: 'Repas ajoutés',
	addTo: 'Ajouter à',
	addedToLog: 'Ajouté au journal',
	saveAsFood: 'Enregistrer comme nourriture',
	updateEntry: "Mettre à jour l'entrée",
	updateIngredient: "Mettre à jour l'ingrédient",
	otherTrackingUpdated: 'Autre suivi mis à jour',
	nameEmpty: 'Nom vide',
	pleaseEnterName: 'Veuillez saisir un nom',
	selectDate: 'Sélectionner une date',
	foodNotFound: 'Nourriture introuvable',
	deleted: 'Supprimé',
	deleteConfirm: 'Etes-vous sûr que vous voulez supprimer?',
	addedToFavorites: 'ajouté aux favoris',
	favoritesCleared: 'Favoris effacés',
	removedFromFavorites: 'Supprimé des favoris',
	savedToFoods: 'Enregistré dans les aliments',
	updated: 'Mis à jour',
	multiAdd: 'Ajout multiple',
	noThanks: 'Non merci',
	giveFeedback: 'Donnez votre avis',
	rateAppName: 'Noter MyKeto',
	never: 'Jamais',
	later: 'Plus tard',
	rate: 'Taux',
	rateDesc:
		"Si vous aimez utiliser MyKeto, cela vous dérangerait-il de prendre une seconde pour l'évaluer? Cela ne prendra pas plus d'une minute. Merci pour votre soutien!",
	rateFeed:
		'Pourriez-vous nous faire part de vos commentaires sur ce que vous aimeriez voir dans notre application? Merci!',
	basics: 'Basiques',
	benefits: 'Avantages',
	sideeffects: 'Effets secondaires',
	myths: 'Mythes',
	faq: 'FAQ',
	faq1: 'Questions fréquemment posées',
	getstarted: 'Commencer',
	fiber: 'Fibre',
	tips: 'Conseils',
	products: 'Produits recommandés',
	basicsh1: "Qu'est-ce que le régime Keto?",
	basicsh2: 'Comment ça marche?',
	basicsh3: 'Pourquoi Keto?',
	basicsp1:
		"Le régime cétogène ou céto est très similaire à un régime pauvre en glucides tel que le régime Atkins. La principale différence entre un régime régulier à faible teneur en glucides et un régime cétogène est la quantité de glucides et de protéines autorisée quotidiennement.  N  nEn général, les régimes à faible teneur en glucides réduisent l'apport en glucides à moins de 100 grammes; Les régimes cétogènes stricts sont un sous-groupe de régimes à faible teneur en glucides qui ne permettent généralement que jusqu'à 50 g de glucides par jour.",
	basicsp2:
		'De plus, le régime cétogène est un régime riche en graisses, en protéines et faible en glucides. Vous remplacez les glucides de votre alimentation par une plus grande quantité de matières grasses et un apport modéré en protéines.',
	basicsp3:
		"Ce régime riche en graisses met votre corps dans un état appelé' cétose, où le corps produit des cétones dans le foie pour être utilisées comme énergie.  N  nNormalement, les glucides sont décomposés en glucose, que votre corps transporte dans le sang pour donner l'énergie de vos cellules. À l'opposé, la cétose produit des cétones à partir des grandes quantités de graisses consommées, et la présence de gros glucides dans l'alimentation arrêtera en fait la cétose.",
	basicsp4:
		"Par rapport au régime occidental qui se compose en grande partie de glucides hautement raffinés, le céto peut offrir de nombreux avantages pour la santé. La plupart des gens commencent un régime cétogène parce qu'ils veulent perdre du poids. Cependant, la recherche scientifique suggère qu'il va plus loin que cela, comme réduire le risque de maladie cardiaque, de diabète, de cancer, d'accident vasculaire cérébral, etc.  n  nUn régime céto est bien plus que la simple suppression des glucides, c'est un mode de vie sur la santé globale. Il favorise de longs et intenses accès d'énergie, une augmentation de la nourriture saine et délicieuse et une meilleure vision globale de votre vie. Il est facilement maintenable avec un excès d'options et est souvent une réponse à l'amélioration du bien-être que beaucoup de gens ont du mal à saisir au début.  N  nKeto est difficile et testera votre volonté, mais cela change votre façon de voir et de penser vous-même, la nourriture et la santé en général.",
	basicsbq:
		"L'apport nutritionnel avec un régime cétogène correspond généralement à environ 70 à 75% des calories provenant des graisses, 20 à 25% des protéines et 5 à 10% des glucides sur une base quotidienne.",
	benefitsh0: 'Avantages',
	benefitsp0:
		"Les régimes cétogènes ont une longue liste d'avantages et peuvent être observés aussi rapidement qu'une semaine, tout en se développant progressivement sur une période de 3 semaines.",
	benefitsh1: '1. Perte de poids',
	benefitsp1:
		"Lorsque le corps brûle les graisses comme principale source d'énergie, il les prend essentiellement dans vos réserves de graisses puisque vous êtes à jeun. Des études montrent que les personnes suivant un régime faible en glucides perdent plus de poids et plus rapidement que les personnes suivant un régime faible en gras. Cela est vrai même lorsque les personnes à la diète faible en gras limitent activement les calories.",
	benefitsbq1:
		"Les sujets gravement obèses avec une prévalence élevée de diabète ou de syndrome métabolique ont perdu plus de poids pendant six mois avec un régime hypocalorique que sous un régime hypocalorique et lipidique, avec une amélioration relative de la sensibilité à l'insuline et des taux de triglycérides, même après ajustement de la quantité de poids perdu.",
	benefitsbq2:
		'Un régime pauvre en glucides semble être une méthode efficace pour perdre du poids à court terme chez les adolescents en surpoids et ne nuit pas au profil lipidique.',
	benefitsbq3:
		"Un essai contrôlé multicentrique d'un an portant sur 63 hommes et femmes obèses qui ont été assignés au hasard soit à un régime pauvre en glucides, riche en protéines, riche en graisses ou à un régime hypocalorique, riche en glucides et faible en gras (conventionnel ) régime. Le régime pauvre en glucides a entraîné une perte de poids plus importante (différence absolue, environ 4 pour cent) que le régime conventionnel pendant les six premiers mois, mais les différences n'étaient pas significatives à un an.",
	benefitsbq4:
		'Un pourcentage important de la graisse perdue dans les régimes pauvres en glucides a tendance à provenir de la graisse nocive dans la cavité abdominale, connue pour causer de graves problèmes métaboliques.',
	benefitsbq5:
		"Une étude récente de l'école de médecine de l'Université Johns Hopkins confirme qu'un régime riche en graisses et faible en glucides n'est pas préjudiciable à la santé vasculaire ET entraîne une perte de poids plus rapide. Les auteurs ont confirmé que les personnes à la diète dans le groupe à faible teneur en glucides ont perdu plus de poids sur une période plus courte que le groupe à haute teneur en glucides, et que le groupe à faible teneur en glucides n'avait pas de changements nocifs dans la santé vasculaire.",
	benefitsh2: '2. Abaisser la pression artérielle',
	benefitsp2:
		"Les régimes pauvres en glucides sont très efficaces pour réduire la tension artérielle. Parlez à votre médecin si vous prenez des médicaments contre l'hypertension avant de commencer.",
	benefitsbq6:
		'Dans un échantillon de patients externes médicaux, un régime Low Carb Keto a conduit à des améliorations similaires à celles du traitement médicamenteux Orlistat combiné à un régime faible en gras pour le poids, les lipides sériques et les paramètres glycémiques et était plus efficace pour abaisser la tension artérielle.',
	benefitsh3: '3. Profil de cholestérol amélioré',
	benefitsp3:
		"Montré pour améliorer les taux de triglycérides et de cholestérol les plus associés à l'accumulation artérielle.",
	benefitsbq7:
		'Les régimes cétogènes augmentent davantage les concentrations de cholestérol HDL sain pour le cœur que les régimes faibles en gras et riches en glucides.',
	benefitsbq8:
		'La réduction de la consommation de glucides chez les personnes en bonne santé conduit également à des niveaux plus élevés de cholestérol HDL.',
	benefitsbq9:
		'Les régimes pauvres en glucides et riches en graisses diminuent la concentration de particules LDL (LDL-P) et augmentent la taille du cholestérol LDL.',
	benefitsbq10:
		'Les régimes cétogènes diminuent la quantité de cholestérol VLDL nocif dans le sang.',
	benefitsh4: '4. Traitement de la glycémie stabilisée et du diabète ',
	benefitsp4:
		"La meilleure façon de réduire la glycémie et les taux d'insuline est de réduire la consommation de glucides. C'est également un moyen très efficace de traiter et peut-être même d'inverser le diabète de type II.",
	benefitsbq11:
		"Le LCKD a amélioré le contrôle glycémique chez les patients atteints de diabète de type 2, de sorte que les médicaments contre le diabète ont été interrompus ou réduits chez la plupart des participants. Étant donné que le LCKD peut être très efficace pour abaisser la glycémie, les patients sous traitement contre le diabète qui utilisent ce régime doivent être sous étroite surveillance médicale ou capables d'ajuster leur traitement.",
	benefitsbq12:
		"Les graisses saturées très faibles en glucides (VLCARB) entraînent une perte de graisse similaire à celle des régimes pauvres en graisses saturées, mais sont plus efficaces pour améliorer les concentrations de triacylglycérols, de HDL-C, de glucose à jeun et postprandial et d'insuline. Le VLCARB peut être utile dans la prise en charge à court terme de sujets présentant une résistance à l'insuline et une hypertriacylglycérolémie. ",
	benefitsbq13:
		"La modification du régime alimentaire a conduit à des améliorations du contrôle glycémique et de la réduction / élimination des médicaments chez des volontaires motivés atteints de diabète de type 2. Le régime alimentaire pauvre en glucides a conduit à de plus grandes améliorations du contrôle glycémique et une réduction / élimination des médicaments plus fréquente que le régime à faible indice glycémique. La modification du mode de vie à l'aide d'interventions à faible teneur en glucides est efficace pour améliorer et inverser le diabète de type 2.",
	benefitsh5: '5. Manque de faim',
	benefitsp5:
		"Les corps cétoniques amortissent l'appétit, et la graisse est naturellement plus satisfaisante et finit par nous laisser dans un état rassasié et plein plus longtemps.",
	benefitsbq14:
		"Les symptômes de l'affect négatif et de la faim se sont améliorés davantage chez les patients suivant un régime céto par rapport à ceux suivant un régime faible en gras.",
	benefitsh6: '6. Anti-Cancer',
	benefitsp6:
		'Keto peut aider à lutter contre certains types de cancer et diverses tumeurs.',
	benefitsbq15:
		"Bien que le mécanisme par lequel les régimes cétogènes démontrent des effets anticancéreux lorsqu'ils sont combinés avec des radiothérapies standard n'a pas été complètement élucidé, les résultats précliniques ont démontré l'innocuité et l'efficacité potentielle de l'utilisation de régimes cétogènes en combinaison avec la radio-chimiothérapie pour améliorer les réponses dans des modèles de cancer murin. Ces études précliniques ont donné l'impulsion pour étendre l'utilisation des régimes cétogènes dans les essais cliniques de phase I qui sont actuellement en cours.",
	benefitsh7: 'sept. Meilleure peau',
	benefitsp7:
		"Les chercheurs affirment que les aliments qui augmentent la glycémie peuvent également augmenter les hormones. Les hormones peuvent stimuler la production d'huile, qui à son tour, peut déclencher l'acné. Le régime Keto empêche les pics de sucre dans le sang en utilisant les graisses comme principale source d'énergie.",
	benefitsbq16:
		"Dans un article publié dans le Journal of the Academy of Nutrition and Dietetics, Burris et ses collègues passent en revue 27 études sur la nutrition et l'acné. Certaines des preuves les plus convaincantes proviennent de quelques études récentes sur des adolescents et des jeunes hommes (âgés de 15 à 25 ans) qui avaient de l'acné. Après que les participants aient suivi un régime à faible charge glycémique, les chercheurs ont documenté une diminution des lésions inflammatoires d'acné. Les études étaient petites, mais les résultats étaient significatifs.",
	benefitsh8: '8. Amélioration de la santé dentaire',
	benefitsp8:
		'Le sucre (glucides) est le principal contributeur aux maladies et caries dentaires.',
	benefitsbq17:
		"Les résidus alimentaires, en particulier les glucides, ont des caractéristiques favorisant les caries qui permettent la fermentation et l'accumulation d'acide dans la plaque bactérienne. Il améliore les quatre activités précédemment mentionnées du biofilm - implantation, colonisation, activité métabolique et épaisseur. Lorsque les glucides sont limités dans un régime, cela limite un facteur essentiel pour le développement des caries.",
	benefitsh9: "9. Stabilisation de l'humeur",
	benefitsp9:
		"Les corps cétoniques se sont avérés bénéfiques pour stabiliser les neurotransmetteurs tels que la sérotonine et la dopamine, ce qui permet un meilleur contrôle de l'humeur.",
	benefitsbq18:
		"Le régime cétogène, introduit à l'origine dans les années 1920, a connu une résurgence récente en tant que traitement d'appoint de l'épilepsie réfractaire, en particulier chez les enfants. Dans cette population difficile à traiter, le régime présente une efficacité remarquable avec deux tiers montrant une réduction significative de la fréquence des crises et un tiers devenant presque sans crise. Il y a plusieurs raisons de soupçonner que le régime cétogène peut également avoir une utilité comme stabilisateur de l'humeur dans la maladie bipolaire. Celles-ci incluent l'observation que plusieurs interventions anticonvulsivantes peuvent améliorer l'issue des troubles de l'humeur. En outre, des changements bénéfiques dans le profil d'énergie cérébrale sont notés chez les sujets sous régime cétogène. Ceci est important car l'hypométabolisme cérébral global est une caractéristique du cerveau des individus déprimés ou maniaques. Enfin, on s'attend à ce que les changements extracellulaires qui se produisent dans la cétose diminuent les concentrations intracellulaires de sodium, une propriété commune de tous les stabilisateurs de l'humeur efficaces. Des essais sur le régime cétogène dans la prévention des rechutes des épisodes d'humeur bipolaire sont justifiés.",
	benefitsh10: 'dix. Énergie augmentée possible',
	benefitsp10:
		"Les graisses s'avèrent être la molécule la plus efficace pour brûler comme carburant.",
	benefitsbq19:
		"Pendant le stress métabolique, les cétones servent de source d'énergie alternative pour maintenir le métabolisme normal des cellules cérébrales. En fait, le BHB (une cétone majeure) peut être un carburant encore plus efficace que le glucose, fournissant plus d'énergie par unité d'oxygène utilisée. Un régime cétogène augmente également le nombre de mitochondries, appelées 'usines d'énergie' dans les cellules cérébrales. Une étude récente a révélé une expression améliorée des gènes codant pour les enzymes mitochondriales et le métabolisme énergétique dans l'hippocampe, une partie du cerveau importante pour l'apprentissage et la mémoire. Les cellules de l'hippocampe dégénèrent souvent en maladies cérébrales liées à l'âge, entraînant un dysfonctionnement cognitif et une perte de mémoire. Avec une réserve d'énergie accrue, les neurones peuvent être capables de conjurer les facteurs de stress de la maladie qui épuiseraient et tueraient généralement la cellule.",
	sideeffectsh0: 'Dangers',
	sideeffectsp0:
		'Par mesure de précaution, vous devez toujours consulter votre médecin généraliste avant de commencer un régime cétogène. Vous devriez être particulièrement fatigué si vous ou un membre de votre famille avez des problèmes rénaux, cardiaques ou diabétiques préexistants.',
	sideeffectsh1: 'Ostéoporose et calculs rénaux',
	sideeffectsp1:
		"Il a également été démontré que les régimes riches en protéines amènent les gens à excréter une grande quantité de calcium dans leurs urines. Sur une période prolongée, cela peut augmenter le risque d'ostéoporose et de calculs rénaux. Un régime qui augmente les protéines au détriment d'un apport très restrictif en glucides végétaux peut être mauvais pour les os, mais pas nécessairement un apport riche en protéines à lui seul.",
	sideeffectsh2: 'Insuffisance rénale',
	sideeffectsp2:
		'Consommer trop de protéines exerce une pression sur les reins, ce qui peut rendre une personne vulnérable aux maladies rénales.',
	sideeffectsh3: 'Thyroïde, cœur, tension artérielle',
	sideeffectsp3:
		"Il existe peu de preuves basées sur la recherche qui pourraient suggérer que la cétose peut provoquer une augmentation de courte durée des niveaux d'adrénaline et de cortisol dans le corps pour un petit sous-groupe de personnes. À leur tour, ces niveaux accrus d'hormones peuvent causer des problèmes de thyroïde, des irrégularités cardiaques. et une pression artérielle élevée pour certains. ",
	sideeffectsh4: 'Effets secondaires',
	sideeffectsp4:
		"À mesure que votre corps devient induit dans un état cétogène, votre corps utilisera naturellement ce qui reste de votre glucose. Ainsi, le corps sera épuisé de tout stockage de glucose dans les muscles, ce qui peut entraîner un manque d'énergie et une léthargie générale. Au cours des deux premières semaines, les gens signalent:",
	sideeffectsbq1:
		"\"Au cours des premiers jours à quelques semaines d'adaptation à faible teneur en glucides, votre corps supprime les enzymes de travail des glucides et construit de nouvelles enzymes de travail des graisses. Une fois que la main-d'œuvre dans votre corps a changé, vous commencez à fonctionner correctement sur votre nouveau bas -carbe, régime riche en graisses. Les glucides que vous brûliez pour l'énergie sont maintenant remplacés dans une large mesure par des cétones ... \"- Mike Eades MD",
	sideeffectsh5: 'Keto Flu',
	sideeffectsp5: 'Symptômes pseudo-grippaux',
	sideeffectsh6: 'Maux de tête',
	sideeffectsp6:
		'La cétose peut causer des maux de tête à certaines personnes et vous pourriez vous sentir un peu étourdi.',
	sideeffectsh7: 'Fatigue et étourdissements',
	sideeffectsp7:
		"Lorsque vous commencez à déverser de l'eau, vous perdrez des minéraux tels que le sel, le potassium et le magnésium. Avoir des niveaux inférieurs de ces minéraux vous rendra très, très fatigué, étourdi ou étourdi, vous donnera des crampes musculaires et des maux de tête. l'effet secondaire le plus courant des régimes à faible teneur en glucides, et il peut être évité pour la plupart en vous assurant d'obtenir suffisamment de minéraux de remplacement. ",
	sideeffectsh8: "Épisodes d'hypoglycémie",
	sideeffectsp8:
		"Le corps a l'habitude de libérer une certaine quantité d'insuline pour prendre soin du glucose qui est créé à partir de tous les glucides que vous mangez. Lorsque vous ajoutez votre apport en glucides, il peut y avoir au début des épisodes occasionnels d'hypoglycémie",
	sideeffectsh9: 'Brouillard mental',
	sideeffectsp9:
		"Il n'est pas rare d'avoir un manque de clarté mentale au départ. Dans la plupart des cas, le brouillard cérébral ne durera que quelques jours.",
	sideeffectsh10: 'Urination fréquente',
	sideeffectsp10:
		"Après avoir commencé, vous remarquerez que vous allez aux toilettes plus souvent. Le corps brûle le glucose restant (du glycogène stocké) dans votre foie et vos muscles. La décomposition du glycogène libère beaucoup d'eau et vos reins va commencer à le vider. De plus, à mesure que votre taux d'insuline circulante baisse, les reins commencent à excréter le surplus de sodium, ce qui entraînera également des mictions plus fréquentes. ",
	sideeffectsh11: 'Constipation',
	sideeffectsp11:
		"C'est l'un des effets secondaires les plus courants et est généralement le résultat d'une déshydratation, d'une perte de sel et d'un manque de magnésium. Assurez-vous de rester hydraté et de boire beaucoup d'eau tout au long de la journée. N'oubliez pas de manger suffisamment de fibres de légumes et vous pouvez même prendre une cuillère à café ou deux de fibres de cosse de psyllium par jour.",
	sideeffectsh12: 'Envie de glucides',
	sideeffectsp12:
		"Le corps s'adapte toujours à la vie sans glucides comme le sucre. Des envies intenses de féculents comme le pain seront présentes pendant environ une semaine.",
	sideeffectsh13: 'La diarrhée',
	sideeffectsp13:
		'Cela arrive généralement aux personnes qui décident imprudemment de limiter leur consommation de graisses avec un régime pauvre en glucides, ce qui entraîne une consommation excessive de protéines.',
	sideeffectsh14: 'Crampes musculaires',
	sideeffectsp14:
		'La perte de minéraux, en particulier de magnésium, est le facteur le plus susceptible de provoquer des crampes.',
	sideeffectsh15: "Niveaux de gymnastique et d'exercice",
	sideeffectsp15:
		"Au début, il est normal de perdre un peu de force et d'endurance. Ne vous inquiétez pas, dès que votre corps sera adapté à la cétose, vous pourrez utiliser complètement les graisses comme principale source d'énergie. De nombreuses études le montrent. n'est pas une baisse de performance à long terme pour les athlètes.",
	sideeffectsh16: 'Électrolytes',
	sideeffectsp16:
		"Étant donné que la cétose a un effet diurétique sur le corps, les effets secondaires sont la plupart du temps le résultat du rinçage des électrolytes. Assurez-vous de boire beaucoup d'eau et de maintenir votre apport en sel / sodium. Vous devriez devenir fou de sel avec tous vos aliments. Cela facilite la rétention d'eau et aide à reconstituer les électrolytes.",
	sideeffectsh17: 'Conclusion',
	sideeffectsp17:
		"Ces effets secondaires cétogènes ne sont en réalité que des problèmes mineurs et ne surviennent qu'au cours des premières semaines. Ils s'amélioreront et finiront par disparaître, puis vous bénéficierez de tous les avantages du régime céto.",
	mythsh0: 'Le plus gros mythe',
	mythsp0:
		"La majorité des personnes qui abandonnent le régime céto échouent parce qu'elles ne se sentent pas à l'aise d'augmenter massivement leur apport en graisses, en particulier en graisses saturées.",
	mythsh1: 'Riche en glucides + en gras = mauvais',
	mythsp1:
		"Les régimes riches en glucides augmentent les taux de sucre dans le sang et d'insuline. Des niveaux élevés de sucre et d'insuline sont corrélés à une inflammation élevée. La plupart des régimes américains sont riches en sucre et en graisses saturées. Lorsqu'ils ont été étudiés, ces deux facteurs ont été combinés et non étudiés séparément.  N  n Les graisses saturées ont été accusées de l'inflammation qui cause les maladies cardiaques, car elles ont été étudiées en association avec un régime riche en glucides. Les régimes cétogènes, riches en graisses saturées et très faibles en glucides, minimiseront l'inflammation.",
	mythsbq1:
		"Une étude de John Hopkins a confirmé que les graisses saturées ne sont pas nocives dans le contexte d'un régime pauvre en glucides et que la cause des maladies cardiaques est une consommation chronique élevée de glucides, et non un apport élevé en graisses saturées et en cholestérol. En d'autres termes, les graisses nous font grossir lorsqu'elles sont associées à des niveaux élevés de glucides.",
	mythsh2: 'Et les protéines?',
	mythsp2:
		"Il est essentiel pour la cétose que vos niveaux de protéines ne dépassent pas celui des graisses en calories, pas en poids. En effet, les protéines à des niveaux excessifs agissent de manière très similaire aux glucides. Ils sont finalement décomposés en glucose, ce qui augmente ensuite votre glycémie et provoque une poussée d'insuline.",
	mythsh3: 'La cétose est dangereuse et nocive',
	mythsp3:
		"La cétose, qui est un état métabolique bénéfique, est souvent confondue avec l'acidocétose, qui est quelque chose de complètement différent et une condition médicale grave. L'acidocétose est causée par l'incapacité du corps à réguler correctement la production de cétones. Elle est le plus souvent ressentie par les alcooliques et le type 1 diabétique.",
	mythsh4: '"Ce n\'est pas maintenable"',
	mythsp4:
		"S'en tenir à un régime peut être plus facile que vous ne le pensez. Si vous mangez beaucoup au restaurant, en particulier dans la restauration rapide, vous serez tenté par la commodité et vous aurez peut-être des difficultés. Il en va de même si vous conservez beaucoup de féculents et de sucre la nourriture dans votre garde-manger. Cependant, si vous vous éloignez et ne vous exposez pas à ces aliments, vous le remarquerez à peine. Les fringales disparaîtront après la première semaine ou deux, sinon plus tôt ",
	mythsh5: '"Vous ne pouvez pas obtenir toutes vos vitamines"',
	mythsp5:
		"On obtiendra beaucoup plus de la plupart des vitamines et des minéraux avec un régime cétogène approprié qu'avec un régime typique. La principale exception est la vitamine C, qui n'est nécessaire qu'en quantités infimes si vous suivez un régime riche en glucides. les légumes-feuilles seront plus que suffisants en vitamine C.",
	mythsh6: 'Keto vous causera des lésions rénales',
	mythsp6:
		"Les personnes atteintes d'une maladie rénale très avancée ont du mal à gérer de grandes quantités de protéines alimentaires. Cependant, il n'y a aucune recherche nulle part qu'un régime riche en protéines provoque une maladie rénale. Il peut y avoir un risque accru de calculs rénaux en raison de l'augmentation des niveaux d'acide urique dans le du sang, mais c'est facilement évité en buvant beaucoup de liquides, et si vous voulez être très prudent, prenez des suppléments de citrate de potassium. ",
	mythsh7: '"Je ne veux pas mourir de faim"',
	mythsp7:
		'Les graisses et les protéines sont digérées beaucoup plus lentement que les glucides, vous remarquerez donc que vous passez de plus longues périodes sans avoir faim.',
	mythsh8: 'Keto vous donne une mauvaise haleine',
	mythsp8:
		"Lorsque vous êtes en cétose, c'est certainement détectable dans votre haleine. Ce que vous sentez, ce sont des cétones, des molécules à l'odeur fruitée converties à partir de la graisse pour être utilisées comme carburant par les cellules. Si vous êtes celui en cétose, c'est en fait assez satisfaisant de savoir que vous expirez réellement des calories! Mâchez simplement de la gomme sans sucre si cela vous dérange. ",
	getstartedh0: 'Comment commencer',
	getstartedp0:
		"Dans l'ensemble, manger une grande quantité de graisses, une quantité modérée de protéines et une quantité limitée de glucides aura un impact considérable sur votre santé - abaissant votre cholestérol, votre poids corporel, votre glycémie et augmentant votre énergie et votre humeur.  n  nUne fois que vous êtes dans la mentalité de commencer une approche plus réaliste et plus saine pour perdre de la graisse corporelle - un mode de vie faible en glucides ne semble pas aussi mauvais. Donnez-lui quelques semaines pour surmonter la bosse initiale, et ces effets secondaires désagréables s'atténueront.  n  nUn point important sur un régime céto: soyez prêt à passer plus de temps dans la cuisine. Un menu céto consiste à cuisiner et à manger de vrais aliments. Si vous ne savez pas déjà cuisiner, ce sera une bonne expérience d'apprentissage pour vous.  n  nVous devez utiliser la section calculatrice de cette application pour calculer vos macros spécifiques mentionnées ci-dessous",
	getstartedh1:
		"1. Réapprovisionnez votre cuisine afin qu'il n'y ait pas de tentations pour vous.",
	getstartedh2:
		'2. Réduisez la consommation quotidienne de glucides à environ 20 g, mais tout ce qui est inférieur à 50 g est acceptable.',
	getstartedh3:
		'3. Augmentez considérablement la proportion de graisses dans votre alimentation.',
	getstartedh4:
		"4. Apportez l'apport en protéines de 0,6 à 1 g par livre de masse corporelle maigre. (1,3 à 2,2 g par kg).",
	getstartedh5:
		"5. Augmentez considérablement la quantité d'eau que vous consommez.",
	getstartedh6:
		"6. Augmenter l'apport d'électrolytes tels que le sel, le potassium et le magnésium.",
	fiberh0: 'Fibre - Obtenez-en!',
	fiberh1: 'Les sources de fibres céto comprennent:',
	fiberh2: 'La graine de lin',
	fiberh3: 'Graines de chia',
	fiberh4: 'Légumes proches de toutes les fibres',
	fiberh5: 'Suppléments de fibres',
	fiberh6: 'Plus de fibres que de glucides utilisables:',
	fiberh7: 'Son de blé',
	fiberh8: 'Noix de coco et farine de noix de coco non sucrée',
	fiberh9: 'Céréales riches en fibres',
	fiberh10: 'Collard Greens',
	fiberh11: 'Avocat, Hass',
	fiberh12: 'Épinards et blettes',
	fiberh13: 'Brocoli',
	fiberh14: 'Choufleur',
	fiberh15: 'Mûres',
	fiberh16: 'Environ autant de glucides utilisables que de fibres:',
	fiberh17: 'Asperges',
	fiberh18: 'Céleri',
	fiberh19: 'Aubergine',
	fiberh20: 'Laitue romaine',
	fiberh21: 'Champignons',
	fiberh22: 'Des radis',
	fiberh23: 'Framboises rouges',
	fiberh24:
		'Riche en fibres, mais pas autant de fibres que les glucides utilisables:',
	fiberh25: 'Son de riz',
	fiberh26: 'Chou',
	fiberh27: 'Poivrons',
	fiberh28: 'Pois mange-tout (gousse comestible)',
	fiberh29: 'Courge courgette',
	fiberh30: 'Fraises',
	fiberp0:
		"La fibre est une forme de sucre appelée cellulose. Nous n'avons pas les enzymes nécessaires pour décomposer la cellulose, elle n'active donc pas la glycolyse et n'affectera pas les niveaux d'insuline. Les aliments riches en fibres peuvent être consommés sans danger avec le céto et doivent être consommés tout au long de la journée. Il est important de noter que les fibres NE comptent PAS dans votre apport en glucides.  N  nPar exemple, si votre repas contient 10 g de glucides au total et 5 g de fibres, l'apport réel en glucides pour le repas est de 5 g (10 g à 5 g) . Bien que les fibres soient un glucide, elles ne sont pas digérées comme un simple glucide et ne sont donc pas incluses dans votre nombre quotidien de glucides.",
	fiberp1:
		'Il est important de souligner que les fibres ne NÉGÈRENT pas les glucides - elles ne sont tout simplement pas comptées; donc mélanger une poignée de farine de lin dans un bol de crème glacée ne fonctionnera pas!',
	fiberp2:
		"La plupart des gens tirent leurs fibres de céréales et l'élimination de ce groupe alimentaire pour adopter votre régime cétogène peut entraîner une diminution considérable de votre apport quotidien en fibres.",
	fiberp3:
		'Les directives diététiques de 2010 pour les Américains recommandent de consommer entre 25 et 38 g de fibres par jour, & nbsp; mais une personne suivant un régime céto peut avoir besoin de plus.',
	fiberp4:
		"Il n'y a presque pas de glucides utilisables dans les graines de lin. Il est très riche en fibres solubles et insolubles (environ un tiers de la fibre est soluble), et a un tas de nutriments pour démarrer. Le lin est l'une des meilleures sources de fibres à intégrer dans votre alimentation.",
	fiberp5:
		'Avoir un profil de fibres et de glucides similaire à celui des graines de lin.',
	fiberp6: 'Feuilles de moutarde, chicorée, endives',
	fiberp7: 'Comme la poudre de cosse de psyllium',
	fiberp8:
		'½ tasse crue, 3 grammes de glucides utilisables, 6 grammes de fibres',
	fiberp9: '1 once, 2 grammes de glucides utilisables, 5 grammes de fibres',
	fiberp10:
		'Vérifiez attentivement les étiquettes, mais quelques céréales riches en fibres sont également faibles ou assez faibles en glucides. Exemples - Tout son avec fibre supplémentaire; Fibre One',
	fiberp11:
		'1 tasse hachée, cuite, 4 grammes de glucides utilisables, 5 grammes de fibres',
	fiberp12:
		'1 avocat moyen, 3 grammes de glucides utilisables, 12 grammes de fibres',
	fiberp13:
		"Congelé 1 paquet de 10 oz, 10 g de glucides au total, 6 g de fibres. 6 tasses d'épinards crus ou de blettes = environ 1 tasse cuite",
	fiberp14:
		'1/2 tasse hachée, cuite, 1 gramme de glucides utilisables, 3 grammes de fibres. 1 tasse hachée, crue, 4 grammes de glucides utilisables, 2 grammes de fibres',
	fiberp15:
		'1/2 tasse de morceaux, cuits, 1 gramme de glucides utilisables, 2 grammes de fibres. 1 tasse crue, 2 grammes de glucides utilisables, 2,5 grammes de fibres',
	fiberp16:
		'1 tasse, cru, 6 grammes de glucides utilisables, 8 grammes de fibres',
	fiberp17:
		'1/2 tasse de morceaux, 2 grammes de glucides utilisables, 2 grammes de fibres',
	fiberp18:
		'1 tasse hachée, 1,5 gramme de glucides utilisables, 1,5 gramme de fibres',
	fiberp19:
		'1 tasse crue, en cubes, 2 grammes de fibres utilisables, 3 grammes de fibres. 1 tasse en cubes, cuit, 5 grammes de glucides utilisables, 3 grammes de fibres',
	fiberp20: '1 tasse râpée, 0,5 gramme de glucides utilisables, 1 g de fibres',
	fiberp21:
		'1 tasse, tranchée, crue, 1 gramme de glucides utilisables, 1 gramme de fibre',
	fiberp22:
		'1 tasse crue, tranchée, 2 grammes de glucides utilisables, 2 grammes de fibres',
	fiberp23:
		'1 tasse, cru, 7 grammes de glucides utilisables, 8 grammes de fibres',
	fiberp24: '1/4 tasse 8 grammes de glucides utilisables, 6 grammes de fibres',
	fiberp25:
		'1 tasse crue, hachée, 3 grammes de glucides utilisables, 2 grammes de fibres. 1/2 tasse cuit, haché, 2 grammes de glucides utilisables 1 gramme de fibres',
	fiberp26:
		'1 tasse hachée, crue, 4 grammes de glucides utilisables, 3 grammes de fibres',
	fiberp27:
		'1 tasse entière, crue, 3 grammes de glucides utilisables, 2 grammes de fibres',
	fiberp28:
		'1 tasse cuite, tranchée, 4 grammes de glucides utilisables, 3 grammes de fibres',
	fiberp29:
		'1/2 tasse en tranches, 5 grammes de glucides utilisables, 2 grammes de fibres',
	tipsh1: 'Faites un balayage des glucides.',
	tipsp1:
		'Inspectez les armoires de cuisine et le réfrigérateur, et retirez tous les aliments riches en glucides. Cela évitera les tentations plus tard sur la route.',
	tipsh2:
		'Faites un bilan de santé et un test sanguin avant de commencer le régime.',
	tipsp2:
		"Prévoyez d'avoir encore quatre à huit semaines après le début. De cette façon, vous pouvez suivre l'effet du changement de vos habitudes alimentaires sur votre cholestérol, votre tension artérielle et d'autres marqueurs de santé.",
	tipsh3: 'Achetez Ketostix.',
	tipsp3:
		"Pour vérifier si vous êtes en cétose ou non. Ketostix est conçu pour mesurer la quantité de cétones dans votre urine. Récemment, un lecteur de cétones sanguines est devenu disponible pour suivre la cétose à la maison. C'est un moyen beaucoup plus précis de suivre vos niveaux de cétone individuels.",
	tipsh4: 'Pensez à vos repas et à la façon dont vous les planifierez.',
	tipsp4:
		"Cela vous aidera à acheter les bons aliments à l'épicerie et vous donnera un cadre à suivre lorsque l'heure du repas arrive. Si vous savez que vous êtes censé avoir du saumon et du brocoli pour le dîner ce soir, il est beaucoup plus facile d'éviter de choisir les vieux aliments riches en glucides que vous aviez l'habitude de manger. ",
	tipsh5: 'Restez hydraté.',
	tipsp5:
		"À mesure que l'apport en glucides diminue, les reins commenceront à évacuer l'excès d'eau que le corps a retenu en raison d'un apport élevé en glucides. Assurez-vous de boire suffisamment d'eau pour remplacer ce qui est perdu.",
	tipsh6: 'Pensez à toutes les situations sociales qui seront rencontrées.',
	tipsp6:
		"Concevez des moyens de gérer ces moments où les tentations de manger' à l'ancienne 'seront fortes. Vous ne voulez pas être aveuglé lorsque quelqu'un au bureau apporte une boîte de vos chocolats préférés et les met juste sous votre nez. De même, une bière entre amis se transforme généralement en rendez-vous avec des peaux de pommes de terre et des nachos. Pensez plutôt à la salade et au steak.",
	tipsh7: 'Ne vous concentrez pas sur votre poids.',
	tipsp7:
		"Ne vous pesez pas tous les jours. Votre poids peut varier entre 2 et 4 livres par jour en raison des changements dans la consommation et l'absorption d'eau. Vous ne pourrez pas suivre avec précision toute perte de graisse au quotidien, et les fluctuations vont vous rendre fou si vous vous concentrez sur eux. Au lieu de cela, pesez-vous une fois par semaine ou faites le suivi de vos mensurations pour suivre vos progrès. ",
	makes: 'Fait du',
	servings: 'Portions',
	fat: 'Graisse',
	carbs: 'Glucides',
	totalcarb: 'Total de glucides',
	netcarb: 'Glucides nets',
	protein: 'Protéine',
	calories: 'Calories',
	zeroResults: '0 résultat',
	loadMore: 'Charger plus',
	likes: 'Aime',
	comments: 'Commentaires',
	netcarbFinder: 'Net Carb Finder',
	search: 'Chercher',
	searchFilter: 'Filtre de recherche',
	barcode: 'Code à barre',
	offline: 'Hors ligne',
	results: 'Résultats',
	barcodeNotRecognized: 'Code à barres non reconnu',
	barcodeNotRecognizedDetail:
		"Essayez de rechercher dans la base de données ou de l'ajouter comme aliment personnalisé.",
	clearAllRecentlyScanned: 'Effacer tout récemment analysé?',
	clearAllConfirm:
		'Cela effacera vos codes-barres récemment scannés sur tous les appareils synchronisés',
	requestCamera: "Demande d'autorisation de caméra",
	noAccessCamera: "Pas d'accès à la caméra",
	scanBarcode: 'Scanner le code-barres',
	itemFound: 'Objet trouvé',
	itemNotFoundFS: 'Objet introuvable dans le gros secret',
	ItemNotFoundFSdesc:
		"Essayez de rechercher dans la base de données ou de l'ajouter comme aliment personnalisé.",
	generic: 'Générique',
	basedDiet: '% basé sur un régime de 2000 calories',
	all: 'Tout',
	alcohol: "De l'alcool",
	beverages: 'Breuvages',
	cheeses: 'Les fromages',
	dairy: 'Laitier',
	dressings: 'Pansements (étiquettes de contrôle)',
	fatsoils: 'Graisses et huiles',
	fish: 'Poisson',
	flours: 'Farines ou repas',
	fowl: 'Volaille',
	fruits: 'Des fruits',
	grainsstarch: 'Grains & Amidon',
	meats: 'Viandes',
	nutslegumes: 'Noix et légumineuses',
	seeds: 'Des graines',
	shellfish: 'Fruits de mer',
	sugarssweetener: 'Sucres et édulcorants',
	vegetables: 'Des légumes',
	calculatorDetails:
		'Nous avons besoin de quelques détails pour former votre plan céto personnalisé',
	height: 'La taille',
	weight: 'Poids',
	age: 'Âge',
	gender: 'Le genre',
	male: 'Masculin',
	female: 'Femme',
	exerciseLevel: "Niveau d'exercice",
	littleToNone: 'Peu ou aucun',
	exerciseOne: '1-3 fois / semaine',
	exerciseTwo: '3-5 fois / semaine',
	exerciseThree: '6-7 fois / semaine',
	exerciseFour: '2 fois / jour',
	goalWeight: 'Objectif de poids',
	faster: 'Plus rapide',
	slower: 'Ralentissez',
	weightPlan: 'Votre plan de poids',
	maintain: 'Maintenir',
	easier: 'Plus facile',
	mild: 'Bénin',
	moderate: 'Modérer',
	difficult: 'Difficile',
	customize: 'Personnaliser',
	dailyCalories: 'Calories quotidiennes',
	week: 'La semaine',
	lose: 'Perdre',
	by: 'par',
	bodyFat: 'Graisse corporelle',
	bodyFatDetails:
		'Votre pourcentage de graisse corporelle sera utilisé pour calculer votre quantité idéale de protéines en fonction de votre masse musculaire totale.',
	proteinDetail:
		'Trop de protéines peut briser la cétose, il vous en faut donc juste assez pour maintenir le muscle et un peu plus pour le construire.',
	dailyProtein: 'Protéine quotidienne',
	idealRange: 'Votre gamme idéale est',
	maintainLower: 'maintenir',
	build: 'construire',
	maintainMuscle: 'Maintenir le muscle',
	buildMuscle: 'Se muscler',
	netcarbDescription:
		'Manger plus de 50 g de glucides nets par jour suffit pour briser la cétose. La plupart des personnes à la diète céto mangent environ 20g-30g. Moins vous mangez de glucides, moins vous avez de chances de briser la cétose.',
	language: 'Langue',
	languageUpdated: 'Langue mise à jour',
	english: 'Anglais',
	french: 'Français',
	spanish: 'Espagnol',
	portuguese: 'Portugais',
	german: 'Allemand',
	logout: 'Se déconnecter',
	loggedOut: 'Déconnecté',
	unitsUpdated: 'Unités mises à jour',
	featureRequest: 'Demande de fonctionnalité',
	deleteAccount: 'Supprimer le compte',
	verifyDeleteMessage:
		'Cela supprimera votre compte et toutes les données, êtes-vous sûr?',
	accountDeleted: 'Compte supprimé',
	net: 'Net',
	total: 'Total',
	preferredCarb: 'Carbone préféré',
	preferredCarbDesc:
		'Les glucides totaux comprennent les fibres et les alcools de sucre, tandis que les glucides nets incluent uniquement les glucides dénombrables qui affectent la cétose. La plupart des personnes à la diète céto ne suivent que les glucides nets.',
	updateMacroGoals: 'Mettre à jour les objectifs de macro',
	defaultMacroGoalsUpdated: 'Objectifs de macro par défaut mis à jour',
	customGoalsApplied: 'Objectifs personnalisés appliqués aux dates',
	tracking: 'Suivi',
	recalculateMacroGoals: 'Recalculer les objectifs macroéconomiques',
	manuallyChangeMacroGoals: 'Changer manuellement les objectifs de macro',
	changePreferences: 'Modifier les préférences',
	otherTracking: 'Autre suivi',
	other: 'Autre',
	notes: 'Remarques',
	invalidCode: 'Code invalide',
	purchaseRestored: 'Achat restauré',
	userNotFound: 'Utilisateur non trouvé',
	error: 'Erreur',
	errorProducts:
		"Une erreur s'est produite lors du chargement des produits. Assurez-vous que vous disposez d'une connexion Internet valide.",
	trackerEnabledAdsRemoved: 'Tracker activé, annonces supprimées',
	purchaseTracking: 'Suivi des achats',
	gettingProductDetails: 'Obtenir les détails du produit ...',
	noPurchaseFound: 'Aucun achat trouvé',
	noPurchaseFoundMore:
		"Aucun achat n'a été trouvé sur ce compte. Veuillez nous envoyer un e-mail si vous pensez que c'est incorrect.",
	account: 'Compte',
	signIn: 'Se connecter',
	logIn: "S'identifier",
	signedIn: 'Connecté',
	accountCreated: 'Compte créé',
	signUp: "S'inscrire",
	email: 'Email',
	password: 'Mot de passe',
	forgotPassword: 'Mot de passe oublié?',
	resetEmailSent: "Réinitialiser l'e-mail envoyé",
	newUser: 'Nouvel utilisateur?',
	emailError: 'Entrer une adresse email valide',
	passwordError: 'Entrer un mot de passe',
	emailSubscribe: 'Recevez des mises à jour et des offres spéciales de MyKeto',
	enterYourEmail: 'Entrer votre Email',
	medicalDisclaimer: 'Clause de non-responsabilité médicale',
	disclaim:
		"Clause de non-responsabilité médicale - MyKeto est proposé à des fins éducatives et de divertissement uniquement, et n'a en aucun cas l'intention de diagnostiquer, de guérir ou de traiter une condition médicale ou autre. Demandez toujours l'avis de votre médecin ou d'un autre professionnel de la santé qualifié avant de modifier votre régime alimentaire ou votre programme d'exercice et posez à votre médecin toutes les questions que vous pourriez avoir concernant un problème de santé. Bien que nous fassions de notre mieux pour vérifier l'exactitude des informations contenues dans ce document, nous ne pouvons garantir leur exactitude.",
	skipAccount: 'Ignorer le compte',
	registerAccount: 'Créer un compte',
	convertAccount: 'Convertir le compte en connexion',
	createAccountLater:
		'Vous pouvez créer un compte plus tard avec vos données en allant dans le menu Paramètres',
	whyLogin: 'Pourquoi se connecter?',
	usingAccount:
		"L'utilisation d'un compte sauvegardera et synchronisera vos données sur tous vos autres appareils. L'enregistrement ne prend qu'une seconde et nous ne partageons ni ne stockons aucune information personnelle.",
	totalcarbError: 'Le total ne peut pas être inférieur à la fibre',
	auto: 'Auto',
	manual: 'Manuel',
	name: 'Nom',
	editingItem: "Modification de l'élément",
	foods: 'Nourriture',
	meals: 'Repas',
	meal: 'Repas',
	favorites: 'Favoris',
	recent: 'Récents',
	recentlyused: 'Utilisé récemment',
	saveToFoods: 'Enregistrer dans les aliments',
	searched: 'Cherché',
	myfoods: 'Mes aliments',
	mymeals: 'Mes repas',
	quickmacro: 'Macros rapides',
	create: 'Créer',
	created: 'Créé',
	createFood: 'Créer de la nourriture',
	createMeal: 'Créer un repas',
	ingredientNotFound: "Ingrédient introuvable, essayez de l'ajouter à nouveau",
	totalServings: 'Nombre total de portions',
	totalServingsDesc:
		'Veuillez saisir un nombre pour le nombre total de portions',
	clearAllFavorites: 'Effacer tous les favoris?',
	clearAllFavoritesDesc:
		'Cela effacera tous vos favoris sur tous les appareils synchronisés',
	clearAllRecentlyUsed: 'Effacer tous les articles récemment utilisés?',
	clearAllRecentlyUsedConfirm:
		'Cela effacera vos éléments récemment enregistrés sur tous les appareils synchronisés',
	removedFromRecentlyUsed: 'Supprimé des articles récemment utilisés',
	itemsAdded: 'Articles ajoutés',
	mealName: 'Nom du repas',
	servingName: 'Nom de la portion',
	wholeRecipe: 'Recette entière',
	clickStart: 'Vous êtes prêt, cliquez sur le bouton ci-dessous pour commencer',
	privacyPolicy: 'Politique de confidentialité',
	termsConditions: 'Termes et conditions',
	policyAgree: 'En cliquant ci-dessous, vous acceptez nos',
	unitsOnboard:
		"Choisissez vos unités préférées pour les mesures corporelles (poids, tour de poitrine, etc.) et l'eau",
	clockOnboard:
		'Choisissez comment vous voulez que votre affichage du minuteur de début et de fin soit formaté',
	scheduleOnboard:
		'Choisissez si vous souhaitez recevoir des notifications à chaque fois que vous changez de fenêtre et si vous souhaitez un rappel avant la fin de la période.',
	manualOnboardSettings:
		"Choisissez votre objectif rapide par défaut (vous pouvez le modifier rapidement sur la page d'accueil) et si vous souhaitez choisir votre objectif en heures totales ou si vous souhaitez sélectionner une date et une heure pour votre objectif.",
	manualOnboardNotifications:
		"Choisissez si vous souhaitez recevoir des notifications telles qu'un rappel spécifique de jeûner tous les jours à une certaine heure et des rappels lorsque le jeûne est terminé ou est sur le point de se terminer bientôt.",
	weightLoss: 'Perte de poids',
	diseasePrevention: 'Prévention des maladies',
	lowerBloodPressure: 'Pression artérielle basse',
	improvedCholesterolProfile: 'Profil de cholestérol amélioré',
	antiAging: 'Anti-âge et longévité',
	insulinManagement: "Gestion de l'insuline et du diabète",
	antiCancer: 'Anti-Cancer',
	heartHealth: 'Santé cardiaque',
	improvedSkin: 'Peau améliorée',
	dentalHealth: 'Santé dentaire',
	whyKeto: 'Pourquoi vous intéressez-vous au régime cétogène?',
	betterTailor:
		'Nous allons mieux adapter MyKeto pour vous aider à atteindre vos objectifs',
	goals: 'Buts',
	goalsExplanation:
		'Nous utiliserons ces informations pour vous aider à prévoir vos progrès',
	finished: 'Fini',
	startTracking: 'Démarrer le suivi',
	weightUnits: 'Mesures du poids et du corps',
	units: 'Unités',
	metric: 'Métrique',
	imperial: 'Impérial',
	ftcm: 'ft, lbs',
	bodyMeasurements: 'Des mesures',
	bmi: 'IMC',
	leanBodyMass: 'Masse corporelle mince',
	waistSize: 'Tour de taille',
	hipSize: 'Tour de hanches',
	chestSize: 'Tour de poitrine',
	neckSize: 'Taille du cou',
	armSize: 'Taille du bras',
	forearmSize: "Taille de l'avant-bras",
	calfSize: 'Taille du mollet',
	thighSize: 'Tour de cuisse',
	enterNumber: 'Veuillez saisir un nombre supérieur à zéro.',
	vitals: 'Vitals',
	water: 'Eau',
	bloodGlucose: 'Glucose sanguin',
	exercise: 'Exercice',
	bloodPressure: 'Pression artérielle',
	heartRate: 'Rythme cardiaque',
	ketones: 'Cétones',
	ketoneLevels: 'Niveaux de cétone',
	potassium: 'Potassium',
	sodium: 'Sodium',
	magnesium: 'Magnésium',
	systolic: 'Systolique',
	diastolic: 'Diastolique',
	breakfast: 'Déjeuner',
	lunch: 'Le déjeuner',
	dinner: 'Dîner',
	snacks: 'Collations',
	quickAdd: 'Ajout rapide',
	quickAddMacros: 'Ajout rapide de macros',
	quickAddToMeal: 'Ajout rapide au repas',
	copyTo: 'Copier',
	saveAsMeal: 'Enregistrer comme repas',
	addEntry: 'Ajouter une entrée',
	caloriesFromMacros: 'Calories des macros',
	eaten: 'Mangé',
	remaining: 'Restant',
	macroGoalsEaten: 'Objectifs macro consommés',
	per: 'Par',
	syncExercise: 'Exercice de synchronisation',
	estimateCalories: 'Estimer les calories',
	selectTime: "Sélectionnez l'heure",
	duration: 'Durée',
	caloriesBurned: 'Calories brûlées',
	addExerciseCal: "Ajouter un exercice d'étalonnage",
	custom: 'Douane',
	oneWeek: '1 semaine',
	oneMonth: '1 mois',
	threeMonths: '3 mois',
	sixMonths: '6 mois',
	oneYear: '1 an',
	allTime: 'Tout le temps',
	to: 'À',
	addTwoEntries: 'Ajouter 2 entrées ou plus',
	netChange: 'Changement net',
	noEntriesFound: 'Aucune entrée trouvée',
	addFoodEntries:
		"Ajoutez des entrées d'aliments à votre journal pour voir les données",
	fitbitDesc:
		"Envoyez vos données de poids, d'eau et de macros MyKeto dans Fitbit. Envoyez votre activité physique Fitbit dans MyKeto",
	fitbitConnected: 'Synchronisation Fitbit connectée',
	fitbitRemove: "Voulez-vous supprimer l'accès Fitbit de cet appareil?",
	fitbitDisconnected: 'Fitbit déconnecté',
	healthkit1:
		"Pour mettre à jour vos autorisations pour Apple Healthkit, ouvrez l'application du kit de santé sur votre téléphone, appuyez sur les sources et sélectionnez MyKeto dans la liste",
	healthkit2:
		"Envoyez vos données de poids, d'eau et de macros MyKeto dans Apple HealthKit",
	friendRequest: "Demande d'ami",
	tryAddFriend:
		"Essayez d'ajouter un ami par e-mail avec le bouton (+). Une fois accepté, vous pouvez copier les aliments et les repas de chacun.",
	alreadyCopied: 'Déjà copié',
	sent: 'Envoyé',
	received: 'Reçu',
	alreadyInvited: 'Déjà invité',
	alreadyPendingInvite: 'Cet utilisateur a déjà une invitation en attente',
	alreadyFriended: 'Déjà ami',
	alreadyYourFriend: 'Cet utilisateur est déjà votre ami',
	pendingInvite: "En attente d'invitation",
	pendingAlready: 'Cet utilisateur vous a déjà envoyé une invitation',
	friendRequestSent: "Demande d'ami envoyée",
	invalidEmail: 'Email invalide',
	cantFriendYourself: 'Vous ne pouvez pas vous ami',
	friendRequestCanceled: "Demande d'ami annulée",
	friendRequestAccepted: "Demande d'ami acceptée",
	friendRequestDeclined: "Demande d'ami refusée",
	unfriended: 'Sans ami',
	allFoodsCopied: 'Tous les aliments copiés',
	exerciseLabel1: 'Danse aérobie (occasionnel)',
	exerciseLabel2: 'Danse aérobie (modérée)',
	exerciseLabel3: 'Danse aérobie (intense)',
	exerciseLabel4: 'Basketball (terrain complet)',
	exerciseLabel5: 'Basketball (demi-terrain)',
	exerciseLabel6: 'Gymnastique suédoise',
	exerciseLabel7: 'Cyclisme (5,5 mph, 9 km / h)',
	exerciseLabel8: 'Cyclisme (10 mph, 16 km / h)',
	exerciseLabel9: 'Cyclisme (13 mph, 21 km / h)',
	exerciseLabel10: 'Danse (occasionnel)',
	exerciseLabel12: 'Danse (modérée)',
	exerciseLabel13: 'Danse (intense)',
	exerciseLabel14: 'Sports sur le terrain',
	exerciseLabel15: 'Frisbie',
	exerciseLabel16: 'Golf (marche)',
	exerciseLabel17: 'Golf (avec voiturette)',
	exerciseLabel18: 'Gymnastique',
	exerciseLabel19: 'Travaux ménagers',
	exerciseLabel20: 'Corde à sauter',
	exerciseLabel21: 'Arts martiaux',
	exerciseLabel22: 'Racquetball',
	exerciseLabel23: 'Rollerblading',
	exerciseLabel24: 'Rameur (Intense)',
	exerciseLabel25: 'Rameur (modéré)',
	exerciseLabel26: 'Course à pied (4 mph, 6,5 km / h)',
	exerciseLabel27: 'Course à pied (5 mph, 8 km / h)',
	exerciseLabel28: 'Course à pied (6 mph, 9,7 km / h)',
	exerciseLabel29: 'Course à pied (7 mph, 11,3 km / h)',
	exerciseLabel30: 'Course à pied (8 mph, 13 km / h)',
	exerciseLabel31: 'Course à pied (10 mi / h, 16 km / h)',
	exerciseLabel32: 'Course à pied (12 mi / h, 19,3 km / h)',
	exerciseLabel33: 'Balle molle',
	exerciseLabel34: 'Marche d\'escalier (6 ", 15,2 cm)',
	exerciseLabel35: 'Marche d\'escalier (8 ", 20,3 cm)',
	exerciseLabel36: 'Marche d\'escalier (10 ", 25,4 cm)',
	exerciseLabel37: 'Marche d\'escalier (12 ", 30,5 cm)',
	exerciseLabel38: 'Natation (occasionnelle)',
	exerciseLabel39: 'Natation (intense)',
	exerciseLabel40: 'Tennis',
	exerciseLabel41: 'Volley-ball',
	exerciseLabel42: 'Marcher (2 mph, 3,2 km / h)',
	exerciseLabel43: 'Marcher (3 mph, 4,8 km / h)',
	exerciseLabel44: 'Marcher (4 mph, 6,5 km / h)',
	exerciseLabel45: 'Musculation (Intense)',
	exerciseLabel46: 'Entraînement avec poids (normal)',
	exerciseLabel47: 'Travaux de jardinage'
};
