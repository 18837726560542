import { Platform } from "react-native";

const shareUrl = Platform.select({
	ios: "https://apps.apple.com/us/app/mediterranean-diet-guide-and-foods/id1089223593?ls=1",
	android: "https://play.google.com/store/apps/details?id=com.prestigeworldwide.medidiet",
});

const rateURL = Platform.select({
	ios: "itms://itunes.apple.com/us/app/apple-store/1089223593?mt=8",
	android: "https://play.google.com/store/apps/details?id=com.prestigeworldwide.medidiet",
});

const admobBannerID = Platform.select({
	ios: "ca-app-pub-5202729129404099/1953767362",
	android: "ca-app-pub-5202729129404099/9477034164",
});

const admobInterID = Platform.select({
	ios: "ca-app-pub-5202729129404099/4907233761",
	android: "ca-app-pub-5202729129404099/3430500569",
});

const testingID = {
	banner: "ca-app-pub-3940256099942544/6300978111",
	inter: "ca-app-pub-3940256099942544/1033173712",
};

const decimalOption = 2;

const Global = {
	shareUrl: shareUrl,
	rateURL: rateURL,
	admobBannerID: global.__DEV__ ? testingID.banner : admobBannerID,
	admobInterID: global.__DEV__ ? testingID.inter : admobInterID,
	decimalOption: decimalOption,
};

export default Global;
