//@flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { neverAskRateAgain } from 'Redux/actions';
import { View, StyleSheet, Linking, Platform } from 'react-native';
import { Prompt, Text } from '../common';
import StarRating from 'react-native-star-rating';
import Communications from 'react-native-communications';
import { Global, i18n } from 'Theme';

export const RateModal = ({ visible, onCancel }) => {
	const [starCount, updateStarCount] = useState(5);
	const [feedBackDialog, toggleFeedBackDialog] = useState(false);

	const dispatch = useDispatch();
	const feedbackSubject =
		Platform.OS === 'ios'
			? `${i18n.t('appName')} Feedback - IOS`
			: `${i18n.t('appName')} Feedback - Android`;

	const customButtons = !feedBackDialog
		? [
				{ text: i18n.t('never'), onPress: () => neverRating() },
				{ text: i18n.t('later'), onPress: () => onCancel() },
				{ text: i18n.t('rate'), onPress: () => submitRating(starCount) }
		  ]
		: [
				{
					text: i18n.t('noThanks'),
					onPress: () => onCancel()
				},
				{ text: i18n.t('giveFeedback'), onPress: () => onFeedback() }
		  ];

	const submitRating = (starCount) => {
		if (starCount > 4) {
			Linking.openURL(Global.rateURL);
			dispatch(neverAskRateAgain());
			onCancel();
		} else {
			toggleFeedBackDialog(true);
		}
	};

	const neverRating = () => {
		dispatch(neverAskRateAgain());
		onCancel();
	};

	const onFeedback = () => {
		onCancel();
		Communications.email(
			['prestigeworldwide.app@gmail.com'],
			null,
			null,
			feedbackSubject,
			''
		);
	};

	//Generic Input Modal
	return (
		<Prompt
			title={i18n.t('rateAppName')}
			visible={visible}
			onSubmit={submitRating}
			onCancel={onCancel}
			onClosed={onCancel}
			submitText={i18n.t('rate')}
			customButtons={customButtons}
			customButtonTextStyle={{
				fontSize: 14
			}}>
			<View style={styles.rateHeader}>
				{!feedBackDialog && (
					<Text style={styles.dialogDesc}>{i18n.t('rateDesc')}</Text>
				)}
				{feedBackDialog && (
					<Text style={styles.dialogDesc}>{i18n.t('rateFeed')}</Text>
				)}
			</View>
			{!feedBackDialog && (
				<View style={styles.starContainer}>
					<StarRating
						disabled={false}
						fullStarColor={'#f9d010'}
						maxStars={5}
						rating={starCount}
						selectedStar={updateStarCount}
					/>
				</View>
			)}
		</Prompt>
	);
};

const styles = StyleSheet.create({
	rateHeader: {
		justifyContent: 'center',
		alignItems: 'center'
	},
	dialogDesc: {
		fontSize: 12,
		lineHeight: 16,
		padding: 10,
		textAlign: 'center'
	},
	starContainer: {
		height: 30,
		justifyContent: 'center',
		margin: 20
	}
});
