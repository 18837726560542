//@flow
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { Fonts } from '../../constants';
import { Text } from './';

type Props = {
	style?: Object,
	children: React.ComponentType<any> | string
};

export const BlockQuote = (props: Props) => {
	return (
		<View style={[styles.greyBacking, props.ctnStyle]}>
			<Text style={[styles.block, props.style]}>{props.children}</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	block: {
		padding: 15,
		fontWeight: 'bold',
		fontSize: Fonts.size.mediumer
	},
	greyBacking: {
		backgroundColor: '#fafafa',
		marginBottom: 15,
		borderTopWidth: 0.5,
		borderTopColor: '#f2f2f2',
		borderBottomWidth: 0.5,
		borderBottomColor: '#f2f2f2',
		borderLeftColor: '#505050',
		borderLeftWidth: 10,
		borderRightColor: '#505050',
		borderRightWidth: 10
	}
});
