//@flow
import React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import {
	ServingSelectorWheel,
	ServingSelectorTextInput,
	ServingSelectorTypeSwitch
} from './servings';
import { Colors } from 'Theme';
import { useSelector, useDispatch } from 'react-redux';
import { servingSelectTypeChange } from 'Redux/actions';

type Props = {
	reducerType: string,
	labelArray: Array<string>
};

export const ServingSelector = (props: Props) => {
	const dispatch = useDispatch();
	const { reducerType, labelArray, theme } = props;
	const { servingSelectType } = useSelector(
		(state) => state.singleItem[reducerType]
	);

	if (Platform.OS === 'web')
		return (
			<ServingSelectorTextInput
				reducerType={reducerType}
				labelArray={labelArray}
				theme={theme}
			/>
		);

	return (
		<View style={styles.servingContainer}>
			{servingSelectType === 'wheel' && (
				<ServingSelectorWheel
					reducerType={reducerType}
					labelArray={labelArray}
					theme={theme}
				/>
			)}
			{servingSelectType === 'input' && (
				<ServingSelectorTextInput
					reducerType={reducerType}
					labelArray={labelArray}
					theme={theme}
				/>
			)}
			<ServingSelectorTypeSwitch
				onChange={(value) =>
					dispatch(servingSelectTypeChange(reducerType, value))
				}
				active={servingSelectType}
				theme={theme}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	servingContainer: {
		flex: 1
	}
});
