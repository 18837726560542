import { StyleSheet } from 'react-native';
import { Fonts, Colors } from 'Theme';

export default StyleSheet.create({
	fitnessAppImage: {
		height: 50,
		width: 50,
		borderRadius: 5,
		marginRight: 30
	},
	arrowIcon: {
		justifyContent: 'flex-end',
		flex: 0.1
	},
	title: {
		fontSize: 16
	},
	subTitle: {
		fontSize: 10,
		lineHeight: 12
	}
});
