//@flow
import React from 'react';
import { View, FlatList } from 'react-native';
import { Nav } from './';
import styles from './styles';
import { Text, Checkbox } from 'Components/common';
import { i18n } from 'Theme';

export const PageCheckboxes = (props) => {
	const { changeOptions, checkboxOptions, next, prev, page, theme } = props;

	const toggleCheckbox = (id) => {
		//Toggle checkboxes for email signup
		const objIndex = checkboxOptions.findIndex((obj) => obj.id === id);
		const updatedObj = {
			...checkboxOptions[objIndex],
			checked: !checkboxOptions[objIndex].checked
		};
		const newCheckboxOptions = [
			...checkboxOptions.slice(0, objIndex),
			updatedObj,
			...checkboxOptions.slice(objIndex + 1)
		];

		changeOptions(newCheckboxOptions);
	};

	const renderCheckbox = ({ item }) => {
		return (
			<Checkbox
				selected={item.checked}
				color={theme.blue}
				size={24}
				onPress={() => toggleCheckbox(item.id)}
				text={item.name}
				textStyle={{ color: theme.darkFont, marginLeft: 10, fontSize: 12 }}
				style={{
					marginVertical: 5,
					marginLeft: 0,
					borderBottomWidth: 1,
					borderColor: theme.border,
					padding: 5
				}}
			/>
		);
	};

	return (
		<View style={styles.pageContainer}>
			<Nav onForward={next} onBack={prev} page={page} theme={theme} />
			<Text style={styles.pageSubtitle}>
				{i18n.t('whyKeto')} {i18n.t('betterTailor')}
			</Text>
			<FlatList
				data={checkboxOptions}
				keyExtractor={(item) => item.name}
				renderItem={renderCheckbox}
				bounces={false}
				persistentScrollbar={true}
			/>
		</View>
	);
};
