import {
	REQUEST_KETO_REDDIT_COMMENTS,
	RECEIVE_KETO_REDDIT_COMMENTS,
	SET_THREAD_DETAILS
} from '../actions/types';

const INITIAL_STATE = {
	loaded: false,
	comments: [],
	commentOP: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REQUEST_KETO_REDDIT_COMMENTS:
			return { ...state, loaded: false };
		case RECEIVE_KETO_REDDIT_COMMENTS:
			return { ...state, loaded: true, comments: action.payload };
		case SET_THREAD_DETAILS:
			return { ...state, commentOP: action.payload };
		default:
			return state;
	}
};
