import {
	REQUEST_KETO_REDDIT_POSTS,
	RECEIVE_KETO_REDDIT_POSTS
} from '../actions/types';

const INITIAL_STATE = {
	isFetching: false,
	items: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REQUEST_KETO_REDDIT_POSTS:
			return { ...state, isFetching: true };
		case RECEIVE_KETO_REDDIT_POSTS:
			return { ...state, isFetching: false, items: action.payload };
		default:
			return state;
	}
};
