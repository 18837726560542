import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Fonts } from 'Theme';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text } from './';

export const BulletListItem = (props) => {
	const { onPress, iconName = 'circle', iconColor = '#333' } = props;
	return (
		<View style={[styles.container, props.style]} onPress={onPress}>
			<Icon
				style={styles.iconStyle}
				name={iconName}
				color={iconColor}
				size={9}
			/>
			<Text style={styles.text}>{props.children}</Text>
		</View>
	);
};

var styles = StyleSheet.create({
	container: {
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row'
	},
	text: {
		color: '#333',
		fontSize: 12,
		fontFamily: 'Raleway'
	},
	iconStyle: {
		paddingRight: 10,
		alignSelf: 'center'
	}
});
