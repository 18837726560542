//@flow
import { Platform, Dimensions } from 'react-native';

//Checks if is iPhoneX or the newer Iphone XR
export const isIphoneX = () => {
	let d = Dimensions.get('window');
	const { height, width } = d;

	return (
		Platform.OS === 'ios' &&
		!Platform.isPad &&
		!Platform.isTVOS &&
		(height === 812 || width === 812 || height === 896 || width === 896)
	);
};

export const isBelowAndroid5 = () => {
	if (Platform.OS === 'android' && Platform.Version < 21) {
		return true;
	}
	return false;
};

export const isIos = () => {
	if (Platform.OS === 'ios') {
		return true;
	}
	return false;
};

export const isPortrait = () => {
  const dim = Dimensions.get('screen');
  return dim.height >= dim.width;
};

//For Response Width/Height and Fonts. Gets the Height and Width of the device in Landscape (even if the device is in portrait). If the device is in landscape, it can lead to overscaled fonts if turned back to portait. Therefore, the app always goes off portait values
let newWidth;
let newHeight;
const { width, height } = Dimensions.get("window");
newWidth = !isPortrait() ? height : width;
newHeight = !isPortrait() ? width : height;

//Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;
const scale = (size: number) => newWidth / guidelineBaseWidth * size;
const verticalScale = (size: number) => newHeight / guidelineBaseHeight * size;
const moderateScale = (size: number, factor: number = 0.5) => size + (scale(size) - size) * factor;
export { scale, verticalScale, moderateScale };