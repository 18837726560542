import { StyleSheet, Dimensions } from 'react-native';
import { Colors } from 'Theme';
import { isBelowAndroid5 } from 'Utilities';

// Getting the width and the height of the phone
const { width } = Dimensions.get('window');

export default StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#F5FCFF'
	},
	activityIndicator: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		height: 50
	},
	description: {
		paddingVertical: 10,
		paddingHorizontal: 20
	},
	btnContainer: {
		backgroundColor: Colors.grey,
		marginVertical: 10
	},
	restoreContainer: {
		flexDirection: 'column',
		backgroundColor: Colors.grey,
		marginTop: 20
	},
	restoreBtn: {
		flex: 1,
		margin: 0,
		backgroundColor: Colors.submit
	},
	promoBtn: {
		flex: 1,
		margin: 0,
		backgroundColor: '#FFF'
	},
	titleContainer: {
		height: 200,
		backgroundColor: Colors.themeAccent,
		justifyContent: 'center'
	},
	whiteStripe: {
		backgroundColor: '#FFF'
	},
	textContainer: {
		backgroundColor: '#FFF',
		borderTopWidth: isBelowAndroid5() ? 1 : 0,
		borderTopColor: isBelowAndroid5() ? Colors.border : '#FFF',
		elevation: 2,
		shadowColor: '#333',
		shadowOffset: {
			width: 0,
			height: 1
		},
		shadowRadius: 1,
		shadowOpacity: 0.5,
		margin: 20,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 5
	},
	title: {
		fontSize: 18,
		fontWeight: '900'
	},
	subtitle: {
		fontSize: 14,
		fontWeight: '100'
	},
	featureContainer: {
		paddingBottom: 20
	},
	buyContainer: {
		borderTopWidth: isBelowAndroid5() ? 1 : 0,
		borderTopColor: isBelowAndroid5() ? Colors.border : '#FFF',
		elevation: 2,
		shadowColor: '#333',
		shadowOffset: {
			width: 0,
			height: 1
		},
		shadowRadius: 1,
		shadowOpacity: 0.5,
		marginVertical: 5
	},
	imageRow: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	//LIGHTBOX
	closeButton: {
		color: 'white',
		borderWidth: 1,
		borderColor: 'white',
		padding: 8,
		borderRadius: 3,
		textAlign: 'center',
		margin: 10,
		alignSelf: 'flex-end'
	},
	modalImgCtn: {
		width
	},
	modalImg: {
		width: width * 0.9,
		resizeMode: 'contain',
		alignSelf: 'center'
	},
	thumbCtn: {
		justifyContent: 'center',
		paddingHorizontal: 10
	},
	thumbImg: {
		width: 110,
		height: 190,
		resizeMode: 'contain',
		borderColor: Colors.backdropGray,
		borderWidth: 4,
		padding: 4
	}
});
