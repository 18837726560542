//@flow
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Fonts } from '../../constants';
import { Text } from './';
import Elevations from 'react-native-elevation';

type Props = {
	children: string
};

export const Heading = (props: Props) => {
	return (
		<View style={[styles.greyBacking, props.style]}>
			<Text style={[styles.learnHeader, props.titleStyle]}>
				{props.children}
			</Text>
		</View>
	);
};

var styles = StyleSheet.create({
	learnHeader: {
		paddingTop: 20,
		paddingBottom: 20,
		paddingLeft: 15,
		fontWeight: 'bold',
		fontSize: Fonts.size.h6,
		lineHeight: Fonts.size.h6 * 1.2
	},
	greyBacking: {
		marginBottom: 15,
		...Elevations[2]

	}
});
