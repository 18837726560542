//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SwitchSelector } from 'Components/common';
import { Colors } from 'Theme';
import Elevations from 'react-native-elevation';

type Props = {
	active: string,
	onChange: (x: string) => Object,
	theme: Object
};

export const FsServingSelectorTypeSwitch = (props: Props) => {
	const { theme } = props;
	const wheelSelected = props.active === 'wheel' ? "#FFF" : theme.blue;
	const inputSelected = props.active === 'input' ? "#FFF" : theme.blue;
	return (
		<View style={[styles.switchHeader, { backgroundColor: theme.base }]}>
			<View style={{ width: 150 }}>
				<SwitchSelector
					options={[
						{
							value: 'wheel',
							customIcon: (
								<MaterialCommunityIcons
									name="counter"
									size={20}
									color={wheelSelected}
								/>
							)
						},
						{
							value: 'input',
							customIcon: (
								<MaterialCommunityIcons
									name="keyboard"
									size={20}
									color={inputSelected}
								/>
							)
						}
					]}
					initial={0}
					onPress={(value) => props.onChange(value)}
					buttonColor={theme.blue}
					fontSize={10}
					hasPadding={true}
					borderColor={theme.blue}
					backgroundColor={theme.baseBG}
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	switchHeader: {
		height: 50,
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 2,
		...Elevations[2]
	}
});
