//@flow
import { metaTypes } from '../actions/types';
import * as types from '../actions/types';

export function clearData() {
	return {
		type: types.firebase.FIREBASE_RESET_DATA
	};
}

export function listenRequested(metaType) {
	return {
		type: types.firebase.FIREBASE_LISTEN_REQUESTED,
		metaType
	};
}

export function listenRejected(metaType, error) {
	return {
		type: types.firebase.FIREBASE_LISTEN_REJECTED,
		metaType,
		error
	};
}

export function listenFulfilled(metaType, items) {
	return {
		type: types.firebase.FIREBASE_LISTEN_FULFILLED,
		metaType,
		items
	};
}

export function listenRemoved(metaType, clearItems) {
	return {
		type: types.firebase.FIREBASE_LISTEN_REMOVED,
		metaType,
		clearItems
	};
}

export function removeListenerRef(state, metaType) {
	if (
		state &&
		state.friendsListener[metaType] &&
		state.friendsListener[metaType].ref
	) {
		return state.friendsListener[metaType].ref.off();
	}

	//returns resolved promise
	return Promise.resolve();
}

export const listenToPath = (metaType, path) => (
	dispatch,
	getState,
	getFirebase
) => {
	//just in case we remove any existing listener
	return removeListenerRef(getState(), metaType)
		.then(() => {
			const firebase = getFirebase();
			const ref = firebase.ref(path);
			dispatch(listenRequested(metaType));

			return ref.on('value', (snap) => {
				//better to have an empty object then a null value if data does not
				//exist
				const val = snap.val();
				const value = val ? val : {};
				dispatch(listenFulfilled(metaType, value));
			});
		})
		.catch((error) => {
			dispatch(listenRejected(metaType, error));
		});
};

export function removeListener(metaType, clearItems = false) {
	return (dispatch, getState) => {
		return removeListenerRef(getState(), metaType).then(() => {
			dispatch(listenRemoved(metaType, clearItems));
		});
	};
}

export function removeAllListeners() {
	return (dispatch, getState) => {
		const promises = Object.keys(metaTypes).map((metaType) => {
			return removeListenerRef(getState(), metaType).then(() => {
				dispatch(listenRemoved(metaType, true));
			});
		});
		return Promise.all(promises);
	};
}

export function listenToFriendsFoods(uid) {
	return listenToPath(metaTypes.customFoods, `userdata/${uid}/customfoodsv3`);
}

export function listenToFriendsSingleFood(uid, foodId) {
	return listenToPath(
		metaTypes.singleCustomFood,
		`userdata/${uid}/customfoodsv3/${foodId}`
	);
}

export function listenToFriendsMeals(uid) {
	return listenToPath(metaTypes.savedMeals, `userdata/${uid}/savedmealsv3`);
}

export function listenToFriendsSingleMeal(uid, foodId) {
	return listenToPath(
		metaTypes.singleSavedMeal,
		`userdata/${uid}/savedmealsv3/${foodId}`
	);
}
