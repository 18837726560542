import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { Images } from 'Theme';

export const FsImage = () => {
	return (
		<Image
			resizeMode="contain"
			style={styles.fatImage}
			source={Images.fatsecret}
		/>
	);
};

const styles = StyleSheet.create({
	fatImage: {
		width: 120,
		height: 17,
		alignSelf: 'center',
		marginVertical: 10
	}
});
