//@flow
import React, { useState, useEffect } from 'react';
import { ScrollView, View, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
	logEntry,
	deletePreset,
	favoriteAddItem,
	quickRemoveFavorite
} from './actions';
import { copyIngredientsToEdit, resetItem } from 'Redux/actions';
import {
	Header,
	ServingSelector,
	MacroGrid,
	Ingredients,
	Nutrients,
	Collapsible,
	ClickableHeader
} from 'Components/common';
import { SingleViewButtons } from './components';
import { ErrorText, Loader, Button } from 'Components/common';
import { i18n } from 'Theme';
import Picker from 'react-native-picker';
import { servingMealSelector, ingredientsFormatSelector } from './selectors';
import { defaultNutrients } from 'Redux/selectors';
import { Feature } from 'Components/purchase/components';

export const CustomMealTabSingle = ({ route, navigation }) => {
	const dispatch = useDispatch();
	const {
		savedmealsv3 = {},
		settings: { units },
		premium
	} = useSelector((state) => state.firebase.profile);
	const { mealTime, date, editMealTime } = useSelector((state) => state.log);
	const item = savedmealsv3[route.params.foodID];
	const { theme } = useSelector((state) => state.startup);
	const servingData = useSelector((state) => servingMealSelector(state, route));
	const ingredients = useSelector((state) =>
		ingredientsFormatSelector(state, route)
	);
	const [viewIngredients, toggleIngredients] = useState(false);
	const [viewNutrients, toggleNutrients] = useState(false);

	useEffect(() => {
		const { editingMeal, manage, justCreated, editingLogEntry } = route.params;

		if (typeof item !== 'undefined') {
			const onFavorite =
				item.favorite === true
					? () => dispatch(quickRemoveFavorite(item))
					: () => dispatch(favoriteAddItem(item));
			const favText =
				item.favorite === true
					? i18n.t('removeFavorite')
					: i18n.t('addFavorite');
			const iconColor = item.favorite === true ? '#f1c40f' : theme.darkFont;
			if (manage || editingMeal) {
				navigation.setParams({
					menuArray: [
						{
							iconText: favText,
							onPress: onFavorite,
							iconName: 'star',
							iconColor
						},
						{
							iconText: i18n.t('editMeal'),
							onPress: () => toggleEdit(),
							iconName: 'edit',
							iconColor: theme.darkFont
						},
						{
							iconText: i18n.t('deleteMeal'),
							onPress: () => dispatch(deletePreset(item, true)),
							iconName: 'trash',
							iconColor: theme.darkFont
						}
					]
				});
			} else if (editingLogEntry) {
				navigation.setParams({
					menuArray: [
						{
							iconText: favText,
							onPress: onFavorite,
							iconName: 'star',
							iconColor
						}
					]
				});
			} else {
				const addTo = mealTime.charAt(0).toUpperCase() + mealTime.slice(1);
				navigation.setParams({
					menuArray: [
						/*{
							iconText: `${i18n.t('addTo')} ${addTo}`,
							onPress: () =>
								dispatch(logEntry(servingData.servingMacros, date, mealTime)),
							iconName: 'plus',
							iconColor: theme.darkFont
						},*/
						{
							iconText: favText,
							onPress: onFavorite,
							iconName: 'star',
							iconColor
						},
						{
							iconText: i18n.t('editMeal'),
							onPress: () => toggleEdit(),
							iconName: 'edit',
							iconColor: theme.darkFont
						},
						{
							iconText: i18n.t('deleteMeal'),
							onPress: () => dispatch(deletePreset(item, true)),
							iconName: 'trash',
							iconColor: theme.darkFont
						}
					]
				});
			}
		}

		//If a user just created a meal, automatically enables editing mode to make it more obvious how to add ingredients
		if (justCreated && typeof item !== 'undefined') {
			toggleEdit();
		}
		return () => {
			if (Platform.OS !== 'web') {
				Picker.hide();
			}
			dispatch(resetItem('meal'));
		};
	}, [savedmealsv3]);

	//Toggle the Edit Custom Meal
	const toggleEdit = () => {
		dispatch(
			copyIngredientsToEdit(typeof item.foods !== 'undefined' ? item.foods : [])
		);
		navigation.navigate('MealEdit', {
			screen: 'CustomMealSingleEdit',
			params: {
				...route.params,
				item: item
			}
		});
	};

	if (!item) {
		return (
			<Loader
				full={true}
				loaderBG={theme.baseBG}
				loaderColor={theme.darkFont}
			/>
		);
	}

	if (item) {
		return (
			<ScrollView style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<Header title={item.name} bgColor={theme.base} />
				<ServingSelector
					reducerType={'meal'}
					labelArray={[
						item.customServingName ? item.customServingName : i18n.t('servings')
					]}
					theme={theme}
				/>
				<MacroGrid
					{...servingData.servingMacros}
					carbUnits={units.carbUnits}
					theme={theme}
				/>
				<Ingredients
					ingredients={ingredients}
					viewMore={viewIngredients}
					toggle={() => toggleIngredients(!viewIngredients)}
					theme={theme}
				/>
				<ClickableHeader
					leftText="Nutrients:"
					noMargin
					onPress={() => toggleNutrients(!viewNutrients)}
					rightIconName={!viewNutrients ? 'arrow-right' : 'arrow-down'}
					style={{ backgroundColor: theme.base }}
					iconColor={theme.darkFont}
				/>
				<Collapsible collapsed={!viewNutrients}>
					{!premium ? (
						<View>
							<Feature
								icon="check"
								title="Track Nutrients"
								body="With Premium, follow more than just your macros. Keep track of: Cholesterol, Sodium, Calcium, Magnesium, Potassium, Iron, Zinc, Phosphorus, Folate, Thiamin (B1), Riboflavin (B2), Niacin (B3), Vitamin B6, Vitamin B12, Vitamin A,C,D,E,K, "
							/>
							<Button
								onPress={() => navigation.navigate('Purchase')}
								style={[{ backgroundColor: theme.teal }]}>
								{i18n.t('premium')}
							</Button>
						</View>
					) : (
						<Nutrients
							viewMore={viewNutrients}
							toggle={() => toggleNutrients(!viewNutrients)}
							theme={theme}
							nutrients={{
								...defaultNutrients,
								...servingData.servingMacros.nutrientsV2
							}}
						/>
					)}
				</Collapsible>
				<SingleViewButtons
					servingMacros={servingData.servingMacros}
					item={item}
					mealTime={mealTime}
					editMealTime={editMealTime}
					date={date}
					params={route.params}
					theme={theme}
				/>
			</ScrollView>
		);
	}

	return <ErrorText showError={!item}>{i18n.t('foodNotFound')}</ErrorText>;
};
