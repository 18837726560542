//@flow
import i18n from 'i18n-js';
import { enStrings } from './strings/Strings_en';
import { esStrings } from './strings/Strings_es';
import { frStrings } from './strings/Strings_fr';
import { deStrings } from './strings/Strings_de';
import { ptStrings } from './strings/Strings_pt';
//Strings used throughout the app translated into English, French, Spanish, Portuguese, German

const Strings = {
    en: enStrings,
    es: esStrings,
    fr: frStrings,
    de: deStrings,
    pt: ptStrings
};

i18n.translations = Strings;
i18n.defaultLocale = 'en';
i18n.fallbacks = true;
export default i18n;