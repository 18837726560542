// @flow

const CONFIG = {
	SENDGRIDURL: "https://api.sendgrid.com/v3/mail/send",
	KEY: "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",
};

const CONFIGLISTS = {
	SENDGRIDURL: "https://api.sendgrid.com/v3/contactdb/lists",
	KEY: "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",
};

const CONFIGLISTS2 = {
	SENDGRIDURL: "https://api.sendgrid.com/v3/marketing/lists?page_size=100",
	KEY: "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",
};

export const sendGridEmail = (email, language) => {
	const isSuccess = sendEmail(email, language);
	return isSuccess;
};

export const sendEmail = (email, language) => {
	return fetch(CONFIG.SENDGRIDURL, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + CONFIG.KEY,
		},
		body: JSON.stringify({
			from: {
				email: "prestigeworldwide.app@gmail.com",
			},
			personalizations: [
				{
					to: [
						{
							email: email,
						},
					],
					dynamic_template_data: {
						[`${language}`]: true,
					},
				},
			],
			template_id: "d-f66c6430134d47a68156061528adf5a5",
		}),
	})
		.then((response) => {
			return true;
		})
		.catch((error) => {
			return false;
		});
};

/*export const createList = () => {
	return fetch(CONFIGLISTS.SENDGRIDURL, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + CONFIGLISTS.KEY,
		}
	})
		.then((response) => {
			console.log(response)
			return true;
		})
		.catch((error) => {
			console.log(error)
			return false;
		});
};

export const createList = () => {
	return fetch(CONFIGLISTS.SENDGRIDURL, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + CONFIGLISTS.KEY,
		},
		body: JSON.stringify({
			name: "MyMedi",
		}),
	})
		.then((response) => {
			console.log(response	)
			return true;
		})
		.catch((error) => {
			return false;
		});
};*/
