//@flow
import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { WebCompatAlert, ListHeader } from 'Components/common';
import { quickRemoveRecent, clearAllRecentlyUsed } from './actions';
import { SearchFilter } from './components';
import { FavFlatList } from './components/listview';
import { createFilter } from 'react-native-search-filter';
import _ from 'lodash';
import { i18n } from 'Theme';
export const RecentlyUsedScreen = ({ route, navigation }) => {
	const dispatch = useDispatch();
	const {
		profile: { recentlyused = {} }
	} = useSelector((state) => state.firebase);
	const [searchTerm, setSearchTerm] = useState('');
	const { theme } = useSelector((state) => state.startup);
	const searchUpdated = (term) => setSearchTerm(term);

	const clearAll = (favObjs) => {
		WebCompatAlert(
			i18n.t('clearAllRecentlyUsed'),
			i18n.t('clearAllRecentlyUsedConfirm'),
			i18n.t('clear'),
			() => dispatch(clearAllRecentlyUsed())
		);
	};

	const arr = _.values(recentlyused);

	const sortedArr = arr.slice().sort((a, b) => {
		var textA = a.name.toUpperCase();
		var textB = b.name.toUpperCase();
		return textA < textB ? -1 : textA > textB ? 1 : 0;
	});

	const searchFilter = sortedArr.filter(createFilter(searchTerm, 'name'));

	return (
		<ScrollView
			style={{ flex: 1, backgroundColor: theme.baseBG }}
			keyboardShouldPersistTaps="handled">
			<SearchFilter
				onChangeText={searchUpdated}
				ctnStyle={{
					backgroundColor: theme.baseBG,
					borderBottomColor: theme.border
				}}
				inputStyle={{ backgroundColor: theme.grey }}
			/>
			<ListHeader
				leftIconName="history"
				leftIconColor={theme.darkFont}
				rightText={i18n.t('clear')}
				rightIconName="trash"
				rightIconColor={theme.darkFont}
				onPress={clearAll}
				noMargin
				style={{ backgroundColor: theme.base }}
			/>
			<FavFlatList
				data={searchFilter}
				quickRemove={(item) => dispatch(quickRemoveRecent(item))}
				params={route.params}
				navigation={navigation}
				theme={theme}
			/>
		</ScrollView>
	);
};
