//@flow
import { SAVE_PURCHASE_DATA, PROMO_CODE_SUCCESS } from './types';
import Toast from 'react-native-root-toast';
import { Platform } from 'react-native';
import { i18n } from 'Theme';

//Save Purchase History Data to Redux Store
export const savePurchaseData = (purchaseDatas) => (
	dispatch,
	getState,
	getFirebase
) => {
	const purchase = true;
	const purchaseData = purchaseDatas;
	const platform = Platform.OS;

	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	if (typeof uid !== 'undefined') {
		firebase.set(`userdata/${uid}/premium/`, true);
	}
	dispatch({
		type: SAVE_PURCHASE_DATA,
		payload: purchase,
		purchaseData,
		platform
	});
	return Toast.show(i18n.t('premiumEnabled'), {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
};

//Submit Promo Code for validation
export const submitPromoCode = (promoSubmitted) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	let promocode = '';
	firebase
		.database()
		.ref('promocode')
		.once('value')
		.then((snapshot) => {
			promocode = snapshot.val();
		})
		.then(() => {
			if (promoSubmitted === promocode) {
				dispatch(promoCodeSuccess());
			} else {
				Toast.show(i18n.t('invalidCode'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			}
		});

	return Promise.resolve();
};

//Promo Code Success, allow access
export const promoCodeSuccess = () => {
	let purchase = true;
	Toast.show(i18n.t('trackerEnabledAdsRemoved'), {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
	return {
		type: PROMO_CODE_SUCCESS,
		payload: purchase
	};
};

//Restore account via
export const restorePreviousAccount = (email) => (dispatch) => {
	return fetch(
		`https://us-central1-keto-diet-guide.cloudfunctions.net/restore/restoreaccount?email=${email}`,
		{
			method: 'GET'
		}
	)
		.then((responsej) => responsej.json())
		.then((response) => {
			if (response === true) {
				dispatch(promoCodeSuccess());
				Toast.show(i18n.t('purchaseRestored'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
				return Promise.resolve();
			} else {
				console.log(response);
				Toast.show(i18n.t('userNotFound'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
				return Promise.resolve();
			}
		})
		.catch((error) => {
			Toast.show(i18n.t('userNotFound'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
			return Promise.resolve();
		});
};

/*
export const trialExpired = () => {
	return {
		type: TRIAL_EXPIRED
	};
};*/
