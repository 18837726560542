import {
	ADD_SHOPPING_ITEM,
	DELETE_SHOPPING_ITEM,
	TOGGLE_SHOPPING_ITEM
} from './types';
import { randomString } from 'Utilities/FirebaseHelper';

export const addShoppingItem = (shoppingItem: string) => {
	const finalObj = {
		name: shoppingItem,
		key: randomString(3),
		completed: false
	};
	return { type: ADD_SHOPPING_ITEM, payload: finalObj };
};

export const deleteShoppingItem = (shoppingItem: Object) => {
	return { type: DELETE_SHOPPING_ITEM, payload: shoppingItem.key };
};

export const toggleCompletedItem = (shoppingItem: Object) => {
	return { type: TOGGLE_SHOPPING_ITEM, payload: shoppingItem };
};
