//@flow
import Toast from 'react-native-root-toast';
import _ from 'lodash';
import { i18n } from 'Theme';
//-----------------------------------------------------Search Food Single --------------------------------------------------------------------------------------

//Adds API Single Searched Food To Favorites
export const favoriteAddApiItem = (item) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;

	if (typeof item.food_id === 'undefined') {
		return;
	}

	firebase
		.update(`userdata/${uid}/favorites/${item.food_id}`, {
			name: item.food_name,
			id: item.food_id,
			type: 'api'
		})
		.then(() => {
			return Toast.show(`${item.food_name} ${i18n.t('addedToFavorites')}`, {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

/*-------------------General Favorites-------------*/

//Add Food/Meal To Favorites
export const favoriteAddItem = (item) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const firebaseRef = ((itemType) => {
		switch (itemType) {
			case 'food':
				return 'customfoodsv3';
			case 'meal':
				return 'savedmealsv3';
			default:
				return;
		}
	})(item.type);

	firebase.update(`userdata/${uid}/favorites/${item.id}`, {
		name: item.name,
		id: item.id,
		type: item.type
	});

	if (item.type !== 'api') {
		firebase.update(`userdata/${uid}/${firebaseRef}/${item.id}`, {
			favorite: true
		});
	}
	return Toast.show(`${item.name} ${i18n.t('addedToFavorites')}`, {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
};

//Remove Food/Meal/API from Favorites
export const quickRemoveFavorite = (item) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;

	const firebaseRef = ((itemType) => {
		switch (itemType) {
			case 'food':
				return 'customfoodsv3';
			case 'meal':
				return 'savedmealsv3';
			default:
				return;
		}
	})(item.type);

	firebase.remove(`userdata/${uid}/favorites/${item.id}`);

	if (item.type !== 'api') {
		firebase.update(`userdata/${uid}/${firebaseRef}/${item.id}`, {
			favorite: false
		});
	}

	return Toast.show(i18n.t('removedFromFavorites'), {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
};

//Clears all Favorites from the Favorites tab, as well as on the individual items child node that were Favorite
export const clearAllFavorites = (favObj) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};
	updates.favorites = {};
	const arr = _.values(favObj);
	arr.forEach((element) => {
		switch (element.type) {
			case 'food':
				return (updates[`customfoodsv3/${element.id}/favorite`] = false);
			case 'meal':
				return (updates[`savedmealsv3/${element.id}/favorite`] = false);
			default:
				return;
		}
	});

	ref.update(updates).then(() => {
		return Toast.show(i18n.t('favoritesCleared'), {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
};
