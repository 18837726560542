//@flow
import { StyleSheet, Platform } from 'react-native';
import { Colors } from 'Theme';

export default StyleSheet.create({
	pageContainer: {
		flex: 1,
		justifyContent: 'center',
		maxWidth: 700,
		width: '90%',
		marginHorizontal: 30
	},
	pageTitle: {
		fontSize: 14,
		textAlign: 'center',
		marginVertical: 10,
		fontFamily: 'Comfortaa',
		fontWeight: '900',
		marginHorizontal: '10%'
	},
	pageDescription: {
		textAlign: 'center',
		justifyContent: 'center',
		paddingVertical: 20,
		fontSize: 12
	},
	pageSubtitle: {
		fontSize: 14,
		lineHeight: 20,
		textAlign: 'center',
		marginVertical: 10
	},
	inputsContainer: {
		justifyContent: 'center',
		flex: 1
	},
	inputsContainerEnd: {
		justifyContent: 'flex-end',
		flex: 1
	},
	linkText: {
		color: 'blue',
		paddingLeft: 10,
		fontSize: 12,
		lineHeight: 20
	},
	policyCtn: {
		flex: 1,
		justifyContent: 'center'
	},
	policyText: {
		fontSize: 12,
		lineHeight: 22,
		marginBottom: 10
	},
	emailSubscribeCtn: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	},
	emailSubscribe: {
		paddingLeft: 10,
		fontSize: 11,
		lineHeight: 17,
		width: '80%',
		textAlign: 'center'
	},
	disclaimer: {
		fontSize: 12,
		lineHeight: 15,
		marginTop: 10
	},
	textInputContainer: {
		flex: 2.5,
		paddingVertical: 10,
		paddingRight: 5,
		textAlign: 'right',
		fontFamily: 'Raleway'
	},
	placeholderStyle: {
		fontSize: 12,
		paddingRight: 5
	},

	//PageResults
	sliderCtn: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	slider: {
		padding: 0
	},
	sliderLabel: {
		fontSize: 10,
		padding: 0
	},
	weightLossEstimateCtn: {
		flexDirection: 'row',
		paddingVertical: 10,
		justifyContent: 'center',
		alignItems: 'center'
	},
	weightLossEstimateText: {
		fontSize: 12,
		textAlign: 'center'
	},
	note: {
		fontSize: 10,
		textAlign: 'center'
	},
	pageResultsPercentHolder: {
		flexDirection: 'row',
		alignItems: 'center',
		flex: 1
	},
	macroCtn: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center'
	},
	macroList: {
		flex: 1
	},
	macroListRow: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1
	},
	noBorder: {
		borderBottomWidth: 0,
		borderBottomColor: 'transparent'
	},
	customizeTextCtn: {
		paddingHorizontal: 15
	},
	customizeText: {
		color: Colors.blue,
		fontSize: 10
	},
	bodyFat: {
		width: 300,
		height: 220,
		alignSelf: 'center'
	},
	deficitPresetsContainer: {
		flexDirection: 'row'
	},
	deficitPreset: {
		flex: 1
	},
	deficitPresetText: {
		fontSize: 12
	},
	carbNumberText: {
		fontSize: 20,
		textAlign: 'center'
	},
	closeContainer: {
		height: 40,
		width: 40,
		borderRadius: 30,
		justifyContent: 'center',
		alignItems: 'center',
		margin: 10
	},
	closeIcon: {},
	modal: { marginVertical: Platform.OS === 'web' ? 50 : '20%' },
	modalContainer: {
		flex: 1,
		borderRadius: 10
	},
	modalSubContainer: {
		flex: 1,
		justifyContent: 'center',
		paddingHorizontal: '6.5%'
	},
	modalHeader: {
		flexDirection: 'row'
	}
});
