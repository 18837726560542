//@flow
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from 'Theme';
import { Text } from 'Components/common';
import { SimpleLineIcons } from '@expo/vector-icons';

export const Feature = (props) => {
	return (
		<View style={styles.row}>
			<SimpleLineIcons
				name={props.icon}
				color={Colors.teal}
				size={40}
				style={styles.icon}
			/>
			<View style={styles.textContainer}>
				<Text style={styles.title}>{props.title}</Text>
				<Text style={styles.body}>{props.body}</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	icon: {
		flex: 1
	},
	row: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		padding: 10,
		marginHorizontal: 20,
		marginVertical: 15
	},
	textContainer: {
		flex: 4,
		flexDirection: 'column'
	},
	title: {
		fontSize: 16,
		lineHeight: 18,
		fontWeight: '900',
		marginBottom: 5
	},
	body: {
		fontSize: 12,
		lineHeight: 14
	}
});
