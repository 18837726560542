//@flow
import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavOptionsHeader, NavOptionsHeaderNoDraw, NavOptionsBottomTab } from "../NavOptionsHeader";
import { Ionicons } from "@expo/vector-icons";
import { i18n } from "Theme";
import { AllowedFoodScreen } from "Components/foodlist/AllowedFoodScreen";
import { ForbiddenFoodScreen } from "Components/foodlist/ForbiddenFoodScreen";

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const AllowedFoodStack = () => {
	return (
		<Stack.Navigator
			screenOptions={({ navigation }) => {
				return NavOptionsHeader(navigation);
			}}
		>
			<Stack.Screen
				name="Allowed"
				component={AllowedFoodScreen}
				options={{
					header: ({ navigation, scene }) => {
						return <NavOptionsHeader navigation={navigation} title={i18n.t("foodList")} />;
					},
				}}
			/>
		</Stack.Navigator>
	);
};

const ForbiddenFoodStack = () => {
	return (
		<Stack.Navigator
			screenOptions={({ navigation }) => {
				return NavOptionsHeader(navigation);
			}}
		>
			<Stack.Screen
				name="Forbidden"
				component={ForbiddenFoodScreen}
				options={{
					header: ({ navigation, scene }) => {
						return <NavOptionsHeader navigation={navigation} title={i18n.t("foodList")} />;
					},
				}}
			/>
		</Stack.Navigator>
	);
};

const FoodTab = () => {
	return (
		<Tab.Navigator
			screenOptions={({ route }) => ({
				tabBarIcon: ({ focused, color, size }) => {
					let iconName;

					if (route.name === "Allowed") {
						iconName = "ios-thumbs-up";
					} else if (route.name === "Forbidden") {
						iconName = "ios-thumbs-down";
					}

					// You can return any component that you like here!
					return <Ionicons name={iconName} size={size} color={color} />;
				},
			})}
			tabBar={(props) => <NavOptionsBottomTab {...props} />}
		>
			<Tab.Screen name="Allowed" component={AllowedFoodStack} />
			<Tab.Screen name="Forbidden" component={ForbiddenFoodStack} />
		</Tab.Navigator>
	);
};


export default FoodTab;
