//@flow
import * as React from 'react';
import { Text as TextRN, StyleSheet } from 'react-native';
import { Fonts } from 'Theme';
import { useSelector } from 'react-redux';

type Props = {
	onPress?: Function,
	children: ?string | React.Node,
	style?: Object | Array<?Object>
};

export const Text = (props: Props) => {
	const { theme } = useSelector((state) => state.startup);

	if (props.onPress) {
		return (
			<TextRN
				style={[{ color: theme.darkFont }, styles.learnText, props.style]}
				onPress={props.onPress}>
				{props.children}
			</TextRN>
		);
	}
	return (
		<TextRN style={[{ color: theme.darkFont }, styles.learnText, props.style]}>
			{props.children}
		</TextRN>
	);
};

const styles = StyleSheet.create({
	learnText: {
		fontFamily: 'Comfortaa',
		fontSize: Fonts.size.medium,
		lineHeight: Fonts.size.medium * 2
	}
});
