//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import {
	VictoryChart,
	VictoryBar,
	VictoryAxis,
	VictoryTheme,
	VictoryLabel
} from 'victory-native';
import moment from 'moment';

type Props = {
	width: number,
	data: Object,
	progressFilter: string,
	xLabel?: string,
	yLabel?: string,
	theme: Object
};

export const ProgressBar = (props: Props) => {
	const { width, data, xLabel, yLabel, children, theme } = props;

	return (
		<View style={styles.chartContainer}>
			<VictoryChart
				theme={VictoryTheme.material}
				padding={{ top: 55, bottom: 65, left: 70, right: 50 }}
				domainPadding={{ x: 1, y: 10 }}
				width={width}
				scale={{ x: 'time' }}
				disableContainerEvents={false}
				animate={{
					duration: 250,
					onLoad: { duration: 0 }
				}}>
				<VictoryAxis
					dependentAxis
					axisLabelComponent={<VictoryLabel dy={-140} dx={5} angle={0} />}
					label={yLabel}
					tickFormat={(y) => y.toFixed(1)}
					style={{
						grid: { stroke: () => theme.border },
						tickLabels: {
							fontSize: 12,
							fontFamily: 'Raleway',
							fill: theme.darkFont
						},
						axisLabel: {
							padding: 40,
							fontSize: 14,
							fontFamily: 'Raleway',
							fill: theme.darkFont
						},
						axis: { stroke: theme.placeholderGray }
					}}
				/>
				<VictoryAxis
					fixLabelOverlap={true}
					tickLabelComponent={<VictoryLabel angle={45} />}
					tickFormat={(x) => moment(x).format('MMM-DD')}
					label={xLabel}
					scale={{ x: 'time' }}
					style={{
						grid: { stroke: () => theme.border },
						tickLabels: {
							fontSize: 8,
							fill: theme.darkFont,
							fontFamily: 'Raleway'
						},
						axisLabel: {
							padding: 40,
							fontSize: 14,
							fontFamily: 'Raleway',
							fill: theme.darkFont
						},
						axis: { stroke: theme.placeholderGray }
					}}
				/>
				<VictoryBar
					style={{
						labels: {
							fontSize: 14,
							fontFamily: 'Raleway',
							padding: 15,
							strokeWidth: 5
						},
						data: { strokeWidth: 1, fill: theme.blue }
					}}
					barRatio={0.75}
					data={data}
				/>
				{children}
			</VictoryChart>
		</View>
	);
};

const styles = StyleSheet.create({
	chartContainer: {
		borderTopWidth: 0,
		justifyContent: 'center',
		alignItems: 'center'
	}
});
