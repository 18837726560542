//@flow
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { i18n } from 'Theme';
import { NavOptionsHeaderNoDraw, NavOptionsTopTab } from '../';
import { FontAwesome } from '@expo/vector-icons';
import { HeaderTextButton, HeaderMenuDropdown } from 'Components/common';

import { FsSearchScreen } from 'Components/fatsecret/FsSearchScreen';
import { FsSingleScreen } from 'Components/fatsecret/FsSingleScreen';
import { BarcodeScreen } from 'Components/fatsecret/BarcodeScreen';

import { CustomFoodTab } from 'Components/tracker/foodtabs/CustomFoodTab';
import { CustomFoodTabSingle } from 'Components/tracker/foodtabs/CustomFoodTabSingle';

import { CustomMealTab } from 'Components/tracker/foodtabs/CustomMealTab';
import { CustomMealTabSingle } from 'Components/tracker/foodtabs/CustomMealTabSingle';
import { CustomMealTabSingleEdit } from 'Components/tracker/foodtabs/CustomMealTabSingleEdit';
import { MealEditingTabList } from 'Components/tracker/foodtabs/components/mealediting/MealEditingTabList';
import { CustomMealTabSingleClone } from 'Components/tracker/foodtabs/CustomMealTabSingleClone';

import { FavoriteScreen } from 'Components/tracker/foodtabs/FavoriteScreen';
import { RecentlyUsedScreen } from 'Components/tracker/foodtabs/RecentlyUsedScreen';

const Stack = createStackNavigator();
const Tab = createMaterialTopTabNavigator();

const SearchStack = () => {
	return (
		<Stack.Navigator initialRouteName="SearchScreen">
			<Stack.Screen
				name="SearchScreen"
				component={FsSearchScreen}
				initialParams={{ manage: false }}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('search')}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="SearchSingleScreen"
				component={FsSingleScreen}
				initialParams={{ manage: false }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('addFood')}
								right={<HeaderMenuDropdown array={params.menuArray} />}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const BarcodeStack = () => {
	return (
		<Stack.Navigator initialRouteName="Barcode">
			<Stack.Screen
				name="Barcode"
				component={BarcodeScreen}
				initialParams={{ manage: false }}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('barcode')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const MyFoodStack = () => {
	return (
		<Stack.Navigator initialRouteName="CustomFood">
			<Stack.Screen
				name="CustomFood"
				component={CustomFoodTab}
				initialParams={{ manage: false }}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('foods')}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="CustomFoodSingle"
				component={CustomFoodTabSingle}
				initialParams={{ manage: false }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('foods')}
								right={<HeaderMenuDropdown array={params.menuArray} />}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const MyMealStack = () => {
	return (
		<Stack.Navigator initialRouteName="CustomMeal">
			<Stack.Screen
				name="CustomMeal"
				component={CustomMealTab}
				initialParams={{ manage: false }}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('mealsRecipes')}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="CustomMealSingle"
				component={CustomMealTabSingle}
				initialParams={{ manage: false }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('mealsRecipes')}
								right={<HeaderMenuDropdown array={params.menuArray} />}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const MyEditStack = () => {
	return (
		<Stack.Navigator initialRouteName="CustomMealSingleEdit">
			<Stack.Screen
				name="CustomMealSingleEdit"
				component={CustomMealTabSingleEdit}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('editMeal')}
								right={
									<HeaderTextButton title="Save" onPress={params.onSubmit} />
								}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="MealEditingSearch"
				component={FsSearchScreen}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('addIngredient')}
							/>
						);
					},
					tabBarVisible: false
				}}
				initialParams={{ editingMeal: true }}
			/>
			<Stack.Screen
				name="MealEditingSingleSearch"
				component={FsSingleScreen}
				initialParams={{ editingMeal: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('addIngredient')}
							/>
						);
					},
					tabBarVisible: false
				}}
			/>
			<Stack.Screen
				name="MealEditingBarcode"
				component={BarcodeScreen}
				initialParams={{ editingMeal: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('addIngredient')}
							/>
						);
					},
					tabBarVisible: false
				}}
			/>
			<Stack.Screen
				name="MealEditingTabList"
				component={MealEditingTabList}
				initialParams={{ editingMeal: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('addIngredient')}
							/>
						);
					},
					tabBarVisible: false
				}}
			/>
			<Stack.Screen
				name="MealEditingSingleFood"
				component={CustomFoodTabSingle}
				initialParams={{ editingMeal: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('addIngredient')}
							/>
						);
					},
					tabBarVisible: false
				}}
			/>
			<Stack.Screen
				name="MealEditingSingleMeal"
				component={CustomMealTabSingleClone}
				initialParams={{ editingMeal: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('addIngredient')}
							/>
						);
					},
					tabBarVisible: false
				}}
			/>
		</Stack.Navigator>
	);
};

const FavStack = () => {
	return (
		<Stack.Navigator initialRouteName="Favorites">
			<Stack.Screen
				name="Favorites"
				component={FavoriteScreen}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('favorites')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const RecentStack = () => {
	return (
		<Stack.Navigator initialRouteName="Recent">
			<Stack.Screen
				name="Recent"
				component={RecentlyUsedScreen}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('recentlyused')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const LogEntryStack = () => {
	return (
		<Stack.Navigator initialRouteName="EditFood">
			<Stack.Screen
				name="EditApi"
				component={FsSingleScreen}
				initialParams={{ editingLogEntry: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('editLogEntry')}
								right={<HeaderMenuDropdown array={params.favArray} />}
							/>
						);
					},
					tabBarVisible: false
				}}
			/>
			<Stack.Screen
				name="EditFood"
				component={CustomFoodTabSingle}
				initialParams={{ editingLogEntry: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('editLogEntry')}
								right={<HeaderMenuDropdown array={params.favArray} />}
							/>
						);
					},
					tabBarVisible: false
				}}
			/>
			<Stack.Screen
				name="EditMeal"
				component={CustomMealTabSingle}
				initialParams={{ editingLogEntry: true }}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('editLogEntry')}
								right={<HeaderMenuDropdown array={params.favArray} />}
							/>
						);
					},
					tabBarVisible: false
				}}
			/>
		</Stack.Navigator>
	);
};

const AddFoodScreenRouter = ({ route }) => {
	return (
		<Tab.Navigator
			initialRouteName="SearchScreen"
			backBehavior={'none'}
			lazy={true}
			swipeEnabled={false}
			tabBarPosition={'bottom'}
			tabBar={(props) => (
				<NavOptionsTopTab
					{...props}
					{...{
						showIcon: true,
						upperCaseLabel: false,
						allowFontScaling: false,
						scrollEnabled: true
					}}
				/>
			)}>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('search'),
					tabBarIcon: ({ color, size }) => (
						<FontAwesome name={'search'} size={20} color={color} />
					)
				}}
				name="SearchScreen"
				component={SearchStack}
			/>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('barcode'),
					tabBarIcon: ({ color, size }) => (
						<FontAwesome name={'barcode'} size={20} color={color} />
					)
				}}
				name="Barcode"
				component={BarcodeStack}
			/>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('foods'),
					gesturesEnabled: false,
					tabBarIcon: ({ color, size }) => (
						<FontAwesome name={'spoon'} size={20} color={color} />
					)
				}}
				name="CustomFood"
				component={MyFoodStack}
			/>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('meals'),
					tabBarIcon: ({ color, size }) => (
						<FontAwesome name={'cutlery'} size={20} color={color} />
					)
				}}
				name="CustomMeal"
				component={MyMealStack}
			/>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('favorites'),
					tabBarIcon: ({ color, size }) => (
						<FontAwesome name={'star'} size={20} color={color} />
					)
				}}
				name="Favorites"
				component={FavStack}
			/>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('recent'),
					tabBarIcon: ({ color, size }) => (
						<FontAwesome name={'history'} size={20} color={color} />
					)
				}}
				name="Recent"
				component={RecentStack}
			/>
		</Tab.Navigator>
	);
};

export { AddFoodScreenRouter, MyEditStack, LogEntryStack };
