//@flow
import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, FlatList, Keyboard } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { copyFriendItem, copyAllTab } from './actions/FirebaseActionsFriends';
import {
	removeListener,
	listenToFriendsMeals,
	listenToFriendsFoods
} from 'Redux/actions';
import Icon from 'react-native-vector-icons/FontAwesome';
import IconG from 'react-native-vector-icons/MaterialCommunityIcons';
import styles from './Styles/Styles';
import _ from 'lodash';
import { i18n } from 'Theme';
import { calculateMacroDescription } from 'Utilities/MacrosUtil';
import { Loader, Text } from 'Components/common';
import Toast from 'react-native-root-toast';

export const FriendsSingleScreen = ({ navigation, route }) => {
	const dispatch = useDispatch();
	const [tabView, changeTabView] = useState(true);
	const [copied, changeCopied] = useState([]);
	const [copiedAllF, changeCopiedF] = useState(false);
	const [copiedAllM, changeCopiedM] = useState(false);
	const { loading } = useSelector((state) => state.ui);
	const { carbUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);

	const { theme } = useSelector((state) => state.startup);

	const {
		savedMeals: { items: mealItems, inProgress },
		customFoods: { items: foodItems }
	} = useSelector((state) => state.friendsListener);

	useEffect(() => {
		dispatch(listenToFriendsMeals(route.params.friendUID));
		dispatch(listenToFriendsFoods(route.params.friendUID));

		return () => {
			dispatch(removeListener('savedMeals', true));
			dispatch(removeListener('customFoods', true));
		};
	}, []);

	const navigateCloseKeyboard = (item) => {
		Keyboard.dismiss();
		navigation.navigate(routeConfig(item.type), {
			...route.params,
			foodID: item.id,
			item
		});
	};

	const alreadyCopiedError = () => {
		return Toast.show(i18n.t('alreadyCopied'), {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	};

	const routeConfig = (itemType) => {
		switch (itemType) {
			case 'api':
				return 'SearchSingleScreen';
			case 'food':
				return 'FriendsSingleFoodScreen';
			case 'meal':
				return 'FriendsSingleMealScreen';
			case 'macro':
				return 'plus';
			default:
				return '';
		}
	};

	const addToCopied = (item) => {
		const newState = [...copied.slice(), item];
		changeCopied(newState);
	};

	const copyItem = (item) => {
		dispatch(copyFriendItem(item, () => addToCopied(item)));
	};

	const copyAllOfTab = (items, type) => {
		let filterItems = items.filter((el) => {
			return !copied.includes(el);
		});
		const fState = type === 'food' ? true : copiedAllF;
		const mState = type === 'meal' ? true : copiedAllM;
		dispatch(
			copyAllTab(filterItems, type, () => {
				changeCopied([...copied.slice(), ...filterItems]);
				changeCopiedF(fState);
				changeCopiedM(mState);
			})
		);
	};

	const isCopied = (item) => {
		const indexToRemove = copied.findIndex((i) => i.id === item.id);
		if (indexToRemove === -1) {
			return false;
		} else {
			return true;
		}
	};

	const renderItem = ({ item }) => {
		return (
			<View
				style={[
					styles.rowContainer,
					{ backgroundColor: theme.baseBG, borderColor: theme.border }
				]}>
				<View style={styles.textHolder}>
					<View style={styles.textContainer}>
						<TouchableOpacity onPress={() => navigateCloseKeyboard(item)}>
							<Text style={[styles.itemName, { color: theme.themeAccent }]}>
								{item.name}
							</Text>
							<Text style={styles.itemDescription}>
								{calculateMacroDescription(item, carbUnits)}
							</Text>
						</TouchableOpacity>
					</View>
				</View>
				<TouchableOpacity
					style={[styles.quickAddHolder, { backgroundColor: theme.grey }]}
					onPress={() => {
						isCopied(item) ? alreadyCopiedError() : copyItem(item);
					}}>
					<Icon
						style={styles.quickAdd}
						name={isCopied(item) ? 'check' : 'plus'}
						color={isCopied(item) ? theme.green : theme.darkFont}
						size={12}
					/>
				</TouchableOpacity>
			</View>
		);
	};

	const formatArr = (items) => {
		const arr = _.values(items);
		return arr.slice().sort((a, b) => {
			var textA = a.name.toUpperCase();
			var textB = b.name.toUpperCase();
			return textA < textB ? -1 : textA > textB ? 1 : 0;
		});
	};

	const bgStyle = {
		backgroundColor: tabView ? theme.backdropGray : theme.baseAlt
	};
	const bgStyle2 = {
		backgroundColor: !tabView ? theme.backdropGray : theme.baseAlt
	};
	const mealArr = formatArr(mealItems);
	const foodArr = formatArr(foodItems);

	if (inProgress || (mealArr.length === 0 && foodArr.length === 0)) {
		return <View />;
	}

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<Text
				style={{
					textAlign: 'center',
					backgroundColor: theme.grey,
					paddingTop: 15
				}}>
				{route.params.title}
			</Text>
			<View style={styles.itemListStyle}>
				{tabView && !loading && (
					<View
						style={[
							styles.copyAll,
							{ backgroundColor: theme.grey, borderBottomColor: theme.border }
						]}>
						<TouchableOpacity
							style={[styles.copyAllTab, { borderColor: theme.themeAccent }]}
							onPress={() => {
								copiedAllF
									? alreadyCopiedError()
									: copyAllOfTab(foodArr, 'food');
							}}>
							<IconG
								style={styles.tabIcon}
								name={copiedAllF ? 'check' : 'content-save-all'}
								color={theme.themeAccent}
								size={18}
							/>
							<Text style={[styles.tabText, { color: theme.themeAccent }]}>
								{i18n.t('copyAll')}
							</Text>
						</TouchableOpacity>
					</View>
				)}
				{!tabView && !loading && (
					<View
						style={[
							styles.copyAll,
							{ backgroundColor: theme.grey, borderBottomColor: theme.border }
						]}>
						<TouchableOpacity
							style={[styles.copyAllTab, { borderColor: theme.themeAccent }]}
							onPress={() => {
								copiedAllM
									? alreadyCopiedError()
									: copyAllOfTab(mealArr, 'meal');
							}}>
							<IconG
								style={styles.tabIcon}
								name={copiedAllM ? 'check' : 'content-save-all'}
								color={theme.themeAccent}
								size={18}
							/>
							<Text style={[styles.tabText, { color: theme.themeAccent }]}>
								{i18n.t('copyAll')}
							</Text>
						</TouchableOpacity>
					</View>
				)}
				{loading && (
					<Loader
						full={true}
						loaderBG={theme.baseBG}
						loaderColor={theme.darkFont}
					/>
				)}
				{!loading && (
					<FlatList
						data={tabView ? foodArr : mealArr}
						keyExtractor={(item) => item.id}
						renderItem={renderItem}
						keyboardShouldPersistTaps="handled"
					/>
				)}
			</View>
			<View style={styles.friendFoodSwitchContainer}>
				<TouchableOpacity
					style={[styles.sectionTab, { borderTopColor: theme.border }, bgStyle]}
					onPress={() => changeTabView(true)}>
					<Icon
						style={styles.tabIcon}
						name="spoon"
						color={theme.tabInactive}
						size={14}
					/>
					<Text style={styles.tabText}>{i18n.t('foods')}</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={[
						styles.sectionTab,
						{ borderTopColor: theme.border },
						bgStyle2,
						styles.noBorderLeft
					]}
					onPress={() => changeTabView(false)}>
					<Icon
						style={styles.tabIcon}
						name="cutlery"
						color={theme.tabInactive}
						size={14}
					/>
					<Text style={styles.tabText}>{i18n.t('meals')}</Text>
				</TouchableOpacity>
			</View>
		</View>
	);
};
