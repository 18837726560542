//@flow
import React from 'react';
import { TouchableOpacity, Dimensions, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';
import NavigationBar from 'react-native-navbar';
import { MaterialTopTabBar } from '@react-navigation/material-top-tabs';
import { BottomTabBar } from '@react-navigation/bottom-tabs';
import Elevations from 'react-native-elevation';

export const NavOptionsHeader = (props) => {
	const { right, navigation, title } = props;
	const { theme } = useSelector((state) => state.startup);

	return (
		<NavigationBar
			statusBar={Platform.OS === 'ios' ? { hidden: false } : { hidden: true }}
			title={{
				title: title,
				style: {
					color: theme.navText,
					fontFamily: 'Comfortaa',
					fontSize: 14
				}
			}}
			containerStyle={{
				backgroundColor: theme.navBar,
				justifyContent: 'center',
				paddingHorizontal: 10,
				...Elevations[4]
			}}
			leftButton={
				<TouchableOpacity
					style={{
						paddingHorizontal: 15,
						justifyContent: 'center'
					}}
					onPress={() => navigation.openDrawer()}>
					<Icon name="md-menu" color={theme.navText} size={24} />
				</TouchableOpacity>
			}
			rightButton={right}
		/>
	);
};

export const NavOptionsHeaderNoDraw = (props) => {
	const { right, navigation, title } = props;
	const { theme } = useSelector((state) => state.startup);

	return (
		<NavigationBar
			statusBar={Platform.OS === 'ios' ? { hidden: false } : { hidden: true }}
			title={{
				title: title,
				style: {
					color: theme.navText,
					fontFamily: 'Comfortaa',
					fontSize: 14
				}
			}}
			containerStyle={{
				backgroundColor: theme.navBar,
				justifyContent: 'center',
				paddingHorizontal: 10,
				...Elevations[4]
			}}
			leftButton={
				<TouchableOpacity
					style={{
						paddingHorizontal: 15,
						justifyContent: 'center'
					}}
					onPress={() => navigation.goBack(null)}>
					<Icon name="md-arrow-back" color={theme.navText} size={24} />
				</TouchableOpacity>
			}
			rightButton={right}
		/>
	);
};

export const NavOptionsTransparent = (navigation) => {
	return {
		title: '',
		headerLeft: () => (
			<TouchableOpacity
				style={{
					paddingHorizontal: 15,
					justifyContent: 'center'
				}}
				onPress={() => navigation.goBack(null)}>
				<Icon name={'md-arrow-back'} color={'#f9f9f9'} size={30} />
			</TouchableOpacity>
		),
		headerBackTitle: null,
		headerTransparent: true
	};
};

export const NavOptionsBottomTab = (props) => {
	const { theme } = useSelector((state) => state.startup);
	const { width } = Dimensions.get('window');
	const style = {
		style: {
			backgroundColor: theme.tabBase,
			borderTopWidth: 1,
			borderTopColor: theme.border,
			alignItems: 'center',
			...Elevations[2]
		},
		labelStyle: {
			fontSize: 10,
			fontFamily: 'Comfortaa'
		},
		iconStyle: {
			padding: 0,
			margin: 0,
			alignSelf: 'center'
		},
		tabStyle: {
			width: width / props.state.routes.length,
			borderRightWidth: 1,
			borderRightColor: theme.border,
			borderLeftWidth: 1,
			borderLeftColor: theme.border,
			paddingVertical: 5
		},
		activeBackgroundColor: theme.tabBase,
		inactiveBackgroundColor: theme.tabBase,
		activeTintColor: theme.tabActive,
		inactiveTintColor: theme.tabInactive,
		showIcon: true,
		upperCaseLabel: false,
		allowFontScaling: false
	};

	return <BottomTabBar {...props} {...style} />;
};

export const NavOptionsTopTab = (props) => {
	const { theme } = useSelector((state) => state.startup);
	const { width } = Dimensions.get('window');
	const style = {
		style: {
			backgroundColor: theme.tabBase,
			borderTopWidth: 1,
			borderTopColor: theme.border
		},
		labelStyle: {
			fontSize: 10,
			fontFamily: 'Comfortaa'
		},
		iconStyle: {
			padding: 0,
			margin: 0,
			alignItems: 'center'
		},
		tabStyle: {
			width: Platform.OS === 'web' ? width / props.state.routes.length : 90,
			borderRightWidth: 1,
			borderRightColor: theme.border,
			borderLeftWidth: 1,
			borderLeftColor: theme.border,
			paddingVertical: 5
		},
		indicatorStyle: {
			backgroundColor: theme.tabActive
		},
		activeBackgroundColor: theme.tabBase,
		inactiveBackgroundColor: theme.tabBase,
		activeTintColor: theme.tabActive,
		inactiveTintColor: theme.tabInactive,
		showIcon: true,
		upperCaseLabel: false,
		allowFontScaling: false
	};

	return <MaterialTopTabBar {...props} {...style} />;
};
