//@flow
import * as types from '../actions/types';
import createReducer from '../createReducer';
import { calculateMultiplier } from 'Utilities/ServingSelectorLogic';
import { i18n } from 'Theme';

export function getInitialState() {
	let state = {};
	Object.keys(types.itemTypes).forEach((key) => {
		let subState = {
			lastValueConfirmed: [1, '-', i18n.t('servings')],
			activeServing: [1, '-', i18n.t('servings')],
			servingSelectType: 'wheel'
		};
		state[key] = subState;
	});

	return state;
}

const initialState = getInitialState();

export const singleItemReducer = createReducer(initialState, {
	[types.items.RESET_ITEM](state, action) {
		const property = action.itemType;
		let newState = {
			...state,
			[property]: {
				lastValueConfirmed: [1, '-', i18n.t('servings')],
				activeServing: [1, '-', i18n.t('servings')],
				servingSelectType: 'wheel'
			}
		};
		return newState;
	},
	[types.items.RECEIVE_ITEM](state, action) {
		const property = action.itemType;
		const propertyState = state[property];
		let newState = {
			...state,
			[property]: {
				...propertyState,
				isFetching: false,
				itemData: action.itemData
			}
		};
		return newState;
	},
	[types.items.SERVING_WHEEL_CANCEL](state, action) {
		const property = action.itemType;
		const propertyState = state[property];
		let newState = {
			...state,
			[property]: {
				...propertyState,
				activeServing: propertyState.lastValueConfirmed
			}
		};
		return newState;
	},
	[types.items.SERVING_WHEEL_CHANGE](state, action) {
		const property = action.itemType;
		const propertyState = state[property];
		let newState = {
			...state,
			[property]: { ...propertyState, activeServing: action.activeWheelValue }
		};
		return newState;
	},
	[types.items.SERVING_WHEEL_CONFIRM](state, action) {
		const property = action.itemType;
		const propertyState = state[property];
		let newState = {
			...state,
			[property]: {
				...propertyState,
				activeServing: action.activeWheelValue,
				lastValueConfirmed: action.activeWheelValue
			}
		};
		return newState;
	},
	[types.items.SERVING_INPUT_QTY_CHANGE](state, action) {
		const property = action.itemType;
		const propertyState = state[property];
		let newState = {
			...state,
			[property]: {
				...propertyState,
				activeServing: [action.value, '-', propertyState.lastValueConfirmed[2]],
				lastValueConfirmed: [
					action.value,
					'-',
					propertyState.lastValueConfirmed[2]
				]
			}
		};
		return newState;
	},
	[types.items.SERVING_INPUT_LABEL_CHANGE](state, action) {
		const property = action.itemType;
		const propertyState = state[property];
		let newState = {
			...state,
			[property]: {
				...propertyState,
				activeServing: [
					propertyState.lastValueConfirmed[0],
					'-',
					action.activeLabel
				],
				lastValueConfirmed: [
					propertyState.lastValueConfirmed[0],
					'-',
					action.activeLabel
				]
			}
		};
		return newState;
	},
	[types.items.SWITCH_SERVING_SELECTOR](state, action) {
		const property = action.itemType;
		const propertyState = state[property];
		let lastValue = [];
		if (action.payload === 'wheel') {
			lastValue = propertyState.lastValueConfirmed;
		} else {
			lastValue = [
				calculateMultiplier(propertyState.lastValueConfirmed),
				'-',
				propertyState.lastValueConfirmed[2]
			];
		}
		let newState = {
			...state,
			[property]: {
				...propertyState,
				servingSelectType: action.activeType,
				lastValueConfirmed: lastValue
			}
		};
		return newState;
	},
	[types.items.PRELOAD_EDIT_SERVING](state, action) {
		const property = action.itemType;
		const propertyState = state[property];
		let newState = {
			...state,
			[property]: {
				...propertyState,
				activeServing: action.arr,
				lastValueConfirmed: action.arr
			}
		};
		return newState;
	}
});
