//@flow
import React from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import { WhiteButton, Paragraph, Loader, ListHeader } from 'Components/common';
import { FsSearchResults } from './';
import { useSelector, useDispatch } from 'react-redux';
import { combineLoadMoreResults } from '../../actions';
import { i18n } from 'Theme';

type Props = {
	route: string,
	theme: Object
};

export const FsSearchResultsContainer = (props: Props) => {
	const { theme } = props;
	const fatSecret = useSelector((state) => state.fatSecretApi);
	const dispatch = useDispatch();
	const { isFetching, canLoadMore, searchResults, totalResults } = fatSecret;

	//Render Each Search Result Item
	const renderItem = ({ item }) => {
		return (
			<FsSearchResults
				food={item}
				route={props.route}
				border={theme.border}
				themeColor={theme.themeAccent}
			/>
		);
	};

	const renderFooter = () => {
		return (
			searchResults.length > 0 &&
			canLoadMore && (
				<WhiteButton onPress={() => dispatch(combineLoadMoreResults())}>
					{i18n.t('loadMore')}
				</WhiteButton>
			)
		);
	};

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<ListHeader
				style={{ backgroundColor: theme.base }}
				leftText={`${i18n.t('results')}:`}
				noMargin={true}
			/>
			{isFetching && <Loader full={true} />}
			{!isFetching && totalResults === '0' && (
				<Paragraph style={styles.noResults}>{i18n.t('zeroResults')}</Paragraph>
			)}
			{searchResults.length > 0 && (
				<FlatList
					data={searchResults}
					keyExtractor={(item) => item.food_id}
					renderItem={renderItem}
					ListFooterComponent={renderFooter}
				/>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	noResults: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
		flex: 1,
		marginTop: 100
	}
});
