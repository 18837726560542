//@flow
import {
	NEVER_RATE_APP,
	START_UP_COUNTER,
	CHANGE_LANG,
	DB_CHECKED,
	TOGGLE_DARK_MODE
} from '../actions/types';
import { Colors, Dark } from 'Theme';

const INITIAL_STATE = {
	startCounter: 0,
	askRateAgain: true,
	language: 'en',
	dbChecked: false,
	theme: Colors,
	darkMode: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case START_UP_COUNTER:
			return { ...state, startCounter: action.payload };
		case NEVER_RATE_APP:
			return { ...state, askRateAgain: false };
		case CHANGE_LANG:
			return { ...state, language: action.payload };
		case DB_CHECKED:
			return { ...state, dbChecked: action.payload };
		case TOGGLE_DARK_MODE:
			return { ...state, theme: action.payload, darkMode: !state.darkMode };
		default:
			return state;
	}
};
