//@flow
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Colors } from 'Theme';
import moment from 'moment';

type Props = {
	date: string,
	changeDate: Function,
	theme: Object
};

export const ProgressDateHeader = (props: Props) => {
	const { date, changeDate, theme } = props;

	return (
		<View
			style={[
				styles.dateContainer,
				{ backgroundColor: theme.grey, borderBottomColor: theme.border }
			]}>
			<TouchableOpacity
				style={styles.dateArrows}
				onPress={() =>
					changeDate(moment(date).add(-1, 'day').format('YYYY-MM-DD'))
				}>
				<FontAwesome name="minus-circle" size={20} color={theme.darkFont} />
			</TouchableOpacity>
			<DatePicker
				onChange={(x) => changeDate(moment(x).format('YYYY-MM-DD'))}
				selected={moment(date, 'YYYY-MM-DD').toDate()}
			/>
			<TouchableOpacity
				style={styles.dateArrows}
				onPress={() =>
					changeDate(moment(date).add(1, 'day').format('YYYY-MM-DD'))
				}>
				<FontAwesome name="plus-circle" size={20} color={theme.darkFont} />
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	dateContainer: {
		zIndex: 9999,
		flexDirection: 'row',
		height: 50,
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1
	},
	dateArrows: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	}
});
