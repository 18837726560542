//@flow
import React, { useState } from 'react';
import {
	View,
	KeyboardAvoidingView,
	Keyboard,
	TouchableWithoutFeedback,
	Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { Nav } from './';
import { OptionSelector, OptionTextInput, Gram, Text } from 'Components/common';
import styles from './styles';
import {
	getBaseCalories,
	updateCalcValue
} from 'Components/calculator/actions/MacroCalculatorActions';
import {
	convertWeightToLbs,
	convertWeightToMetric
} from 'Components/calculator/utilities/MacroCalculatorHelper';
import { validateAll } from 'Utilities';
import { i18n } from 'Theme';

type Props = {
	page: number,
	next: Function,
	prev: Function,
	dotNumber?: number
};

export const PageProfile2 = (props: Props) => {
	const bmr = useSelector((state) => state.calculator.bmr);
	const { exercise, goalWeight } = useSelector(
		(state) => state.firebase.profile.profilePage
	);

	const { bodymeasurementsUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);

	const [weightState, changeWeight] = useState(
		bodymeasurementsUnits === 'metric'
			? goalWeight
			: convertWeightToLbs(goalWeight)
	);

	const { next, page, prev, dotNumber, theme } = props;
	const dispatch = useDispatch();

	//Exercise constant options for the Exercise dropdown field
	const exerciseOptions = [
		{ label: i18n.t('littleToNone'), value: 1.1 },
		{ label: i18n.t('exerciseOne'), value: 1.375 },
		{ label: i18n.t('exerciseTwo'), value: 1.55 },
		{ label: i18n.t('exerciseThree'), value: 1.725 },
		{ label: i18n.t('exerciseFour'), value: 1.9 }
	];

	const submit = () => {
		if (!validateAll([+weightState])) return;
		const formattedWeight =
			bodymeasurementsUnits === 'metric'
				? weightState
				: convertWeightToMetric(weightState);
		dispatch(updateCalcValue('goalWeight', (+formattedWeight).toFixed(2)));
		dispatch(getBaseCalories(bmr, exercise));
		next();
	};

	const returnWeightForm = (w) => {
		return bodymeasurementsUnits === 'metric' ? (
			<OptionTextInput
				onChange={(value) => changeWeight(value.replace(',', '.'))}
				currentValue={w}
				placeholder={i18n.t('goalWeight')}>
				<Gram customText={'kg'} />
			</OptionTextInput>
		) : (
			<OptionTextInput
				onChange={(value) => changeWeight(value.replace(',', '.'))}
				currentValue={w}
				placeholder={i18n.t('goalWeight')}>
				<Gram customText={'lbs'} />
			</OptionTextInput>
		);
	};

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS === 'ios' ? 'padding' : null}
			style={styles.pageContainer}>
			<TouchableWithoutFeedback
				onPress={Platform.OS === 'web' ? null : Keyboard.dismiss}>
				<View style={{ flex: 1 }}>
					<Nav
						onForward={submit}
						page={page}
						onBack={prev}
						dotNumber={dotNumber}
						theme={theme}
					/>
					<Text style={styles.pageTitle}>{i18n.t('calculatorDetails')}</Text>
					<View style={styles.inputsContainer}>
						<OptionSelector
							onChange={(value) =>
								dispatch(updateCalcValue('exercise', +value))
							}
							currentValue={exercise}
							placeholder={i18n.t('exerciseLevel')}
							data={exerciseOptions}
						/>
						{returnWeightForm(weightState)}
					</View>
				</View>
			</TouchableWithoutFeedback>
		</KeyboardAvoidingView>
	);
};
