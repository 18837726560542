//@flow
import { i18n } from 'Theme';
import Toast from 'react-native-root-toast';
import { randomString } from 'Utilities/FirebaseHelper';
import * as NavigationService from 'Navigation/NavigationService';
import type { FirebaseLogEntry } from 'Utilities/FlowTypes';
import { ADDED_MACROS_TO_LOG } from 'Components/tracker/log/actions/types';
import { incrementTrackerInterAdCount } from 'Redux/actions';

//-----------------------------------------------------Search Food Single --------------------------------------------------------------------------------------

//Adds Single Searched Food To Log
export const logSingleSearched = (
	item: FirebaseLogEntry,
	foodName: string,
	date: string,
	time: string,
	foodID: string,
	foodNameRaw: string
) => (dispatch: Dispatch, getState: GetState, getFirebase: GetFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { premium } = state.firebase.profile;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};
	const uniqid = randomString(14);

	const postData: FirebaseLogEntry = {
		name: foodName,
		calories: item.calories,
		fats: item.fats,
		proteins: item.proteins,
		carbs: item.carbs,
		totalcarbs: item.totalcarbs,
		fiber: item.fiber,
		nutrientsV2: item.nutrientsV2,
		id: foodID,
		type: 'api',
		random: uniqid
	};

	updates[`trackerdate/${date}/${time}/${uniqid}`] = postData;
	updates[`recentlyused/${foodID}`] = {
		name: foodNameRaw,
		id: foodID,
		type: 'api'
	};

	ref.update(updates).then(() => {
		dispatch({ type: ADDED_MACROS_TO_LOG, payload: time });
		if (!premium) dispatch(incrementTrackerInterAdCount());
		Toast.show(`${foodName} ${i18n.t('addedToLog')}`, {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
	return NavigationService.navigate('LogScreen', {});
};
