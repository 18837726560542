import React from 'react';
import { StyleSheet, View } from 'react-native';

export const Row = (props) => {
	return <View style={[styles.rowLayout, props.style]}>{props.children}</View>;
};

var styles = StyleSheet.create({
	rowLayout: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		padding: 10,
		borderBottomWidth: 1,
		borderBottomColor: '#e6e6e6'
	}
});
