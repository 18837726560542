//@flow
import { persistCombineReducers } from 'redux-persist';
import StartUpReducer from './StartUpReducer';
import AdReducer from './AdReducer';
import EdamamRecipesReducer from 'Components/recipes/reducers/EdamamRecipesReducer';
import RecipeFeedReducer from 'Components/reddit/reducers/RecipeFeedReducer';
import RecipeFeedSingleReducer from 'Components/reddit/reducers/RecipeFeedSingleReducer';
import FatSecretApiReducer from 'Components/fatsecret/reducers/FatSecretApiReducer';
import FatSecretSingleFoodReducer from 'Components/fatsecret/reducers/FatSecretSingleFoodReducer';
import FatSecretApiCacheReducer from 'Components/fatsecret/reducers/FatSecretApiCacheReducer';
import CalculatorReducer from 'Components/calculator/reducers/CalculatorReducer';
import LogReducer from 'Components/tracker/log/reducers/LogReducer';
import ProgressReducer from 'Components/tracker/progress/reducers/ProgressReducer';
import FitnessTrackerReducer from 'Components/tracker/fitnesstrackers/reducers/FitnessTrackerReducer';
import PurchaseReducer from 'Components/purchase/reducers/PurchaseReducer';
import SingleItemEditingMealReducer from './SingleItemEditingMealReducer';
import { singleItemReducer } from './SingleItemReducer';
import { friendsListenerReducer } from './FriendsListenerReducer';
import UIManagerReducer from './UIManagerReducer';
import AsyncStorage from '@react-native-community/async-storage';
import { firebaseReducer } from 'react-redux-firebase';
import { createFilter } from 'redux-persist-transform-filter';
import BodyMeasurementsReducer from './BodyMeasurementsReducer';
import VitalsReducer from './VitalsReducer';
import OtherTrackingReducer from './OtherTrackingReducer';
import ShoppingListReducer from 'Components/shoppinglist/reducers/ShoppingListReducer';

//const formFilter1 = createFilter('startup', ['theme']);
const formFilter2 = createFilter('netCarbLookUp', ['items']);
const formFilter3 = createFilter('fatSecretApi', ['recentBarcodes']);
const formFilter4 = createFilter('log', ['activeTab']);

export default persistCombineReducers(
	{
		blacklist: [
			'reddit',
			'redditSingle',
			'fatSecretSingleFood',
			'progress',
			'vitals',
			'bodymeasurements',
			'othertracking'
		],
		transforms: [formFilter2, formFilter3, formFilter4],
		storage: AsyncStorage,
		key: 'root'
	},
	{
		adReducer: AdReducer,
		calculator: CalculatorReducer,
		edamamRecipeApi: EdamamRecipesReducer,
		fatSecretApi: FatSecretApiReducer,
		fatSecretApiCache: FatSecretApiCacheReducer,
		fatSecretSingleFood: FatSecretSingleFoodReducer,
		firebase: firebaseReducer,
		fitnessTracker: FitnessTrackerReducer,
		friendsListener: friendsListenerReducer,
		log: LogReducer,
		progress: ProgressReducer,
		purchase: PurchaseReducer,
		reddit: RecipeFeedReducer,
		redditSingle: RecipeFeedSingleReducer,
		singleItemMealEdit: SingleItemEditingMealReducer,
		singleItem: singleItemReducer,
		startup: StartUpReducer,
		ui: UIManagerReducer,
		bodymeasurements: BodyMeasurementsReducer,
		vitals: VitalsReducer,
		othertracking: OtherTrackingReducer,
		shoppinglist: ShoppingListReducer
	}
);
