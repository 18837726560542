import {
	ADD_SHOPPING_ITEM,
	DELETE_SHOPPING_ITEM,
	TOGGLE_SHOPPING_ITEM
} from '../actions/types';

const INITIAL_STATE = {
	items: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_SHOPPING_ITEM:
			return { ...state, items: [...state.items, action.payload] };
		case DELETE_SHOPPING_ITEM:
			const deleteKey = action.payload;
			return {
				...state,
				items: state.items.filter((item) => item.key !== deleteKey)
			};
		case TOGGLE_SHOPPING_ITEM:
			const toBeCompletedID = action.payload.key;
			const elementsIndex = state.items.findIndex(
				(element) => element.key === toBeCompletedID
			);
			let newArray = [...state.items];
			newArray[elementsIndex] = {
				...newArray[elementsIndex],
				completed: !newArray[elementsIndex].completed
			};
			return {
				...state,
				items: newArray
			};
		default:
			return state;
	}
};
