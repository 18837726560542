import React from 'react';
import { StyleSheet, View, Linking, Image, Dimensions } from 'react-native';
import {
	Paragraph,
	CardSection,
	Divider,
	Text,
	Heading
} from 'Components/common';
import he from 'he';
import Hyperlink from 'react-native-hyperlink';

const window = Dimensions.get('window');

const linkURL = (url) => {
	Linking.openURL(url);
};

export const CommentOP = (props) => {
	const { thread } = props;
	return (
		<View>
			{thread.preview && (
				<CardSection style={styles.paddingZero}>
					<Image
						style={styles.imageStyle}
						source={{
							uri: thread.preview.images[0].source.url.replace(/&amp;/g, '&')
						}}
					/>
				</CardSection>
			)}
			<Heading style={styles.cardHeading}>{thread.title}</Heading>
			<CardSection>
				<Hyperlink onPress={(url) => linkURL(url)} linkStyle={styles.linkStyle}>
					<Text>{thread.selftext && he.unescape(thread.selftext)}</Text>
					<Divider />
					<Text>{thread.url && thread.url}</Text>
				</Hyperlink>
			</CardSection>
		</View>
	);
};

const styles = StyleSheet.create({
	cardHeading: {
		padding: 0,
		marginBottom: 0
	},
	paddingZero: {
		padding: 0,
		borderLeftWidth: 1,
		borderLeftColor: '#ddd',
		borderRightColor: '#ddd',
		borderRightWidth: 1
	},
	imageStyle: {
		width: window.width,
		height: (238 / 375) * window.width,
		alignSelf: 'center'
	},
	linkStyle: {
		color: '#2980b9',
		fontSize: 12
	}
});
