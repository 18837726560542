//@flow
import {
	UPDATE_LOG_DATE,
	CHANGE_LOG_VIEW,
	SET_COPY_TIME,
	SET_COPY_DATE,
	SET_COPY_ITEMS,
	SET_MEAL_TIME,
	SET_EDIT_VALUES,
	TOGGLE_LOG_MODAL
} from '../actions/types';
import moment from 'moment';

const INITIAL_STATE = {
	date: moment().format('YYYY-MM-DD'),
	defaultData: {
		breakfast: {},
		lunch: {},
		dinner: {},
		snacks: {},
		fitbit: {}
	},
	activeTab: 0,
	mealTime: 'breakfast',
	copyTime: 'breakfast',
	copyDate: moment().format('YYYY-MM-DD'),
	copyItems: [],
	modal: {
		quickAddModal: false,
		saveMealModal: false,
		copyMealModal: false,
		editLogItemModal: false,
		newUserModal: false
	},
	editValues: {
		name: '',
		calories: '',
		fats: '',
		proteins: '',
		carbs: '',
		totalcarbs: '',
		fiber: '',
		manualCalories: false,
		nutrientsV2: []
	},
	editMealTime: ''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CHANGE_LOG_VIEW:
			return { ...state, activeTab: action.payload };
		case UPDATE_LOG_DATE:
			return { ...state, date: action.payload };
		case SET_COPY_TIME:
			return { ...state, copyTime: action.payload };
		case SET_COPY_DATE:
			return { ...state, copyDate: action.payload };
		case SET_COPY_ITEMS:
			return { ...state, copyItems: action.payload };
		case SET_MEAL_TIME:
			return { ...state, mealTime: action.payload };
		case SET_EDIT_VALUES:
			return {
				...state,
				editValues: action.payload,
				editMealTime: action.mealTime
			};
		case TOGGLE_LOG_MODAL:
			return { ...state, modal: { ...INITIAL_STATE.modal, ...action.payload } };
		default:
			return state;
	}
};
