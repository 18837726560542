//@flow
import _ from 'lodash';
import moment from 'moment';
//import {} from 'Utilities';
import { Platform, PermissionsAndroid } from 'react-native';
import { WebCompatAlert } from 'Components/common';
import { i18n } from 'Theme';
import {
	returnCorrectUnitsValue,
	returnCorrectUnitsString,
	calculateMacroTotals
} from 'Utilities';
import {
	defaultMeasurementUnits,
	defaultVitalsUnits,
	defaultOtherTrackingUnits,
	defaultNutrientUnits
} from 'Redux/selectors';

const flatten = (arr) => {
	return arr.reduce((flat, toFlatten) => {
		return flat.concat(
			Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
		);
	}, []);
};

//Helper function for sending written .csv file to the user's email
const sendExportEmail = (
	filePath: string,
	title: string,
	email: string,
	cb: Function = () => true
) => {
	return
};

//Formats all the firebase weight entries into usable formats for the .csv export
const formatBodyMeasurementsExportArray = (
	bodymeasurements: Object,
	unitsBool: boolean
) => {
};

const formatBodyMeasurementsExportLines = (finalArr, unitsBool) => {
	let headerString = `${i18n.t('date')},`;
	let rowString = '';

	for (var [key, value] of finalArr[0].measures) {
		headerString += `${i18n.t([
			key.replace(/\s+/g, '')
		])} (${returnCorrectUnitsString(
			defaultMeasurementUnits[key],
			unitsBool['bodymeasurementsUnits']
		)}),`;
	}

	rowString = finalArr
		.map((d, index) => {
			let tempString = `${d.date},`;
			for (var [key, value] of finalArr[index].measures) {
				tempString += `${value},`;
			}
			tempString += '\n';
			return tempString;
		})
		.join('');

	headerString += '\n';
	return { headerString, rowString };
};

const defaultMeasurements = {
	Weight: '',
	'Body Fat': '',
	BMI: '',
	'Lean Body Mass': '',
	'Waist Size': '',
	'Hip Size': '',
	'Chest Size': '',
	'Neck Size': '',
	'Arm Size': '',
	'Forearm Size': '',
	'Calf Size': '',
	'Thigh Size': ''
};

//On the Review -> Weight Tab header menu. Exports all the weight entries into a .CSV format.
export const exportBodyMeasurements = (
	bodymeasurements: Object,
	unitsBool: boolean,
	email: string
) => {
	if (Platform.OS === 'android') {
		PermissionsAndroid.requestMultiple(
			[
				PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
				PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
			],
			{
				title: i18n.t('permissionTitle'),
				message: i18n.t('permissionMessage')
			}
		)
			.then((permRes) => {
				if (
					permRes['android.permission.READ_EXTERNAL_STORAGE'] ===
						PermissionsAndroid.RESULTS.GRANTED &&
					permRes['android.permission.WRITE_EXTERNAL_STORAGE'] ===
						PermissionsAndroid.RESULTS.GRANTED
				) {
					const arr = formatBodyMeasurementsExportArray(
						bodymeasurements,
						unitsBool
					);
					if (arr.length === 0) {
						//if empty data, alert user and stop export
						return WebCompatAlert(i18n.t('noData'), i18n.t('noDataDesc'));
					}
					const hey = formatBodyMeasurementsExportLines(arr, unitsBool);
					const headerString = hey.headerString;
					const rowString = hey.rowString;
					const csvString = `${headerString}${rowString}`;
					const pathToWrite = `${
						RNFS.ExternalStorageDirectoryPath
					}/Download/${i18n.t('appName')}_BodyMeasurements.csv`;

					RNFS.writeFile(pathToWrite, csvString, 'utf8')
						.then(() => {
							sendExportEmail(
								pathToWrite,
								`${i18n.t('appName')}_BodyMeasurements_Export`,
								email
							);
						})
						.catch((error) => console.error(error));
				} else {
					console.warn('Permission Denied');
				}
			})
			.catch((err) => console.warn(err));
	} else {
		const arr = formatBodyMeasurementsExportArray(bodymeasurements, unitsBool);
		if (arr.length === 0) {
			//if empty data, alert user and stop export
			return WebCompatAlert(i18n.t('noData'), i18n.t('noDataDesc'));
		}
		const hey = formatBodyMeasurementsExportLines(arr, unitsBool);
		const headerString = hey.headerString;
		const rowString = hey.rowString;
		const csvString = `${headerString}${rowString}`;
		const pathToWrite = `${RNFS.DocumentDirectoryPath}/${i18n.t(
			'appName'
		)}_BodyMeasurements.csv`;
		RNFS.writeFile(pathToWrite, csvString, 'utf8')
			.then(() => {
				sendExportEmail(
					pathToWrite,
					`${i18n.t('appName')}_BodyMeasurements_Export`,
					email
				);
			})
			.catch((error) => console.error(error));
	}
};

/*-------------------------Vitals Export Data Functions -----------------------------------------*/
//Formats all the firebase weight entries into usable formats for the .csv export
const formatVitalsExportArray = (
	vitals: Object,
	unitsObj: Object,
	vitalToExport: string
) => {
	if (typeof vitals === 'undefined') {
		return [];
	}

	let arr = Object.keys(vitals)
		.map((key) => {
			if (vitals[key].hasOwnProperty(vitalToExport)) {
				return Object.values(vitals[key][vitalToExport]);
			}
		})
		.filter((item) => typeof item !== 'undefined');
	arr = flatten(arr);
	let finalArr = [];
	if (vitalToExport == i18n.t('BloodPressure')) {
		finalArr = arr.map((item: Object) => {
			return {
				date: moment(item.time).format('MMM-DD-YYYY hh:mm a'),
				value: returnCorrectUnitsValue(
					defaultVitalsUnits[vitalToExport],
					unitsObj[`${defaultVitalsUnits[vitalToExport]}Units`],
					item.value
				).toFixed(1),
				valueTwo: returnCorrectUnitsValue(
					defaultVitalsUnits[vitalToExport],
					unitsObj[`${defaultVitalsUnits[vitalToExport]}Units`],
					item.valueTwo
				).toFixed(1)
			};
		});
		const sortedArr = _.sortBy(finalArr.slice(), 'date').reverse();

		return sortedArr.map((item) => {
			let obj = {};
			obj.date = item.date;
			obj.measures = new Map();

			obj.measures.set(
				'Systolic',
				item.value ? item.value : defaultVitals[vitalToExport]
			);

			obj.measures.set(
				'Diastolic',
				item.valueTwo ? item.valueTwo : defaultVitals[vitalToExport]
			);

			return obj;
		});
	} else {
		finalArr = arr.map((item: Object) => {
			return {
				date: moment(item.time).format('MMM-DD-YYYY hh:mm a'),
				value: returnCorrectUnitsValue(
					defaultVitalsUnits[vitalToExport],
					unitsObj[`${defaultVitalsUnits[vitalToExport]}Units`],
					item.value
				).toFixed(1)
			};
		});

		const sortedArr = _.sortBy(finalArr.slice(), 'date').reverse();

		return sortedArr.map((item) => {
			let obj = {};
			obj.date = item.date;
			obj.measures = new Map();

			obj.measures.set(
				vitalToExport,
				item.value ? item.value : defaultVitals[vitalToExport]
			);

			return obj;
		});
	}
};

const formatVitalsExportLines = (finalArr, unitsObj, vitalToExport) => {
	let headerString = `${i18n.t('date')},`;
	let rowString = '';

	if (vitalToExport == i18n.t('BloodPressure')) {
		for (var [key, value] of finalArr[0].measures) {
			headerString += `${[key]} (${returnCorrectUnitsString(
				defaultVitalsUnits['Blood Pressure'],
				unitsObj[`${defaultVitalsUnits['Blood Pressure']}Units`]
			)}),`;
		}
	} else {
		for (var [key, value] of finalArr[0].measures) {
			headerString += `${i18n.t([
				key[0].replace(/\s+/g, '')
			])} (${returnCorrectUnitsString(
				defaultVitalsUnits[key],
				unitsObj[`${defaultVitalsUnits[vitalToExport]}Units`]
			)}),`;
		}
	}

	rowString = finalArr
		.map((d, index) => {
			let tempString = `${d.date},`;
			for (var [key, value] of finalArr[index].measures) {
				tempString += `${value},`;
			}
			tempString += '\n';
			return tempString;
		})
		.join('');

	headerString += '\n';
	return { headerString, rowString };
};

const defaultVitals = {
	Ketones: '',
	'Blood Glucose': '',
	Potassium: '',
	Sodium: '',
	Magnesium: '',
	'Heart Rate': '',
	'Blood Pressure': ''
};

//On the Review -> Weight Tab header menu. Exports all the weight entries into a .CSV format.
export const exportVitals = (
	vitals: Object,
	unitsBool: boolean,
	email: string,
	vitalToExport: string
) => {
	if (Platform.OS === 'android') {
		PermissionsAndroid.requestMultiple(
			[
				PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
				PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
			],
			{
				title: i18n.t('permissionTitle'),
				message: i18n.t('permissionMessage')
			}
		)
			.then((permRes) => {
				if (
					permRes['android.permission.READ_EXTERNAL_STORAGE'] ===
						PermissionsAndroid.RESULTS.GRANTED &&
					permRes['android.permission.WRITE_EXTERNAL_STORAGE'] ===
						PermissionsAndroid.RESULTS.GRANTED
				) {
					const arr = formatVitalsExportArray(vitals, unitsBool, vitalToExport);

					if (arr.length === 0) {
						//if empty data, alert user and stop export
						return WebCompatAlert(i18n.t('noData'), i18n.t('noDataDesc'));
					}
					const hey = formatVitalsExportLines(arr, unitsBool);

					const headerString = hey.headerString;
					const rowString = hey.rowString;
					const csvString = `${headerString}${rowString}`;
					const pathToWrite = `${
						RNFS.ExternalStorageDirectoryPath
					}/Download/${i18n.t('appName')}_${i18n.t(`${vitalToExport[0]}`)}.csv`;

					RNFS.writeFile(pathToWrite, csvString, 'utf8')
						.then(() => {
							sendExportEmail(
								pathToWrite,
								`${i18n.t('appName')}_${i18n.t(`${vitalToExport[0]}`)}_Export`,
								email
							);
						})
						.catch((error) => console.error(error));
				} else {
					console.warn('Permission Denied');
				}
			})
			.catch((err) => console.warn(err));
	} else {
		const arr = formatVitalsExportArray(vitals, unitsBool, vitalToExport);
		if (arr.length === 0) {
			//if empty data, alert user and stop export
			return WebCompatAlert(i18n.t('noData'), i18n.t('noDataDesc'));
		}
		const hey = formatVitalsExportLines(arr, unitsBool, vitalToExport);
		const headerString = hey.headerString;
		const rowString = hey.rowString;
		const csvString = `${headerString}${rowString}`;
		const pathToWrite = `${RNFS.DocumentDirectoryPath}/${i18n.t(
			'appName'
		)}_${i18n.t(`${vitalToExport[0]}`)}.csv`;

		RNFS.writeFile(pathToWrite, csvString, 'utf8')
			.then(() => {
				sendExportEmail(
					pathToWrite,
					`${i18n.t('appName')}_${i18n.t(`${vitalToExport[0]}`)}_Export`,
					email
				);
			})
			.catch((error) => console.error(error));
	}
};

//OTHER TRACKING EXPORT
//Formats all the firebase weight entries into usable formats for the .csv export
const formatOtherExportArray = (
	othertracking: Object,
	unitsObj: Object,
	otherToExport: string
) => {
	if (typeof othertracking === 'undefined') {
		return [];
	}

	let arr = Object.keys(othertracking)
		.map((key) => {
			if (othertracking[key].hasOwnProperty(otherToExport)) {
				return Object.values(othertracking[key][otherToExport]);
			}
		})
		.filter((item) => typeof item !== 'undefined');
	arr = flatten(arr);
	let finalArr = [];

	finalArr = arr.map((item: Object) => {
		return {
			date: moment(item.time).format('MMM-DD-YYYY hh:mm a'),
			value: returnCorrectUnitsValue(
				defaultOtherTrackingUnits[otherToExport],
				unitsObj[`${defaultOtherTrackingUnits[otherToExport]}Units`],
				item.value
			)
		};
	});

	const sortedArr = _.sortBy(finalArr.slice(), 'date').reverse();

	return sortedArr.map((item) => {
		let obj = {};
		obj.date = item.date;
		obj.measures = new Map();

		obj.measures.set(
			otherToExport,
			defaultOtherTrackingUnits[otherToExport] === 'notes'
				? item.value
				: item.value.toFixed(1)
		);

		return obj;
	});
};

const formatOtherExportLines = (finalArr, unitsObj, otherToExport) => {
	let headerString = `${i18n.t('date')},`;
	let rowString = '';

	for (var [key, value] of finalArr[0].measures) {
		headerString += `${i18n.t([
			key[0].replace(/\s+/g, '')
		])} (${returnCorrectUnitsString(
			defaultOtherTrackingUnits[key],
			unitsObj[`${defaultOtherTrackingUnits[otherToExport]}Units`]
		)}),`;
	}

	rowString = finalArr
		.map((d, index) => {
			let tempString = `${d.date},`;
			for (var [key, value] of finalArr[index].measures) {
				tempString += `${value},`;
			}
			tempString += '\n';
			return tempString;
		})
		.join('');

	headerString += '\n';
	return { headerString, rowString };
};

//On the Review -> Weight Tab header menu. Exports all the weight entries into a .CSV format.
export const exportOtherTracking = (
	otherTracking: Object,
	unitsBool: boolean,
	email: string,
	otherToExport: string
) => {
	if (Platform.OS === 'android') {
		PermissionsAndroid.requestMultiple(
			[
				PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
				PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
			],
			{
				title: i18n.t('permissionTitle'),
				message: i18n.t('permissionMessage')
			}
		)
			.then((permRes) => {
				if (
					permRes['android.permission.READ_EXTERNAL_STORAGE'] ===
						PermissionsAndroid.RESULTS.GRANTED &&
					permRes['android.permission.WRITE_EXTERNAL_STORAGE'] ===
						PermissionsAndroid.RESULTS.GRANTED
				) {
					const arr = formatOtherExportArray(
						otherTracking,
						unitsBool,
						otherToExport
					);
					const hey = formatOtherExportLines(arr, unitsBool, otherToExport);
					const headerString = hey.headerString;
					const rowString = hey.rowString;
					const csvString = `${headerString}${rowString}`;
					const pathToWrite = `${
						RNFS.ExternalStorageDirectoryPath
					}/Download/${i18n.t('appName')}_${i18n.t(`${otherToExport[0]}`)}.csv`;

					RNFS.writeFile(pathToWrite, csvString, 'utf8')
						.then(() => {
							sendExportEmail(
								pathToWrite,
								`${i18n.t('appName')}_${i18n.t(`${otherToExport[0]}`)}_Export`,
								email
							);
						})
						.catch((error) => console.error(error));
				} else {
					console.warn('Permission Denied');
				}
			})
			.catch((err) => console.warn(err));
	} else {
		const arr = formatOtherExportArray(otherTracking, unitsBool, otherToExport);
		const hey = formatOtherExportLines(arr, unitsBool, otherToExport);
		const headerString = hey.headerString;
		const rowString = hey.rowString;
		const csvString = `${headerString}${rowString}`;
		const pathToWrite = `${RNFS.DocumentDirectoryPath}/${i18n.t(
			'appName'
		)}_${i18n.t(`${otherToExport[0]}`)}.csv`;
		RNFS.writeFile(pathToWrite, csvString, 'utf8')
			.then(() => {
				sendExportEmail(
					pathToWrite,
					`${i18n.t('appName')}_${i18n.t(`${otherToExport[0]}`)}_Export`,
					email
				);
			})
			.catch((error) => console.error(error));
	}
};

/*-------Diet Log Exporter ---------*/
const formatDietLogExportArray = (trackerdate: Object) => {
	if (typeof trackerdate === 'undefined') {
		return [];
	}

	let arr = Object.keys(trackerdate)
		.map((key) => {
			const arrBreakfast = _.values(trackerdate[key].breakfast);
			const arrLunch = _.values(trackerdate[key].lunch);
			const arrDinner = _.values(trackerdate[key].dinner);
			const arrSnacks = _.values(trackerdate[key].snacks);
			const arrTotal = arrBreakfast.concat(arrLunch, arrDinner, arrSnacks);
			const macroTotals = calculateMacroTotals(arrTotal);
			return { date: key, export: macroTotals };
		})
		.filter((item) => typeof item !== 'undefined');

	const sortedArr = _.sortBy(arr.slice(), 'date').reverse();
	return sortedArr.map((item) => {
		let obj = {};
		obj.date = `${moment(item.date, 'YYYY-MM-DD')
			.format('M-DD-YYYY')
			.replace(/,/g, ' ')}`;
		obj.measures = new Map();
		for (const [key, value] of Object.entries(item.export)) {
			if (key === 'nutrientsV2') {
				for (const [k, v] of Object.entries(item.export.nutrientsV2)) {
					obj.measures.set(`${k} (${defaultNutrientUnits[k]})`, v);
				}
			} else {
				obj.measures.set(
					`${key[0].toUpperCase() + key.substring(1)} ${
						key === 'calories' ? '(kCal)' : '(g)'
					}`,
					value
				);
			}
		}

		return obj;
	});
};

const formatDietLogExportLines = (finalArr) => {
	let headerString = `${i18n.t('date')},`;
	let rowString = '';

	for (var [key, value] of finalArr[0].measures) {
		headerString += `${key} ,`;
	}

	rowString = finalArr
		.map((d, index) => {
			let tempString = `${d.date},`;
			for (var [key, value] of finalArr[index].measures) {
				tempString += `${value},`;
			}
			tempString += '\n';
			return tempString;
		})
		.join('');

	headerString += '\n';
	return { headerString, rowString };
};

export const exportDietLog = (trackerdate: Object, email: string) => {
	if (Platform.OS === 'android') {
		PermissionsAndroid.requestMultiple(
			[
				PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
				PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
			],
			{
				title: i18n.t('permissionTitle'),
				message: i18n.t('permissionMessage')
			}
		)
			.then((permRes) => {
				if (
					permRes['android.permission.READ_EXTERNAL_STORAGE'] ===
						PermissionsAndroid.RESULTS.GRANTED &&
					permRes['android.permission.WRITE_EXTERNAL_STORAGE'] ===
						PermissionsAndroid.RESULTS.GRANTED
				) {
					const arr = formatDietLogExportArray(trackerdate);
					const hey = formatDietLogExportLines(arr);
					const headerString = hey.headerString;
					const rowString = hey.rowString;
					const csvString = `${headerString}${rowString}`;
					const pathToWrite = `${
						RNFS.ExternalStorageDirectoryPath
					}/Download/${i18n.t('appName')}_${i18n.t('dietLog')}.csv`;

					RNFS.writeFile(pathToWrite, csvString, 'utf8')
						.then(() => {
							sendExportEmail(
								pathToWrite,
								`${i18n.t('appName')}_${i18n.t('dietLog')}_Export`,
								email
							);
						})
						.catch((error) => console.error(error));
				} else {
					console.warn('Permission Denied');
				}
			})
			.catch((err) => console.warn(err));
	} else {
		const arr = formatDietLogExportArray(trackerdate);
		const hey = formatDietLogExportLines(arr);
		console.log(arr, hey);
		const headerString = hey.headerString;
		const rowString = hey.rowString;
		const csvString = `${headerString}${rowString}`;
		const pathToWrite = `${RNFS.DocumentDirectoryPath}/${i18n.t(
			'appName'
		)}_${i18n.t('dietLog')}.csv`;
		RNFS.writeFile(pathToWrite, csvString, 'utf8')
			.then(() => {
				sendExportEmail(
					pathToWrite,
					`${i18n.t('appName')}_${i18n.t('dietLog')}_Export`,
					email
				);
			})
			.catch((error) => console.error(error));
	}
};
