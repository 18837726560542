//@flow
import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import {
	returnCorrectUnitsString,
	returnCorrectUnitsValue,
	returnWeightNumber,
	totalAllArrValues,
	addChartDataTotals,
	addLineDataTotals
} from 'Utilities';

const getSettingsFormats = (state) => state.firebase.profile.settings;
const getTrackerData = (state) => state.firebase.profile.trackerdate;
const getChartTimeframe = (state) => state.progress.progressTimeFrame;

const getAllMeasurements = (state) => state.firebase.profile.bodymeasurements;
const measurementNameActive = (state) =>
	state.bodymeasurements.activeReviewValue;

const getAllVitals = (state) => state.firebase.profile.vitals;
const vitalsNameActive = (state) => state.vitals.activeReviewValue;

const getAllOtherTracking = (state) => state.firebase.profile.othertracking;
const othertrackingNameActive = (state) =>
	state.othertracking.activeReviewValue;

const getMeasurementDate = (state) => state.log.date;

const getBMReviewTimeframe = (state) =>
	state.progress.bodymeasurementsTimeFrame;

/*---------------------- Progress Page Charts Tab ----------------------*/

//Gets the correct Macros Eaten Data For Chart Tab
export const getMarcroPieData = createSelector(
	[getTrackerData, getChartTimeframe],
	(allTrackingData, timeFrameArr) => {
		let trackingdata = [];
		let tempArr = [];
		if (
			typeof allTrackingData === 'undefined' ||
			typeof timeFrameArr === 'undefined'
		) {
			return trackingdata;
		}

		//Format into Arr
		const arr = Object.keys(allTrackingData).map((key) => {
			return { x: key, value: allTrackingData[key] };
		});

		if (timeFrameArr === 'all') {
			for (let i = 0; i < arr.length; i++) {
				tempArr.push(arr[i].value);
			}
		} else {
			for (let i = 0; i < arr.length; i++) {
				if (timeFrameArr.indexOf(arr[i].x) > -1) {
					tempArr.push(arr[i].value);
				}
			}
		}
		return addChartDataTotals(tempArr);
	}
);

export const getMarcroLineData = createSelector(
	[getTrackerData, getChartTimeframe],
	(allTrackingData, timeFrameArr) => {
		let trackingdata = [];
		let tempArr = [];
		if (
			typeof allTrackingData === 'undefined' ||
			typeof timeFrameArr === 'undefined'
		) {
			return { carb: [], protein: [], fat: [], cal: [] };
		}

		//Format into Arr
		const arr = Object.keys(allTrackingData).map((key) => {
			return { x: key, value: allTrackingData[key] };
		});

		if (timeFrameArr === 'all') {
			for (let i = 0; i < arr.length; i++) {
				tempArr.push(arr[i]);
			}
		} else {
			for (let i = 0; i < arr.length; i++) {
				if (timeFrameArr.indexOf(arr[i].x) > -1) {
					tempArr.push(arr[i]);
				}
			}
		}
		let pArr = [];
		let cArr = [];
		let fArr = [];
		let calArr = [];
		for (let i = 0; i < tempArr.length; i++) {
			const totalObj = addLineDataTotals(tempArr[i].value);
			pArr.push({ y: totalObj.proteins, x: tempArr[i].x });
			cArr.push({ y: totalObj.carbs, x: tempArr[i].x });
			fArr.push({ y: totalObj.fats, x: tempArr[i].x });
			calArr.push({ y: totalObj.calories, x: tempArr[i].x });
		}

		return { carb: cArr, protein: pArr, fat: fArr, cal: calArr };
	}
);

export const calculateStatBoxes = createSelector(
	[getTrackerData, getChartTimeframe],
	(allTrackingData, timeFrameArr) => {
		//new user default data handler
		if (
			typeof allTrackingData === 'undefined' ||
			typeof timeFrameArr === 'undefined'
		) {
			return {
				totalCarbs: 0,
				dayStreak: 0,
				daysOverCarbLimit: 0
			};
		}

		const totalCarbs = 0;
		const dayStreak = 0;
		const daysOverCarbLimit = 0;

		return {
			totalCarbs: totalCarbs,
			dayStreak: dayStreak,
			daysOverCarbLimit: daysOverCarbLimit
		};
	}
);

/*-------------------Body Measurements && Body Measurements Review Tab -----------------------*/

//Gets all the user logged measurements entries for a specific date on the BodyMeasurementsScreen. One date at a time.
export const getDatesMeasurements = createSelector(
	[getAllMeasurements, getMeasurementDate],
	(allMeasurements, measurementDate) => {
		if (
			typeof allMeasurements === 'undefined' ||
			typeof measurementDate === 'undefined'
		) {
			//if no data return defaults
			return defaultMeasurements;
		} else if (typeof allMeasurements[measurementDate] === 'undefined') {
			return defaultMeasurements;
		}

		const formattedDate = moment(measurementDate).format('YYYY-MM-DD');
		const cloneDefault = JSON.parse(JSON.stringify(defaultMeasurements));
		const arr2 = Object.keys(allMeasurements[formattedDate]).map((key) => ({
			[key]: allMeasurements[formattedDate][key]
		}));

		const mergedArr = cloneDefault.map((e) => {
			for (let element of arr2) {
				if (Object.keys(e)[0] == Object.keys(element)[0]) {
					Object.assign(e, element);
				}
			}
			return e;
		});
		return mergedArr;
	}
);

//Gets all of one specific logged measurements for ALL dates (ie every Weight entry or every BMI entry). Used on Review Tab
export const getActiveMeasurements = createSelector(
	[getAllMeasurements, measurementNameActive, getSettingsFormats],
	(allMeasurements, measurementName, settings) => {
		if (typeof allMeasurements === 'undefined') {
			return [];
		}

		const arr = Object.keys(allMeasurements)
			.map((key) => {
				if (allMeasurements[key].hasOwnProperty(measurementName)) {
					return {
						date: key,
						//formattedDate: moment(key).format(settings.dateFormat),
						formattedDate: moment(key).format('MMM-DD-YYYY'),
						value: allMeasurements[key][`${measurementName}`],
						formattedValue: returnCorrectUnitsValue(
							defaultMeasurementUnits[measurementName],
							settings.units.bodymeasurementsUnits,
							allMeasurements[key][`${measurementName}`]
						).toFixed(1),
						units: returnCorrectUnitsString(
							defaultMeasurementUnits[measurementName],
							settings.units.bodymeasurementsUnits
						)
					};
				}
			})
			.filter((item) => typeof item !== 'undefined');
		const sortedArr = arr.slice().sort((a: Object, b: Object) => {
			return b.date < a.date ? -1 : b.date > a.date ? 1 : 0;
		});

		return sortedArr;
	}
);

//Gets all of one specific measurements for ALL dates (ie Weight or BMI). Output formatted in graph form [x,y]
export const getBodyMeasurementChartData = createSelector(
	[
		getAllMeasurements,
		getBMReviewTimeframe,
		getSettingsFormats,
		measurementNameActive
	],
	(allMeasurements, timeFrameArr, settings, measurementName) => {
		let tempArr = [];

		if (
			typeof allMeasurements === 'undefined' ||
			typeof timeFrameArr === 'undefined'
		) {
			return [];
		}

		const arr = Object.keys(allMeasurements)
			.map((key) => {
				if (allMeasurements[key].hasOwnProperty(measurementName)) {
					return {
						x: key,
						y: returnCorrectUnitsValue(
							defaultMeasurementUnits[measurementName],
							settings.units.bodymeasurementsUnits,
							allMeasurements[key][`${measurementName}`]
						)
					};
				}
			})
			.filter((item) => typeof item !== 'undefined');

		if (timeFrameArr === 'all') {
			for (let i = 0; i < arr.length; i++) {
				tempArr.push(arr[i]);
			}
		} else {
			for (let i = 0; i < arr.length; i++) {
				if (timeFrameArr.indexOf(arr[i].x) > -1) {
					tempArr.push(arr[i]);
				}
			}
		}
		return _.sortBy(tempArr, [
			(o) => {
				return o.x;
			}
		]);
	}
);

//Default Data for BodyMeasurements
const defaultMeasurements = [
	{ Weight: 0 },
	{ 'Body Fat': 0 },
	{ BMI: 0 },
	{ 'Lean Body Mass': 0 },
	{ 'Waist Size': 0 },
	{ 'Hip Size': 0 },
	{ 'Chest Size': 0 },
	{ 'Neck Size': 0 },
	{ 'Arm Size': 0 },
	{ 'Forearm Size': 0 },
	{ 'Calf Size': 0 },
	{ 'Thigh Size': 0 }
];

//Default Unit Type data for Body Measurements ==> Used along with bodymeasurementsUnits to determine which units the measurement should use. (Example: Chest Size = Length, bodymeasurementsUnits ? "cm" : "in")
export const defaultMeasurementUnits = {
	Weight: 'weight',
	'Body Fat': 'percent',
	BMI: 'none',
	'Lean Body Mass': 'percent',
	'Waist Size': 'length',
	'Hip Size': 'length',
	'Chest Size': 'length',
	'Neck Size': 'length',
	'Arm Size': 'length',
	'Forearm Size': 'length',
	'Calf Size': 'length',
	'Thigh Size': 'length'
};

/*-------------------Vitals && Vitals Review Tab -----------------------*/

//Gets all the available measurements for a specific date on the Vitals Screen. One date at a time.

export const getDatesVitals = createSelector(
	[getAllVitals, getMeasurementDate],
	(allVitals, measurementDate) => {
		if (
			typeof allVitals === 'undefined' ||
			typeof measurementDate === 'undefined'
		) {
			return defaultVitals;
		} else if (typeof allVitals[measurementDate] === 'undefined') {
			return defaultVitals;
		}

		const formattedDate = moment(measurementDate).format('YYYY-MM-DD');
		const cloneDefault = JSON.parse(JSON.stringify(defaultVitals));
		const arr2 = Object.keys(allVitals[formattedDate]).map((key) => ({
			[key]: allVitals[formattedDate][key]
		}));

		const mergedArr = cloneDefault.map((e) => {
			for (let element of arr2) {
				if (Object.keys(e)[0] == Object.keys(element)[0]) {
					Object.assign(e, element);
				}
			}
			return e;
		});

		return mergedArr;
	}
);

//Gets all of one specific vital for ALL dates (ie Blood Glucose or Ketones). Used on Vitals Review Tab
export const getActiveVital = createSelector(
	[getAllVitals, vitalsNameActive, getSettingsFormats],
	(allVitals, vitalName, settings) => {
		if (typeof allVitals === 'undefined') {
			return [];
		}

		let arr = Object.keys(allVitals)
			.map((key) => {
				if (allVitals[key].hasOwnProperty(vitalName)) {
					return Object.values(allVitals[key][vitalName]);
				}
			})
			.filter((item) => typeof item !== 'undefined');
		arr = flatten(arr);
		let finalArr = [];
		if (vitalName === 'Blood Pressure') {
			finalArr = arr.map((item: Object) => {
				return {
					time: item.time,
					/*formattedDate: moment(item.time).format(
						`${settings.isClock24 ? 'HH:mm' : 'hh:mm a'}, ${
							settings.dateFormat
						}`
					),*/
					formattedDate: moment(item.time).format('MMM-DD-YYYY hh:mm a'),
					value: item.value,
					valueTwo: item.valueTwo,
					formattedValue: returnCorrectUnitsValue(
						defaultVitalsUnits[vitalName],
						settings.units[`${defaultVitalsUnits[vitalName]}Units`],
						item.value
					).toFixed(1),
					formattedValueTwo: returnCorrectUnitsValue(
						defaultVitalsUnits[vitalName],
						settings.units[`${defaultVitalsUnits[vitalName]}Units`],
						item.valueTwo
					).toFixed(1),
					units: returnCorrectUnitsString(
						defaultVitalsUnits[vitalName],
						settings.units[`${defaultVitalsUnits[vitalName]}Units`]
					),
					id: item.id,
					type: 'diastolic'
				};
			});
		} else {
			finalArr = arr.map((item: Object) => {
				return {
					time: item.time,
					/*formattedDate: moment(item.time).format(
						`${settings.isClock24 ? 'HH:mm' : 'hh:mm a'}, ${
							settings.dateFormat
						}`
					),*/
					formattedDate: moment(item.time).format('MMM-DD-YYYY hh:mm a'),
					value: item.value,
					formattedValue: returnCorrectUnitsValue(
						defaultVitalsUnits[vitalName],
						settings.units[`${defaultVitalsUnits[vitalName]}Units`],
						item.value
					).toFixed(1),
					units: returnCorrectUnitsString(
						defaultVitalsUnits[vitalName],
						settings.units[`${defaultVitalsUnits[vitalName]}Units`]
					),
					id: item.id
				};
			});
		}
		const sortedArr = finalArr.slice().sort((a: Object, b: Object) => {
			return b.time < a.time ? -1 : b.time > a.time ? 1 : 0;
		});

		return sortedArr;
	}
);

//Gets all of one specific measurements for ALL dates (ie Weight or BMI). Output formatted in graph form [x,y] for Vitals Review Tab
export const getVitalChartData = createSelector(
	[getAllVitals, getBMReviewTimeframe, getSettingsFormats, vitalsNameActive],
	(allVitals, timeFrameArr, settings, vitalName) => {
		let tempArr = [];
		let finalArr;
		if (
			typeof allVitals === 'undefined' ||
			typeof timeFrameArr === 'undefined'
		) {
			return [];
		}

		let arr = Object.keys(allVitals)
			.map((key) => {
				if (allVitals[key].hasOwnProperty(vitalName)) {
					return Object.values(allVitals[key][vitalName]);
				}
			})
			.filter((item) => typeof item !== 'undefined');

		if (
			vitalName === 'Blood Glucose' ||
			vitalName === 'Heart Rate' ||
			vitalName === 'Ketones'
		) {
			arr = flatten(arr);
			finalArr = arr.map((item: Object) => {
				return {
					x: moment(item.time).format('YYYY-MM-DD'),
					y: returnCorrectUnitsValue(
						defaultVitalsUnits[vitalName],
						settings.units[`${defaultVitalsUnits[vitalName]}Units`],
						item.value
					)
				};
			});
		} else if (vitalName === 'Blood Pressure') {
			arr = flatten(arr);
			let finalArrOne = [];
			let finalArrTwo = [];
			finalArrOne = arr.map((item: Object) => {
				return {
					x: moment(item.time).format('YYYY-MM-DD'),
					y: returnCorrectUnitsValue(
						defaultVitalsUnits[vitalName],
						settings.units[`${defaultVitalsUnits[vitalName]}Units`],
						item.value
					),
					type: 'systolic'
				};
			});

			finalArrTwo = arr.map((item: Object) => {
				return {
					x: moment(item.time).format('YYYY-MM-DD'),
					y: returnCorrectUnitsValue(
						defaultVitalsUnits[vitalName],
						settings.units[`${defaultVitalsUnits[vitalName]}Units`],
						item.valueTwo
					),
					type: 'diastolic '
				};
			});
			finalArr = finalArrOne.concat(finalArrTwo);
		} else {
			finalArr = arr.map((item: Object) => {
				return {
					x: moment(item[0].time).format('YYYY-MM-DD'),
					y: totalAllArrValues(item)
				};
			});
		}

		if (timeFrameArr === 'all') {
			for (let i = 0; i < finalArr.length; i++) {
				tempArr.push(finalArr[i]);
			}
		} else {
			for (let i = 0; i < finalArr.length; i++) {
				if (
					timeFrameArr.indexOf(moment(finalArr[i].x).format('YYYY-MM-DD')) > -1
				) {
					tempArr.push(finalArr[i]);
				}
			}
		}
		return _.sortBy(tempArr, [
			(o) => {
				return o.x;
			}
		]);
	}
);

const flatten = (arr) => {
	return arr.reduce((flat, toFlatten) => {
		return flat.concat(
			Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
		);
	}, []);
};

export const defaultVitals = [
	{ 'Blood Glucose': [] },
	{ 'Heart Rate': [] },
	{ 'Blood Pressure': [] }
];

export const defaultVitalsUnits = {
	'Blood Glucose': 'glucose',
	'Heart Rate': 'bpm',
	'Blood Pressure': 'mmHg'
};

/*-------------Other Tracking ----------*/
export const getDatesOtherTracking = createSelector(
	[getAllOtherTracking, getMeasurementDate],
	(allOtherTracking, measurementDate) => {
		if (
			typeof allOtherTracking === 'undefined' ||
			typeof measurementDate === 'undefined'
		) {
			return defaultOtherTracking;
		} else if (typeof allOtherTracking[measurementDate] === 'undefined') {
			return defaultOtherTracking;
		}

		const formattedDate = moment(measurementDate).format('YYYY-MM-DD');
		const cloneDefault = JSON.parse(JSON.stringify(defaultOtherTracking));
		const arr2 = Object.keys(allOtherTracking[formattedDate]).map((key) => ({
			[key]: allOtherTracking[formattedDate][key]
		}));

		const mergedArr = cloneDefault.map((e) => {
			for (let element of arr2) {
				if (Object.keys(e)[0] == Object.keys(element)[0]) {
					Object.assign(e, element);
				}
			}
			return e;
		});

		return mergedArr;
	}
);

//Gets all of one specific vital for ALL dates (ie Blood Glucose or Ketones). Used on Vitals Review Tab
export const getActiveOther = createSelector(
	[getAllOtherTracking, othertrackingNameActive, getSettingsFormats],
	(allOther, otherName, settings) => {
		if (typeof allOther === 'undefined') {
			return [];
		}

		let arr = Object.keys(allOther)
			.map((key) => {
				if (allOther[key].hasOwnProperty(otherName)) {
					return Object.values(allOther[key][otherName]);
				}
			})
			.filter((item) => typeof item !== 'undefined');
		arr = flatten(arr);
		let finalArr = [];
		if (otherName === 'Notes') {
			finalArr = arr.map((item: Object) => {
				return {
					time: item.time,
					formattedDate: moment(item.time).format('MMM-DD-YYYY hh:mm a'),
					value: item.value,
					formattedValue: item.value,
					units: '',
					id: item.id
				};
			});
		} else {
			finalArr = arr.map((item: Object) => {
				return {
					time: item.time,
					formattedDate: moment(item.time).format('MMM-DD-YYYY hh:mm a'),
					value: item.value,
					formattedValue: returnCorrectUnitsValue(
						defaultOtherTrackingUnits[otherName],
						settings.units[`${defaultOtherTrackingUnits[otherName]}Units`],
						item.value
					).toFixed(1),
					units: returnCorrectUnitsString(
						defaultOtherTrackingUnits[otherName],
						settings.units[`${defaultOtherTrackingUnits[otherName]}Units`]
					),
					id: item.id
				};
			});
		}
		const sortedArr = finalArr.slice().sort((a: Object, b: Object) => {
			return b.time < a.time ? -1 : b.time > a.time ? 1 : 0;
		});

		return sortedArr;
	}
);

//Gets all of one specific measurements for ALL dates (ie Weight or BMI). Output formatted in graph form [x,y] for Vitals Review Tab
export const getOtherChartData = createSelector(
	[
		getAllOtherTracking,
		getBMReviewTimeframe,
		getSettingsFormats,
		othertrackingNameActive
	],
	(allOther, timeFrameArr, settings, otherName) => {
		let tempArr = [];
		let finalArr;
		if (
			typeof allOther === 'undefined' ||
			typeof timeFrameArr === 'undefined'
		) {
			return [];
		}

		let arr = Object.keys(allOther)
			.map((key) => {
				if (allOther[key].hasOwnProperty(otherName)) {
					return Object.values(allOther[key][otherName]);
				}
			})
			.filter((item) => typeof item !== 'undefined');

		if (otherName === 'Notes' || otherName === 'Mood') {
			finalArr = arr.map((item: Object) => {
				return {
					x: '',
					y: ''
				};
			});
		} else {
			finalArr = arr.map((item: Object) => {
				return {
					x: moment(item[0].time).format('YYYY-MM-DD'),
					y: returnCorrectUnitsValue(
						defaultOtherTrackingUnits[otherName],
						settings.units[`${defaultOtherTrackingUnits[otherName]}Units`],
						totalAllArrValues(item)
					)
				};
			});
		}

		if (timeFrameArr === 'all') {
			for (let i = 0; i < finalArr.length; i++) {
				tempArr.push(finalArr[i]);
			}
		} else {
			for (let i = 0; i < finalArr.length; i++) {
				if (
					timeFrameArr.indexOf(moment(finalArr[i].x).format('YYYY-MM-DD')) > -1
				) {
					tempArr.push(finalArr[i]);
				}
			}
		}
		return _.sortBy(tempArr, [
			(o) => {
				return o.x;
			}
		]);
	}
);

export const defaultOtherTracking = [
	{ Notes: [] },
	{ Water: [] },
	{ Exercise: [] }
	//{ Mood: [] }
];

export const defaultOtherTrackingUnits = {
	Notes: 'notes',
	Water: 'water',
	Exercise: 'exercise'
	//Mood: 'none'
};

/* -------- Nutrients ------- */
export const defaultNutrientUnits = {
	Cholesterol: 'mg',
	Sodium: 'mg',
	Calcium: 'mg',
	Magnesium: 'mg',
	Potassium: 'mg',
	Iron: 'mg',
	Zinc: 'mg',
	Phosphorus: 'mg',
	'Vitamin A': '\u03BCg',
	'Vitamin C': 'mg',
	'Thiamin (B1)': 'mg',
	'Riboflavin (B2)': 'mg',
	'Niacin (B3)': 'mg',
	'Vitamin B6': 'mg',
	Folate: '\u03BCg',
	'Vitamin B12': '\u03BCg',
	'Vitamin D': '\u03BCg',
	'Vitamin E': 'mg',
	'Vitamin K': '\u03BCg'
};

export const defaultNutrients = {
	Cholesterol: 0,
	Sodium: 0,
	Calcium: 0,
	Magnesium: 0,
	Potassium: 0,
	Iron: 0,
	Zinc: 0,
	Phosphorus: 0,
	'Vitamin A': 0,
	'Vitamin C': 0,
	'Thiamin (B1)': 0,
	'Riboflavin (B2)': 0,
	'Niacin (B3)': 0,
	'Vitamin B6': 0,
	Folate: 0,
	'Vitamin B12': 0,
	'Vitamin D': 0,
	'Vitamin E': 0,
	'Vitamin K': 0
};

export const defaultNutrientDaily = {
	Cholesterol: 300,
	Sodium: 2300,
	Calcium: 1300,
	Magnesium: 400,
	Potassium: 4700,
	Iron: 18,
	Zinc: 8,
	Phosphorus: 1250,
	'Vitamin A': 800,
	'Vitamin C': 80,
	'Thiamin (B1)': 1.1,
	'Riboflavin (B2)': 1.2,
	'Niacin (B3)': 15,
	'Vitamin B6': 1.7,
	Folate: 400,
	'Vitamin B12': 2.4,
	'Vitamin D': 20,
	'Vitamin E': 15,
	'Vitamin K': 90
};

