//@flow
import React, { useState, useEffect } from 'react';
import {
	View,
	Image,
	Platform,
	ScrollView,
	Alert,
	StyleSheet
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ListHeaderSorting, Text } from 'Components/common';
import { BarcodeScreenRecentFlatList } from './components/barcode/BarcodeScreenRecentFlatList';
import {
	fetchFatSecretScan,
	clearAllRecentBarcodes,
	quickRemoveRecentScanned
} from 'Components/fatsecret/actions';

import _ from 'lodash';
import { Images, Colors } from 'Theme';

export const BarcodeScreen = (props) => {
	const [hasPermission, setHasPermission] = useState(null);
	const [scanned, setScanned] = useState(false);
	const [isOpen, toggleCamera] = useState(false);
	const { theme } = useSelector((state) => state.startup);
	//const auth = useSelector((state) => state.firebase.auth);
	//const recentbarcodes = useSelector((state) => state.firebase.profile.recentbarcodes);

	const dispatch = useDispatch();

	/*constructor(props) {
		super(props);
		this.state = {
			camera: {
				onBarCodeRead: this.scannedBarcode
			},
			cameraOn: false,
			stateParams: {},
			route: '',
			azSort: false,
			dateSort: false,
			sortedData: _.values(this.props.recentbarcodes)
				.slice()
				.reverse()
		};
	}

	componentWillMount() {
		this.setState({ onBarCodeRead: this.scannedBarcode });
		this.calculateParams();
	}

	componentWillUnmount() {
		this.setState({ cameraOn: false });
		this.setState({ onBarCodeRead: null });
	}

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.recentbarcodes !== prevProps.recentbarcodes) {
			this.setState({
				sortedData: _.values(this.props.recentbarcodes)
					.slice()
					.reverse()
			});
		}
	}

	calculateParams = () => {
		const { params, name } = this.props.route;
		let stateParams = {};
		let route = '';
		if (name === 'BarcodeTab') {
			// Add Food Stack -> Barcode Tab -> Single Search
			stateParams = { ...params, tracker: true };
			route = 'SearchTabSingle';
		} else if (name === 'MealEditingBarcode') {
			// Add Food Stack -> Meal Tab -> Editing Meal -> Barcode
			route = 'MealEditingSingleSearch';
			stateParams = { tracker: false, editingMeal: true };
		} else if (name === 'ManageMealEditingBarcode') {
			//Manage -> Meal Tab -> Editing Meal -> Barcode
			route = 'ManageMealEditingSingleSearch';
			stateParams = { tracker: false, editingMeal: true };
		} else {
			//Net Carb Look-Up Barcode
			route = 'NetCarbSearchSingle';
			stateParams = { tracker: false };
		}

		this.setState({ route: route, stateParams: stateParams });
	};

	//Render Each Recent Barcode Item
	renderItem = ({ item }) => {
		return (
			<BarcodeScreenRecentFlatList
				food={item}
				nav={this.props.navigation}
				route={this.state.route}
				stateParams={this.state.stateParams}
				quickRemoveRecentScanned={this.props.quickRemoveRecentScanned}
			/>
		);
	};

	clearAllRecentBarcodes = () => {
		AlertRN.Alert.alert(
			'Clear All Recently Scanned Items?',
			'This will clear your recently scanned barcodes on all synced devices',
			[
				{
					text: 'Clear',
					onPress: () => {
						this.props.clearAllRecentBarcodes();
						this.setState({
							azSort: false,
							dateSort: false,
							sortedData: []
						});
					}
				},
				{
					text: 'Cancel',
					onPress: () => {
						return;
					}
				}
			]
		);
	};

	sortBarcodesAlpha = () => {
		//Sort Array Alphabetically By Name
		const sortedArr = this.state.sortedData.slice().sort((a, b) => {
			var textA = this.state.azSort
				? a.food_name.toUpperCase()
				: b.food_name.toUpperCase();
			var textB = this.state.azSort
				? b.food_name.toUpperCase()
				: a.food_name.toUpperCase();
			return textA < textB ? -1 : textA > textB ? 1 : 0;
		});

		this.setState({ sortedData: sortedArr, azSort: !this.state.azSort });
	};

	sortBarcodesDateAdded = () => {
		const sorted = this.state.dateSort
			? _.values(this.props.recentbarcodes)
					.slice()
					.reverse()
			: _.values(this.props.recentbarcodes).slice();

		this.setState({
			sortedData: sorted,
			dateSort: !this.state.dateSort
		});
	};

*/
	const openCamera = () => {
		toggleCamera(true);
	};

	const scannedBarcode = (barcodeData) => {
		toggleCamera(false);
		const params = {
			...props.route.params,
			route: props.route.name
		};

		let UPC = '';
		if (barcodeData.data !== '') {
			if (Platform.OS === 'ios') {
				if (barcodeData.type === 'org.gs1.UPC_A') {
					UPC = '0' + barcodeData.data;
				} else if (barcodeData.type === 'org.gs1.UPC-E') {
					UPC = '0' + barcodeData.data;
				} else if (barcodeData.type === 'org.gs1.EAN-8') {
					UPC = '00000' + barcodeData.data;
				} else if (barcodeData.type === 'org.gs1.EAN-13') {
					UPC = barcodeData.data;
				}
			} else if (barcodeData.type === 'UPC_A') {
				UPC = '0' + barcodeData.data;
			} else if (barcodeData.type === 'UPC_E') {
				UPC = '0' + barcodeData.data;
			} else if (barcodeData.type === 'EAN_8') {
				UPC = '00000' + barcodeData.data;
			} else if (barcodeData.type === 'EAN_13') {
				UPC = barcodeData.data;
			}
			dispatch(fetchFatSecretScan(UPC, params));
		} else {
			Alert.alert(
				'Barcode Not Recognized',
				'Try searching the database or adding it as a custom food.'
			);
		}
	};

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<ScrollView>
				<View style={styles.barcodeContainer}>
					<Button
						style={[styles.scanBtn, { backgroundColor: theme.themeAccent }]}
						onPress={() => openCamera()}>
						Scan Barcode
					</Button>
					<Image
						resizeMode="contain"
						style={styles.fatImage}
						source={Images.fatsecret}
					/>
				</View>
				{/*!this.props.auth.isEmpty && (
					<ListHeaderSorting
						leftPress={() => this.sortBarcodesDateAdded()}
						leftPressTwo={() => this.sortBarcodesAlpha()}
						leftIconTwo={this.state.azSort ? "sort-alpha-desc" : "sort-alpha-asc"}
						rightText="Clear"
						rightIconName="trash"
						rightPress={this.clearAllRecentBarcodes}
					/>
				)}
				{!this.props.auth.isEmpty && (
					<FlatList
						data={this.state.sortedData}
						keyExtractor={(item) => item.food_id}
						renderItem={this.renderItem}
						style={styles.recentFlatList}
					/>
				)*/}
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	fatImage: {
		width: 120,
		height: 17,
		alignSelf: 'center',
		marginVertical: 0
	},
	barcodeContainer: {
		marginBottom: 10
	},
	scanBtn: {
		marginBottom: 20
	},
	recentFlatList: {}
});
