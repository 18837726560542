// @flow
import {
	CALCULATE_BMR,
	CALCULATE_BASE_CALORIES,
	CALCULATE_PROTEIN_RANGE,
	CALCULATE_RESULTS,
	ON_CHANGE_DEFICIT,
	UPDATE_CALC_VALUE
} from '../actions/types';

const INITIAL_STATE = {
	//age: 35,
	//gender: 'female',
	//height: ['170', 'ft'], //textinput
	//weight: '170', //textinput
	bodyFat: '25', //textinput
	//exercise: 1.55,
	//goalWeight: '160', //textinput
	bmr: 1500,
	baseCalories: 2000,
	calories: '1500',
	carb: 50, //slider number
	fat: '100',
	protein: '50', //textinput
	deficit: -10,
	baseProteins: { gain: 0, maintain: 0 },
	macroRatio: [0.3, 0.2, 0.5],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UPDATE_CALC_VALUE:
			return { ...state, [action.key]: action.value };
		case CALCULATE_BMR:
			return { ...state, bmr: action.BMR };
		case CALCULATE_BASE_CALORIES:
			return { ...state, baseCalories: action.baseCalories };
		case ON_CHANGE_DEFICIT:
			return { ...state, deficit: action.deficit, calories: action.calories };
		case CALCULATE_PROTEIN_RANGE:
			return { ...state, baseProteins: { ...action.proteins } };
		case CALCULATE_RESULTS:
			return {
				...state,
				calories: action.calories,
				carb: action.carb,
				fat: action.fat,
				protein: action.protein,
				macroRatio: action.macroRatio
			};
		default:
			return state;
	}
};
