import { StyleSheet } from 'react-native';
import { Colors } from 'Theme';

export default StyleSheet.create({
	foodListStyle: {
		flex: 1
	},
	multiListStyle: {
		borderTopWidth: 1,
		flex: 1
	},
	rowContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottomWidth: 1,
		paddingLeft: 15
	},
	itemTitle: {
		fontSize: 14,
		lineHeight: 16,
		marginTop: 17,
		fontFamily: 'Raleway'
	},
	itemDescription: {
		marginLeft: 0,
		fontSize: 10,
		lineHeight: 14,
		marginBottom: 19,
		marginTop: 5
	},
	textContainer: {
		flex: 1
	},
	textHolder: {
		flex: 4,
		flexDirection: 'row',
		paddingRight: 5
	},
	quickAddHolder: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignSelf: 'stretch',
		paddingLeft: 3
	},
	quickAdd: {
		alignSelf: 'center'
	},
	//Favorites
	favDescription: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginTop: -5,
		marginBottom: 10
	},
	typeIcon: {
		alignSelf: 'center',
		width: 20,
		paddingLeft: 1
	},
	typeDescription: {
		fontSize: 10,
		fontFamily: 'Raleway'
	}
});
