//@flow
import React from 'react';
import { View, Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { SearchBar } from 'Components/common';
import { FsSearchResultsContainer } from './components/search';
import { fetchFatSecretSearch, clearFatSecretSearch } from './actions';

export const FsSearchScreen = ({ route }) => {
	const dispatch = useDispatch();
	const { theme } = useSelector((state) => state.startup);
	//Submit function for Search Bar, Gets Results and Clears Last Search
	const onSubmit = (text) => {
		Keyboard.dismiss();
		dispatch(clearFatSecretSearch());
		dispatch(fetchFatSecretSearch(text));
	};

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<SearchBar
				onSubmit={(text) => onSubmit(text)}
				placeholder={'"Avocado"'}
				theme={theme}
			/>
			<FsSearchResultsContainer route={route.name} theme={theme} />
		</View>
	);
};
