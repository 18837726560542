//@flow
import _ from 'lodash';
import { calculateMacroTotals } from 'Utilities/MacrosUtil';
import { i18n } from 'Theme';
import { defaultNutrients } from 'Redux/selectors';

//Adds up ALL the date's Meals (breakfast, lunch, dinner, snacks). Used for Log View Tabs (Macro Grid, Bar, Pie)
export const addUpMealMacros = (
	breakfast: Object = {},
	lunch: Object = {},
	dinner: Object = {},
	snacks: Object = {},
	goals: Object = {},
	exercise: number = 0
) => {
	let objectHolder = {
		macrosEaten: {
			fats: 0,
			carbs: 0,
			totalcarbs: 0,
			fiber: 0,
			proteins: 0,
			calories: 0,
			nutrientsV2: { ...defaultNutrients }
		},
		macrosRemaining: {
			fats: 0,
			carbs: 0,
			totalcarbs: 0,
			fiber: 0,
			proteins: 0,
			calories: 0
		},
		breakfast: {},
		lunch: {},
		dinner: {},
		snacks: {}
	};

	//Convert all Meals Objects of Log-Entry Objects to Arrays of Log-Entry Objects
	const arrBreakfast = _.values(breakfast);
	const arrLunch = _.values(lunch);
	const arrDinner = _.values(dinner);
	const arrSnacks = _.values(snacks);
	//Combine them all into one big array
	const arrTotal = arrBreakfast.concat(arrLunch, arrDinner, arrSnacks);
	if (arrTotal) {
		const macroTotals = calculateMacroTotals(arrTotal);
		objectHolder.macrosEaten.fats = macroTotals.fats;
		objectHolder.macrosEaten.totalcarbs = macroTotals.totalcarbs; //totalcarbs
		objectHolder.macrosEaten.fiber = macroTotals.fiber;
		objectHolder.macrosEaten.carbs = macroTotals.carbs; //netcarb
		objectHolder.macrosEaten.proteins = macroTotals.proteins;
		objectHolder.macrosEaten.calories = macroTotals.calories;
		objectHolder.macrosEaten.nutrientsV2 = macroTotals.nutrientsV2;
	}

	objectHolder.macrosRemaining.fats =
		goals.fatgrams - objectHolder.macrosEaten.fats;
	objectHolder.macrosRemaining.carbs =
		goals.carbgrams - objectHolder.macrosEaten.carbs;
	objectHolder.macrosRemaining.totalcarbs =
		goals.carbgrams - objectHolder.macrosEaten.totalcarbs;
	objectHolder.macrosRemaining.proteins =
		goals.proteingrams - objectHolder.macrosEaten.proteins;
	objectHolder.macrosRemaining.calories =
		goals.cal - objectHolder.macrosEaten.calories + exercise;
	objectHolder.breakfast = calculateMacroTotals(arrBreakfast);
	objectHolder.lunch = calculateMacroTotals(arrLunch);
	objectHolder.dinner = calculateMacroTotals(arrDinner);
	objectHolder.snacks = calculateMacroTotals(arrSnacks);
	return objectHolder;
};

//Returns the remaining % of two numbers used / total
export const returnPercentage = (used: number, total: number) => {
	var percentage = (used / total) * 100;
	return Math.round(percentage);
};

//Returns the remaining % of two numbers used / total
export const customDateMacroGoals = (dailygoals) => {
	if (typeof dailygoals.fatgrams !== 'undefined') {
		return {
			cal: +dailygoals.cal,
			fatgrams: +dailygoals.fatgrams,
			carbgrams: +dailygoals.carbgrams,
			proteingrams: +dailygoals.proteingrams
		};
	}

	const fatgrams = Number(dailygoals.fat * dailygoals.cal) / 100 / 9;
	const carbgrams = Number(dailygoals.carb * dailygoals.cal) / 100 / 4;
	const proteingrams = Number(dailygoals.protein * dailygoals.cal) / 100 / 4;
	return { cal: dailygoals.cal, fatgrams, carbgrams, proteingrams };
};

export const convertMealNameString = (localString: string) => {
	switch (localString) {
		case i18n.t('breakfast'):
			return 'breakfast';
		case i18n.t('lunch'):
			return 'lunch';
		case i18n.t('dinner'):
			return 'dinner';
		case i18n.t('snacks'):
			return 'snacks';
		default:
			return '';
	}
};
