//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Gram } from 'Components/common';
import { Colors, i18n } from 'Theme';
import { useSelector } from 'react-redux';

type Props = {
	mealMacros: Object,
	theme: Object
};

export const HeaderMacros = (props: Props) => {
	const { mealMacros, theme } = props;
	const { carbUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);

	return (
		<View
			style={[
				styles.mealBreakdownContainer,
				{ backgroundColor: theme.grey, borderBottomColor: theme.border }
			]}>
			<View style={styles.mealBreakdown}>
				<Text style={styles.mealMacroLabel}>
					{mealMacros.fats.toFixed(0)}
					<Gram />
				</Text>
				<Text style={styles.mealMacro}>{i18n.t('fat')}</Text>
			</View>
			{carbUnits === 'total' && (
				<View style={styles.mealBreakdown}>
					<Text style={styles.mealMacroLabel}>
						{mealMacros.totalcarbs.toFixed(0)}
						<Gram />
					</Text>
					<Text style={styles.mealMacro}>{i18n.t('totalcarb')}</Text>
				</View>
			)}
			{carbUnits === 'net' && (
				<View style={styles.mealBreakdown}>
					<Text style={styles.mealMacroLabel}>
						{mealMacros.carbs.toFixed(0)}
						<Gram />
					</Text>
					<Text style={styles.mealMacro}>{i18n.t('netcarb')}</Text>
				</View>
			)}
			<View style={styles.mealBreakdown}>
				<Text style={styles.mealMacroLabel}>
					{mealMacros.proteins.toFixed(0)}
					<Gram />
				</Text>
				<Text style={styles.mealMacro}>{i18n.t('protein')}</Text>
			</View>
			<View style={styles.mealBreakdown}>
				<Text style={styles.mealMacroLabel}>
					{mealMacros.calories.toFixed(0)}
				</Text>
				<Text style={styles.mealMacro}>kCal</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	mealBreakdownContainer: {
		flex: 1,
		flexDirection: 'row',
		borderBottomWidth: 1,
		paddingVertical: 5
	},
	mealMacroLabel: {
		fontSize: 12,
		paddingLeft: 0,
		lineHeight: 12,
		paddingRight: 0,
		paddingTop: 8
	},
	mealMacro: {
		fontSize: 9,
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: -5
	},
	mealBreakdown: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
});
