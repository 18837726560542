//@flow
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'Components/common';
import {
	VictoryChart,
	VictoryLabel,
	VictoryAxis,
	VictoryArea,
	VictoryTheme,
	VictoryLine
} from 'victory-native';
import { i18n } from 'Theme';
import moment from 'moment';
import { Defs, LinearGradient, Stop } from 'react-native-svg';
type Props = {
	width: number,
	data: Object,
	progressFilter: string,
	goalWeight?: number,
	yLabel?: string,
	xLabel?: string,
	theme: Object
};

export const ProgressLine = (props: Props) => {
	const { width, data, yLabel, xLabel, goalWeight, theme } = props;

	return (
		<View style={styles.chartContainer}>
			{data.length < 2 && (
				<View style={styles.chartErrorContainer}>
					<Text style={styles.weightErrorText}>{i18n.t('addTwoEntries')}</Text>
				</View>
			)}
			{data.length > 1 && (
				<VictoryChart
					theme={VictoryTheme.material}
					padding={{ top: 55, bottom: 65, left: 70, right: 50 }}
					domainPadding={{ x: 0, y: 10 }}
					width={width}
					disableContainerEvents={false}
					animate={{
						duration: 250,
						onLoad: { duration: 0 }
					}}>
					<VictoryAxis
						dependentAxis
						label={yLabel}
						axisLabelComponent={<VictoryLabel dy={-140} dx={5} angle={0} />}
						tickFormat={(y) => y.toFixed(1)}
						style={{
							grid: { stroke: () => theme.border },
							tickLabels: {
								fontSize: 12,
								fontFamily: 'Raleway',
								fill: theme.darkFont
							},
							axisLabel: {
								padding: 40,
								fontSize: 14,
								fontFamily: 'Raleway',
								fill: theme.darkFont
							},
							axis: { stroke: theme.placeholderGray }
						}}
					/>
					<VictoryAxis
						label={xLabel}
						fixLabelOverlap={true}
						tickFormat={(x) => moment(x).format('MM/DD')}
						style={{
							grid: { stroke: () => theme.border },
							tickLabels: {
								fontSize: 8,
								fill: theme.darkFont,
								fontFamily: 'Raleway'
							},
							axisLabel: {
								padding: 40,
								fontSize: 14,
								fontFamily: 'Raleway',
								fill: theme.darkFont
							},
							axis: { stroke: theme.placeholderGray }
						}}
					/>
					<Defs>
						<LinearGradient
							id="gradientStroke"
							x1="0%"
							x2="0%"
							y1="0%"
							y2="100%">
							<Stop offset="0%" stopColor="#b4840d" stopOpacity="1" />
							<Stop offset="90%" stopColor="#b4840d" stopOpacity="0.2" />
						</LinearGradient>
					</Defs>
					<VictoryArea
						domain={{
							y: [
								Math.min.apply(
									Math,
									data.map((o) => {
										return o.y;
									})
								),
								Math.max.apply(
									Math,
									data.map((o) => {
										return o.y;
									})
								)
							]
						}}
						data={data}
						height={300}
						padding={75}
						style={{
							data: {
								fill: 'url(#gradientStroke)',
								stroke: theme.themeAccent,
								strokeWidth: 2
							}
						}}
					/>
				</VictoryChart>
			)}
		</View>
	);
};
const styles = StyleSheet.create({
	chartContainer: {
		borderTopWidth: 0,
		justifyContent: 'center',
		alignItems: 'center'
	},
	weightErrorText: {
		fontSize: 14
	},
	chartErrorContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		marginVertical: 50
	},

	//Weight Line
	weightNetContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 5
	},
	weightNetText: {
		fontSize: 10
	}
});

/*
						{goalWeight && (
						<VictoryLine
							style={{
								data: { stroke: Colors.blue, strokeWidth: 2 },
								labels: { fontSize: 9, fontFamily: 'Raleway' }
							}}
							data={[
								{ x: 0, y: goalWeight },
								{ x: data.length, y: goalWeight }
							]}
						/>
					)}*/
