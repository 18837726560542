//@flow
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from 'Theme';

export const Divider = () => {
	return <View style={styles.row} />;
};

const styles = StyleSheet.create({
	row: {
		width: 40,
		height: 1,
		borderWidth: 1,
		borderColor: Colors.themeAccent,
		alignSelf: 'center',
		marginVertical: 20
	}
});
