import { REQUEST_FATSECRET_SEARCH, RECEIVE_FATSECRET_SEARCH, CLEAR_FATSECERT_SEARCH, REQUEST_LOAD_MORE_FATSECERT_SEARCH, RECEIVE_LOAD_MORE_FATSECERT_SEARCH } from "./types";
import jsSHA from "jssha";
import { i18n } from "Theme";
import { Platform } from "react-native";
const REQUEST_URL = "http://platform.fatsecret.com/rest/server.api";
const REQUEST_METHOD = "GET";
const OAUTH_SIGNATURE_METHOD = "HMAC-SHA1";
const OAUTH_VERSION = "1.0";
const FORMAT = "json";
const OAUTH_CONSUMER_KEY = "0c349309114f4ee784900151e0f4039b";
const SHARED_SECRET = "785e42225de34903943895bee51b4cc5";

export const requestFatSecretSearch = (searchedText) => {
	return {
		type: REQUEST_FATSECRET_SEARCH,
		payload: searchedText,
	};
};

export const receiveFatSecretSearch = (response) => {
	if (typeof response !== "undefined" && typeof response.foods.food !== "undefined") {
		if (response.foods.food.length > 1) {
			response.foods.food.map((item) => {
				item.food_description = item.food_description
					.replace(/[-] Calories/, "")
					.replace(/Fat/, i18n.t("fat"))
					.replace(/Protein/, i18n.t("protein"))
					.replace(/Carbs/, i18n.t("totalcarb"))
					.replace(/[|]/, "\n");
				return item;
			});
		} else {
			response.foods.food.food_description = response.foods.food.food_description
				.replace(/[-] Calories/, "")
				.replace(/Fat/, i18n.t("fat"))
				.replace(/Protein/, i18n.t("protein"))
				.replace(/Carbs/, i18n.t("totalcarb"))
				.replace(/[|]/, "\n");
			let a = [];
			a.push(response.foods.food);
			response.foods.food = a;
		}
	} else {
		response.foods.food = [];
	}

	let canLoadMore = false;
	if (50 * (parseInt(response.foods.page_number) + 1) > parseInt(response.foods.total_results)) {
		canLoadMore = false;
	} else {
		canLoadMore = true;
	}
	return {
		type: RECEIVE_FATSECRET_SEARCH,
		payload: response.foods,
		canLoadMore,
	};
};

export const clearFatSecretSearch = () => {
	return {
		type: CLEAR_FATSECERT_SEARCH,
	};
};

//Special EncodeURIComponent() for the FatSecret API Search Text
const fixedEncodeURIComponent = (str) => {
	return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
		return "%" + c.charCodeAt(0).toString(16);
	});
};

export const fetchFatSecretSearch = (searchedText, pageCount = 0) => {
	const METHOD = "foods.search";
	const OAUTH_TIMESTAMP = new Date().getTime();
	const OAUTH_NONCE = "" + new Date().getTime();
	const SEARCH_EXPRESSION = fixedEncodeURIComponent(searchedText);
	const MAX_RESULTS = "50";
	const PAGE_NUMBER = pageCount;

	// Create a Signature Base String
	const REQUEST_URL_ENCODED = encodeURIComponent(REQUEST_URL);
	let NORMALISED_PARAMETERS = "format=" + FORMAT;
	NORMALISED_PARAMETERS += "&max_results=" + MAX_RESULTS;
	NORMALISED_PARAMETERS += "&method=" + METHOD;
	NORMALISED_PARAMETERS += "&oauth_consumer_key=" + OAUTH_CONSUMER_KEY;
	NORMALISED_PARAMETERS += "&oauth_nonce=" + OAUTH_NONCE;
	NORMALISED_PARAMETERS += "&oauth_signature_method=" + OAUTH_SIGNATURE_METHOD;
	NORMALISED_PARAMETERS += "&oauth_timestamp=" + OAUTH_TIMESTAMP;
	NORMALISED_PARAMETERS += "&oauth_version=" + OAUTH_VERSION;
	NORMALISED_PARAMETERS += "&page_number=" + PAGE_NUMBER;
	NORMALISED_PARAMETERS += "&search_expression=" + SEARCH_EXPRESSION;
	const NORMALISED_PARAMETERS_ENCODED = encodeURIComponent(NORMALISED_PARAMETERS);
	const BASE_STRING = REQUEST_METHOD + "&" + REQUEST_URL_ENCODED + "&" + NORMALISED_PARAMETERS_ENCODED;
	const OAUTH_SIGNATURE = new jsSHA(BASE_STRING, "TEXT");
	const OAUTH_SIGNATURE_BASE64 = OAUTH_SIGNATURE.getHMAC(encodeURIComponent(SHARED_SECRET) + "&", "TEXT", "SHA-1", "B64");
	const OAUTH_SIGNATURE_BASE64_ENCODED = encodeURIComponent(OAUTH_SIGNATURE_BASE64);
	const OAUTH_REQUEST_URL = REQUEST_URL + "?" + NORMALISED_PARAMETERS + "&oauth_signature=" + OAUTH_SIGNATURE_BASE64_ENCODED;
	return (dispatch) => {
		dispatch(requestFatSecretSearch(searchedText));
		if (Platform.OS === "web") {
			var href = new URL("https://us-central1-medidiet-119a0.cloudfunctions.net/fatsecret/fatsecret");
			href.searchParams.set("url", OAUTH_REQUEST_URL);
			return fetch(`${href.toString()}`, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((json) => dispatch(receiveFatSecretSearch(json)))
				.catch((error) => {
					console.error(error);
				});
		} else {
			return fetch(OAUTH_REQUEST_URL)
				.then((response) => response.json())
				.then((json) => dispatch(receiveFatSecretSearch(json)))
				.catch((error) => {
					console.error(error);
				});
		}
	};
};

export const requestLoadMoreFatSecretSearch = () => {
	return {
		type: REQUEST_LOAD_MORE_FATSECERT_SEARCH,
	};
};

export const receiveLoadMoreFatSecretSearch = () => {
	return {
		type: RECEIVE_LOAD_MORE_FATSECERT_SEARCH,
	};
};

export const combineLoadMoreResults = () => (dispatch, getState) => {
	const state = getState();
	const searchText = state.fatSecretApi.loadMoreSearchText;
	const pageCount = +state.fatSecretApi.pageNumber;
	dispatch(requestLoadMoreFatSecretSearch());
	dispatch(fetchFatSecretSearch(searchText, pageCount + 1));
	dispatch(receiveLoadMoreFatSecretSearch());
};
