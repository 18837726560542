//@flow
import React from 'react';
import { FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { ModalFlatList } from './ModalFlatList';
import _ from 'lodash';
import { addUserIngredient } from 'Redux/actions';

export const MealEditingTabList = ({ navigation, route }) => {
	const {
		savedmealsv3,
		customfoodsv3,
		favorites,
		recentlyused,
		settings: { units: carbUnits }
	} = useSelector((state) => state.firebase.profile);
	const { theme } = useSelector((state) => state.startup);

	const dataConfig = (itemType) => {
		switch (itemType) {
			case 'food':
				return customfoodsv3;
			case 'meal':
				return savedmealsv3;
			case 'favorite':
				return favorites;
			case 'recent':
				return recentlyused;
			default:
				return;
		}
	};

	const renderItem = ({ item }) => {
		return (
			<ModalFlatList
				item={item}
				type={route.params.type}
				addIngredient={addUserIngredient}
				carbUnits={carbUnits}
				navigation={navigation}
				route={route}
				theme={theme}
			/>
		);
	};

	const { type } = route.params;

	const arr = _.values(dataConfig(type));
	const sortedArr = arr.slice().sort((a, b) => {
		var textA = a.name.toUpperCase();
		var textB = b.name.toUpperCase();
		return textA < textB ? -1 : textA > textB ? 1 : 0;
	});

	return (
		<FlatList
			data={sortedArr}
			keyExtractor={(item) => item.id}
			renderItem={renderItem}
			keyboardShouldPersistTaps="handled"
			style={{ backgroundColor: theme.baseBG }}
		/>
	);
};
