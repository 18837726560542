//@flow
import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Text } from 'Components/common';
import { FontAwesome } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import {
	Menu,
	MenuOptions,
	MenuOption,
	MenuTrigger
} from 'react-native-popup-menu';

type Props = {
	menuIconColor?: string,
	customHeight?: number,
	menuIconSize?: number,
	array: Array<{
		text?: string,
		onPress: Function,
		iconName?: string,
		iconColor?: string,
		iconText?: string
	}>
};

export const HeaderMenuDropdown = (props: Props) => {
	const { theme } = useSelector((state) => state.startup);
	const {
		array = [],
		menuIconColor = theme.navButton,
		customHeight = 60,
		menuIconSize = 21
	} = props;

	if (array.length < 1) {
		return <View />;
	}

	return (
		<Menu>
			<MenuTrigger>
				<View style={[styles.container, { height: Platform.OS === "web" ? 40 : '100%' }]}>
					<FontAwesome
						name="ellipsis-v"
						size={menuIconSize}
						color={menuIconColor}
					/>
				</View>
			</MenuTrigger>
			<MenuOptions style={{ backgroundColor: theme.grey }}>
				{array.length > 0 &&
					array.map((item) => {
						return !item.text ? (
							<MenuOption
								key={item.iconName}
								customStyles={{
									optionWrapper: [
										styles.wrapperStyle,
										{ borderBottomColor: theme.border }
									],
									optionText: [styles.itemStyle, { color: theme.darkFont }]
								}}
								onSelect={item.onPress}>
								<FontAwesome
									name={item.iconName}
									color={item.iconColor}
									size={14}
									style={styles.iconStyle}
								/>
								<Text style={styles.itemStyle}>{item.iconText}</Text>
							</MenuOption>
						) : (
							<MenuOption
								key={item.text}
								customStyles={{
									optionText: [styles.itemStyle, { color: theme.darkFont }],
									optionWrapper: [
										styles.wrapperStyle,
										{ backgroundColor: theme.base }
									]
								}}
								onSelect={item.onPress}
								text={item.text}
							/>
						);
					})}
			</MenuOptions>
		</Menu>
	);
};

var styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
		paddingHorizontal: 30
	},
	wrapperStyle: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		borderBottomWidth: 1
	},
	itemStyle: {
		paddingVertical: 10,
		fontSize: 14,
		fontFamily: 'Raleway'
	},
	iconStyle: {
		paddingLeft: 10,
		width: 40,
		paddingRight: 10,
		alignSelf: 'center'
	}
});
