//@flow
import React from 'react';
import { View } from 'react-native';
import { CardSection, Card, Text } from './';

export const ErrorText = (props) => {
	const { showError } = props;
	return showError ? (
		<Card>
			<CardSection>
				<Text style={{ textAlign: 'center' }}>{props.children}</Text>
			</CardSection>
		</Card>
	) : (
		<View />
	);
};
