//@flow
import { ADDED_MACROS_TO_LOG } from 'Components/tracker/log/actions/types';
import Toast from 'react-native-root-toast';
import { i18n } from 'Theme';
import { WebCompatAlert } from 'Components/common';
import { randomString } from 'Utilities/FirebaseHelper';
import { servingUpdate } from 'Utilities/MacrosUtil';
import * as NavigationService from 'Navigation/NavigationService';
import { incrementTrackerInterAdCount } from 'Redux/actions';
import type {
	servingUpdateType,
	FirebaseCustomFoodv3,
	FirebaseSavedMealv3
} from 'Utilities/FlowTypes';
export type Dispatch = (ThunkAction) => any;
export type GetState = () => Object;
export type GetFirebase = () => Object;
export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

//Grey QuickAdd + Button on the FlatLists
export const quickAddEntry = (
	item: FirebaseCustomFoodv3 | FirebaseSavedMealv3,
	time: string,
	date: string
) => (dispatch: Dispatch, getState: GetState, getFirebase: GetFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { premium } = state.firebase.profile;
	const uniqid = randomString(14);
	const macroHolder = servingUpdate(item, '1');
	const itemName = item.customServingName
		? `${item.name} x 1 ${item.customServingName}`
		: `${item.name} x 1 ${i18n.t('servings')}`;
	const postData = {
		name: itemName,
		calories: +macroHolder.calories,
		fats: +macroHolder.fats,
		proteins: +macroHolder.proteins,
		carbs: +macroHolder.carbs,
		totalcarbs: +macroHolder.totalcarbs,
		fiber: +macroHolder.fiber,
		nutrientsV2: macroHolder.nutrientsV2,
		manualCalories: item.manualCalories || false,
		id: item.id,
		type: item.type,
		random: uniqid
	};
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};
	updates[`trackerdate/${date}/${time}/${uniqid}`] = postData;
	updates[`recentlyused/${item.id}`] = {
		name: item.name,
		id: item.id,
		type: item.type
	};

	ref.update(updates).then(() => {
		dispatch({ type: ADDED_MACROS_TO_LOG, payload: time });
		if (!premium) dispatch(incrementTrackerInterAdCount());
		Toast.show(`${itemName} ${i18n.t('added')}`, {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
	return NavigationService.addFoodReturn();
};

//Takes an itemObject, a Log Date, and a Meal Time and adds the item to the Log
export const logEntry = (
	itemObject: servingUpdateType,
	date: string,
	time: string
) => (dispatch: Dispatch, getState: GetState, getFirebase: GetFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { premium } = state.firebase.profile;
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};
	const uniqid = randomString(14);
	const postData = {
		name: itemObject.servingAmount,
		calories: +itemObject.calories,
		fats: +itemObject.fats,
		proteins: +itemObject.proteins,
		carbs: +itemObject.carbs,
		totalcarbs: +itemObject.totalcarbs,
		fiber: +itemObject.fiber,
		nutrientsV2: itemObject.nutrientsV2,
		manualCalories: itemObject.manualCalories || false,
		id: itemObject.id,
		type: itemObject.type,
		random: uniqid
	};

	updates[`trackerdate/${date}/${time}/${uniqid}`] = postData;
	updates[`recentlyused/${itemObject.id}`] = {
		name: itemObject.name,
		id: itemObject.id,
		type: itemObject.type
	};

	ref.update(updates).then(() => {
		dispatch({ type: ADDED_MACROS_TO_LOG, payload: time });
		if (!premium) dispatch(incrementTrackerInterAdCount());
		Toast.show(`${itemObject.name} ${i18n.t('added')}`, {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
		return NavigationService.navigate('LogScreen', {});
	});
};

//Deletes a saved preset (Food/Meal) by using it's item ID
export const deletePreset = (
	item: FirebaseCustomFoodv3 | FirebaseSavedMealv3,
	goBack: boolean = false
) => (dispatch: Dispatch, getState: GetState, getFirebase: GetFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};
	updates[`savedmealsv3/${item.id}`] = {};
	updates[`customfoodsv3/${item.id}`] = {};
	updates[`favorites/${item.id}`] = {};
	updates[`recentlyused/${item.id}`] = {};
	WebCompatAlert(
		i18n.t('deleted'),
		i18n.t('deleteConfirm'),
		i18n.t('delete'),
		() =>
			ref.update(updates).then(() => {
				if (goBack) {
					NavigationService.goBack();
				}
				return Toast.show(`${item.name} ${i18n.t('deleted')}`, {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			})
	);
};
