//@flow
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { Text } from 'Components/common';
import { FontAwesome } from '@expo/vector-icons';
import { useDispatch } from 'react-redux';
import { quickDelete } from '../../actions';

type Props = {
	item: Object,
	date: string,
	mealTime: string,
	editEntry: Function,
	theme: Object
};

export const MealEntry = (props: Props) => {
	const dispatch = useDispatch();
	const { item, mealTime, date, editEntry, theme } = props;
	return (
		<View
			style={[
				styles.foodItemContainer,
				{ borderBottomColor: theme.border, backgroundColor: theme.popMenuBg }
			]}>
			<TouchableOpacity
				style={styles.itemTitle}
				onPress={() => editEntry(item, date, mealTime)}>
				<Text style={[styles.foodItem, { color: theme.themeAccent }]}>
					{item.name}
				</Text>
			</TouchableOpacity>
			<TouchableOpacity
				style={[
					styles.quickDelete,
					{ backgroundColor: theme.grey, borderLeftColor: theme.border }
				]}
				onPress={() => dispatch(quickDelete(item, mealTime))}>
				<FontAwesome
					name="close"
					size={14}
					color={theme.darkFont}
					style={styles.quickDeleteIcon}
				/>
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	foodItem: {
		fontSize: 12,
		textAlign: 'left',
		lineHeight: 16
	},
	foodItemContainer: {
		borderBottomWidth: 1,
		flex: 1,
		flexDirection: 'row'
	},
	itemTitle: {
		flex: 4,
		paddingVertical: 15,
		paddingLeft: 20,
		paddingRight: 8,
		alignItems: 'flex-start'
	},
	quickDelete: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'stretch',
		flex: 0.75,
		paddingVertical: 15,
		borderLeftWidth: 1
	},
	quickDeleteIcon: {
		alignSelf: 'center'
	}
});
