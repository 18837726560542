// @flow

//Returns Water in correct units as a string with or without unit labels
export const returnWaterString = (
	waterML: string,
	isMetric: boolean,
	units: boolean
) => {
	let water = '';
	if (isMetric === true) {
		water = +waterML;
	} else {
		water = +waterML * 0.033814;
	}
	return units
		? `${+water.toFixed(1)} ${isMetric ? 'ml' : 'oz'}`
		: `${+water.toFixed(1)}`;
};

export const returnWaterStringNull = (
	waterML: string,
	isMetric: boolean,
	units: boolean
) => {
	if (waterML === '') {
		return null;
	}
	let water = '';
	if (isMetric === true) {
		water = +waterML;
	} else {
		water = +waterML * 0.033814;
	}
	return units
		? `${+water.toFixed(1)} ${isMetric ? 'ml' : 'oz'}`
		: `${+water.toFixed(1)}`;
};

//Returns Water in correct units as a number without labels
export const returnWaterNumber = (water: string, isMetric: boolean) => {
	let waters = '';
	if (isMetric === true) {
		waters = +water;
	} else {
		waters = +water * 0.033814;
	}

	return waters;
};

//Returns Water in ML number, in order to be saved (always saved as ML)
export const returnWaterML = (water: string, isMetric: boolean) => {
	let weightMl = '';
	if (isMetric === true) {
		weightMl = +water;
	} else {
		weightMl = +water / 0.033814;
	}
	return weightMl;
};
