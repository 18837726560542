//@flow
import React from 'react';
import { View } from 'react-native';
import styles from '../Styles/FlatList';
import { Text } from 'Components/common';
import { MultiSelect } from '../multiselect';
import Icon from 'react-native-vector-icons/FontAwesome';
import { calculateMacroDescription } from 'Utilities/MacrosUtil';

type Props = {
	listArray: Array<Object>,
	onSelectionChange: Function,
	carbUnits: string,
	theme: Object
};

export const FlatListMultiAdd = (props: Props) => {
	const { listArray, onSelectionChange, carbUnits, theme } = props;

	//Gets the selected color for the multi-select rows. If selected, the row needs to change color
	const getSelectedColor = (isSelected) => {
		let selectedBg = {};
		if (isSelected) {
			selectedBg = { backgroundColor: theme.nutrientBG };
		}
		return selectedBg;
	};

	return (
		<MultiSelect
			options={listArray.map((thing) => ({
				key: thing.id,
				name: thing.name,
				...thing
			}))}
			renderRow={(row, isSelected) => (
				<View
					style={[
						styles.rowContainer,
						getSelectedColor(isSelected),
						{ borderBottomColor: theme.border }
					]}>
					<View style={styles.textHolder}>
						<View style={styles.textContainer}>
							<Text style={[styles.itemTitle, { color: theme.themeAccent }]}>
								{row.name}
							</Text>
							<View />
							<Text style={styles.itemDescription}>
								{calculateMacroDescription(row, carbUnits)}
							</Text>
						</View>
						{row.favorite === true ? (
							<Icon
								style={{ alignSelf: 'center', flex: 0.1 }}
								name={'star'}
								color={'#f1c40f'}
								size={12}
							/>
						) : null}
					</View>
					<View
						style={[styles.quickAddHolder, { backgroundColor: theme.grey }]}>
						<Icon
							style={styles.quickAdd}
							name={isSelected ? 'check-circle-o' : 'circle-o'}
							color={theme.themeAccent}
							size={20}
						/>
					</View>
				</View>
			)}
			onSelectionChange={onSelectionChange}
			style={[styles.multiListStyle, { borderTopColor: theme.border }]}
		/>
	);
};
