//@flow
import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

export const Checkbox = ({
	selected,
	onPress,
	style,
	size = 30,
	color = '#FFF',
	text = '',
	...props
}) => (
	<TouchableOpacity
		style={[styles.checkBox, style]}
		onPress={onPress}
		{...props}>
		<MaterialCommunityIcons
			size={size}
			color={color}
			name={selected ? 'check-circle' : 'checkbox-blank-circle-outline'}
		/>

		<Text style={[styles.textStyle, props.textStyle]}> {text} </Text>
	</TouchableOpacity>
);

const styles = StyleSheet.create({
	checkBox: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	textStyle: {
		color: '#FFF',
		fontSize: 10
	}
});
