// @flow
import moment from 'moment';
import _ from 'lodash';
//Returns Weight in correct units as a string with or without unit labels
export const returnGlucoseString = (
	glucoseMMOL: string,
	isMMOL: boolean,
	units: boolean
) => {
	let glucose = '';
	if (isMMOL === true) {
		glucose = +glucoseMMOL;
	} else {
		glucose = +glucoseMMOL * 18.02;
	}
	return units
		? `${+glucose.toFixed(1)} ${isMMOL ? 'mmol/L' : 'mg/dL'}`
		: `${+glucose.toFixed(1)}`;
};

//Returns weight in correct units as a number without labels
export const returnGlucoseNumber = (glucose: string, isMMOL: boolean) => {
	let glucoses = '';
	if (isMMOL === true) {
		glucoses = +glucose;
	} else {
		glucoses = +glucose * 18.02;
	}
	return glucoses;
};

//Returns weight in mmol/L number, in order to be saved (always saved as mmol/L)
export const returnMMOL = (glucose: string, isMMOL: boolean) => {
	let glucoseMMOL = '';
	if (isMMOL === true) {
		glucoseMMOL = +glucose;
	} else {
		glucoseMMOL = +glucose / 18.02;
	}
	return glucoseMMOL;
};

export const glucoseTimeStamp = (isoString: string) => {
	return moment(isoString).local().format('LT');
};

export const returnLatestGlucose = (BG: Object | boolean) => {
	if (BG === false) {
		return 0;
	}
	const arr = _.values(BG);
	const sortedBg = _.sortBy(arr, [
		(o) => {
			return o.time;
		}
	]);
	return sortedBg[sortedBg.length - 1];
};

export const returnLatestGlucoseValue = (BG: Object | boolean) => {
	if (BG === false) {
		return 0;
	}
	const arr = _.values(BG);
	const sortedBg = _.sortBy(arr, [
		(o) => {
			return o.time;
		}
	]);
	return sortedBg[sortedBg.length - 1].glucose;
};

export const returnGlucoseRange = (isMMOL: boolean) => {
	if (isMMOL) {
		return [2, 18];
	} else {
		return [40, 320];
	}
};
