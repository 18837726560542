// @flow
import { Platform } from 'react-native';
import Toast from 'react-native-root-toast';
import { WebCompatAlert } from 'Components/common';
import { i18n } from 'Theme';
import { CHANGE_VITAL_REVIEW_NAME } from './types';
import moment from 'moment';
import {
	ahk_updateGlucose,
	ahk_deleteGlucose
} from 'Components/tracker/fitnesstrackers/utilities/AppleHealthKit';

//Saves or Updates Vital Key/Value for a specific Date. Used for both Saving original and Editing Previous Entries
//Date in YYYY-MM-DD format
export const saveVital = (
	firebaseVitalName: string,
	value: Object,
	id: string,
	originalDateIfUpdating?: string
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { ahkWasAuth } = state.fitnessTracker.appleHealthKit;
	const ref = firebase.database().ref(`userdata/${uid}/vitals/`);
	let updates = {};
	if (originalDateIfUpdating) {
		updates[
			`${moment(originalDateIfUpdating).format(
				'YYYY-MM-DD'
			)}/${firebaseVitalName}/${id}`
		] = {};
	}

	const fbnodeDate = moment(value.time).format('YYYY-MM-DD'); //convert iso string from vital entry object to get needed YYYY-MM-DD value for fb node
	updates[`${fbnodeDate}/${firebaseVitalName}/${id}`] = value;

	if (firebaseVitalName === 'Blood Glucose') {
		if (Platform.OS === 'ios' && ahkWasAuth) {
			ahk_updateGlucose(value);
		}
	}

	ref.update(updates).then(() => {
		Toast.show(i18n.t('updated'), {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
};

//Delete Vital Key/Value for a specific Date.
//Date in YYYY-MM-DD format
export const deleteVital = (
	item: Object,
	itemName: string,
	callback?: Function
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { ahkWasAuth } = state.fitnessTracker.appleHealthKit;
	const ref = firebase.database().ref(`userdata/${uid}/vitals/`);
	const formattedDate = moment(item.time).format('YYYY-MM-DD');

	let updates = {};
	updates[`${formattedDate}/${itemName}/${item.id}`] = {};

	WebCompatAlert(
		i18n.t('delete'),
		i18n.t('confirmDelete'),
		i18n.t('delete'),
		() => {
			if (itemName === 'Blood Glucose') {
				if (Platform.OS === 'ios' && ahkWasAuth) {
					ahk_deleteGlucose(item);
				}
			}

			return ref.update(updates).then(() => {
				callback && callback();
				Toast.show(i18n.t('updated'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			});
		}
	);
};

/*-------------------------------Review Vitals Tab----------------------------------------------*/

//Changes Vital REview Tab filter while keeping translations
export const changeVitalName = (newName: string, index: number) => {
	const payLd = vitalNameFind(newName);

	return {
		type: CHANGE_VITAL_REVIEW_NAME,
		value: payLd,
		label: newName,
		index: index
	};
};

//Converts String to English Version for Review Tab Filter in order to save to Firebase Node
export const vitalNameFind = (newName: string) => {
	switch (newName) {
		case i18n.t('BloodGlucose'):
			return 'Blood Glucose';
		case i18n.t('Ketones'):
			return 'Ketones';
		case i18n.t('Potassium'):
			return 'Potassium';
		case i18n.t('Sodium'):
			return 'Sodium';
		case i18n.t('Magnesium'):
			return 'Magnesium';
		case i18n.t('HeartRate'):
			return 'Heart Rate';
		case i18n.t('BloodPressure'):
			return 'Blood Pressure';
		default:
			return 'Ketones';
	}
};

/*export const createDemoData = () => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};

	//Adding Demo Data
	var startDate = new Date('2020-011-04'); //YYYY-MM-DD
	var endDate = new Date('2021-01-04'); //YYYY-MM-DD
	var getDateArray = (start, end) => {
		var arr = {};
		var dt = new Date(start);
		while (dt <= end) {
			const day = moment(dt).format('YYYY-MM-DD');
			const time = '06:30';
			const time2 = '13:30';
			const time3 = '20:00';
			const randomOne = randomString(14);
			const randomTwo = randomString(14);
			const randomThree = randomString(14);
			const randomFour = randomString(14);
			const randomFive = randomString(14);
			const randomSix = randomString(14);
			const randomSeven = randomString(14);
			const randomEight = randomString(14);
			const randomNine = randomString(14);
			arr[moment(dt).format('YYYY-MM-DD')] = {
				'Blood Glucose': {
					[randomOne]: {
						id: randomOne,
						time: moment(day + ' ' + time, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (5.5 - 3.5) + 3.5
					},
					[randomTwo]: {
						id: randomTwo,
						time: moment(day + ' ' + time2, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (8 - 6.8) + 6.8
					},
					[randomThree]: {
						id: randomThree,
						time: moment(day + ' ' + time3, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (7 - 6) + 6
					}
				},
				Ketones: {
					[randomFour]: {
						id: randomFour,
						time: moment(day + ' ' + time, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (5 - 0)
					},
					[randomFive]: {
						id: randomFive,
						time: moment(day + ' ' + time2, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (8 - 6.8) + 6.8
					},
					[randomSix]: {
						id: randomSix,
						time: moment(day + ' ' + time3, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (5 - 0)
					}
				},
				'Blood Pressure': {
					[randomSeven]: {
						id: randomSeven,
						time: moment(day + ' ' + time, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (140 - 110) + 110,
						valueTwo: Math.random() * (90 - 70) + 70
					},
					[randomEight]: {
						id: randomEight,
						time: moment(day + ' ' + time3, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (140 - 110) + 110,
						valueTwo: Math.random() * (90 - 70) + 70
					},
					[randomNine]: {
						id: randomNine,
						time: moment(day + ' ' + time3, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (140 - 110) + 110,
						valueTwo: Math.random() * (90 - 70) + 70
					}
				}
			};
			dt.setDate(dt.getDate() + 1);
		}
		return arr;
	};
	var dateArr = getDateArray(startDate, endDate);
	updates['vitals'] = dateArr;
	ref.update(updates).then(() => console.log('DONOE'));
	console.log(JSON.stringify({ dateArr }));
};*/
