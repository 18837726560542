//@flow
import {
	SAVE_PURCHASE_DATA,
	PROMO_CODE_SUCCESS /*TRIAL_EXPIRED */
} from '../actions/types';

const INITIAL_STATE = {
	purchase: false,
	purchaseData: {},
	platform: '',
	promoUsed: false
	// trialExpired: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SAVE_PURCHASE_DATA:
			return {
				...INITIAL_STATE,
				purchase: action.payload,
				purchaseData: action.purchaseData,
				platform: action.platform
			};
		case PROMO_CODE_SUCCESS:
			return { ...state, purchase: action.payload, promoUsed: true };
		/*  case TRIAL_EXPIRED:
      return { ...state, trialExpired: true }; */
		default:
			return state;
	}
};
