import {
	FITBIT_AUTH,
	FITBIT_LOGOUT,
	FITBIT_SYNCED,
	AHK_SYNCED,
	GFIT_SYNCED,
	AUTH_APPLE_HK
} from './types';
import {
	ADDED_MACROS_TO_LOG,
	DELETED_MACROS_FROM_LOG
} from 'Components/tracker/log/actions/types';

const INITIAL_STATE = {
	fitbit: {
		auth: { isAuth: false },
		shouldSync: false,
		syncMeal: ''
	},
	appleHealthKit: {
		shouldSync: false,
		ahkWasAuth: false
	},
	googleFit: {
		shouldSync: false
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FITBIT_AUTH:
			return {
				...state,
				fitbit: {
					...INITIAL_STATE.fitbit,
					auth: { isAuth: true, ...action.payload }
				}
			};
		case FITBIT_LOGOUT:
			return { ...state, fitbit: { ...INITIAL_STATE.fitbit } };
		case AUTH_APPLE_HK:
			return {
				...state,
				appleHealthKit: { shouldSync: false, ahkWasAuth: true }
			};
		case ADDED_MACROS_TO_LOG:
			return {
				...state,
				fitbit: {
					...state.fitbit,
					shouldSync: true,
					syncMeal: action.payload
				},
				appleHealthKit: { ...state.appleHealthKit, shouldSync: true },
				googleFit: { shouldSync: true }
			};
		case DELETED_MACROS_FROM_LOG:
			return {
				...state,
				fitbit: {
					...state.fitbit,
					shouldSync: true,
					syncMeal: action.payload
				},
				appleHealthKit: { ...state.appleHealthKit, shouldSync: true },
				googleFit: { shouldSync: true }
			};
		case FITBIT_SYNCED:
			return { ...state, fitbit: { ...state.fitbit, shouldSync: false } };
		case AHK_SYNCED:
			return {
				...state,
				appleHealthKit: { ...state.appleHealthKit, shouldSync: false }
			};
		case GFIT_SYNCED:
			return { ...state, googleFit: { shouldSync: false } };
		default:
			return state;
	}
};
