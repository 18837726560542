//@flow
import React from 'react';
import { View, TextInput, Platform, StyleSheet } from 'react-native';
import { Text, SwitchSelector, Gram } from 'Components/common';

type renderEditProps = {
	value: string,
	onChange: Function,
	inputContainerStyle?: Object,
	style?: Object,
	isError: boolean,
	placeholder?: string,
	refInput?: Object,
	onFocus?: Function,
	onBlur?: Function,
	onSubmitEditing?: Function
};

//Edting Name style
export const RenderEdit = (props: renderEditProps) => {
	const {
		value,
		onChange,
		inputContainerStyle,
		isError,
		style,
		placeholder,
		refInput,
		onFocus,
		onBlur,
		onSubmitEditing,
		keyboardType = 'default'
	} = props;

	return (
		<View style={[styles.textInputHolder, inputContainerStyle]}>
			<TextInput
				style={[styles.textinput, style]}
				onChangeText={onChange}
				placeholderTextColor={isError ? 'red' : '#e6e6e6'}
				placeholder={placeholder}
				value={value}
				ref={refInput}
				onFocus={onFocus}
				onBlur={onBlur}
				onSubmitEditing={onSubmitEditing}
				underlineColorAndroid="rgba(0,0,0,0)"
				autoCorrect={false}
				keyboardType={keyboardType}
			/>
		</View>
	);
};

type renderListItemInputProps = {
	value: string,
	onChange: Function,
	labelText: string,
	style?: Object,
	isError: boolean,
	errorMessage?: string,
	refInput?: Object,
	onFocus?: Function,
	onBlur?: Function,
	onSubmitEditing?: Function,
	textInputStyle?: Object
};

//EditingItemModal Style
export const RenderListItemTextInput = (props: renderListItemInputProps) => {
	const {
		value,
		onChange,
		isError,
		style,
		textInputStyle,
		labelText,
		errorMessage = labelText,
		refInput,
		onFocus,
		onBlur,
		onSubmitEditing
	} = props;

	let formattedValue = value && value.replace(',', '.');

	return (
		<View style={[styles.listItemContainer, style]}>
			<Text style={isError ? styles.listError : styles.listLabel}>
				{isError ? errorMessage : labelText}
			</Text>
			<TextInput
				style={[styles.listInput, textInputStyle]}
				onChangeText={onChange}
				placeholder={'0'}
				placeholderTextColor={isError ? 'red' : '#e6e6e6'}
				value={formattedValue}
				underlineColorAndroid="rgba(0,0,0,0)"
				keyboardType={'decimal-pad'}
				ref={refInput}
				onFocus={onFocus}
				onBlur={onBlur}
				onSubmitEditing={onSubmitEditing}
				autoCorrect={false}
			/>
		</View>
	);
};

type renderSwitchSelectorProps = {
	value: string,
	onChange: Function,
	options: Object,
	buttonColor?: string,
	fontSize?: string,
	hasPadding?: boolean
};

export const RenderSwitchSelector = (props: renderSwitchSelectorProps) => {
	const {
		onChange,
		value,
		options,
		hasPadding = false,
		fontSize = 12,
		buttonColor
	} = props;
	return (
		<SwitchSelector
			options={options}
			initial={value ? 1 : 0}
			onPress={(value) => onChange(value)}
			buttonColor={buttonColor}
			fontSize={fontSize}
			hasPadding={hasPadding}
		/>
	);
};

//Macro Calculator

type renderInputClassProps = {
	value: string,
	onChange: Function,
	labelText: string,
	style?: Object,
	isError: boolean,
	errorMessage?: string,
	refInput?: Object,
	onFocus?: Function,
	onBlur?: Function,
	onSubmitEditing?: Function,
	gram?: boolean,
	gramText?: string,
	editable?: boolean,
	containerStyle?: Object,
	customComponent?: Object
};

export const RenderInputClass = (props: renderInputClassProps) => {
	const {
		value,
		onChange,
		isError,
		style,
		labelText,
		errorMessage = labelText,
		refInput,
		onFocus,
		onBlur,
		onSubmitEditing,
		gram = true,
		gramText,
		editable,
		containerStyle,
		customComponent
	} = props;

	let formattedValue;
	if (typeof value === 'string') {
		formattedValue = value && value.replace(',', '.');
	} else {
		formattedValue = value.toFixed(0);
	}

	return (
		<View style={[styles.inputClassHolder, containerStyle]}>
			<View style={styles.placeholderContainer}>
				<Text style={isError ? styles.listError : styles.listLabel}>
					{isError ? errorMessage : labelText}
				</Text>
				{customComponent && customComponent}
			</View>
			<TextInput
				style={[styles.textInputContainer, style]}
				onChangeText={onChange}
				placeholder={'0'}
				placeholderTextColor={isError ? 'red' : '#e6e6e6'}
				value={formattedValue}
				underlineColorAndroid="rgba(0,0,0,0)"
				keyboardType={'decimal-pad'}
				ref={refInput}
				onFocus={onFocus}
				onBlur={onBlur}
				onSubmitEditing={onSubmitEditing}
				editable={editable}
				autoCorrect={false}
			/>
			{gram && <Gram customText={gramText} />}
		</View>
	);
};

const styles = StyleSheet.create({
	//renderInputClass Styles
	inputClassHolder: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1,
		marginHorizontal: 30
	},
	textInputContainer: {
		flex: 2.5,
		paddingVertical: 10,
		paddingRight: 20,
		textAlign: 'right',
		fontFamily: 'Raleway'
	},
	placeholderContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center'
	},
	placeholderStyle: {
		fontSize: 12,
		paddingRight: 5
	},
	errorInputStyle: {
		fontSize: 12,
		color: 'red',
		paddingRight: 5
	},
	labelInput: {
		fontSize: 12
	},
	labelError: {
		color: 'red',
		fontSize: 13
	},
	//renderEdit
	textInputHolder: {
		flexDirection: 'row',
		borderWidth: 1,
		flex: 1,
		paddingLeft: 5
	},
	textinput: {
		flex: 1,
		fontSize: 14,
		fontFamily: 'Raleway',
		textAlign: 'left',
		paddingVertical: 15,
		paddingLeft: 10
	},

	//rrenderTextInputIconButton
	searchContainer: {
		flex: 0.75,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 20,
		paddingBottom: 10,
		paddingLeft: 10,
		paddingRight: 10
	},
	searchButton: {
		flex: 1,
		alignSelf: 'stretch',
		justifyContent: 'center',
		alignItems: 'center',
		borderLeftWidth: 1
	},
	searchBar: {
		paddingLeft: 15,
		fontSize: 14,
		fontFamily: 'Raleway',
		height: 50,
		flex: 4,
		marginLeft: 10
	},
	//InputListItemsRender
	listLabel: {
		flex: 2,
		alignSelf: 'center'
	},
	listItemContainer: {
		flexDirection: 'row',
		borderBottomWidth: 1,
		paddingVertical: Platform.OS === 'ios' ? 15 : 0,
		paddingLeft: 15
	},
	listInput: {
		flex: 1,
		textAlign: 'center',
		fontSize: 14,
		borderLeftWidth: 1,
		fontFamily: 'Raleway'
	},
	listError: {
		color: 'red',
		flex: 2,
		alignSelf: 'center'
	},

	//LoginForm
	loginInputContainer: {
		marginHorizontal: 40,
		backgroundColor: 'transparent',
		flexDirection: 'column',
		marginVertical: 10
	},
	loginInput: {
		fontFamily: 'Raleway',
		fontWeight: 'normal',
		color: '#FFF',
		marginTop: 5
	},
	loginLabel: {
		fontFamily: 'Raleway',
		fontWeight: 'normal',
		color: '#FFF',
		marginTop: 0
	},
	loginErrorLabel: {
		fontFamily: 'Raleway',
		fontWeight: 'normal',
		color: 'red',
		marginTop: 0
	}
});
