//@flow
import React, { useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { fetchComments } from './actions/ThreadActions';
import { CardSection, Loader } from 'Components/common';
import { CommentOP, CommentList } from './components';

export const RecipeFeedSingleScreen = (props) => {
	const dispatch = useDispatch();
	const comments = useSelector((state) => state.redditSingle.comments);
	const commentOP = useSelector((state) => state.redditSingle.commentOP);
	const loaded = useSelector((state) => state.redditSingle.loaded);
	const { theme } = useSelector((state) => state.startup);

	useEffect(() => {
		dispatch(fetchComments(props.route.params.permalink));
	}, []);

	if (loaded)
		return (
			<ScrollView style={{ backgroundColor: theme.backdropGray }}>
				<CommentOP thread={commentOP} />
				<CardSection>
					<CommentList comments={comments} />
				</CardSection>
			</ScrollView>
		);

	return (
		<Loader full={true} loaderBG={theme.baseBG} loaderColor={theme.darkFont} />
	);
};
