//@flow
import React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import {
	FsServingSelectorWheel,
	FsServingSelectorTextInput,
	FsServingSelectorTypeSwitch
} from './servings';
import { Colors } from 'Theme';
import { useSelector, useDispatch } from 'react-redux';
import { servingSelectTypeChange } from 'Components/fatsecret/actions';

type Props = {
	theme: Object
};

export const FsServingSelector = (props: Props) => {
	const { theme } = props;
	const dispatch = useDispatch();
	const servingSelectType = useSelector(
		(state) => state.fatSecretSingleFood.servingSelectType
	);

	if (Platform.OS === 'web')
		return <FsServingSelectorTextInput theme={theme} />;

	return (
		<View style={[styles.servingContainer, {backgroundColor: theme.base}]}>
			{servingSelectType === 'wheel' && (
				<FsServingSelectorWheel theme={theme} />
			)}
			{servingSelectType === 'input' && (
				<FsServingSelectorTextInput theme={theme} />
			)}
			<FsServingSelectorTypeSwitch
				onChange={(value) => dispatch(servingSelectTypeChange(value))}
				active={servingSelectType}
				theme={theme}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	servingContainer: {
		flex: 1
	}
});
