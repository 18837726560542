//@flow
import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';

type Props = {
	loaderBG: string,
	loaderColor: string,
	full?: boolean
};

export const Loader = (props: Props) => {
	const { loaderBG, loaderColor = '#333' } = props;
	if (!props.full) {
		return <ActivityIndicator size="large" style={styles.activityIndicator} />;
	}

	return (
		<View style={[styles.container, { backgroundColor: loaderBG }]}>
			<ActivityIndicator size="large" color={loaderColor} />
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(255,255,255,0.18)'
	},
	activityIndicator: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		height: 200
	}
});
