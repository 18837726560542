//@flow
import React from 'react';
import { StyleSheet, View, PixelRatio, Linking } from 'react-native';
import { Text } from 'Components/common';
import { CommentList } from './';
import he from 'he';
import Hyperlink from 'react-native-hyperlink';

const linkURL = (url) => {
	Linking.openURL(url);
};

export const Comment = (props) => {
	const _renderRepliesSection = () => {
		var repliesSection = props.comment.replies.length ? (
			<View>{_renderReplies()}</View>
		) : (
			<View />
		);
		return repliesSection;
	};

	const _renderReplies = () => {
		return (
			<View style={styles.repliesContainer}>
				<CommentList comments={props.comment.replies} />
			</View>
		);
	};

	return (
		<View style={styles.comment}>
			<View>
				<Hyperlink onPress={(url) => linkURL(url)} linkStyle={styles.linkStyle}>
					<Text style={styles.commentBody}>
						{props.comment.body && he.unescape(props.comment.body)}
					</Text>
				</Hyperlink>
				{props.comment.replies && _renderRepliesSection()}
			</View>
		</View>
	);
};

var styles = StyleSheet.create({
	comment: {
		flex: 1,
		margin: 5,
		marginBottom: 20,
		paddingLeft: 4,
		borderLeftColor: '#CACACA',
		borderLeftWidth: 1 / PixelRatio.get()
	},
	commentBody: {
		marginTop: 10,
		marginBottom: 15,
		fontSize: 12
	},
	repliesContainer: {
		flex: 1,
		marginLeft: 5,
		marginTop: 10
	},
	linkStyle: {
		color: '#2980b9',
		fontSize: 12
	}
});
