import { registerRootComponent } from 'expo';
import App from './App';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';

// For Firebase WEB JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCcJuMKIRiZGG1f3h3wp8xQfirO2RlV6PY",
  authDomain: "medidiet-119a0.firebaseapp.com",
  databaseURL: "https://medidiet-119a0-default-rtdb.firebaseio.com",
  projectId: "medidiet-119a0",
  storageBucket: "medidiet-119a0.appspot.com",
  messagingSenderId: "660428425148",
  appId: "1:660428425148:web:79ec8e66161722c0fdcdb9",
  measurementId: "G-6G3YK3TYV5"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
