//@flow
import React from 'react';
import { View, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { EditingItemModal as QuickAddMacros } from 'Components/modal/EditingItemModal';
import { EditingItemModal } from 'Components/modal/EditingItemModal';
import { i18n } from 'Theme';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

type Props = {
	macroModal: boolean,
	editIngredientModal: boolean,
	addMacrosToMeal: Function,
	toggleEditIngredientModal: Function,
	toggleMacroModal: Function,
	updateQuickMacroIngredient: Function,
	tempIngredientObject: Object
};

export const CustomSingleMealModals = (props: Props) => {
	const {
		macroModal,
		toggleMacroModal,
		editIngredientModal,
		toggleEditIngredientModal,
		addMacrosToMeal,
		updateQuickMacroIngredient,
		tempIngredientObject
	} = props;
	const dispatch = useDispatch();
	//Editing Log Item Submit Changes from Actions Function
	const confirmUpdateQuickMacroIngredient = (values, originalItem) => {
		dispatch(
			updateQuickMacroIngredient(values, originalItem, () =>
				toggleEditIngredientModal(false)
			)
		);
	};

	const quickSubmit = (item) => {
		dispatch(addMacrosToMeal(item));
		toggleMacroModal(false);
	};

	return (
		<View>
			<Modal.ReactNativeModal
				isVisible={macroModal}
				onClosed={() => toggleMacroModal(false)}
				style={{
					padding: 0,
					margin: 0
				}}
				animationIn={'slideInUp'}
				animationOut={'slideOutDown'}>
				<QuickAddMacros
					closeModal={() => toggleMacroModal(false)}
					onSubmit={quickSubmit}
					modalTitle={i18n.t('quickAddToMeal')}
					item={{
						name: i18n.t('quickAdd'),
						calories: '0',
						carbs: '0',
						fats: '0',
						proteins: '0',
						manualCalories: false,
						totalcarbs: '0',
						fiber: '0',
						nutrientsV2: {}
					}}
				/>
			</Modal.ReactNativeModal>
			{Object.keys(tempIngredientObject).length !== 0 && (
				<Modal.ReactNativeModal
					isVisible={editIngredientModal}
					style={{
						padding: 0,
						margin: 0
					}}
					animationIn={'slideInUp'}
					animationOut={'slideOutDown'}
					onClosed={() => toggleEditIngredientModal(false)}>
					<EditingItemModal
						closeModal={() => toggleEditIngredientModal(false)}
						onSubmit={confirmUpdateQuickMacroIngredient}
						item={{
							...tempIngredientObject,
							name: tempIngredientObject.name,
							calories: tempIngredientObject.calories.toString(),
							carbs: tempIngredientObject.carbs.toString(),
							fats: tempIngredientObject.fats.toString(),
							proteins: tempIngredientObject.proteins.toString(),
							manualCalories: tempIngredientObject.manualCalories || false,
							totalcarbs: tempIngredientObject.totalcarbs.toString(),
							fiber: tempIngredientObject.fiber.toString(),
							nutrientsV2: tempIngredientObject.nutrientsV2 || []
						}}
						modalTitle={i18n.t('editingItem')}
					/>
				</Modal.ReactNativeModal>
			)}
		</View>
	);
};
