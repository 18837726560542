//FatSecret Search Types
export const REQUEST_FATSECRET_SEARCH = 'REQUEST_FATSECRET_SEARCH';
export const RECEIVE_FATSECRET_SEARCH = 'RECEIVE_FATSECRET_SEARCH';
export const REQUEST_FATSECRET_FOOD = 'REQUEST_FATSECRET_FOOD';
export const RECEIVE_FATSECRET_FOOD = 'RECEIVE_FATSECRET_FOOD';
export const ADD_FOOD_TO_24_CACHE = 'ADD_FOOD_TO_24_CACHE';
export const REQUEST_LOAD_MORE_FATSECERT_SEARCH =
	'REQUEST_LOAD_MORE_FATSECERT_SEARCH';
export const RECEIVE_LOAD_MORE_FATSECERT_SEARCH =
	'RECEIVE_LOAD_MORE_FATSECERT_SEARCH';
export const CLEAR_FATSECERT_SEARCH = 'CLEAR_FATSECERT_SEARCH';

//FatSecret Barcode Types
export const REQUEST_FATSECRET_BARCODE = 'REQUEST_FATSECRET_BARCODE';
export const RECEIVE_FATSECRET_BARCODE = 'RECEIVE_FATSECRET_BARCODE';
export const ERROR_FATSECRET_BARCODE = 'ERROR_FATSECRET_BARCODE';
export const PUSH_RECENTLY_SCANNED_BARCODE = 'PUSH_RECENTLY_SCANNED_BARCODE';
export const CLEAR_RECENTLY_SCANNED = 'CLEAR_RECENTLY_SCANNED';

//FatSecret Single Food
export const SERVING_WHEEL_CHANGE_FS = 'SERVING_WHEEL_CHANGE_FS';
export const SERVING_WHEEL_CANCEL_FS = 'SERVING_WHEEL_CANCEL_FS';
export const SERVING_WHEEL_CONFIRM_FS = 'SERVING_WHEEL_CONFIRM_FS';
export const SERVING_INPUT_QTY_CHANGE_FS = 'SERVING_INPUT_QTY_CHANGE_FS';
export const SERVING_INPUT_LABEL_CHANGE_FS = 'SERVING_INPUT_LABEL_CHANGE_FS';
export const SWITCH_SERVING_SELECTOR_FS = 'SWITCH_SERVING_SELECTOR_FS';
export const PRELOAD_SERVING_WHEEL = 'PRELOAD_SERVING_WHEEL';
