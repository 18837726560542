//@flow
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Fonts, Colors } from 'Theme';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text } from './';

export const WhiteButton = (props) => {
	const { onPress, color = Colors.blue, center = true } = props;
	return (
		<TouchableOpacity style={[styles.container, props.style]} onPress={onPress}>
			{props.iconName && (
				<Icon
					style={[styles.iconStyle, props.iconStyle]}
					name={props.iconName}
					color={color}
					size={props.iconSize}
				/>
			)}
			<Text style={[styles.btnText, props.btnTextStyle, { textAlign: center ? 'center' : 'auto' }]}>
				{props.children}
			</Text>
		</TouchableOpacity>
	);
};

var styles = StyleSheet.create({
	container: {
		borderRadius: 2,
		borderWidth: 1,
		borderColor: Colors.blue,
		justifyContent: 'center',
		alignItems: 'center',
		height: 50,
		marginVertical: 20,
		marginHorizontal: 15,
		flexDirection: 'row'
	},
	btnText: {
		color: Colors.blue,
		fontSize: 15,
		fontFamily: 'Raleway'
	},
	iconStyle: {
		paddingRight: 10,
		alignSelf: 'center'
	}
});
