import {
	REQUEST_FATSECRET_SEARCH,
	RECEIVE_FATSECRET_SEARCH,
	REQUEST_LOAD_MORE_FATSECERT_SEARCH,
	RECEIVE_LOAD_MORE_FATSECERT_SEARCH,
	CLEAR_FATSECERT_SEARCH,
	REQUEST_FATSECRET_BARCODE,
	RECEIVE_FATSECRET_BARCODE
} from '../actions/types';

const INITIAL_STATE = {
	isFetching: false,
	searchResults: [],
	loadMoreSearchText: '',
	pageNumber: '',
	canLoadMore: false,
	recentBarcodes: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REQUEST_FATSECRET_SEARCH:
			return {
				...state,
				isFetching: true,
				loadMoreSearchText: action.payload
			};
		case RECEIVE_FATSECRET_SEARCH:
			return {
				...state,
				isFetching: false,
				searchResults: [...state.searchResults, ...action.payload.food],
				totalResults: action.payload.total_results,
				pageNumber: action.payload.page_number,
				canLoadMore: action.canLoadMore
			};
		case REQUEST_LOAD_MORE_FATSECERT_SEARCH:
			return { ...state, isFetching: true };
		case RECEIVE_LOAD_MORE_FATSECERT_SEARCH:
			return { ...state, isFetching: false };
		case CLEAR_FATSECERT_SEARCH:
			return { ...state, searchResults: [] };
		case REQUEST_FATSECRET_BARCODE:
			return { ...state, isFetching: true };
		case RECEIVE_FATSECRET_BARCODE:
			return {
				...state,
				isFetching: false
			};
		default:
			return state;
	}
};
