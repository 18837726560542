//@flow
import React from 'react';
import { View, FlatList, TouchableOpacity, StyleSheet } from 'react-native';
import { Text, ListHeader } from 'Components/common';
import Icon from 'react-native-vector-icons/FontAwesome';
import { iconType } from '../../utilities/helper';
import { useDispatch } from 'react-redux';
import { i18n } from 'Theme';

type Props = {
	items: Array<Object>,
	removeIngredient: Function,
	editServing: Function,
	theme: Object
};

export const IngredientsEditing = (props: Props) => {
	const { items, removeIngredient, editServing, theme } = props;
	const dispatch = useDispatch();
	const renderEditingItem = ({ item }) => {
		return (
			<View
				style={[
					styles.editMealItemsContainer,
					{ borderBottomColor: theme.border, backgroundColor: theme.baseBG }
				]}>
				<TouchableOpacity
					style={[styles.deleteItem, { backgroundColor: theme.red }]}
					onPress={() => dispatch(removeIngredient(item.random, items))}>
					<Icon style={styles.deleteIcon} name="minus" color="#FFF" size={20} />
				</TouchableOpacity>
				{item.type ? (
					<TouchableOpacity
						style={styles.editServingItemContainer}
						onPress={() => editServing(item)}>
						<Icon
							style={[styles.typeIcon, { color: theme.themeAccent }]}
							name={iconType(item.type)}
							size={12}
						/>
						<Text
							style={[styles.editServingItem, { color: theme.themeAccent }]}>
							{item.name}
						</Text>
					</TouchableOpacity>
				) : (
					<Text style={styles.titleItem}>{item.name}</Text>
				)}
			</View>
		);
	};

	return (
		<View style={styles.foodListStyle}>
			<ListHeader
				leftText={`${i18n.t('ingredients')}:`}
				noMargin
				style={{ backgroundColor: theme.base }}
			/>
			<FlatList
				data={items}
				keyExtractor={(item) => item.random}
				renderItem={renderEditingItem}
				removeMealItem={removeIngredient}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	foodListStyle: {
		alignSelf: 'stretch',
		flex: 1
	},
	textStyle: {
		paddingLeft: 0,
		paddingRight: 0,
		fontSize: 12,
		lineHeight: 15
	},
	editMealItemsContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingHorizontal: 18,
		paddingVertical: 15,
		borderBottomWidth: 1
	},
	deleteItem: {
		width: 30,
		height: 30,
		borderRadius: 30,
		alignItems: 'center',
		justifyContent: 'center'
	},
	deleteIcon: {
		alignSelf: 'center'
	},
	titleItem: {
		flex: 1
	},
	editServingItemContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		flex: 1
	},
	editServingItem: {
		paddingLeft: 0,
		fontSize: 12,
		lineHeight: 15
	},
	typeIcon: {
		alignSelf: 'center',
		paddingLeft: 10,
		width: 30,
		paddingRight: 5
	}
});
