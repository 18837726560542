import {
	REQUEST_KETO_REDDIT_COMMENTS,
	RECEIVE_KETO_REDDIT_COMMENTS,
	SET_THREAD_DETAILS
} from './types';

export const requestComments = () => {
	return {
		type: REQUEST_KETO_REDDIT_COMMENTS
	};
};

export const receiveComments = (com) => {
	return {
		type: RECEIVE_KETO_REDDIT_COMMENTS,
		payload: com
	};
};

export const threadDetails = (commentOp) => {
	return {
		type: SET_THREAD_DETAILS,
		payload: commentOp.data.children[0].data
	};
};

function beutifyReplies(comments) {
	return comments.map((comment) => {
		comment.replies = comment.replies
			? comment.replies.data.children
					.map((reply) => reply.data)
					.filter((c) => c.body)
			: [];
		beutifyReplies(comment.replies);
		return comment;
	});
}

export const fetchComments = (permalink) => {
	return (dispatch) => {
		dispatch(requestComments());
		fetch(`https://www.reddit.com/${permalink}.json`)
			.then((response) => {
				return response.json();
			})
			.then((responseJson) => {
				dispatch(threadDetails(responseJson[0]));
				return responseJson[1].data.children
					.map((c) => c.data)
					.filter((c) => c.body);
			})
			.then((transform) => {
				dispatch(receiveComments(beutifyReplies(transform)));
			});
	};
};
