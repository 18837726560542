//@flow
import React, { useEffect, useRef } from 'react';
import {
	View,
	StyleSheet,
	TextInput,
	Platform,
	ScrollView,
	SafeAreaView
} from 'react-native';
import { Gram, Button, Text, HeaderTextButton } from 'Components/common';
import { i18n } from 'Theme';
import { returnCorrectUnitsString, moderateScale } from 'Utilities';
import NavigationBar from 'react-native-navbar';
import { KeyboardAccessoryView } from 'react-native-keyboard-accessory';
import KeyboardManager from 'react-native-keyboard-manager';
import { ProgressDateHeader } from 'Components/tracker/progress/components';
import Elevations from 'react-native-elevation';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

type Props = {
	isModalVisible: boolean,
	onClose: Function,
	onChangeValue: Function,
	saveEdit: Function,
	value: string,
	name: string,
	unitsArray?: Object,
	bodymeasurementsUnits: string,
	theme: Object
};

//Modal that opens when user clicks on a bodymeasurement row item on the BodyMeasurements screen. For Saving and Editing values.
export const EditModal = (props: Props) => {
	const {
		isModalVisible,
		onClose,
		onChangeValue,
		saveEdit,
		value,
		name,
		unitsArray,
		bodymeasurementsUnits,
		theme
	} = props;

	const _textInput = useRef(null);

	useEffect(() => {
		if (Platform.OS === 'ios') KeyboardManager.setEnableAutoToolbar(false);
		return () => {
			if (Platform.OS === 'ios') KeyboardManager.setEnableAutoToolbar(true);
		};
	}, []);

	//Focus on the textinput when loading
	const onFocusEdit = () => {
		if (value === '0.0') {
			return _textInput.current.setNativeProps({ text: '' });
		}
	};

	//Render TextInput for number entry
	const renderTextInput = (unitString: string, name: string) => {
		const unitType = unitsArray[name];
		return (
			<View style={[styles.numberRow, { borderBottomColor: theme.border }]}>
				<TextInput
					style={[
						styles.numberInput,
						{ color: theme.themeAccent, borderColor: theme.placeholderGray }
					]}
					onChangeText={(value) => onChangeValue(value)}
					keyboardType={'numeric'}
					value={value}
					underlineColorAndroid="rgba(0,0,0,0)"
					onFocus={() => onFocusEdit()}
					onBlur={() => _textInput.current.setNativeProps({ text: value })}
					ref={_textInput}
				/>
				<Gram
					style={{ alignSelf: 'center', color: theme.darkFont }}
					customText={returnCorrectUnitsString(unitType, unitString)}
				/>
			</View>
		);
	};

	//Render page with text input and save button
	const renderContent = (name: string) => {
		const unitType = unitsArray[name];
		return (
			<View style={{ flex: 1 }}>
				{props.date && (
					<ProgressDateHeader date={props.date} changeDate={props.changeDate} theme={theme} />
				)}
				<ScrollView style={{ flex: 1 }} keyboardShouldPersistTaps={'handled'}>
					{renderTextInput(bodymeasurementsUnits, name)}
				</ScrollView>
				<KeyboardAccessoryView
					alwaysVisible={true}
					avoidKeyboard={true}
					style={{
						backgroundColor: theme.teal
					}}
					androidAdjustResize>
					<Button
						iconName="ios-checkmark-circle"
						color={theme.white}
						iconSize={16}
						style={{
							backgroundColor: theme.teal,
							padding: 0,
							marginHorizontal: 0,
							marginVertical: 5
						}}
						isFlat={true}
						textStyle={styles.btn}
						fullWidth={true}
						onPress={() => saveEdit(name, unitType)}>
						{i18n.t('confirm')}
					</Button>
				</KeyboardAccessoryView>
			</View>
		);
	};

	const unitType = unitsArray[name];
	return (
		<Modal.ReactNativeModal
			animationIn={'slideInLeft'}
			animationOut={'slideOutRight'}
			isVisible={isModalVisible}
			hasBackdrop={false}
			style={{ margin: 0 }}
			hideModalContentWhileAnimating={true}
			onModalShow={() => _textInput && _textInput.current.focus()}>
			<SafeAreaView
				style={[styles.topCtn, { backgroundColor: theme.navBar }]}
			/>
			<SafeAreaView style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<NavigationBar
					statusBar={
						Platform.OS === 'ios' ? { hidden: false } : { hidden: true }
					}
					title={
						<Text
							style={{
								color: theme.navText,
								fontWeight: null,
								fontFamily: 'Comfortaa',
								fontSize: 14
							}}>
							{i18n.t(name.replace(/\s+/g, ''))}{' '}
							<Gram
								style={{
									color: theme.navText
								}}
								customText={returnCorrectUnitsString(
									unitType,
									bodymeasurementsUnits
								)}
								addPar={unitType !== 'none' ? true : false}
							/>
						</Text>
					}
					containerStyle={{
						backgroundColor: theme.navBar,
						paddingHorizontal: 10
					}}
					leftButton={
						<HeaderTextButton
							title={i18n.t('cancel')}
							onPress={() => onClose()}
						/>
					}
				/>
				{renderContent(name)}
			</SafeAreaView>
		</Modal.ReactNativeModal>
	);
};

const styles = StyleSheet.create({
	numberInput: {
		fontSize: moderateScale(15),
		fontFamily: 'Comfortaa',
		justifyContent: 'flex-end',
		alignItems: 'center',
		textAlign: 'center',
		paddingHorizontal: 5,
		marginRight: 10,
		borderWidth: 1,
		minWidth: 100,
		minHeight: 45
	},
	btn: {
		fontSize: 12,
		color: '#FFF'
	},
	numberRow: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingVertical: 20,
		borderBottomWidth: 1
	}
});
