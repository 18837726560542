//@flow
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

type Props = {
	children?: React.ComponentType<any>
};

export const Divider = (props: Props) => {
	return (
		<View style={[styles.dividePadding, props.style]}>{props.children}</View>
	);
};

var styles = StyleSheet.create({
	dividePadding: {
		paddingTop: 10,
		paddingBottom: 10
	}
});
