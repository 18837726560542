//@flow
import React from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import { Text } from 'Components/common';
import { Colors } from 'Theme';
import { validateNumber } from 'Utilities';
import { useSelector } from 'react-redux';

type Props = {
	onChange: Function,
	currentValue: Object,
	containerStyle?: Object,
	placeholder?: string,
	placeholderStyle?: Object,
	children?: Object
};

export const OptionTextInput = (props: Props) => {
	const {
		onChange,
		currentValue,
		placeholder,
		containerStyle,
		placeholderStyle
	} = props;
	const { theme } = useSelector((state) => state.startup);

	return (
		<View
			style={[
				styles.selectContainer,
				containerStyle,
				{ borderBottomColor: theme.border }
			]}>
			{placeholder && (
				<Text
					style={[
						styles.placeholderStyle,
						placeholderStyle
					]}>{`${placeholder} `}</Text>
			)}
			<TextInput
				style={[
					styles.textInput,
					{ borderColor: theme.border },
					{ color: validateNumber(currentValue) ? theme.blue : theme.red }
				]}
				onChangeText={(value) => onChange(value)}
				placeholderTextColor={theme.placeholderGray}
				value={currentValue}
				keyboardType="decimal-pad"
			/>
			{props.children}
		</View>
	);
};

const styles = StyleSheet.create({
	selectContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1,
		paddingVertical: 5,
		paddingRight: 10
	},
	placeholderStyle: {
		flex: 1
	},
	textInput: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 10,
		width: 60,
		borderRadius: 1,
		textAlign: 'center',
		fontFamily: 'Comfortaa'
	}
});
