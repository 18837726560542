//@flow
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, TextInput } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

type Props = {
	onSubmit: Function,
	placeholder?: string,
	theme: Object
};

export const SearchBar = (props: Props) => {
	const [value, setValue] = useState('');
	const { onSubmit, placeholder = 'Search', theme } = props;
	return (
		<View
			style={[
				styles.container,
				props.style,
				{ borderBottomColor: theme.border, backgroundColor: theme.baseBG }
			]}>
			<TextInput
				style={[styles.textInput, { color: theme.themeAccent }]}
				multiline={false}
				onChangeText={(value) => setValue(value)}
				placeholder={placeholder}
				placeholderTextColor={theme.placeholderGray}
				value={value}
				onSubmitEditing={() => onSubmit(value)}
			/>
			<TouchableOpacity
				style={[styles.icon, { backgroundColor: theme.blue }]}
				onPress={() => onSubmit(value)}>
				<MaterialIcons name={'search'} size={12} color={'#FFF'} />
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		height: 50,
		borderBottomWidth: 1
	},
	textInput: {
		flex: 1,
		paddingLeft: 20
	},
	icon: {
		alignSelf: 'stretch',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 0.15
	}
});
